import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Text, View } from 'react-native';
import { useCommonStyles } from '../styles';
import Button from '/components/Button';
import GridList from '/components/GridList';
import TeamMemberCard from '/components/Teams/TeamMemberCard';
import { SECTION_CONTAINER } from '/constants';
import { useAuthContext } from '/context';
import {
  PaginatedTeamMembers,
  Team,
  TeamMember,
  TeamMemberRole,
  User,
} from '/generated/graphql';

interface ITeamMember extends Pick<TeamMember, 'userId' | 'teamId' | 'title'> {
  user: Pick<User, 'id' | 'name' | 'profile_image'>;
  team_role?: TeamMemberRole | undefined;
}

interface IPaginatedTeamMembers extends Omit<PaginatedTeamMembers, 'items'> {
  items: ITeamMember[];
}

export interface ITeam extends Pick<Team, 'id'> {
  members: IPaginatedTeamMembers;
}

interface IUser extends Pick<User, 'id'> {
  team?: ITeam | undefined | null;
}

interface Props {
  data: IUser | undefined | null;
  isEditing: boolean;
}

export default function OurTeam(props: Props) {
  const { navigate, push } = useNavigation<any>();
  const { userData } = useAuthContext();

  const { styles: commonStyles } = useCommonStyles();

  function goToProfile(userId: string) {
    push('Profile', { id: userId });
  }

  function onManageTeam() {
    const params = {};

    if (props.data?.id !== userData?.id) {
      Object.assign(params, {
        teamId: props.data?.team?.id,
      });
    }

    navigate('ManageTeam', params);
  }

  return props.data?.team?.members?.items?.length || props.isEditing ? (
    <View style={SECTION_CONTAINER}>
      <View style={commonStyles('sectionTitleContainer')}>
        <Text style={commonStyles('sectionTitle')}>OUR TEAM</Text>
      </View>
      <View
        style={{
          flexDirection: 'row',
          paddingBottom: 8,
        }}
      >
        {props.isEditing ? (
          <Button
            containerStyle={{ marginVertical: 8, marginBottom: 16 }}
            label={
              <View
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <Ionicons name="people" size={20} color="black" />
                <Text
                  style={{
                    alignItems: 'center',
                    fontFamily: 'Lato-Bold',
                    fontSize: 16,
                    marginHorizontal: 4,
                  }}
                >
                  Manage
                </Text>
              </View>
            }
            onPress={onManageTeam}
          />
        ) : null}
      </View>
      {props.data?.team?.members?.total === 0 ? (
        <Text
          style={{
            fontFamily: 'Lato-Bold',
            color: 'gray',
            alignSelf: 'center',
            padding: 24,
            paddingTop: 12,
            fontSize: 17,
          }}
        >
          You haven't added any team members yet
        </Text>
      ) : (
        <GridList
          data={props.data?.team?.members?.items}
          renderItem={({ item: member, tileWidth }) => {
            return (
              <TeamMemberCard
                avatarSize={tileWidth - 12}
                member={member as ITeamMember}
                onPress={() => {
                  goToProfile(member.userId as string);
                }}
              />
            );
          }}
        />
      )}
    </View>
  ) : null;
}
