import React from 'react';
import { Text, TextStyle, View, ViewStyle } from 'react-native';

import Skills from '/constants/Skills';

type SkillBadgeProps = {
  skill: string;
  color?: string;
  highlight?: boolean;
  style?: ViewStyle;
  textStyle?: TextStyle;
};

const DEFAULT_BADGE_COLOR = '#F5F5F5';
const DEFAULT_HIGHLIGHT_COLOR = '#CAFE00';

const SkillBadge = ({
  skill,
  highlight,
  color = DEFAULT_BADGE_COLOR,
  style = {},
  textStyle = {},
}: SkillBadgeProps) => {
  return (
    <View
      style={[
        {
          backgroundColor: highlight ? DEFAULT_HIGHLIGHT_COLOR : color,
          padding: 8,
          borderRadius: 5,
          margin: 3,
          marginRight: 5,
          marginLeft: 0,
        },
        style,
      ]}
    >
      <Text
        style={[
          {
            fontFamily: 'Lato-Bold',
            textTransform: 'uppercase',
            textAlign: 'center',
          },
          textStyle,
        ]}
      >
        {Skills[skill as keyof typeof Skills] ?? skill}
      </Text>
    </View>
  );
};

export default SkillBadge;
