import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import { Platform } from 'react-native';

export async function loadResourcesAsync() {
  await Promise.all([
    Asset.loadAsync([require('../assets/images/splash.png')]),
    Platform.OS === 'web'
      ? Font.loadAsync({
          Lato: require('../assets/fonts/Lato/Lato.ttf'),
          'Lato-Italic': require('../assets/fonts/Lato/Lato-Italic.ttf'),
          'Lato-Bold': require('../assets/fonts/Lato/Lato-Bold.ttf'),
          'Lato-BoldItalic': require('../assets/fonts/Lato/Lato-BoldItalic.ttf'),
          LeagueSpartan: require('../assets/fonts/LeagueSpartan/LeagueSpartan.ttf'),
          'LeagueSpartan-Medium': require('../assets/fonts/LeagueSpartan/LeagueSpartan-Medium.ttf'),
          'LeagueSpartan-Bold': require('../assets/fonts/LeagueSpartan/LeagueSpartan-Bold.ttf'),
        })
      : undefined,
  ]);
}
