import { Feather } from '@expo/vector-icons';
import React, { useState } from 'react';
import { Image, Pressable, StyleSheet, Text, View } from 'react-native';
import { useCommonStyles } from '../styles';
import EditSpeciesUserContentModal from './EditSpeciesUserContentModal';
import Alert from '/Alert';
import Button from '/components/Button';
import GridList from '/components/GridList';
import AddSpecies from '/compositions/AddSpecies';
import { KEY_GREEN, SECTION_CONTAINER } from '/constants';
import {
  Maybe,
  NewSpecies,
  SpeciesIucnThreatStatus,
  SpeciesUserContent,
  SpeciesUserContentCategory,
  SpeciesUserContentFact,
  SpeciesUserContentMedia,
  SpeciesVernacularName,
  useDeleteSpeciesUserContentMutation,
  User,
} from '/generated/graphql';
import { DeepPartial } from '/types';
import getCDNImageUri from '/util/getCDNImageUri';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

interface ISpecies
  extends Pick<
    NewSpecies,
    | 'taxonID'
    | 'acceptedNameUsageID'
    | 'preferredVernacularName'
    | 'canonicalName'
  > {
  iucnThreatStatus?: Pick<SpeciesIucnThreatStatus, 'threatStatus'> | null;
}

export interface IOurSpeciesSpeciesUserContentMedia
  extends Pick<
    SpeciesUserContentMedia,
    'id' | 'uri' | 'thumbnail' | 'caption' | 'public'
  > {
  created_by?: Pick<User, 'id' | 'name' | 'profile_image'> | undefined;
}

interface ISpeciesUserContentFact
  extends Pick<SpeciesUserContentFact, 'public' | 'body'> {
  id: string | undefined;
}

export interface IOurSpeciesSpeciesUserContent
  extends Pick<SpeciesUserContent, 'newSpeciesTaxonID'> {
  thumbnail_media?:
    | Pick<SpeciesUserContentMedia, 'id' | 'thumbnail'>
    | undefined
    | null;
  new_species: ISpecies | undefined;
  preferred_vernacular_name?:
    | Pick<SpeciesVernacularName, 'vernacularName'>
    | undefined
    | null;
  media: IOurSpeciesSpeciesUserContentMedia[];
  facts: ISpeciesUserContentFact[];
}

type IUser = Pick<DeepPartial<User>, 'id'> & {
  main_species?: IOurSpeciesSpeciesUserContent[];
  secondary_species?: IOurSpeciesSpeciesUserContent[];
};

interface Props {
  data: IUser | undefined;
  setData: (data: IUser) => void;
  isEditing: boolean;
}

type SpeciesCategory = 'main_species' | 'secondary_species';

export default function OurSpecies(props: Props) {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  /** Used to decide which data to render in modals. */
  const [modalCategory, setModalCategory] =
    useState<SpeciesCategory>('main_species');

  const [removingIds, setRemovingIds] = useState<number[]>([]);

  /** Used to track which species on our profile we are editing, if any */
  const [editingTaxonId, setEditingTaxonId] = useState<number | undefined>();

  const [isEditingSpeciesContent, setIsEditingSpeciesContent] = useState(false);

  const { styles: commonStyles } = useCommonStyles();

  const [, deleteSpeciesUserContent] = useDeleteSpeciesUserContentMutation();

  function onSpeciesPressed(index: number, category: SpeciesCategory) {
    const item = props.data?.[category]?.[index];

    if (!item || !props.data?.id) return;

    navigate('ViewSpeciesUserContent', {
      taxonID: item.newSpeciesTaxonID,
      userId: props.data.id,
    });
  }

  function onAddSpecies(category: SpeciesCategory) {
    setModalCategory(category);
    setIsEditingSpeciesContent(true);
  }

  function onEditSpecies(index: number, category: SpeciesCategory) {
    setModalCategory(category);
    const item = props.data?.[category]?.[index];
    setEditingTaxonId(item?.newSpeciesTaxonID);
    setIsEditingSpeciesContent(true);
  }

  function onRemoveSpecies(taxonID: number) {
    const remove = async () => {
      try {
        setRemovingIds((ids) => [...ids, taxonID]);

        const { error } = await deleteSpeciesUserContent({
          taxonID,
          userId: props.data?.id,
        });

        if (error) throw error;

        Alert.notify({
          message: 'Removed successfully',
          color: KEY_GREEN,
          textColor: 'black',
        });
        const data = Array.from(props.data?.[modalCategory] ?? []);

        props.setData({
          [modalCategory]: data.filter(
            (species) => species.newSpeciesTaxonID !== taxonID,
          ),
        });
      } catch (error) {
        console.log('OurSpecies: Failed to remove species', error);
        Alert.notify({
          message: 'Failed to remove',
          color: 'crimson',
          textColor: 'white',
        });
      } finally {
        setRemovingIds((ids) => ids.filter((id) => id !== taxonID));
      }
    };

    Alert.alert(
      'Remove species',
      'Are you sure you want to remove this species?',
      [
        { style: 'cancel' },
        {
          text: 'Remove',
          style: 'destructive',
          onPress: remove,
        },
      ],
    );
  }

  function onEditModalRequestClose(data?: IOurSpeciesSpeciesUserContent) {
    setIsEditingSpeciesContent(false);
    setEditingTaxonId(undefined);

    // If data is undefined, user cancelled and we don't need to do anything else
    if (!data) return;

    let newData = Array.from(props.data?.[modalCategory] ?? []);

    if (editingTaxonId !== undefined) {
      const targetIndex = newData.findIndex(
        (species) => species.newSpeciesTaxonID === editingTaxonId,
      );

      newData[targetIndex] = data;
    } else newData.push(data);

    props.setData({
      [modalCategory]: newData,
    });
  }

  const hasValidData = props.data?.main_species?.some(
    (speciesContent) =>
      !!speciesContent.media?.length && !!speciesContent.facts?.length,
  );

  return (
    <View>
      <View>
        {/* MAIN STUDY SPECIES */}
        <View
          style={[
            SECTION_CONTAINER,
            {
              flex: undefined,
              display: hasValidData || props.isEditing ? 'flex' : 'none',
            },
          ]}
        >
          <View style={commonStyles('sectionTitleContainer')}>
            <Text style={commonStyles('sectionTitle')}>
              OUR MAIN STUDY SPECIES
            </Text>
          </View>
          <Text style={[commonStyles('sectionText')]}>
            {props.isEditing
              ? 'Show people and educate them about the species you mainly work with'
              : 'Click each species to learn more'}
          </Text>
          <SpeciesGrid
            removingIds={removingIds}
            isEditing={props.isEditing}
            data={props.data?.main_species}
            onAddPressed={() => onAddSpecies('main_species')}
            onEditPressed={(index) => onEditSpecies(index, 'main_species')}
            onDeletePressed={(taxonID) => {
              onRemoveSpecies(taxonID);
            }}
            onSpeciesPressed={(index) => {
              onSpeciesPressed(index, 'main_species');
            }}
          />
        </View>

        {/* ADDITIONAL SPECIES */}
        <View
          style={[
            SECTION_CONTAINER,
            {
              flex: undefined,
              display:
                props.data?.secondary_species?.length || props.isEditing
                  ? 'flex'
                  : 'none',
            },
          ]}
        >
          <View style={commonStyles('sectionTitleContainer')}>
            <Text style={commonStyles('sectionTitle')}>ADDITIONAL SPECIES</Text>
          </View>
          <Text style={[commonStyles('sectionText')]}>
            {props.isEditing
              ? 'Show people and educate them about the species you work with on occasion'
              : 'We work with these species on occasion'}
          </Text>
          <SpeciesGrid
            removingIds={removingIds}
            data={props.data?.secondary_species}
            isEditing={props.isEditing}
            onAddPressed={() => onAddSpecies('secondary_species')}
            onEditPressed={(index) => {
              onEditSpecies(index, 'secondary_species');
            }}
            onDeletePressed={(taxonID) => {
              onRemoveSpecies(taxonID);
            }}
            onSpeciesPressed={(index) => {
              onSpeciesPressed(index, 'secondary_species');
            }}
          />
        </View>
      </View>
      {/* MODALS */}
      <EditSpeciesUserContentModal
        userId={props.data?.id}
        visible={isEditingSpeciesContent}
        category={
          modalCategory === 'main_species'
            ? SpeciesUserContentCategory.MainSpecies
            : SpeciesUserContentCategory.SecondarySpecies
        }
        data={
          editingTaxonId !== undefined
            ? props.data?.[modalCategory]?.find(
                (s) => s.newSpeciesTaxonID === editingTaxonId,
              ) ?? undefined
            : undefined
        }
        alreadyIncludedSpeciesIds={
          [props.data?.secondary_species ?? [], props.data?.main_species ?? []]
            .flat()
            .map(
              (species) =>
                species.new_species?.acceptedNameUsageID ||
                species.newSpeciesTaxonID,
            )
            .filter((id) => !!id) as number[]
        }
        onRequestClose={onEditModalRequestClose}
      />
    </View>
  );
}

interface ISpeciesGridProps {
  isEditing: boolean;
  removingIds: number[];
  onSpeciesPressed: (index: number) => void;
  onEditPressed: (index: number) => void;
  onDeletePressed: (taxonID: number) => void;
  onAddPressed: () => void;
  data: DeepPartial<Maybe<SpeciesUserContent>>[] | undefined;
}

function SpeciesGrid(props: ISpeciesGridProps) {
  return (
    <GridList
      style={{
        flex: 1,
        paddingTop: 10,
      }}
      tileContainerStyle={{
        justifyContent: 'flex-start',
      }}
      data={[props.isEditing ? {} : null, props.data ?? []]
        .flat()
        .filter((item) => !!item)}
      renderItem={({ item: speciesContent, index: falseIndex, tileWidth }) => {
        const index = props.isEditing ? falseIndex - 1 : falseIndex;

        const content = speciesContent as DeepPartial<SpeciesUserContent>;

        /** If this SpeciesUserContent is missing top_facts or has no media, it is invalid */
        const isInvalid = !content.facts?.length || !content.media?.length;

        const vernacularName =
          content.preferred_vernacular_name?.vernacularName ||
          content.new_species?.preferredVernacularName;
        const canonicalName = content.new_species?.canonicalName;

        const shouldDisable = props.removingIds.includes(
          content.newSpeciesTaxonID ?? -1,
        );

        /** If species user content is invalid and we are not editing, do not render */
        if (!props.isEditing && isInvalid) return null;

        return props.isEditing && falseIndex === 0 ? (
          <AddSpeciesButton
            size={tileWidth - 12}
            onPress={props.onAddPressed}
          />
        ) : (
          <Pressable
            pointerEvents={shouldDisable ? 'none' : 'auto'}
            disabled={shouldDisable}
            style={[
              styles.speciesContainer,
              {
                opacity: shouldDisable ? 0.5 : 1,
              },
            ]}
            onPress={() => props.onSpeciesPressed?.(index)}
          >
            {/* Invalid Content Alert */}
            {isInvalid ? (
              <Feather
                style={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  zIndex: 10,
                  elevation: 10,
                }}
                name="alert-triangle"
                size={32}
                color="crimson"
              />
            ) : null}

            <Image
              style={{
                width: tileWidth - 12,
                height: tileWidth - 12,
                borderRadius: tileWidth,
                backgroundColor: '#eee',
              }}
              source={{
                uri: getCDNImageUri({
                  uri:
                    /** If `thumbnail_media` is set, just use the thumbnail from first Media object */
                    (content.thumbnail_media ?? content.media?.[0])?.thumbnail,
                  isThumbnail: true,
                  dimensions: {
                    width: 120,
                    height: 120,
                  },
                }),
              }}
            />
            <Text style={styles.speciesName}>
              {vernacularName || canonicalName}
            </Text>
            {/* Only render scientific name here if `preferredVernacularName` is also present */}
            {vernacularName ? (
              <Text style={styles.speciesScientificName}>{canonicalName}</Text>
            ) : null}
            {props.isEditing ? (
              <View>
                <Button
                  disabled={shouldDisable}
                  label="Edit"
                  containerStyle={{
                    marginTop: 6,
                  }}
                  onPress={() => {
                    props.onEditPressed?.(index);
                  }}
                />
                <Button
                  disabled={shouldDisable}
                  label="Remove"
                  containerStyle={{
                    marginTop: 6,
                  }}
                  onPress={() =>
                    content.newSpeciesTaxonID !== undefined &&
                    props.onDeletePressed?.(content.newSpeciesTaxonID)
                  }
                />
              </View>
            ) : null}
          </Pressable>
        );
      }}
    />
  );
}

interface IAddSpeciesButtonProps {
  onPress: () => void;
  size: number;
}

function AddSpeciesButton(props: IAddSpeciesButtonProps) {
  return (
    <Pressable
      style={{
        flex: 1,
        alignItems: 'center',
      }}
      onPress={props.onPress}
    >
      <AddSpecies size={props.size} />
      <Text
        style={{
          fontFamily: 'Lato-Bold',
          fontSize: 17,
          marginTop: 4,
        }}
      >
        Add Species
      </Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  speciesContainer: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  speciesName: {
    marginTop: 8,
    textTransform: 'uppercase',
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 16,
    textAlign: 'center',
  },
  speciesScientificName: {
    fontFamily: 'Lato-Italic',
    textAlign: 'center',
  },
});
