import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import {
  CommonActions,
  RouteProp,
  useFocusEffect,
  useIsFocused,
  useScrollToTop,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Badge } from '@rneui/themed';
import { getBadgeCountAsync, setBadgeCountAsync } from 'expo-notifications';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ActivityIndicator,
  Platform,
  Pressable,
  RefreshControl,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { Buffer } from 'buffer';
import PendingTeamMemberInvite from '../Teams/elements/PendingTeamMemberInvite';
import PendingTeamMemberRequest from '../Teams/elements/PendingTeamMemberRequest';
import Alert from '/Alert';
import ChevronRight from '/assets/jsicons/miscIcons/ChevronRight';
import Notification from '/components/Notification';
import PendingGroupInvite from '/components/PendingGroupInvite';
import TeamSelector from '/components/TeamSelector/TeamSelector';
import TruncatedList from '/components/TruncatedList/TruncatedList';
import UpdateAvailableWidget from '/components/UpdateAvailableWidget';
import ScrollView from '/components/common/ScrollView/ScrollView';
import withAuthRequired from '/components/withAuthRequired';
import { KEY_GRAY, KEY_YELLOW } from '/constants';
import { useAuthContext, useTeamContext } from '/context';
import { useNotificationContext } from '/context/NotificationProvider';
import {
  GetNotificationsForUserQuery,
  NotificationType,
  Notification as TNotification,
  TeamMemberRole,
  TeamMembershipStatus,
  UserRole,
  useGetNotificationsForUserQuery,
  useGetPendingGroupInvitesQuery,
  useGetTeamMembershipsQuery,
  useGetTotalUnreadDirectConverationsQuery,
  useMarkAllNotificationsReadMutation,
  useTotalUnreadDirectConversationsSubscription,
} from '/generated/graphql';
import { DeepPartial } from '/types';
import { isUnderPrivileged, truncateNumber } from '/util';

interface Props {
  route: RouteProp<any>;
  navigation: StackNavigationProp<any>;
}

const NotificationsMain = (props: Props) => {
  const { navigate } = props.navigation;
  const { userData } = useAuthContext();
  const { teams, activeTeam } = useTeamContext();
  const { unread_notifications } = useNotificationContext();

  const isFocused = useIsFocused();

  const scrollViewRef = useRef<any>(null);

  useScrollToTop(scrollViewRef);

  const viewTeamNotifications =
    props.route.params?.viewTeamNotifications &&
    userData?.role === UserRole.Supporter;

  const userId = viewTeamNotifications ? activeTeam?.user.id : userData?.id;

  const [totalUnreadDirectConversations, setTotalUnreadDirectConversations] =
    useState<number>(0);

  const [{ data: notifications, fetching, error, stale }, getNotifications] =
    useGetNotificationsForUserQuery({
      variables: {
        userId,
      },
      pause: !userId,
      requestPolicy: 'cache-and-network',
    });
  const [, markAllNotificationsRead] = useMarkAllNotificationsReadMutation();

  const [
    {
      data: totalUnreadDirectConversationsQuery,
      fetching: fetchingTotalUnreadDirectConversations,
    },
    getTotalUnreadDirectConversations,
  ] = useGetTotalUnreadDirectConverationsQuery({
    requestPolicy: 'cache-and-network',
  });

  const [{ data: totalUnreadDirectConversationsSubscription }] =
    useTotalUnreadDirectConversationsSubscription();

  const [{ data: pendingGroupInvites }] = useGetPendingGroupInvitesQuery({
    variables: {
      userId,
    },
    pause:
      !userId ||
      !!(
        viewTeamNotifications &&
        activeTeam?.membership?.team_role !== TeamMemberRole.Admin
      ),
  });

  useEffect(() => {
    setTotalUnreadDirectConversations(
      totalUnreadDirectConversationsQuery?.getTotalUnreadDirectConversations ??
        0,
    );
  }, [totalUnreadDirectConversationsQuery]);

  useEffect(() => {
    if (
      !totalUnreadDirectConversationsSubscription?.totalUnreadDirectConversations
    )
      return;

    setTotalUnreadDirectConversations(
      totalUnreadDirectConversationsSubscription?.totalUnreadDirectConversations,
    );
  }, [
    totalUnreadDirectConversationsSubscription?.totalUnreadDirectConversations,
  ]);

  useEffect(() => {
    if (!isFocused) return;

    getTotalUnreadDirectConversations({ requestPolicy: 'network-only' });
  }, [getNotifications, getTotalUnreadDirectConversations, isFocused]);

  const [
    { data: pendingReceivedRequests, fetching: loadingPendingReceived },
    getMyPendingReceivedRequests,
  ] = useGetTeamMembershipsQuery({
    variables: {
      userId: userId!,
      membershipStatus: TeamMembershipStatus.Requested,
      notInitiatedByUserId: userId,
    },
    requestPolicy: 'cache-and-network',
    pause: !userId,
  });

  const [actionableNotifications, setActionableNotifications] = useState<
    GetNotificationsForUserQuery['getNotificationsForUser']['actionable']
  >([]);
  const [generalNotifications, setGeneralNotifications] = useState<any[]>([]);

  const [isRefreshing, setIsRefreshing] = useState(false);
  useEffect(() => {
    if (!fetching && !loadingPendingReceived) setIsRefreshing(false);
  }, [fetching, loadingPendingReceived]);

  const refresh = useCallback(() => {
    /** Refresh everything */
    getNotifications({ requestPolicy: 'network-only' });
    getMyPendingReceivedRequests({ requestPolicy: 'network-only' });
  }, [getMyPendingReceivedRequests, getNotifications]);

  const onRefresh = useCallback(
    function () {
      setIsRefreshing(true);
      refresh();
    },
    [refresh],
  );

  useEffect(() => {
    refresh();
  }, [refresh, unread_notifications]);

  useFocusEffect(
    useCallback(() => {
      /** Fetch notifications on focus */
      getNotifications({ requestPolicy: 'network-only' });

      /** Check if viewTeamNotifications is set and we cannot navigate backwards.. */
      if (viewTeamNotifications && !props.navigation.canGoBack()) {
        /** .. And if so, make sure we can. */
        props.navigation.dispatch((state) => {
          const routes = [{ name: 'Notifications' }, ...state.routes];

          return CommonActions.reset({
            ...state,
            routes,
            index: routes.length - 1,
          });
        });
      }
    }, [getNotifications, props.navigation, viewTeamNotifications]),
  );

  useEffect(() => {
    if (error)
      Alert.notify({
        message: 'Failed to fetch notifications',
        color: 'crimson',
      });
  }, [error]);

  const lastActiveTeamId = useRef<string | undefined>();
  useEffect(() => {
    if (!viewTeamNotifications) return;

    if (activeTeam?.id !== lastActiveTeamId.current) {
      lastActiveTeamId.current = activeTeam?.id;

      setGeneralNotifications([]);
      setActionableNotifications([]);
    }
  }, [activeTeam?.id, viewTeamNotifications]);

  useEffect(() => {
    if (fetching || !isFocused) return;

    const hasUnreadNotifications =
      notifications?.getNotificationsForUser.has_unreads;

    if (hasUnreadNotifications) {
      markAllNotificationsRead({
        userId: userId,
      }).then(() => {
        // If marking notifications as read is successful, set badge count to 0 (Only if reading own notifications)
        if (userId === userData?.id) return setBadgeCountAsync(0);
      });
    }

    // If for some reason there is a badge count, but no unread notifications, set badge count to 0
    getBadgeCountAsync().then((count) => {
      if (userId !== userData?.id) return;

      if (count > 0 && !hasUnreadNotifications) {
        return setBadgeCountAsync(0);
      }
    });

    // Sort notifications into categories
    // TODO: Remove those when all actionable notifications are implemented into new system
    const filteredNotifications: DeepPartial<TNotification>[] = [];

    notifications?.getNotificationsForUser?.non_actionable.forEach(
      (notification) => {
        if (!notification) return;

        switch (notification?.type) {
          /** Action Required */
          case NotificationType.GroupMembershipInviteReceived:
          case NotificationType.TeamMembershipInviteReceived:
          case NotificationType.TeamMembershipRequestReceived: {
            /** For these notification types, do not render a Notification component. Instead, we render
             * sections at the top that display any pending action items */
            break;
          }
          /** Don't render "unread notification" notifications here */
          case NotificationType.UnreadNotifications: {
            break;
          }
          /** General */
          default: {
            filteredNotifications.push(notification);
          }
        }
      },
    );

    setGeneralNotifications(filteredNotifications);
    setActionableNotifications(
      notifications?.getNotificationsForUser.actionable ?? [],
    );
  }, [
    markAllNotificationsRead,
    notifications,
    userData,
    userId,
    stale,
    fetching,
    isFocused,
  ]);

  function onViewTeamNotifications() {
    props.navigation.push('Notifications', { viewTeamNotifications: true });
  }

  function onListDirectMessages() {
    props.navigation.push('ListDirectConversations');
  }

  const totalUnreadTeamNotifications = teams.reduce((total, team) => {
    if (team.user.unread_notifications)
      return total + team.user.unread_notifications;

    return total;
  }, 0);

  const hasNotifications =
    !!generalNotifications.length || !!actionableNotifications.length;

  // Render the notifications by Section
  return viewTeamNotifications && !teams.length ? (
    <ActivityIndicator
      size="large"
      style={{
        alignSelf: 'center',
        padding: 24,
      }}
      color={KEY_GRAY}
    />
  ) : (
    <View style={{ flex: 1 }}>
      <UpdateAvailableWidget />
      <ScrollView
        refreshControl={
          <RefreshControl
            tintColor="black"
            refreshing={isRefreshing}
            onRefresh={onRefresh}
          />
        }
        ref={(r) => (scrollViewRef.current = r)}
        style={{ flex: 1 }}
        contentContainerStyle={styles.contentContainer}
      >
        {/* If we have the role Admin on at least one team, then show the
      "Manage Team Organizations" button */}
        {teams?.length &&
        teams.some(
          (team) =>
            !isUnderPrivileged(
              TeamMemberRole.Creator,
              team.membership?.team_role,
            ),
        ) &&
        !viewTeamNotifications &&
        userData?.role === UserRole.Supporter ? (
          <TouchableOpacity
            onPress={onViewTeamNotifications}
            style={styles.headerButtonContainer}
          >
            <FontAwesome name="gears" color={'gray'} size={20} />
            <Text style={styles.viewTeamNotificationsButtonText}>
              View Team Notifications
            </Text>
            {totalUnreadTeamNotifications ? (
              <Badge
                badgeStyle={{
                  backgroundColor: KEY_YELLOW,
                  marginLeft: 6,
                  height: 26,
                  minWidth: 26,
                  borderRadius: 26,
                }}
                textStyle={{
                  color: 'black',
                  paddingBottom: 1,
                }}
                value={truncateNumber(totalUnreadTeamNotifications, 99)}
              />
            ) : null}
            <View style={{ flex: 1 }} />
            <ChevronRight />
          </TouchableOpacity>
        ) : null}
        {!viewTeamNotifications && (
          <TouchableOpacity
            onPress={onListDirectMessages}
            style={[
              styles.headerButtonContainer,
              styles.headerButtonSeparatorBorder,
            ]}
          >
            <MaterialIcons name="message" size={20} color="gray" />
            <Text style={styles.viewTeamNotificationsButtonText}>
              Direct Messages
            </Text>
            {fetchingTotalUnreadDirectConversations ? (
              <ActivityIndicator
                size="small"
                color={KEY_GRAY}
                style={{ marginLeft: 6 }}
              />
            ) : totalUnreadDirectConversations ? (
              <Badge
                badgeStyle={{
                  backgroundColor: KEY_YELLOW,
                  marginLeft: 6,
                  height: 26,
                  minWidth: 26,
                  borderRadius: 26,
                }}
                textStyle={{
                  color: 'black',
                  paddingBottom: 1,
                }}
                value={truncateNumber(totalUnreadDirectConversations, 99)}
              />
            ) : null}
            <View style={{ flex: 1 }} />
            <ChevronRight />
          </TouchableOpacity>
        )}

        {viewTeamNotifications ? (
          <TeamSelector
            requireTeamSelection
            showUnreadNotificationBadge
            requireRole={TeamMemberRole.Creator}
          />
        ) : null}

        {/* Team Requests */}
        {pendingReceivedRequests?.getTeamMemberships.pending ? (
          <View style={styles.sectionContainer}>
            <View style={styles.sectionTitleContainer}>
              <Text style={styles.sectionTitle}>
                TEAM{' '}
                {userData?.role === UserRole.Conservationist ||
                viewTeamNotifications
                  ? 'REQUESTS'
                  : 'INVITES'}
              </Text>
            </View>
            <TruncatedList
              onViewMore={() => {
                navigate('ViewPendingTeamMemberships', {});
              }}
              items={pendingReceivedRequests?.getTeamMemberships.items}
              renderItem={(item) => {
                return (
                  <View style={styles.teamRequestContainer}>
                    {userData?.role === UserRole.Conservationist ||
                    viewTeamNotifications ? (
                      <PendingTeamMemberRequest
                        viewingAsSupporter={false}
                        request={item}
                        avatarSize={55}
                      />
                    ) : (
                      <PendingTeamMemberInvite
                        viewingAsOrg={false}
                        invite={item}
                        avatarSize={55}
                      />
                    )}
                  </View>
                );
              }}
            />
          </View>
        ) : null}

        {/* Group Invites */}
        {pendingGroupInvites?.getPendingGroupInvites.total ? (
          <View style={styles.sectionContainer}>
            <View style={styles.sectionTitleContainer}>
              <Text style={styles.sectionTitle}>GROUP INVITES</Text>
            </View>
            <TruncatedList
              items={pendingGroupInvites?.getPendingGroupInvites.items}
              renderItem={(item) => {
                return (
                  <View style={styles.teamRequestContainer}>
                    <PendingGroupInvite invite={item} />
                  </View>
                );
              }}
            />
          </View>
        ) : null}

        {hasNotifications ? null : fetching ? (
          <ActivityIndicator
            size="large"
            style={{
              alignSelf: 'center',
              padding: 24,
            }}
            color={KEY_GRAY}
          />
        ) : (
          <Text
            style={{
              marginVertical: 16,
              padding: 26,
              color: KEY_GRAY,
              fontFamily: 'Lato-Bold',
              width: '100%',
              textAlign: 'center',
            }}
          >
            No notifications
          </Text>
        )}

        {/* Actionable Notifications */}
        {userData?.id && actionableNotifications.length
          ? actionableNotifications?.map((section, i) => {
              return (
                <View key={i} style={styles.sectionContainer}>
                  <View style={styles.sectionTitleContainer}>
                    <Text style={styles.sectionTitle}>{section.title}</Text>
                  </View>

                  {section.notifications.map((notification, j) => {
                    if (!notification) return null;
                    return (
                      <Notification
                        key={j}
                        notification={notification}
                        viewingAsUserId={userId || userData?.id}
                      />
                    );
                  })}

                  {section.hasMore ? (
                    <Pressable
                      onPress={() => {
                        navigate('ListActionableNotifications', {
                          userId: userId || userData?.id,
                          group: Buffer.from(section.group).toString('base64'),
                        });
                      }}
                      style={({ pressed }) => ({
                        borderTopWidth: 1,
                        borderTopColor: '#eee',
                        opacity: pressed ? 0.5 : 1,
                      })}
                    >
                      <Text
                        style={{
                          fontFamily: 'Lato-Bold',
                          fontSize: 16,
                          padding: 12,
                          textAlign: 'center',
                        }}
                      >
                        View All
                      </Text>
                    </Pressable>
                  ) : null}
                </View>
              );
            })
          : null}

        {/* All Notifications */}
        {generalNotifications?.length && userData?.id ? (
          <View style={styles.sectionContainer}>
            <View style={styles.sectionTitleContainer}>
              <Text style={styles.sectionTitle}>ALL NOTIFICATIONS</Text>
            </View>
            {generalNotifications.map((item, index) => {
              if (!item) return null;
              return (
                <Notification
                  key={index}
                  notification={item}
                  viewingAsUserId={userId || userData?.id}
                />
              );
            })}
          </View>
        ) : null}
      </ScrollView>
    </View>
  );
};

export default withAuthRequired(NotificationsMain);

const styles = StyleSheet.create({
  sectionTitle: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 18,
    marginLeft: 4,
    alignItems: 'center',
    textTransform: 'uppercase',
  },
  sectionTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 8,
    paddingTop: 12,
  },
  sectionTitleIconContainer: {
    width: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  sectionContainer: {
    backgroundColor: '#fff',
    borderRadius: 10,
    margin: 8,
    marginBottom: 0,
  },
  contentContainer: {
    paddingBottom: 42,
  },
  teamRequestContainer: {
    padding: 8,
  },
  headerButtonContainer: {
    ...(Platform.OS === 'web'
      ? {
          cursor: 'pointer',
        }
      : null),
    padding: 16,
    backgroundColor: 'white',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerButtonSeparatorBorder: {
    borderTopWidth: 1,
    borderTopColor: '#eee',
  },
  viewTeamNotificationsButtonText: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
    marginLeft: 8,
  },
});
