import { UpdateResolver } from '@urql/exchange-graphcache';

const leaveTeam: UpdateResolver = (result, args, cache) => {
  cache.invalidate({
    __typename: 'TeamMember',
    teamId: (result.leaveTeam as any).teamId,
    userId: (result.leaveTeam as any).userId,
  });
};

export default leaveTeam;
