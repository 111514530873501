import { Entypo } from '@expo/vector-icons';
import React from 'react';
import { Pressable, Text } from 'react-native';
import { ISpeciesPickerSpecies } from '../../SpeciesPicker/SpeciesPickerModal';
import HorizontalContainer from '../../common/Generic/HorizontalContainer';
import styles from '../_common.style';
import SpeciesFilterModal from './SpeciesFilterModal';
import { useModalContext } from '/context';
import { shorten } from '/util';

type Props = {
  species: ISpeciesPickerSpecies[];
  onChange: (species: ISpeciesPickerSpecies[]) => void;
};

export default function SpeciesFilterButton({ species, onChange }: Props) {
  const { closeModal, spawnModal } = useModalContext();

  const modalId = React.useRef<string | null>(null);
  function onSpeciesFilterPressed() {
    modalId.current = spawnModal({
      title: 'Filter by Species',
      precedence: 1,
      component: (
        <SpeciesFilterModal
          initialState={species}
          onRequestClose={(speciesFilter) => {
            onChange(speciesFilter);
            if (modalId.current) closeModal(modalId.current);
          }}
        />
      ),
    });
  }

  const speciesFilterLabel = (() => {
    if (!species.length) {
      return 'Any';
    } else if (species.length === 1) {
      return shorten(
        species[0].vernacularName || species[0].canonicalName || '1 species',
        28,
      );
    } else {
      return `${species.length} species`;
    }
  })();

  return (
    <Pressable onPress={onSpeciesFilterPressed} style={styles.filterButton}>
      <HorizontalContainer
        style={{
          justifyContent: 'space-between',
        }}
      >
        <Text numberOfLines={1} style={styles.filterButtonText}>
          {speciesFilterLabel}
        </Text>
        <Entypo
          name="chevron-small-down"
          size={20}
          style={{
            width: 20,
            height: 20,
          }}
          color="gray"
        />
      </HorizontalContainer>
    </Pressable>
  );
}
