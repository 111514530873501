import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { StyleSheet } from 'react-native';
import Button from '/components/Button';
import ScrollView from '/components/common/ScrollView/ScrollView';
import SectionContainer from '/components/common/Section/SectionContainer';
import SectionTitle from '/components/common/Section/SectionTitle';
import withAdminAccess from '/components/withAdminAccess';

type Props = {
  navigation: StackNavigationProp<any>;
};

function Moderation(props: Props) {
  return (
    <ScrollView>
      <SectionContainer style={styles.section}>
        <SectionTitle>USER REPORTS</SectionTitle>
        <Button
          label="View"
          onPress={() => {
            props.navigation.navigate('UserReports');
          }}
        />
      </SectionContainer>
      <SectionContainer style={styles.section}>
        <SectionTitle>HIDDEN CAMPAIGNS</SectionTitle>
        <Button
          label="View"
          onPress={() => {
            props.navigation.navigate('HiddenCampaigns');
          }}
        />
      </SectionContainer>
    </ScrollView>
  );
}

export default withAdminAccess(Moderation);

const styles = StyleSheet.create({
  section: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
