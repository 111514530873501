import { MaterialIcons } from '@expo/vector-icons';
import React from 'react';
import {
  ActivityIndicator,
  Image,
  ImageStyle,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import { KEY_GRAY, KEY_LIGHT_GRAY } from '/constants';
import { useLocales } from 'expo-localization';

type Props = {
  fromLanguage?: string | undefined | null;
  isTranslated: boolean;
  isTranslating: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  iconSize?: number;
  labelStyle?: StyleProp<TextStyle>;
  attributionStyle?: StyleProp<ImageStyle>;
  theme?: 'light' | 'dark';
  onPress: () => void;
};

export default function TranslateButton(props: Props) {
  const { languageCode } = useLocales()[0];

  return props.fromLanguage && props.fromLanguage !== languageCode ? (
    <View style={props.containerStyle}>
      <Pressable
        disabled={props.isTranslating}
        onPress={props.onPress}
        style={({ pressed }) => [
          styles.buttonContainer,
          {
            opacity: pressed ? 0.5 : 1,
            backgroundColor: props.theme === 'dark' ? KEY_GRAY : KEY_LIGHT_GRAY,
          },
        ]}
      >
        <MaterialIcons
          name="g-translate"
          size={props.iconSize ?? 14}
          style={{
            marginRight: 4,
          }}
          color={
            props.theme === 'dark'
              ? 'white'
              : props.isTranslated
              ? KEY_GRAY
              : 'black'
          }
        />
        <Text
          style={[
            styles.text,
            {
              color: props.theme === 'dark' ? 'white' : KEY_GRAY,
            },
            props.labelStyle,
          ]}
        >
          {props.isTranslated ? 'SEE ORIGINAL' : 'TRANSLATE'}
        </Text>

        {props.isTranslating ? (
          <ActivityIndicator
            size={14}
            color={props.theme === 'dark' ? 'white' : 'black'}
            style={{
              marginLeft: 4,
            }}
          />
        ) : null}
      </Pressable>

      {props.isTranslated ? (
        <Image
          source={require('/assets/attribution/translated_by_google_gray.png')}
          style={[
            {
              width: 114,
              height: 15,
              marginBottom: 4,
            },
            props.attributionStyle,
          ]}
        />
      ) : null}
    </View>
  ) : null;
}

const styles = StyleSheet.create({
  buttonContainer: {
    backgroundColor: KEY_LIGHT_GRAY,
    borderRadius: 6,
    padding: 4,
    paddingHorizontal: 6,
    marginVertical: 4,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  attributionText: {
    color: 'gray',
    fontFamily: 'Lato',
    fontSize: 12,
    marginBottom: 4,
  },
  text: {
    color: KEY_GRAY,
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 15,
    paddingTop: 2,
  },
});
