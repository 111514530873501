import { Cache } from '@urql/exchange-graphcache';
import { TypedDocumentNode } from 'urql';

interface PaginatedFieldParams {
  cache: Cache;
  debug?: boolean;
  /** Default is `start`, which will append the new item at the start
   * of the list. */
  appendPosition?: 'start' | 'end';
  /** The entity containing the target paginated field */
  entity: any & {
    __typename: string;
    id?: string;
  };
  /** The paginated field to add item to */
  paginatedField: {
    name: string;
    fragment: TypedDocumentNode;
  };
  /** The item to add to the paginated field */
  newItem: any;
  /** Primary key(s) to check for duplicates, default is `id` */
  itemPrimaryKeys?: string[];
}

const addItemToPaginatedField = ({
  cache,
  entity,
  debug,
  paginatedField,
  newItem,
  appendPosition = 'start',
  itemPrimaryKeys = ['id'],
}: PaginatedFieldParams) => {
  const entityFields = cache.inspectFields(entity);

  const paginatedFields = entityFields.filter(
    (field) => field.fieldName === paginatedField.name,
  );

  const variableSets = paginatedFields.reduce((sets, field) => {
    const { nextToken, prevToken, ...rest } = (field.arguments || {}) as any;
    const key = JSON.stringify(rest);
    if (!sets[key]) {
      sets[key] = [];
    }
    sets[key].push(field);
    return sets;
  }, {} as any);

  if (debug) {
    console.log({ variableSets, paginatedFields });
  }

  for (let key in variableSets) {
    const fields = variableSets[key];

    fields.forEach((targetField: any) => {
      const paginatedData: any = cache.readFragment(
        paginatedField.fragment,
        entity,
        targetField.arguments,
      );

      if (debug) {
        console.log({ targetField, paginatedData });
      }

      const total =
        paginatedData?.[paginatedField.name]?.total !== undefined
          ? paginatedData[paginatedField.name].total + 1
          : undefined;

      if (paginatedData?.[paginatedField.name]?.items) {
        // Avoid duplicates
        const existingItem = paginatedData[paginatedField.name].items.find(
          (item: any) => {
            // Check primary keys
            return itemPrimaryKeys.every((k) => {
              return item[k] === newItem[k];
            });
          },
        );

        if (existingItem) {
          return;
        }

        let newItems = [...paginatedData[paginatedField.name].items];

        if (appendPosition === 'end') {
          newItems = [...newItems, newItem];
        } else {
          newItems = [newItem, ...newItems];
        }

        if (debug)
          console.log('writing', {
            ...entity,
            [paginatedField.name]: {
              ...paginatedData[paginatedField.name],
              items: newItems,
              ...(total ? { total } : {}),
            },
          });

        cache.writeFragment(
          paginatedField.fragment,
          {
            ...entity,
            [paginatedField.name]: {
              ...paginatedData[paginatedField.name],
              items: newItems,
              ...(total ? { total } : {}),
            },
          },
          targetField.arguments,
        );
      }
    });
  }
};

export default addItemToPaginatedField;
