import { Ionicons } from '@expo/vector-icons';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { forwardRef } from 'react';
import { PressableProps } from 'react-native';
import ActionSheetPressableCore, {
  ActionSheetPressableRef,
} from './ActionSheetPressableCore';
import Alert from '/Alert';
import { KEY_GREEN } from '/constants';
import { useAuthContext, useLoadingContext, useTeamContext } from '/context';
import {
  MomentInNature,
  TeamMemberRole,
  useDeleteMomentInNatureMutation,
} from '/generated/graphql';
import { isUnderPrivileged } from '/util';

export interface MomentInNatureActionSheetRef {
  /** Shows the action sheet */
  show: () => void;
}

interface Props {
  moment_in_nature:
    | Pick<MomentInNature, 'id' | 'userId' | 'caption'>
    | undefined
    | null;
  goBackAfterDelete?: boolean;
  style?: PressableProps['style'];
  disabled?: boolean;
}

export default forwardRef<
  ActionSheetPressableRef,
  React.PropsWithChildren<Props>
>(function MomentInNatureActionSheetPressable(
  props: React.PropsWithChildren<Props>,
  ref,
) {
  const { teams } = useTeamContext();

  const { userData, isAdmin } = useAuthContext();

  const { navigate, canGoBack, goBack } =
    useNavigation<StackNavigationProp<any>>();

  const linkTo = useLinkTo();

  const { setShowLoading, setLoadingInfo }: any = useLoadingContext();

  const [, deleteMomentInNature] = useDeleteMomentInNatureMutation();

  const isAuthorizedTeamMember = teams?.some(
    (team) =>
      team.user.id === props.moment_in_nature?.userId &&
      !isUnderPrivileged(TeamMemberRole.Creator, team.membership?.team_role),
  );

  const isMine =
    userData?.id === props.moment_in_nature?.userId || isAuthorizedTeamMember;

  const report = () => {
    const id = props.moment_in_nature?.id;

    if (!id) {
      console.warn(
        'MomentInNatureActionSheetPressable: `moment_in_nature` property missing or invalid - action canceled',
      );
      return;
    }

    navigate('CreateReport', {
      type: 'moment_in_nature',
      id: props.moment_in_nature?.id,
      data: {
        ...props.moment_in_nature,
        caption: props.moment_in_nature?.caption?.text,
      },
    });
  };

  const deletePost = async () => {
    const id = props.moment_in_nature?.id;

    if (!id) {
      console.warn(
        'MomentInNatureActionSheetPressable: `moment_in_nature` property missing or invalid - action canceled',
      );
      return;
    }

    setShowLoading(true);
    setLoadingInfo('Deleting moment in nature...');

    const { error } = await deleteMomentInNature({ postId: id });

    setShowLoading(false);

    if (error) {
      Alert.alert('Error', 'Something went wrong. Please try again later.');
      return;
    }

    Alert.notify({
      message: 'Post deleted!',
      color: KEY_GREEN,
    });

    if (props.goBackAfterDelete) {
      if (canGoBack()) {
        goBack();
      } else {
        linkTo('/');
      }
    }
  };

  const editPost = () => {
    const id = props.moment_in_nature?.id;

    if (!id) {
      console.warn(
        'MomentInNatureActionSheetPressable: `moment_in_nature` property missing or invalid - action canceled',
      );
      return;
    }

    navigate('EditMomentInNature', { id });
  };

  const generateActionSheetOptions = () => {
    const options: string[] = [];

    const labels = {
      delete: 'Delete',
      edit: 'Edit',
      report: 'Report',
      // share: 'Share...',
      cancel: 'Cancel',
    };

    if (isMine || isAdmin) {
      options.push(labels.delete);
    }
    if (isMine) {
      options.push(labels.edit);
    }
    if (!isMine && !!userData?.id) options.push(labels.report);
    options.push(labels.cancel);

    const cancelButtonIndex = options.length - 1;
    const destructiveButtonIndex = !userData?.id ? undefined : 0;

    return {
      title: isAdmin ? 'Actions (Admin)' : 'Actions',
      options,
      cancelButtonIndex,
      destructiveButtonIndex,
      /** Web and Android icons */
      icons: options.map((option) => {
        switch (option) {
          case labels.delete: {
            return <Ionicons name="trash-bin-outline" />;
          }
          case labels.edit: {
            return <Ionicons name="pencil-outline" />;
          }
          case labels.report: {
            return <Ionicons name="alert-circle-outline" />;
          }
          default:
            return undefined;
        }
      }),
      onPress: (index: number | undefined) => {
        if (index === undefined) return;

        switch (options[index]) {
          case labels.delete: {
            Alert.alert(
              'Delete Post',
              'Are you sure you want to delete this post?',
              [
                {
                  text: 'Cancel',
                  style: 'cancel',
                },
                {
                  text: 'Delete',
                  style: 'destructive',
                  onPress: deletePost,
                },
              ],
            );
            break;
          }
          case labels.edit: {
            editPost();
            break;
          }
          case labels.report: {
            report();
            break;
          }
          default:
            break;
        }
      },
    };
  };

  const actionSheetOptions = generateActionSheetOptions();

  return (
    <ActionSheetPressableCore
      disabled={props.disabled}
      options={actionSheetOptions}
      style={props.style}
      ref={ref}
    >
      {props.children}
    </ActionSheetPressableCore>
  );
});
