import { UpdateResolver } from '@urql/exchange-graphcache';

const archiveResearchTopic: UpdateResolver = (result, args, cache) => {
  cache.invalidate({
    __typename: 'ResearchTopic',
    topic: (result.archiveResearchTopic as any)?.topic,
  });
};

export default archiveResearchTopic;
