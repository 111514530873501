/**
 * Render a pending TeamMember request initiated by a supporter
 */

import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import dayjs from 'dayjs';
import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import Alert from '/Alert';
import Avatar from '/components/Avatar';
import Button from '/components/Button';
import { KEY_GREEN, KEY_YELLOW } from '/constants';
import {
  TeamMember,
  TeamMembershipStatus,
  useLeaveTeamMutation,
  User,
  useRemoveTeamMemberMutation,
} from '/generated/graphql';

interface IUser extends Pick<User, 'id' | 'name' | 'profile_image'> {}

interface ITeam {
  user: IUser | null;
}

interface ITeamMember
  extends Pick<
    TeamMember,
    'membership_status' | 'userId' | 'teamId' | 'requested_at'
  > {
  initiator: IUser | null;
  user: IUser | null;
  team: ITeam | null;
}

interface Props {
  request: Partial<ITeamMember>;
  avatarSize?: number;
  viewingAsSupporter: boolean;
}

export default function PendingTeamMemberRequest(props: Props) {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const [, removeTeamMember] = useRemoveTeamMemberMutation();
  const [, leaveTeam] = useLeaveTeamMutation();

  function onInviteMember(userId: string) {
    navigate('InviteTeamMember', {
      userId,
      teamId: props.request.teamId,
      existingMembership: true,
    });
  }

  function onDelete(userOrTeamId: string) {
    const remove = async () => {
      try {
        const { error } = !props.viewingAsSupporter
          ? await removeTeamMember({
              userId: userOrTeamId,
              teamId: props.request.teamId,
            })
          : await leaveTeam({ teamId: userOrTeamId });

        if (error) throw error;

        Alert.notify({ message: 'Successfully deleted', color: KEY_GREEN });
      } catch (error) {
        console.log('Failed to remove membership', error);
        Alert.notify({
          message: `Failed to delete request`,
          color: 'crimson',
          textColor: 'white',
        });
      }
    };

    Alert.alert(
      `Delete request`,
      `Are you sure you want to delete this request?`,
      [
        {
          text: 'Delete',
          style: 'destructive',
          onPress: remove,
        },
        {
          style: 'cancel',
        },
      ],
    );
  }

  /** Considering that "requests" are initiated by supporters, decide which user's name and avatar to render */
  const user = props.viewingAsSupporter
    ? props.request.team?.user
    : props.request.initiator;

  return (
    <View key={user?.id} style={styles.memberCard}>
      <Pressable onPress={() => navigate('Profile', { id: user?.id })}>
        <Avatar
          source={{ uri: user?.profile_image ?? '' }}
          size={props.avatarSize ?? 48}
          rounded
        />
      </Pressable>
      <View style={styles.cardTextSection}>
        <Text style={styles.cardTitle}>{user?.name}</Text>
        <Text style={styles.cardSubtitle}>
          Requested {dayjs(props.request.requested_at).fromNow()}
        </Text>
      </View>
      {props.request.membership_status === TeamMembershipStatus.Confirmed ? (
        <View pointerEvents="none">
          <Button label="Confirmed" style={{ backgroundColor: KEY_YELLOW }} />
        </View>
      ) : (
        <>
          {!props.viewingAsSupporter ? (
            <Button
              label="Add Member"
              style={styles.cardButtonStyle}
              onPress={() =>
                props.request.userId && onInviteMember(props.request.userId)
              }
            />
          ) : null}
          <Button
            label={<FontAwesome name="trash-o" size={24} color="black" />}
            style={styles.cardButtonStyle}
            containerStyle={{
              marginLeft: 6,
            }}
            onPress={() =>
              onDelete(
                !props.viewingAsSupporter
                  ? props.request.userId!
                  : props.request.teamId!,
              )
            }
          />
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  memberCard: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
  },
  cardTextSection: {
    flex: 1,
    padding: 8,
  },
  cardTitle: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
  },
  cardSubtitle: {
    fontFamily: 'Lato',
    color: 'gray',
    fontSize: 15,
  },
  cardButtonStyle: {
    height: 40,
  },
});
