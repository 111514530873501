import { Avatar } from '@rneui/base';
import React from 'react';
import {
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import { KEY_GRAY } from '/constants';
import { TeamMember, TeamMemberRole, User } from '/generated/graphql';

interface ITeamMember extends Pick<TeamMember, 'title'> {
  user: Pick<User, 'id' | 'name' | 'profile_image'>;
  team_role?: TeamMemberRole | undefined | null;
}

interface Props {
  member: ITeamMember;
  onPress?: () => void;
  containerStyle?: StyleProp<ViewStyle>;
  avatarSize?: number;
}

export default function TeamMemberCard(props: Props) {
  return (
    <Pressable
      onPress={props.onPress}
      style={[styles.memberCard, props.containerStyle]}
    >
      <Avatar
        source={{ uri: props.member.user.profile_image ?? '' }}
        size={props.avatarSize ?? 112}
        rounded
      />
      <View
        style={{
          paddingHorizontal: 3,
        }}
      >
        <Text numberOfLines={3} style={styles.memberCardName}>
          {props.member.user.name.toLocaleUpperCase()}
        </Text>
        {props.member.title ? (
          <Text numberOfLines={3} style={styles.memberCardTitle}>
            {props.member.title}
          </Text>
        ) : null}
        {props.member.team_role ? (
          <Text numberOfLines={1} style={styles.memberCardRole}>
            {getRoleDescriptionFromEnumValue(props.member.team_role)}
          </Text>
        ) : null}
      </View>
    </Pressable>
  );
}

function getRoleDescriptionFromEnumValue(enumValue: TeamMemberRole) {
  switch (enumValue) {
    case TeamMemberRole.Creator: {
      return 'Creator';
    }
    case TeamMemberRole.Admin: {
      return 'Administrator';
    }
    default:
    case TeamMemberRole.Member: {
      return 'No Permissions';
    }
  }
}

const styles = StyleSheet.create({
  memberCard: {
    alignSelf: 'center',
    alignItems: 'center',
    width: 120,
    flex: 1,
  },
  memberCardName: {
    marginTop: 5,
    textAlign: 'center',
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 17,
  },
  memberCardTitle: {
    marginVertical: 1,
    color: KEY_GRAY,
    textAlign: 'center',
    fontSize: 17,
    fontFamily: 'Lato',
  },
  memberCardRole: {
    fontFamily: 'Lato',
    fontSize: 15,
    color: 'gray',
    textAlign: 'center',
    textTransform: 'capitalize',
  },
});
