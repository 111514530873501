import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  horizontalContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  statusText: {
    fontSize: 16,
    fontFamily: 'Lato-Bold',
    marginLeft: 4,
  },
});
