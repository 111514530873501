import { UpdateResolver } from '@urql/exchange-graphcache';

const archiveKeyBulletinPost: UpdateResolver = (result, args, cache) => {
  cache.invalidate({
    __typename: 'KeyBulletinPost',
    id: args.id as string,
  });
};

export default archiveKeyBulletinPost;
