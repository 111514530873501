import { UpdateResolver } from '@urql/exchange-graphcache';

const deleteGroupDiscussionPost: UpdateResolver = (result, args, cache) => {
  const postId = (args as any).groupDiscussionPostId;

  if (!postId) return;

  cache.invalidate({
    __typename: 'GroupDiscussionPost',
    id: postId,
  });
};

export default deleteGroupDiscussionPost;
