import { StyleSheet } from 'react-native';
import { KEY_GREEN } from '/constants';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  background: {
    flex: 1,
    alignItems: 'center',
  },
  header: {
    paddingTop: 16,
    paddingBottom: 16,
    top: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'rgba(0,0,0,0.3)',
    zIndex: 99,
    position: 'absolute',
  },
  backButton: {
    paddingLeft: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  btnText: {
    paddingLeft: 4,
    color: 'white',
    fontSize: 16,
  },
  title: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 21,
  },
  headerLeft: {
    flex: 1,
    paddingHorizontal: 8,
  },
  headerRight: {
    flex: 1,
    paddingHorizontal: 8,
  },
  content: {
    alignSelf: 'center',
    paddingTop: 128,
    width: '100%',
    maxWidth: 500,
    padding: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
  centerContent: {
    justifyContent: 'center',
  },
  buttonContainer: {
    backgroundColor: KEY_GREEN,
    paddingVertical: 14,
    justifyContent: 'center',
    width: '100%',
    marginBottom: '7%',
  },
  button: {
    fontFamily: 'Lato-Bold',
    textAlign: 'center',
    color: 'black',
    fontSize: 18,
  },
  backbutton: {
    fontFamily: 'Lato-Bold',
    fontStyle: 'normal',
    textAlign: 'center',
    color: 'black',
    opacity: 0.7,
    fontSize: 18,
  },
  aboutIconContainer: {
    position: 'absolute',
    left: 16,
    bottom: 16,
    zIndex: 99,
  },
  aboutIconContainerHidden: {
    position: 'absolute',
    left: 16,
    bottom: 16,
    zIndex: 99,
    display: 'none',
  },
  aboutIconTouch: {
    alignSelf: 'flex-start',
    padding: 10,
    width: 60,
    height: 60,
  },
  contentWrapper: {
    flex: 1,
  },
});
