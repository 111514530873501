/**
 * Render a pending TeamMember request initiated by an organization
 */

import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import dayjs from 'dayjs';
import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import Alert from '/Alert';
import Avatar from '/components/Avatar';
import Button from '/components/Button';
import { KEY_GREEN, KEY_YELLOW } from '/constants';
import {
  TeamMember,
  TeamMemberRole,
  TeamMembershipStatus,
  useJoinTeamMutation,
  useLeaveTeamMutation,
  User,
  useRemoveTeamMemberMutation,
} from '/generated/graphql';

interface ITeamMember
  extends Pick<
    TeamMember,
    'membership_status' | 'userId' | 'teamId' | 'requested_at' | 'team_role'
  > {
  initiator: Pick<User, 'id' | 'name' | 'profile_image'> | null;
  user: Pick<User, 'id' | 'name' | 'profile_image'> | null;
}

interface Props {
  invite: Partial<ITeamMember>;
  avatarSize?: number;
  viewingAsOrg: boolean;
}

export default function PendingTeamMemberInvite(props: Props) {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const [, removeTeamMember] = useRemoveTeamMemberMutation();
  const [, leaveTeam] = useLeaveTeamMutation();

  function onEditMembership(userId: string, teamId: string) {
    navigate('EditTeamMember', {
      userId,
      teamId,
    });
  }

  const [{ fetching: joining }, joinTeam] = useJoinTeamMutation();

  async function onJoinTeam(teamId: string) {
    try {
      const { error } = await joinTeam({ teamId });

      if (error) throw error;

      Alert.notify({
        message: 'Successfully joined team',
        color: KEY_GREEN,
      });
    } catch (error) {
      console.log('Failed to join team', error);
      Alert.notify({
        message: 'Failed to join team',
        color: 'crimson',
        textColor: 'white',
      });
    }
  }

  function onDelete(userOrTeamId: string) {
    const remove = async () => {
      try {
        const { error } = props.viewingAsOrg
          ? await removeTeamMember({
              userId: userOrTeamId,
              teamId: props.invite.teamId,
            })
          : await leaveTeam({ teamId: userOrTeamId });

        if (error) throw error;

        Alert.notify({ message: 'Successfully deleted', color: KEY_GREEN });
      } catch (error) {
        console.log('Failed to remove membership', error);
        Alert.notify({
          message: `Failed to delete invite`,
          color: 'crimson',
          textColor: 'white',
        });
      }
    };

    Alert.alert(
      `Delete invite`,
      `Are you sure you want to delete this invite?`,
      [
        {
          text: 'Delete',
          style: 'destructive',
          onPress: remove,
        },
        {
          style: 'cancel',
        },
      ],
    );
  }

  let role;

  switch (props.invite.team_role) {
    case TeamMemberRole.Creator: {
      role = 'Creator';
      break;
    }
    case TeamMemberRole.Admin: {
      role = 'Administrator';
      break;
    }
    default:
    case TeamMemberRole.Member: {
      role = 'Member';
    }
  }

  const user = props.viewingAsOrg ? props.invite.user : props.invite.initiator;

  return (
    <View key={user?.id} style={styles.memberCard}>
      <Pressable onPress={() => navigate('Profile', { id: user?.id })}>
        <Avatar
          source={{ uri: user?.profile_image ?? '' }}
          size={props.avatarSize ?? 48}
          rounded
        />
      </Pressable>
      <View style={styles.cardTextSection}>
        <Text style={styles.cardTitle}>{user?.name}</Text>
        <Text style={styles.cardSubtitle}>{role}</Text>
        <Text style={styles.cardSubtitle}>
          Sent {dayjs(props.invite.requested_at).fromNow()}
        </Text>
      </View>
      {props.invite.membership_status === TeamMembershipStatus.Confirmed ? (
        // If status is confirmed, then it must be because we just confirmed it
        <View pointerEvents="none">
          <Button
            label="Confirmed"
            style={[styles.cardButtonStyle, { backgroundColor: KEY_YELLOW }]}
          />
        </View>
      ) : (
        <>
          {props.viewingAsOrg ? (
            <Button
              label="Edit"
              style={styles.cardButtonStyle}
              onPress={() =>
                onEditMembership(props.invite.userId!, props.invite.teamId!)
              }
            />
          ) : (
            <Button
              label="Join"
              loading={joining}
              style={[styles.cardButtonStyle, { backgroundColor: KEY_GREEN }]}
              onPress={() => onJoinTeam(props.invite.teamId!)}
            />
          )}

          <Button
            containerStyle={{
              marginLeft: 6,
            }}
            style={styles.cardButtonStyle}
            label={<FontAwesome name="trash-o" size={24} color="black" />}
            onPress={() =>
              onDelete(
                props.viewingAsOrg
                  ? props.invite.userId!
                  : props.invite.teamId!,
              )
            }
          />
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  memberCard: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
  },
  cardTextSection: {
    flex: 1,
    padding: 8,
  },
  cardTitle: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
  },
  cardSubtitle: {
    fontFamily: 'Lato',
    color: 'gray',
    fontSize: 15,
  },
  cardButtonStyle: {
    height: 40,
  },
});
