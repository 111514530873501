import { Skeleton } from '@rneui/base';
import { LinearGradient } from 'expo-linear-gradient';
import _ from 'lodash';
import React from 'react';
import { useWindowDimensions, View } from 'react-native';

export default function MomentInNatureCarouselSkeleton() {
  const windowDimensions = useWindowDimensions();

  return (
    <View
      style={{
        width: '100%',
        marginVertical: 8,
        flexDirection: 'row',
        overflow: 'hidden',
      }}
    >
      {_.times(3, (num) => num).map((__, index) => (
        <View
          key={index}
          style={{
            width: Math.min(windowDimensions.width - 80, 420),
            marginRight: 8,
            overflow: 'hidden',
          }}
        >
          <Skeleton
            animation="wave"
            LinearGradientComponent={LinearGradient}
            style={{
              width: '100%',
              height: 560,
              marginBottom: 8,
              borderRadius: 6,
            }}
          />
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              flex: 1,
              width: '100%',
              padding: 8,
            }}
          >
            <Skeleton
              animation="wave"
              LinearGradientComponent={LinearGradient}
              style={{
                borderRadius: 40,
                width: 48,
                height: 48,
              }}
            />
            <View
              style={{
                flex: 1,
                marginLeft: 6,
              }}
            >
              <Skeleton
                animation="wave"
                LinearGradientComponent={LinearGradient}
                style={{
                  width: '100%',
                  height: 24,
                  marginBottom: 10,
                }}
              />
              <Skeleton
                animation="wave"
                LinearGradientComponent={LinearGradient}
                style={{
                  width: '100%',
                  height: 16,
                }}
              />
            </View>
          </View>
        </View>
      ))}
    </View>
  );
}
