import React from 'react';
import { Text, View, ViewStyle } from 'react-native';

import styles from '/constants/Profile/CampaignBlankSpace';

import KeyIconGray from '/assets/jsicons/KeyCon/KeyIconGray';

const CampaignBlankSpace = (props: {
  placeholderText?: string;
  style?: ViewStyle;
  tintColor?: string;
}) => {
  return (
    <View style={[styles.container, props.style]}>
      <View style={styles.plusIcon}>
        <KeyIconGray fill={props.tintColor} />
        <Text
          style={[
            styles.text,
            props.tintColor
              ? {
                  color: props.tintColor,
                }
              : undefined,
          ]}
        >
          {props.placeholderText || 'Nothing to see here'}
        </Text>
      </View>
    </View>
  );
};

export default CampaignBlankSpace;
