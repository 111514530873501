import { useEffect, useRef, useState } from 'react';
import getCDNVideoUri from '/util/getCDNVideoUri';
import { determineIfVideo } from '/util';

type UseCDNVideoSourceOptions = {
  uri: string | undefined | null;
  disableFallbackToOriginal?: boolean;
};

type UseCDNVideoSourceReturns = {
  source: { uri: string };
  fetching: boolean;
  error: Error | undefined;
};

export default function useCDNVideoSource({
  uri,
  disableFallbackToOriginal = false,
}: UseCDNVideoSourceOptions): UseCDNVideoSourceReturns {
  const [source, setSource] = useState<UseCDNVideoSourceReturns['source']>({
    uri: '',
  });
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState<Error | undefined>();

  const prevUriRef = useRef<string | undefined>(undefined);

  // If the URI changes, reset the source and error
  useEffect(() => {
    if (uri !== prevUriRef.current) {
      setSource({ uri: '' });
      setError(undefined);
    }

    prevUriRef.current = uri || undefined;
  }, [uri]);

  useEffect(() => {
    let abort = false;

    const fetchFinalURL = async () => {
      if (!uri) {
        setSource({ uri: '' });
        setError(new Error('No video source'));
        return;
      }

      setFetching(true);
      setError(undefined);

      try {
        // If the URI is not a video, just set the source to the URI
        if (!determineIfVideo(uri)) {
          setSource({ uri: uri });
          return;
        }

        const cdnUri = getCDNVideoUri({
          uri,
          preset: 'hls',
        });

        if (cdnUri === uri || !cdnUri) {
          setSource({ uri: uri });
          return;
        }

        const finalUri = await getFinalURL(cdnUri);

        if (!abort) {
          setSource({ uri: finalUri });
          setError(undefined);
        }
      } catch (err) {
        if (!abort) {
          setError(err as Error);
          if (!disableFallbackToOriginal) {
            setSource({ uri: uri });
          }
        }
      } finally {
        if (!abort) {
          setFetching(false);
        }
      }
    };

    fetchFinalURL();

    return () => {
      abort = true;
    };
  }, [uri, disableFallbackToOriginal]);

  return { source, fetching, error };
}

/** Makes a request with the goal of getting the final URL it redirects to */
const getFinalURL = async (url: string) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: { Range: 'bytes=0-255' },
    });
    return response.url;
  } catch (error) {
    console.error('Error fetching final URL:', error);
    throw error;
  }
};
