import { View, Text, Pressable } from 'react-native';
import React from 'react';
import styles from './KeyBulletinPostPreview.style';
import { KeyBulletinPost } from '/generated/graphql';
import { shorten } from '/util';
import ChevronRight from '/assets/jsicons/miscIcons/ChevronRight';
import dayjs from 'dayjs';

interface Props {
  data: Pick<KeyBulletinPost, 'id' | 'title' | 'body' | 'created_at'>;
  onPress: () => void;
}

export default function KeyBulletinPostPreview(props: Props) {
  return (
    <Pressable
      onPress={props.onPress}
      style={({ pressed }) => [
        styles.container,
        {
          backgroundColor: pressed ? '#F2F2F2' : 'white',
        },
      ]}
    >
      <View style={{ flex: 1 }}>
        <Text style={styles.title} numberOfLines={2}>
          {shorten(props.data.title, 90)}
        </Text>
        <Text style={styles.bodyPreview} numberOfLines={3}>
          {shorten(props.data.body, 160)}
        </Text>
        {/* <Text
          style={[
            styles.bodyPreview,
            {
              marginTop: 2,
            },
          ]}
        >
          {dayjs(Number(props.data.created_at)).fromNow()}
        </Text> */}
      </View>
      <ChevronRight />
    </Pressable>
  );
}
