import { FontAwesome } from '@expo/vector-icons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ActivityIndicator,
  Animated,
  ColorValue,
  Easing,
  LayoutChangeEvent,
  Pressable,
  Text,
} from 'react-native';
import { ALERT_RED, ALERT_YELLOW, KEY_GRAY, KEY_GREEN } from '/constants';
import { ViewStyle } from 'react-native';
import { SmallAlertAwaitPromise } from '/context/AlertProvider';

type AnimationDirection = 'slide-down' | 'slide-up';

interface ISmallAlertProps {
  message: string;
  /** Default is 4000 milliseconds. If set to 0, alert will not close until user manually does so */
  closeAfterDuration?: number;
  /** Set custom background color for alert */
  color?: ColorValue;
  /** Set custom color for alert text */
  textColor?: ColorValue;
  /** Default is `slide-down` */
  animationDirection?: AnimationDirection;
  /** Optionally control alert visibility. Default is `true` */
  visible?: boolean;
  style?: ViewStyle;
  onClose?: () => void;
  /** If provided, the alert will not close until `closeAfterDuration`ms after the promise resolves */
  awaitPromise?: SmallAlertAwaitPromise;
  onLayout?: (event: LayoutChangeEvent) => void;
}

export default function SmallAlert({
  onClose: props_onClose,
  ...props
}: ISmallAlertProps) {
  const [promiseStatus, setPromiseStatus] = useState<
    'pending' | 'success' | 'error'
  >('pending');

  const animationDirection = props.animationDirection ?? 'slide-down';

  const animation = useRef(new Animated.Value(0));

  const animate = (toValue: number, duration: number, callback?: () => void) =>
    Animated.timing(animation.current, {
      toValue,
      duration,
      easing: Easing.out(Easing.poly(4)),
      useNativeDriver: true,
    }).start(callback);

  const onClose = useCallback(() => {
    animate(0, 220, () => {
      props_onClose?.();
    });
  }, [props_onClose]);

  const isAnimatedVisible = useRef(false);
  useEffect(() => {
    // Track whether promise is pending
    if (props.awaitPromise) {
      setPromiseStatus('pending');
      props.awaitPromise.promise
        .then(() => setPromiseStatus('success'))
        .catch(() => setPromiseStatus('error'));
    }

    if (props.visible === true || props.visible === undefined) {
      if (isAnimatedVisible.current) return;
      isAnimatedVisible.current = true;

      const startClose = () => {
        setTimeout(onClose, props.closeAfterDuration ?? 4000);
      };

      animate(1, 180, () => {
        // Close automatically after set duration (unless duration is explicitly set to 0)
        if (props.closeAfterDuration === 0) return;
        if (props.awaitPromise) props.awaitPromise.promise.then(startClose);
        else startClose();
      });
    } else {
      if (!isAnimatedVisible.current) return;
      isAnimatedVisible.current = false;
      animate(0, 220);
    }
  }, [onClose, props.awaitPromise, props.closeAfterDuration, props.visible]);

  const multiplier = animationDirection === 'slide-down' ? -1 : 1;

  const translateY = animation.current.interpolate({
    inputRange: [0, 1],
    outputRange: [50 * multiplier, 0],
  });

  const opacity = animation.current.interpolate({
    inputRange: [0, 0.2, 1],
    outputRange: [0, 1, 1],
  });

  const alertMessage = (() => {
    if (!props.awaitPromise) return props.message;

    switch (promiseStatus) {
      case 'success':
        return props.awaitPromise.successMessage || props.message;
      case 'error':
        return props.awaitPromise.errorMessage || props.message;
      case 'pending':
      default:
        return props.message;
    }
  })();

  const textColor = (() => {
    if (!props.awaitPromise) return props.textColor || KEY_GRAY;

    switch (promiseStatus) {
      case 'pending':
        return KEY_GRAY;
      case 'success':
        return props.awaitPromise.successTextColor || 'black';
      case 'error':
        return props.awaitPromise.errorTextColor || 'white';
    }
  })();

  const backgroundColor = (() => {
    if (!props.awaitPromise) return props.color || ALERT_YELLOW;

    switch (promiseStatus) {
      case 'pending':
        return ALERT_YELLOW;
      case 'success':
        return props.awaitPromise.successColor || KEY_GREEN;
      case 'error':
        return props.awaitPromise.errorColor || ALERT_RED;
    }
  })() as ColorValue;

  return (
    <Animated.View
      style={[
        {
          pointerEvents: props.visible !== false ? 'auto' : 'none',
          flex: 1,
          position: 'absolute',
          left: 10,
          right: 10,
          padding: 10,
          borderRadius: 6,
          top: 10,
          backgroundColor: backgroundColor,
          transform: [{ translateY }],
          opacity,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
          maxWidth: 600,
          zIndex: 999,
          borderWidth: 1,
          borderColor: 'rgba(128, 128, 128, 0.1)',
          elevation: 999,
          shadowColor: 'lightgray',
          shadowOpacity: 0.1,
          shadowRadius: 2,
          shadowOffset: {
            width: 0,
            height: 0,
          },
        },
        props.style,
      ]}
      onLayout={props.onLayout}
    >
      <Text
        style={{
          flex: 1,
          fontFamily: 'Lato-Bold',
          color: textColor,
          fontSize: 16,
          textAlign: 'center',
        }}
      >
        {alertMessage}
      </Text>
      {props.awaitPromise && promiseStatus === 'pending' ? (
        <ActivityIndicator
          style={{
            padding: 8,
          }}
          size={20}
          color={textColor}
        />
      ) : (
        <Pressable
          style={{
            padding: 8,
          }}
          onPress={onClose}
        >
          <FontAwesome name="close" color={textColor} size={20} />
        </Pressable>
      )}
    </Animated.View>
  );
}
