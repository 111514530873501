const [
  africanwilddog,
  easternmountainbongo,
  greatgreenmacaw,
  kakapo,
  pangolin,
  radiatedtortoise,
  whaleshark,
  sumatranrhinoceros,
  brazilianriverfrog,
] = [
  'https://keyconservation.s3.us-west-1.amazonaws.com/africanwilddog.png',
  'https://keyconservation.s3.us-west-1.amazonaws.com/bongo.png',
  'https://keyconservation.s3.us-west-1.amazonaws.com/greatgreen.png',
  'https://keyconservation.s3.us-west-1.amazonaws.com/kakapo.png',
  'https://keyconservation.s3.us-west-1.amazonaws.com/pangolin.png',
  'https://keyconservation.s3.us-west-1.amazonaws.com/tortoise.png',
  'https://keyconservation.s3.us-west-1.amazonaws.com/whaleshark.png',
  'https://keyconservation.s3.us-west-1.amazonaws.com/sumatranrhinoceros.png',
  'https://keyconservation.s3.us-west-1.amazonaws.com/brazilianriverfrog_small.webp',
];

const animalData = [
  {
    id: 1,
    image: kakapo,
    name: 'Kakapo',
    link: 'https://www.iucnredlist.org/species/22685245/129751169',
    photoCred: 'Paddy Ryan',
  },
  {
    id: 2,
    image: pangolin,
    name: 'Pangolin',
    link: 'https://www.iucnredlist.org/species/12766/123586126',
    photoCred: 'Nigel Dennis',
  },
  {
    id: 3,
    image: whaleshark,
    name: 'Whale Shark',
    link: 'https://www.iucnredlist.org/species/19488/2365291',
    photoCred: 'Doug Perrine',
  },
  {
    id: 4,
    image: brazilianriverfrog,
    name: 'Brazilian River Frog',
    link: 'https://www.projetodots.org/thoropa-saxatilis',
    photoCred: 'Pedro Peloso',
  },
  {
    id: 5,
    image: sumatranrhinoceros,
    name: 'Sumatran Rhinoceros',
    link: 'https://www.iucnredlist.org/species/6553/12787457',
    photoCred: 'Cyril Ruoso',
  },
  {
    id: 6,
    image: radiatedtortoise,
    name: 'Radiated Tortoise',
    link: 'https://www.iucnredlist.org/species/9014/12950491',
    photoCred: 'Edwin Giesbers',
  },
  {
    id: 7,
    image: greatgreenmacaw,
    name: 'Great Green Macaw',
    link: 'https://www.iucnredlist.org/species/22685553/93079606',
    photoCred: 'Edwin Giesbers',
  },
  {
    id: 8,
    image: easternmountainbongo,
    name: 'Eastern Mountain Bongo',
    link: 'https://www.iucnredlist.org/species/22057/50197212',
    photoCred: 'Mark MacEwen',
  },
  {
    id: 9,
    image: africanwilddog,
    name: 'African Wild Dog',
    link: 'https://www.iucnredlist.org/species/12436/16711116',
    photoCred: 'Glenn Nagel',
  },
];

export default animalData;
