import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import Button from '/components/Button';
import withAdminAccess from '/components/withAdminAccess';
import {
  CARD_TITLE_FONT_SIZE,
  CONTENT_PARAGRAPH_FONT_SIZE,
  KEY_GRAY,
  SECTION_CONTAINER,
} from '/constants';
import {
  useSearchResearchTopicsQuery,
  useSearchSkillsQuery,
} from '/generated/graphql';

interface Props {
  navigation: StackNavigationProp<any>;
}

function Configuration(props: Props) {
  const [{ data: skillsData, fetching: fetchingSkillsData }] =
    useSearchSkillsQuery({
      variables: {
        limit: 0,
        query: '',
      },
    });

  const [{ data: researchTopicsData, fetching: fetchingResearchTopics }] =
    useSearchResearchTopicsQuery({
      variables: {
        limit: 0,
        query: '',
      },
    });

  function onConfigureSkills() {
    props.navigation.navigate('ConfigureSkills');
  }

  function onConfigureResearchTopics() {
    props.navigation.navigate('ConfigureResearchTopics');
  }

  return (
    <View>
      {/* SKILLS */}
      <View style={styles.sectionContainer}>
        <View style={{ flex: 1 }}>
          <Text style={styles.titleText}>SKILLS</Text>
          {fetchingSkillsData ? (
            <ActivityIndicator
              size="small"
              color={KEY_GRAY}
              style={{ padding: 4 }}
            />
          ) : (
            <Text style={styles.text}>
              {skillsData?.searchSkills.total} skills configured
            </Text>
          )}
        </View>
        <View>
          <Button label="Configure" onPress={onConfigureSkills} />
        </View>
      </View>

      {/* RESEARCH TOPICS */}
      <View style={styles.sectionContainer}>
        <View style={{ flex: 1 }}>
          <Text style={styles.titleText}>RESEARCH TOPICS</Text>
          {fetchingResearchTopics ? (
            <ActivityIndicator
              size="small"
              color={KEY_GRAY}
              style={{ padding: 4 }}
            />
          ) : (
            <Text style={styles.text}>
              {researchTopicsData?.searchResearchTopics.total} research topics
              configured
            </Text>
          )}
        </View>
        <View>
          <Button label="Configure" onPress={onConfigureResearchTopics} />
        </View>
      </View>
    </View>
  );
}

export default withAdminAccess(Configuration);

const styles = StyleSheet.create({
  sectionContainer: {
    ...SECTION_CONTAINER,
    flexDirection: 'row',
  },
  titleText: {
    fontFamily: 'Lato-Bold',
    fontSize: CARD_TITLE_FONT_SIZE,
  },
  text: {
    fontFamily: 'Lato',
    fontSize: CONTENT_PARAGRAPH_FONT_SIZE,
  },
});
