import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';
import addItemToPaginatedField from '/util/cache/addItemToPaginatedField';

const fragment = gql`
  fragment NewDirectMessageGetDirectConversation on Query {
    getDirectConversation(
      userId: $userId
      limit: $limit
      nextToken: $nextToken
    ) {
      total
      items {
        id
        read_at
        created_at
        senderId
        receiverId
        body_translatable {
          id
          text
          language
        }
        sender {
          id
          name
          profile_image
        }
      }
    }
  }
`;

const newDirectMessage: UpdateResolver = async (result, args, cache) => {
  const data = cache.readFragment(
    fragment,
    {
      __typename: 'Query',
      id: null,
    },
    {
      userId: args.userId,
    },
  ) as any;

  if ((result.newDirectMessage as any)?.senderId !== args.userId) {
    return;
  }

  const existingMessage = data?.getDirectConversation?.items?.find(
    (item: any) => item.id === (result.newDirectMessage as any)?.id,
  );

  if (!existingMessage) {
    /** Add item to response used in the chat screen */
    addItemToPaginatedField({
      cache,
      entity: {
        __typename: 'Query',
        id: null,
      },
      newItem: result.newDirectMessage,
      paginatedField: {
        name: 'getDirectConversation',
        fragment,
      },
    });
  }

  /** Add item to response used in the inbox screen */
  cache.link(
    {
      __typename: 'DirectConversation',
      user1Id: (result?.newDirectMessage as any)?.senderId,
      user2Id: (result?.newDirectMessage as any)?.receiverId,
    },
    'latest_message',
    result.newDirectMessage as any,
  );
  // The other way around as well for redundancy
  cache.link(
    {
      __typename: 'DirectConversation',
      user2Id: (result?.newDirectMessage as any)?.senderId,
      user1Id: (result?.newDirectMessage as any)?.receiverId,
    },
    'latest_message',
    result.newDirectMessage as any,
  );
};

export default newDirectMessage;
