import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import Button from '/components/Button';

type Props = {
  projectId: string;
};

export default function CreativeConnectProjectJoinRequestCTA(props: Props) {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  function onView() {
    if (props.projectId) {
      navigate('ListCreativeConnectCollaborators', {
        id: props.projectId,
        confirmed: false,
        collaborator_initiated: true,
      });
    }
  }

  return props.projectId ? (
    <Button label="View Requests" onPress={onView} />
  ) : null;
}
