import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import ProgressiveImage, { ProgressiveImageProps } from './ProgressiveImage';

interface ProgressiveImageBackgroundProps extends ProgressiveImageProps {
  overlayStyle?: StyleProp<ViewStyle>;
}

export default function ProgressiveImageBackground({
  children,
  overlayStyle,
  ...props
}: React.PropsWithChildren<ProgressiveImageBackgroundProps>) {
  return (
    <>
      <ProgressiveImage
        {...props}
        containerStyle={[StyleSheet.absoluteFill, props.containerStyle]}
        style={[{ width: '100%', height: '100%', zIndex: -1 }, props.style]}
      />
      <View
        style={[
          StyleSheet.absoluteFill,
          overlayStyle,
          {
            pointerEvents: 'none',
          },
        ]}
      />
      {children}
    </>
  );
}
