/**
 * Component for rendering images progressively from low-quality, to high quality
 *
 */

import { BlurView } from 'expo-blur';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ImageSourcePropType,
  StyleProp,
  StyleSheet,
  ViewStyle,
  Animated,
  Easing,
} from 'react-native';
import LoadingOverlay from './LoadingOverlay';

export type ProgressiveImageProps = Animated.ComponentProps<
  typeof Animated.Image
> & {
  thumbnailSource?: ImageSourcePropType;
  /** Default is `true`. Setting to false will disable blur effect. */
  blurThumbnail?: boolean;
  /** Default is `20` */
  thumbnailBlurRadius?: number;
  /** Default is `false` */
  showLoadingIndicator?: boolean;
  source: ImageSourcePropType;
  // style?: StyleProp<ImageStyle>;
  containerStyle?: StyleProp<ViewStyle>;
};

export default function ProgressiveImage({
  thumbnailSource,
  thumbnailBlurRadius = 64,
  blurThumbnail,
  source,
  style,
  containerStyle,
  ...props
}: ProgressiveImageProps) {
  const mounted = useRef(false);

  const blurAnimation = useRef(new Animated.Value(64));
  useEffect(() => {
    mounted.current = true;

    blurAnimation.current.addListener(({ value }) => {
      if (mounted.current) setBlurAnimationAmount(value);
    });

    return () => {
      mounted.current = false;
    };
  }, []);

  const [blurAnimationAmount, setBlurAnimationAmount] =
    useState(thumbnailBlurRadius);

  const [renderThumbnail, setRenderThumbnail] = useState(
    !!(source as any)?.uri,
  );

  const onImageLoaded = useCallback(() => {
    if (!mounted.current) return;

    setRenderThumbnail(false);
    Animated.timing(blurAnimation.current, {
      useNativeDriver: false,
      duration: 320,
      toValue: 0,
      easing: Easing.out(Easing.poly(4)),
    }).start();
  }, []);

  const MemoizedImage = useMemo(
    () => (
      <Animated.Image
        {...props}
        testID={undefined}
        source={source}
        onLoad={onImageLoaded}
        style={[styles.imageOverlay, style]}
      />
    ),
    [props, source, onImageLoaded, style],
  );

  return (
    <Animated.View
      style={[
        {
          backgroundColor: 'transparent',
          pointerEvents: 'none',
        },
        containerStyle,
      ]}
    >
      {renderThumbnail && thumbnailSource && (
        <Animated.Image {...props} source={thumbnailSource} style={style} />
      )}

      {MemoizedImage}

      <BlurView
        intensity={blurThumbnail ? blurAnimationAmount : 0}
        style={StyleSheet.absoluteFill}
      />

      {props.showLoadingIndicator ? (
        <LoadingOverlay loading={renderThumbnail} activeOpacity={0.5} />
      ) : null}
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  imageOverlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
});
