import React, { useRef } from 'react';
import { Platform } from 'react-native';
import { KeyboardAwareScrollView } from '@mtourj/react-native-keyboard-aware-scroll-view';
import { CampaignBuilderComponentProps } from '../CampaignBuilder';
import CampaignBuilderFooter from '../components/CampaignBuilderFooter';
import SkillDescriptionForm from './SkillDescriptionForm';
import { useFormValidationContext } from '/components/ValidatedForm';
import withFormValidation from '/components/ValidatedForm/withFormValidationHOC';
import styles from '/constants/CampaignBuilder';
import { SkillRequest } from '/generated/graphql';

const DescribeSkills = (props: CampaignBuilderComponentProps) => {
  const content: React.ReactElement[] = [];

  const skillRequests = props.data?.skilled_impact_requests ?? [];

  const scrollViewRef = useRef<KeyboardAwareScrollView | null>();

  const { fields, validateForm } = useFormValidationContext(scrollViewRef);

  skillRequests.forEach((request, index) => {
    const onChange = (data: Partial<SkillRequest>) => {
      const newRequests = Array.from(skillRequests) ?? [];

      newRequests[index] = {
        ...newRequests[index],
        ...data,
      };

      props.setData({
        skilled_impact_requests: newRequests,
      });
    };

    content.push(
      <SkillDescriptionForm
        fields={fields}
        key={index}
        skill={request}
        onChange={onChange}
      />,
    );
  });

  return (
    <>
      <KeyboardAwareScrollView
        ref={(r) => (scrollViewRef.current = r)}
        style={{
          paddingTop: 120,
        }}
        extraScrollHeight={120}
        contentContainerStyle={[
          styles.scrollView,
          { paddingBottom: Platform.OS === 'web' ? 0 : 120, minHeight: '100%' },
        ]}
      >
        {content}
      </KeyboardAwareScrollView>
      <CampaignBuilderFooter
        {...props}
        onNext={() => props.next()}
        validateForm={validateForm}
      />
    </>
  );
};

export default withFormValidation(DescribeSkills);
