import { View, Text, Pressable, StyleSheet } from 'react-native';
import React, { useState } from 'react';
import Hoverable from '../Hoverable';
import Avatar from '../Avatar';
import useBinaryTimingAnimation from '../../hooks/useBinaryTimingAnimation';
import { KEY_GRAY } from '/constants';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';

interface IOrganizationMapMarkerProps {
  thumbnail: string | undefined;
  onPress: () => void;
  badge_number?: number;
}

export default function OrganizationMapMarker(
  props: IOrganizationMapMarkerProps,
) {
  const [isHovering, setHovering] = useState(false);

  const scale = useBinaryTimingAnimation({
    value: isHovering,
    disabledValue: 1,
    enabledValue: 1.3,
  });
  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ scale: scale.value }],
  }));

  return (
    <Animated.View
      pointerEvents="box-none"
      style={[styles.organizationMarkerContainer, animatedStyle]}
    >
      <Hoverable
        onHoverIn={() => {
          setHovering(true);
        }}
        onHoverOut={() => {
          setHovering(false);
        }}
      >
        <Pressable
          onPress={() => {
            props.onPress();
          }}
          style={styles.organizationMarkerInner}
        >
          <Avatar
            source={{
              uri: props.thumbnail ?? '',
            }}
            rounded
            size={48}
          />
          {props.badge_number && props.badge_number > 1 && (
            <View style={styles.markerPill}>
              <Text style={styles.markerPillText}>{props.badge_number}</Text>
            </View>
          )}
        </Pressable>
      </Hoverable>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  organizationMarkerInner: {
    width: 56,
    height: 56,
    borderRadius: 32,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    overflow: 'visible',
  },
  markerPill: {
    position: 'absolute',
    alignSelf: 'center',
    bottom: -12,
    height: 24,
    width: 48,
    borderRadius: 15,
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
  },
  markerPillText: {
    fontSize: 14,
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
  },
  organizationMarkerContainer: {
    width: 80,
    height: 80,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
