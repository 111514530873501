import { Entypo, FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useRef } from 'react';
import { FlatList, Pressable, StyleSheet, Text, View } from 'react-native';
import IUCNStatusImage from '../../IUCNStatusImage/IUCNStatusImage';
import ChevronRight from '/assets/jsicons/miscIcons/ChevronRight';
import { useModalContext } from '/context';
import { NewSpecies, SpeciesSelectionResponse } from '/generated/graphql';

export interface ICampaignSpecies
  extends Pick<SpeciesSelectionResponse, 'vernacularName'> {
  species: Pick<
    NewSpecies,
    'taxonID' | 'iucnThreatStatus' | 'preferredVernacularName' | 'canonicalName'
  >;
}

interface ICampaignSpeciesProps {
  hide: boolean | undefined;
  campaign:
    | {
        species?: ICampaignSpecies[] | undefined | null;
      }
    | undefined
    | null;
}

export default function CampaignSpecies(props: ICampaignSpeciesProps) {
  const { spawnModal, closeModal: _closeModal } = useModalContext();

  const modalId = useRef<string | null>(null);
  const closeModal = () => {
    modalId.current && _closeModal(modalId.current);
    modalId.current = null;
  };

  const { navigate } = useNavigation<StackNavigationProp<any>>();

  function goToSpecies(
    taxonID: number,
    vernacularName: string | undefined | null,
  ) {
    closeModal();
    navigate('SpeciesSummary', {
      taxonID: taxonID,
      name: vernacularName,
    });
  }

  function showAllSpecies() {
    modalId.current = spawnModal({
      title: 'Species',
      component: (
        <FlatList
          style={{
            flexGrow: 0,
          }}
          data={props.campaign?.species}
          renderItem={({ item }) => {
            return (
              <Pressable
                onPress={() =>
                  item?.species?.taxonID &&
                  goToSpecies(item.species.taxonID, item.vernacularName)
                }
                style={({ pressed }) => ({
                  borderRadius: 6,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: 10,
                  backgroundColor: pressed ? '#f0f0f0' : 'white',
                })}
              >
                <View
                  style={{
                    justifyContent: 'flex-start',
                    flexDirection: 'row',
                  }}
                >
                  {item?.species?.iucnThreatStatus?.threatStatus ? (
                    <View
                      style={{
                        marginRight: 8,
                      }}
                    >
                      <IUCNStatusImage
                        width={64}
                        height={64}
                        redlist_category={
                          item.species.iucnThreatStatus.threatStatus
                        }
                      />
                    </View>
                  ) : null}
                  <View
                    style={{
                      alignItems: 'flex-start',
                    }}
                  >
                    <Text
                      style={[
                        {
                          fontFamily: 'Lato-Bold',
                          fontSize: 20,
                          textTransform: 'capitalize',
                        },
                      ]}
                    >
                      {item?.vernacularName ??
                        item?.species?.preferredVernacularName ??
                        item?.species?.canonicalName}
                    </Text>
                    {!!(
                      item?.vernacularName ||
                      item?.species?.preferredVernacularName
                    ) && (
                      <Text
                        style={{
                          fontFamily: 'Lato-Italic',
                          fontSize: 17,
                          textTransform: 'capitalize',
                        }}
                      >
                        ({item?.species?.canonicalName})
                      </Text>
                    )}
                  </View>
                </View>
                <ChevronRight />
              </Pressable>
            );
          }}
        />
      ),
    });
  }

  return (
    <View
      style={{
        display: props.hide ? 'none' : 'flex',
        flex: 1,
        flexDirection: 'row',
        padding: 4,
        // paddingHorizontal: 10,
        justifyContent: 'space-between',
      }}
    >
      <View
        style={{
          flex: 1,
        }}
      >
        {(props.campaign?.species?.length ?? 0) > 0 && !props.hide && (
          <>
            <View
              style={{
                flex: 1,
                marginRight: 16,
                overflow: 'hidden',
              }}
            >
              <Pressable
                onPress={showAllSpecies}
                style={({ pressed }) => ({
                  backgroundColor: pressed ? '#ddd' : 'white',
                  padding: 4,
                  paddingLeft: 6,
                  borderRadius: 6,
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  alignSelf: 'flex-start',
                })}
              >
                <View>
                  <FontAwesome name="paw" size={20} color="black" />
                  {(props.campaign?.species?.length ?? 0) > 1 && (
                    <>
                      <Entypo
                        name="chevron-down"
                        size={18}
                        color="black"
                        style={{
                          marginTop: -2,
                        }}
                      />
                    </>
                  )}
                </View>
                <IUCNStatusImage
                  style={{
                    marginLeft: 4,
                  }}
                  redlist_category={
                    props.campaign?.species?.[0]?.species?.iucnThreatStatus
                      ?.threatStatus ?? ''
                  }
                  small
                  width={20}
                  height={20}
                />
                <Text style={[styles.speciesName]}>
                  {/* Fall back to `canonicalName` if `preferredVernacularName` is not set */}
                  {props.campaign?.species?.[0]?.vernacularName ||
                    props.campaign?.species?.[0]?.species
                      ?.preferredVernacularName ||
                    props.campaign?.species?.[0]?.species?.canonicalName}
                  {/* Only render if this if we are rendering the `preferredVernacularName` above */}
                  {!props.campaign?.species?.[0]?.species
                    ?.preferredVernacularName ? null : (
                    <Text style={styles.speciesScientificName}>
                      {`\n`}(
                      {props.campaign?.species?.[0].species.canonicalName})
                    </Text>
                  )}
                </Text>
              </Pressable>
            </View>
          </>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  speciesName: {
    flex: 1,
    marginHorizontal: 8,
    fontSize: 15,
    textTransform: 'capitalize',
    fontFamily: 'Lato-Bold',
    flexWrap: 'wrap',
    alignItems: 'center',
    textAlignVertical: 'center',
  },
  speciesScientificName: {
    fontFamily: 'Lato-BoldItalic',
    textAlignVertical: 'center',
    lineHeight: 14,
  },
});
