import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles';
import {
  CARD_TITLE_FONT_SIZE,
  CONTENT_PARAGRAPH_FONT_SIZE,
  KEY_GRAY,
} from '/constants';

/** COMMON STYLES FOR COMPONENTS IN Profile/conservationist */

export const useCommonStyles = CreateResponsiveStyle(
  {
    sectionTitleContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 32,
    },
    sectionTitle: {
      fontFamily: 'LeagueSpartan-Bold',
      fontSize: CARD_TITLE_FONT_SIZE,
      marginVertical: 2,
      textTransform: 'uppercase',
    },
    sectionText: {
      fontFamily: 'Lato',
      fontSize: CONTENT_PARAGRAPH_FONT_SIZE,
      color: 'black',
    },
    emptyPlaceholderSectionText: {
      marginLeft: 12,
      fontFamily: 'Lato-Italic',
      color: 'gray',
    },
    sectionAddButtonText: {
      marginHorizontal: 4,
      fontFamily: 'Lato-Bold',
      fontSize: 16,
      color: KEY_GRAY,
    },
    editSectionButtonContainer: {
      position: 'absolute',
      top: 16,
      right: 16,
    },
    supporterName: {
      fontFamily: 'LeagueSpartan-Bold',
      textTransform: 'uppercase',
      fontSize: 17,
      textAlign: 'center',
      alignSelf: 'center',
      marginTop: 5,
    },
    horizontalContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    supporterTile: {
      alignSelf: 'center',
      alignItems: 'center',
      width: 120,
    },
    moreSupportersText: {
      fontFamily: 'Lato-Bold',
      fontSize: 17,
      padding: 12,
      textAlign: 'center',
      alignSelf: 'center',
      color: KEY_GRAY,
    },
    errorContainer: {
      padding: 10,
      alignItems: 'center',
      alignSelf: 'center',
    },
    errorText: {
      fontFamily: 'Lato-Bold',
      fontSize: 16,
      color: 'gray',
    },
  },
  {
    [minSize(DEVICE_SIZES.LARGE_DEVICE)]: {
      sectionTitle: {
        fontSize: 21,
        marginVertical: 4,
      },
      sectionText: {
        fontSize: CONTENT_PARAGRAPH_FONT_SIZE + 1,
      },
    },
  },
);
