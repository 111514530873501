import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';

const unblockUser: UpdateResolver = (result, args, cache) => {
  cache.writeFragment(
    gql`
      fragment UnblockUser on User {
        id
        is_blocked
      }
    `,
    {
      __typename: 'User',
      id: args.userId as string,
      is_blocked: false,
    },
  );
};

export default unblockUser;
