import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';

const withdrawVolunteerRegistration: UpdateResolver = (result, args, cache) => {
  const fragment = gql`
    fragment WithdrawVolunteerRegistration on VolunteerRegistration {
      volunteerRequestId
      userId
      withdrawn
    }
  `;

  cache.writeFragment(fragment, {
    __typename: 'VolunteerRegistration',
    ...(result.withdrawVolunteerRegistration as any),
  });
};

export default withdrawVolunteerRegistration;
