import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  inputContainer: {
    width: '100%',
  },
  inputField: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    marginTop: 12,
    marginBottom: 6,
  },
  input: {
    width: '100%',
    height: 60,
    backgroundColor: 'rgba(255,255,255,0.5)',
    fontWeight: 'bold',
    color: '#fafafa',
    paddingHorizontal: 10,
  },
});
