import { UpdateResolver } from '@urql/exchange-graphcache';

const registerForVolunteerRequest: UpdateResolver = (result, args, cache) => {
  cache.invalidate(
    {
      __typename: 'VolunteerRequest',
      id: args.volunteerRequestId as string,
    },
    'discussion_board',
  );

  cache.link(
    {
      __typename: 'VolunteerRequest',
      id: args.volunteerRequestId as string,
    },
    'my_registration',
    result.registerForVolunteerRequest as any,
  );
};

export default registerForVolunteerRequest;
