import { ViewportAwareView } from '@skele/components';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import {
  ActivityIndicator,
  FlatList,
  Pressable,
  StyleSheet,
  Text,
  View,
  Image,
} from 'react-native';
import Button from '../../Button';
import Lightening from '/assets/jsicons/bottomnavigation/Lightening';
import { KEY_GRAY, KEY_GREEN } from '/constants';
import { useGetCampaignPostsQuery } from '/generated/graphql';
import { determineIfVideo } from '/util';
import getCDNImageUri from '/util/getCDNImageUri';

interface Props {
  campaignId: string | undefined;
  selectedPostId: string | undefined;
  onSelectPost: (postId: string) => void;
}

export default function CampaignTimeline(props: Props) {
  const [nextToken, setNextToken] = useState<string>();

  /** Used to lazily fetch data when post has entered viewport */
  const [hasEnteredViewport, setHasEnteredViewport] = useState(false);

  const [{ data, fetching, error }, refetch] = useGetCampaignPostsQuery({
    variables: { campaignId: props.campaignId!, nextToken },
    pause: !props.campaignId,
  });

  function onSelectPost(id: string) {
    props.onSelectPost(id);
  }

  function onViewportEnter() {
    if (!hasEnteredViewport) setHasEnteredViewport(true);
  }

  /** Do not render this component if the campaign only has a total of 1 post */
  return (!fetching && !error && (data?.getCampaignPosts.total ?? 0) <= 1) ||
    !props.campaignId ? null : (
    <ViewportAwareView
      onViewportEnter={onViewportEnter}
      style={styles.container}
    >
      <Text style={styles.headerText}>CAMPAIGN TIMELINE</Text>

      {!data && fetching ? (
        <ActivityIndicator style={styles.activityIndicator} color={KEY_GRAY} />
      ) : error ? (
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>
            There was a problem fetching this campaign's timeline
          </Text>
          <Button label="Try Again" onPress={refetch} />
        </View>
      ) : (
        <FlatList
          data={data?.getCampaignPosts.items ?? []}
          horizontal
          contentContainerStyle={{
            paddingBottom: 8,
          }}
          renderItem={({ item, index }) => {
            return (
              <Pressable
                style={[styles.postTile]}
                onPress={() => onSelectPost(item.id)}
              >
                <View>
                  <Image
                    style={styles.tileImage}
                    source={{
                      uri: getCDNImageUri({
                        uri: item.thumbnail,
                        isThumbnail: true,
                        dimensions: {
                          height: 120,
                          width: 120,
                        },
                      }),
                    }}
                  />
                  {/* Video Icon */}
                  {determineIfVideo(item.media[0]) ? (
                    <Image
                      source={require('/assets/icons/video.png')}
                      style={styles.videoIcon}
                      resizeMode={'contain'}
                    />
                  ) : null}
                </View>
                <Text style={styles.date}>
                  {index === 0
                    ? 'Latest'
                    : dayjs(Number(item.created_at)).format('MMM DD')}
                </Text>

                <View style={styles.timeLineContainer}>
                  <View
                    style={[
                      styles.timelineLeft,
                      { opacity: index === 0 ? 0 : 1 },
                    ]}
                  />
                  {index === (data?.getCampaignPosts.total ?? 0) - 1 ? (
                    <View style={styles.tileSelectionIndicatorContainer}>
                      <Lightening
                        width={24}
                        height={24}
                        fill={
                          props.selectedPostId === item.id ? KEY_GREEN : 'black'
                        }
                      />
                    </View>
                  ) : (
                    <View style={styles.tileSelectionIndicatorContainer}>
                      <View
                        style={[
                          styles.tileSelectionIndicator,
                          {
                            backgroundColor:
                              props.selectedPostId === item.id
                                ? KEY_GREEN
                                : 'black',
                          },
                        ]}
                      />
                    </View>
                  )}
                  <View
                    style={[
                      styles.timelineRight,
                      {
                        opacity:
                          index ===
                          (data?.getCampaignPosts?.items.length ?? 0) - 1
                            ? 0
                            : 1,
                      },
                    ]}
                  />
                </View>
              </Pressable>
            );
          }}
          onEndReached={() => {
            if (
              data?.getCampaignPosts.nextToken &&
              nextToken !== data.getCampaignPosts.nextToken
            ) {
              setNextToken(data.getCampaignPosts.nextToken);
            }
          }}
        />
      )}
    </ViewportAwareView>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 8,
  },
  headerText: {
    padding: 8,
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 20,
    textAlign: 'center',
    alignSelf: 'center',
  },
  activityIndicator: {
    alignSelf: 'center',
    padding: 16,
  },
  errorContainer: {
    padding: 8,
    alignItems: 'center',
  },
  errorText: {
    fontFamily: 'Lato-Bold',
    color: 'crimson',
    fontSize: 15,
    paddingBottom: 8,
    textAlign: 'center',
  },
  postTile: {
    paddingHorizontal: 8,
    width: 128,
    alignItems: 'center',
  },
  tileImage: {
    width: 120,
    height: 120,
    backgroundColor: 'gray',
    borderRadius: 10,
  },
  date: {
    paddingVertical: 8,
    fontFamily: 'Lato-Bold',
    fontSize: 16,
    color: 'black',
    textAlign: 'center',
  },
  timeLineContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
  },
  tileSelectionIndicator: {
    alignSelf: 'center',
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: 'black',
  },
  tileSelectionIndicatorContainer: {
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
  timelineLeft: {
    position: 'absolute',
    height: 2,
    backgroundColor: 'black',
    left: -8,
    width: '50%',
  },
  timelineRight: {
    position: 'absolute',
    height: 2,
    backgroundColor: 'black',
    right: -8,
    width: '50%',
  },
  videoIcon: {
    position: 'absolute',
    top: 8,
    left: 8,
    width: 18,
    height: 18,
  },
});
