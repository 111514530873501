import { UpdateResolver } from '@urql/exchange-graphcache';

const addPartner: UpdateResolver = (result, args, cache) => {
  // Once with the partnerId and once without
  cache.link(
    {
      __typename: 'User',
      id: args.userId as string,
    },
    'partnership',
    {
      partnerId: args.initiatorId as string,
    },
    result.addPartner as any,
  );
  cache.link(
    {
      __typename: 'User',
      id: args.userId as string,
    },
    'partnership',
    result.addPartner as any,
  );
};

export default addPartner;
