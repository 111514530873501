import { UpdateResolver } from '@urql/exchange-graphcache';

const createSkillRequestApplication: UpdateResolver = (result, args, cache) => {
  cache.link(
    {
      __typename: 'SkillRequest',
      id: args.requestId as string,
    },
    'my_application',
    result.createSkillRequestApplication as any,
  );
};

export default createSkillRequestApplication;
