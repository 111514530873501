import { Feather, FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Badge } from '@rneui/themed';
import React, { useState } from 'react';
import {
  ActivityIndicator,
  LayoutChangeEvent,
  Platform,
  Pressable,
  Share,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useCommonStyles } from '../styles';
import Alert from '/Alert';
import Avatar from '/components/Avatar';
import Button from '/components/Button';
import AddPartnershipModal from '/components/Partnerships/AddPartnershipModal';
import { KEY_GRAY, KEY_GREEN, KEY_YELLOW, SECTION_CONTAINER } from '/constants';
import { useAuthContext } from '/context';
import {
  PartnershipStatus,
  User,
  useRemovePartnershipMutation,
} from '/generated/graphql';
import * as Clipboard from 'expo-clipboard';
import { DeepPartial } from '/types';
import { createUniversalURL } from '/util';

interface Props {
  data: Pick<DeepPartial<User>, 'id' | 'partnerships'> | undefined | null;
  isEditing: boolean;
}

export default function Partnerships(props: Props) {
  const { userData } = useAuthContext();

  const { push } = useNavigation<StackNavigationProp<any>>();

  const { styles: commonStyles } = useCommonStyles();

  const [showUserSearch, setShowUserSearch] = useState(false);

  const [avatarWidth, setAvatarWidth] = useState(128);

  const [{ fetching: removing }, removePartnership] =
    useRemovePartnershipMutation();

  const onAvatarGridContainerLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    /** Figures out the width that the Avatar components need to be in order to evenly
     * fill their rows based on the width of their container */

    const containerWidth = nativeEvent.layout.width;

    const maxWidth = containerWidth > 480 ? 140 : 128;

    const avatarCount = Math.ceil(containerWidth / maxWidth);

    setAvatarWidth(Math.floor(containerWidth / avatarCount));
  };

  const { navigate } = useNavigation<StackNavigationProp<any>>();

  function onViewPartnershipRequests() {
    if (!userData?.id) {
      console.warn(
        'Partnerships.tsx:onViewPartnershipRequests cannot find current user ID',
      );
      return;
    }

    navigate('ViewPartnerships', {
      userId: userData.id,
      status: PartnershipStatus.Pending,
    });
  }

  function onSeeAllPartnerships() {
    if (!props.data?.id) {
      console.warn('Partnerships.tsx:onSeeAllPartnerships cannot find user ID');
      return;
    }

    navigate('ViewPartnerships', {
      userId: props.data.id,
    });
  }

  function onPressPartner(partnerId: string) {
    // Navigate to partner's profile
    push('Profile', { id: partnerId });
  }

  function onRemovePartnership(partnershipId: string) {
    const remove = async () => {
      try {
        const { error } = await removePartnership({
          removePartnershipId: partnershipId,
        });

        if (error) throw error;
      } catch (error) {
        console.log(error);
      }
    };

    Alert.alert(
      'Remove Partner',
      'Are you sure you want to remove this partner?',
      [
        { style: 'cancel' },
        {
          text: 'Remove',
          style: 'destructive',
          onPress: remove,
        },
      ],
    );
  }

  function onAddPartnerOrganization() {
    setShowUserSearch(true);
  }

  async function onInviteNewUser() {
    // TODO: Add some stuff to this link...
    const shareableURL = createUniversalURL('login');

    try {
      if (Platform.OS === 'web') throw new Error('Not supported on web');

      await Share.share(
        {
          message:
            'The Key Conservation platform is helping conservation organizations fill gaps of funding and support. Come join us on the platform!',
          url: shareableURL,
          title: 'Join Key Conservation',
        },
        {
          subject: 'Join Key Conservation',
        },
      );
    } catch (error) {
      /** Fallback */
      Clipboard.setStringAsync(shareableURL).then((value) => {
        if (value) {
          Alert.notify({
            message: 'Invite link copied to clipboard!',
            color: KEY_GREEN,
          });
        }
      });
    }
  }

  // If this is not our profile and there are no partnerships associated with it,
  // do not render the component
  // If it is our profile and we have no partnerships, pending or otherwise, and we are
  // not currently editing the profile, do not render the component

  const shouldHide =
    (userData?.id !== props.data?.id ||
      (!props.isEditing && !props.data?.partnerships?.pending)) &&
    props.data?.partnerships?.items?.length === 0;

  return (
    <>
      {removing ? (
        <View
          style={[
            StyleSheet.absoluteFill,
            {
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1,
            },
          ]}
        >
          <ActivityIndicator
            color={'black'}
            size={24}
            style={{
              marginVertical: 8,
            }}
          />
          <Text
            style={{
              fontFamily: 'Lato-Bold',
              fontSize: 17,
              color: 'black',
              textAlign: 'center',
            }}
          >
            Removing...
          </Text>
        </View>
      ) : null}
      <View
        pointerEvents={removing ? 'none' : 'auto'}
        style={[
          SECTION_CONTAINER,
          {
            display: shouldHide ? 'none' : 'flex',
            opacity: removing ? 0.4 : 1,
          },
        ]}
      >
        <AddPartnershipModal
          initiatorId={props.data?.id}
          visible={showUserSearch}
          onRequestClose={() => {
            setShowUserSearch(false);
          }}
        />
        <View style={commonStyles('sectionTitleContainer')}>
          <Text style={commonStyles('sectionTitle')}>OUR PARTNERS</Text>
          {props.data?.partnerships?.pending ? (
            <TouchableOpacity
              onPress={onViewPartnershipRequests}
              style={styles.partnershipRequestsButton}
            >
              <Text style={styles.partnershipRequestsButtonText}>PENDING</Text>
              <Badge
                value={props.data.partnerships.pending}
                textStyle={styles.partnershipRequestsButtonCircleText}
                badgeStyle={styles.partnershipRequestsButtonCircle}
              />
            </TouchableOpacity>
          ) : null}
        </View>
        {(props.data?.partnerships?.total === 0 &&
          userData?.id === props.data.id) ||
        (props.data?.partnerships?.items?.length && props.isEditing) ? (
          <View style={{ alignItems: 'flex-start' }}>
            {props.data?.partnerships?.total === 0 && (
              <Text style={styles.emptyText}>
                You don't have any partner organizations yet!
              </Text>
            )}
            <Button
              containerStyle={{
                marginVertical: 8,
              }}
              label={
                <View
                  style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <Feather name="plus-circle" size={20} />
                  <Text
                    style={{
                      alignItems: 'center',
                      fontFamily: 'Lato-Bold',
                      fontSize: 16,
                      marginHorizontal: 4,
                    }}
                  >
                    Add a Partner Organization
                  </Text>
                </View>
              }
              onPress={onAddPartnerOrganization}
            />
            <Button
              containerStyle={{
                marginVertical: 8,
                marginBottom: 16,
              }}
              style={{
                backgroundColor: KEY_GRAY,
              }}
              label={
                <View
                  style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <FontAwesome name="share" size={20} color="white" />
                  <Text
                    style={{
                      alignItems: 'center',
                      fontFamily: 'Lato-Bold',
                      fontSize: 16,
                      marginHorizontal: 4,
                      color: 'white',
                    }}
                  >
                    Invite a Partner to Key
                  </Text>
                </View>
              }
              onPress={onInviteNewUser}
            />
          </View>
        ) : null}
        <View
          style={styles.partnershipsContainer}
          onLayout={onAvatarGridContainerLayout}
        >
          {props.data?.partnerships?.items?.map((partnership) => {
            // Exclude pending partnerships
            if (
              !partnership ||
              partnership.status === PartnershipStatus.Pending
            )
              return null;

            const partner =
              props.data?.id === partnership.initiator!.id
                ? partnership.recipient!
                : partnership.initiator!;

            return (
              <Pressable
                key={partnership?.id}
                onPress={() => {
                  onPressPartner(partner.id!);
                }}
                style={[{ width: avatarWidth, padding: 2, paddingVertical: 4 }]}
              >
                <View
                  style={[
                    styles.partnerAvatarContainer,
                    removing && styles.partnerAvatarContainerBusy,
                  ]}
                >
                  <Avatar
                    source={{ uri: partner?.profile_image || '' }}
                    rounded
                    size={avatarWidth - 12}
                  />
                  <View style={styles.partnerNameContainer}>
                    <Text numberOfLines={3} style={styles.partnerName}>
                      {partner?.name}
                    </Text>
                  </View>
                  {userData?.id === props.data?.id && props.isEditing ? (
                    <Button
                      disabled={removing}
                      containerStyle={{
                        marginHorizontal: 2,
                      }}
                      label={
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <FontAwesome
                            size={17}
                            name="trash-o"
                            style={{
                              marginRight: 4,
                            }}
                            color={KEY_GRAY}
                          />
                          <Text
                            style={{
                              color: KEY_GRAY,
                              fontFamily: 'Lato-Bold',
                              fontSize: 17,
                            }}
                          >
                            Remove
                          </Text>
                        </View>
                      }
                      onPress={() => onRemovePartnership(partnership.id!)}
                    />
                  ) : null}
                </View>
              </Pressable>
            );
          })}
        </View>
        {/* Only show "See All" button if total confirmed partnerships is more than 6 */}
        {(props.data?.partnerships?.total || 0) -
          (props.data?.partnerships?.pending || 0) >
        6 ? (
          <View style={styles.seeAllButtonContainer}>
            <TouchableOpacity
              style={styles.seeAllButton}
              onPress={onSeeAllPartnerships}
            >
              <Text style={styles.seeAllButtonText}>See All {`>`}</Text>
            </TouchableOpacity>
          </View>
        ) : null}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  partnershipsContainer: {
    paddingVertical: 4,
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  partnerAvatarContainer: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  partnerAvatarContainerBusy: {
    opacity: 0.5,
  },
  partnerNameContainer: {
    justifyContent: 'center',
    maxHeight: 64,
    alignSelf: 'stretch',
  },
  partnerName: {
    textTransform: 'uppercase',
    marginVertical: 8,
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 16,
    textAlignVertical: 'center',
    textAlign: 'center',
  },
  partnershipRequestsButton: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 4,
  },
  partnershipRequestsButtonCircle: {
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: KEY_YELLOW,
    borderRadius: 10,
    marginLeft: 6,
  },
  partnershipRequestsButtonCircleText: {
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    color: 'black',
  },
  partnershipRequestsButtonText: {
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    color: KEY_GRAY,
  },
  emptyTextContainer: {
    flex: 1,
    marginVertical: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyText: {
    fontFamily: 'Lato-Bold',
    color: 'gray',
    fontSize: 15,
    textAlign: 'center',
    marginVertical: 10,
  },
  addPartnerButton: {
    maxWidth: 350,
    justifyContent: 'center',
    alignItems: 'center',
  },
  seeAllButtonContainer: {
    alignItems: 'flex-end',
  },
  seeAllButton: {
    padding: 12,
    marginVertical: 4,
  },
  seeAllButtonText: {
    color: 'black',
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 18,
  },
});
