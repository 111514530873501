import React from 'react';
import SvgXml from '/components/common/SvgXml';

export default function MomentInNatureIcon(props) {
  const xml = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  width="100%" viewBox="0 0 496 496" enable-background="new 0 0 496 496" xml:space="preserve">
<path fill="#000000" opacity="1.000000" stroke="none" 
 d="
M274.000000,497.000000 
 C258.645782,497.000000 243.291550,497.000000 227.108978,496.876038 
 C212.716797,494.426819 199.047775,492.674927 185.782654,488.786926 
 C127.585228,471.729187 80.623108,439.201599 46.530365,388.555420 
 C22.211731,352.429108 7.881695,312.793365 4.927998,269.436157 
 C3.609741,250.085510 4.216869,230.617310 7.390712,211.241562 
 C14.296947,169.080231 30.540998,130.998871 57.273666,97.864525 
 C100.808167,43.904800 157.103149,12.494775 226.461075,5.605607 
 C253.091125,2.960505 279.599091,4.239797 305.752930,10.196331 
 C349.113983,20.071795 386.944458,40.667500 419.532654,70.642632 
 C447.311554,96.194046 467.422760,127.145851 481.038818,162.344009 
 C489.481659,184.169052 494.501129,206.782425 497.000000,230.000000 
 C497.000000,242.687561 497.000000,255.375122 496.999481,268.937286 
 C496.802673,270.618134 496.483765,271.416016 496.428253,272.231812 
 C495.329681,288.373413 492.067596,304.123077 487.606506,319.624146 
 C475.742889,360.846741 454.408875,396.411774 423.768738,426.463654 
 C391.258118,458.350098 353.059204,479.845093 308.731934,490.519287 
 C297.247742,493.284698 285.479065,494.324402 274.000000,497.000000 
M403.206268,118.278358 
 C392.182617,105.679916 379.879608,94.502228 366.045898,85.057510 
 C321.229065,54.459660 271.773499,43.373993 218.392288,51.812164 
 C172.088287,59.131615 132.737152,80.503136 101.410423,115.509613 
 C58.066605,163.944717 41.703979,221.011307 51.809017,284.816406 
 C59.953415,336.241669 85.584862,378.485229 126.834045,410.420044 
 C175.322830,447.959778 230.337082,460.970428 290.411224,449.207550 
 C341.374176,439.228699 382.409821,412.389038 413.521790,370.800385 
 C436.416382,340.196228 449.225372,305.493896 452.387360,267.528107 
 C453.680389,252.003067 453.264221,236.317368 450.814240,220.730057 
 C444.762299,182.226730 429.092834,148.290955 403.206268,118.278358 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
 d="
M312.070129,299.981049 
 C295.062927,319.454865 273.520142,327.680908 248.450729,327.633270 
 C228.875534,327.596069 211.556747,320.995605 196.464050,308.763214 
 C186.539993,300.719879 181.856110,289.602325 179.710327,277.265259 
 C179.005783,273.214447 180.456787,271.510071 184.731674,271.638245 
 C191.890671,271.852814 199.063614,271.733551 206.227386,271.589935 
 C208.925018,271.535858 210.332733,272.574707 211.566010,275.014343 
 C219.360168,290.432709 231.946411,297.390015 249.272552,296.872620 
 C259.272034,296.574005 268.836029,295.891724 277.269745,289.746368 
 C284.714966,284.321289 289.750214,277.292053 291.963867,268.396698 
 C292.846832,264.848602 294.590424,263.521912 298.220642,263.655396 
 C304.712067,263.894196 311.220123,263.861755 317.716461,263.722137 
 C321.899231,263.632263 323.326904,265.433044 322.995941,269.504517 
 C322.098694,280.541779 318.790710,290.671021 312.070129,299.981049 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
 d="
M120.367615,202.642700 
 C124.397728,176.941833 149.119049,152.254608 181.819565,154.323303 
 C206.301453,155.872086 230.148773,176.221405 234.881088,201.026413 
 C235.636826,204.987686 234.750320,206.518570 230.567429,206.481079 
 C224.249588,206.424469 217.925735,206.769394 211.608444,207.038040 
 C208.631912,207.164627 206.787827,206.310684 205.527023,203.222458 
 C200.048248,189.802795 189.928192,183.066071 176.321701,183.342117 
 C164.180359,183.588470 154.379227,191.177475 149.589920,204.485855 
 C148.524780,207.445648 146.846664,208.303146 144.064331,208.295898 
 C137.572601,208.278992 131.077103,208.227921 124.590096,208.422791 
 C120.523796,208.544937 118.988884,207.039200 120.367615,202.642700 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
 d="
M305.003845,157.693176 
 C332.817596,150.312637 360.700958,160.458893 374.505035,182.901352 
 C378.007935,188.596268 380.579193,194.693771 380.437592,202.310822 
 C371.598053,203.398132 362.862885,203.438828 354.115143,203.038818 
 C352.126617,202.947891 351.649200,201.042450 350.827759,199.621368 
 C344.291107,188.312912 334.061615,183.905899 321.492981,184.340866 
 C309.487701,184.756348 299.837860,189.411804 294.649017,200.909348 
 C293.649170,203.124847 292.250732,203.930878 290.083893,203.961807 
 C283.087952,204.061691 276.091583,204.130981 269.095795,204.238846 
 C265.729218,204.290741 264.874084,202.802948 265.445343,199.513840 
 C268.848175,179.920868 280.993591,167.696136 298.469452,159.901733 
 C300.438232,159.023666 302.551971,158.470642 305.003845,157.693176 
z"/>
</svg>`;

  return <SvgXml xml={xml} fill="#333" width={36} height={36} {...props} />;
}
