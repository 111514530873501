import { Platform, StyleSheet } from 'react-native';
import { KEY_LIGHT_GRAY, KEY_GREEN } from '/constants';

export default StyleSheet.create({
  difficultyButton: {
    backgroundColor: KEY_LIGHT_GRAY,
    borderRadius: 6,
    padding: 12,
    shadowColor: 'gray',
    shadowOpacity: 0.2,
    shadowRadius: 4,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    elevation: 2,
  },
  difficultyButtonSelected: {
    backgroundColor: KEY_GREEN,
  },
  difficultyButtonLabel: {
    fontFamily: 'Lato-Bold',
    fontSize: 17,
    alignSelf: 'center',
    textTransform: 'uppercase',
    paddingHorizontal: Platform.OS === 'web' ? 8 : 0,
  },
});
