import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles';
import styles from './OnboardHeader.style';
import NavigateBack from '/screens/org-onboarding-screens/formElement/NavigateBack';
import ProgressBar, { ProgressBarType } from '../ProgressBar/ProgressBar';

type OnboardHeaderProps = {
  progress: number;
  showProgress?: boolean;
  color?: 'black' | 'white';
  /** Optionally override behavior on back button is pressed */
  onGoBack?: () => void;
};

const OnboardHeader = (props: OnboardHeaderProps) => {
  const { goBack } = useNavigation();

  const { deviceSize } = useStyles();

  const isLargeDevice = minSize(DEVICE_SIZES.MEDIUM_DEVICE).includes(
    deviceSize,
  );

  return (
    <View style={styles.header}>
      <View style={styles.headerContent}>
        <View style={styles.arrowView}>
          <NavigateBack
            onButtonPress={() => {
              props.onGoBack ? props.onGoBack() : goBack();
            }}
            color={props.color || 'black'}
          />
        </View>
        {props.showProgress !== false && typeof props.progress === 'number' && (
          <View style={styles.progressBar}>
            <ProgressBar
              height={isLargeDevice ? 24 : 17}
              progress={props.progress}
              progressType={ProgressBarType.Percentage}
              goal={100}
            />
          </View>
        )}
      </View>
    </View>
  );
};

const useStyles = CreateResponsiveStyle({}, {});

export default OnboardHeader;
