import { StyleSheet } from 'react-native';
import { KEY_GRAY, KEY_LIGHT_GRAY } from '/constants';

export default StyleSheet.create({
  feedContainer: {
    flex: 1,
    backgroundColor: '#fff',
  },
  name: {
    flexDirection: 'row',
    marginLeft: -10,
    width: '100%',
  },
  ellipse: {
    paddingTop: 3,
    paddingLeft: 5,
  },
  searchIcon: {
    marginRight: 20,
  },
  mainContainer: {
    // flex: 1,
  },
  campaignAuthorsContainer: {
    padding: 4,
    paddingHorizontal: 8,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'whitesmoke',
    borderRadius: 8,
  },
  authorCard: {
    padding: 8,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
  },
  authorName: {
    fontFamily: 'Lato-Bold',
    padding: 6,
    fontSize: 18,
  },
  container: {
    width: '100%',
    justifyContent: 'center',
    alignSelf: 'center',
    backgroundColor: 'white',
    paddingTop: 0,
    paddingBottom: 10,
    borderRadius: 10,
    marginTop: 10,
  },
  orgTitleView: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 18,
  },
  goToCampaignButton: {
    backgroundColor: 'rgba(0, 255, 157, 0.7)',
    alignItems: 'center',
    justifyContent: 'center',
    height: 37,
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  iconRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 15,
    height: 30,
  },
  topRow: {
    flexDirection: 'row',
    width: '100%',
    paddingTop: 8,
    backgroundColor: 'white',
  },
  topRowLeft: {
    paddingLeft: 17,
  },
  topRowRight: {
    paddingRight: 10,
  },
  postTitle: {
    paddingRight: 15,
    flex: 1,
    fontFamily: 'Lato-Bold',
    fontSize: 18,
  },
  timeText: {
    color: '#929292',
    fontSize: 16,
    fontFamily: 'Lato',
    lineHeight: 19,
  },
  goToCampaignText: {
    fontFamily: 'Lato-Bold',
    fontSize: 18,
  },
  campaignDescriptionName: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
    paddingBottom: 10,
  },
  campaignDescriptionAndTimestampContainer: {
    margin: 8,
    marginBottom: 12,
  },
  campaignDescriptionText: {
    fontFamily: 'Lato',
    fontSize: 16,
  },
  rightSectionComment: {
    borderRadius: 12,
    backgroundColor: '#F5F5F5',
    height: 44,
    width: 48,
    margin: 2,
    paddingLeft: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  readMore: {
    color: '#929292',
  },
  demarcation: {
    marginTop: 5,
  },
  subtitleText: {
    color: '#929292',
  },
  campaignControls: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 5,
  },
  campaignControlsLeft: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  campaignControlsRight: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  smallDeviceDescriptionAndTimestamp: {
    flex: 1,
  },
  largeDeviceDescriptionAndTimestamp: {
    flex: 1,
    backgroundColor: 'blue',
  },
  smallDeviceMediaViewerContainer: {},
  largeDeviceMediaViewerContainer: {
    flex: 1,
    maxWidth: 640,
  },
  largeDeviceContentContainer: {
    flexDirection: 'row-reverse',
  },
  smallDeviceContentContainer: {},
  urgencyBar: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    // top: 0,
    // left: 0,
    height: 37,
    width: '100%',
    // position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
  },
  urgencyBarText: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
    letterSpacing: 2,
    color: 'black',
  },
  locationText: {
    fontFamily: 'Lato',
    fontSize: 16,
    color: '#969696',
  },
  detailExpandContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    marginTop: 4,
    paddingBottom: 0,
    borderTopWidth: 1,
    borderTopColor: KEY_LIGHT_GRAY,
  },
  detailExpandText: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    fontSize: 16,
    paddingHorizontal: 10,
  },
});
