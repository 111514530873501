import { Platform, StyleSheet } from 'react-native';
import { KEY_LIGHT_GRAY, BUTTON_SHADOW } from '/constants';

export default StyleSheet.create({
  filterButton: {
    borderWidth: 1,
    borderColor: KEY_LIGHT_GRAY,
    borderRadius: 6,
    marginBottom: 6,
    padding: 8,
    backgroundColor: 'white',
    alignSelf: 'flex-start',
    minWidth: 140,
    ...BUTTON_SHADOW,
    ...(Platform.OS === 'web'
      ? {
          // @ts-ignore
          cursor: 'pointer',
        }
      : {}),
  },
  filterButtonText: {
    fontFamily: 'Lato-Bold',
    color: 'black',
    fontSize: 16,
  },
});
