import { StyleSheet, Text, View } from 'react-native';
import TakeActionCallToAction, {
  ITakeActionCallToActionCampaign,
} from '/components/TakeAction/TakeActionCallToAction';
import { KEY_GRAY } from '/constants';
import { Campaign, User } from '/generated/graphql';

interface ICampaign
  extends ITakeActionCallToActionCampaign,
    Pick<Campaign, 'closed'> {}

interface ICampaignPostFooterComponentProps {
  preview: boolean;
  hidden: boolean;
  campaign: ICampaign | undefined | null;
  user: Pick<User, 'id'> | undefined | null;
}

export default function CampaignPostFooter(
  props: ICampaignPostFooterComponentProps,
) {
  return props.hidden ? null : (
    <>
      {props.campaign?.closed !== true ? (
        <View
          pointerEvents={props.preview ? 'none' : 'auto'}
          style={{
            width: '100%',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          <TakeActionCallToAction data={props.campaign} />
        </View>
      ) : props.campaign?.closed === true ? (
        <View style={styles.closedContainer}>
          <Text style={styles.closedText}>This is a closed campaign.</Text>
          <Text>No support is currently needed.</Text>
        </View>
      ) : null}
    </>
  );
}

const styles = StyleSheet.create({
  closedContainer: {
    borderRadius: 6,
    marginHorizontal: 8,
    marginTop: 12,
    backgroundColor: '#f5f5f5',
    padding: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  closedText: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
  },
  bigIssuesLinkContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  bigIssuesLinkText: {
    flex: 1,
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
  },
});
