import {
  View,
  Text,
  Pressable,
  TextInput,
  StyleProp,
  ViewStyle,
} from 'react-native';
import React, { useCallback, useEffect, useMemo } from 'react';
import styles from './NumberInput.style';

interface Props {
  value: number;
  onChange?: (value: number) => void;
  style?: StyleProp<ViewStyle>;
  minValue?: number;
  maxValue?: number;
}

export default function NumberInput({
  onChange: props_onChange,
  ...props
}: Props) {
  const clampValue = useCallback(
    function (value: number) {
      let newValue = value;

      if (typeof props.minValue === 'number')
        newValue = Math.max(newValue, props.minValue);
      if (typeof props.maxValue === 'number')
        newValue = Math.min(newValue, props.maxValue);

      return newValue;
    },
    [props.minValue, props.maxValue],
  );

  const renderedValue = useMemo(() => {
    return typeof props.value === 'number'
      ? clampValue(props.value) + ''
      : 0 + '';
  }, [props.value, clampValue]);

  useEffect(() => {
    let newValue = clampValue(props.value);

    if (newValue !== props.value) props_onChange?.(newValue);
  }, [props.value, props_onChange, clampValue]);

  function increment() {
    const newValue = clampValue(props.value + 1);

    props_onChange?.(newValue);
  }

  function decrement() {
    const newValue = clampValue(props.value - 1);

    props_onChange?.(newValue);
  }

  function onChange(text: string | number) {
    const clean =
      typeof text === 'number' ? text : text?.replace(/[^0-9]/g, '');
    let newValue = typeof clean === 'number' ? clean : parseInt(clean);
    if (isNaN(newValue)) newValue = 0;

    /** Make sure new value is within set limits */
    newValue = clampValue(newValue);

    props_onChange?.(newValue);
  }

  return (
    <View style={[styles.container, props.style]}>
      <Pressable
        testID="decrement-button"
        disabled={props.minValue === props.value}
        style={({ pressed }) => [
          styles.button,
          {
            backgroundColor: pressed
              ? '#ddd'
              : props.minValue === props.value
              ? '#ccc'
              : undefined,
          },
        ]}
        onPress={decrement}
      >
        <Text style={styles.buttonLabel}>-</Text>
      </Pressable>
      <TextInput
        testID="input-field"
        inputMode="numeric"
        style={styles.input}
        value={renderedValue}
        onChangeText={onChange}
      />
      <Pressable
        testID="increment-button"
        disabled={props.maxValue === props.value}
        style={({ pressed }) => [
          styles.button,
          {
            backgroundColor: pressed
              ? '#ddd'
              : props.maxValue === props.value
              ? '#ccc'
              : undefined,
          },
        ]}
        onPress={increment}
      >
        <Text style={styles.buttonLabel}>+</Text>
      </Pressable>
    </View>
  );
}
