import React, { useState } from 'react';
import ContentCardResultsBase, {
  ContentCardResultsProps,
} from './ContentCardResultsBase';
import {
  GetGroupsInBoundsQuery,
  MapBounds,
  useGetGroupsInBoundsQuery,
} from '/generated/graphql';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import GroupCard from '/components/GroupCard/GroupCard';

export default function ContentCardResultsGroups(
  props: ContentCardResultsProps,
) {
  const navigation = useNavigation<StackNavigationProp<any>>();

  const [nextToken, setNextToken] = useState<string>();

  const [{ data, fetching, stale, error }, refetch] = useGetGroupsInBoundsQuery(
    {
      variables: {
        bounds: {
          northeast: {
            latitude: props.bounds?.northeast.latitude,
            longitude: props.bounds?.northeast.longitude,
          },
          southwest: {
            latitude: props.bounds?.southwest.latitude,
            longitude: props.bounds?.southwest.longitude,
          },
        },
        query: props.query,
        nextToken,
      },
    },
  );

  return (
    <ContentCardResultsBase
      hasError={!!error}
      total={data?.getGroupsInBounds.total ?? 0}
      loading={fetching}
      stale={stale}
      hasMore={!!data?.getGroupsInBounds.nextToken}
      onRetry={refetch}
      onClose={props.onClose}
      data={data?.getGroupsInBounds.items ?? []}
      renderItem={({ item }) => {
        return (
          <GroupCard
            item={
              item as GetGroupsInBoundsQuery['getGroupsInBounds']['items'][0]
            }
            onPress={() => {
              navigation.navigate('ViewGroup', {
                id: item.id,
              });
            }}
          />
        );
      }}
      onPaginate={() => {
        setNextToken(data?.getGroupsInBounds.nextToken ?? undefined);
      }}
    />
  );
}
