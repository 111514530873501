import React from 'react';
import { ActivityIndicator, Text, View } from 'react-native';
import Button from '../Button';
import Carousel, { CarouselCardData } from '../Carousel/Carousel';
import styles from './BigIssuesCarousel.style';
import ViewOrganizationBigIssueModal from './ViewOrganizationBigIssueModal';
import { KEY_GRAY } from '/constants';
import { CarouselCard, Maybe, OrganizationBigIssue } from '/generated/graphql';

interface IOrganizationBigIssue
  extends Pick<OrganizationBigIssue, 'id' | 'title'> {
  main_carousel?: Maybe<
    Pick<CarouselCard, 'media' | 'thumbnail' | 'caption'>[]
  >;
}

interface Props {
  disableInteraction?: boolean;
  data: IOrganizationBigIssue[] | undefined;
  fetching?: boolean;
  error?: any;
  isEditing?: boolean;
  textColor?: string;
  buttonColor?: string;
  buttonLabelColor?: string;
  refetch?: () => void;
  onEditPressed?: (id: string) => void;
  onCarouselChange?: (data: CarouselCardData[]) => void;
}

export default function BigIssuesCarousel(props: Props) {
  const { data, fetching, error, refetch } = props;

  const [viewingId, setViewingId] = React.useState<string | undefined>();

  function onLearnMorePressed(id: string) {
    setViewingId(id);
  }

  function onViewModalRequestClose() {
    setViewingId(undefined);
  }

  return (
    <>
      <ViewOrganizationBigIssueModal
        visible={viewingId !== undefined}
        data={data?.find((i) => i.id === viewingId)}
        onRequestClose={onViewModalRequestClose}
      />

      {!data && fetching ? (
        <ActivityIndicator
          style={{
            padding: 24,
            alignSelf: 'center',
          }}
          color={props.textColor ?? KEY_GRAY}
        />
      ) : error ? (
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>
            Something went wrong while fetching The Big Issues
          </Text>
          <Button
            label="Retry"
            containerStyle={{
              marginTop: 8,
            }}
            onPress={() => refetch?.()}
          />
        </View>
      ) : (
        <Carousel
          itemHeight={400}
          editMode="add-remove-only"
          addCardPromptText="Educate people about the problems your organization faces"
          isEditing={props.isEditing}
          onChange={props.onCarouselChange}
          captionStyle={{
            color: props.textColor ?? 'black',
          }}
          titleStyle={{
            color: props.textColor ?? 'black',
          }}
          data={
            props.data?.map((item) => ({
              media: item.main_carousel?.[0]?.media,
              thumbnail: item.main_carousel?.[0]?.thumbnail,
              caption: item.main_carousel?.[0]?.caption,
              title: item.title?.text,
              footerComponent: props.isEditing ? (
                <Button
                  disabled={props.disableInteraction}
                  label="Edit"
                  onPress={() => props.onEditPressed?.(item.id)}
                />
              ) : (
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    disabled={props.disableInteraction}
                    containerStyle={{
                      maxWidth: 200,
                      alignSelf: 'center',
                    }}
                    style={{
                      backgroundColor: props.buttonColor ?? KEY_GRAY,
                    }}
                    labelStyle={{
                      color: props.buttonLabelColor ?? 'white',
                      padding: 8,
                    }}
                    label="LEARN MORE"
                    onPress={() => onLearnMorePressed(item.id)}
                  />
                </View>
              ),
            })) as CarouselCardData[]
          }
        />
      )}
    </>
  );
}
