import { Entypo } from '@expo/vector-icons';
import React from 'react';
import { Pressable, Text } from 'react-native';
import styles from '../_common.style';
import ResearchTopicPickerModal from '../../ResearchTopicPIckerModal/ResearchTopicPickerModal';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import { useModalContext } from '/context';
import { shorten } from '/util';

type Props = {
  topics: string[];
  onChange: (topics: string[]) => void;
};

export default function ResearchTopicFilterButton({ topics, onChange }: Props) {
  const { closeModal, spawnModal } = useModalContext();

  const modalId = React.useRef<string | null>(null);
  function onPress() {
    modalId.current = spawnModal({
      title: 'Filter by Research Topics',
      precedence: 1,
      style: {
        height: '100%',
        width: '100%',
        maxHeight: 720,
        maxWidth: 800,
      },
      component: (
        <ResearchTopicPickerModal
          initialState={topics}
          onRequestClose={(topicsFilter) => {
            onChange(topicsFilter);
            if (modalId.current) closeModal(modalId.current);
          }}
        />
      ),
    });
  }

  const label = (() => {
    if (!topics.length) {
      return 'Any';
    } else if (topics.length === 1) {
      return shorten(topics[0], 28);
    } else {
      return `${topics.length} topics`;
    }
  })();

  return (
    <Pressable onPress={onPress} style={styles.filterButton}>
      <HorizontalContainer
        style={{
          justifyContent: 'space-between',
        }}
      >
        <Text numberOfLines={1} style={styles.filterButtonText}>
          {label}
        </Text>
        <Entypo
          name="chevron-small-down"
          size={20}
          style={{
            width: 20,
            height: 20,
          }}
          color="gray"
        />
      </HorizontalContainer>
    </Pressable>
  );
}
