import {
  CommonActions,
  NavigationContext,
  NavigationRouteContext,
  ParamListBase,
  TabNavigationState,
} from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import {
  Animated,
  Image,
  LayoutChangeEvent,
  Platform,
  ScrollView,
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { EdgeInsets, useSafeAreaFrame } from 'react-native-safe-area-context';

import { BottomTabBarHeightCallbackContext } from '@react-navigation/bottom-tabs';
import { BottomTabDescriptorMap } from '@react-navigation/bottom-tabs/lib/typescript/src/types';
import _ from 'lodash';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  maxSize,
} from 'rn-responsive-styles';
import { TabNavigationConfig } from '../navigators/createResponsiveTabNavigator';
import ResponsiveTabItem from './ResponsiveTabItem';
import { useAuthContext } from '/context';
import { ArrayElement } from '/types';

type ResponsiveTabBarProps = TabNavigationConfig['tabBarOptions'] & {
  state: TabNavigationState<ParamListBase>;
  insets: EdgeInsets;
  navigation: any;
  descriptors: any;
};

export type NavigationRoute = ArrayElement<
  TabNavigationState<ParamListBase>['routes']
>;

type Options = {
  state: TabNavigationState<ParamListBase>;
  descriptors: BottomTabDescriptorMap;
  layout: { height: number; width: number };
  dimensions: { height: number; width: number };
};

const getPaddingBottom = (insets: EdgeInsets) =>
  Math.max(insets.bottom - Platform.select({ ios: 4, default: 0 }), 0);

const DEFAULT_TABBAR_HEIGHT = 49;
const COMPACT_TABBAR_HEIGHT = 32;

/** Based on @react-navigation/bottom-tabs */
export const getTabBarHeight = ({
  state,
  descriptors,
  dimensions,
  insets,
  style,
  ...rest
}: Options & {
  insets: EdgeInsets;
  style: Animated.WithAnimatedValue<StyleProp<ViewStyle>> | undefined;
}) => {
  // @ts-ignore
  const customHeight = StyleSheet.flatten(style)?.height;

  if (typeof customHeight === 'number') {
    return customHeight;
  }

  const isLandscape = dimensions.width > dimensions.height;
  const horizontalLabels = rest.layout.width > 540;
  const paddingBottom = getPaddingBottom(insets);

  // If we are showing horizontal labels, we are not rendering anything on the bottom
  if (horizontalLabels) return 0;

  if (Platform.OS === 'ios' && !Platform.isPad && isLandscape) {
    return COMPACT_TABBAR_HEIGHT + paddingBottom;
  }

  return DEFAULT_TABBAR_HEIGHT + paddingBottom;
};

export default function ResponsiveTabBar({
  state,
  navigation,
  descriptors,
  activeBackgroundColor,
  activeTintColor,
  iconHorizontal = false,
  allowFontScaling,
  inactiveBackgroundColor,
  inactiveTintColor,
  labelStyle,
  showIcon,
  showLabel,
  iconSize,
  labelSize,
  insets,
  style,
  tabStyle,
}: ResponsiveTabBarProps) {
  const { styles, deviceSize } = useStyles();

  const { userData, isAdmin, userAttributes } = useAuthContext();

  const [routes, setRoutes] = useState<NavigationRoute[]>([]);
  const [adminRoutes, setAdminRoutes] = useState<NavigationRoute[]>([]);

  const paddingBottom = getPaddingBottom(insets);

  // Initializer effect
  useEffect(() => {
    const _routes: NavigationRoute[] = [];
    const _adminRoutes: NavigationRoute[] = [];

    state.routes.forEach((route) => {
      const desc = descriptors[route.key];
      const isAdminRoute = desc?.options.isAdminScreen;

      if (isAdminRoute) _adminRoutes.push(route);
      else _routes.push(route);
    });

    if (!_.isEqual(routes, _routes)) {
      setRoutes(_routes);
    }

    if (!_.isEqual(adminRoutes, _adminRoutes)) {
      setAdminRoutes(_adminRoutes);
    }
  }, [adminRoutes, descriptors, routes, state.routes, userData]);

  const renderTabBarItems = (_routes: NavigationRoute[]) =>
    _routes.map((route, index) => {
      const desc = descriptors[route.key];

      if (!desc) return;

      const { options } = desc;
      if (options.hide) {
        return null;
      }

      const focused = route.name === state.routes[state.index].name;

      const onPress = () => {
        const event = navigation.emit({
          type: 'tabPress',
          target: route.key,
          canPreventDefault: true,
        });

        if (!focused && !event.defaultPrevented) {
          if (options.authRequired && !userAttributes?.sub) {
            navigation.dispatch(
              CommonActions.navigate({
                name: 'auth',
              }),
            );
          } else {
            navigation.dispatch({
              ...CommonActions.navigate({
                name: route.name,
              }),
              target: state.key,
            });
          }
        }
      };

      const onLongPress = () => {
        navigation.emit({
          type: 'tabLongPress',
          target: route.key,
        });
      };

      const label =
        options.tabBarLabel !== undefined
          ? options.tabBarLabel
          : options.title !== undefined
          ? options.title
          : route.name;

      const ariaLabel =
        options.tabBarAccessibilityLabel !== undefined
          ? options.tabBarAccessibilityLabel
          : typeof label === 'string'
          ? `${label}, tab, ${index + 1} of ${_routes.length}`
          : undefined;

      return (
        <NavigationContext.Provider
          key={route.key}
          value={descriptors[route.key].navigation}
        >
          <NavigationRouteContext.Provider value={route}>
            {/* If auth is required for this route and we're not signed in, don't render it the in tab bar */}

            <ResponsiveTabItem
              route={route}
              focused={focused}
              horizontal={iconHorizontal}
              onPress={onPress}
              onLongPress={onLongPress}
              ariaLabel={ariaLabel}
              testID={options.tabBarTestID}
              allowFontScaling={allowFontScaling}
              activeTintColor={activeTintColor}
              inactiveTintColor={inactiveTintColor}
              activeBackgroundColor={activeBackgroundColor}
              inactiveBackgroundColor={inactiveBackgroundColor}
              button={options.tabBarButton}
              iconSize={iconSize}
              labelSize={labelSize}
              icon={options.tabBarIcon}
              label={label}
              showIcon={showIcon}
              showLabel={
                deviceSize === DEVICE_SIZES.EXTRA_LARGE_DEVICE
                  ? showLabel
                  : false
              }
              labelStyle={labelStyle}
              style={tabStyle}
            />
          </NavigationRouteContext.Provider>
        </NavigationContext.Provider>
      );
    });

  const dimensions = useSafeAreaFrame();
  const [layout, setLayout] = useState({
    height: 0,
    width: dimensions.width,
  });

  const handleLayout = (e: LayoutChangeEvent) => {
    const { height, width } = e.nativeEvent.layout;

    onHeightChange?.(height);

    setLayout((prevLayout) => {
      if (height === prevLayout.height && width === prevLayout.width) {
        return prevLayout;
      } else {
        return {
          height,
          width,
        };
      }
    });
  };

  const onHeightChange = React.useContext(BottomTabBarHeightCallbackContext);

  const tabBarHeight = getTabBarHeight({
    state,
    descriptors,
    insets,
    dimensions,
    layout,
    style: [style],
  });

  return (
    <View
      style={[
        styles('tabBar'),
        {
          paddingHorizontal: Math.max(insets.left, insets.right),
          // overflow: 'hidden',
          paddingBottom,
          height: tabBarHeight,
        },
        style,
      ]}
      pointerEvents={'auto'}
      onLayout={handleLayout}
    >
      <ScrollView
        style={[
          styles('contentWrapper'),
          {
            paddingBottom:
              paddingBottom + Platform.select({ default: 0, ios: 2 }),
          },
        ]}
        scrollEnabled={
          deviceSize === DEVICE_SIZES.EXTRA_SMALL_DEVICE ? false : undefined
        }
        contentContainerStyle={[styles('content')]}
      >
        <TouchableOpacity style={styles('keyLogoContainer')} onPress={() => {}}>
          <Image
            style={styles('keyLogo')}
            source={require('/assets/images/keyFullBlack.png')}
          />
        </TouchableOpacity>
        <View style={[styles('tabItemContainer')]}>
          {renderTabBarItems(routes)}
          {/* Admin-only tabs */}
          <View
            style={[
              {
                display: isAdmin ? 'flex' : 'none',
              },
              styles('adminSectionContainer'),
            ]}
          >
            <View
              style={{
                display: adminRoutes.length ? 'flex' : 'none',
                width: '100%',
              }}
            >
              <Text style={styles('adminSectionTitle')}>Admin</Text>
            </View>
            {renderTabBarItems(adminRoutes)}
          </View>
        </View>
      </ScrollView>
    </View>
  );
}

const useStyles = CreateResponsiveStyle(
  {
    tabBar: {
      flex: 1,
      // maxWidth: '20%',
      zIndex: 2,
      borderTopColor: '#ccc',
      alignItems: 'flex-end',
    },
    contentWrapper: {
      flex: 1,
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 10,
    },
    content: {
      flex: 1,
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      paddingBottom: 40,
    },
    tabItemContainer: {
      alignItems: 'flex-start',
      paddingBottom: 48,
    },
    adminSectionContainer: {
      alignItems: 'flex-start',
    },
    adminSectionTitle: {
      borderTopWidth: 1,
      borderTopColor: 'gray',
      paddingTop: 16,
      marginTop: 8,
      width: '100%',
      fontSize: 17,
      color: 'crimson',
      fontFamily: 'Lato-Bold',
    },
    keyLogoContainer: {
      width: 200,
      height: 100,
      marginVertical: 48,
      justifyContent: 'center',
      alignItems: 'center',
    },
    keyLogo: {
      width: 100,
      height: 100,
    },
  },
  {
    [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
      tabBar: {
        alignItems: 'center',
      },
      tabItemContainer: {
        alignItems: 'center',
      },
      adminSectionContainer: {
        alignItems: 'center',
      },
      keyLogo: {
        width: 56,
        height: 56,
      },
    },
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      keyLogo: {
        width: 48,
        height: 48,
      },
    },
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      keyLogoContainer: {
        display: 'none',
      },
      tabBar: {
        flex: undefined,
        left: 0,
        right: 0,
        bottom: 0,
        maxWidth: '100%',
        borderTopWidth: StyleSheet.hairlineWidth,
      },
      tabItemContainer: {
        flex: 1,
        paddingBottom: 0,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      },
      content: {
        maxWidth: '100%',
        paddingBottom: 0,
      },
      adminSectionContainer: {
        display: 'none',
      },
    },
  },
);
