import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {},
  selectionContainer: {
    paddingVertical: 8,
  },
  selectionItemContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 4,
  },
  selectionItemRemoveButton: {
    padding: 12,
    marginLeft: 4,
  },
  paginationButton: {
    padding: 10,
  },
  paginationContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: 240,
    alignSelf: 'center',
    marginVertical: 8,
  },
  paginationText: {
    flex: 1,
    textAlign: 'center',
    fontFamily: 'Lato-Bold',
    color: 'gray',
    fontSize: 15,
  },
});
