import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { Platform, Pressable, Text, View, ViewStyle } from 'react-native';
import Button from '../Button';
import styles from './DonationStatusWidget.style';
import {
  ALERT_RED,
  ALERT_YELLOW,
  KEY_GRAY,
  KEY_GREEN,
  KEY_LIGHT_GRAY,
  SECTION_CONTAINER,
} from '/constants';
import useAccountDonationStatus from '/hooks/useAccountDonationStatus';

interface Props {
  hideGoToSettingsButton?: boolean;
  style?: ViewStyle;
}

export default function DonationStatusWidget(props: Props) {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const {
    status,
    error: donationStatusError,
    fetching: fetchingDonationStatus,
    refresh,
  } = useAccountDonationStatus();

  const onRetry = () => {
    if (donationStatusError) {
      refresh();
    }
  };

  const onGoToSettings = () => {
    navigate('DonationSettings');
  };

  let backgroundColor = KEY_LIGHT_GRAY;

  if (fetchingDonationStatus) {
    backgroundColor = KEY_LIGHT_GRAY;
  } else if (donationStatusError || status === 'inactive') {
    backgroundColor = ALERT_RED;
  } else if (status === 'active') {
    backgroundColor = KEY_GREEN;
  } else if (status === 'active_action_required') {
    backgroundColor = ALERT_YELLOW;
  }

  let statusText = 'Your account is not set up to receive donations.';

  if (fetchingDonationStatus) {
    statusText = 'Loading...';
  } else if (donationStatusError) {
    statusText = `Failed to get status. ${
      Platform.OS === 'web' ? 'Click' : 'Tap'
    } to retry.`;
  } else if (status === 'active') {
    statusText = 'Your account is receiving donations.';
  } else if (status === 'active_action_required') {
    statusText = 'Your account is receiving donations, but action is required.';
  } else if (status === 'inactive') {
    statusText = 'Your account is not receiving donations.';
  }

  let statusIcon = 'information-circle';

  if (fetchingDonationStatus) {
    // Clock
    statusIcon = 'time';
  } else if (donationStatusError) {
    // Reload
    statusIcon = 'reload';
  } else if (status === 'active') {
    // Checkmark
    statusIcon = 'checkmark-circle';
  } else if (status === 'active_action_required' || status === 'inactive') {
    // Warning
    statusIcon = 'warning';
  }

  return (
    <Pressable
      disabled={!donationStatusError}
      onPress={() => {
        onRetry();
      }}
      style={[
        SECTION_CONTAINER,
        {
          backgroundColor,
        },
        props.style,
      ]}
    >
      <View style={styles.horizontalContent}>
        <Ionicons name={statusIcon as any} size={32} color={KEY_GRAY} />
        <Text style={styles.statusText}>{statusText}</Text>
      </View>

      {!props.hideGoToSettingsButton && (
        <Button
          onPress={onGoToSettings}
          containerStyle={{ marginTop: 6, alignSelf: 'flex-end' }}
          label="Go to Settings"
        />
      )}
    </Pressable>
  );
}
