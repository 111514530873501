import { createStackNavigator } from '@react-navigation/stack';
import OrgDataSurveySetup from '/screens/OrgDataSurveySetup';

const Stack = createStackNavigator<DataSurveyStackParamList>();

export type DataSurveyStackParamList = {
  DataQ: {};
  DataQOne: {};
  DataQTwo: {};
  MonthlyDonations: {};
};

export default function DataSurveyStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        title: 'Account Setup',
      }}
      initialRouteName="DataQ"
    >
      <Stack.Screen
        component={OrgDataSurveySetup}
        name="DataQ"
        options={{
          title: 'Onboard',
        }}
      />
    </Stack.Navigator>
  );
}
