import { SearchBar } from '@rneui/themed';
import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { RouteProp } from '@react-navigation/native';

import { Ionicons } from '@expo/vector-icons';

import { StackNavigationProp } from '@react-navigation/stack';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
  NavigationState,
  SceneMap,
  SceneRendererProps,
  TabView,
} from 'react-native-tab-view';
import ResearchTopicSearch from '/components/Search/ResearchTopicSearch';
import SkillSearch from '/components/Search/SkillSearch';
import SpeciesSearch from '/components/Search/SpeciesSearch';
import UserSearch from '/components/Search/UserSearch';
import TabBar from '/components/TabBar/TabBar';
import { KEY_GRAY, TOTAL_SPECIES } from '/constants';
import { UserRole } from '/generated/graphql';
import { formatMoney } from '/util';
import GroupSearch from '/components/Search/GroupSearch';
import useDebouncedState from '/hooks/useDebouncedState';

const TABS = [
  {
    key: 'organizations',
    title: 'Organizations',
  },
  {
    key: 'people',
    title: 'People',
  },
  // {
  //   key: 'campaigns',
  //   title: 'Campaigns',
  // },
  {
    key: 'species',
    title: 'Species',
  },
  {
    key: 'skills',
    title: 'Skills',
  },
  {
    key: 'topics',
    title: 'Topics',
  },
  {
    key: 'groups',
    title: 'Groups',
  },
];

interface Props {
  navigation: StackNavigationProp<any>;
  route: RouteProp<any>;
}

const SearchScreen = (props: Props) => {
  const { navigate, goBack } = props.navigation;

  const { top: safeAreaInsetTop } = useSafeAreaInsets();

  const [tabNavigationState, setTabNavigationState] = useState({
    index: !isNaN(Number(props.route.params?.t))
      ? Number(props.route.params?.t)
      : 0,
    routes: TABS,
  });

  const [query, queryDebounced, _setQuery] = useDebouncedState(
    props.route.params?.q || '',
  );

  const setQuery = useCallback(
    (q: string) => {
      _setQuery(q);
      props.navigation.setParams({ q });
    },
    [_setQuery, props.navigation],
  );

  const onSelectSpecies = useCallback(
    function (taxonID: number, name: string) {
      navigate('SpeciesSummary', { taxonID: taxonID, name });
    },
    [navigate],
  );

  const handleIndexChange = (index: number) => {
    props.navigation.setParams({ t: index });
    setTabNavigationState((prevState) => ({
      ...prevState,
      index,
    }));
  };

  const renderTabBar = ({
    navigationState,
  }: SceneRendererProps & {
    navigationState: NavigationState<{
      key: string;
      title: string;
    }>;
  }) => {
    return (
      <TabBar
        index={navigationState.index}
        onChange={handleIndexChange}
        tabs={navigationState.routes}
        containerStyle={{ width: '100%' }}
      />
    );
  };

  const renderScene = useMemo(
    () =>
      getTabScene({
        queryDebounced,
        onSelectSpecies,
      }),
    [onSelectSpecies, queryDebounced],
  );

  return (
    <View style={[styles.container, { paddingTop: safeAreaInsetTop }]}>
      <View style={styles.header}>
        <TouchableOpacity
          onPress={() => {
            goBack();
          }}
          style={styles.backButton}
        >
          <Ionicons name="arrow-back-sharp" size={32} color={KEY_GRAY} />
        </TouchableOpacity>
        <SearchBar
          containerStyle={styles.searchBar}
          inputContainerStyle={styles.innerSearchBar}
          inputStyle={styles.inputSearchBar}
          searchIcon={{
            size: 24,
            color: KEY_GRAY,
          }}
          autoCorrect={false}
          platform="default"
          placeholder={
            'Search' +
            (tabNavigationState.index ===
            TABS.findIndex((t) => t.key === 'species')
              ? ` ${formatMoney(TOTAL_SPECIES, 0)} species`
              : ``)
          }
          onChangeText={(value) => {
            setQuery(value);
          }}
          value={query}
        />
      </View>
      <TabView
        sceneContainerStyle={{
          flex: 1,
          height: '100%',
          width: '100%',
        }}
        lazy={true}
        style={{ flex: 1 }}
        navigationState={tabNavigationState}
        renderScene={renderScene}
        renderTabBar={renderTabBar}
        onIndexChange={handleIndexChange}
      />
    </View>
  );
};

interface ITabSceneProps {
  queryDebounced: string;
  onSelectSpecies: (taxonID: number, name: string) => void;
}

function getTabScene(options: ITabSceneProps) {
  return SceneMap({
    organizations: (_props: any) => (
      <UserSearch
        query={options.queryDebounced}
        role={UserRole.Conservationist}
        {..._props}
      />
    ),
    people: (_props: any) => (
      <UserSearch
        query={options.queryDebounced}
        role={UserRole.Supporter}
        {..._props}
      />
    ),
    // campaigns: (_props: any) => <CampaignSearch query={query} {..._props} />,
    species: (_props: any) => (
      <SpeciesSearch
        pauseWhenEmpty
        resultsPerPage={20}
        query={options.queryDebounced}
        {..._props}
        onSelectSpecies={(species, name) =>
          options.onSelectSpecies(species.taxonID, name)
        }
      />
    ),
    skills: (_props: any) => (
      <SkillSearch query={options.queryDebounced} {..._props} />
    ),
    topics: (_props: any) => (
      <ResearchTopicSearch query={options.queryDebounced} {..._props} />
    ),
    groups: (_props: any) => (
      <GroupSearch query={options.queryDebounced} {..._props} />
    ),
  });
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
    backgroundColor: '#fff',
  },
  backButton: {
    backgroundColor: '#fff',
    marginLeft: 5,
  },
  searchBar: {
    width: '95%',
    backgroundColor: '#fff',
    borderRadius: 0,
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent',
    paddingHorizontal: 20,
  },
  innerSearchBar: {
    width: '100%',
    backgroundColor: '#F2F2FB',
    borderColor: 'transparent',
  },
  inputSearchBar: {
    width: '100%',
    backgroundColor: '#F2F2FB',
    borderColor: 'transparent',
    color: 'black',
  },
  tabBar: {
    maxHeight: 48,
    width: '100%',
    zIndex: 1,
    flexDirection: 'row',
    backgroundColor: 'white',
  },
});

export default SearchScreen;
