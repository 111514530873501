import React, { useState } from 'react';
import { Text, TextInput, TouchableOpacity, View } from 'react-native';
import styles from './ConfirmForm.style';
import Alert from '/Alert';
import { KEY_GRAY, KEY_GREEN } from '/constants';
import { useAuthContext, useLoadingContext } from '/context';

interface IConfirmFormProps {
  email: string;
  onConfirmSuccess: () => void;
  onCancel: () => void;
}

export default function ConfirmForm(props: IConfirmFormProps) {
  const { confirmSignup, resendSignup } = useAuthContext();
  const { setShowLoading }: any = useLoadingContext();

  const [code, setCode] = useState('');

  const validateCode = () => {
    setShowLoading(true);

    confirmSignup(props.email, code)
      .then(() => {
        props.onConfirmSuccess();
      })
      .catch((error) => {
        // If for some reason we are trying to confirm a account that has already been confirmed,
        // consider confirmation complete.
        if (
          error.message ===
          'User cannot be confirmed. Current status is CONFIRMED'
        ) {
          props.onConfirmSuccess();
          return;
        }

        const errorMessage =
          error?.message ||
          'We ran into a problem while confirming your account. Please try again later.';

        console.log(error);

        Alert.alert('Warning', errorMessage);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const resendCode = () => {
    const resend = () =>
      resendSignup(props.email)
        .then(() => {
          Alert.alert('Success', 'Confirmation code re-sent!');
        })
        .catch(() => {});

    Alert.alert(
      'Resend Code',
      'Before getting a new code, make sure you have checked your spam/junk inbox and that your email is spelled correctly.',
      [
        {
          text: 'Resend',
          style: 'default',
          onPress: resend,
        },
        {
          style: 'cancel',
        },
      ],
    );
  };

  return (
    <>
      <View style={styles.inputContainer}>
        <Text
          style={{
            fontFamily: 'Lato-Bold',
            fontSize: 17,
            color: '#fafafa',
            marginBottom: 10,
            textAlign: 'center',
          }}
        >
          We've sent a verification code to{' '}
          <Text
            style={{
              color: KEY_GREEN,
            }}
          >
            {props.email}
          </Text>
          . Please check your inbox and enter the code here. If you cannot find
          it, try checking your spam/junk inbox.
        </Text>
        <View style={styles.inputField}>
          {/* <Envelope /> */}
          <TextInput
            keyboardType="number-pad"
            placeholder="CONFIRMATION CODE"
            placeholderTextColor="#fafafa"
            enterKeyHint="next"
            style={styles.input}
            textContentType="oneTimeCode"
            autoCapitalize="none"
            autoCorrect={false}
            value={code}
            onChangeText={(text) => {
              // Set code to text, only allowing numbers
              setCode(text.replace(/[^0-9]/g, ''));
            }}
          />
        </View>
        <TouchableOpacity style={styles.buttonContainer} onPress={validateCode}>
          <Text style={styles.button}>CONFIRM CODE</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.buttonContainer, { backgroundColor: KEY_GRAY }]}
          onPress={props.onCancel}
        >
          <Text style={[styles.button, { color: 'white' }]}>CANCEL</Text>
        </TouchableOpacity>
        <View style={styles.footnoteContainer}>
          <TouchableOpacity onPress={resendCode}>
            <Text style={styles.footnote}>Need a new confirmation code?</Text>
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
}
