import React from 'react';
import { StyleProp, Text, TextStyle } from 'react-native';

interface Props {
  style?: StyleProp<TextStyle>;
}

export default function ErrorText(props: React.PropsWithChildren<Props>) {
  return <Text style={[style, props.style]}>{props.children}</Text>;
}

const style: TextStyle = {
  fontFamily: 'Lato-Bold',
  color: 'crimson',
  fontSize: 15,
  marginBottom: 4,
};
