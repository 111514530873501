import { FontAwesome5 } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { Text, View } from 'react-native';
import DonationCard from '/components/DonationCard/DonationCard';
import TruncatedList from '/components/TruncatedList/TruncatedList';
import { SECTION_CONTAINER } from '/constants';
import { useAuthContext } from '/context';
import { useGetMyDonationsQuery } from '/generated/graphql';
import styles from './MyDonations.style';
import ListLoading from '/components/ListLoading';
import GenericError from '../../common/Generic/GenericError';

export default function MyDonations() {
  const { userData, fetching: fetchingUser } = useAuthContext();

  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const [{ data, fetching, error }, refetch] = useGetMyDonationsQuery({
    pause: !userData?.onboarded,
  });

  const onViewAllMyDonations = () => {
    navigate('ListMyDonations');
  };

  const donations = data?.me?.donations;

  return (
    <View style={SECTION_CONTAINER}>
      <View style={styles.headerContainer}>
        <View style={styles.iconContainer}>
          <FontAwesome5 name="gratipay" size={21} color="black" />
        </View>
        <Text style={styles.headerText}>My Donations</Text>
      </View>

      {fetching || (!userData && fetchingUser) ? (
        <ListLoading />
      ) : error ? (
        <GenericError onRetry={refetch} />
      ) : donations?.items.length === 0 || !userData ? (
        <Text style={styles.noDonationsText}>
          You haven't donated to any campaigns yet.
        </Text>
      ) : (
        <TruncatedList
          items={donations?.items ?? []}
          total={donations?.total ?? 0}
          maxLength={3}
          renderItem={(item) => {
            const user = {
              id: userData.id,
              profile_image: userData?.profile_image,
              name: 'You',
              locations: [],
            };

            const donation = {
              ...item,
              donor: user,
            };

            return (
              <DonationCard
                showCampaignPreview
                showAnonymousFlag
                donation={donation}
              />
            );
          }}
          onViewMore={() => onViewAllMyDonations()}
        />
      )}
    </View>
  );
}
