import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

interface Props {
  style?: StyleProp<ViewStyle>;
}

export default function HorizontalContainer(
  props: React.PropsWithChildren<Props>,
) {
  return <View style={[style, props.style]}>{props.children}</View>;
}

const style: ViewStyle = {
  flexDirection: 'row',
  alignItems: 'center',
};
