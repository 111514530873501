import {
  getPathFromState,
  getStateFromPath,
  LinkingOptions,
} from '@react-navigation/native';
import * as Linking from 'expo-linking';

const prefix = Linking.createURL('/');

const truncatePathSuffixes = ['/onboard', '/setupsurvey', '/profile/edit'];

const common = {
  ListCampaigns: {
    path: 'campaign/list',
    parse: {
      filter: (value: any) => {
        return JSON.parse(decodeURIComponent(value as any));
      },
    },
    stringify: {
      filter: (value: any) => {
        return encodeURIComponent(JSON.stringify(value as any));
      },
    },
  },
  Bookmarks: 'bookmarks',
  Profile: '/user/:id',
  ViewSpeciesUserContent: 'species-user-content',
  CreateCreativeConnectProject: 'creative-connect/create',
  EditCreativeConnectProject: 'creative-connect/:id/edit',
  CreativeConnectDashboard: 'creative-connect/:id/dashboard',
  ViewCreativeConnectLanding: 'creative-connect/:id/landing',
  ApplyToCreativeConnectProject: 'creative-connect/:id/apply',
  ListCreativeConnectCollaborators: 'creative-connect/:id/collaborators',
  ListCreativeConnectProjects: 'creative-connect/browse',
  ManageCampaign: 'campaign/:campaignId/manage',
  CloseCampaign: 'campaign/:campaignId/close',
  Comments: { path: 'comments/:interactionsId' },
  CreateCampaignUpdate: 'post/create',
  ManageSkillRequest: 'skill-request/:requestId/manage',
  ManageVolunteerRequest: 'volunteer/:requestId/manage',
  CreateGroup: 'group/create',
  EditGroupDiscussionCategories: 'group/:groupId/edit/categories',
  EditGroup: 'group/:id/edit',
  ManageGroup: 'group/:id/manage',
  ViewGroup: 'group/:id',
  GroupDiscussionPost: 'group/discussion/:id',
  GroupMembers: 'group/:id/members',
  GroupSharedResources: 'group/:id/shared-resources',
  Campaign: 'campaign',
  EditCampaign: 'post/:postId/edit',
  CampaignStats: 'campaign/:campaignId/stats',
  GiveSkills: 'skill-request/:skillRequestId',
  MomentInNature: 'moment-in-nature/:id',
  EditMomentInNature: 'moment-in-nature/:id/edit',
  VolunteerScreen: 'volunteer/:requestId',
  DonateScreen: 'campaign/:campaignId/donate',
  ManageDonationRequest: 'campaign/:campaignId/manage/funding',
  ListCampaignDonations: 'campaign/:campaignId/donations',
  ListSurveySubmissions: 'survey/:surveyId/submissions',
  DonateSuccess: 'campaign/:campaignId/donate-success',
  VolunteerSignUp: 'volunteer/:requestId/signup',
  CreateSkillRequestApplication: 'skill-request/:skillRequestId/apply',
  CreateReport: { path: 'report' },
  ViewApplicants: 'applicants',
  ViewApplicantDetails: 'applicant/:applicationId',
  JobBoard: 'jobs',
  ViewJobPost: 'jobs/:id',
  CreateJobPost: 'jobs/create',
  EditJobPost: 'jobs/:id/edit',
  ListJobPosts: 'jobs/list',
  ListDirectConversations: 'messages',
  ListBlockedUsers: 'settings/blocked-users',
  DirectMessageScreen: 'messages/:userId',
  ViewNewsPost: 'news/:id',
  CreateNewsPost: 'news/create',
  EditNewsPost: 'news/:id/edit',
  ListNewsPosts: 'news/list',
  ViewPartnerships: 'partnerships/:userId',
  ViewApplicationDetails: 'skill-request-application/:applicationId',
  ViewDiscussionBoard: 'discussion/:id',
  ViewMyTeams: { path: 'my-teams', exact: false },
  CreateKeyBulletinPost: 'key-bulletin/create',
  EditKeyBulletinPost: 'key-bulletin/:id/edit',
  ViewKeyBulletinPost: 'key-bulletin/:id',
  ManageTeam: {
    path: 'manage-team/:teamId',
  },
  InviteTeamMember: 'manage-team/:teamId/invite/:userId',
  ViewPendingTeamMemberships: { path: 'manage-team-requests' },
  EditProfileLocations: { path: 'edit-locations/:userId' },
  SpeciesSummary: { path: 'species/:taxonID' },
  SpeciesVernacularNames: { path: 'species/:taxonID/vernacular-names' },
  SpeciesScientificNames: { path: 'species/:taxonID/scientific-synonyms' },
  SkillSummary: { path: 'skill/:name' },
  ResearchTopicSummary: { path: 'research-topic/:topic' },
  Connections: { path: 'connections/:userId' },
  AccountSettings: { path: 'settings' },
  DonationSettings: { path: 'settings/donations' },
  NotificationPreferences: { path: 'settings/notifications' },
  StripePayouts: { path: 'settings/payouts/stripe' },
  ChangePassword: { path: 'settings/password' },
  ChangeEmail: { path: 'settings/email' },
  ViewSharedContentBucket: 'shared-resources/:id',
  ListUserGroups: 'user/:userId/groups',
  SearchGroups: 'groups/search',
  DeleteAccount: 'settings/delete-account',
};

const modals = {
  CreateCampaign: 'campaign/builder',
  FindTeam: 'find-team',
  FindTeamMember: 'find-team-member',
  TakeSurvey: { path: 'survey', exact: false },
};

/**
 * Linking configuration file for ios/android
 */
export default {
  prefixes: [
    'https://app.keyconservation.org',
    'https://app.keyconservation.com',
    'http://app.keyconservation.org',
    'http://app.keyconservation.com',
    'keyconservation://',
    prefix,
  ],
  config: {
    screens: {
      tabs: {
        path: '',
        initialRouteName: 'main',
        screens: {
          ...modals,
          main: {
            initialRouteName: 'Home',
            path: '',
            screens: {
              FeedStack: {
                initialRouteName: 'Home',
                screens: {
                  ...common,

                  Home: { path: '', exact: true },
                  CreateMomentInNature: 'moment-in-nature/create',
                },
              },
              ImpactStack: {
                initialRouteName: 'SupSkilledImpact',
                path: 'impact',
                screens: {
                  ...common,

                  OrgSkilledImpact: 'o',
                  SupSkilledImpact: 's',
                  SupViewAllApplications: 'applications',

                  MyImpactCampaigns: 'my-campaigns',

                  OrganizationStats: 'stats/:userId',
                  ListCampaignsWithStats: {
                    path: 'stats/:userId/campaigns',
                  },
                },
              },
              MapStack: {
                initialRouteName: 'MapHome',
                path: 'discover',
                exact: false,
                screens: {
                  ...common,
                  Search: 'list',
                  MapHome: '',
                },
              },
              NotificationStack: {
                initialRouteName: 'Notifications',
                path: 'notifications',
                screens: {
                  ...common,
                  Notifications: '',
                  ListActionableNotifications: '/list',
                },
              },
              ProfileStack: {
                initialRouteName: 'MyProfile',
                path: 'user',
                screens: {
                  ...common,
                  MyProfile: 'me',
                },
              },

              /**
               * Admin Routes
               */
              Dashboard: 'dashboard',
              Supporters: 'supporters',
              Referrals: 'referrals',
              Organizations: 'organizations',
              Donations: 'donations',
              Analytics: 'analytics',
              DevOps: 'devops',
              ModerationStack: {
                path: 'moderation',
                initialRouteName: 'Moderation',
                screens: {
                  Moderation: '',
                  UserReports: 'reports',
                  HiddenCampaigns: 'hidden-campaigns',
                },
              },
              SurveyStack: {
                path: 'surveys',
                initialRouteName: 'Surveys',
                screens: {
                  Surveys: '',
                  ViewSurvey: ':id',
                  ViewSurveySubmission: 'submission/:submissionId',
                  CreateSurvey: 'create',
                  EditSurvey: ':id/edit',
                },
              },
              ConfigurationStack: {
                path: 'configuration',
                initialRouteName: 'Configuration',
                screens: {
                  Configuration: '',
                  ConfigureSkills: 'skills',
                  ConfigureResearchTopics: 'research-topics',
                },
              },
            },
          },
        },
      },
      /**
       * Onboarding Routes
       */
      Onboard: {
        exact: false,
        path: 'onboard',
      },

      OrgDataSurveySetup: {
        path: 'setupsurvey',
        exact: false,
      },

      /**
       * Auth Routes
       */
      auth: {
        screens: {
          LoginScreen: 'login',
          ResetPassword: 'resetpassword',
        },
      },

      // 404 fallback screen
      // NotFound: '*'
    },
  },
  getStateFromPath: (path, options) => {
    const queryStringStart = path.slice(path.lastIndexOf('/')).indexOf('?');

    // Lower case everything except query string and beyond
    let _path =
      path.slice(0, queryStringStart).toLowerCase() +
      path.slice(queryStringStart);

    for (let suffix of truncatePathSuffixes)
      if (_path.includes(suffix + '/') || _path.includes(suffix + '?')) {
        _path = _path.slice(0, _path.lastIndexOf(suffix)) + suffix;
      }

    const result = getStateFromPath(_path || '/', options);

    return result;
  },
  getPathFromState: (state, options) => {
    let _state = { ...state };

    // Stringify using Expo provided function
    let result = getPathFromState(_state, options);

    const queryStringStart =
      result.slice(result.lastIndexOf('/')).indexOf('?') +
      result.lastIndexOf('/');

    // Lower case everything except query string and beyond
    result =
      result.slice(0, queryStringStart).toLowerCase() +
      result.slice(queryStringStart);

    for (let suffix of truncatePathSuffixes) {
      if (result.includes(suffix + '/') || result.includes(suffix + '?')) {
        result = result.slice(0, result.lastIndexOf(suffix)) + suffix;
      }
    }

    return result;
  },
} as LinkingOptions<any>;
