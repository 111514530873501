import { StyleSheet } from 'react-native';
import { SECTION_CONTAINER } from '/constants';

export default StyleSheet.create({
  goals: {
    flex: 1,
    flexDirection: 'column',
  },
  bullet: {
    width: 20,
    height: 20,
    marginRight: 8,
    marginTop: 8,
    borderRadius: 50,
    backgroundColor: '#0EE6A6',
  },

  plusSign: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5,
    marginRight: 8,
    width: 30,
    height: 30,
    borderWidth: 2,
    borderRadius: 15,
  },
  addButton: {
    height: 40,
    marginVertical: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  addButtonText: {
    fontFamily: 'Lato-Bold',
    fontSize: 15,
  },

  noGoalsContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 8,
  },
  noGoalsText: {
    color: 'gray',
  },
  foribddenText: {
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    color: 'gray',
    textAlign: 'center',
  },
  donationStatus: {
    ...SECTION_CONTAINER,
  },
  donationStatusText: {
    fontSize: 16,
    fontFamily: 'Lato-Bold',
    marginLeft: 4,
  },
});
