import { Linking } from 'react-native';

const shareToTwitter = async (text: string, url: string = '') => {
  const encodedUrl = encodeURIComponent(url);
  const encodedText = encodeURIComponent(text);
  const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodedText}&url=${encodedUrl}`;

  try {
    await Linking.openURL(twitterShareUrl);
  } catch (error) {
    console.error('An error occurred while sharing', error);
  }
};

export default shareToTwitter;
