import { Linking, Platform } from 'react-native';

const shareToLinkedIn = async (url: string, title: string, summary = '') => {
  const encodedUrl = encodeURIComponent(url);
  const encodedTitle = encodeURIComponent(title);
  const encodedSummary = encodeURIComponent(summary);
  const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}&title=${encodedTitle}&summary=${encodedSummary}`;

  try {
    if (Platform.OS === 'web') {
      window.open(
        linkedInShareUrl,
        'Share to LinkedIn',
        'width=600,height=400,menubar=no,toolbar=no,scrollbars=yes',
      );
    } else {
      await Linking.openURL(linkedInShareUrl);
    }
  } catch (error) {
    console.error('An error occurred while sharing', error);
  }
};

export default shareToLinkedIn;
