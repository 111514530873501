import { UpdateResolver } from '@urql/exchange-graphcache';
import { GetUserBigIssuesDocument } from '/generated/graphql';

const updateBigIssue: UpdateResolver = (result, args, cache) => {
  const userId = (result.updateBigIssue as any)?.userId;

  if (!userId) return;

  cache.invalidate('User', 'big_issues', {
    id: userId,
  });

  const query = GetUserBigIssuesDocument;

  const draftValues = [true, false, undefined];

  draftValues.forEach((draft) => {
    const variables: any = { userId };

    if (draft !== undefined) variables.draft = draft;

    cache.updateQuery({ query, variables }, (data) => {
      const issues = [...((data?.getBigIssues || []) as any[])];

      const index = issues.findIndex(
        (issue) => issue.id === (result.updateBigIssue as any)?.id,
      );
      if (typeof index === 'number') issues[index] = result.updateBigIssue;

      return {
        getBigIssues: issues,
      } as any;
    });
  });
};

export default updateBigIssue;
