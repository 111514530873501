import { FontAwesome } from '@expo/vector-icons';
import React, { useRef } from 'react';
import CampaignConnectInviteCTAModal, {
  ICampaignConnectInvite,
} from './CampaignConnectInviteCTAModal';
import Alert from '/Alert';
import Button from '/components/Button';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import { KEY_GRAY, KEY_GREEN } from '/constants';
import { useModalContext } from '/context';
import {
  User,
  useDeleteCampaignConnectInviteMutation,
} from '/generated/graphql';

type Props = {
  invite: ICampaignConnectInvite;
  invitingUser: Pick<User, 'id' | 'name' | 'profile_image'> | null | undefined;
};

export default function CampaignConnectInviteCTA(props: Props) {
  const { spawnModal, closeModal } = useModalContext();

  const [{ fetching: deleting }, deleteCampaignConnectInvite] =
    useDeleteCampaignConnectInviteMutation();

  const modalIdRef = useRef<string | null>(null);
  function onViewInvite() {
    modalIdRef.current = spawnModal({
      title: 'Campaign Connect',
      style: {
        width: '100%',
        maxWidth: 480,
      },
      component: (
        <CampaignConnectInviteCTAModal
          invite={props.invite}
          invitingUser={props.invitingUser}
          onRequestClose={() => {
            if (modalIdRef.current) closeModal(modalIdRef.current);
          }}
        />
      ),
    });
  }

  function deleteInvite() {
    const _delete = async () => {
      const { error } = await deleteCampaignConnectInvite({
        inviteId: props.invite.id,
      });

      if (error) {
        console.error('Error deleting campaign connect invite:', error);
        Alert.alert(
          'Oh no',
          'There was a problem deleting the invite. Please try again.',
        );
        return;
      }

      if (modalIdRef.current) closeModal(modalIdRef.current);
      Alert.notify({
        message: 'Invited deleted successfully',
        color: KEY_GREEN,
      });
    };

    Alert.alert(
      'Delete Invite',
      'Are you sure you want to delete this invite?',
      [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        {
          text: 'Delete',
          style: 'destructive',
          onPress: _delete,
        },
      ],
    );
  }

  return (
    <HorizontalContainer
      style={{
        pointerEvents: deleting ? 'none' : 'auto',
        opacity: deleting ? 0.5 : 1,
      }}
    >
      <Button
        label="View"
        containerStyle={{
          marginRight: 6,
        }}
        onPress={onViewInvite}
      />
      <Button
        loading={deleting}
        label={
          <FontAwesome
            size={20.5}
            name="trash-o"
            style={{
              marginRight: 4,
            }}
            color={KEY_GRAY}
          />
        }
        onPress={deleteInvite}
      />
    </HorizontalContainer>
  );
}
