import { CampaignConnectInvite } from '/generated/graphql';

const LINKABLE_POST_TYPE_KEYS = ['creativeConnectProjectId'] as const;

interface ICampaignConnectInvite
  extends Pick<CampaignConnectInvite, 'creativeConnectProjectId'> {}

export default function getCreativeConnectLinkablePostType(
  invite: ICampaignConnectInvite,
): (typeof LINKABLE_POST_TYPE_KEYS)[number] | undefined {
  return LINKABLE_POST_TYPE_KEYS.find((key) => !!invite[key]);
}
