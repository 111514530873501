import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';

const archiveDirectMessage: UpdateResolver = (result, args, cache) => {
  const messageId = args?.messageId as any;

  cache.writeFragment(
    gql`
      fragment ArchiveDirectMessage on DirectMessage {
        id
        archived_at
      }
    `,
    {
      __typename: 'DirectMessage',
      id: messageId,
      archived_at: new Date().toISOString(),
    },
  );
};

export default archiveDirectMessage;
