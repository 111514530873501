import { UpdateResolver } from '@urql/exchange-graphcache';

const deleteSharedDocument: UpdateResolver = (result, args, cache) => {
  const documentId = (args as any).id;

  cache.invalidate({
    __typename: 'SharedDocument',
    id: documentId,
  });
};

export default deleteSharedDocument;
