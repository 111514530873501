import React, { useCallback, useRef } from 'react';
import {
  ActivityIndicator,
  Pressable,
  ScrollView,
  Text,
  View,
} from 'react-native';
import { CampaignBuilderComponentProps } from '../CampaignBuilder';
import sharedStyles from '/constants/CampaignBuilder';
import styles from '/constants/CampaignBuilder/Donations/RequestDonation';

import { FontAwesome, Ionicons } from '@expo/vector-icons';
import { KeyboardAwareScrollView } from '@mtourj/react-native-keyboard-aware-scroll-view';
import CampaignBuilderFooter from '../components/CampaignBuilderFooter';
import GoalsFormList from './elements/GoalsFormList';
import Button from '/components/Button';
import DonationStatusWidget from '/components/DonationStatusWidget/DonationStatusWidget';
import {
  ValidatedAny,
  useFormValidationContext,
  withFormValidation,
} from '/components/ValidatedForm';
import ErrorText from '/components/common/Generic/ErrorText';
import { ALERT_RED, KEY_GRAY, KEY_GREEN } from '/constants';
import { useAuthContext, useTeamContext } from '/context';
import { UserRole, useGetMaxDonationGoalAmountQuery } from '/generated/graphql';
import useAccountDonationStatus from '/hooks/useAccountDonationStatus';
import { DraftDonationRequestGoal } from '/hooks/useCampaignBuilder';
import { formatMoney, getLocaleCurrencyCode } from '/util';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Currency, getDecimalPlacesForCurrency } from 'currency-decimal-places';

const currencyCode = getLocaleCurrencyCode();

const RequestDonation = (props: CampaignBuilderComponentProps) => {
  const { userData } = useAuthContext();

  const donationStatus = useAccountDonationStatus();

  const {
    activeTeam,
    loading: teamsLoading,
    refresh: refreshTeams,
  } = useTeamContext();

  const acceptingDonations =
    userData?.role === UserRole.Conservationist
      ? !!donationStatus?.acceptingDonations
      : !!activeTeam?.user.accepting_donations;

  const organizationCurrency =
    activeTeam?.user.donation_currency ?? userData?.donation_currency;

  const scrollViewRef = useRef<ScrollView>();

  const { fields, validateForm, resetValidations } =
    useFormValidationContext(scrollViewRef);

  const [maxDonationGoalQuery] = useGetMaxDonationGoalAmountQuery({
    variables: {
      currency: organizationCurrency ?? currencyCode,
    },
  });

  const onNext = useCallback(() => {
    if (validateForm()) {
      props.next();
    }
  }, [props, validateForm]);

  const onChangeGoals = useCallback(
    (goals: DraftDonationRequestGoal[]) => {
      resetValidations();

      props.setData({
        donation_request: {
          goals: goals,
        },
      });
    },
    [props, resetValidations],
  );

  const goals = props.data?.donation_request?.goals ?? [];

  const hasAtLeastOneGoal = !!goals.length;

  return (
    <>
      <KeyboardAwareScrollView
        innerRef={(r: any) => {
          if (r) {
            scrollViewRef.current = r as ScrollView;
          }
        }}
        style={[sharedStyles.scrollView, { paddingBottom: 35 }]}
      >
        {userData?.role === UserRole.Conservationist ? (
          <DonationStatusWidget />
        ) : (
          <Pressable
            style={[
              styles.donationStatus,
              {
                backgroundColor: acceptingDonations ? KEY_GREEN : ALERT_RED,
              },
            ]}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Ionicons
                name={
                  donationStatus?.fetching || teamsLoading
                    ? 'time'
                    : acceptingDonations
                    ? 'checkmark-circle'
                    : 'warning'
                }
                size={32}
                color={KEY_GRAY}
              />
              <Text style={styles.donationStatusText}>
                {teamsLoading
                  ? 'Loading...'
                  : activeTeam?.user.accepting_donations
                  ? 'Your organization is accepting donations'
                  : 'Your organization is not accepting donations'}
              </Text>
            </View>
            {!acceptingDonations ? (
              <Button
                label="Refresh"
                containerStyle={{
                  marginTop: 8,
                  alignSelf: 'flex-end',
                }}
                onPress={refreshTeams}
                loading={teamsLoading}
              />
            ) : null}
          </Pressable>
        )}

        <ValidatedAny
          name="goals"
          value={goals}
          validate={(items) => {
            if (
              (maxDonationGoalQuery?.data?.max_donation_goal_amount.amount ||
                0) < (items?.reduce((acc, item) => acc + item.amount, 0) ?? 0)
            ) {
              return false;
            }

            return !!items?.length;
          }}
          containerStyle={[
            sharedStyles.sectionContainer,
            {
              borderWidth: fields?.goals?.valid === false ? 1 : 0,
              borderColor: 'crimson',
            },
          ]}
        >
          <View style={sharedStyles.titleWithIconContainer}>
            <FontAwesome
              name="credit-card"
              size={24}
              color="black"
              style={{
                marginRight: 8,
              }}
            />
            <Text style={sharedStyles.subheading}>FUNDING</Text>
          </View>

          {donationStatus?.fetching || teamsLoading ? (
            <ActivityIndicator size="large" color={KEY_GRAY} />
          ) : !acceptingDonations ? (
            <Text style={styles.foribddenText}>
              {donationStatus?.status === 'not_set_up'
                ? 'You must set up a deposit method before you can create a funding request.'
                : 'You must have at least one valid deposit method before you can create a funding request. You can manage your deposit methods in your account settings.'}
            </Text>
          ) : (
            <>
              <Text style={sharedStyles.description}>
                In an effort to promote transparency and help supporters know
                where their money is going, we offer organizations the option to
                list multiple, more specific goals rather than a bigger, more
                obscure one. We highly recommend taking the few additional
                minutes to create this list, but you may always create one goal
                if this does not apply for this campaign.
              </Text>

              <Text style={sharedStyles.subheading}>Goals</Text>

              {fields?.goals?.valid === false ? (
                <ErrorText>
                  {hasAtLeastOneGoal
                    ? `Total sum of goals cannot exceed ${getSymbolFromCurrency(
                        maxDonationGoalQuery.data?.max_donation_goal_amount.currency.toUpperCase() as Currency,
                      )}${formatMoney(
                        maxDonationGoalQuery.data?.max_donation_goal_amount
                          .amount || 0,
                        getDecimalPlacesForCurrency(
                          maxDonationGoalQuery.data?.max_donation_goal_amount.currency.toUpperCase() as Currency,
                        ),
                      )}`
                    : 'Please add at least one goal.'}
                </ErrorText>
              ) : null}

              <GoalsFormList
                data={{
                  currency: organizationCurrency ?? currencyCode,
                  goals: goals.map((g) => ({
                    ...g,
                    id: g.id,
                    total_donated: {
                      amount: 0,
                      currency: organizationCurrency ?? currencyCode,
                    },
                  })),
                }}
                onChangeGoals={onChangeGoals}
              />
            </>
          )}
        </ValidatedAny>
      </KeyboardAwareScrollView>
      <CampaignBuilderFooter
        {...props}
        disableNext={
          !acceptingDonations || donationStatus?.fetching || teamsLoading
        }
        onNext={onNext}
        validateForm={validateForm}
      />
    </>
  );
};

export default withFormValidation(RequestDonation, {
  disableValidateFieldOnChange: true,
});
