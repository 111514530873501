import { RouteProp, useRoute } from '@react-navigation/native';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Platform, Pressable, View, useWindowDimensions } from 'react-native';
import { PanGestureHandler } from 'react-native-gesture-handler';
import Animated, {
  runOnJS,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import { DEVICE_SIZES } from 'rn-responsive-styles';
import ContentCardResultsOrganizations from '../ContentCardResults/ContentCardResultsOrganizations';
import useStyles from './ContentCard.style';
import Hoverable from '/components/Hoverable';
import { DiscoverMapFilterInput, MapBounds } from '/generated/graphql';
import ContentCardResultsGroups from '../ContentCardResults/ContentCardResultsGroups';
import { DiscoverSearchTab } from '../SearchBox/SearchBox';
import FontAwesome from '@expo/vector-icons/FontAwesome';
import { KEY_GRAY } from '/constants';
import useBinaryTimingAnimation from '/hooks/useBinaryTimingAnimation';

interface IContentCardProps {
  query: string;
  collapsedCardHeight: number;
  type: 'organizations';
  currentTab: DiscoverSearchTab | undefined;
  headerHeight: number;
  bounds: Pick<MapBounds, 'northeast' | 'southwest'> | undefined;
  loading: boolean;
  totalResults: number | undefined;
  forceCollapse: boolean;
  onFilterChanged: (filter: Partial<DiscoverMapFilterInput>) => void;
  onClose: () => void;
  onSearchQueryChange: (query: string) => void;
}

const CONTENT_CARD_HEADER_PADDING = 24;

export default function ContentCard({
  onSearchQueryChange,
  onFilterChanged,
  ...props
}: IContentCardProps) {
  const { styles, deviceSize } = useStyles();

  const route = useRoute<RouteProp<any>>();

  const headerHeight = props.headerHeight + CONTENT_CARD_HEADER_PADDING;
  const windowHeight = useWindowDimensions().height;
  const isSmallDevice = deviceSize === DEVICE_SIZES.EXTRA_SMALL_DEVICE;
  const tabBarHeight = isSmallDevice ? 64 : 0;
  const dragHandleOpacity = useSharedValue(0.7);

  const panRef = useRef(null);

  const minTranslateY = 0;
  const maxTranslateY =
    windowHeight - props.collapsedCardHeight - tabBarHeight - 100;

  const shouldBeInitiallyOpen =
    (!isNaN(Number(route.params?.t)) && route.params?.t > 0) || route.params?.q;

  const translateY = useSharedValue(
    shouldBeInitiallyOpen ? minTranslateY : maxTranslateY,
  );

  const [isOpen, setOpen] = useState(shouldBeInitiallyOpen);

  const opacity = useBinaryTimingAnimation({ value: isOpen, duration: 100 });

  const springOpen = useCallback(
    (velocity?: number) => {
      translateY.value = withSpring(minTranslateY, {
        velocity,
        stiffness: 100,
        mass: 0.3,
      });
      setOpen(true);
    },
    [minTranslateY, translateY],
  );

  const springClose = useCallback(
    (velocity?: number) => {
      translateY.value = withSpring(maxTranslateY, {
        velocity,
        overshootClamping: true,
      });
      setOpen(false);
    },
    [maxTranslateY, translateY],
  );

  const gestureHandler = useAnimatedGestureHandler(
    {
      onStart: (
        __,
        ctx: {
          startY: number;
        },
      ) => {
        ctx.startY = translateY.value;
      },
      onActive: (event, ctx) => {
        const newPosition = ctx.startY + event.translationY;

        if (newPosition < minTranslateY) {
          translateY.value = minTranslateY;
        } else if (newPosition > maxTranslateY) {
          translateY.value = maxTranslateY;
        } else {
          translateY.value = newPosition;
        }
      },
      onEnd: (event) => {
        if (props.forceCollapse) {
          runOnJS(springClose)(event.velocityY);
          return;
        }

        if (isOpen) {
          if (event.translationY > 150) {
            runOnJS(springClose)(event.velocityY);
          } else {
            runOnJS(springOpen)(event.velocityY);
          }
        } else {
          if (event.translationY < -100) {
            runOnJS(springOpen)(event.velocityY);
          } else {
            runOnJS(springClose)(event.velocityY);
          }
        }
      },
    },
    [
      maxTranslateY,
      minTranslateY,
      translateY,
      springOpen,
      springClose,
      isOpen,
      props.forceCollapse,
    ],
  );

  const [isHoveringDragHandle, setIsHoveringDragHandle] = useState(false);

  useEffect(() => {
    if (props.bounds && !props.forceCollapse) springOpen();
  }, [props.bounds, props.forceCollapse, springOpen]);

  useEffect(() => {
    if (!props.bounds || props.forceCollapse) {
      springClose();
    }
  }, [props.bounds, props.forceCollapse, springClose]);

  useEffect(() => {
    if (isHoveringDragHandle) {
      dragHandleOpacity.value = withTiming(1, { duration: 200 });
    } else {
      dragHandleOpacity.value = withTiming(0.7, { duration: 200 });
    }
  }, [dragHandleOpacity, isHoveringDragHandle]);

  const ResultsComponent = useMemo(() => {
    switch (props.currentTab?.key) {
      // case 'jobs': {
      //   return ContentCardResultsJobs;
      // }
      case 'groups': {
        return ContentCardResultsGroups;
      }
      case 'organizations':
      default: {
        return ContentCardResultsOrganizations;
      }
    }
  }, [props.currentTab?.key]);

  const animatedCardStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: translateY.value }],
      opacity: opacity.value,
    };
  }, [opacity, translateY]);

  const cardHeightStyle = useAnimatedStyle(() => {
    return {
      height: translateY.value === windowHeight - 150 ? 150 : windowHeight,
    };
  });

  const dragHandleStyle = useAnimatedStyle(() => {
    return {
      opacity: dragHandleOpacity.value,
    };
  });

  return (
    <Animated.View
      style={[
        styles('contentCardContainer'),
        animatedCardStyle,
        {
          paddingTop: headerHeight,
          pointerEvents: isOpen ? 'box-none' : 'none',
        },
      ]}
    >
      <PanGestureHandler
        enabled={!!props.bounds}
        ref={panRef}
        // @ts-ignore - needed on web
        onBegan={Platform.OS === 'web' ? gestureHandler : undefined}
        // @ts-ignore - needed on web
        onEnded={Platform.OS === 'web' ? gestureHandler : undefined}
        onGestureEvent={gestureHandler}
      >
        <Animated.View
          style={[{ flex: 1 }, cardHeightStyle]}
          pointerEvents={isOpen ? 'auto' : 'none'}
        >
          {/* LOAD INDICATOR */}
          {/* <Animated.View
          style={{
            opacity: resultsCardOpacity,
            transform: [{ translateY: resultsCardTranslateY }],
            marginLeft: 24,
            flexDirection: 'row',
            alignItems: 'center',
            alignSelf: 'flex-start',
            backgroundColor: OFFWHITE,
            padding: 4,
            paddingHorizontal: 8,
            height: 28,
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
            borderBottomLeftRadius: isSearchFocused ? 0 : 6,
            borderBottomRightRadius: isSearchFocused ? 0 : 6,
          }}
        >
          {props.loading ? (
            <>
              <ActivityIndicator
                size={16}
                color={KEY_GRAY}
                style={{
                  marginRight: 4,
                }}
              />
              <Text style={styles('resultsText')}>Searching...</Text>
            </>
          ) : (
            <Text style={styles('resultsText')}>
              {props.totalResults || 0} result
              {props.totalResults === 1 ? '' : 's'} in this area
            </Text>
          )}
        </Animated.View> */}

          <Animated.View
            style={[
              styles('contentCard'),
              {
                padding: 0,
                // opacity,
                // transform: [{ scale }],
              },
            ]}
          >
            <Hoverable
              onHoverIn={() => {
                setIsHoveringDragHandle(true);
              }}
              onHoverOut={() => {
                setIsHoveringDragHandle(false);
              }}
            >
              <Pressable
                style={
                  Platform.OS === 'web'
                    ? ({
                        cursor: 'grab' as any,
                      } as any)
                    : undefined
                }
                onPress={() => {
                  if (isOpen) springClose();
                  else if (!props.forceCollapse) springOpen();
                }}
              >
                {isHoveringDragHandle ? (
                  isOpen ? (
                    <View
                      pointerEvents="box-none"
                      style={styles('dragHandleContainer')}
                    >
                      <FontAwesome
                        pointerEvents={'box-none'}
                        name="caret-down"
                        size={24}
                        style={{
                          alignSelf: 'center',
                        }}
                        color={KEY_GRAY}
                      />
                    </View>
                  ) : (
                    <View
                      style={styles('dragHandleContainer')}
                      pointerEvents="box-none"
                    >
                      <FontAwesome
                        pointerEvents={'box-none'}
                        name="caret-up"
                        size={24}
                        style={{
                          alignSelf: 'center',
                        }}
                        color={KEY_GRAY}
                      />
                    </View>
                  )
                ) : (
                  <Animated.View
                    style={[styles('dragHandle'), dragHandleStyle]}
                  />
                )}
              </Pressable>
            </Hoverable>

            {!props.bounds ? null : (
              <ResultsComponent
                key={JSON.stringify(props.bounds)}
                bounds={props.bounds}
                onClose={props.onClose}
                query={props.query}
              />
            )}
          </Animated.View>
        </Animated.View>
      </PanGestureHandler>
    </Animated.View>
  );
}
