import { UpdateResolver } from '@urql/exchange-graphcache';

const deleteSpeciesMedia: UpdateResolver = (result, args, cache) => {
  const mediaId = args.id;

  cache.invalidate({
    __typename: 'SpeciesUserContentMedia',
    id: mediaId as string,
  });
};

export default deleteSpeciesMedia;
