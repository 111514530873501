import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';
import { SpeciesUserContentFragmentFragmentDoc } from '/generated/graphql';
import { pascalToSnake } from '/util';

const UserProfileFragment = gql`
  fragment PutSpeciesUserContentUserProfile on User {
    id
    main_species {
      ...SpeciesUserContentFragment
    }
    secondary_species {
      ...SpeciesUserContentFragment
    }
  }
  ${SpeciesUserContentFragmentFragmentDoc}
`;

const putSpeciesUserContent: UpdateResolver = (result, args, cache) => {
  const category = pascalToSnake((args.input as any)?.category ?? '');

  if (!category) return;

  const data: any = cache.readFragment(UserProfileFragment, {
    __typename: 'User',
    id: args.userId,
  });

  if (!data) return;

  cache.writeFragment(UserProfileFragment, {
    ...data,
    __typename: 'User',
    id: args.userId,
    [category]: [
      ...(data?.[category] ?? []).filter(
        (s: any) => s.newSpeciesTaxonID !== (args.input as any)?.taxonID,
      ),
      result.putSpeciesUserContent,
    ],
  });
};

export default putSpeciesUserContent;
