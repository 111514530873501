import { StyleSheet } from 'react-native';
import { KEY_GRAY } from '..';

export default StyleSheet.create({
  buttonContainer: {
    marginTop: 3.5,
    flex: 1,
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 18,
  },
  horizontalContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  verticalContainer: {
    flex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  takeActionContainer: {
    alignSelf: 'stretch',
    minWidth: 200,
    maxWidth: 420,
    width: '100%',
    padding: 8,
  },
  columnSpacer: {
    width: 1,
    height: '80%',
    backgroundColor: '#eee',
    marginHorizontal: 8,
    alignSelf: 'center',
  },
  titleContainer: {
    flex: 1,
    marginBottom: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
    fontFamily: 'LeagueSpartan-Bold',
    marginLeft: 4,
  },
  buttonTouch: {
    width: '100%',
    height: 48,
    backgroundColor: '#0EE6A6',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    textAlign: 'center',
    shadowColor: 'rgba(0, 0, 0, 0.15)',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 5,
    shadowOpacity: 10,
  },
  button_label: {
    color: '#323339',
    textTransform: 'uppercase',
    letterSpacing: 1.8,
    fontSize: 17,
    textAlign: 'center',
    fontFamily: 'Lato-Bold',
  },
  takeActionButton: {
    flex: 1,
    justifyContent: 'center',
    marginVertical: 4,
    backgroundColor: '#F5F5F5',
    padding: 8,
    borderRadius: 8,
    shadowColor: 'gray',
    shadowOpacity: 0.5,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 4,
    elevation: 6,
  },
  takeActionButtonInner: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'stretch',
    marginVertical: 10,
  },
  volunteerDistanceContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 4,
    justifyContent: 'center',
    borderRadius: 8,
    marginRight: 5,
  },
  volunteerDistanceText: {
    flex: 1,
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    textAlign: 'center',
  },
  dueDateContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: KEY_GRAY,
    borderRadius: 6,
    padding: 6,
    paddingHorizontal: 8,
    justifyContent: 'center',
  },
  buttonDueDate: {
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    color: 'white',
    textTransform: 'uppercase',
  },
});
