import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';
import removeItemFromPaginatedField from '/util/cache/removeItemFromPaginatedField';

const CampaignPostFragment = gql`
  fragment DeleteCampaignDraftFragment on Query {
    getDrafts(userId: $userId, nextToken: $nextToken) {
      items {
        id
      }
    }
  }
`;

const deleteCampaignDraft: UpdateResolver = (result, args, cache) => {
  removeItemFromPaginatedField({
    cache,
    entity: {
      __typename: 'Query',
    },
    paginatedField: {
      name: 'getDrafts',
      fragment: CampaignPostFragment,
    },
    removeItem: {
      id: args.id,
    },
  });
};

export default deleteCampaignDraft;
