import { CreativeConnectProjectCreativeType } from '/generated/graphql';

export default function getCreativeTypeLabel(
  creativeType: CreativeConnectProjectCreativeType,
): string {
  switch (creativeType) {
    case CreativeConnectProjectCreativeType.Art:
      return 'Art';
    case CreativeConnectProjectCreativeType.Film:
      return 'Film';
    case CreativeConnectProjectCreativeType.Game:
      return 'Game';
    case CreativeConnectProjectCreativeType.Photography:
      return 'Photography';
    case CreativeConnectProjectCreativeType.Podcast:
      return 'Podcast';
    default:
      creativeType satisfies never;
      return '';
  }
}
