import SvgXml from '../common/SvgXml';

import CR from '/assets/images/iucn/iucn-cr.svg';
import DD from '/assets/images/iucn/iucn-dd.svg';
import EN from '/assets/images/iucn/iucn-en.svg';
import EW from '/assets/images/iucn/iucn-ew.svg';
import EX from '/assets/images/iucn/iucn-ex.svg';
import LC from '/assets/images/iucn/iucn-lc.svg';
import NT from '/assets/images/iucn/iucn-nt.svg';
import VU from '/assets/images/iucn/iucn-vu.svg';
import CR_SMALL from '/assets/images/iucn/iucn-cr-small.svg';
import DD_SMALL from '/assets/images/iucn/iucn-dd-small.svg';
import EN_SMALL from '/assets/images/iucn/iucn-en-small.svg';
import EW_SMALL from '/assets/images/iucn/iucn-ew-small.svg';
import EX_SMALL from '/assets/images/iucn/iucn-ex-small.svg';
import LC_SMALL from '/assets/images/iucn/iucn-lc-small.svg';
import NT_SMALL from '/assets/images/iucn/iucn-nt-small.svg';
import VU_SMALL from '/assets/images/iucn/iucn-vu-small.svg';
import { ViewStyle } from 'react-native';

export interface IUCNStatusImageProps {
  redlist_category: string | undefined | null;
  style?: ViewStyle;
  small?: boolean;
  width?: number;
  height?: number;
}

export default function IUCNStatusImage(props: IUCNStatusImageProps) {
  let targetXml;

  switch (props.redlist_category) {
    /** Data Deficient */
    case 'DD':
      targetXml = props.small ? DD_SMALL : DD;
      break;
    /** Least Concern */
    case 'LC':
      targetXml = props.small ? LC_SMALL : LC;
      break;
    /** Near Threatened */
    case 'NT':
      targetXml = props.small ? NT_SMALL : NT;
      break;
    /** Vulnerable */
    case 'VU':
      targetXml = props.small ? VU_SMALL : VU;
      break;
    /** Endangered */
    case 'EN':
      targetXml = props.small ? EN_SMALL : EN;
      break;
    /** Critically Endangered */
    case 'CE':
    case 'CR':
      targetXml = props.small ? CR_SMALL : CR;
      break;
    /** Extinct in the Wild */
    case 'EW':
      targetXml = props.small ? EW_SMALL : EW;
      break;
    /** Extinct */
    case 'EX':
      targetXml = props.small ? EX_SMALL : EX;
      break;
  }

  return targetXml ? (
    <SvgXml
      style={props.style}
      xml={targetXml}
      width={props.width ?? (props.small ? 22 : 80)}
      height={props.height ?? (props.small ? 22 : 80)}
    />
  ) : null;
}
