/**
 * Used for managing shared video player state.
 *
 * Things like deciding which video should be playing right now, whether user has chosen to mute sound, etc
 */
import React, { useCallback, useContext, useState } from 'react';

interface IVideoPlayerState {
  /** The node handle of the video that should be playing right now */
  activeVideoNodeHandle: number | null;
  mute: boolean;
}

interface IVideoPlayerContext {
  state: IVideoPlayerState;
  _setActiveVideoNodeHandle: (nodeHandle: number | null) => void;
  _setMute: (mute: boolean) => void;
}

const ctx = React.createContext<IVideoPlayerContext>(null as any);

export const useVideoPlayerContext = () => useContext(ctx);

export default function VideoPlayerContextProvider(
  props: React.PropsWithChildren<{}>,
) {
  const [state, setState] = useState<IVideoPlayerState>(getInitialState());

  function getInitialState(): IVideoPlayerState {
    return {
      activeVideoNodeHandle: null,
      mute: true, // When Expo adds functionality for checking whether device is "muted", use that as the initial value
    };
  }

  const setActiveVideoNodeHandle = useCallback(function (
    nodeHandle: number | null,
  ) {
    setState((prevState) => ({
      ...prevState,
      activeVideoNodeHandle: null,
    }));

    if (nodeHandle !== null) {
      /** Allow time between video unload/load operations */
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          activeVideoNodeHandle: nodeHandle,
        }));
      }, 100);
    }
  },
  []);

  const setMute = useCallback(function (mute: boolean) {
    setState((prevState) => ({
      ...prevState,
      mute,
    }));
  }, []);

  return (
    <ctx.Provider
      value={{
        state,
        _setActiveVideoNodeHandle: setActiveVideoNodeHandle,
        _setMute: setMute,
      }}
    >
      {props.children}
    </ctx.Provider>
  );
}
