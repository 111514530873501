import React, { forwardRef, useCallback, useMemo } from 'react';
import { Platform, PressableProps, Share } from 'react-native';
import Alert from '/Alert';

import { useNavigation } from '@react-navigation/native';

import { Ionicons } from '@expo/vector-icons';

import SharePopover from '../SharePopover/SharePopover';
import ActionSheetPressableCore, {
  ActionSheetPressableRef,
} from './ActionSheetPressableCore';
import { useAuthContext } from '/context';
import {
  useBlockUserMutation,
  useDeactivateUserMutation,
  User,
  useUnblockUserMutation,
} from '/generated/graphql';
import { createUniversalURL, shorten } from '/util';

type ExcludeOptions = {
  excludeAccountSettings?: boolean;
  excludeShare?: boolean;
};

type UASProps = ExcludeOptions & {
  user: Pick<Partial<User>, 'id' | 'name' | 'is_blocked'> | undefined | null;
  style?: PressableProps['style'];
  disabled?: boolean;
};

export default forwardRef<
  ActionSheetPressableRef,
  React.PropsWithChildren<UASProps>
>((props: React.PropsWithChildren<UASProps>, ref) => {
  const { goBack, navigate } = useNavigation<any>();

  const [isPopoverVisible, setIsPopoverVisible] = React.useState(false);

  const { userData, isAdmin } = useAuthContext();

  const [, _blockUser] = useBlockUserMutation();
  const [, _unblockUser] = useUnblockUserMutation();

  const [, deactivateUser] = useDeactivateUserMutation();

  const report = useCallback(() => {
    if (!props.user?.id) {
      console.warn(
        'UserActionSheet: a valid user prop was not found - action canceled',
      );
      return;
    }
    // Take the user to a report screen
    navigate('CreateReport', {
      type: 'users',
      id: props.user?.id,
      data: props.user,
    });
  }, [navigate, props.user]);

  const blockUser = useCallback(async () => {
    const _block = async () => {
      if (!props.user?.id) throw new Error('No user id found');

      try {
        const { error } = await _blockUser({
          userId: props.user.id,
        });

        if (error) throw error;

        Alert.notify({ message: 'User blocked successfully.' });
      } catch (err) {
        console.log(err);
        Alert.alert(
          'Error',
          'There was a problem blocking this user. Please try again.',
        );
      }
    };

    Alert.alert(
      'Block User',
      'Blocking this user will prevent them from' +
        ' interacting with you on Key Conservation. Are you sure you want to block this user?',
      [
        {
          style: 'cancel',
        },
        {
          text: 'Block',
          onPress: _block,
          style: 'destructive',
        },
      ],
    );
  }, [_blockUser, props.user?.id]);

  const unblockUser = useCallback(async () => {
    const _unblock = async () => {
      if (!props.user?.id) throw new Error('No user id found');

      try {
        const { error } = await _unblockUser({
          userId: props.user.id,
        });

        if (error) throw error;

        Alert.notify({ message: 'User unblocked successfully.' });
      } catch (err) {
        console.log(err);
        Alert.alert(
          'Error',
          'There was a problem unblocking this user. Please try again.',
        );
      }
    };

    Alert.alert('Unblock User', 'Are you sure you want to unblock this user?', [
      {
        style: 'cancel',
      },
      {
        text: 'Unblock',
        onPress: _unblock,
        style: 'destructive',
      },
    ]);
  }, [_unblockUser, props.user?.id]);

  const deactivate = useCallback(async () => {
    if (!props.user?.id) {
      console.warn(
        'UserActionSheet: a valid user prop was not found - action canceled',
      );
      return;
    }

    try {
      await deactivateUser({ id: props.user?.id });

      Alert.alert('Deactivate User', 'User deactivated successfully.');

      goBack();
    } catch (err) {
      console.log(err);

      Alert.alert(
        'Error',
        'There was a problem deactivating this user, the action was not completed.',
      );
    }
  }, [deactivateUser, goBack, props.user?.id]);

  const share = useCallback(async () => {
    if (!props.user?.id) {
      console.warn('UserActionSheet: user prop not found - action canceled');
      return;
    }

    const shareableURL = createUniversalURL(`user/${props.user?.id}`);

    try {
      if (Platform.OS === 'web') throw new Error('Web not supported');

      await Share.share(
        {
          url: shareableURL,
        },
        {
          dialogTitle: 'Share Profile',
          subject: `Check out "${shorten(
            props.user?.name ?? 'this profile',
            42,
          )}" on Key Conservation`,
        },
      );
    } catch (err) {
      /** Fallback */
      setIsPopoverVisible(true);
    }
  }, [props.user?.id, props.user?.name]);

  // Options for actions to take on a campaign vary
  // depending on the user, so we use this function
  // to initialize the options we want
  const generateActionSheetOptions = useCallback(
    (opts: ExcludeOptions | undefined) => {
      const options: string[] = [];

      // Define labels here
      const labels = {
        deactivate: 'Deactivate User',
        block: 'Block User',
        unblock: 'Unblock User',
        bookmarks: 'Bookmarks',
        settings: 'Account Settings',
        report: 'Report',
        share: 'Share...',
        cancel: 'Cancel',
      };

      // Construct options[]
      if (isAdmin) options.push(labels.deactivate);
      if (!!userData?.id && userData?.id !== props.user?.id) {
        if (props.user?.is_blocked) options.push(labels.unblock);
        else options.push(labels.block);
      }
      if (!!userData?.id && userData?.id !== props.user?.id)
        options.push(labels.report);
      if (
        props.user?.id &&
        userData?.id === props.user?.id &&
        !opts?.excludeAccountSettings
      ) {
        options.push(labels.bookmarks);
        options.push(labels.settings);
      }
      if (!opts?.excludeShare) options.push(labels.share);
      options.push(labels.cancel);

      const cancelButtonIndex = options.length - 1; // Cancel option is always the last one.
      const destructiveButtonIndex =
        userData?.id === props.user?.id ? undefined : 0; // Destructive option is at the top in all cases.

      return {
        title: isAdmin ? 'Actions (Admin)' : 'Actions',
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
        /*
         * Icons appear on Android & Web only
         */
        icons: options.map((option) => {
          switch (option) {
            case labels.deactivate: {
              // @ts-ignore
              return <Ionicons name="ban-outline" size={24} />;
            }
            case labels.settings: {
              return <Ionicons name="settings-outline" size={24} />;
            }
            case labels.report: {
              return <Ionicons name="alert-circle-outline" size={24} />;
            }
            case labels.share: {
              return <Ionicons name="share-outline" size={24} />;
            }
            default:
              return undefined;
          }
        }),
        onPress: (index: number | undefined) => {
          if (index === undefined) return;

          const buttonLabel = options[index];

          switch (buttonLabel) {
            case labels.deactivate: {
              Alert.alert(
                'Deactivate user',
                "Are you sure you want to deactivate this user's account?",
                [
                  {
                    text: 'Cancel',
                    style: 'cancel',
                  },
                  {
                    text: 'Deactivate',
                    style: 'destructive',
                    onPress: deactivate,
                  },
                ],
              );
              break;
            }
            case labels.bookmarks: {
              navigate('Bookmarks');
              break;
            }
            case labels.settings: {
              navigate('AccountSettings');
              break;
            }
            case labels.report: {
              report();
              break;
            }
            case labels.share: {
              share();
              break;
            }
            case labels.block: {
              blockUser();
              break;
            }
            case labels.unblock: {
              unblockUser();
              break;
            }
            default: {
              break;
            }
          }
        },
      };
    },
    [
      blockUser,
      deactivate,
      isAdmin,
      navigate,
      props.user?.id,
      props.user?.is_blocked,
      report,
      share,
      unblockUser,
      userData?.id,
    ],
  );

  const actionSheetOptions = useMemo(
    () =>
      generateActionSheetOptions({
        excludeAccountSettings: props.excludeAccountSettings,
        excludeShare: props.excludeShare,
      }),
    [
      generateActionSheetOptions,
      props.excludeAccountSettings,
      props.excludeShare,
    ],
  );

  return (
    <SharePopover
      shareMessage={`Check out "${shorten(
        props.user?.name ?? 'this profile',
        42,
      )}" on Key Conservation`}
      shareTitle="Share Profile"
      shareURL={createUniversalURL(`user/${props.user?.id}`)}
      isVisible={isPopoverVisible}
      onRequestClose={() => setIsPopoverVisible(false)}
      disabled={Platform.OS !== 'web'}
    >
      <ActionSheetPressableCore
        disabled={props.disabled}
        options={actionSheetOptions}
        style={props.style}
        ref={ref}
      >
        {props.children}
      </ActionSheetPressableCore>
    </SharePopover>
  );
});
