import { Octicons } from '@expo/vector-icons';
import { KeyboardAwareScrollView } from '@mtourj/react-native-keyboard-aware-scroll-view';
import React from 'react';
import { Platform, Text, View } from 'react-native';
import { CampaignBuilderComponentProps } from '../CampaignBuilder';
import CampaignBuilderFooter from './CampaignBuilderFooter';
import CampaignPost, {
  ICampaignPostComponentCampaign,
  ICampaignPostComponentCampaignPost,
} from '/components/CampaignPost/CampaignPost';
import {
  IDonationRequest,
  ISkilledImpactRequest,
  IVolunteerRequest,
} from '/components/TakeAction/TakeActionCallToAction';
import { INFO_GREEN, SECTION_CONTAINER } from '/constants';
import styles from '/constants/CampaignBuilder';
import { useAuthContext, useTeamContext } from '/context';
import useStripeBalance from '/hooks/useStripeBalance';
import GenericError from '../../common/Generic/GenericError';

const Preview = (props: CampaignBuilderComponentProps) => {
  const { userData } = useAuthContext();
  const { activeTeam } = useTeamContext();

  const { currency: stripeCurrency } = useStripeBalance();

  const supportNeededByDate = props.data?.skilled_impact_requests?.length
    ? props.data?.skilled_impact_requests[0].due_date
    : props.data?.volunteer_request
    ? props.data.volunteer_request.date
    : null;

  /** Transform CampaignBuilder form state into a Campaign-like object
   * for rendering
   */
  const transformedData:
    | (ICampaignPostComponentCampaign & {
        original_post: ICampaignPostComponentCampaignPost;
      })
    | undefined = !props.data
    ? undefined
    : ({
        ...props.data.campaign,
        id: 'preview',
        original_post: {
          ...props.data.post,
          created_at: Date.now().toString(),
        },
        closed: false,
        is_bookmarked: false,
        user: {
          id: 'preview',
          profile_image: (activeTeam?.user || userData)?.profile_image,
          name: (activeTeam?.user || userData)?.name || 'Organization',
        },
        support_needed_by_date: supportNeededByDate,
        donation_request: Object.keys(props.data.donation_request ?? {}).length
          ? ({
              ...props.data.donation_request,
              total_goal: {
                amount:
                  props.data.donation_request?.goals.reduce(
                    (acc, goal) => acc + goal.amount,
                    0,
                  ) ?? 0,
                currency: stripeCurrency,
              },
            } as IDonationRequest)
          : undefined,
        volunteer_request: Object.keys(props.data?.volunteer_request ?? {})
          .length
          ? ({
              id: 'preview',
              ...props.data.volunteer_request,
            } as IVolunteerRequest)
          : undefined,
        skilled_impact_requests:
          props.data.skilled_impact_requests?.map(
            (req) =>
              ({
                ...req,
              } as ISkilledImpactRequest),
          ) ?? [],
      } as ICampaignPostComponentCampaign & {
        original_post: ICampaignPostComponentCampaignPost;
      });

  return (
    <KeyboardAwareScrollView
      style={{
        paddingTop: 120,
      }}
      contentContainerStyle={[
        styles.scrollView,
        { paddingBottom: Platform.OS === 'web' ? 0 : 120 },
      ]}
    >
      <View
        style={[
          SECTION_CONTAINER,
          {
            backgroundColor: INFO_GREEN,
            flexDirection: 'row',
            alignItems: 'center',
            marginHorizontal: 0,
          },
        ]}
      >
        <Octicons
          name="info"
          size={38}
          color="black"
          style={{
            marginRight: 8,
          }}
        />
        <Text
          style={{
            flex: 1,
            fontFamily: 'Lato',
            fontSize: 16,
            paddingLeft: 10,
          }}
        >
          Here is what your campaign will look like on the feed. If everything
          looks good, hit 'Publish' below!
        </Text>
      </View>

      <View pointerEvents={'none'}>
        {transformedData ? (
          <CampaignPost
            useOriginalMediaSource
            page={null}
            data={{
              ...transformedData.original_post,
              user: transformedData.user,
              campaign: transformedData,
              created_at: Date.now().toString(),
            }}
            disableHeader
            hideRelated
            preview
          />
        ) : (
          <GenericError message="There was a problem creating a preview. Please exit the campaign builder and try again." />
        )}

        {/* {transformedData.skilled_impact_requests?.items?.map?.((req) => (
          <View
            style={{
              paddingVertical: 12,
            }}
          >
            <SkillDescriptionPreview
              skilledImpactRequest={{
                ...req,
                campaign: transformedData,
              }}
            />
          </View>
        ))} */}
      </View>

      <CampaignBuilderFooter
        {...props}
        onNext={() => {
          if (!props.isSaving) props.publish?.();
        }}
        // Nothing to validate on this step
        validateForm={() => true}
        nextButtonLabel="Post Campaign"
      />
    </KeyboardAwareScrollView>
  );
};

export default Preview;
