import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

interface Props {
  style?: StyleProp<ViewStyle>;
}

export default function SectionTitleIconContainer(
  props: React.PropsWithChildren<Props>,
) {
  return <View style={[style, props.style]}>{props.children}</View>;
}

const style: ViewStyle = {
  marginRight: 8,
  width: 32,
  alignItems: 'center',
  justifyContent: 'center',
};
