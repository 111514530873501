import {
  LayoutChangeEvent,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import React, { useMemo, useRef, useState } from 'react';

interface IGridListProps<ItemT> {
  /** Grid list will target this as the target width for each tile. Width will vary
   * depending on container size. Default is `128` */
  maxTileWidth?: number;
  /** Callback that is called with the current tile width whenever tile width changes */
  onTileWidthChanged?: (tileWidth: number) => void;
  /** Tile width will be multiplied by this number if container width exceeds
   * `largeContainerWidthThreshold`. Default is `1.1`
   */
  largeContainerTileWidthMultiplier?: number;
  /** If the container becomes wider than this threshold, targetTileWidth will be
   * multiplied by largeContainerTileWidthMultiplier. Default is `480` */
  largeContainerWidthThreshold?: number;
  style?: StyleProp<ViewStyle>;
  tileContainerStyle?: StyleProp<ViewStyle>;
  squareTiles?: boolean;
  data: ItemT[] | undefined;
  renderItem: ({
    item,
    index,
    tileWidth,
  }: {
    item: ItemT;
    index: number;
    tileWidth: number;
  }) => JSX.Element | null;
  keyExtractor?: ({
    item,
    index,
  }: {
    item: ItemT;
    index: number;
  }) => string | number;
}

const DEFAULT_TARGET_TILE_WIDTH = 128;
const DEFAULT_LARGE_CONTAINER_TILE_WIDTH_MULTIPLIER = 1.1;
const DEFAULT_LARGE_CONTAINER_WIDTH_THRESHOLD = 480;

export default function GridList<ItemT = any>({
  onTileWidthChanged,
  ...props
}: IGridListProps<ItemT>) {
  const maxTileWidth = props.maxTileWidth ?? DEFAULT_TARGET_TILE_WIDTH;
  const largeContainerTileWidthMultiplier =
    props.largeContainerTileWidthMultiplier ??
    DEFAULT_LARGE_CONTAINER_TILE_WIDTH_MULTIPLIER;
  const largeContainerWidthThreshold =
    props.largeContainerWidthThreshold ??
    DEFAULT_LARGE_CONTAINER_WIDTH_THRESHOLD;

  // const [tileWidth, setTileWidth] = useState(maxTileWidth);
  const [containerWidth, setContainerWidth] = useState(0);

  function onGridContainerLayout({ nativeEvent }: LayoutChangeEvent) {
    setContainerWidth(nativeEvent.layout.width);
  }

  const tileCount = useMemo(() => {
    const maxWidth =
      containerWidth > largeContainerWidthThreshold
        ? maxTileWidth * largeContainerTileWidthMultiplier
        : maxTileWidth;

    return Math.ceil(containerWidth / maxWidth);
  }, [
    containerWidth,
    largeContainerTileWidthMultiplier,
    largeContainerWidthThreshold,
    maxTileWidth,
  ]);

  const lastTileWidth = useRef(0);

  const tileWidth = useMemo(() => {
    const newTileWidth = Math.floor(containerWidth / tileCount);

    if (newTileWidth !== lastTileWidth.current) {
      // Check if new tile width is within 8px of the old tile width
      if (Math.abs(newTileWidth - lastTileWidth.current) < 8) {
        /** If it is, and it is larger, then return */
        if (newTileWidth > lastTileWidth.current) return lastTileWidth.current;
      }

      lastTileWidth.current = newTileWidth;
      onTileWidthChanged?.(newTileWidth);
      return newTileWidth;
    }

    return lastTileWidth.current;
  }, [containerWidth, onTileWidthChanged, tileCount]);

  // Helper function to divide items into rows
  function divideIntoRows(items: ItemT[], itemsPerRow: number): ItemT[][] {
    const rows: ItemT[][] = [];

    if (itemsPerRow === 0) return rows;

    for (let i = 0; i < items.length; i += itemsPerRow) {
      rows.push(items.slice(i, i + itemsPerRow));
    }
    return rows;
  }

  const rows = divideIntoRows(props.data ?? [], tileCount);

  const itemStyle = [
    props.tileContainerStyle,
    {
      width: tileWidth,
      padding: 3,
      height: props.squareTiles ? tileWidth : undefined,
    },
  ].flat();

  return (
    <View
      style={[styles.container, props.style]}
      onLayout={onGridContainerLayout}
    >
      {rows.map((row, rowIndex) => (
        <View key={rowIndex} style={{ flexDirection: 'row' }}>
          {row.map((item, index) => {
            if (!item) return null;

            const compoundIndex = rowIndex * tileCount + index;

            const renderedItem = props.renderItem({
              item,
              index: compoundIndex,
              tileWidth,
            });

            /** If consumer returned null, do not create a container */
            if (renderedItem === null) return null;

            return (
              <View
                key={
                  props.keyExtractor?.({ item, index }) ||
                  (item as any)?.id ||
                  index
                }
                style={itemStyle}
              >
                {renderedItem}
              </View>
            );
          })}
        </View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 4,
    flexDirection: 'column',
  },
});
