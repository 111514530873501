import { UpdateResolver } from '@urql/exchange-graphcache';

const updateCampaignPost: UpdateResolver = (result, args, cache) => {
  const postId = args.id;
  const campaignId = (result as any)?.updateCampaignPost?.campaign?.id;

  cache.invalidate({
    __typename: 'CampaignPost',
    id: postId as any,
  });

  if (campaignId) {
    cache.invalidate({
      __typename: 'Campaign',
      id: campaignId as any,
    });
  } else {
    console.error(
      'Cache updater for updateCampaignPost tried to invalidate campaign, but campaign.id was not included in the selection set',
    );
  }
};

export default updateCampaignPost;
