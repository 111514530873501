import { Ionicons } from '@expo/vector-icons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Pressable,
  StyleProp,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import Button from '../Button';
import CampaignConnectInviteCard, {
  ICampaignConnectInvite,
} from '../CampaignConnect/CampaignConnectInviteCard/CampaignConnectInviteCard';
import CreateCampaignConnectInviteModal, {
  CampaignConnectInviteModalLinkablePost,
  CreateCampaignConnectInviteModalInvite,
} from '../CampaignConnect/CreateCampaignConnectInviteModal';
import {
  CampaignFlatListFilter,
  CampaignFlatListLayoutMode,
} from '../CampaignFlatList/CampaignFlatList';
import CampaignPreview from '../CampaignPreview/CampaignPreview';
import PaginatedList from '../PaginatedList/PaginatedList';
import SectionText from '../common/Section/SectionText';
import styles from './CampaignPicker.style';
import CampaignPickerModal, {
  ShowFilters,
} from './elements/CampaignPickerModal';
import {
  CARD_TITLE_FONT_SIZE,
  KEY_DARK_GREEN,
  KEY_GRAY,
  KEY_GREEN,
  PRIMARY_BUTTON_BACKGROUND,
} from '/constants';
import { useAuthContext, useModalContext } from '/context';
import {
  Campaign,
  CampaignPost,
  CreateCampaignConnectInvitesInput,
  User,
} from '/generated/graphql';
import { isEmpty } from '/util';

export interface ICampaignPickerCampaign
  extends Pick<Campaign, 'id' | 'name' | 'created_at'> {
  original_post: Pick<CampaignPost, 'id' | 'media' | 'thumbnail'>;
  user: Pick<User, 'id' | 'name' | 'profile_image'>;
}

interface Props {
  style?: StyleProp<ViewStyle>;
  buttonStyle?: StyleProp<ViewStyle>;
  buttonContainerStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  campaignConnectInviteButtonTextStyle?: StyleProp<TextStyle>;
  campaignConnectInviteButtonIconSize?: number;
  textStyle?: StyleProp<TextStyle>;
  resultsLayoutMode?: CampaignFlatListLayoutMode;
  buttonLabel?: string;
  showFilters?: ShowFilters;
  emptyPlaceholderText?: string;
  /** IDs of users who should be excluded from search when inviting organizations to create campaigns.
   * Authenticated user is automatically excluded. */
  campaignConnectInviteIneligibleUserIds?: string[];
  showCampaignConnectInviteButton?: boolean;
  /** Required if `campaignConnectInviteButtonMode` is 'save-immediately-and-return' (which is default) */
  campaignConnectInviteLinkedPost?: CampaignConnectInviteModalLinkablePost;
  /** Called when invites are changed. Invites that have been immediately saved have an `id`, entities that
   * are queued up to be saved by a higher component do not have an `id` */
  onCampaignConnectInvitesChanged?: (
    invites: Omit<CreateCampaignConnectInvitesInput, 'invites'> & {
      invites: (CreateCampaignConnectInvitesInput['invites'][number] & {
        id?: string;
      })[];
    },
  ) => void;
  /** Default mode is 'save-immediately-and-return' */
  campaignConnectInviteButtonMode?:
    | 'save-immediately-and-return'
    | 'just-return';
  filter: CampaignFlatListFilter;
  maxSelectionSize?: number;
  campaignConnectInvitesInitialState?: CreateCampaignConnectInviteModalInvite[];
  /** Array of Campaigns*/
  selection: ICampaignPickerCampaign[] | undefined;
  onChangeSelection?: (campaigns: ICampaignPickerCampaign[]) => void;
  darkTheme?: boolean;
}

export default function CampaignPicker({
  onCampaignConnectInvitesChanged,
  campaignConnectInviteIneligibleUserIds: ineligibleUserIds,
  filter,
  ...props
}: Props) {
  const { userData } = useAuthContext();
  const { spawnModal, closeModal } = useModalContext();

  const [invites, _setInvites] = useState<
    // Store both invites that have been immedately saved, and those
    // queued up to be saved by a higher component.
    CreateCampaignConnectInviteModalInvite[]
  >(props.campaignConnectInvitesInitialState ?? []);
  /** Used to get latest invites from modal context closure */
  const invitesRef = useRef<CreateCampaignConnectInviteModalInvite[]>(
    props.campaignConnectInvitesInitialState ?? [],
  );
  invitesRef.current = invites;
  const setInvites = useCallback(
    (_invites: CreateCampaignConnectInviteModalInvite[]) => {
      _setInvites(
        _invites.filter((inv) => {
          if (
            inv.invitedUserId &&
            ineligibleUserIds?.includes(inv.invitedUserId)
          )
            return false;

          return true;
        }),
      );
    },
    [ineligibleUserIds],
  );

  useEffect(() => {
    if (isEmpty(filter))
      console.error('[CampaignPicker] `filter` prop is required');
  }, [filter]);

  useEffect(
    function syncIneligibleInvites() {
      if (!ineligibleUserIds) return;

      _setInvites((prev) =>
        prev.filter(
          (inv) =>
            !inv.invitedUserId ||
            !ineligibleUserIds.includes(inv.invitedUserId),
        ),
      );
    },
    [ineligibleUserIds],
  );

  const modalIdRef = useRef<string>();
  function onShowCampaigns() {
    modalIdRef.current = spawnModal({
      title: 'SELECT CAMPAIGNS',
      disableEntireHeader: true,
      style: {
        height: '100%',
        width: '100%',
        maxHeight: 800,
        maxWidth: props.resultsLayoutMode === 'grid' ? 960 : 640,
      },
      component: (
        <CampaignPickerModal
          onClose={onCloseModal}
          filter={filter}
          emptyPlaceholderText={props.emptyPlaceholderText}
          resultsLayoutMode={props.resultsLayoutMode}
          showFilters={props.showFilters}
          selection={props.selection ?? []}
          maxSelectionSize={props.maxSelectionSize}
        />
      ),
    });
  }

  function onInvitesChanged(
    _invites: CreateCampaignConnectInviteModalInvite[],
  ) {
    if (!userData?.id) return;

    const linkablePost = props.campaignConnectInviteLinkedPost
      ? {
          [props.campaignConnectInviteLinkedPost.type]:
            props.campaignConnectInviteLinkedPost.id,
        }
      : {};

    onCampaignConnectInvitesChanged?.({
      ...linkablePost,
      invitingUserId: userData?.id,
      invites: _invites.map((i) => ({
        ...i,
      })),
    });
  }

  function onAddCampaignConnectInvite(
    invite: CreateCampaignConnectInviteModalInvite,
  ) {
    const newInvites: CreateCampaignConnectInviteModalInvite[] = [
      ...invitesRef.current.filter((i) => {
        if (i.id) return i.id !== invite.id;

        if (i.invitedUserId) return i.invitedUserId !== invite.invitedUserId;

        if (i.invitedUserEmail)
          return i.invitedUserEmail !== invite.invitedUserEmail;

        return false;
      }),
      invite,
    ];
    setInvites(newInvites);
    onInvitesChanged(newInvites);
  }

  function onUpdateInvite(updatedInvite: ICampaignConnectInvite) {
    const newInvites = invitesRef.current.map((i) =>
      i.invitedUserId === updatedInvite.invitedUser?.id
        ? {
            ...i,
            ...updatedInvite,
          }
        : i,
    );
    setInvites(newInvites);
    onInvitesChanged(newInvites);
  }

  function onRemoveCampaignConnectInvite(
    invite: CreateCampaignConnectInviteModalInvite,
  ) {
    const newInvites = invitesRef.current.filter((i) => {
      if (invite.id) return i.id !== invite.id;

      const invitedUserId = invite.invitedUserId ?? invite.invitedUser?.id;
      if (invitedUserId) return i.invitedUserId !== invitedUserId;

      return true;
    });
    setInvites(newInvites);
    onInvitesChanged(newInvites);
  }

  const campaignConnectInviteModalIdRef = useRef<string>();
  function onCreateCampaignConnectInvite() {
    if (!userData?.id) return;

    campaignConnectInviteModalIdRef.current = spawnModal({
      title: 'CAMPAIGN CONNECT',
      style: {
        width: '100%',
        height: '100%',
        maxWidth: 480,
        maxHeight: 640,
      },
      component: (
        <CreateCampaignConnectInviteModal
          saveButtonAction={
            props.campaignConnectInviteButtonMode ||
            'save-immediately-and-return'
          }
          linkedPost={props.campaignConnectInviteLinkedPost}
          invitingUserId={userData.id}
          ineligibleUserIds={ineligibleUserIds ?? []}
          onRequestClose={(invite) => {
            if (invite) onAddCampaignConnectInvite(invite);

            if (campaignConnectInviteModalIdRef.current)
              closeModal(campaignConnectInviteModalIdRef.current);
          }}
        />
      ),
    });
  }

  function onCloseModal(newSelection: ICampaignPickerCampaign[]) {
    modalIdRef.current && closeModal(modalIdRef.current);
    props.onChangeSelection?.(getNormalizedCampaigns(newSelection));
  }

  function getNormalizedCampaigns(
    campaigns: ICampaignPickerCampaign[],
  ): ICampaignPickerCampaign[] {
    return campaigns.map((campaign) => ({
      id: campaign.id,
      name: campaign.name,
      created_at: campaign.created_at,
      user: {
        id: campaign.user?.id,
        name: campaign.user?.name,
        profile_image: campaign.user?.profile_image,
      },
      original_post: {
        id: campaign.original_post.id,
        thumbnail: campaign.original_post.thumbnail,
        media: campaign.original_post.media,
      },
    }));
  }

  function onRemoveCampaign(campaignId: string) {
    const selection = props.selection ?? [];
    const newSelection = selection.filter((c) => c.id !== campaignId);

    if (props.onChangeSelection)
      props.onChangeSelection(getNormalizedCampaigns(newSelection));
  }

  return (
    <View style={[styles.container, props.style]}>
      {props.selection?.length ? (
        <PaginatedList
          textColor={props.darkTheme ? 'lightgray' : undefined}
          caretColor={props.darkTheme ? PRIMARY_BUTTON_BACKGROUND : undefined}
          data={props.selection ?? []}
          style={{
            marginVertical: 6,
          }}
          renderItem={(campaign) => (
            <View key={campaign.id} style={styles.selectionItemContainer}>
              <View style={{ flex: 1 }}>
                <CampaignPreview
                  campaign={campaign}
                  campaignPost={campaign.original_post}
                  {...(props.darkTheme
                    ? {
                        chevronColor: '#fff',
                        subtitleTextStyle: {
                          color: 'white',
                        },
                        titleStyle: {
                          color: 'white',
                        },
                        authorTextStyle: {
                          color: 'white',
                        },
                      }
                    : {})}
                />
              </View>
              <Pressable
                style={styles.selectionItemRemoveButton}
                onPress={() => onRemoveCampaign(campaign.id)}
                testID={`unselect-campaign-${campaign.id}`}
              >
                <Ionicons
                  name="remove-circle-outline"
                  size={24}
                  color={props.darkTheme ? 'white' : 'black'}
                />
              </Pressable>
            </View>
          )}
        />
      ) : null}

      <Button
        label={props.buttonLabel ?? 'Select Campaigns'}
        style={props.buttonStyle}
        containerStyle={props.buttonContainerStyle}
        onPress={onShowCampaigns}
      />

      {props.showCampaignConnectInviteButton ? (
        <>
          <Text
            style={[
              {
                flex: 1,
                marginTop: 20,
                textTransform: 'uppercase',
                fontFamily: 'LeagueSpartan-Bold',
                fontSize: CARD_TITLE_FONT_SIZE - 2,
                color: props.darkTheme ? 'white' : KEY_GRAY,
              },
              props.titleStyle,
            ]}
          >
            Can't find the right campaign?
          </Text>
          <SectionText
            style={[
              {
                color: props.darkTheme ? 'white' : 'black',
              },
              props.textStyle,
            ]}
          >
            Invite organizations to create relevant campaigns to be featured in
            your post.
          </SectionText>
          <Pressable
            onPress={() => {
              onCreateCampaignConnectInvite();
            }}
            style={({ pressed }) => ({
              opacity: pressed ? 0.5 : 1,
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 4,
            })}
          >
            <Ionicons
              name="add-circle-outline"
              size={props.campaignConnectInviteButtonIconSize ?? 22}
              style={{
                marginRight: 2,
              }}
              color={props.darkTheme ? KEY_GREEN : KEY_DARK_GREEN}
            />
            <Text
              style={[
                {
                  fontFamily: 'Lato-Bold',
                  fontSize: 17,
                  color: props.darkTheme ? KEY_GREEN : KEY_DARK_GREEN,
                },
                props.campaignConnectInviteButtonTextStyle,
              ]}
            >
              Invite an organization to create a campaign
            </Text>
          </Pressable>
          {invites.length ? (
            <>
              <Text
                style={{
                  flex: 1,
                  marginTop: 8,
                  textTransform: 'uppercase',
                  fontFamily: 'LeagueSpartan-Bold',
                  fontSize: CARD_TITLE_FONT_SIZE - 2,
                  color: props.darkTheme ? 'white' : KEY_GRAY,
                }}
              >
                INVITES
              </Text>
              <PaginatedList
                textColor={props.darkTheme ? 'lightgray' : undefined}
                caretColor={
                  props.darkTheme ? PRIMARY_BUTTON_BACKGROUND : undefined
                }
                data={invites.sort((a, b) => {
                  // make sure invites with submittedCampaignId are at the top
                  if (a.submittedCampaignId && !b.submittedCampaignId)
                    return -1;
                  if (!a.submittedCampaignId && b.submittedCampaignId) return 1;
                  return 0;
                })}
                renderItem={(item) => (
                  <CampaignConnectInviteCard
                    key={
                      item.id ??
                      `${item.invitedUserId}-${item.invitedUserEmail}`
                    }
                    darkTheme={props.darkTheme}
                    invite={item}
                    viewingAsInvitedUser={false}
                    onUpdated={onUpdateInvite}
                    onRemoved={() => {
                      onRemoveCampaignConnectInvite(item);
                    }}
                  />
                )}
              />
            </>
          ) : null}
        </>
      ) : null}
    </View>
  );
}
