import { useNavigation } from '@react-navigation/native';
import { Badge } from '@rneui/themed';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import CommentIcon from '/assets/jsicons/CommentIcon';

export default function CommentsButton({
  interactionsId,
  commentCount,
  size = 44,
  style = {},
}) {
  const { navigate } = useNavigation();

  const badgeValue = (() => {
    /** If in the tens of millions, divide by 1 million and append 'm', removing decimal places */
    if (commentCount >= 10000000) {
      return `${Math.floor(commentCount / 1000000)}m`;
    }

    /** If in the millions, divide by 1 million and append 'm', maintaining one decimal place */
    if (commentCount >= 1000000) {
      return `${(commentCount / 1000000).toFixed(1)}m`;
    }

    /** If in the tens of thousands, divide by 1000 and append 'k', removing decimal places */
    if (commentCount >= 10000) {
      return `${Math.floor(commentCount / 1000)}k`;
    }

    /** If in the thousands, divide by 1000 and append 'k', maintaining one decimal place */
    if (commentCount >= 1000) {
      return `${(commentCount / 1000).toFixed(1)}k`;
    }

    return commentCount;
  })();

  return (
    <TouchableOpacity
      style={[
        {
          borderRadius: 12,
          backgroundColor: '#F5F5F5',
          height: size,
          width: size * 1.075,
          margin: 2,
          paddingLeft: 2,
          justifyContent: 'center',
          alignItems: 'center',
        },
        style,
      ]}
      onPress={() => {
        navigate('Comments', {
          interactionsId,
        });
      }}
    >
      <CommentIcon width={size * 0.7} height={size * 0.7} />
      {commentCount > 0 ? (
        <Badge
          textStyle={{
            color: 'black',
            fontSize: Math.floor(0.35 * size),
            flexWrap: 'nowrap',
          }}
          textProps={{
            adjustsFontSizeToFit: true,
          }}
          badgeStyle={{
            backgroundColor: '#CAFF03',
          }}
          containerStyle={{
            position: 'absolute',
            top: -6,
            right: -6,
          }}
          value={badgeValue}
        />
      ) : null}
    </TouchableOpacity>
  );
}
