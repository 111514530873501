import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  errorContainer: {
    alignSelf: 'center',
    alignItems: 'center',
    padding: 24,
    marginTop: 100,
  },
  errorText: {
    textAlign: 'center',
    color: 'crimson',
    fontFamily: 'Lato-Bold',
    fontSize: 15,
  },
});
