import React from 'react';
import SvgXml from '/components/common/SvgXml';

export default function Logo(props) {
  const logo = `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="338.000000pt" height="338.000000pt" viewBox="0 0 338.000000 338.000000"
    preserveAspectRatio="xMidYMid meet">
   <metadata>
   Created by potrace 1.16, written by Peter Selinger 2001-2019
   </metadata>
   <g transform="translate(0.000000,338.000000) scale(0.100000,-0.100000)"
   fill="#000000" stroke="none">
   <path d="M1510 3299 c-728 -70 -1319 -626 -1435 -1349 -19 -124 -22 -360 -5
   -475 53 -361 201 -656 460 -916 230 -231 482 -369 807 -441 85 -19 129 -22
   328 -22 201 -1 242 2 328 21 305 69 566 207 779 412 300 287 465 633 498 1044
   60 731 -394 1417 -1089 1647 -140 47 -230 66 -376 80 -122 11 -171 11 -295 -1z
   m400 -166 c198 -31 428 -127 605 -252 103 -72 264 -234 338 -338 121 -170 216
   -399 252 -608 21 -117 21 -361 1 -475 -73 -413 -304 -763 -651 -990 -205 -133
   -450 -215 -682 -227 l-103 -6 0 622 0 621 115 0 115 0 0 70 0 70 -110 0 -110
   0 0 55 0 55 105 0 105 0 0 75 0 75 -105 0 -105 0 0 55 0 55 110 0 110 0 0 70
   0 70 -190 0 -190 0 0 -945 0 -944 -32 5 c-206 35 -324 71 -471 144 -421 210
   -716 611 -792 1075 -19 115 -19 344 0 460 88 547 486 1005 1010 1163 233 71
   430 84 675 45z"/>
   <path d="M690 1805 l0 -325 80 0 79 0 3 126 3 127 109 -127 108 -126 111 0
   c104 0 109 1 95 18 -8 9 -72 82 -143 162 -70 79 -129 150 -132 157 -3 7 46 72
   109 145 62 73 119 141 127 151 13 16 7 17 -85 17 l-99 -1 -100 -120 -100 -121
   -3 121 -3 121 -79 0 -80 0 0 -325z"/>
   <path d="M2126 2103 c9 -16 64 -98 121 -183 l103 -155 0 -142 0 -143 80 0 80
   0 0 145 0 145 111 166 c60 91 113 172 116 180 4 12 -11 14 -89 14 l-94 0 -58
   -90 c-32 -49 -61 -92 -66 -95 -4 -3 -35 38 -69 90 l-61 95 -96 0 -96 0 18 -27z"/>
   </g>
   </svg>`;
  return <SvgXml width={125} height={125} {...props} xml={logo} />;
}
