import { Cache } from '@urql/exchange-graphcache';
import { TypedDocumentNode } from 'urql';

interface PaginatedFieldParams {
  cache: Cache;
  /** The entity containing the target paginated field */
  entity: any & {
    __typename: string;
    id?: string;
  };
  /** The paginated field to remove item from */
  paginatedField: {
    name: string;
    fragment: TypedDocumentNode;
    args?: Record<string, any>; // Added optional args property
  };
  /** The item to remove from the paginated field */
  removeItem: any & {
    id?: string;
  };
}

const removeItemFromPaginatedField = (params: PaginatedFieldParams) => {
  const { cache, entity, paginatedField, removeItem } = params;

  const entityFields = cache.inspectFields(entity);

  const paginatedFields = entityFields.filter((field) => {
    if (field.fieldName !== paginatedField.name) {
      return false;
    }

    if (paginatedField.args) {
      // Ensure all specified args are present in field.arguments
      if (
        !Object.entries(paginatedField.args).every(
          ([key, value]) => field.arguments?.[key] === value,
        )
      )
        return false;
    }

    return true;
  });

  paginatedFields.forEach((field) => {
    const paginatedData: any = cache.readFragment(
      paginatedField.fragment,
      entity,
      field.arguments as any,
    );

    if (paginatedData?.[paginatedField.name]?.items) {
      const updatedItems = paginatedData[paginatedField.name].items.filter(
        (item: any) => {
          return Object.keys(removeItem).some((attr) => {
            return item[attr] !== removeItem[attr];
          });
        },
      );

      const total = paginatedData?.[paginatedField.name]?.total
        ? paginatedData[paginatedField.name].total - 1
        : undefined;

      cache.writeFragment(
        paginatedField.fragment,
        {
          ...entity,
          [paginatedField.name]: {
            ...paginatedData[paginatedField.name],
            items: updatedItems,
            ...(total ? { total } : {}),
          },
        },
        field.arguments as any,
      );
    }
  });
};

export default removeItemFromPaginatedField;
