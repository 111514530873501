import React, { useCallback, useEffect } from 'react';
import { DepositMethodStatus } from '/hooks/useStripeStatus';
import { post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useAuthContext } from './AuthProvider';
import { UserRole } from '/generated/graphql';

type StripeStatusContext = {
  status: DepositMethodStatus;
  fetching: boolean;
  error: boolean;
  acceptingCharges: boolean;
  getStripeStatus: () => Promise<void>;
};

interface IStripeContext {
  status: StripeStatusContext;
}

type StripeStatusResult = {
  charges_enabled: boolean;
  requirements: {
    disabled_reason: string;
    eventually_due: string[];
  };
};

const StripeContext = React.createContext<IStripeContext>({} as any);

export function useStripeContext() {
  return React.useContext(StripeContext);
}

export default function StripeProvider(props: React.PropsWithChildren<{}>) {
  const { userData } = useAuthContext();

  const [status, setStatus] = React.useState<DepositMethodStatus>('inactive');
  const [acceptingCharges, setAcceptingCharges] = React.useState(false);
  const [fetchingStatus, setFetchingStatus] = React.useState(false);
  const [statusError, setStatusError] = React.useState(false);

  const getStripeStatus = useCallback(async function (): Promise<void> {
    setFetchingStatus(true);
    setStatusError(false);

    try {
      const session = await fetchAuthSession();
      const idToken = session.tokens?.idToken?.toString();

      const response = await post({
        apiName: 'stripeconnect',
        path: '/stripe/status',
        options: {
          body: {
            authorization: idToken,
          } as any,
        },
      }).response;

      const result = (await response.body.json()) as StripeStatusResult;

      setAcceptingCharges(result?.charges_enabled || false);

      if (result?.charges_enabled) {
        setStatus('active');

        if (result?.requirements.eventually_due.length > 0) {
          setStatus('action_eventually_required');
        }
      } else {
        setStatus('inactive');

        switch (result?.requirements?.disabled_reason) {
          case 'requirements.past_due':
            setStatus('action_required');
            break;
          case 'requirements.pending_verification':
          case 'listed':
          case 'under_review':
          case 'other':
            setStatus('in_review');
            break;
          case 'rejected.fraud':
          case 'rejected.terms_of_service':
          case 'rejected.listed':
          case 'rejected.other':
            setStatus('rejected');
            break;
          default:
            setStatus('inactive');
        }
      }
    } catch (err) {
      console.log('getStripeStatus error', err);

      setStatusError(true);
    } finally {
      setFetchingStatus(false);
    }
  }, []);

  useEffect(() => {
    if (userData?.role !== UserRole.Conservationist) return;

    getStripeStatus();
  }, [getStripeStatus, userData]);

  return (
    <StripeContext.Provider
      value={{
        status: {
          status,
          fetching: fetchingStatus,
          error: statusError,
          acceptingCharges,
          getStripeStatus,
        },
      }}
    >
      {props.children}
    </StripeContext.Provider>
  );
}
