import geocoder from '/util/mapping/geocoder';
import env from '/env';

const geo = geocoder({
  key: env.WEB_GOOGLE_MAPS_KEY,
});

type ReverseGeocodeResult = {
  administrative_area_level_1: {
    long_name: string;
    short_name: string;
  };
  administrative_area_level_2: {
    long_name: string;
    short_name: string;
  };
  city: {
    long_name: string;
    short_name: string;
  };
  country: {
    long_name: string;
    short_name: string;
  };
  formatted_address: string;
  locality: {
    long_name: string;
    short_name: string;
  };
  location: {
    lat: number;
    lng: number;
  };
  location_bounds: {
    northeast: {
      lat: number;
      lng: number;
    };
    southwest: {
      lat: number;
      lng: number;
    };
  };
  location_type: string;
  plus_code: {
    long_name: string;
    short_name: string;
  };
  postal_code: {
    long_name: string;
    short_name: string;
  };
  province_state: {
    long_name: string;
    short_name: string;
  };
};

export default async function reverseGeocode({
  latitude,
  longitude,
}: {
  latitude: number;
  longitude: number;
}): Promise<ReverseGeocodeResult[]> {
  return new Promise((resolve, reject) => {
    geo.reverseFind(
      latitude,
      longitude,
      (err: any, res: ReverseGeocodeResult[]) => {
        if (err) reject(err);
        else resolve(res);
      },
    );
  });
}
