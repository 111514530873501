import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';

const unfollowResearchTopic: UpdateResolver = (result, args, cache) => {
  const fragment = gql`
    fragment UnfollowResearchTopic on ResearchTopic {
      topic
      is_following
      follower_count
    }
  `;

  const data = cache.readFragment(fragment, { topic: args.topic }) as any;

  cache.writeFragment(fragment, {
    topic: args.topic,
    is_following: false,
    follower_count: (data?.follower_count ?? 1) - 1,
  });
};

export default unfollowResearchTopic;
