/**
 * Handles navigation layout and routing all all device sizes.
 *
 * For use on both native and the browser.
 */

import {
  createNavigatorFactory,
  DefaultNavigatorOptions,
  ParamListBase,
  TabActionHelpers,
  TabNavigationState,
  TabRouter,
  TabRouterOptions,
  useNavigationBuilder,
} from '@react-navigation/native';
import React from 'react';
import { TextStyle, ViewStyle } from 'react-native';
import ResponsiveTabView from '../views/ResponsiveTabView';

// Navigator props
export type TabNavigationConfig = {
  tabBarOptions: {
    activeBackgroundColor?: string;
    activeTintColor?: string;
    iconHorizontal?: boolean;
    allowFontScaling?: boolean;
    inactiveBackgroundColor?: string;
    inactiveTintColor?: string;
    labelStyle?: TextStyle;
    showIcon?: boolean;
    showLabel?: boolean;
    iconSize?: number;
    labelSize?: number;
    style?: ViewStyle;
    tabStyle?: ViewStyle;
  };
};

// Screen props
export type ResponsiveTabNavigationOptions = {
  isAdminScreen?: boolean;
  authRequired?: boolean;
  tabBarLabel?: string;
  unmountOnBlur?: boolean;
  contentMaxWidth?: number;
  /** Default is `true` */
  headerShown?: boolean;
  tabBarIcon?: (args: {
    focused: boolean;
    size: number;
    color: string;
  }) => JSX.Element;
};

type TabNavigationEventMap = {
  /**
   * Event which fires on tapping on the tab in the tab bar.
   */
  tabPress: { data: undefined; canPreventDefault: true };
  /**
   * Event which fires on long press on the tab in the tab bar.
   */
  tabLongPress: { data: undefined };
};

export type ResponsiveTabNavigatorProps = DefaultNavigatorOptions<
  ParamListBase,
  TabNavigationState<ParamListBase>,
  ResponsiveTabNavigationOptions,
  TabNavigationEventMap
> &
  TabRouterOptions &
  TabNavigationConfig & {
    /**
     * Whether inactive screens should be detached from the view hierarchy to save memory.
     * Make sure to call `enableScreens` from `react-native-screens` to make it work.
     * Defaults to `true` on Android.
     */
    detachInactiveScreens?: boolean;
  };

function ResponsiveTabNavigator({
  initialRouteName,
  backBehavior,
  children,
  screenOptions,
  ...rest
}: ResponsiveTabNavigatorProps) {
  const { state, descriptors, navigation } = useNavigationBuilder<
    TabNavigationState<ParamListBase>,
    TabRouterOptions,
    TabActionHelpers<ParamListBase>,
    ResponsiveTabNavigationOptions,
    TabNavigationEventMap
  >(TabRouter, {
    initialRouteName,
    backBehavior,
    children,
    screenOptions,
  });

  return (
    <ResponsiveTabView
      {...rest}
      state={state}
      navigation={navigation as any}
      descriptors={descriptors}
    >
      {children}
    </ResponsiveTabView>
  );
}

export default createNavigatorFactory<
  TabNavigationState<ParamListBase>,
  ResponsiveTabNavigationOptions,
  TabNavigationEventMap,
  typeof ResponsiveTabNavigator
>(ResponsiveTabNavigator);
