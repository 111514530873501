import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Modal,
  FlatList,
  TextInput,
  Image,
} from 'react-native';

import { EvilIcons, AntDesign } from '@expo/vector-icons';

const habitats = [
  {
    class: 'Forest',
    key: '1',
    subClasses: [
      {
        subClass: 'Forest',
        key: '1',
      },
      {
        subClass: 'Forest - Boreal',
        key: '1.1',
      },
      {
        subClass: 'Forest - Subarctic',
        key: '1.2',
      },
      {
        subClass: 'Forest - Subantarctic',
        key: '1.3',
      },
      {
        subClass: 'Forest - Temperate',
        key: '1.4',
      },
      {
        subClass: 'Forest - Subtropical/tropical dry',
        key: '1.5',
      },
      {
        subClass: 'Forest - Subtropical/tropical moist lowland',
        key: '1.6',
      },
      {
        subClass:
          'Forest - Subtropical/tropical mangrove vegetation above high tide level',
        key: '1.7',
      },
      {
        subClass: 'Forest - Subtropical/tropical swamp',
        key: '1.8',
      },
      {
        subClass: 'Forest - Subtropical/tropical moist montane',
        key: '1.9',
      },
    ],
  },
  {
    class: 'Savanna',
    key: '2',
    subClasses: [
      {
        subClass: 'Savanna',
        key: '2',
      },
      {
        subClass: 'Savanna - Dry',
        key: '2.1',
      },
      {
        subClass: 'Savanna - Moist',
        key: '2.2',
      },
    ],
  },
  {
    class: 'Shrubland',
    key: '3',
    subClasses: [
      {
        subClass: 'Shrubland',
        key: '3',
      },
      {
        subClass: 'Shrubland - Subarctic',
        key: '3.1',
      },
      {
        subClass: 'Shrubland - Subantarctic',
        key: '3.2',
      },
      {
        subClass: 'Shrubland - Boreal',
        key: '3.3',
      },
      {
        subClass: 'Shrubland - Temperate',
        key: '3.4',
      },
      {
        subClass: 'Shrubland - Subtropical/tropical dry',
        key: '3.5',
      },
      {
        subClass: 'Shrubland - Subtropical/tropical moist',
        key: '3.6',
      },
      {
        subClass: 'Shrubland - Subtropical/tropical high altitude',
        key: '3.7',
      },
      {
        subClass: 'Shrubland - Mediterranean-type shrubby vegetation',
        key: '3.8',
      },
    ],
  },
  {
    class: 'Grassland',
    key: '4',
    subClasses: [
      {
        subClass: 'Grassland',
        key: '4',
      },
      {
        subClass: 'Grassland - Tundra',
        key: '4.1',
      },
      {
        subClass: 'Grassland - Subarctic',
        key: '4.2',
      },
      {
        subClass: 'Grassland - Subantarctic',
        key: '4.3',
      },
      {
        subClass: 'Grassland - Temperate',
        key: '4.4',
      },
      {
        subClass: 'Grassland -  Subtropical/tropical dry',
        key: '4.5',
      },
      {
        subClass: 'Grassland - Subtropical/tropical seasonally wet/flooded',
        key: '4.6',
      },
      {
        subClass: 'Grassland - Subtropical/tropical high altitude',
        key: '4.7',
      },
    ],
  },
  {
    class: 'Wetlands (inland)',
    key: '5',
    subClasses: [
      {
        subClass: 'Wetlands',
        key: '5',
      },
      {
        subClass:
          'Wetlands (inland) – Permanent rivers/streams/creeks (includes waterfalls)',
        key: '5.1',
      },
      {
        subClass:
          'Wetlands (inland) – Seasonal/intermittent/irregular rivers/streams/creeks',
        key: '5.2',
      },
      {
        subClass: 'Wetlands (inland) – Shrub dominated wetlands',
        key: '5.3',
      },
      {
        subClass: 'Wetlands (inland) – Bogs, marshes, swamps, fens, peatlands',
        key: '5.4',
      },
      {
        subClass: 'Wetlands (inland) – Permanent freshwater lakes (over 8 ha)',
        key: '5.5',
      },
      {
        subClass:
          'Wetlands (inland) – Seasonal/intermittent freshwater lakes (over 8 ha)',
        key: '5.6',
      },
      {
        subClass:
          'Wetlands (inland) – Permanent freshwater marshes/pools (under 8 ha)',
        key: '5.7',
      },
      {
        subClass:
          'Wetlands (inland) – Seasonal/intermittent freshwater marshes/pools (under 8 ha)',
        key: '5.8',
      },
      {
        subClass: 'Wetlands (inland) – Freshwater springs and oases',
        key: '5.9',
      },
      {
        subClass:
          'Wetlands (inland) – Tundra wetlands (inc. pools and temporary waters from snowmelt)',
        key: '5.10',
      },
      {
        subClass:
          'Wetlands (inland) – Alpine wetlands (inc. temporary waters from snowmelt)',
        key: '5.11',
      },
      {
        subClass: 'Wetlands (inland) – Geothermal wetlands',
        key: '5.12',
      },
      {
        subClass: 'Wetlands (inland) – Permanent inland deltas',
        key: '5.13',
      },
      {
        subClass:
          'Wetlands (inland) – Permanent saline, brackish or alkaline lakes',
        key: '5.14',
      },
      {
        subClass:
          'Wetlands (inland) – Seasonal/intermittent saline, brackish or alkaline lakes and flats',
        key: '5.15',
      },
      {
        subClass:
          'Wetlands (inland) – Permanent saline, brackish or alkaline marshes/pools',
        key: '5.16',
      },
      {
        subClass:
          'Wetlands (inland) – Seasonal/intermittent saline, brackish or alkaline marshes/pools',
        key: '5.17',
      },
      {
        subClass:
          'Wetlands (inland) – Karst and other subterranean hydrological systems (inland)',
        key: '5.18',
      },
    ],
  },
  {
    class: 'Rocky Areas (e.g., inland cliffs, mountain peaks)',
    key: '6',
    subClasses: null,
  },
  {
    class: 'Caves & Subterranean Habitats (non-aquatic)',
    key: '7',
    subClasses: [
      {
        subClass: 'Caves & Subterranean Habitats (non-aquatic)',
        key: '7',
      },
      {
        subClass: 'Caves & Subterranean Habitats (non-aquatic) - Caves',
        key: '7.1',
      },
      {
        subClass:
          'Caves & Subterranean Habitats (non-aquatic) - Other subterranean habitats',
        key: '7.2',
      },
    ],
  },
  {
    class: 'Desert',
    key: '8',
    subClasses: [
      {
        subClass: 'Desert',
        key: '8',
      },
      {
        subClass: 'Desert - Hot',
        key: '8.1',
      },
      {
        subClass: 'Desert - Temperate',
        key: '8.2',
      },
      {
        subClass: 'Desert - Cold',
        key: '8.3',
      },
    ],
  },
  {
    class: 'Marine Neritic',
    key: '9',
    subClasses: [
      {
        subClass: 'Marine Neritic',
        key: '9',
      },
      {
        subClass: 'Marine Neritic - Pelagic',
        key: '9.1',
      },
      {
        subClass: 'Marine Neritic - Subtidal rock and rocky reefs',
        key: '9.2',
      },
      {
        subClass: 'Marine Neritic - Subtidal loose rock/pebble/gravel',
        key: '9.3',
      },
      {
        subClass: 'Marine Neritic - Subtidal sandy',
        key: '9.4',
      },
      {
        subClass: 'Marine Neritic - Subtidal sandy-mud',
        key: '9.5',
      },
      {
        subClass: 'Marine Neritic - Subtidal muddy',
        key: '9.6',
      },
      {
        subClass: 'Marine Neritic - Macroalgal/kelp',
        key: '9.7',
      },
      {
        subClass: 'Marine Neritic - Coral Reef',
        key: '9.8',
        subClasses: [
          {
            subClass: 'Outer reef channel',
            key: '9.8.1',
          },
          {
            subClass: 'Back slope',
            key: '9.8.2',
          },
          {
            subClass: 'Foreslope (outer reef slope)',
            key: '9.8.3',
          },
          {
            subClass: 'Lagoon',
            key: '9.8.4',
          },
          {
            subClass: 'Inter-reef soft substrate',
            key: '9.8.5',
          },
          {
            subClass: 'Inter-reef rubble substrate',
            key: '9.8.6',
          },
        ],
      },
      {
        subClass: 'Marine Neritic - Seagrass (Submerged)',
        key: '9.9',
      },
      {
        subClass: 'Marine Neritic - Estuaries',
        key: '9.10',
      },
    ],
  },
  {
    class: 'Marine Oceanic',
    key: '10',
    subClasses: [
      {
        subClass: 'Marine Oceanic',
        key: '10',
      },
      {
        subClass: 'Epipelagic (0–200 m)',
        key: '10.1',
      },
      {
        subClass: 'Mesopelagic (200–1,000 m)',
        key: '10.2',
      },
      {
        subClass: 'Bathypelagic (1,000–4,000 m)',
        key: '10.3',
      },
      {
        subClass: 'Abyssopelagic (4,000–6,000 m)',
        key: '10.4',
      },
    ],
  },
  {
    class: 'Marine Deep Ocean Floor (Benthic and Demersal)',
    key: '11',
    subClasses: [
      {
        subClass: 'Marine Deep Ocean Floor (Benthic and Demersal)',
        key: '11',
      },
      {
        subClass: 'Continental Slope/Bathyl Zone (200–4,000 m)',
        key: '11.1',
        subClasses: [
          {
            subClass: 'Hard Substrate',
            key: '11.1.1',
          },
          {
            subClass: 'Soft Substrate',
            key: '11.1.2',
          },
        ],
      },
      {
        subClass: 'Abyssal Plain (4,000–6,000 m)',
        key: '11.2',
      },
      {
        subClass: 'Abyssal Mountain/Hills (4,000–6,000 m)',
        key: '11.3',
      },
      {
        subClass: 'Hadal/Deep Sea Trench (>6,000 m)',
        key: '11.4',
      },
      {
        subClass: 'Seamount',
        key: '11.5',
      },
      {
        subClass: 'Deep Sea Vents (Rifts/Seeps)',
        key: '11.6',
      },
    ],
  },
  {
    class: 'Marine Intertidal',
    key: '12',
    subClasses: [
      {
        subClass: 'Marine Intertidal',
        key: '12',
      },
      {
        subClass: 'Rocky Shoreline',
        key: '12.1',
      },
      {
        subClass: 'Sandy Shoreline and/or Beaches, Sand Bars, Spits, etc.',
        key: '12.2',
      },
      {
        subClass: 'Shingle and/or Pebble Shoreline and/or Beaches',
        key: '12.3',
      },
      {
        subClass: 'Mud Shoreline and Intertidal Mud Flats',
        key: '12.4',
      },
      {
        subClass: 'Salt Marshes (Emergent Grasses)',
        key: '12.5',
      },
      {
        subClass: 'Tidepools',
        key: '12.6',
      },
      {
        subClass: 'Mangrove Submerged Roots',
        key: '12.7',
      },
    ],
  },
  {
    class: 'Marine Coastal/Supratidal',
    key: '13',
    subClasses: [
      {
        subClass: 'Marine Coastal/Supratidal',
        key: '13',
      },
      {
        subClass: 'Sea Cliffs and Rocky Offshore Islands',
        key: '13.1',
      },
      {
        subClass: 'Coastal Caves/Karst',
        key: '13.2',
      },
      {
        subClass: 'Coastal Sand Dunes',
        key: '13.3',
      },
      {
        subClass: 'Coastal Brackish/Saline Lagoons/Marine Lakes',
        key: '13.4',
      },
      {
        subClass: 'Coastal Freshwater Lakes',
        key: '13.5',
      },
    ],
  },
  {
    class: 'Artificial - Terrestrial',
    key: '14',
    subClasses: [
      {
        subClass: 'Artificial - Terrestrial',
        key: '14',
      },
      {
        subClass: 'Arable Land',
        key: '14.1',
      },
      {
        subClass: 'Pastureland',
        key: '14.2',
      },
      {
        subClass: 'Plantations',
        key: '14.3',
      },
      {
        subClass: 'Rural Gardens',
        key: '14.4',
      },
      {
        subClass: 'Urban Areas',
        key: '14.5',
      },
      {
        subClass: 'Subtropical/Tropical Heavily Degraded Former Forest',
        key: '14.6',
      },
    ],
  },
  {
    class: 'Artificial - Aquatic',
    key: '15',
    subClasses: [
      {
        subClass: 'Artificial - Aquatic',
        key: '15',
      },
      {
        subClass: 'Water Storage Areas [over 8 ha]',
        key: '15.1',
      },
      {
        subClass: 'Ponds [below 8 ha]',
        key: '15.2',
      },
      {
        subClass: 'Aquaculture Ponds',
        key: '15.3',
      },
      {
        subClass: 'Salt Exploitation Sites',
        key: '15.4',
      },
      {
        subClass: 'Excavations (open)',
        key: '15.5',
      },
      {
        subClass: 'Wastewater Treatment Areas',
        key: '15.6',
      },
      {
        subClass: 'Irrigated Land [includes irrigation channels]',
        key: '15.7',
      },
      {
        subClass: 'Seasonally Flooded Agricultural Land',
        key: '15.8',
      },
      {
        subClass: 'MoiCanals and Drainage Channels, Ditchesst',
        key: '15.9',
      },
      {
        subClass:
          'Karst and Other Subterranean Hydrological Systems [human-made]',
        key: '15.10',
      },
      {
        subClass: 'Marine Anthropogenic Structures',
        key: '15.11',
      },
      {
        subClass: 'Mariculture Cages',
        key: '15.12',
      },
      {
        subClass: 'Mari/Brackish-culture Ponds',
        key: '15.13',
      },
    ],
  },
  {
    class: 'Introduced Vegetation',
    key: '16',
    subClasses: null,
  },
  {
    class: 'Other',
    key: '17',
    subClasses: null,
  },
];

import languages from '../assets/json/languages.json';

export const HabitatPicker = createContext({});

export const useHabitatPickerContext = () => useContext(HabitatPicker);

export default function HabitatPickerProvider(props) {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  // const [memLang, setMemLang] = useState([]);
  const [dataType, setDataType] = useState('');
  const [filter, setFilter] = useState('');
  const [select, setSelect] = useState('');
  const [searchData, setSearchData] = useState({});
  const [refreshing, setRefreshing] = useState(false);
  const [back, setBack] = useState(false);
  const [modalInfo, setModalInfo] = useState({ search: false });

  useEffect(() => {
    // console.log('modal select', select);
  }, [select]);

  useEffect(() => {
    if (dataType === 'lang') {
      setBack(false);
      setData(languages);
      // setMemLang(languages);
    } else if (dataType === 'species') {
      // setData(species);
    } else {
      setData(habitats);
    }
  }, [dataType]);

  useEffect(() => {
    if (dataType === 'lang') {
      setData(languages);
    } else if (dataType === 'species') {
      // setData(species);
    } else {
      setData(habitats);
    }
  }, [dataType]);

  useEffect(() => {
    if (dataType === 'lang' && !filter) {
      setData(languages);
      setBack(false);
    }
    // console.log("SET DATA -> " + data);
  }, [data, dataType, filter]);

  return (
    <HabitatPicker.Provider
      value={{
        dataType,
        setDataType,
        showModal,
        setShowModal,
        data,
        setData,
        filter,
        setFilter,
        searchData,
        setSearchData,
        refreshing,
        setRefreshing,
        back,
        setBack,
        modalInfo,
        setModalInfo,
        select,
        setSelect,
      }}
    >
      <Modal
        animationType="slide"
        // transparent={true}
        visible={showModal}
        onRequestClose={() => {
          // console.log('modal closed');
        }}
        onShow={() => {
          // console.log('modal opening');
        }}
        style={{
          backgroundColor: 'black',
          height: '100%',
          width: '100%',
          zIndex: 99,
        }}
      >
        <View
          style={{
            maxWidth: 1000,
            alignSelf: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              height: 48,
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 50,
            }}
          >
            <View
              style={{
                height: 48,
                width: '15%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {back ? (
                <TouchableOpacity
                  onPress={() => {
                    setData(habitats);
                    setBack(false);
                  }}
                >
                  <AntDesign name="back" size={24} color="black" />
                </TouchableOpacity>
              ) : (
                <TouchableOpacity onPress={() => setShowModal(false)}>
                  <EvilIcons name="close" size={24} color="black" />
                </TouchableOpacity>
              )}
            </View>
            <TextInput
              autoCorrect={false}
              style={{
                height: 48,
                width: '60%',
                display: !modalInfo.search ? 'none' : 'flex',
              }}
              placeholder="Search here..."
              onChangeText={(text) => {
                // console.log(text);
                setFilter(text);
                if (!text) {
                  // console.log(JSON.stringify(languages));
                  setData(languages);
                }
                setData(
                  languages.filter((item) =>
                    item.name
                      .toLowerCase()
                      .includes(text?.toLowerCase?.().trim()),
                  ),
                );
              }}
              // onChangeText={(text) => setFilter(text)}
            />
            <TouchableOpacity
              style={{
                height: 30,
                paddingHorizontal: '5%',
                borderRadius: 10,
                backgroundColor: 'lightgrey',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: 10,
                display: !modalInfo.search ? 'none' : 'flex',
              }}
              onPress={() => {
                if (!filter) {
                  setData(languages);
                }
                setData(data.filter((item) => item.name.includes(filter)));
              }}
              // onPress={() => (filter ? getData(filter) : getData(false))}
            >
              <EvilIcons name="search" size={24} color="black" />
            </TouchableOpacity>
          </View>
          <FlatList
            renderItem={({ item }) => {
              // console.log(item);
              return (
                <Item
                  data={item}
                  onSelect={setSelect}
                  setData={setData}
                  setBack={setBack}
                  setShowModal={setShowModal}
                  setModalInfo={setModalInfo}
                />
              );
            }}
            data={data}
            style={{ marginBottom: 75 }}
          />
          {dataType !== 'lang' ? (
            <View
              style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                height: 75,
                backgroundColor: 'white',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                paddingHorizontal: '5%',
              }}
            >
              <Image
                source={require('../assets/images/IUCN_Red_List.png')}
                style={{ height: 50, resizeMode: 'contain', width: 100 }}
              />
              <Text style={{ width: '50%' }}>
                Habitats Classification Scheme (Version 3.1)
              </Text>
            </View>
          ) : null}
        </View>
      </Modal>
      {props.children}
    </HabitatPicker.Provider>
  );
}

const Item = ({
  onSelect,
  data,
  setData,
  setBack,
  setShowModal,
  setModalInfo,
}) => {
  // console.log(data);
  return (
    <TouchableOpacity
      onPress={() => {
        if (data?.subClasses) {
          setData(data?.subClasses);
          setBack(true);
        } else {
          if (data.class) {
            onSelect(data.class);
            setShowModal(false);
            setModalInfo({ search: false });
          } else if (data.subClass) {
            onSelect(data.subClass);
            setShowModal(false);
            setModalInfo({ search: false });
          } else if (data.name) {
            onSelect(data.name);
            setShowModal(false);
            setData(languages);
            setBack(false);
            setModalInfo({ search: false });
          }
        }
      }}
      style={{
        padding: 25,
        borderBottomColor: 'rgba(0,0,0,0.09)',
        borderBottomWidth: 1,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <View style={{ width: '90%' }}>
          <Text>{data?.class || data?.subClass || data?.name}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};
