import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import CampaignPreview from '/components/CampaignPreview/CampaignPreview';
import Accordion from '../../../components/Accordion/Accordion';
import ACTION_ALERT from '/components/ImpactTab/elements/ActionAlerts';
import TruncatedList from '../../../components/TruncatedList/TruncatedList';
import SelectedApplicationCTA from '/components/ImpactTab/SelectedApplicationCTA';
import { KEY_GRAY, KEY_GREEN } from '/constants';
import {
  GetMyImpactApplicationsQuery,
  SkillRequestApplication,
  SkillRequestApplicationStatus,
} from '/generated/graphql';
import { DeepPartial } from '/types';

interface IMyImpactApplicationsProps {
  data:
    | Pick<
        GetMyImpactApplicationsQuery['getMyImpactApplications'],
        'submitted' | 'selected' | 'not_selected' | 'declined'
      >
    | undefined;
  expandAll: boolean;
  loading: boolean;
}

export default function MyImpactApplications({
  data,
  expandAll,
  loading,
}: IMyImpactApplicationsProps) {
  const { navigate } = useNavigation<any>();

  const [pendingAppsActionAlert, setPendingAppsActionAlert] = useState<
    keyof typeof ACTION_ALERT | undefined
  >();

  useEffect(() => {
    // Pending applications

    // For now we only have NewMessage alerts, but when we add more, we will
    // want to prioritze which alerts to show here
    if (
      data?.submitted.items.some((app) => {
        return !!getActionAlertForApplication(app);
      })
    ) {
      setPendingAppsActionAlert('NewMessage');
    } else {
      setPendingAppsActionAlert(undefined);
    }
  }, [data]);

  function getActionAlertForApplication(
    app: DeepPartial<SkillRequestApplication>,
  ) {
    if (app.has_unread_messages_from_organization) {
      return ACTION_ALERT.NewMessage;
    }
  }

  function viewApplicationDetails(applicationId: string) {
    // TODO: Eventually we will want to have a dedicated screen for supporter impact on a specific campaign
    navigate('ViewApplicationDetails', {
      applicationId,
    });
  }

  function shouldExpand(list: any[] | undefined) {
    return expandAll && (list?.length ?? 0) > 0;
  }

  return (
    <>
      <Accordion
        loading={loading}
        headerStyle={styles.accordionHeaderStyle}
        contentContainerStyle={styles.accordionContentContainerStyle}
        expanded={shouldExpand(data?.submitted.items)}
        title="Submitted"
        icon={<Ionicons name="checkmark-circle" color="#AAAAAA" size={30} />}
        badgeValue={data?.submitted.total ?? 0}
        subtitle={
          pendingAppsActionAlert && ACTION_ALERT[pendingAppsActionAlert].text
        }
        subtitleStyle={{
          color:
            pendingAppsActionAlert &&
            ACTION_ALERT[pendingAppsActionAlert].color,
        }}
      >
        {data?.submitted.items?.length ? (
          <TruncatedList
            style={{ width: '100%' }}
            onViewMore={() => {
              navigate('SupViewAllApplications', {
                status: SkillRequestApplicationStatus.Submitted,
                title: `Pending Applications (${data?.submitted.total ?? 0})`,
              });
            }}
            total={data.submitted.total}
            items={data?.submitted.items}
            renderItem={(app, i) => {
              const actionAlert = getActionAlertForApplication(app);

              return (
                <CampaignPreviewContainer>
                  <CampaignPreview
                    onPress={() => viewApplicationDetails(app.id)}
                    subtitle={
                      <>
                        Applied {dayjs(Number(app.created_at)).fromNow()}
                        {actionAlert ? (
                          <Text
                            style={[
                              styles.actionAlert,
                              {
                                color: actionAlert.color,
                              },
                            ]}
                          >
                            {`\n` + actionAlert.text}
                          </Text>
                        ) : null}
                      </>
                    }
                    showChevronArrow
                    key={i}
                    campaign={app.skill_request.campaign}
                    campaignPost={app.skill_request.campaign.original_post}
                  />
                </CampaignPreviewContainer>
              );
            }}
          />
        ) : (
          <View style={styles.emptyContainer}>
            <Text style={styles.emptyText}>
              You have no pending applications
            </Text>
          </View>
        )}
      </Accordion>
      <Accordion
        headerStyle={styles.accordionHeaderStyle}
        contentContainerStyle={styles.accordionContentContainerStyle}
        expanded={shouldExpand(data?.selected.items)}
        title="Selected"
        icon={<Ionicons name="checkmark-circle" color={KEY_GREEN} size={30} />}
        loading={loading}
        badgeValue={data?.selected.total ?? 0}
      >
        {data?.selected.items?.length ? (
          <TruncatedList
            style={{ width: '100%' }}
            onViewMore={() => {
              navigate('SupViewAllApplications', {
                status: SkillRequestApplicationStatus.SelectedUnconfirmed,
                title: `Selected (${data?.selected.total ?? 0})`,
              });
            }}
            total={data.selected.total}
            items={data?.selected.items}
            renderItem={(app, i) => (
              <View
                key={i}
                style={{
                  width: '100%',
                }}
              >
                {/* TODO: Action Alert should go here */}
                <CampaignPreviewContainer>
                  <CampaignPreview
                    onPress={() => viewApplicationDetails(app.id)}
                    showChevronArrow
                    key={i}
                    campaign={app.skill_request.campaign}
                    campaignPost={app.skill_request.campaign.original_post}
                    widget={
                      <SelectedApplicationCTA
                        applicationId={app.id ?? ''}
                        selectedAt={app.selected_at ?? ''}
                      />
                    }
                  />
                </CampaignPreviewContainer>
              </View>
            )}
          />
        ) : (
          <View style={styles.emptyContainer}>
            <Text style={styles.emptyText}>Nothing to see here</Text>
          </View>
        )}
      </Accordion>
      <Accordion
        loading={loading}
        headerStyle={styles.accordionHeaderStyle}
        contentContainerStyle={styles.accordionContentContainerStyle}
        expanded={shouldExpand(data?.not_selected?.items)}
        title="Not Selected"
        icon={<Ionicons name="checkmark-circle" color="#FF0202" size={30} />}
        badgeValue={data?.not_selected?.total ?? 0}
      >
        {data?.not_selected?.items?.length ? (
          <TruncatedList
            style={{ width: '100%' }}
            onViewMore={() => {
              navigate('SupViewAllApplications', {
                status: SkillRequestApplicationStatus.NotSelected,
                title: `Not Selected (${data?.not_selected?.total ?? 0})`,
              });
            }}
            total={data.not_selected.total}
            items={data?.not_selected?.items}
            renderItem={(app, i) => (
              <CampaignPreviewContainer>
                <CampaignPreview
                  onPress={() => viewApplicationDetails(app.id)}
                  showChevronArrow
                  key={i}
                  campaign={app.skill_request.campaign}
                  campaignPost={app.skill_request.campaign?.original_post}
                />
              </CampaignPreviewContainer>
            )}
          />
        ) : (
          <View style={styles.emptyContainer}>
            <Text style={styles.emptyText}>Nothing to see here</Text>
          </View>
        )}
      </Accordion>
      {/* <Accordion
        headerStyle={styles.accordionHeaderStyle}
        contentContainerStyle={styles.accordionContentContainerStyle}
        expanded={shouldExpand(closed)}
        title="Closed Campaigns"
        icon={<Ionicons name="checkmark-circle" color="#FFC502" size={30} />}
        badgeValue={closed.length}
      >
        {closed?.length ? (
          <TruncatedList
          style={{ width: '100%' }}
            onViewMore={() => {
              navigate('SupViewAllApplications', {
                status: SkillRequestApplicationStatus.Closed,
                title: `Closed Campaigns (${closed.length})`,
              });
            }}
            items={closed}
            renderItem={(app, i) => (
              <CampaignPreviewContainer>
                <CampaignPreview
                  onPress={() => viewApplicationDetails(app.id)}
                  showChevronArrow
                  key={i}
                  campaign={app.campaign}
                  campaignPost={app.campaign?.original_post}
                />
              </CampaignPreviewContainer>
            )}
          />
        ) : (
          <View style={styles.emptyContainer}>
            <Text style={styles.emptyText}>Nothing to see here</Text>
          </View>
        )}
      </Accordion> */}
      <Accordion
        loading={loading}
        headerStyle={styles.accordionHeaderStyle}
        contentContainerStyle={styles.accordionContentContainerStyle}
        expanded={shouldExpand(data?.declined.items)}
        title="Declined by Me"
        icon={<Ionicons name="checkmark-circle" color="#323338" size={30} />}
        badgeValue={data?.declined.total ?? 0}
      >
        {data?.declined.items?.length ? (
          <TruncatedList
            style={{ width: '100%' }}
            onViewMore={() => {
              navigate('SupViewAllApplications', {
                status: SkillRequestApplicationStatus.SelectedDeclined,
                title: `Selected & Declined (${data?.declined.total ?? 0})`,
              });
            }}
            total={data.declined.total}
            items={data?.declined.items}
            renderItem={(app, i) => (
              <CampaignPreviewContainer>
                <CampaignPreview
                  onPress={() => viewApplicationDetails(app.id)}
                  showChevronArrow
                  key={i}
                  campaign={app.skill_request.campaign}
                  campaignPost={app.skill_request.campaign?.original_post}
                />
              </CampaignPreviewContainer>
            )}
          />
        ) : (
          <View style={styles.emptyContainer}>
            <Text style={styles.emptyText}>Nothing to see here</Text>
          </View>
        )}
      </Accordion>
    </>
  );
}

const CampaignPreviewContainer = (props: React.PropsWithChildren<{}>) => (
  <View
    style={{
      width: '100%',
      borderRadius: 6,
      overflow: 'hidden',
      backgroundColor: 'white',
      marginVertical: 4,
      paddingRight: 6,
    }}
  >
    {props.children}
  </View>
);

const styles = StyleSheet.create({
  emptyContainer: {
    padding: 12,
    marginVertical: 6,
    width: '100%',
  },
  emptyText: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
  },
  accordionContentContainerStyle: {
    backgroundColor: 'transparent',
    paddingVertical: 4,
  },
  accordionHeaderStyle: {
    backgroundColor: 'white',
    paddingHorizontal: 16,
  },
  actionAlert: {
    fontFamily: 'Lato-Italic',
    fontSize: 17,
    marginLeft: 8,
  },
});
