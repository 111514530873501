import { UpdateResolver } from '@urql/exchange-graphcache';

const deleteMomentInNature: UpdateResolver = (result, args, cache) => {
  cache.invalidate({
    __typename: 'MomentInNature',
    id: args.postId as string,
  });
};

export default deleteMomentInNature;
