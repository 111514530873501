import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';

const deleteJobPostBookmark: UpdateResolver = (result, args, cache) => {
  const fragment = gql`
    fragment DeleteJobPostBookmark on JobPost {
      id
      is_bookmarked
    }
  `;

  cache.writeFragment(fragment, {
    id: args.id,
    is_bookmarked: false,
  });
};

export default deleteJobPostBookmark;
