import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';

const setDefaultGroupDiscussionCategory: UpdateResolver = (
  result,
  args,
  cache,
) => {
  const groupId = (result.setDefaultGroupDiscussionCategory as any)?.groupId;

  const allFields = cache.inspectFields('Query');

  const test = allFields.filter(
    (field) =>
      field.fieldName === 'listGroupDiscussionCategories' &&
      field.arguments?.groupId === groupId,
  );

  test.forEach((field) => {
    const key = cache.resolve('Query', field.fieldKey);
    const data = cache.resolve(key as string, 'items') as string[];

    data?.forEach((categoryKey) => {
      /** If this is the category we just made default, don't touch it */
      if (
        categoryKey.includes(
          (result.setDefaultGroupDiscussionCategory as any)?.id,
        )
      )
        return;

      const is_default = cache.resolve(categoryKey, 'is_default') as
        | boolean
        | undefined;

      if (is_default) {
        cache.writeFragment(
          gql`
            fragment SetDefaultGroupDiscussionCategory on GroupDiscussionCategory {
              id
              is_default
            }
          `,
          {
            id: categoryKey.split(':')[1],
            is_default: false,
          },
        );
      }
    });
  });
};

export default setDefaultGroupDiscussionCategory;
