import { UpdateResolver } from '@urql/exchange-graphcache';

const removePartnership: UpdateResolver = (result, args, cache) => {
  cache.invalidate({
    __typename: 'Partnership',
    id: args.id as string,
  });
  // TODO: Implement
  // const fragment = gql`
  //   fragment RemovePartnership on User {
  //     id
  //     partnerships
  //   }
  // `;
  // cache.updateQuery(
  //   {
  //     query: GetPartnershipsDocument,
  //     variables: args,
  //   },
  //   (data: any) => {
  //     const removedIndex = data.getPartnerships.items.findIndex(
  //       (partnership: any) => partnership.id === args.id
  //     );
  //     // If can't find the item we wanted to remove, do nothing
  //     if (removedIndex === -1) return data;
  //     const removedPartnership =
  //       data.getPartnerships?.items?.splice?.(removedIndex, 1);
  //     if (data.getPartnerships?.items?.total)
  //       data.getPartnerships.items.total -= 1;
  //     if (
  //       removedPartnership.status === PartnershipStatus.Pending &&
  //       data.getPartnerships?.pending
  //     )
  //       data.getPartnerships.pending -= 1;
  //   }
  // );
};

export default removePartnership;
