import { ViewportAwareView } from '@skele/components';
import { Video, VideoProps } from 'expo-av';
import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

interface Props extends VideoProps {
  innerRef?: (ref: Video) => void;
  containerStyle?: StyleProp<ViewStyle>;
  preTriggerRatio?: number;
  onViewportEnter?: () => void;
  onViewportLeave?: () => void;
}

export default function ViewportAwareVideo({
  onViewportEnter,
  onViewportLeave,
  preTriggerRatio,
  containerStyle,
  innerRef,
  ...videoProps
}: Props) {
  return (
    <ViewportAwareView
      style={containerStyle}
      preTriggerRatio={preTriggerRatio}
      onViewportEnter={onViewportEnter}
      onViewportLeave={onViewportLeave}
    >
      <Video {...videoProps} ref={innerRef} />
    </ViewportAwareView>
  );
}
