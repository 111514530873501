import { View, Text, Pressable, ViewStyle, Platform } from 'react-native';
import React, { useRef, useState } from 'react';
import { ITeamProviderTeam } from '/context/TeamProvider';
import {
  isOrganizationProfileComplete,
  isUnderPrivileged,
  truncateNumber,
} from '/util';
import { TeamMemberRole } from '/generated/graphql';
import useBinaryTimingAnimation from '../../../hooks/useBinaryTimingAnimation';
import Avatar from '/components/Avatar';
import { Badge } from '@rneui/base';
import { KEY_GREEN, KEY_YELLOW } from '/constants';
import Hoverable from '/components/Hoverable';
import { MaterialIcons } from '@expo/vector-icons';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';
import { StyleSheet } from 'react-native';

type TeamSelectorListItemProps = {
  team: ITeamProviderTeam;
  onPress: () => void;
  activeTeam: ITeamProviderTeam | undefined;
  requireRole: TeamMemberRole | undefined;
  requireOrganizationProfileComplete: boolean;
  showUnreadNotificationBadge: boolean;
  style: ViewStyle;
};

export default (props: TeamSelectorListItemProps) => {
  const { team } = props;

  const disableButton = props.activeTeam?.id === team.id;
  const underPrivileged =
    (props.requireRole &&
      isUnderPrivileged(props.requireRole, team.membership?.team_role)) ||
    (props.requireOrganizationProfileComplete &&
      !isOrganizationProfileComplete(team.user));

  const hideTooltipTimeoutRef = useRef<NodeJS.Timeout>();

  const describeUnderprivileged = () => {
    if (!underPrivileged) return null;

    if (
      props.requireRole &&
      isUnderPrivileged(props.requireRole, team.membership?.team_role)
    ) {
      return `You must be a '${props.requireRole}' or higher to perform this action for this team`;
    } else if (props.requireOrganizationProfileComplete) {
      return `This team's organization profile is not complete`;
    }
  };

  const temporarilyShowDisabledTooltip = () => {
    if (hideTooltipTimeoutRef.current)
      clearTimeout(hideTooltipTimeoutRef.current);

    setShowDisabledTooltip(true);

    hideTooltipTimeoutRef.current = setTimeout(() => {
      hideTooltipTimeoutRef.current = undefined;
      setShowDisabledTooltip(false);
    }, 8000);
  };

  const [showDisabledTooltip, setShowDisabledTooltip] = useState(false);
  const tooltipOpacity = useBinaryTimingAnimation({
    value: showDisabledTooltip,
  });
  const tooltipAnimatedStyle = useAnimatedStyle(() => ({
    opacity: tooltipOpacity.value,
  }));

  return (
    <Pressable
      testID="team-selector-button"
      pointerEvents={underPrivileged ? 'box-none' : 'auto'}
      onPress={() => !underPrivileged && props.onPress()}
      style={({ pressed }) => [
        styles.container,
        Platform.select({
          web: {
            cursor: underPrivileged ? ('default' as any) : 'pointer',
          } as any,
        }),
        {
          opacity: underPrivileged ? 0.6 : 1,
          backgroundColor: pressed || disableButton ? '#efefef' : 'transparent',
        },
        props.style,
      ]}
    >
      <Avatar
        containerStyle={{
          padding: 1,
          borderWidth: 2,
          borderColor:
            props.activeTeam?.id === team.id ? KEY_GREEN : 'transparent',
        }}
        source={{ uri: team.user.profile_image ?? '' }}
        size={50}
        rounded
      />
      <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
        <View>
          <Text numberOfLines={2} style={styles.buttonTitle}>
            {team.user.name}
          </Text>
          <Text style={styles.roleText}>
            {team.membership?.team_role ?? ''}
          </Text>
        </View>
        {!!team.user.unread_notifications &&
          !!props.showUnreadNotificationBadge && (
            <Badge
              badgeStyle={{
                marginLeft: 8,
                backgroundColor: KEY_YELLOW,
                height: 26,
                minWidth: 26,
                borderRadius: 12,
              }}
              textStyle={{
                color: 'black',
                fontFamily: 'Lato-Bold',
                padding: 2,
                paddingBottom: 3,
              }}
              value={truncateNumber(team.user.unread_notifications, 99)}
            />
          )}
      </View>
      {props.activeTeam?.id === team.id ? (
        <Text style={styles.selectedText}>SELECTED</Text>
      ) : underPrivileged ? (
        <View>
          <Hoverable
            onHoverIn={() => setShowDisabledTooltip(true)}
            onHoverOut={() => setShowDisabledTooltip(false)}
          >
            <MaterialIcons
              name="info-outline"
              size={17}
              color="gray"
              style={{
                padding: 8,
                flexDirection: 'row',
                ...Platform.select({
                  web: {
                    cursor: 'pointer',
                  },
                }),
              }}
              onPress={() => {
                if (showDisabledTooltip) setShowDisabledTooltip(false);
                else temporarilyShowDisabledTooltip();
              }}
            >
              <Animated.View
                style={[
                  tooltipAnimatedStyle,
                  {
                    alignSelf: 'center',
                    position: 'absolute',
                    left: 0,
                    transform: [{ translateX: -290 }, { translateY: -32 }],
                    height: 64,
                    justifyContent: 'center',
                    width: 272,
                    backgroundColor: 'white',
                    borderRadius: 6,
                    shadowRadius: 10,
                    shadowOpacity: 1,
                    elevation: 5,
                    shadowColor: 'gray',
                    padding: 10,
                    zIndex: 100,
                  },
                ]}
              >
                <Text
                  style={{
                    textAlign: 'center',
                    fontFamily: 'Lato-Bold',
                    fontSize: 15,
                  }}
                >
                  {describeUnderprivileged()}
                </Text>
              </Animated.View>
            </MaterialIcons>
          </Hoverable>
        </View>
      ) : null}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 12,
  },
  roleText: {
    textTransform: 'uppercase',
    fontFamily: 'Lato-Bold',
    marginLeft: 8,
    marginBottom: 1,
    fontSize: 15,
    color: 'black',
  },
  selectedText: {
    fontFamily: 'Lato-Bold',
    fontSize: 14,
  },
  buttonTitle: {
    fontFamily: 'LeagueSpartan-Bold',
    textTransform: 'uppercase',
    fontSize: 19,
    marginLeft: 8,
  },
});
