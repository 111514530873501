import { View } from 'react-native';
import styles from '../CampaignPost.style';
import BookmarkButton from './BookmarkButton';
import CommentsButton from './CommentsButton';
import LikeButtonComponent from '/components/LikeButtonComponent';
import ShareButton from '/components/Sharing/ShareButton';
import { useAuthContext } from '/context';
import {
  Campaign,
  CampaignPost,
  useAddLikeMutation,
  useRemoveLikeMutation,
} from '/generated/graphql';
import { createUniversalURL, shorten } from '/util';

export interface ICampaignPostInteractions
  extends Pick<CampaignPost['interactions'], 'id' | 'is_liked' | 'likes'> {
  comments: {
    total: number;
  };
}

interface ICampaignPost extends Pick<CampaignPost, 'id'> {
  interactions?: ICampaignPostInteractions | undefined | null;
}

interface ICampaignPostControlsProps {
  data: ICampaignPost | undefined | null;
  campaign: Pick<Campaign, 'id' | 'is_bookmarked' | 'name'> | undefined | null;
  hideCommentsButton: boolean;
  hideShareButton?: boolean;
}

const BUTTON_SIZE = 38;

export default function CampaignPostControls(
  props: ICampaignPostControlsProps,
) {
  const { userData } = useAuthContext();

  return (
    <View style={styles.campaignControls}>
      <View style={styles.campaignControlsRight}>
        {userData?.id ? (
          <BookmarkButton
            campaignId={props.campaign?.id}
            size={BUTTON_SIZE}
            isBookmarked={props.campaign?.is_bookmarked ?? false}
          />
        ) : null}

        {props.hideShareButton ? null : (
          <ShareButton
            style={{
              width: BUTTON_SIZE,
              height: BUTTON_SIZE,
            }}
            iconSize={26}
            url={createUniversalURL(`campaign?postId=${props.data?.id}`)}
            shareMessage={`Check out ${
              props.campaign?.name
                ? `"${shorten(props.campaign?.name, 42)}"`
                : 'this post'
            } on Key Conservation!`}
          />
        )}

        {props.hideCommentsButton ? null : (
          <CommentsButton
            size={BUTTON_SIZE}
            commentCount={props.data?.interactions?.comments?.total}
            interactionsId={props.data?.interactions?.id}
          />
        )}

        <LikeButton
          returnToOnLogIn={
            props.data?.id ? `/campaign?postId=${props.data.id}` : ''
          }
          interactionsId={props.data?.interactions?.id}
          likeCount={props.data?.interactions?.likes ?? 0}
          size={BUTTON_SIZE}
          isLiked={props.data?.interactions?.is_liked ?? false}
        />
      </View>
    </View>
  );
}

interface ILikeButtonProps {
  returnToOnLogIn: string;
  likeCount: number;
  isLiked: boolean;
  interactionsId: string | undefined;
  size?: number;
}

function LikeButton(props: ILikeButtonProps) {
  const [, addLike] = useAddLikeMutation();
  const [, removeLike] = useRemoveLikeMutation();

  function onPress() {
    if (!props.interactionsId) return;

    if (props.isLiked) {
      removeLike({
        interactionsId: props.interactionsId,
      });
    } else {
      addLike({
        interactionsId: props.interactionsId,
      });
    }
  }

  return (
    <LikeButtonComponent
      returnToOnLogIn={props.returnToOnLogIn}
      likeCount={props.likeCount}
      isLiked={props.isLiked}
      onPress={onPress}
      size={props.size}
    />
  );
}
