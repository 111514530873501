import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  half: {
    flex: 0.5,
  },
  single: {
    flex: 1,
  },
  double: {
    flex: 1,
    flexDirection: 'row',
  },
  verticalSpacer: {
    width: 4,
    height: '100%',
  },
  horizontalSpacer: {
    height: 4,
    width: '100%',
  },
  moreOverlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0,0,0,0.4)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  moreOverlayText: {
    opacity: 0.8,
    color: '#fff',
    fontFamily: 'Lato-Bold',
    fontSize: 32,
  },
});
