import _ from 'lodash';
import React from 'react';
import { Skeleton } from '@rneui/base';
import { View } from 'react-native';

const BG_COLOR = '#EAEAEA';

export default function CampaignPostSkeleton() {
  return (
    <View
      style={{
        flex: 1,
        padding: 12,
        height: 800,
      }}
    >
      <Skeleton
        width={'100%'}
        height={20}
        style={{
          marginBottom: 20,
          backgroundColor: BG_COLOR,
        }}
      />
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Skeleton
          circle
          width={75}
          height={75}
          style={{
            backgroundColor: BG_COLOR,
          }}
        />
        <View
          style={{
            marginLeft: 6,
          }}
        >
          <Skeleton
            width={280}
            height={24}
            style={{
              marginBottom: 10,
              backgroundColor: BG_COLOR,
            }}
          />
          <Skeleton
            width={200}
            height={16}
            style={{
              backgroundColor: BG_COLOR,
            }}
          />
        </View>
      </View>
      {_.times(4, (num) => num).map((num) => (
        <Skeleton
          key={`description-${num}`}
          width={'100%'}
          height={15}
          style={{
            marginTop: 10,
            backgroundColor: BG_COLOR,
          }}
        />
      ))}
      <Skeleton
        width={'50%'}
        height={15}
        style={{
          marginTop: 10,
          backgroundColor: BG_COLOR,
        }}
      />
      <Skeleton
        width={100}
        height={14}
        style={{
          marginTop: 16,
          backgroundColor: BG_COLOR,
        }}
      />
      <Skeleton
        width={'100%'}
        height={420}
        style={{
          marginVertical: 12,
          backgroundColor: BG_COLOR,
        }}
      />
    </View>
  );
}
