import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    padding: 6,
  },
  itemButton: {
    flex: 1,
    flexDirection: 'row',
    padding: 6,
  },
  itemButtonIcon: {
    width: 28,
    alignItems: 'center',
  },
  itemButtonTitle: {
    fontSize: 18,
    fontFamily: 'Lato-Bold',
  },
  itemButtonText: {
    fontSize: 15,
    fontFamily: 'Lato',
  },
  actionAlertText: {
    paddingVertical: 2,
    fontSize: 17,
    fontFamily: 'Lato-Italic',
  },
  chevronArrow: {
    flex: 1,
    alignItems: 'flex-end',
  },
});
