import { AntDesign, FontAwesome } from '@expo/vector-icons';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useState } from 'react';
import {
  ActivityIndicator,
  FlatList,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import Avatar from '/components/Avatar';
import Button from '/components/Button';
import TextInputWithIcon from '/components/TextInputWithIcon';
import withAuthRequired from '/components/withAuthRequired';
import { KEY_GRAY, KEY_GREEN, TEXT_INPUT, TITLE_FONT_SIZE } from '/constants';
import { useAuthContext } from '/context';
import {
  TeamMembershipStatus,
  UserRole,
  useSearchUsersWithMembershipQuery,
} from '/generated/graphql';
import { isCloseToBottom } from '/util';
import getCDNImageUri from '/util/getCDNImageUri';

interface Props {
  navigation: StackNavigationProp<any, any>;
  route: RouteProp<any>;
}

function FindTeamMemberScreen(props: Props) {
  const teamId = props.route.params?.teamId;

  const { userData } = useAuthContext();

  const [searchQuery, setSearchQuery] = useState('');
  const [nextToken, setNextToken] = useState('');

  const [{ data, fetching, error }, refetch] =
    useSearchUsersWithMembershipQuery({
      variables: {
        filter: {
          role: UserRole.Supporter,
          query: searchQuery,
        },
        teamId,
        nextToken,
      },
      pause: !searchQuery?.trim(),
    });
  async function onInviteUser(userId: string) {
    props.navigation.navigate('InviteTeamMember', {
      userId,
      teamId,
    });
  }

  function onScroll(evt: NativeSyntheticEvent<NativeScrollEvent>) {
    if (!data?.searchUsers.nextToken) return;

    if (isCloseToBottom(evt.nativeEvent)) {
      // If we are close to the bottom, and we have a next token, get more data
      setNextToken(data.searchUsers.nextToken);
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <Text style={styles.headerText}>INVITE TEAM MEMBER</Text>
        <Pressable
          onPress={() => props.navigation.goBack()}
          style={styles.closeButton}
        >
          <AntDesign name="close" size={28} />
        </Pressable>
      </View>
      <TextInputWithIcon
        value={searchQuery}
        autoCorrect={false}
        iconPosition="right"
        icon={
          <View style={{ justifyContent: 'center', flex: 1, right: 12 }}>
            <FontAwesome name="search" color={KEY_GRAY} size={18} />
          </View>
        }
        onChangeText={setSearchQuery}
        containerStyle={{
          marginHorizontal: 16,
        }}
        placeholder="Search users..."
        style={TEXT_INPUT}
      />
      <FlatList
        scrollEventThrottle={32}
        onScroll={onScroll}
        data={searchQuery ? data?.searchUsers.items : []}
        style={{ flex: 1 }}
        contentContainerStyle={{
          paddingBottom: 42,
          paddingHorizontal: 8,
        }}
        ListFooterComponent={
          data?.searchUsers.total && nextToken && fetching ? (
            <View
              style={{
                padding: 16,
                alignItems: 'center',
              }}
            >
              <ActivityIndicator size={24} color="black" />
            </View>
          ) : error ? (
            <View style={styles.errorContainer}>
              <Text style={styles.errorText}>
                We ran into a problem while fetching user profiles
              </Text>
              <Button
                label="Retry"
                onPress={refetch}
                containerStyle={{
                  marginTop: 8,
                }}
              />
            </View>
          ) : !data?.searchUsers.total || !searchQuery ? (
            <View style={styles.emptyContainer}>
              {fetching ? (
                <ActivityIndicator size={32} color="black" />
              ) : (
                <Text style={styles.emptyText}>
                  {!searchQuery
                    ? 'Start typing above to search for people'
                    : 'No results'}
                </Text>
              )}
            </View>
          ) : null
        }
        renderItem={({ item: user, index }) => {
          const { team_membership } = user;

          let buttonColor = '#eee';
          let buttonLabel = 'Select';
          let disabled = false;

          switch (team_membership?.membership_status) {
            case TeamMembershipStatus.Requested: {
              if (team_membership.initiator?.id !== userData?.id) {
                buttonLabel = 'Select';
              } else {
                buttonLabel = 'Invited';
                disabled = true;
              }
              break;
            }
            case TeamMembershipStatus.Confirmed: {
              buttonColor = KEY_GREEN;
              buttonLabel = 'Already Added';
              disabled = true;
              break;
            }
          }

          return (
            <View
              style={[
                styles.userCard,
                index !== data!.searchUsers.items.length - 1
                  ? styles.bottomBorderDivider
                  : undefined,
              ]}
            >
              <Avatar
                size={56}
                rounded
                source={{
                  uri: getCDNImageUri({
                    uri: user.profile_image,
                    dimensions: {
                      width: 64,
                      height: 64,
                    },
                  }),
                }}
              />
              <View style={styles.userCardRightContent}>
                <Text style={styles.userCardTitle} numberOfLines={2}>
                  {user.name}
                </Text>
                <Button
                  style={{
                    backgroundColor: buttonColor,
                  }}
                  disabled={disabled}
                  containerStyle={styles.requestButton}
                  onPress={() => onInviteUser(user.id)}
                  label={buttonLabel}
                />
              </View>
            </View>
          );
        }}
      />
    </View>
  );
}

export default withAuthRequired(FindTeamMemberScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    width: '100%',
    maxWidth: 800,
    alignSelf: 'center',
  },
  headerContainer: {
    padding: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerText: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: TITLE_FONT_SIZE,
  },
  closeButton: {
    padding: 8,
  },
  emptyContainer: {
    flex: 1,
    alignItems: 'center',
    paddingTop: 42,
  },
  emptyText: {
    fontFamily: 'Lato',
    color: KEY_GRAY,
  },
  userCard: {
    flex: 1,
    padding: 10,
    flexDirection: 'row',
  },
  userCardTitle: {
    fontFamily: 'Lato-Bold',
    fontSize: 17,
    paddingBottom: 6,
  },
  userCardRightContent: {
    flex: 1,
    padding: 8,
  },
  bottomBorderDivider: {
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
  },
  requestButton: {
    maxWidth: 300,
  },
  errorContainer: {
    padding: 12,
    alignItems: 'center',
    alignSelf: 'center',
  },
  errorText: {
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    color: 'crimson',
    textAlign: 'center',
  },
});
