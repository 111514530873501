import { StyleSheet } from 'react-native';
import { CARD_TITLE_FONT_SIZE, KEY_GRAY } from '/constants';

export default StyleSheet.create({
  listItemContainer: {
    width: 400,
    marginRight: 8,
    marginTop: 8,
    marginBottom: 8,
  },
  thumbnail: {
    borderRadius: 6,
    overflow: 'hidden',
    backgroundColor: 'gray',
    width: '100%',
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleText: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: CARD_TITLE_FONT_SIZE,
  },
  subtitleText: {
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    marginVertical: 2,
  },
  topLeftOverlay: {
    position: 'absolute',
    flexDirection: 'row',
    alignItems: 'center',
    top: 0,
    left: 0,
    padding: 12,
  },
  goodNewsIcon: {
    marginLeft: 4,
  },
  listItemFooter: {
    marginTop: 8,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  authorName: {
    textTransform: 'uppercase',
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 16,
  },
  locationText: {
    flex: 1,
    color: KEY_GRAY,
    fontFamily: 'Lato-Bold',
  },
  timestampText: {
    color: 'gray',
    fontFamily: 'Lato-Bold',
  },
  expandButton: {
    position: 'absolute',
    bottom: 8,
    right: 8,
    padding: 8,
    backgroundColor: 'rgba(230,230,230,0.75)',
    borderRadius: 7,
  },
  footerContainer: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 16,
  },
  loadingIndicator: {
    marginVertical: 16,
  },
  loadMoreButton: {
    marginVertical: 16,
  },
  timestamp: {
    fontFamily: 'Lato-Bold',
    fontSize: 14,
    color: 'gray',
    marginBottom: 2,
  },
  caption: {
    fontFamily: 'Lato',
    fontSize: 16,
    color: KEY_GRAY,
  },
});
