import { Platform, StyleSheet } from 'react-native';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  maxSize,
} from 'rn-responsive-styles';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      backgroundColor: '#eee',
      overflow: 'hidden',
      zIndex: 1,
    },
    nameContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    content: {
      ...StyleSheet.absoluteFillObject,
      backgroundColor: 'rgba(0, 0, 0, 0.68)',
    },
    locationContainer: {
      flexDirection: 'row',
    },
    locationText: {
      fontFamily: 'Lato-Bold',
      fontSize: 17,
      maxWidth: 280,
    },
    smallDeviceHeaderContent: {
      flexDirection: 'row',
      padding: 16,
      paddingTop: 24,
      alignItems: 'center',
    },
    smallDeviceHeaderHiddenContent: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      // Using `opacity` instead of `display` because we still want
      // this component to take up space
      opacity: 0,
    },
    largeDeviceHeaderContent: {
      ...StyleSheet.absoluteFillObject,
      padding: 24,
      alignItems: 'center',
      justifyContent: 'center',
    },
    smallDeviceHeaderName: {
      fontFamily: 'LeagueSpartan-Bold',
      color: 'black',
      fontSize: 21,
      textTransform: 'uppercase',
    },
    smallDeviceHeaderNameInput: {
      marginLeft: 20,
      fontSize: 21,
      color: 'black',
      fontFamily: 'LeagueSpartan-Bold',
      paddingTop: 2,
      textAlignVertical: 'center',
    },
    largeDeviceHeaderName: {
      color: 'white',
      fontFamily: 'LeagueSpartan-Bold',
      fontSize: 50,
      paddingVertical: 20,
      textAlign: 'center',
      textTransform: 'uppercase',
      // @ts-ignore
      ...(Platform.OS === 'web'
        ? {
            pointerEvents: 'box-none',
          }
        : {}),
    },
    largeDeviceHeaderNameInput: {
      paddingLeft: 64,
      color: 'white',
      textAlignVertical: 'center',
      fontFamily: 'LeagueSpartan-Bold',
      fontSize: 48,
      paddingTop: 4,
      textAlign: 'center',
    },
    locationName: {
      fontFamily: 'Lato-Bold',
      fontSize: 18,
    },
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      content: {
        backgroundColor: 'rgba(255, 255, 255, 0.68)',
        bottom: undefined,
      },
      smallDeviceHeaderHiddenContent: {
        opacity: 1,
      },
    },
  },
);

export default useStyles;
