// @ts-ignore
import SideMenu from '@mtourj/react-native-side-menu';
import { EventArg, RouteProp, useFocusEffect } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ActivityIndicator,
  Animated,
  Easing,
  Image,
  ImageURISource,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';
import ProgressBar, { ProgressBarType } from '../ProgressBar/ProgressBar';
import TeamSelector from '../TeamSelector/TeamSelector';
import withAuthRequired from '../withAuthRequired';
import styles from './CampaignBuilder.style';
import ArticleBuilder from './components/ArticleBuilder/ArticleBuilder';
import CampaignBigIssues from './components/CampaignBigIssues';
import CampaignConnect from './components/CampaignConnect';
import CampaignResearchTopics from './components/CampaignResearchTopics';
import DraftsMenu from './components/DraftsMenu';
import Preview from './components/Preview';
import SupportPicker from './components/SupportPicker';
import DescribeSkills from './SkillImpact/DescribeSkills';
import SelectSkillsContent from './SkillImpact/SelectSkillsContent';
import Alert from '/Alert';
import BackArrow from '/assets/jsicons/miscIcons/BackArrow';
import { getAnimalPatternURL } from '/assets/KeyAnimalPattern';
import { KEY_GRAY, OFFWHITE } from '/constants';
import { useAuthContext, useLoadingContext, useTeamContext } from '/context';
import {
  TeamMemberRole,
  useGetTotalCampaignConnectInvitesForUserQuery,
  UserRole,
} from '/generated/graphql';
import useCampaignBuilder, {
  CampaignBuilderFormState,
} from '/hooks/useCampaignBuilder';
import { DeepPartial } from '/types';

export type CampaignBuilderStep = (
  props: CampaignBuilderComponentProps,
) => JSX.Element;

export type CampaignBuilderComponentProps = {
  data?: CampaignBuilderFormState;
  isSaving: boolean;
  hasUnsavedChanges: boolean;
  onSaveCampaignDraft: () => Promise<void>;
  setData: (data: DeepPartial<CampaignBuilderFormState>) => void;
  /**
   * To be called whenever user navigates to the next step
   * @param addSteps Array of steps to add to the stack after the current step
   * @param addStepsShift How many steps after current step should addSteps be inserted? Default is 0
   */
  next: (
    addSteps?: (CampaignBuilderStep | CampaignBuilderStep[])[],
    addStepsShift?: number,
  ) => void;
  publish?: () => Promise<void>;
  exit: () => void;
};

interface ICampaignBuilderProps {
  navigation: StackNavigationProp<any>;
  route: RouteProp<any>;
}

const ANIMATION_DURATION = 200;

const CAMPAIGN_BUILDER_STEPS: CampaignBuilderStep[] = [
  SupportPicker,
  ArticleBuilder,
  CampaignResearchTopics,
  CampaignBigIssues,
  CampaignConnect,
  Preview,
] as const;

function CampaignBuilder(props: ICampaignBuilderProps) {
  const { width: window_width } = useWindowDimensions();

  const { userData, fetching: loadingUser } = useAuthContext();
  const {
    activeTeam,
    loading: loadingTeams,
    teams,
    setActiveTeam,
  } = useTeamContext();

  const [autosaveEnabled, setAutosaveEnabled] = useState(true);

  const {
    data,
    drafts,
    draftsError,
    hasUnsavedChanges,
    isDraftsLoading,
    isSaving,
    hasMoreDrafts,
    fetchDrafts,
    paginateDrafts,
    onDeleteDraft,
    onSelectDraft,
    publish,
    setData,
    startNewDraft,
  } = useCampaignBuilder({
    autosaveEnabled,
    campaignConnectInviteId: props.route.params?.campaignConnectInviteId,
    onAssignCampaignId: (id) => {
      props.navigation.setParams({ draftId: id });
    },
  });

  const [{ data: campaignConnectInvites }] =
    useGetTotalCampaignConnectInvitesForUserQuery({
      variables: {
        userId: activeTeam?.user?.id || userData?.id,
      },
      requestPolicy: 'network-only',
    });

  const { setShowLoading, setLoadingInfo }: any = useLoadingContext();

  const [steps, setSteps] = useState<CampaignBuilderStep[]>(
    CAMPAIGN_BUILDER_STEPS,
  );

  const [stepAddHistory, setStepAddHistory] = useState<{
    [adderIndex: string]: CampaignBuilderStep[];
  }>({});

  const [skipSteps, setSkipSteps] = useState<CampaignBuilderStep[]>([]);

  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const animation = useRef(new Animated.Value(1));

  /** For drafts */
  const [showDrafts, setShowDrafts] = useState(false); // Show DraftsMenu

  // This is for opening drafts from outside the campaign builder
  const queueLoadDraftId = useRef();

  const scheduleExitAfterSave = useRef<
    Readonly<{
      type: string;
      payload?: object | undefined;
      source?: string | undefined;
      target?: string | undefined;
    }>
  >();

  const ready =
    !isDraftsLoading && !draftsError && !loadingUser && !loadingTeams;

  const onStartNewDraft = useCallback(
    function () {
      setShowDrafts(false);
      props.navigation.setParams({ draftId: undefined });
      resetState();
      startNewDraft();
    },
    [props.navigation, startNewDraft],
  );

  useFocusEffect(
    useCallback(() => {
      // When focusing in, make sure autosave is enabled
      setAutosaveEnabled(true);

      if (!props.route.params?.draftId) onStartNewDraft();
    }, [onStartNewDraft, props.route.params?.draftId]),
  );

  const hasSetInitialActiveTeamId = useRef(false);
  useEffect(() => {
    if (!props.route.params?.activeTeamId) return;
    if (hasSetInitialActiveTeamId.current) return;

    if (props.route.params.activeTeamId !== activeTeam?.id) {
      hasSetInitialActiveTeamId.current = true;
      setActiveTeam(props.route.params.activeTeamId);
    }
  }, [activeTeam?.id, props.route.params?.activeTeamId, setActiveTeam]);

  /** Handle opening draft based on ID passed in navigation params */
  useEffect(() => {
    const draftId = props.route.params?.draftId;

    if (draftId && data.campaign.id !== draftId) {
      if (ready) onSelectDraft(draftId);
      else {
        setShowLoading(true);
        setLoadingInfo('Loading draft...');

        queueLoadDraftId.current = draftId;

        return () => {
          setShowLoading(false);
          setLoadingInfo('');
          queueLoadDraftId.current = undefined;
        };
      }
    }
  }, [
    data.campaign.id,
    onSelectDraft,
    props.route.params?.draftId,
    ready,
    setLoadingInfo,
    setShowLoading,
  ]);

  useEffect(() => {
    if (currentStepIndex === steps.length - 1) {
      // If we are on the last step, disable autosave to prevent issues happening when user clicks "publish"
      if (autosaveEnabled) setAutosaveEnabled(false);
    } else if (currentStepIndex !== steps.length - 1 && !autosaveEnabled) {
      setAutosaveEnabled(true);
    }
  }, [steps, currentStepIndex, autosaveEnabled]);

  useEffect(() => {
    resetState();
  }, [activeTeam?.user.id]);

  useEffect(
    function determineStepsToSkip() {
      let _skipSteps = [];

      if (!campaignConnectInvites?.listCampaignConnectInvitesForUser.total)
        _skipSteps.push(CampaignConnect);

      setSkipSteps(_skipSteps);
    },
    [campaignConnectInvites?.listCampaignConnectInvitesForUser.total],
  );

  useEffect(() => {
    /** If we just got done saving and we've scheduled to exit CampaignBuilder, do it now */
    if (!isSaving && scheduleExitAfterSave.current) {
      setShowLoading(false);

      /** Only navigate away if save was successful */
      if (!hasUnsavedChanges)
        props.navigation.dispatch(scheduleExitAfterSave.current);

      scheduleExitAfterSave.current = undefined;
    }
  }, [hasUnsavedChanges, isSaving, props.navigation, setShowLoading]);

  const onSaveCampaignDraft = useCallback(
    async function () {
      return publish(true);
    },
    [publish],
  );

  useEffect(() => {
    if (ready && queueLoadDraftId.current) {
      onSelectDraft(queueLoadDraftId.current);
      queueLoadDraftId.current = undefined;

      setShowLoading(false);
      setLoadingInfo('');
    }
  }, [onSelectDraft, ready, setLoadingInfo, setShowLoading]);

  /** Side effect that handles exiting CampaignBuilder and prompts the user to save
   * if unsaved changes are present
   */
  useEffect(() => {
    const handleExitCampaignBuilder = (
      e: EventArg<
        'beforeRemove',
        true,
        {
          action: Readonly<{
            type: string;
            payload?: object | undefined;
            source?: string | undefined;
            target?: string | undefined;
          }>;
        }
      >,
    ) => {
      if (isSaving) {
        e.preventDefault();
        // If we are currently saving, wait for saving to finish then exit
        scheduleExitAfterSave.current = e.data.action;
        setShowLoading(true);
        setLoadingInfo('Saving...');
        return;
      }

      if (!hasUnsavedChanges) {
        // If we don't have unsaved changes, then we don't need to do anything
        return;
      }

      // Prevent default behavior of leaving the screen
      e.preventDefault();

      // Prevent autosave from happening while we are awaiting user input
      // This prevents duplicate drafts from being created in the case that autosave begins
      // while this prompt is visible and the user selects 'save'.
      setAutosaveEnabled(false);

      // Prompt the user before leaving the screen
      Alert.alert(
        'Discard changes?',
        'You have unsaved changes. If you choose to exit without saving, they will be lost.',
        [
          {
            text: 'Save changes',
            style: 'default',
            onPress: async () => {
              setShowLoading(true);
              setLoadingInfo('Saving...');

              try {
                await onSaveCampaignDraft();

                // Resume navigation action
                props.navigation.dispatch(e.data.action);
              } catch (err) {
                Alert.alert(
                  'Error',
                  'We ran into a problem while trying to save your draft. Please try again or report this issue if it persists',
                );
                console.log(err);
              }

              // Restore autosave
              setAutosaveEnabled(true);

              setShowLoading(false);
              setLoadingInfo('');
            },
          },
          {
            text: "Don't save",
            style: 'default',
            onPress: () => props.navigation.dispatch(e.data.action),
          },
          {
            text: 'Cancel',
            style: 'cancel',
            onPress: () => {
              // Restore autosave
              setAutosaveEnabled(true);
            },
          },
        ],
      );
    };
    props.navigation.addListener('beforeRemove', handleExitCampaignBuilder);

    return () => {
      props.navigation.removeListener(
        'beforeRemove',
        handleExitCampaignBuilder,
      );
    };
  }, [
    props.navigation,
    hasUnsavedChanges,
    isSaving,
    setShowLoading,
    setLoadingInfo,
    onSaveCampaignDraft,
  ]);

  const previousStepIndex = useMemo(() => {
    let prevIndex = Math.max(currentStepIndex - 1, 0);

    while (prevIndex > 0 && skipSteps.includes(steps[prevIndex])) {
      prevIndex--;
    }

    return prevIndex;
  }, [steps, currentStepIndex, skipSteps]);

  const animateNext = () => {
    animation.current.setValue(0);

    Animated.timing(animation.current, {
      toValue: 1,
      useNativeDriver: true,
      duration: ANIMATION_DURATION,
    }).start();
  };

  const animatePrevious = () => {
    Animated.timing(animation.current, {
      toValue: 0,
      useNativeDriver: true,
      duration: ANIMATION_DURATION,
    }).start(() => {
      animation.current.setValue(1);

      if (stepAddHistory[previousStepIndex]?.length) {
        // Remove any steps previously added by this screen,
        // as going to the next screen will re-add them,
        // and depending on user input, this screen may
        // add a different set of screens entirely
        setSteps(
          steps.filter(
            (step) => !stepAddHistory[previousStepIndex].includes(step),
          ),
        );

        // Remove from history
        setStepAddHistory({
          ...stepAddHistory,
          [previousStepIndex]: null,
        });
      }

      setCurrentStepIndex(previousStepIndex);
    });
  };

  const previous = () => {
    if (currentStepIndex > 0) {
      animatePrevious();
    }
  };

  const exit = useCallback(
    function () {
      if (props.navigation.canGoBack()) {
        props.navigation.goBack();
      } else props.navigation.navigate('main');

      resetState();
    },
    [props.navigation],
  );

  const onPublishCampaign = useCallback(
    async function () {
      if (isSaving) return;

      setShowLoading(true);
      setLoadingInfo('Publishing Campaign...');

      try {
        await publish(false, (campaignId) => {
          props.navigation.navigate('Campaign', {
            campaignId,
            source: 'builder',
          });
        });
      } catch (err) {
        console.log(err);
        Alert.alert(
          'Error',
          'We ran into a problem while publishing your campaign. Please try again at a later time.',
        );
      }

      setShowLoading(false);
      setLoadingInfo('');
    },
    [isSaving, publish, setLoadingInfo, setShowLoading, props.navigation],
  );

  const next = useCallback(
    (
      _addSteps?: (CampaignBuilderStep | CampaignBuilderStep[])[],
      addStepsShift = 0,
    ) => {
      let insertSteps = _addSteps ? _addSteps.flat() : [];

      const newSteps = [
        ...steps.slice(0, currentStepIndex + 1 + addStepsShift),
        ...insertSteps,
        ...steps.slice(currentStepIndex + 1 + addStepsShift),
      ];

      // Add steps in between now and the current next step
      if (insertSteps?.length) {
        // Splice em together
        setSteps(newSteps);

        // Remember what we did in case we come back and need to remove the steps
        setStepAddHistory({
          ...stepAddHistory,
          [currentStepIndex]: insertSteps,
        });
      }

      const totalSteps = newSteps.length;

      let nextStepIndex = currentStepIndex + 1;

      while (
        nextStepIndex < totalSteps &&
        skipSteps.includes(newSteps[nextStepIndex])
      ) {
        nextStepIndex++;
      }

      if (nextStepIndex < totalSteps) {
        setCurrentStepIndex(nextStepIndex);
        animateNext();
      } else {
        // No further steps, next() triggers a publish
        onPublishCampaign();
      }
    },
    [steps, currentStepIndex, skipSteps, stepAddHistory, onPublishCampaign],
  );

  function resetState() {
    setCurrentStepIndex(0);
    setSteps(CAMPAIGN_BUILDER_STEPS);
    setStepAddHistory({});
  }

  function onDraftSelected(draftId: string) {
    resetState();
    props.navigation.setParams({ draftId });
    onSelectDraft(draftId);
    setShowDrafts(false);
  }

  function onDraftDeleted(draftId: string) {
    onDeleteDraft(draftId);
    if (data.campaign.id === draftId) {
      resetState();
    }
  }

  function onGoBack() {
    if (currentStepIndex === 0) {
      exit();
    } else {
      previous();
    }
  }

  const createHeaderBackground = useCallback((source: ImageURISource) => {
    return (
      <Image
        source={source}
        style={[
          StyleSheet.absoluteFill,
          { width: '100%', zIndex: -1, overflow: 'visible', bottom: -200 },
        ]}
        resizeMode="cover"
      />
    );
  }, []);

  const animatedHeaderBackground = useMemo(() => {
    const step = steps[currentStepIndex];

    switch (step) {
      case SupportPicker: {
        return createHeaderBackground(
          require('/assets/images/greatgreen_SD.jpg'),
        );
      }
      case ArticleBuilder: {
        return createHeaderBackground(require('/assets/images/whaleshark.png'));
      }
      case SelectSkillsContent: {
        return createHeaderBackground({ uri: getAnimalPatternURL('tortoise') });
      }
      case DescribeSkills: {
        return createHeaderBackground(require('/assets/images/pangolin.png'));
      }
      case Preview: {
        return createHeaderBackground(require('/assets/images/bongo90.jpg'));
      }
      default:
        return null;
    }
  }, [steps, currentStepIndex, createHeaderBackground]);

  const animatedHeaderTitle = useMemo(() => {
    const step = steps[currentStepIndex];

    switch (step) {
      case SupportPicker: {
        return 'WHAT KIND OF SUPPORT DO YOU NEED?';
      }
      case ArticleBuilder: {
        return 'SHARE WHAT IS GOING ON';
      }
      case SelectSkillsContent: {
        return 'WHAT SKILLS DO YOU NEED?';
      }
      case DescribeSkills: {
        return 'SHARE MORE INFORMATION';
      }
      case Preview: {
        return 'CAMPAIGN PREVIEW';
      }
      default:
        return null;
    }
  }, [steps, currentStepIndex]);

  const CurrentStep = steps[currentStepIndex];
  const PrevStep = steps[previousStepIndex];

  const currentStepTranslateX = animation.current.interpolate({
    inputRange: [0, 1],
    outputRange: [window_width, 0],
  });

  const prevStepTranslateX = animation.current.interpolate({
    inputRange: [0, 1],
    outputRange: [0, -window_width],
  });

  return loadingUser ||
    (userData?.role === UserRole.Supporter &&
      loadingTeams &&
      !teams?.length) ? (
    <View style={styles.loadingContainer}>
      <ActivityIndicator
        style={{
          marginTop: 8,
          marginBottom: 4,
        }}
        color={KEY_GRAY}
        size={18}
      />
      <Text style={styles.loadingText}>Loading...</Text>
    </View>
  ) : (
    <View style={styles.container}>
      <SideMenu
        onChange={(isOpen: boolean) => setShowDrafts(isOpen)}
        menuPosition="right"
        isOpen={showDrafts}
        menu={
          <DraftsMenu
            loading={isDraftsLoading}
            error={draftsError}
            currentCampaignId={data?.campaign.id}
            drafts={drafts}
            onCreateNewDraft={onStartNewDraft}
            onSelectDraft={onDraftSelected}
            hasMoreDrafts={hasMoreDrafts}
            onDeleteDraft={onDraftDeleted}
            onRetryFetchDrafts={fetchDrafts}
            onPaginateDrafts={paginateDrafts}
          />
        }
      >
        <SafeAreaView style={{ backgroundColor: OFFWHITE, flex: 1 }}>
          {/* CAMPAIGN BUILDER HEADER */}
          <View
            style={{
              overflow: 'visible',
              marginRight: Platform.OS === 'web' ? 8 : 0,
              backgroundColor: 'white',
            }}
          >
            <CampaignBuilderHeader
              isSaving={isSaving}
              hasChanged={hasUnsavedChanges}
              navigation={props.navigation}
              progress={Math.ceil(
                (steps
                  .filter((step) => !skipSteps.includes(step))
                  .findIndex((s) => s === steps[currentStepIndex]) /
                  (steps.length - skipSteps.length - 1)) *
                  100,
              )}
              onShowDrafts={() => {
                setShowDrafts(!showDrafts);
              }}
              onGoBack={onGoBack}
              invertFontColor={!!animatedHeaderBackground}
            />
            <View>
              {animatedHeaderTitle && (
                <Text
                  style={{
                    position: 'absolute',
                    fontFamily: 'LeagueSpartan-Bold',
                    lineHeight: 25,
                    fontSize: 30,
                    alignSelf: 'center',
                    textAlign: 'center',
                    padding: 16,
                    width: 300,
                    height: 120,
                    color: 'white',
                  }}
                >
                  {animatedHeaderTitle}
                </Text>
              )}
            </View>

            {/* Animated Header Background */}
            {animatedHeaderBackground}
          </View>

          {/* MAIN CONTENT */}
          <View style={{ flex: 1 }}>
            <Animated.View
              style={[
                {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  overflow: 'hidden',
                  transform: [{ translateX: currentStepTranslateX }],
                },
              ]}
            >
              <CurrentStep
                exit={exit}
                hasUnsavedChanges={hasUnsavedChanges}
                isSaving={isSaving}
                onSaveCampaignDraft={onSaveCampaignDraft}
                setData={setData}
                publish={onPublishCampaign}
                next={next}
                data={data}
              />
            </Animated.View>
            {PrevStep ? (
              <Animated.View
                style={[
                  {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    transform: [{ translateX: prevStepTranslateX }],
                  },
                ]}
              >
                <PrevStep
                  exit={exit}
                  hasUnsavedChanges={hasUnsavedChanges}
                  isSaving={isSaving}
                  onSaveCampaignDraft={onSaveCampaignDraft}
                  setData={setData}
                  publish={onPublishCampaign}
                  next={next}
                  data={data}
                />
              </Animated.View>
            ) : null}
          </View>
        </SafeAreaView>
      </SideMenu>
    </View>
  );
}

export default withAuthRequired(CampaignBuilder);

const CampaignBuilderHeader = ({
  showProgress = true,
  progress,
  onGoBack,
  onShowDrafts,
  isSaving,
  hasChanged,
  invertFontColor,
  navigation,
}: {
  progress: number;
  showProgress?: boolean;
  onGoBack: () => void;
  onShowDrafts?: () => void;
  isSaving?: boolean;
  hasChanged?: boolean;
  invertFontColor: boolean;
  navigation: StackNavigationProp<any>;
}) => {
  const { userData } = useAuthContext();

  const unsavedChangesIndicatorOpacity = useRef(new Animated.Value(0));

  useEffect(() => {
    Animated.timing(unsavedChangesIndicatorOpacity.current, {
      toValue: hasChanged ? 1 : 0,
      duration: 120,
      easing: Easing.out(Easing.poly(4)),
      useNativeDriver: true,
    }).start();
  }, [hasChanged]);

  const color = invertFontColor ? 'white' : 'black';

  return (
    <Animated.View
      style={{
        padding: 8,
      }}
    >
      {userData?.role === UserRole.Supporter ? (
        <TeamSelector
          buttonTextColor={color}
          onSelectTeam={() => {
            navigation.setParams({
              draftId: null,
            });
          }}
          requireOrganizationProfileComplete
          requireRole={TeamMemberRole.Creator}
        />
      ) : null}
      <View
        style={{
          paddingTop: 8,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          zIndex: 9,
        }}
      >
        <View
          style={{
            padding: 8,
            left: 0,
            width: 64,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              onGoBack?.();
            }}
          >
            <BackArrow fill={color} width={40} height={40} />
          </TouchableOpacity>
        </View>
        {showProgress !== false && typeof progress === 'number' && (
          <View
            style={{
              flex: 1,
              maxWidth: '75%',
              paddingHorizontal: 16,
            }}
          >
            <ProgressBar
              progress={progress}
              progressType={ProgressBarType.Percentage}
              goal={100}
            />
          </View>
        )}
        <View
          style={{
            padding: 8,
            left: 0,
            width: 64,
            alignItems: 'center',
          }}
        >
          <TouchableOpacity
            onPress={() => {
              onShowDrafts?.();
            }}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Animated.View
              style={{
                backgroundColor: 'gold',
                width: 8,
                height: 8,
                borderRadius: 4,
                marginRight: 2,
                opacity: unsavedChangesIndicatorOpacity.current,
              }}
            />
            <Text
              numberOfLines={1}
              style={{
                fontFamily: 'Lato-Bold',
                fontSize: 16,
                color: color,
                textAlign: 'center',
              }}
            >
              {isSaving ? <ActivityIndicator size="small" /> : 'Drafts'}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </Animated.View>
  );
};
