import { View, Text, Pressable } from 'react-native';
import React, { useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import styles from './MyImpactWidget.style';
import {
  Campaign,
  Skill,
  SkillRequest,
  SkillRequestApplication,
  VolunteerRegistration,
  VolunteerRequest,
} from '/generated/graphql';
import Sync from '/assets/jsicons/bottomnavigation/Sync';
import SkillButton from '/components/SkilledImpact/SkillButton';
import dayjs from 'dayjs';
import ChevronRight from '/assets/jsicons/miscIcons/ChevronRight';
import ACTION_ALERTS from '../elements/ActionAlerts';
import { FontAwesome5, Ionicons } from '@expo/vector-icons';

interface ISkillRequest extends Pick<SkillRequest, 'id' | 'skill' | 'closed'> {
  my_application?:
    | Pick<
        SkillRequestApplication,
        'id' | 'created_at' | 'has_unread_messages_from_organization'
      >
    | undefined
    | null;
}

interface IVolunteerRequest
  extends Pick<
    VolunteerRequest,
    'id' | 'date' | 'latitude' | 'longitude' | 'location'
  > {
  my_registration?:
    | Pick<VolunteerRegistration, 'volunteerRequestId' | 'created_at'>
    | undefined
    | null;
}

interface ICampaign {
  skilled_impact_requests?: ISkillRequest[] | undefined | null;
  volunteer_request?: IVolunteerRequest | undefined | null;
}

interface Props {
  data: ICampaign;
}

export default function MyImpactWidget(props: Props) {
  const skillRequests = props.data.skilled_impact_requests;
  const volunteerRequest = props.data.volunteer_request;

  const { navigate } = useNavigation<StackNavigationProp<any>>();

  function viewApplicationDetails(applicationId: string) {
    navigate('ViewApplicationDetails', {
      applicationId,
    });
  }

  function viewVolunteerRequest(requestId: string) {
    navigate('VolunteerScreen', {
      requestId,
    });
  }

  return (
    <View style={styles.container}>
      {skillRequests?.map((request) => {
        if (!request.my_application) return null;

        /** Find out if there should be any "action alert"s */
        let actionAlerts = [];

        if (request.my_application?.has_unread_messages_from_organization) {
          actionAlerts.push(ACTION_ALERTS.NewMessage);
        }

        /** Get the highest priority alert */
        const actionAlert = actionAlerts.length
          ? actionAlerts.reduce((prev, curr) => {
              if (!prev || curr.priority < prev.priority) {
                return curr;
              } else return prev;
            })
          : null;

        return (
          <Pressable
            onPress={() =>
              request.my_application?.id &&
              viewApplicationDetails(request.my_application.id)
            }
            style={({ pressed }) => [
              styles.itemButton,
              {
                backgroundColor: pressed ? 'lightgray' : 'white',
              },
            ]}
            key={request.id}
          >
            <View style={styles.itemButtonIcon}>
              <Sync width={25} height={25} />
            </View>
            <View
              style={{
                paddingHorizontal: 8,
              }}
            >
              <Text style={styles.itemButtonTitle}>Skilled Impact</Text>
              {actionAlert && (
                <Text
                  style={[
                    styles.actionAlertText,
                    {
                      color: actionAlert.color,
                    },
                  ]}
                >
                  {actionAlert.text}
                </Text>
              )}
              <SkillButton
                style={{
                  alignSelf: 'flex-start',
                  marginLeft: 0,
                }}
                selected
                skill={request.skill}
                disabled
              />
              <Text style={styles.itemButtonText}>
                You applied to help with this skill{' '}
                {dayjs(Number(request.my_application.created_at)).fromNow()}
              </Text>
            </View>
            <View style={styles.chevronArrow}>
              <ChevronRight />
            </View>
          </Pressable>
        );
      })}
      {!!volunteerRequest?.my_registration && (
        <Pressable
          style={({ pressed }) => [
            styles.itemButton,
            {
              backgroundColor: pressed ? 'lightgray' : 'white',
            },
          ]}
          onPress={() => viewVolunteerRequest(volunteerRequest.id)}
        >
          <View style={styles.itemButtonIcon}>
            <Ionicons name="earth" size={25} />
          </View>
          <View
            style={{
              paddingHorizontal: 8,
            }}
          >
            <Text style={styles.itemButtonTitle}>In-Person Volunteering</Text>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingVertical: 6,
              }}
            >
              <FontAwesome5
                name="clock"
                size={16}
                style={{
                  marginRight: 4,
                }}
                color="black"
              />
              <Text style={styles.itemButtonText}>
                {dayjs(volunteerRequest.date).format('LLL')}
              </Text>
            </View>
            <Text style={styles.itemButtonText}>
              You registered to volunteer{' '}
              {dayjs(volunteerRequest.my_registration.created_at).fromNow()}
            </Text>
          </View>
          <View style={styles.chevronArrow}>
            <ChevronRight />
          </View>
        </Pressable>
      )}
    </View>
  );
}
