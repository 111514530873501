import { FontAwesome } from '@expo/vector-icons';
import React from 'react';
import { StyleSheet } from 'react-native';
import { View } from 'react-native';
import PlusSign from '/assets/jsicons/headerIcons/plusSign';
import { KEY_GRAY, KEY_GREEN } from '/constants';

interface Props {
  size?: number;
}

export default function AddSpecies(props: Props) {
  return (
    <View
      style={{
        width: props.size ?? 80,
        height: props.size ?? 80,
        borderRadius: props.size ?? 80,
        borderWidth: 1,
        borderColor: KEY_GRAY,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <View
        style={{
          ...StyleSheet.absoluteFillObject,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <FontAwesome
          name="paw"
          size={40}
          color="black"
          style={{ marginRight: 4 }}
        />
      </View>
      <View
        style={{
          position: 'absolute',
          backgroundColor: KEY_GREEN,
          width: 20,
          height: 20,
          transform: [
            {
              translateX: 16,
            },
            { translateY: 16 },
          ],
          borderRadius: 24,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PlusSign width={16} height={16} fill={'#000'} />
      </View>
    </View>
  );
}
