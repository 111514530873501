import { View, Text, FlatList } from 'react-native';
import React, { useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { Buffer } from 'buffer';
import { useAuthContext, useTeamContext } from '/context';
import {
  ActionableNotificationTypeGroup,
  useGetNotificationsForUserQuery,
} from '/generated/graphql';
import Avatar from '/components/Avatar';
import { StyleSheet } from 'react-native';
import withAuthRequired from '/components/withAuthRequired';
import ListLoading from '/components/ListLoading';
import GenericError from '/components/common/Generic/GenericError';
import { KEY_GRAY } from '/constants';
import Notification from '/components/Notification';
import GenericListFooter from '/components/GenericListFooter';

type Props = {
  route: RouteProp<any>;
};

function tryDecodeBase64(base64String: string): string | null {
  try {
    return Buffer.from(base64String, 'base64').toString('utf-8');
  } catch (error) {
    console.error('Failed to decode base64 string:', error);
    return null; // or return a default value or handle the error as needed
  }
}

function ListActionableNotifications(props: Props) {
  const { userData } = useAuthContext();
  const { teams } = useTeamContext();

  const actionableGroup = tryDecodeBase64(props.route.params?.group) || '';

  const userId = props.route.params?.userId || userData?.id;

  const [cursor, setCursor] = useState<string>();

  const [{ data, fetching, error }, getNotifications] =
    useGetNotificationsForUserQuery({
      variables: {
        userId,
        filterByActionableGroup:
          actionableGroup as ActionableNotificationTypeGroup,
        limitActionable: 20,
        cursor,
      },
    });

  const team = teams.find((t) => t.user.id === userId);

  const actionableSection = data?.getNotificationsForUser?.actionable[0];

  return !data && fetching ? (
    <ListLoading />
  ) : !data && error ? (
    <GenericError
      message="Failed to load notifications"
      onRetry={getNotifications}
    />
  ) : !actionableSection ? (
    <Text
      style={{
        marginVertical: 16,
        padding: 26,
        color: KEY_GRAY,
        fontFamily: 'Lato-Bold',
        width: '100%',
        textAlign: 'center',
      }}
    >
      No notifications
    </Text>
  ) : (
    <>
      {userId !== userData?.id && !!team ? (
        <View style={styles.teamIndicatorContainer}>
          <Avatar
            rounded
            size={32}
            source={{ uri: team.user.profile_image! }}
          />
          <Text style={styles.teamIndicatorText}>
            Viewing notifications for{' '}
            <Text style={styles.teamIndicatorTextBold}>{team?.user.name}</Text>
          </Text>
        </View>
      ) : null}
      <FlatList
        data={actionableSection.notifications}
        ListHeaderComponent={
          <Text style={styles.sectionTitle}>{actionableSection?.title}</Text>
        }
        ListFooterComponent={
          <GenericListFooter
            emptyHeight={0}
            hasMore={actionableSection.hasMore}
            isFirstPage={!cursor}
            loading={fetching}
            onFetchMore={() => {
              const oldestNotification =
                actionableSection.notifications[
                  actionableSection.notifications.length - 1
                ];
              setCursor(oldestNotification?.created_at);
            }}
          />
        }
        keyExtractor={(n) => n.id}
        contentContainerStyle={styles.sectionContainer}
        renderItem={({ item }) =>
          item ? (
            <Notification notification={item} viewingAsUserId={userId} />
          ) : null
        }
      />
    </>
  );
}

export default withAuthRequired(ListActionableNotifications);

const styles = StyleSheet.create({
  teamIndicatorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
  },
  teamIndicatorText: {
    fontFamily: 'Lato',
    fontSize: 16,
    marginHorizontal: 8,
  },
  teamIndicatorTextBold: {
    fontFamily: 'Lato-Bold',
  },
  sectionTitle: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 18,
    marginLeft: 4,
    alignItems: 'center',
    textTransform: 'uppercase',
    paddingHorizontal: 8,
    paddingTop: 12,
  },
  sectionContainer: {
    backgroundColor: '#fff',
    borderRadius: 10,
    margin: 8,
  },
});
