import { View } from 'react-native';
import Slider from 'react-native-custom-slider';

import { Video } from 'expo-av';
import { Asset } from 'expo-media-library';
import { useState } from 'react';

interface IThumbnailPickerProps {
  asset: Pick<Asset, 'uri' | 'duration'>;
  onSetThumbnail: (positionInMilliseconds: number) => void;
}

export default function ThumbnailPicker({
  asset,
  onSetThumbnail,
}: IThumbnailPickerProps) {
  const [sliderPosition, setSliderPosition] = useState(0);

  function onSlideEnd() {
    onSetThumbnail?.(sliderPosition);
  }

  return (
    <View
      style={{
        width: '90%',
        height: 128,
        justifyContent: 'center',
      }}
    >
      <View
        style={{
          backgroundColor: 'gray',
          height: 64,
          borderRadius: 6,
          justifyContent: 'center',
        }}
      >
        {/* @ts-ignore */}
        <Slider
          trackStyle={{
            opacity: 0,
          }}
          maximumValue={(asset?.duration ?? 1) * 1000}
          onValueChange={(value: number) => {
            setSliderPosition(value);
          }}
          thumbTouchSize={{
            height: 128,
            width: 96,
          }}
          value={sliderPosition}
          thumbStyle={{
            backgroundColor: '#222',
            borderRadius: 6,
          }}
          onSlidingComplete={onSlideEnd}
          customThumb={
            <Video
              source={{ uri: asset?.uri ?? '' }}
              style={{
                height: 128,
                width: 96,
              }}
              onError={(error) => {
                console.log('error', error);
              }}
              positionMillis={sliderPosition ?? 0}
            />
          }
        />
      </View>
    </View>
  );
}
