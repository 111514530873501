import { useCallback, useRef } from 'react';
import { Animated } from 'react-native';

type UseScrollHintAnimationReturns = {
  translateY: Animated.Value;
  animate: () => void;
};

export default function useScrollHintAnimation(): UseScrollHintAnimationReturns {
  const translateY = useRef(new Animated.Value(0)).current;

  const animate = useCallback(() => {
    Animated.sequence([
      Animated.timing(translateY, {
        toValue: -30,
        duration: 640,
        useNativeDriver: true,
      }),
      Animated.spring(translateY, {
        toValue: 0,
        friction: 6,
        tension: 120,
        useNativeDriver: true,
      }),
    ]).start();
  }, [translateY]);

  return {
    translateY,
    animate,
  };
}
