import { FontAwesome } from '@expo/vector-icons';
import { NavigationRouteContext, useLinkTo } from '@react-navigation/native';
import { Badge } from '@rneui/themed';
import { useContext } from 'react';
import { TouchableOpacity, ViewStyle } from 'react-native';
import Alert from '/Alert';
import { useAuthContext } from '/context';
import { formatMoney } from '/util';

interface Props {
  likeCount: number;
  isLiked: boolean;
  size?: number;
  style?: ViewStyle;
  returnToOnLogIn?: string;
  onPress: () => void;
}

const DEFAULT_SIZE = 40;

export default function LikeButtonComponent(props: Props) {
  const { userData } = useAuthContext();

  const route = useContext(NavigationRouteContext);

  const linkTo = useLinkTo();

  const onPress = () => {
    if (!userData?.id) {
      Alert.alert('Log In', 'Log in or create an account to like posts', [
        { style: 'cancel' },
        {
          text: 'Continue',
          style: 'default',
          onPress: () => {
            let params = '';

            const returnTo = props.returnToOnLogIn || route?.path;

            if (returnTo) {
              params = `?returnto=${encodeURIComponent(returnTo)}`;
            }

            linkTo(`/login${params}`);
          },
        },
      ]);
      return;
    }

    props.onPress();
  };

  return (
    <TouchableOpacity
      style={[
        {
          borderRadius: 12,
          backgroundColor: '#f5f5f5',
          margin: 2,
          justifyContent: 'center',
          alignItems: 'center',
          width: props.size ?? DEFAULT_SIZE,
          height: props.size ?? DEFAULT_SIZE,
        },
        props.style,
      ]}
      onPress={onPress}
    >
      {props.isLiked ? (
        <FontAwesome
          name="heart"
          size={Math.floor(0.65 * (props.size ?? DEFAULT_SIZE))}
          color="red"
        />
      ) : (
        <FontAwesome
          name="heart-o"
          size={Math.floor(0.65 * (props.size ?? DEFAULT_SIZE))}
          color="black"
        />
      )}

      {props.likeCount ? (
        <Badge
          textStyle={{
            color: 'black',
            fontSize: Math.floor(0.35 * (props.size ?? DEFAULT_SIZE)),
          }}
          textProps={{
            adjustsFontSizeToFit: true,
          }}
          badgeStyle={{
            backgroundColor: '#CAFF03',
          }}
          containerStyle={{
            position: 'absolute',
            top: -6,
            right: -6,
          }}
          value={formatMoney(props.likeCount, 0)}
        />
      ) : null}
    </TouchableOpacity>
  );
}
