import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker as MUITimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Platform, StyleProp, View, ViewStyle } from 'react-native';
import Button from '../Button';
import { default as NativeDateTimePicker } from '@react-native-community/datetimepicker';

export interface DateTimePickerProps {
  value?: Date;
  mode?: 'date' | 'time' | 'datetime';
  onChange?: (date: Date) => void;
  style?: StyleProp<ViewStyle>;
  maxDate?: Date;
  minDate?: Date;
}

export default function DateTimePickerCore(props: DateTimePickerProps) {
  const [_ANDROID_showDatePicker, set_ANDROID_showDatepicker] = useState(false);
  const [_ANDROID_showTimePicker, set_ANDROID_showTimePicker] = useState(false);

  return (
    <View
      style={[
        {
          flexDirection: 'row',
          flex: 1,
          justifyContent: 'flex-end',
        },
        props.style,
      ]}
    >
      {Platform.OS === 'android' && (
        <>
          <Button
            label={
              props.value ? dayjs(props.value).format('LLL') : 'Select Date'
            }
            containerStyle={{
              width: '100%',
            }}
            onPress={() => {
              set_ANDROID_showDatepicker(true);
            }}
          />
        </>
      )}
      {(Platform.OS !== 'android' || _ANDROID_showDatePicker) &&
        (Platform.OS === 'web' ? (
          // WEB DATETIME PICKER
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {props.mode === 'date' ? (
              <MUIDatePicker
                maxDate={props.maxDate}
                minDate={props.minDate}
                value={props.value}
                onChange={(date) => {
                  if (!date || date.toString() === 'Invalid Date') return;

                  // floor date to midnight in `date` mode
                  date = new Date(date.setHours(0, 0, 0, 0));

                  props.onChange?.(date);
                }}
                renderInput={(_props) => <TextField {..._props} />}
              />
            ) : props.mode === 'time' ? (
              <MUITimePicker
                value={props.value}
                onChange={(date) => {
                  if (!date || date.toString() === 'Invalid Time') return;

                  props.onChange?.(date);
                }}
                renderInput={(_props) => <TextField {..._props} />}
              />
            ) : (
              <MUIDateTimePicker
                maxDate={props.maxDate}
                minDate={props.minDate}
                value={props.value}
                onChange={(date) => {
                  if (!date || date.toString() === 'Invalid Date') return;

                  props.onChange?.(date);
                }}
                renderInput={(_props) => <TextField {..._props} />}
              />
            )}
          </LocalizationProvider>
        ) : (
          // NATIVE DATETIME PICKER
          <NativeDateTimePicker
            value={
              typeof props.value?.getTime === 'function' &&
              !isNaN(props.value?.getTime())
                ? props.value
                : new Date()
            }
            style={{
              flex: 1,
            }}
            minimumDate={props.minDate}
            maximumDate={props.maxDate}
            textColor="black"
            mode={Platform.select({
              default: props.mode,
              android: props.mode === 'datetime' ? 'date' : props.mode,
            })}
            // is24Hour={true}
            display="default"
            onChange={(event: any, d: any) => {
              // Android only
              if (Platform.OS === 'android') {
                set_ANDROID_showDatepicker(false);

                // 'dismissed' means user pressed 'Cancel' button
                // in which case we don't want to open the next
                // picker
                if (
                  event.type !== 'dismissed' &&
                  (!props.mode || props.mode === 'datetime')
                ) {
                  set_ANDROID_showTimePicker(true);
                }
              }

              if (!d) return;

              if (props.mode === 'date') {
                // floor date to midnight in `date` mode
                d = new Date(d.setHours(0, 0, 0, 0));
              }

              props.onChange?.(d);
            }}
          />
        ))}
      {/* ANDROID ONLY (TIME PICKER FOR DATE-TIME) */}
      {Platform.OS === 'android' && _ANDROID_showTimePicker && (
        <>
          <NativeDateTimePicker
            testID="timePicker"
            minimumDate={props.minDate}
            maximumDate={props.maxDate}
            value={props.value ?? new Date()}
            textColor="black"
            mode="time"
            is24Hour={true}
            display="default"
            onChange={(event: any, d: any) => {
              // Android only
              set_ANDROID_showTimePicker(false);

              if (!d) return;

              if (props.mode === 'date') {
                // floor date to midnight in `date` mode
                d = new Date(d.setHours(0, 0, 0, 0));
              }

              props.onChange?.(d);
            }}
          />
        </>
      )}
    </View>
  );
}
