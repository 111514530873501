import { UpdateResolver } from '@urql/exchange-graphcache';
import { GetMyImpactApplicationsDocument } from '/generated/graphql';

const declineSkillRequestSelection: UpdateResolver = (result, args, cache) => {
  cache.updateQuery(
    {
      query: GetMyImpactApplicationsDocument,
      variables: {
        confirmedQueryString: '',
        unconfirmedQueryString: '',
      },
    },
    (data: any) => {
      // Move application from `selected` to `currently_empowering`

      // Get target application index using ID
      const applicationIndex =
        data.getMyImpactApplications.selected?.items.findIndex(
          (app: any) => app.id === args.id,
        );

      /** If we can't find target application, then we cannot perform this
       * cache update. Just return data */
      if (applicationIndex === -1) return data;

      // Splice target application
      const removedApplications =
        data.getMyImpactApplications.selected?.items.splice(
          applicationIndex,
          1,
        );

      // Add target application to new target section
      data.getMyImpactApplications.declined?.items.unshift(
        removedApplications[0],
      );

      // Update totals
      data.getMyImpactApplications.selected.total -= 1;
      if (data.getMyImpactApplications.declined)
        data.getMyImpactApplications.declined.total += 1;

      return data;
    },
  );
};

export default declineSkillRequestSelection;
