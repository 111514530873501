import { Resolver, Variables } from '@urql/exchange-graphcache';
import { stringifyVariables } from 'urql';

const compareArgs = (
  fieldArgs: Variables,
  connectionArgs: Variables | null,
): boolean => {
  const ignoreKeys = ['cursor']; // Ignore cursor for comparison
  const fieldArgsKeys = Object.keys(fieldArgs).filter(
    (key) => !ignoreKeys.includes(key),
  );
  const connectionArgsKeys = Object.keys(connectionArgs ?? {}).filter(
    (key) => !ignoreKeys.includes(key),
  );

  if (fieldArgsKeys.length !== connectionArgsKeys.length) return false;

  return fieldArgsKeys.every((key) => {
    const argA = fieldArgs[key];
    const argB = (connectionArgs ?? {})[key];
    return stringifyVariables(argA) === stringifyVariables(argB);
  });
};

const getNotificationsForUser: Resolver = (result, fieldArgs, cache, info) => {
  if (!fieldArgs?.filterByActionableGroup)
    return cache.resolve(info.parentKey, info.fieldName, fieldArgs) as any;

  const { parentKey: entityKey, fieldName } = info;
  const allFields = cache.inspectFields(entityKey);
  const fieldInfos = allFields.filter((i) => i.fieldName === fieldName);

  let section: any = {
    __typename: 'ActionableNotificationsSection',
    notifications: [],
    hasMore: false,
    nextToken: null,
  };

  fieldInfos.forEach((fieldInfo) => {
    if (!compareArgs(fieldArgs, fieldInfo.arguments)) {
      return; // Skip merging if arguments do not match
    }

    const key = cache.resolve(entityKey, fieldInfo.fieldKey) as string;
    const data = cache.resolve(key, 'actionable') as any[];
    if (!data?.length) return;

    if (data) {
      data.forEach((_section) => {
        const sectionGroup = cache.resolve(_section, 'group');
        if (sectionGroup !== fieldArgs.filterByActionableGroup) return;
        const sectionNotifications = cache.resolve(
          _section,
          'notifications',
        ) as string[];
        const sectionTitle = cache.resolve(_section, 'title') as string;
        const sectionHasMore = cache.resolve(_section, 'hasMore') as boolean;

        // Merge notifications
        section.notifications.push(...sectionNotifications);
        section.title = sectionTitle;
        section.hasMore = sectionHasMore;
      });
    }
  });

  // Deduplicate notifications
  section.notifications = Array.from(new Set(section.notifications));

  return {
    ...result,
    __typename: 'GetAllNotificationsResponse',
    actionable: [section],
  };
};

export default getNotificationsForUser;
