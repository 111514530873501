import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  headerContainer: {
    backgroundColor: '#323338',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 4,
  },
  headerLeft: {
    flex: 1,
    alignItems: 'flex-start',
    padding: 8,
    paddingHorizontal: 16,
    margin: 4,
    maxWidth: 80,
  },
  headerCenter: {
    flex: 1,
    alignItems: 'center',
    padding: 8,
  },
  headerRight: {
    flex: 1,
    alignItems: 'flex-end',
    padding: 8,
    paddingHorizontal: 16,
    margin: 4,
    maxWidth: 80,
  },
});
