import { AntDesign, FontAwesome5 } from '@expo/vector-icons';
import React, { useEffect, useRef, useState } from 'react';
import {
  Pressable,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import styles from './LoginForm.style';
import PasswordTooltip from '../PasswordTooltip/PasswordTooltip';
import { CognitoIdentityProvider } from '/context/AuthProvider';

interface ILoginFormProps {
  mode: 'default' | 'login' | 'signup';
  email: string;
  password: string;
  confirmPassword: string;
  onFederatedSignIn: (provider: CognitoIdentityProvider) => Promise<void>;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  onSubmit: () => Promise<void>;
  setConfirmPassword: (confirmPassword: string) => void;
}

const loginModeButtonLabelMap = {
  default: 'Next',
  login: 'Sign In',
  signup: 'Sign Up',
};

export default function LoginForm(props: ILoginFormProps) {
  const [securePass, setSecurePass] = useState(true);
  const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);

  const ref_email = useRef<TextInput>();
  const ref_password = useRef<TextInput>();
  const ref_confirm_password = useRef<TextInput>();

  useEffect(() => {
    if (props.mode === 'login') {
      ref_password.current?.focus();
    }
  }, [props.mode]);

  return (
    <View style={styles.inputContainer}>
      <View style={styles.inputField}>
        <TextInput
          testID="emailInput"
          placeholder="EMAIL"
          placeholderTextColor="#fafafa"
          enterKeyHint="next"
          style={[
            styles.input,
            {
              pointerEvents: props.mode === 'default' ? 'auto' : 'none',
              opacity: props.mode === 'default' ? 1 : 0.8,
            },
          ]}
          keyboardType="email-address"
          textContentType="username"
          autoCapitalize="none"
          autoCorrect={false}
          ref={(r) => {
            if (r) ref_email.current = r;
          }}
          value={props.email}
          onSubmitEditing={() => {
            if (props.mode === 'default') {
              props.onSubmit();
            } else {
              ref_password.current?.focus();
            }
          }}
          onChangeText={(text) => {
            if (props.mode !== 'default') return;

            props.setEmail(text);
          }}
        />
        {props.mode === 'default' ? null : (
          <View
            style={{
              position: 'absolute',
              right: 12,
              top: '50%',
              opacity: 0.8,
              transform: [{ translateY: -10 }],
            }}
          >
            <FontAwesome5 name="lock" size={20} color={'#ddd'} />
          </View>
        )}
      </View>
      {props.mode === 'default' ? (
        /** Cause OS and browsers to give email autofill suggestions */
        <TextInput
          secureTextEntry={true}
          enterKeyHint="done"
          autoCapitalize="none"
          autoCorrect={false}
          textContentType="password"
          style={{
            position: 'absolute',
            width: 0,
            height: 0,
            pointerEvents: 'none',
          }}
        />
      ) : (
        <>
          <View style={styles.inputField}>
            <PasswordTooltip
              onTapToHide={() => {
                // No need to do anything because tapping this will blur the password field and hide the tooltip
              }}
              password={props.password}
              show={props.mode === 'signup' && showPasswordTooltip}
            />

            <TextInput
              testID="passwordInput"
              placeholder={
                props.mode === 'signup' ? 'CREATE PASSWORD' : 'PASSWORD'
              }
              placeholderTextColor="#fafafa"
              secureTextEntry={securePass}
              enterKeyHint="done"
              onSubmitEditing={() => {
                if (props.mode === 'signup') {
                  ref_confirm_password.current?.focus();
                } else {
                  props.onSubmit();
                }
              }}
              autoCapitalize="none"
              autoCorrect={false}
              style={[styles.input]}
              onFocus={() => {
                setShowPasswordTooltip(true);
              }}
              onBlur={() => {
                setShowPasswordTooltip(false);
              }}
              onEndEditing={() => setShowPasswordTooltip(false)}
              ref={(r) => {
                if (r) ref_password.current = r;
              }}
              value={props.password}
              onChangeText={(text) => props.setPassword(text)}
            />

            <Pressable
              onPress={() => {
                setSecurePass(!securePass);
              }}
              style={{
                position: 'absolute',
                right: 0,
                top: 0,
                bottom: 0,
                justifyContent: 'center',
                zIndex: 1,
                elevation: 1,
              }}
            >
              {!securePass ? (
                <AntDesign
                  style={{
                    paddingHorizontal: 12,
                  }}
                  name="eye"
                  size={24}
                  color="black"
                />
              ) : (
                <AntDesign
                  style={{
                    paddingHorizontal: 12,
                  }}
                  name="eyeo"
                  size={24}
                  color="black"
                />
              )}
            </Pressable>
          </View>
          {props.mode === 'signup' && (
            <View style={styles.inputField}>
              <TextInput
                testID="confirmPasswordInput"
                placeholder={'CONFIRM PASSWORD'}
                placeholderTextColor="#fafafa"
                secureTextEntry={securePass}
                enterKeyHint="done"
                onSubmitEditing={props.onSubmit}
                autoCapitalize="none"
                autoCorrect={false}
                style={[styles.input]}
                ref={(r) => {
                  if (r) ref_confirm_password.current = r;
                }}
                value={props.confirmPassword}
                onChangeText={(text) => props.setConfirmPassword(text)}
              />
            </View>
          )}
        </>
      )}
      <TouchableOpacity
        testID="LoginButton"
        style={styles.buttonContainer}
        onPress={props.onSubmit}
      >
        <Text style={styles.button}>{loginModeButtonLabelMap[props.mode]}</Text>
      </TouchableOpacity>
      {props.mode === 'default' ? (
        <>
          <TouchableOpacity
            testID="LoginWithGoogleButton"
            style={[
              styles.buttonContainer,
              styles.googleButton,
              {
                marginTop: 16,
              },
            ]}
            onPress={() =>
              props.onFederatedSignIn(CognitoIdentityProvider.Google)
            }
          >
            <AntDesign
              style={{
                marginHorizontal: 12,
              }}
              name="google"
              size={24}
              color="black"
            />

            <Text
              style={[
                styles.button,
                {
                  textAlign: 'left',
                },
              ]}
            >
              Continue with Google
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            testID="LoginWithAppleButton"
            style={[styles.buttonContainer, styles.appleButton]}
            onPress={() =>
              props.onFederatedSignIn(CognitoIdentityProvider.Apple)
            }
          >
            <AntDesign
              style={{
                marginHorizontal: 12,
              }}
              name="apple1"
              size={24}
              color="white"
            />
            <Text
              style={[
                styles.button,
                {
                  color: 'white',
                  textAlign: 'left',
                },
              ]}
            >
              Continue with Apple
            </Text>
          </TouchableOpacity>
          {/* <TouchableOpacity
            testID="LoginWithFacebookButton"
            style={[styles.buttonContainer, styles.facebookButton]}
            onPress={() =>
              props.onFederatedSignIn(CognitoIdentityProvider.Facebook)
            }
          >
            <AntDesign
              style={{
                marginHorizontal: 12,
              }}
              name="facebook-square"
              size={24}
              color="white"
            />

            <Text
              style={[
                styles.button,
                {
                  color: 'white',
                  textAlign: 'left',
                },
              ]}
            >
              Continue with Facebook
            </Text>
          </TouchableOpacity> */}
        </>
      ) : null}
    </View>
  );
}
