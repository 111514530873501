import { AntDesign, FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import ButtonToggle from './ButtonToggle/ButtonToggle';
import Alert from '/Alert';
import { KEY_GRAY, KEY_GREEN } from '/constants';
import {
  ResearchTopic,
  useFollowResearchTopicMutation,
  useUnfollowResearchTopicMutation,
} from '/generated/graphql';

interface Props {
  topic: Pick<ResearchTopic, 'topic' | 'is_following'>;
  buttonColor?: string;
  buttonSelectedColor?: string;
  onFollow?: () => void;
  onUnfollow?: () => void;
}

export default function ResearchTopicButton(props: Props) {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const [{ fetching: following }, followResearchTopic] =
    useFollowResearchTopicMutation();
  const [{ fetching: unfollowing }, unfollowResearchTopic] =
    useUnfollowResearchTopicMutation();

  function onFollowTopic(topicName: string) {
    if (following) return;

    followResearchTopic({
      topic: topicName,
    }).then(() => {
      props.onFollow?.();

      Alert.notify({
        message: 'Followed topic!',
        color: KEY_GREEN,
      });
    });
  }

  function onUnfollowTopic(topicName: string) {
    if (unfollowing) return;

    const _unfollow = () => {
      unfollowResearchTopic({
        topic: topicName,
      }).then(() => {
        props.onUnfollow?.();

        Alert.notify({
          message: 'Unfollowed topic!',
          color: KEY_GREEN,
        });
      });
    };

    Alert.alert(
      'Unfollow Topic',
      `Are you sure you want to unfollow ${topicName}?`,
      [
        {
          style: 'destructive',
          text: 'Unfollow',
          onPress: _unfollow,
        },
        {
          style: 'cancel',
        },
      ],
    );
  }

  function onGoToTopicSummary(topicName: string) {
    navigate('ResearchTopicSummary', { topic: topicName });
  }

  return (
    <ButtonToggle
      selected={props.topic.is_following}
      onPress={() => onGoToTopicSummary(props.topic.topic!)}
      key={props.topic.topic}
      color={props.buttonColor}
      selectedColor={props.buttonSelectedColor}
      style={{
        height: 'auto',
        paddingHorizontal: 0,
        overflow: 'hidden',
      }}
      label={
        <View
          style={{
            paddingHorizontal: 16,
            paddingLeft: 0,
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Pressable
            onPress={() => {
              if (props.topic.is_following && !following) {
                onUnfollowTopic(props.topic.topic!);
              } else if (props.topic.is_following === false && !unfollowing) {
                onFollowTopic(props.topic.topic!);
              }
            }}
            style={({ pressed }) => ({
              height: '100%',
              paddingVertical: 8,
              paddingLeft: 12,
              justifyContent: 'center',
              backgroundColor: pressed ? 'gray' : 'transparent',
            })}
          >
            {props.topic.is_following ? (
              <FontAwesome
                name="check"
                size={18}
                style={{
                  paddingHorizontal: 2,
                }}
                color={'black'}
              />
            ) : (
              <AntDesign
                name="pluscircleo"
                style={{
                  paddingRight: 4,
                }}
                size={18}
                color="black"
              />
            )}
          </Pressable>
          <Text style={styles.topicLabel}>
            #{props.topic.topic?.split?.(' ').join('')}
          </Text>
        </View>
      }
      labelStyle={{
        textTransform: 'uppercase',
      }}
    />
  );
}

const styles = StyleSheet.create({
  topicLabel: {
    fontFamily: 'Lato-Bold',
    letterSpacing: 1,
    color: KEY_GRAY,
    textTransform: 'uppercase',
  },
});
