import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';

const createNewsPostBookmark: UpdateResolver = (result, args, cache) => {
  const fragment = gql`
    fragment CreateNewsPostBookmark on NewsPost {
      id
      is_bookmarked
    }
  `;

  cache.writeFragment(fragment, {
    id: args.id,
    __typename: 'NewsPost',
    is_bookmarked: true,
  });
};

export default createNewsPostBookmark;
