import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  emptyText: {
    alignSelf: 'center',
    fontFamily: 'Lato',
    fontSize: 16,
    color: '#999',
    padding: 24,
    textAlign: 'center',
  },
  activityIndicatorContainer: {
    width: '100%',
    padding: 24,
    alignItems: 'center',
  },
  itemSeparator: {
    borderTopWidth: 2,
    width: '100%',
    borderTopColor: '#F2F2FB',
  },
  errorText: {
    alignSelf: 'center',
    fontFamily: 'Lato',
    fontSize: 16,
    color: 'crimson',
    padding: 24,
    textAlign: 'center',
  },
});
