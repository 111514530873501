import { Button } from '@rneui/themed';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import {
  ActivityIndicator,
  FlatList,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import CampaignPreview from '../../CampaignPreview/CampaignPreview';
import Alert from '/Alert';
import CrossCircle from '/assets/jsicons/CrossCircle';
import { KEY_GRAY } from '/constants';
import {
  Campaign,
  CampaignPost,
  useDeleteCampaignDraftMutation,
} from '/generated/graphql';
import { shorten } from '/util';
import GenericListFooter from '/components/GenericListFooter';

interface ICampaignDraft
  extends Pick<Campaign, 'id' | 'name' | 'updated_at' | 'created_at'> {
  original_post: Pick<CampaignPost, 'id' | 'media' | 'thumbnail'>;
}

interface Props {
  drafts: ICampaignDraft[];
  onSelectDraft: (draftId: string) => void;
  onPaginateDrafts: () => void;
  onCreateNewDraft: () => void;
  onDeleteDraft: (id: string) => void;
  hasMoreDrafts: boolean;
  currentCampaignId: string | undefined;
  onRetryFetchDrafts: () => void;
  error: string;
  loading: boolean;
}

export default function DraftsMenu({
  drafts,
  onSelectDraft,
  onPaginateDrafts,
  onCreateNewDraft,
  onDeleteDraft,
  hasMoreDrafts,
  // Used to determine which draft is currently being worked on.
  currentCampaignId,
  onRetryFetchDrafts,
  error,
  loading,
}: Props) {
  // TODO: Sort drafts by updatedAt

  // IDs of drafts that are in loading states
  const [busy, setBusy] = useState<{
    [key: string]: boolean;
  }>({});

  const [, deleteCampaignDraft] = useDeleteCampaignDraftMutation();

  // Keep track of renders and log
  // const renderCount = React.useRef(0);
  // React.useEffect(() => {
  //   renderCount.current += 1;
  //   console.log('DraftsMenu render count: ', renderCount.current);
  // });

  async function _discardDraft(id: string) {
    setBusy({
      ...busy,
      [id]: true,
    });

    try {
      const res = await deleteCampaignDraft({ id });

      if (res.error) {
        Alert.alert(
          'Error',
          'There was a problem while trying to discard the draft',
        );
        console.log(res.error);
        return;
      }

      onDeleteDraft?.(id);
    } catch (err) {
      console.log(err);
    } finally {
      setBusy((prevState) => ({
        ...prevState,
        [id]: false,
      }));
    }
  }

  function onDiscardDraft(draft: ICampaignDraft) {
    const draftName = draft.name || 'Untitled Draft';

    Alert.alert(
      'Discard Draft',
      `Are you sure you want to discard the draft "${shorten(draftName, 32)}"?`,
      [
        {
          text: 'Discard',
          style: 'destructive',
          onPress: () => _discardDraft(draft.id),
        },
        {
          text: 'Cancel',
          style: 'cancel',
        },
      ],
    );
  }

  function onCreateNew() {
    onCreateNewDraft();
  }

  return (
    <SafeAreaView style={styles.container}>
      {!!error && !loading && (
        /** ERROR STATE */
        <View
          style={[
            styles.errorContainer,
            {
              flex: drafts?.length ? 0 : 1,
            },
          ]}
        >
          <Text style={styles.errorText}>{error}</Text>
          <Button
            buttonStyle={styles.retryButton}
            titleStyle={styles.retryButtonText}
            title="Retry"
            onPress={onRetryFetchDrafts}
          />
        </View>
      )}
      {loading && !drafts?.length ? (
        /** LOADING STATE */
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color={KEY_GRAY} />
        </View>
      ) : drafts?.length ? (
        /** MAIN STATE */
        <>
          <Text style={styles.promptText}>
            Select a draft below to resume editing
          </Text>
          <FlatList
            data={drafts}
            ListHeaderComponent={
              currentCampaignId ? (
                <TouchableOpacity
                  activeOpacity={0.72}
                  style={styles.createNewButton}
                  onPress={onCreateNew}
                >
                  <Text style={styles.createNewButtonText}>+ Create New</Text>
                </TouchableOpacity>
              ) : null
            }
            keyExtractor={(_) => _.id}
            renderItem={({ item: draft }) => {
              const isSelected = currentCampaignId === draft.id;
              return (
                <View
                  style={{
                    borderRadius: 6,
                    marginBottom: 8,
                    padding: 4,
                    backgroundColor: isSelected ? '#fafafa' : '#eaeaea',
                    borderWidth: 2,
                    borderColor: isSelected ? KEY_GRAY : '#eaeaea',
                    flexDirection: 'row',
                    alignItems: 'center',
                    opacity: busy[draft.id] ? 0.5 : 1,
                  }}
                  pointerEvents={busy[draft.id] ? 'none' : 'auto'}
                >
                  <View
                    style={{
                      flex: 1,
                    }}
                  >
                    <CampaignPreview
                      hideAuthor
                      thumbnailSize={72}
                      onPress={() => {
                        onSelectDraft?.(draft.id);
                      }}
                      subtitle={`Updated ${dayjs(
                        Number(draft.updated_at),
                      ).format('L')}`}
                      subtitleTextStyle={{
                        fontFamily: 'Lato',
                        color: 'gray',
                        fontSize: 14,
                      }}
                      showSocialControls={false}
                      campaignPost={{
                        ...draft.original_post,
                      }}
                      campaign={{
                        ...draft,
                        name: draft.name || 'Untitled Draft',
                      }}
                    />
                  </View>
                  <Pressable
                    style={{
                      paddingLeft: 4,
                    }}
                    onPress={() => onDiscardDraft(draft)}
                  >
                    <CrossCircle width={20} height={20} />
                  </Pressable>
                </View>
              );
            }}
            ListFooterComponent={
              <GenericListFooter
                hasMore={hasMoreDrafts}
                loading={loading}
                onFetchMore={onPaginateDrafts}
              />
            }
            onEndReached={() => {
              onPaginateDrafts();
            }}
          />
        </>
      ) : (
        /** EMPTY STATE (NO ERROR) */
        !error && <Text style={styles.emptyText}>You have no drafts.</Text>
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#ddd',
    flex: 1,
    padding: 10,
    paddingTop: 24,
    paddingBottom: 24,
  },
  createNewButton: {
    borderRadius: 6,
    backgroundColor: '#eee',
    padding: 12,
    marginBottom: 8,
  },
  createNewButtonText: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
    textAlign: 'center',
  },
  promptText: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    paddingBottom: 16,
  },
  emptyText: {
    marginTop: 24,
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    width: '100%',
    textAlign: 'center',
  },
  errorContainer: {
    marginBottom: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorText: {
    color: KEY_GRAY,
    textAlign: 'center',
    fontFamily: 'Lato',
    paddingBottom: 10,
    paddingHorizontal: 4,
  },
  retryButton: {
    backgroundColor: 'white',
  },
  retryButtonText: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    fontSize: 15,
    padding: 4,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
