import { View, Text, ViewStyle, Animated, Pressable } from 'react-native';
import React, { useEffect, useRef } from 'react';
import { CampaignUrgency } from '/generated/graphql';
import styles from '/constants/CampaignBuilder';
import { KEY_GRAY } from '/constants';

type Props = {
  value: CampaignUrgency | undefined;
  onChange: (value: CampaignUrgency) => void;
  containerStyle?: ViewStyle;
};

export default function CampaignUrgencySelector({
  value,
  onChange,
  ...props
}: Props) {
  return (
    <View style={props.containerStyle}>
      <Text style={styles.subheading}>How urgent is this?</Text>
      <Text style={styles.description}>Select one</Text>
      <View style={{ marginVertical: 16 }}>
        <UrgencyButton
          onPress={() => onChange(CampaignUrgency.Critical)}
          selected={value === CampaignUrgency.Critical}
          urgency={CampaignUrgency.Critical}
          color="#F2005C"
          description="ONLY USE IF: Dire consequences may occur if immediate support is not made available."
        />
        <UrgencyButton
          onPress={() => onChange(CampaignUrgency.Urgent)}
          selected={value === CampaignUrgency.Urgent}
          urgency={CampaignUrgency.Urgent}
          color="#F4AF00"
          description="Immediate support needed but it is not critical."
        />
        <UrgencyButton
          onPress={() => onChange(CampaignUrgency.LongTerm)}
          selected={value === CampaignUrgency.LongTerm}
          urgency={CampaignUrgency.LongTerm}
          color="#00F99A"
          description="Support is needed but can be raised over a longer period of time. This is good for projects that need larger amounts of support."
        />
        <UrgencyButton
          onPress={() => onChange(CampaignUrgency.None)}
          selected={value === CampaignUrgency.None || !value}
          urgency={CampaignUrgency.None}
          color="#eee"
          description="There is no immediate rush for support on this campaign nor is it a long-term goal."
        />
      </View>
    </View>
  );
}

const UrgencyButton = ({
  color,
  urgency,
  description = '',
  onPress,
  selected,
}: {
  color: string;
  urgency: CampaignUrgency;
  description: string;
  onPress?: () => void;
  selected: boolean;
}) => {
  const animation = useRef(new Animated.Value(selected ? 1 : 0));

  useEffect(() => {
    if (selected)
      Animated.timing(animation.current, {
        toValue: 1,
        useNativeDriver: true,
        duration: 72,
      }).start();
    else
      Animated.timing(animation.current, {
        toValue: 0,
        useNativeDriver: true,
        duration: 72,
      }).start();
  }, [selected]);

  const scale = animation.current.interpolate({
    inputRange: [0, 1],
    outputRange: [0.95, 1],
  });

  const opacity = animation.current.interpolate({
    inputRange: [0, 1],
    outputRange: [0.4, 1],
  });

  return (
    <Pressable
      style={{
        marginVertical: 2,
      }}
      onPress={onPress}
    >
      <Animated.View
        style={[
          {
            padding: 8,
            borderRadius: 8,
            shadowColor: 'gray',
            shadowRadius: 5,
            shadowOpacity: 0.4,
            shadowOffset: {
              width: 0,
              height: 0,
            },
            borderWidth: 1,
            opacity: opacity,
            transform: [{ scale: scale }],
          },
          selected
            ? {
                elevation: 5,
                backgroundColor: '#fff',
                borderColor: '#0000',
              }
            : { borderColor: '#0000', shadowOpacity: 0 },
        ]}
      >
        <View
          style={{
            borderRadius: 8,
            backgroundColor: color,
            padding: 8,
            width: 112,
            marginVertical: 4,
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              fontFamily: 'Lato-Bold',
              color: KEY_GRAY,
            }}
          >
            {urgency.replace('_', ' ').toUpperCase()}
          </Text>
        </View>
        <View>
          <Text style={[styles.description, { color: '#999' }]}>
            {description}
          </Text>
        </View>
      </Animated.View>
    </Pressable>
  );
};
