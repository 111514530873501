// @ts-nocheck

import { Mixins } from '@skele/components';
import React from 'react';
import { Animated, findNodeHandle } from 'react-native';

class ViewportTrackingAnimatedFlatList extends Mixins.WithEvents(
  {
    name: 'viewport',
    inChildContext: true,
    notifiesWithLastEventOnAdd: true,
  },

  React.Component,
) {
  _viewportWidth = 0;

  _viewportHeight = 0;

  _viewportOffsetX = 0;

  _viewportOffsetY = 0;

  _nodeHandle;

  _onLayout = (event) => {
    this.props.onLayout?.(event);

    this._viewportWidth = event.nativeEvent.layout.width;
    this._viewportHeight = event.nativeEvent.layout.height;
    this._onViewportChange();
  };

  _onContentSizeChange = () => {
    this._onViewportChange();
  };

  _onViewportChange = (shouldMeasureLayout = true) => {
    this._nodeHandle &&
      this._viewportWidth > 0 &&
      this._viewportHeight > 0 &&
      this.notifyViewportListeners({
        parentHandle: this._nodeHandle,
        viewportOffsetX: this._viewportOffsetX,
        viewportOffsetY: this._viewportOffsetY,
        viewportWidth: this._viewportWidth,
        viewportHeight: this._viewportHeight,
        shouldMeasureLayout,
      });
  };

  _onScroll = (event) => {
    this._viewportOffsetX = event.nativeEvent.contentOffset.x;
    this._viewportOffsetY = event.nativeEvent.contentOffset.y;
    this._onViewportChange(false);
  };

  render() {
    const { innerRef, ...props } = this.props;

    return (
      <Animated.FlatList
        {...props}
        onContentSizeChange={this._onContentSizeChange}
        onLayout={(event) => {
          this._onLayout(event);
        }}
        ref={(r) => {
          // We want to share this ref
          this._nodeHandle = findNodeHandle(r);
          innerRef?.(r);
        }}
        //@ts-ignore
        onScroll={Animated.forkEvent(this.props.onScroll, this._onScroll)}
      >
        {this.props.children}
      </Animated.FlatList>
    );
  }
}

export default React.forwardRef<
  Animated.FlatList<ItemT>,
  React.ComponentPropsWithoutRef<Animated.FlatList<ItemT>>
  // React.ComponentProps<Animated.FlatList<ItemT>, >
>((props, ref) => (
  <ViewportTrackingAnimatedFlatList {...props} innerRef={ref} />
));
