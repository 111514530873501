import geocoder from './geocoder';
import env from '/env';

const geo = geocoder({
  key: env.WEB_GOOGLE_MAPS_KEY,
});

type GeocodeResult = {
  location?: {
    lat: number;
    lng: number;
  };
};

export const geocode = async (address: string): Promise<GeocodeResult[]> => {
  return new Promise((resolve, reject) => {
    geo.find(address, (err: any, res: GeocodeResult[]) => {
      if (err) reject(err);
      else resolve(res);
    });
  });
};
