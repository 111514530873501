import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';

const deleteCreativeConnectProjectBookmark: UpdateResolver = (
  result,
  args,
  cache,
) => {
  const fragment = gql`
    fragment RemoveCCBookmark on CreativeConnectProject {
      id
      is_bookmarked
    }
  `;

  const userSpecificFragment = gql`
    fragment UserSpecificRemoveCCBookmark on CreativeConnectProject {
      id
      is_bookmarked(userId: $userId)
    }
  `;

  cache.writeFragment(fragment, {
    id: args.projectId,
    is_bookmarked: false,
  });

  cache.writeFragment(
    userSpecificFragment,
    {
      id: args.projectId,
      is_bookmarked: false,
    },
    {
      userId: args.userId,
    },
  );
};

export default deleteCreativeConnectProjectBookmark;
