import { UpdateResolver } from '@urql/exchange-graphcache';

const deleteSpeciesUserContentMedia: UpdateResolver = (result, args, cache) => {
  cache.invalidate({
    __typename: 'SpeciesUserContentMedia',
    id: args.id as string,
  });
};

export default deleteSpeciesUserContentMedia;
