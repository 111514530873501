import { StyleSheet } from 'react-native';
import {
  CREATIVE_CONNECT_BACKGROUND_GRAY,
  SECTION_CONTAINER,
} from '/constants';

const styles = StyleSheet.create({
  creativeConnectOptionButton: {
    ...SECTION_CONTAINER,
    backgroundColor: CREATIVE_CONNECT_BACKGROUND_GRAY,
    borderRadius: 6,
    marginHorizontal: 0,
  },
  creativeConnectOptionButtonTitle: {
    marginBottom: 0,
    color: 'white',
  },
  creativeConnectOptionButtonSubtitle: {
    color: 'lightgray',
  },
  closeButton: {
    padding: 6,
  },
  title: {
    color: 'white',
    marginBottom: 0,
  },
  subtitle: {
    color: 'lightgray',
  },
});

export default styles;
