import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    // borderRadius: 6,
    borderColor: '#eee',
    borderWidth: 1,
    padding: 12,
  },
  alertTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  alertTitle: {
    marginLeft: 4,
    fontFamily: 'Lato-Bold',
    fontSize: 17,
  },
  alertText: {
    fontFamily: 'Lato',
    fontSize: 16,
  },
});
