import { View, Text, Pressable, ActivityIndicator } from 'react-native';
import React from 'react';
import { KEY_GRAY, SECTION_CONTAINER } from '/constants';
import DonationStatusWidget from '/components/DonationStatusWidget/DonationStatusWidget';
import { Ionicons } from '@expo/vector-icons';
import styles from './OrgImpactFunding.style';
import { useAuthContext } from '/context';
import useStripeBalance from '/hooks/useStripeBalance';
import { Image } from 'react-native';
import { getCurrencyFormatter } from '/util';
import { Currency, getDecimalPlacesForCurrency } from 'currency-decimal-places';
import { StackNavigationProp } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';
import ChevronRight from '/assets/jsicons/miscIcons/ChevronRight';

export default function OrgImpactFunding() {
  const { userData } = useAuthContext();

  return (
    <View style={SECTION_CONTAINER}>
      <View style={styles.headerContainer}>
        {/* Money icon */}
        <View style={styles.iconContainer}>
          <Ionicons name="cash" size={25} color={KEY_GRAY} />
        </View>
        <Text style={styles.headerText}>Funding</Text>
      </View>
      <DonationStatusWidget
        style={{
          marginHorizontal: 0,
          padding: 8,
        }}
      />

      {/* STRIPE BALANCE */}
      {userData?.stripe_cid ? <StripeBalance /> : null}
    </View>
  );
}

function StripeBalance() {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const stripeBalance = useStripeBalance();

  function onPress() {
    navigate('StripePayouts');
  }

  return (
    <Pressable
      onPress={onPress}
      style={({ pressed }) => [
        styles.balanceButton,
        {
          backgroundColor: pressed ? '#F2F2F2' : 'white',
        },
      ]}
    >
      <Image
        source={require('/assets/images/stripe-logo-purple.png')}
        style={styles.depositMethodButtonImage}
        resizeMode="contain"
      />
      <View
        style={{
          flex: 1,
          justifyContent: 'flex-end',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            alignItems: 'flex-end',
          }}
        >
          <Text style={styles.depositMethodBalanceText}>
            {stripeBalance.fetching ? (
              <ActivityIndicator
                size={20}
                color={KEY_GRAY}
                style={{
                  padding: 6,
                }}
              />
            ) : stripeBalance.error ? (
              '--'
            ) : (
              getCurrencyFormatter(stripeBalance.currency).format(
                stripeBalance.balance.total /
                  10 **
                    getDecimalPlacesForCurrency(
                      stripeBalance.currency as Currency,
                    ),
              )
            )}
          </Text>
          <Text style={styles.depositMethodBalanceLabel}>BALANCE</Text>
        </View>

        <ChevronRight />
      </View>
    </Pressable>
  );
}
