import { UpdateResolver } from '@urql/exchange-graphcache';

const createSharedDocument: UpdateResolver = (result, args, cache) => {
  const bucketId = (args as any).input.bucket.id;

  cache.invalidate({
    __typename: 'SharedContentBucket',
    id: bucketId,
  });
};

export default createSharedDocument;
