import React from 'react';
import { View } from 'react-native';
import ProfileCompletionWidget, {
  IProfileCompletionWidgetUser,
} from '../../ProfileCompletionWidget/ProfileCompletionWidget';
import { SECTION_CONTAINER } from '/constants';

interface Props {
  targetProfile: IProfileCompletionWidgetUser | undefined | null;
}

export default function ProfileScreenCompletionWidget(props: Props) {
  return (
    <View style={[SECTION_CONTAINER, { padding: 0, overflow: 'hidden' }]}>
      <ProfileCompletionWidget
        hideGoToProfileButton
        targetProfile={props.targetProfile}
      />
    </View>
  );
}
