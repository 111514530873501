import { StyleSheet } from 'react-native';
import { KEY_GRAY } from '/constants';

export default StyleSheet.create({
  titleText: {
    fontSize: 20,
    fontFamily: 'Lato-Bold',
  },
  bodyText: {
    fontSize: 16,
    letterSpacing: 0.1,
    fontFamily: 'Lato',
    color: KEY_GRAY,
  },
  bodyTextBold: {
    flex: 1,
    fontSize: 15,
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
  },
  linkPressable: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
