import { StyleSheet } from 'react-native';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles';
import { CARD_TITLE_FONT_SIZE, KEY_GRAY } from '/constants';

const styles = CreateResponsiveStyle(
  {
    overlayContainer: {
      flex: 1,
      ...StyleSheet.absoluteFillObject,
    },
    overlayInnerContainer: {
      flex: 1,
    },
    loadingIndicator: {
      borderRadius: 24,
      backgroundColor: 'white',
      padding: 16,
      paddingHorizontal: 20,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    cardText: {
      fontFamily: 'Lato',
      fontSize: 17,
    },
    cardTextBold: {
      fontFamily: 'Lato-Bold',
      fontSize: 17,
    },
    cardTitle: {
      fontFamily: 'Lato-Bold',
      fontSize: CARD_TITLE_FONT_SIZE,
    },
    resultsText: {
      fontFamily: 'Lato-Bold',
      fontSize: 16,
      color: KEY_GRAY,
    },
    screenHeaderContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 99,
    },
    mapDisabledText: {
      fontFamily: 'Lato-Bold',
      color: 'white',
      fontSize: 18,
      textAlign: 'center',
    },
  },
  {
    [minSize(DEVICE_SIZES.LARGE_DEVICE)]: {
      screenHeaderContainer: {
        maxWidth: 540,
        right: undefined,
      },
      overlayInnerContainer: {
        maxWidth: 540,
      },
    },
  },
);

export default styles;
