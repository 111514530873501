import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';
import { SkillRequestApplicationStatus } from '/generated/graphql';

const unselectApplicant: UpdateResolver = (result, args, cache) => {
  const fragment = gql`
    fragment UnselectApplicant on SkillRequestApplication {
      id
      status
      selected_at
    }
  `;

  cache.writeFragment(fragment, {
    id: args.id,
    selected_at: null,
    status: SkillRequestApplicationStatus.Submitted,
  });
};

export default unselectApplicant;
