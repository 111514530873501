import { Platform, StyleSheet, Text, View } from 'react-native';
import PlusSign from '/assets/jsicons/headerIcons/plusSign';
import PhotoVideo from '/assets/jsicons/miscIcons/PhotoVideo';
import { KEY_GREEN } from '/constants';

export default function AddNewMedia() {
  return (
    <View style={styles.container}>
      <View>
        <View style={styles.plusSignContainer}>
          <PlusSign fill={'#000'} width={12} height={12} />
        </View>
        <PhotoVideo fill={'#000'} width={72} height={72} />
      </View>
      {Platform.OS === 'web' && (
        <Text style={styles.promptText}>
          Drop photos and videos here to upload
        </Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  plusSignContainer: {
    backgroundColor: KEY_GREEN,
    borderRadius: 50,
    width: 24,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 1,
    right: -12,
    top: -12,
  },
  promptText: {
    fontFamily: 'Lato-Bold',
    marginTop: 12,
  },
});
