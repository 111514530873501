import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
  // in headerLeft
  backArrowTouch: {
    zIndex: 300,
    padding: 12,
    color: '#fff',
  },
  scrollBG: {
    backgroundColor: '#F2F2FB',
    paddingBottom: 10,
  },
  title: {
    color: 'crimson',
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    padding: 16,
    paddingBottom: 0,
  },
  sections: {
    margin: 10,
    padding: 4,
    marginBottom: 0,
    backgroundColor: '#fff',
    borderRadius: 5,
    fontSize: 15,
  },
  iconWrap: {
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: Dimensions.get('screen').width * 0.05,
    justifyContent: 'center',
  },
  linkWrap: {
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 8,
  },
  buttonIconContainer: {
    width: 56,
    backgroundColor: '#fff',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 5,
  },
  linkText: {
    fontSize: 20,
    fontFamily: 'LeagueSpartan-Bold',
    color: '#323338',
  },
  buttonContainer: {
    width: '30%',
    height: 30,
    marginTop: 10,
    flexDirection: 'column',
    flexWrap: 'nowrap',
    marginLeft: '35%',
    marginRight: '35%',
    borderRadius: 4,
    backgroundColor: '#0EE6A6',
    justifyContent: 'center',
  },
  buttonText: {
    fontFamily: 'Lato-Bold',
    letterSpacing: 0.8,
    textAlign: 'center',
    color: 'black',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
  },
  separator: {
    marginVertical: 4,
    width: '95%',
    alignSelf: 'center',
    height: 1,
    backgroundColor: '#ddd',
  },
  email: {
    fontFamily: 'Lato',
    color: 'gray',
    fontSize: 15,
  },
  versionText: {
    padding: 16,
    color: 'gray',
    alignSelf: 'center',
    fontFamily: 'Lato',
    fontSize: 14,
    textAlign: 'center',
  },
});

export default styles;
