import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';

const createCreativeConnectProjectBookmark: UpdateResolver = (
  result,
  args,
  cache,
) => {
  const fragment = gql`
    fragment AddCCBookmark on CreativeConnectProject {
      id
      is_bookmarked
    }
  `;

  const userSpecificFragment = gql`
    fragment UserSpecificAddCCBookmark on CreativeConnectProject {
      id
      is_bookmarked(userId: $userId)
    }
  `;

  cache.writeFragment(fragment, {
    id: args.projectId,
    is_bookmarked: true,
  });

  cache.writeFragment(
    userSpecificFragment,
    {
      id: args.projectId,
      is_bookmarked: true,
    },
    {
      userId: args.userId,
    },
  );
};

export default createCreativeConnectProjectBookmark;
