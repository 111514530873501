import {
  AntDesign,
  Feather,
  FontAwesome,
  FontAwesome5,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import {
  ActivityIndicator,
  Animated,
  Easing,
  Keyboard,
  Linking,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import Alert from '/Alert';
import Activity from '/assets/jsicons/Activity';
import Lightening from '/assets/jsicons/bottomnavigation/Lightening';
import Sync from '/assets/jsicons/bottomnavigation/Sync';
import Hoverable from '/components/Hoverable';
import { KEY_GRAY, KEY_YELLOW } from '/constants';
import { useAuthContext } from '/context';
import {
  useAddConnectionMutation,
  useFollowUserMutation,
  User,
  UserConnectionStatus,
  useRemoveConnectionMutation,
  UserProfileFragmentFragment,
  UserRole,
  useUnfollowUserMutation,
} from '/generated/graphql';
import { DeepPartial } from '/types';
import { formatURL, isValidURLRegex, shorten, truncateNumber } from '/util';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

/** This type defines the different sections users can navigate to using this navbar */
type OrganizationProfileSection =
  | 'about'
  | 'map'
  | 'species'
  | 'campaigns'
  | 'impacted_campaigns'
  | 'recent_activity'
  | 'skills';

interface IProfileNavbarProps {
  onScrollToSection: (sectionName: OrganizationProfileSection) => void;
  setData: (data: DeepPartial<User>) => void;
  scrollY: Animated.Value;
  isDragging: MutableRefObject<boolean>;
  isEditing: boolean;
  excludeSections: OrganizationProfileSection[];
  profile:
    | Pick<
        DeepPartial<UserProfileFragmentFragment>,
        | 'id'
        | 'follower_count'
        | 'is_following'
        | 'name'
        | 'role'
        | 'impacted_campaigns_current'
        | 'impacted_campaigns_past'
        | 'skills'
        | 'recent_activity'
        | 'connections_count'
        | 'connection'
        | 'donate_link'
      >
    | null
    | undefined;
}

const HIDE_NAVBAR_SCROLL_AMOUNT = 150;

const NAVBAR_MAX_TRANSLATEX_BASE = 32;
const NAVBAR_MIN_TRANSLATEX_BASE = 0;

export default function ProfileNavbar(props: IProfileNavbarProps) {
  const safeAreaInsets = useSafeAreaInsets();

  const NAVBAR_MAX_TRANSLATEX =
    NAVBAR_MAX_TRANSLATEX_BASE - safeAreaInsets.right;
  const NAVBAR_MIN_TRANSLATEX =
    NAVBAR_MIN_TRANSLATEX_BASE - safeAreaInsets.right;

  const translateX = useRef(new Animated.Value(0));

  const { push, navigate } = useNavigation<StackNavigationProp<any>>();

  const { userData } = useAuthContext();

  const [isNearTop, setIsNearTop] = useState(false);
  /** Used to allow user to tap-to-reveal the navbar even while there
   * is still some remaining scrolling momentum */
  const [showWhileScrolling, setShowWhileScrolling] = useState(false);

  const [navbarHidden, setNavbarHidden] = useState(false);

  const scrollDeviation = useRef(
    Animated.diffClamp(props.scrollY, 0, HIDE_NAVBAR_SCROLL_AMOUNT),
  );

  const hideNavbarAnimationRef = useRef(
    Animated.timing(translateX.current, {
      duration: 250,
      toValue: NAVBAR_MAX_TRANSLATEX,
      easing: Easing.out(Easing.poly(4)),
      useNativeDriver: true,
    }),
  );

  const showNavbarAnimationRef = useRef(
    Animated.timing(translateX.current, {
      toValue: NAVBAR_MIN_TRANSLATEX,
      duration: 120,
      useNativeDriver: true,
    }),
  );

  const [, followUser] = useFollowUserMutation();
  const [, unfollowUser] = useUnfollowUserMutation();

  const [{ fetching: removingConnection }, removeConnection] =
    useRemoveConnectionMutation();

  const [{ fetching: addingConnection }, _addConnection] =
    useAddConnectionMutation();

  function onUnfollowUser() {
    const userId = props.profile?.id;

    if (!userId) {
      console.warn('ProfileNavbar unfollowUser: user ID could not found');
      return;
    }

    Alert.alert(
      'Unfollow',
      `Are you sure you want to unfollow ${props.profile?.name}?`,
      [
        { text: 'Cancel', style: 'cancel' },
        {
          text: 'Unfollow',
          style: 'destructive',
          onPress: () => unfollowUser({ followingId: userId }),
        },
      ],
    );
  }

  useEffect(() => {
    if (isNearTop) showNavbar();
  }, [isNearTop]);

  useEffect(() => {
    const listenerId = props.scrollY.addListener(({ value: scrollYValue }) => {
      let _isNearTop = scrollYValue <= 40;

      // Check if we are near the top of the scroll range
      setIsNearTop(_isNearTop);

      /**
       * Decide whether or not we should hide the navbar
       */

      if (showWhileScrolling) {
        // If isDragging is true, reset showWhileScrolling so the navbar can hide again
        if (props.isDragging.current === true) setShowWhileScrolling(false);
        // if showWhileScrolling is set, we should not hide the navbar
        else return;
      }

      // If we are near the top, we want to keep the navbar shown.
      if (_isNearTop) return;

      // @ts-ignore - get animation value
      const value = scrollDeviation.current.__getValue();

      // If we hit boundaries of our scroll amount that means we have scrolled enough to hide the navbar
      if (value === 0 || value === HIDE_NAVBAR_SCROLL_AMOUNT) {
        hideNavbar();
      }
    });

    return () => {
      props.scrollY.removeListener(listenerId);
    };
  }, [props.scrollY, isNearTop, showWhileScrolling, props.isDragging]);

  const isHidingNavbar = useRef(false);
  const hideNavbar = () => {
    setNavbarHidden(true);
    if (isHidingNavbar.current) return;
    isHidingNavbar.current = true;
    hideNavbarAnimationRef.current.start(() => {
      isHidingNavbar.current = false;
    });
  };

  const isShowingNavbar = useRef(false);
  const showNavbar = () => {
    setNavbarHidden(false);
    if (isShowingNavbar.current) return;
    isShowingNavbar.current = true;
    showNavbarAnimationRef.current.start(() => {
      isShowingNavbar.current = false;
    });
  };

  const onNavbarAreaPressed = () => {
    if (navbarHidden) {
      // If we tap the navbar, show it

      // If there is still some scrolling momentum, this will prevent
      // the navbar from hiding immediately after being shown
      setShowWhileScrolling(true);

      showNavbar();
      return;
    }
  };

  const onButtonPressed = (sectionName: OrganizationProfileSection) => {
    Keyboard.dismiss(); // Keyboard might me open, we want it to close here
    props.onScrollToSection(sectionName);
    hideNavbar();
  };

  const onFollowButtonPressed = () => {
    if (!props.profile?.id) return;

    if (props.profile?.is_following) {
      onUnfollowUser();
    } else followUser({ userId: props.profile.id });
  };

  const onRespondToConnectionRequest = () => {
    Alert.alert(
      'Accept Connection?',
      `${shorten(
        props.profile?.name ?? 'This person',
        28,
      )} has sent you a connection request. They won't be notified if you decline.`,
      [
        {
          text: 'Confirm',
          style: 'default',
          onPress: () =>
            props.profile?.id &&
            _addConnection({
              userId: props.profile?.id,
            }),
        },
        {
          text: 'Decline',
          style: 'destructive',
          onPress: () => {
            if (props.profile?.connection?.id) {
              removeConnection({
                connectionId: props.profile.connection.id,
              });
            }
          },
        },
        {
          text: 'Ignore',
          style: 'cancel',
        },
      ],
    );
  };

  const onConnectButtonPressed = () => {
    // If connection exists and is confirmed, or it exists and we are the initiator, remove connection
    if (
      props.profile?.connection?.status === UserConnectionStatus.Confirmed ||
      props.profile?.connection?.initiator?.id === userData?.id
    ) {
      onRemoveConnection();
    } else {
      // In all other cases, add connection
      if (props.profile?.id) _addConnection({ userId: props.profile.id });
    }
  };

  function onRemoveConnection() {
    const remove = async () => {
      if (!props.profile?.connection?.id) {
        throw new Error('Connection ID cannot be found');
      }

      try {
        const { error } = await removeConnection({
          connectionId: props.profile.connection.id,
        });

        if (error) throw error;
      } catch (error) {
        console.log('Connection not removed, Error:', error);
      }
    };
    Alert.alert(
      'Remove Connection',
      'Are you sure you want to remove this connection?',
      [
        { text: 'Remove', style: 'destructive', onPress: remove },
        { text: 'Cancel', style: 'cancel' },
      ],
    );
  }

  function goToBookmarks() {
    navigate('Bookmarks');
  }

  function onEditDonateLink() {
    Alert.alert(
      `${props.profile?.donate_link?.trim() ? 'Edit' : 'Add'} donation link`,
      'Add a link that people can visit to donate to your organization.',
      [
        {
          text: 'Done',
          style: 'default',
          onPress: (e) => {
            const value = e.values.link?.trim() ?? '';

            const isValid = !value || isValidURLRegex(value);

            if (!isValid) {
              e.preventDefault();
              Alert.alert(
                'Invalid URL',
                'Please make sure you have entered your URL correctly',
              );
              return;
            }

            if (value)
              props.setData({
                donate_link: value,
              });
          },
        },
      ],
      [
        {
          name: 'link',
          initialValue: props.profile?.donate_link,
          placeholder: 'www.example.com/donate',
          maxLength: 280,
        },
      ],
    );
  }

  const onViewConnections = () => {
    push('Connections', { userId: props.profile?.id });
  };

  const opacity = useMemo(
    () =>
      translateX.current.interpolate({
        inputRange: [NAVBAR_MIN_TRANSLATEX, NAVBAR_MAX_TRANSLATEX],
        outputRange: [1, 0.7],
      }),
    [NAVBAR_MAX_TRANSLATEX, NAVBAR_MIN_TRANSLATEX],
  );

  const wideButtonTranslateX = useMemo(
    () =>
      translateX.current.interpolate({
        inputRange: [NAVBAR_MIN_TRANSLATEX, NAVBAR_MAX_TRANSLATEX],
        outputRange: [-25, 25],
      }),
    [NAVBAR_MAX_TRANSLATEX, NAVBAR_MIN_TRANSLATEX],
  );

  const donateButtonTranslateX = useMemo(
    () =>
      translateX.current.interpolate({
        inputRange: [NAVBAR_MIN_TRANSLATEX, NAVBAR_MAX_TRANSLATEX],
        outputRange: [-40, 40],
      }),
    [NAVBAR_MAX_TRANSLATEX, NAVBAR_MIN_TRANSLATEX],
  );

  return (
    <Animated.View
      pointerEvents={'box-none'}
      style={[
        styles.container,
        {
          opacity,
          transform: [{ translateX: translateX.current }],
        },
      ]}
    >
      <Hoverable onHoverIn={showNavbar}>
        <View
          pointerEvents="box-none"
          style={{
            padding: 8,
            paddingVertical: 24,
          }}
        >
          <Pressable
            disabled={!navbarHidden}
            onPress={onNavbarAreaPressed}
            style={styles.showNavbarTouchArea}
          />
          <View pointerEvents={navbarHidden ? 'none' : 'auto'}>
            {/* Follow Button (Organizations Only) */}
            {/* Show this button only if:
              - We are signed in
              - We are not looking at our own profile
              - The profile we are looking at is an organization profile */}
            {!!userData?.id &&
            props.profile?.role === UserRole.Conservationist &&
            props.profile?.id &&
            props.profile.id !== userData?.id ? (
              <>
                <Pressable
                  onPress={() => onFollowButtonPressed()}
                  style={styles.button}
                >
                  {props.profile?.is_following ? (
                    <View
                      style={{
                        borderRadius: 28,
                        width: 28,
                        height: 28,
                        backgroundColor: KEY_YELLOW,
                        overflow: 'hidden',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <AntDesign name="check" size={18} color="black" />
                    </View>
                  ) : (
                    <AntDesign name="pluscircle" size={28} color={'#bbb'} />
                  )}
                </Pressable>
              </>
            ) : null}

            {/* Connect Button (Supporters Only) */}
            {props.profile?.role === UserRole.Supporter ? (
              <Animated.View
                style={{
                  alignSelf: 'center',
                  transform: [
                    {
                      translateX: wideButtonTranslateX,
                    },
                  ],
                }}
              >
                <View pointerEvents="none" style={styles.transparentSpacer} />
                <Pressable
                  onPress={() => onViewConnections()}
                  style={[styles.button, styles.wideButton]}
                >
                  <View
                    style={{
                      flex: 1,
                      flexDirection: 'row',
                      alignItems: 'center',
                      alignSelf: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text style={styles.connectButtonLabel}>
                      {truncateNumber(props.profile.connections_count || 0, 99)}
                    </Text>

                    <FontAwesome5 name="user-alt" size={20} color={KEY_GRAY} />
                  </View>

                  <Pressable
                    onPress={onConnectButtonPressed}
                    style={({ pressed }) => ({
                      opacity: pressed ? 0.7 : 1,
                      /** Hide the connect button if:
                       *  - We are not signed in, OR
                       *  - We are viewing our own profile, OR
                       *  - We are an organization */
                      display:
                        !userData?.id ||
                        props.profile?.id === userData?.id ||
                        userData?.role !== UserRole.Supporter
                          ? 'none'
                          : 'flex',
                    })}
                  >
                    {addingConnection || removingConnection ? (
                      <ActivityIndicator size={28} color={KEY_GRAY} />
                    ) : props.profile?.connection?.status ===
                      UserConnectionStatus.Confirmed ? (
                      <View
                        style={{
                          borderRadius: 28,
                          width: 28,
                          height: 28,
                          backgroundColor: KEY_YELLOW,
                          overflow: 'hidden',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <AntDesign name="check" size={18} color="black" />
                      </View>
                    ) : props.profile?.connection?.status ===
                        UserConnectionStatus.Requested &&
                      props.profile?.connection?.initiator?.id ===
                        userData?.id ? (
                      <MaterialCommunityIcons
                        name="clock-check"
                        size={28}
                        color="gray"
                      />
                    ) : props.profile?.connection?.status ===
                        UserConnectionStatus.Requested &&
                      props.profile.connection.initiator?.id ===
                        props.profile.id ? (
                      <Pressable
                        onPress={onRespondToConnectionRequest}
                        style={{
                          backgroundColor: KEY_YELLOW,
                          width: 28,
                          height: 28,
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: 14,
                        }}
                      >
                        <FontAwesome5 name="clock" size={24} color="black" />
                      </Pressable>
                    ) : (
                      <AntDesign name="pluscircle" size={28} color={'#bbb'} />
                    )}
                  </Pressable>
                </Pressable>
              </Animated.View>
            ) : null}

            {!!userData?.id &&
              props.profile?.id &&
              userData?.id !== props.profile?.id && (
                <>
                  <View pointerEvents="none" style={styles.transparentSpacer} />
                  <Pressable
                    style={styles.button}
                    onPress={() => {
                      navigate('DirectMessageScreen', {
                        userId: props.profile?.id,
                      });
                    }}
                  >
                    <MaterialIcons name="message" size={22} color={KEY_GRAY} />
                  </Pressable>
                </>
              )}

            <View
              pointerEvents="none"
              style={[
                styles.centerLine,
                {
                  backgroundColor: 'transparent',
                },
              ]}
            />

            {/* Impacted Campaigns Buttons (Supporter only) */}
            {props.profile?.role === UserRole.Supporter &&
            (props.profile.impacted_campaigns_current?.items?.length ?? 0) +
              (props.profile.impacted_campaigns_past?.items?.length ?? 0) ? (
              <>
                <Pressable
                  onPress={() => onButtonPressed('impacted_campaigns')}
                  style={styles.button}
                >
                  <Lightening />
                </Pressable>
                <View pointerEvents="none" style={styles.centerLine} />
              </>
            ) : null}
            {/* About Button */}
            <Pressable
              onPress={() => onButtonPressed('about')}
              style={styles.button}
            >
              <Ionicons
                name="information-circle-outline"
                size={36}
                style={{
                  paddingTop: 1,
                  paddingLeft: 1,
                }}
                color={KEY_GRAY}
              />
            </Pressable>
            {/* Species Button (Organizations Only) */}
            {!props.excludeSections?.includes('species') &&
            props.profile?.role === UserRole.Conservationist ? (
              <>
                <View pointerEvents="none" style={styles.centerLine} />
                <Pressable
                  onPress={() => onButtonPressed('species')}
                  style={styles.button}
                >
                  <Ionicons
                    name="paw"
                    size={28}
                    style={{
                      paddingTop: 1,
                      paddingLeft: 1,
                    }}
                    color={KEY_GRAY}
                  />
                </Pressable>
              </>
            ) : null}
            {/* Campaigns Button (Organizations only) */}
            {props.profile?.role === UserRole.Conservationist ? (
              <>
                <View pointerEvents="none" style={styles.centerLine} />
                <Pressable
                  onPress={() => onButtonPressed('campaigns')}
                  style={styles.button}
                >
                  <Lightening />
                </Pressable>
              </>
            ) : null}
            {/* Recent Activity Button (Supporters only) */}
            {props.profile?.role === UserRole.Supporter &&
            props.profile.recent_activity?.length ? (
              <>
                <View pointerEvents="none" style={styles.centerLine} />
                <Pressable
                  onPress={() => onButtonPressed('recent_activity')}
                  style={styles.button}
                >
                  <Activity width={28} height={28} />
                </Pressable>
              </>
            ) : null}
            {/* Skills Button (Supporters only) */}
            {props.profile?.role === UserRole.Supporter &&
            props.profile.skills?.length ? (
              <>
                <View pointerEvents="none" style={styles.centerLine} />
                <Pressable
                  onPress={() => onButtonPressed('skills')}
                  style={styles.button}
                >
                  <Sync width={27} height={27} />
                </Pressable>
              </>
            ) : null}

            {/* Bookmarks Button (Own profile only) */}
            {props.profile?.id === userData?.id ? (
              <>
                <View pointerEvents="none" style={styles.centerLine} />
                <Pressable
                  onPress={() => goToBookmarks()}
                  style={styles.button}
                >
                  <Feather name="bookmark" size={24} color="black" />
                </Pressable>
              </>
            ) : null}

            {/* Donate Button (Organizations only) */}
            {props.profile?.role === UserRole.Conservationist &&
            (props.profile.donate_link || props.isEditing) ? (
              <Animated.View
                style={{
                  alignSelf: 'center',
                  transform: [
                    {
                      translateX: donateButtonTranslateX,
                    },
                  ],
                }}
              >
                <View
                  pointerEvents="none"
                  style={[
                    styles.centerLine,
                    { backgroundColor: 'transparent' },
                  ]}
                />
                <DonateButton
                  isEditing={props.isEditing}
                  donate_link={props.profile.donate_link || undefined}
                  onEdit={onEditDonateLink}
                />
              </Animated.View>
            ) : null}
          </View>
        </View>
      </Hoverable>
    </Animated.View>
  );
}

interface DonateButtonProps {
  isEditing: boolean;
  donate_link: string | undefined;
  onEdit: () => void;
}

function DonateButton(props: DonateButtonProps) {
  function openLink() {
    if (!props.donate_link) return;

    Alert.alert(
      'Leave Platform?',
      `Donate links are provided by the organization and are not affiliated with the Key Conservation platform.\nYou will be redirected to:\n${shorten(
        props.donate_link.toLowerCase(),
        120,
      )}`,
      [
        {
          text: 'Cancel',
        },
        {
          text: 'Open Link',
          style: 'default',
          onPress: () =>
            Linking.openURL(
              formatURL(props.donate_link!),
              // @ts-ignore
              '__blank',
            ),
        },
      ],
    );
  }

  return (
    <Pressable
      onPress={() => !props.isEditing && props.donate_link && openLink()}
      style={[
        styles.button,
        styles.wideButton,
        {
          width: 120,
          backgroundColor: KEY_YELLOW,
          alignItems: 'center',
          ...Platform.select({
            web: {
              cursor: props.isEditing ? ('default' as any) : 'pointer',
            },
          }),
        },
      ]}
    >
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          alignSelf: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={styles.donateButtonLabel}>DONATE</Text>
      </View>

      <Pressable
        onPress={props.onEdit}
        style={({ pressed }) => ({
          opacity: pressed ? 0.7 : 1,
          /** Only show edit button if we are editing */
          display: !props.isEditing ? 'none' : 'flex',
        })}
      >
        {props.donate_link ? (
          <View
            style={{
              borderRadius: 28,
              width: 28,
              height: 28,
              backgroundColor: 'white',
              overflow: 'hidden',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FontAwesome
              name="pencil"
              size={18}
              style={{
                paddingHorizontal: 4,
              }}
              color={KEY_GRAY}
            />
          </View>
        ) : (
          <AntDesign name="pluscircle" size={28} color={KEY_GRAY} />
        )}
      </Pressable>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    right: 8,
    bottom: 0,
    width: 48,
    zIndex: 10,
    overflow: 'visible',
    justifyContent: 'center',
  },
  showNavbarTouchArea: {
    position: 'absolute',
    top: -2,
    left: -10,
    right: 0,
    bottom: -2,
  },
  centerLine: {
    height: 40,
    alignSelf: 'center',
    width: 2,
    backgroundColor: '#fff',
    zIndex: 1,
    shadowOpacity: 0.4,
    shadowColor: KEY_GRAY,
  },
  transparentSpacer: {
    height: 14,
  },
  button: {
    backgroundColor: 'white',
    borderWidth: 1,
    // Shadow not appearing properly on web, so we use a light border instead
    borderColor: Platform.OS === 'web' ? '#eee' : 'transparent',
    alignSelf: 'center',
    width: 40,
    height: 40,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    shadowOpacity: 0.4,
    shadowOffset: {
      height: 0,
      width: 0,
    },
    elevation: 2,
    shadowColor: KEY_GRAY,
  },
  wideButton: {
    width: 90,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 8,
  },
  connectButtonLabel: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    fontSize: 18,
    paddingHorizontal: 4,
  },
  donateButtonLabel: {
    fontFamily: 'LeagueSpartan-Bold',
    paddingTop: 3,
    fontSize: 16,
    paddingHorizontal: 6,
  },
});
