import React from 'react';
import { StyleProp, Text, TextProps, TextStyle } from 'react-native';
import { CONTENT_PARAGRAPH_FONT_SIZE } from '/constants';

interface Props extends TextProps {
  style?: StyleProp<TextStyle>;
  onPress?: () => void;
}

export default function SectionText({
  onPress,
  style,
  children,
  ...rest
}: React.PropsWithChildren<Props>) {
  return (
    <Text {...rest} onPress={onPress} style={[baseStyle, style]}>
      {children}
    </Text>
  );
}

const baseStyle: TextStyle = {
  fontFamily: 'Lato',
  fontSize: CONTENT_PARAGRAPH_FONT_SIZE,
};
