import { AntDesign } from '@expo/vector-icons';
import { ResizeMode } from 'expo-av';
import React, { useCallback, useEffect, useState } from 'react';
import { LayoutChangeEvent, StyleSheet, View } from 'react-native';
import {
  PanGestureHandler,
  PanGestureHandlerGestureEvent,
} from 'react-native-gesture-handler';
import Animated, {
  Easing,
  runOnJS,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import MediaFlatList from '/components/MediaFlatList/MediaFlatList';
import MediaItem from '/components/MediaViewer/elements/MediaItem';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Platform } from 'react-native';

interface IFullScreenMediaViewerContext {
  showMedia: (options: ShowMediaOptions) => void;
}

type SharedTransitionStart = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type ShowMediaOptions = {
  sourceUris: string[];
  onClose?: () => void;
  initialIndex?: number;
  useOriginalSource?: boolean;
  transitionStart?: SharedTransitionStart;
};

const FullScreenMediaViewerContext = React.createContext(
  {} as IFullScreenMediaViewerContext,
);

export const useFullscreenMediaViewer = () => {
  return React.useContext(FullScreenMediaViewerContext);
};

export default function FullscreenMediaViewerProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const safeAreaInsets = useSafeAreaInsets();

  const [sourceUris, setSourceUris] = useState<string[]>([]);
  const [initialIndex, setInitialIndex] = useState(0);
  const [useOriginalSource, setUseOriginalSource] = useState(false);
  const [onCloseCallback, setOnCloseCallback] = useState<(() => void) | null>(
    null,
  );

  const [viewportWidth, setViewportWidth] = useState(0);
  const [viewportHeight, setViewportHeight] = useState(0);

  const opacity = useSharedValue(0);
  const translateY = useSharedValue(0);

  useEffect(() => {
    if (sourceUris.length) {
      opacity.value = withTiming(1, {
        duration: 200,
        easing: Easing.in(Easing.poly(4)),
      });
    }
  }, [opacity, sourceUris]);

  const showMedia = useCallback((options: ShowMediaOptions) => {
    setSourceUris(options.sourceUris);
    setInitialIndex(options.initialIndex ?? 0);
    setUseOriginalSource(options.useOriginalSource ?? false);
    setOnCloseCallback(options.onClose ?? null);
  }, []);

  const onClose = useCallback(
    function () {
      opacity.value = withTiming(0, {
        duration: 100,
        easing: Easing.in(Easing.poly(4)),
      });
      translateY.value = 0;
      setSourceUris([]);
      setInitialIndex(0);
      setUseOriginalSource(false);
      onCloseCallback?.();
      setOnCloseCallback(null);
    },
    [onCloseCallback, opacity, translateY],
  );

  function onViewportLayout(event: LayoutChangeEvent) {
    setViewportWidth(event.nativeEvent.layout.width);
    setViewportHeight(event.nativeEvent.layout.height);
  }

  const gestureHandler = useAnimatedGestureHandler<
    PanGestureHandlerGestureEvent,
    {
      startY: number;
    }
  >(
    {
      onStart: (_, ctx) => {
        ctx.startY = translateY.value;
      },
      onActive: (event, ctx) => {
        translateY.value = Math.max(0, ctx.startY + event.translationY);

        /** Reduce opacity the farther down we drag */
        opacity.value = Math.max(
          0,
          1 - Math.max(event.translationY / viewportHeight, 0),
        );
      },
      onEnd: (event) => {
        const velocityThreshold = Platform.OS === 'web' ? 2 : 1000;

        if (
          event.translationY > viewportHeight / 2 ||
          event.velocityY > velocityThreshold
        ) {
          translateY.value = withTiming(
            Math.floor(viewportHeight * 2),
            {
              duration: 100,
              easing: Easing.in(Easing.poly(4)),
            },
            () => {
              opacity.value = 0;
              runOnJS(onClose)();
            },
          );
        } else {
          opacity.value = withTiming(1, {
            duration: 100,
            easing: Easing.out(Easing.poly(4)),
          });
          translateY.value = withTiming(0, {
            duration: 200,
            easing: Easing.out(Easing.poly(4)),
          });
        }
      },
    },
    [viewportHeight, translateY, opacity, onClose],
  );

  const animatedContainerStyle = useAnimatedStyle(() => ({
    opacity: opacity.value,
    transform: [
      {
        translateY: translateY.value,
      },
    ],
  }));

  return (
    <FullScreenMediaViewerContext.Provider
      value={{
        showMedia,
      }}
    >
      {children}

      <PanGestureHandler
        // @ts-ignore
        onBegan={Platform.OS === 'web' ? gestureHandler : undefined}
        // @ts-ignore
        onEnded={Platform.OS === 'web' ? gestureHandler : undefined}
        onGestureEvent={gestureHandler}
        enabled={sourceUris.length > 0}
      >
        <Animated.View
          pointerEvents={sourceUris.length > 0 ? 'auto' : 'none'}
          style={[
            styles.container,
            animatedContainerStyle,
            {
              paddingTop: safeAreaInsets.top,
              paddingRight: safeAreaInsets.right,
              paddingLeft: safeAreaInsets.left,
              paddingBottom: safeAreaInsets.bottom,
            },
          ]}
        >
          <View
            style={{
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <AntDesign
              name="close"
              size={28}
              color="white"
              onPress={onClose}
              style={styles.closeButton}
            />
          </View>
          <View style={styles.viewport} onLayout={onViewportLayout}>
            <MediaFlatList
              horizontal
              showsHorizontalScrollIndicator={false}
              decelerationRate="fast"
              snapToAlignment="start" // Align items to the start of the FlatList
              getItemLayout={(data, index) => ({
                length: viewportWidth,
                offset: viewportWidth * index,
                index,
              })} // Helps with performance
              data={sourceUris}
              initialScrollIndex={initialIndex}
              snapToInterval={viewportWidth}
              renderItem={({ item, isViewable }) => {
                return (
                  <View
                    style={{
                      width: viewportWidth,
                      height: viewportHeight,
                    }}
                  >
                    <MediaItem
                      key={item}
                      shouldPlayVideo={isViewable}
                      uri={item}
                      style={{
                        flex: 1,
                        width: viewportWidth,
                        height: '100%',
                      }}
                      targetCDNMediaHeight={viewportHeight}
                      targetCDNMediaWidth={viewportWidth}
                      useOriginalSource={useOriginalSource}
                      hideErrorRetryButton={false}
                      resizeMode={ResizeMode.CONTAIN}
                    />
                  </View>
                );
              }}
            />
          </View>
        </Animated.View>
      </PanGestureHandler>
    </FullScreenMediaViewerContext.Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(32,32,32,0.9)',
    zIndex: 100,
  },
  viewport: {
    flex: 1,
    backgroundColor: '#222',
  },
  closeButton: { padding: 24 },
});
