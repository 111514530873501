import React, { useCallback, useState } from 'react';
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  Pressable,
  View,
} from 'react-native';
import CreativeConnectFilter from '../CreativeConnectFilter/CreativeConnectFilter';
import CreativeConnectProjectGridList from '../CreativeConnectProjectGridList/CreativeConnectProjectGridList';
import styles from './common.style';
import X from '/assets/jsicons/miscIcons/X';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import ScrollView from '/components/common/ScrollView/ScrollView';
import SectionText from '/components/common/Section/SectionText';
import SectionTitle from '/components/common/Section/SectionTitle';
import SearchBar from '/components/SearchBar/SearchBar';
import {
  CREATIVE_CONNECT_BACKGROUND,
  CREATIVE_CONNECT_BACKGROUND_GRAY,
} from '/constants';
import { CreativeConnectProjectFilter, UserRole } from '/generated/graphql';
import useDebouncedState from '/hooks/useDebouncedState';
import { isCloseToBottom } from '/util';
import { useAuthContext, useTeamContext } from '/context';
import { StackNavigationProp } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';

type Props = {
  onRequestClose: () => void;
};

export default function ListEditCreativeConnectProjectsModal(props: Props) {
  const { userData } = useAuthContext();
  const { teams } = useTeamContext();

  const navigation = useNavigation<StackNavigationProp<any>>();

  const [fetching, setFetching] = useState(false);
  const [endReached, setEndReached] = useState(false);
  const [query, queryDebounced, setQuery] = useDebouncedState('');

  const [filter, setFilter] = useState<CreativeConnectProjectFilter>({
    created_byId: userData?.id,
  });

  const onScroll = useCallback(
    ({ nativeEvent }: NativeSyntheticEvent<NativeScrollEvent>) => {
      setEndReached(isCloseToBottom(nativeEvent));
    },
    [],
  );

  return (
    <>
      <View
        style={{
          backgroundColor: CREATIVE_CONNECT_BACKGROUND_GRAY,
        }}
      >
        <HorizontalContainer
          style={{
            justifyContent: 'space-between',
          }}
        >
          <SectionTitle style={styles.title}>SELECT PROJECT</SectionTitle>
          <Pressable onPress={props.onRequestClose} style={styles.closeButton}>
            <X fill="white" />
          </Pressable>
        </HorizontalContainer>

        <SectionText style={styles.subtitle}>
          Select a project to update
        </SectionText>
      </View>

      <ScrollView
        style={{
          backgroundColor: CREATIVE_CONNECT_BACKGROUND_GRAY,
        }}
        scrollEventThrottle={100}
        onScroll={onScroll}
        stickyHeaderHiddenOnScroll
        stickyHeaderIndices={[0]}
      >
        <View
          style={{
            backgroundColor: CREATIVE_CONNECT_BACKGROUND_GRAY,
          }}
        >
          <SearchBar
            containerStyle={{
              backgroundColor: CREATIVE_CONNECT_BACKGROUND,
              marginHorizontal: 0,
            }}
            tintColor="white"
            value={query}
            loading={fetching}
            onChangeText={setQuery}
            placeholder="Search projects..."
          />
          {userData?.role === UserRole.Supporter && teams?.length > 0 ? (
            <CreativeConnectFilter
              filter={filter}
              setFilter={setFilter}
              showFilters={{
                createdBy: 'self-and-authorized-teams-only',
                status: false,
                acceptingApplications: false,
                creativeType: false,
                species: false,
              }}
              containerStyle={{
                paddingHorizontal: 0,
              }}
            />
          ) : null}
        </View>

        <CreativeConnectProjectGridList
          theme="dark"
          onFetchingChanged={setFetching}
          endReached={endReached}
          filter={{
            ...filter,
            query: queryDebounced,
          }}
          onSelectProject={(project) => {
            navigation.navigate('EditCreativeConnectProject', {
              id: project.id,
            });
            props.onRequestClose();
          }}
        />
      </ScrollView>
    </>
  );
}
