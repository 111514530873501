import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
import { Text, View } from 'react-native';
import ProjectGoalsFormContent from './ProjectGoalsFormContent';
import FormList from '/components/FormList';
import ValidatedAny from '/components/ValidatedForm/ValidatedAny';
import { ValidatedFormFields } from '/components/ValidatedForm/ValidatedForm';
import { REQUIRED_INPUT_INVALID } from '/constants';
import styles from '/constants/CampaignBuilder/SkillImpact/SkillExpand';
import { SkillRequestGoal } from '/generated/graphql';

interface IProjectGoalsFormProps {
  projectGoals: SkillRequestGoal[];
  fields: ValidatedFormFields;
  onChangeProjectGoals: (newProjectGoals: SkillRequestGoal[]) => void;
}

const ProjectGoalsForm = (props: IProjectGoalsFormProps) => {
  return (
    <ValidatedAny
      name="project_goals"
      value={props.projectGoals}
      validate={(value) => {
        return !!value?.length;
      }}
    >
      <View
        style={[
          styles.itemContainers,
          props.fields.project_goals?.valid == false
            ? REQUIRED_INPUT_INVALID
            : null,
        ]}
      >
        <View style={styles.itemContentRows}>
          <Text style={styles.itemContentTitle}>Project Goals</Text>
        </View>
        <View style={styles.itemContentRows}>
          <Text style={styles.itemBodyText}>
            What are the goals you want to accomplish while working with this
            skilled professional? Give each goal a title and fill out the exact
            details of what you want achieved. Be as clear and precise as
            possible.
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={[styles.leftLineAddGoal, { height: '100%' }]} />
          <FormList
            placeholder="Add another Project Goal"
            data={props.projectGoals}
            containerStyle={{ flex: 1 }}
            renderIcon={() => (
              <MaterialCommunityIcons
                name="bullseye-arrow"
                size={33}
                color="#000"
              />
            )}
            renderForm={({ item, onChange, index }) => {
              return (
                <ProjectGoalsFormContent
                  key={index}
                  index={index}
                  data={item}
                  onChangeContent={(content) => onChange(content)}
                />
              );
            }}
            onChange={(projectGoals) =>
              props.onChangeProjectGoals(projectGoals)
            }
          />
        </View>
        <Text
          style={{
            display:
              props.fields.project_goals?.valid === false ? 'flex' : 'none',
            fontFamily: 'Lato-Bold',
            color: 'crimson',
            paddingTop: 8,
          }}
        >
          Please add at least one project goal
        </Text>
      </View>
    </ValidatedAny>
  );
};
export default ProjectGoalsForm;
