import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useEffect, useState } from 'react';
import { FlatList, StyleProp, View } from 'react-native';
import GenericError from '../common/Generic/GenericError';
import GenericListFooter from '../GenericListFooter';
import NewsPostCard from '../NewsPostCard/NewsPostCard';
import CampaignBlankSpace from '../Profile/CampaignBlankSpace';
import {
  ListNewsPostsFilterInput,
  ListNewsPostsQuery,
  useListNewsPostsQuery,
} from '/generated/graphql';
import { ViewStyle } from 'react-native';

interface Props {
  filter: ListNewsPostsFilterInput;
  contentContainerStyle?: StyleProp<ViewStyle>;
  itemStyle?: StyleProp<ViewStyle>;
  onLoad?: (data: ListNewsPostsQuery) => void;
  onFetchingChanged?: (fetching: boolean) => void;
}

export default function NewsPostFlatList({
  onLoad,
  onFetchingChanged,
  ...props
}: Props) {
  const navigation = useNavigation<StackNavigationProp<any>>();

  const [nextToken, setNextToken] = useState<string>();

  const [{ data, fetching, error, stale }, refetch] = useListNewsPostsQuery({
    variables: {
      nextToken,
      filter: props.filter,
    },
  });

  const fetchMore = () => {
    if (!data?.listNewsPosts.nextToken) return;
    setNextToken(data?.listNewsPosts.nextToken);
  };

  useEffect(() => {
    if (data) {
      onLoad?.(data);
    }
  }, [onLoad, data]);

  useEffect(() => {
    onFetchingChanged?.(fetching);
  }, [fetching, onFetchingChanged]);

  return (
    <FlatList
      data={data?.listNewsPosts.items ?? []}
      onEndReached={fetchMore}
      contentContainerStyle={props.contentContainerStyle}
      ListEmptyComponent={
        fetching ? null : error ? (
          <GenericError onRetry={refetch} />
        ) : (
          <View
            style={{
              marginHorizontal: 8,
            }}
          >
            <CampaignBlankSpace placeholderText="No news posts" />
          </View>
        )
      }
      ListFooterComponent={
        <GenericListFooter
          hasMore={!!data?.listNewsPosts.nextToken}
          loading={fetching || stale}
          isFirstPage={!nextToken}
          onFetchMore={fetchMore}
        />
      }
      renderItem={({ item }) => (
        <NewsPostCard
          data={item}
          style={props.itemStyle}
          onPress={() => {
            navigation.navigate('ViewNewsPost', {
              id: item.id,
            });
          }}
        />
      )}
    />
  );
}
