import { RouteProp, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useEffect, useRef } from 'react';
import {
  Image,
  ImageBackground,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';
import withAuthRequired from '/components/withAuthRequired';
import { KEY_YELLOW } from '/constants';
import PendingStyles from '/constants/screens/org-onboarding-styles/ThankYouScreen.js';
import { useAuthContext } from '/context';
import { SystemSurveyPlacement } from '/generated/graphql';
import { DataSurveyStackParamList } from '/navigation/navigators/nested/DataSurveyStack';

export type DataSurveyScreenProps = {
  route: RouteProp<DataSurveyStackParamList>;
  navigation: StackNavigationProp<DataSurveyStackParamList>;
};

interface IOrgDataSurveySetupProps {
  navigation: StackNavigationProp<DataSurveyStackParamList, 'DataQ'>;
  route: RouteProp<DataSurveyStackParamList, 'DataQ'>;
}

function OrgDataSurveySetup(props: IOrgDataSurveySetupProps) {
  const { userData } = useAuthContext();
  const userDataRef = useRef(userData);

  const { width: window_width, height: window_height } = useWindowDimensions();

  const circleSize = Math.min(window_width, window_height);

  const { getParent } = useNavigation();

  useEffect(() => {
    userDataRef.current = userData;
  }, [userData]);

  function onNext() {
    const navigation: any = getParent() || props.navigation;

    navigation.goBack();
    navigation.navigate('TakeSurvey' as any, {
      placement: SystemSurveyPlacement.OrganizationOnboardingApproval,
    });
  }

  return (
    <View style={{ flex: 1 }}>
      <ImageBackground
        source={require('../assets/images/loading-pangolin.png')}
        style={StyleSheet.absoluteFillObject}
      >
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <View
            style={[
              PendingStyles.obBody,
              {
                marginTop: 48,
                width: circleSize * 0.95,
                height: circleSize * 0.95,
                maxWidth: 420,
                maxHeight: 420,
              },
            ]}
          >
            <View style={PendingStyles.titleTexts}>
              <Image
                style={{
                  width: 120,
                  height: 120,
                }}
                source={require('/assets/images/keyFullWhite.png')}
              />
              <Text
                style={[
                  PendingStyles.obTitle,
                  { fontSize: 36, marginTop: 0, lineHeight: 36 },
                ]}
              >
                YOU'VE BEEN APPROVED!
              </Text>
              <Text
                style={[
                  PendingStyles.obText,
                  { fontSize: 20, fontFamily: 'Lato' },
                ]}
              >
                Please click the next button to fill out the necessary remaining
                information.
              </Text>
              <TouchableOpacity
                onPress={onNext}
                style={[
                  PendingStyles.buttonTouch,
                  {
                    marginTop: 16,
                    backgroundColor: KEY_YELLOW,
                    width: 120,
                    paddingHorizontal: 24,
                    height: 42,
                  },
                ]}
              >
                <Text style={[PendingStyles.buttonText, { fontSize: 20 }]}>
                  Next
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </ImageBackground>
    </View>
  );
}

export default withAuthRequired(OrgDataSurveySetup);
