import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';

const addLike: UpdateResolver = (result, args, cache) => {
  const fragment = gql`
    fragment AddLike on PostInteractions {
      id
      likes
      is_liked
    }
  `;

  const userSpecificFragment = gql`
    fragment UserSpecificAddLike on PostInteractions {
      id
      likes
      is_liked(userId: $userId)
    }
  `;

  const data: any = cache.readFragment(fragment, {
    id: args.interactionsId,
  });

  const userSpecificData: any = cache.readFragment(
    userSpecificFragment,
    {
      id: args.interactionsId,
    },
    {
      userId: args.userId,
    },
  );

  cache.writeFragment(fragment, {
    id: args.interactionsId,
    is_liked: true,
    likes: ((data as any)?.likes ?? 0) + 1,
  });

  cache.writeFragment(
    userSpecificFragment,
    {
      id: args.interactionsId,
      is_liked: true,
      likes: ((userSpecificData as any)?.likes ?? 0) + 1,
    },
    {
      userId: args.userId,
    },
  );
};

export default addLike;
