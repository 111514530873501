// @ts-ignore
import hypher from 'hypher';
// @ts-ignore
import english from 'hyphenation.en-us';

const h = new hypher(english);

export default function hyphenate(text: string | undefined | null) {
  return text ? h.hyphenateText(text) : '';
}
