import { View, Text, StyleSheet } from 'react-native';
import React from 'react';
import { ValidatedTextInput } from '/components/ValidatedForm';
import {
  TEXT_INPUT,
  TEXT_INPUT_LARGE,
  TEXT_INPUT_PLACEHOLDER_COLOR,
} from '/constants';
import FormList from '/components/FormList';
import {
  CurrencyAmount,
  DonationRequest,
  DonationRequestGoal,
} from '/generated/graphql';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import getSymbolFromCurrency from 'currency-symbol-map';
import { formatMoney } from '/util';
import { getDecimalPlacesForCurrency } from 'currency-decimal-places';
import ErrorText from '/components/common/Generic/ErrorText';

interface IDonationRequestGoal
  extends Pick<DonationRequestGoal, 'title' | 'amount'> {
  id: string | undefined;
  total_donated: CurrencyAmount;
}

interface IDonationRequest extends Pick<DonationRequest, 'currency'> {
  goals: IDonationRequestGoal[];
}

type Props = {
  data: IDonationRequest;
  onChangeGoals: (goals: IDonationRequestGoal[]) => void;
};

export default function GoalsFormList(props: Props) {
  return (
    <View>
      <FormList
        data={props.data.goals}
        defaultFormState={
          {
            title: '',
            amount: 0,
          } as DonationRequestGoal
        }
        nonRemovableIndices={props.data.goals
          .filter((goal) => (goal.total_donated?.amount || 0) > 0)
          .map((goal) => props.data.goals.indexOf(goal))}
        onChange={props.onChangeGoals}
        renderForm={({ item, onChange, index }) => {
          return (
            <DonationGoal
              index={index}
              title={item.title}
              amount={item.amount}
              minAmount={Math.ceil(
                (item.total_donated?.amount ?? 0) /
                  10 **
                    getDecimalPlacesForCurrency(
                      props.data.currency?.toUpperCase() as any,
                    ),
              )}
              currency={props.data.currency}
              onTitleChange={(title) => onChange({ ...item, title })}
              onGoalChange={(goalAmount) =>
                onChange({ ...item, amount: goalAmount })
              }
            />
          );
        }}
        maxLength={10}
      />

      <HorizontalContainer style={{ marginVertical: 8 }}>
        <View style={{ width: 24, margin: 8 }} />
        <View
          style={{
            backgroundColor: '#ddd',
            justifyContent: 'space-between',
            padding: 12,
            borderRadius: 4,
            flex: 1,
            flexDirection: 'row',
          }}
        >
          <Text style={styles.fieldLabel}>Total Amount</Text>
          <Text style={styles.fieldLabel}>
            {getSymbolFromCurrency(props.data.currency?.toUpperCase() as any)}
            {props.data.goals.length > 0
              ? formatMoney(
                  props.data.goals?.reduce(
                    (prevValue, goal) =>
                      ({
                        amount: prevValue.amount + goal.amount,
                      } as IDonationRequestGoal),
                  ).amount,
                  getDecimalPlacesForCurrency(props.data.currency as any),
                )
              : formatMoney(0)}
          </Text>
        </View>
      </HorizontalContainer>
    </View>
  );
}

interface IDonationGoalProps {
  index: number;
  title: string;
  amount: number;
  minAmount: number;
  currency: string;
  onTitleChange: (title: string) => void;
  onGoalChange: (goal: number) => void;
}

const DonationGoal = (props: IDonationGoalProps) => {
  return (
    <View style={styles.donationGoal}>
      <View style={styles.inputs}>
        <ValidatedTextInput
          name={`goal-${props.index}-title`}
          style={[
            TEXT_INPUT_LARGE,
            {
              minHeight: 72,
            },
          ]}
          multiline
          placeholder="New goal..."
          placeholderTextColor={TEXT_INPUT_PLACEHOLDER_COLOR}
          value={props.title}
          maxLength={100}
          onChangeText={props.onTitleChange}
        />
        <View style={styles.field}>
          <Text style={styles.fieldLabel}>
            Goal amount:{`   `}
            {getSymbolFromCurrency(props.currency.toUpperCase())}{' '}
          </Text>
          <ValidatedTextInput
            name={`goal-${props.index}-amount`}
            validate={(value) => {
              const num = Number(value);

              return !isNaN(num) && num >= 1;
            }}
            style={[
              TEXT_INPUT,
              {
                minWidth: 100,
              },
            ]}
            value={props.amount ? props.amount.toString() : '0'}
            keyboardType="number-pad"
            onChangeText={(text) => {
              let num = Number(text.replace(/[^0-9]/g, ''));

              props.onGoalChange(num);
            }}
            maxLength={6}
          />
        </View>
        {props.minAmount && props.amount < props.minAmount ? (
          <ErrorText>
            Minimum amount is{' '}
            {getSymbolFromCurrency(props.currency.toUpperCase())}{' '}
            {formatMoney(
              props.minAmount,
              getDecimalPlacesForCurrency(props.currency?.toUpperCase() as any),
            )}
          </ErrorText>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  donationGoal: {
    flexDirection: 'row',
  },
  inputs: {
    flex: 1,
    flexDirection: 'column',
  },
  field: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 4,
  },
  fieldLabel: {
    fontFamily: 'Lato-Bold',
  },
});
