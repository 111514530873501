/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "cdn",
            "endpoint": "https://xgvxkjvn82.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "ffmpegapi",
            "endpoint": "https://46p70ws3gg.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "googlemapsapi",
            "endpoint": "https://c7glencsmc.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "sesemailapi",
            "endpoint": "https://w2nlk56j49.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "stripeconnect",
            "endpoint": "https://dv9olksybf.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:0863ac57-e668-4c29-a7ca-352729945fb0",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_mHi7CXJPT",
    "aws_user_pools_web_client_id": "3452v5uak76qo1ojo8c3u6n2r6",
    "oauth": {
        "domain": "keyconservation-oath-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:19006/login/,keyconservation://login/,https://app.keyconservation.org/login/,https://dev.keyconservation.app/login/",
        "redirectSignOut": "http://localhost:19006/,keyconservation://login/,https://app.keyconservation.org/login/,https://dev.keyconservation.app/login/,https://dev.keyconservation.app/,https://app.keyconservation.org/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "amplify-hosting-prerender-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dx4cyuis66czz.cloudfront.net",
    "aws_user_files_s3_bucket": "userassetsstorage210619-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
