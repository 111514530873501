import React from 'react';
import { Pressable, StyleProp, Text, View, ViewStyle } from 'react-native';
import ChevronRight from '/assets/jsicons/miscIcons/ChevronRight';
import { KEY_GRAY } from '/constants';

interface ITruncatedListProps<T> {
  items: T[];
  renderItem: (item: T, index: number) => React.ReactElement | null;
  keyExtractor?: (item: T, index: number) => string | number;
  /** Specify the total actual length of the data. If not specified, TruncatedList will default
   * to using the length of `items` */
  total?: number | null;
  /** Default is 3 */
  maxLength?: number;
  inverted?: boolean;
  style?: StyleProp<ViewStyle>;
  viewMoreLabel?: string;
  emptyText?: string;
  viewMoreChevronDisabled?: boolean;
  onViewMore?: () => void;
}

export default function TruncatedList<ItemT = any>({
  items,
  keyExtractor = (item: any, index) => item?.id ?? index,
  renderItem,
  total,
  inverted,
  style,
  maxLength = 3,
  viewMoreLabel,
  viewMoreChevronDisabled,
  emptyText,
  onViewMore,
}: ITruncatedListProps<ItemT>) {
  return (
    <View
      style={[
        {
          flexDirection: inverted ? 'column-reverse' : 'column',
          width: '100%',
        },
        style,
      ]}
    >
      {items.length ? (
        Array.from(items)
          .splice(0, maxLength)
          .map((item, index) =>
            // Automatically inject a 'key' prop
            React.cloneElement(renderItem(item, index) ?? <></>, {
              key: keyExtractor(item, index),
            }),
          )
      ) : emptyText ? (
        <Text
          style={{
            fontFamily: 'Lato-Bold',
            alignSelf: 'center',
            color: 'gray',
            fontSize: 14,
            padding: 24,
          }}
        >
          {emptyText}
        </Text>
      ) : null}
      {(total ?? items.length) > maxLength && (
        <Pressable
          style={{
            width: '100%',
            padding: 16,
          }}
          onPress={onViewMore}
        >
          {({ pressed }) => (
            <View
              style={{
                opacity: pressed ? 0.6 : 1,
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <Text
                style={{
                  fontFamily: 'Lato-Bold',
                  fontSize: 18,
                  color: KEY_GRAY,
                }}
              >
                {
                  // If a custom label is provided, use that. Otherwise, use the default
                  viewMoreLabel ??
                    `See all (${(total ?? items.length) - maxLength} more)`
                }
              </Text>
              {viewMoreChevronDisabled ? null : (
                <ChevronRight width="16" height="24" stroke={KEY_GRAY} />
              )}
            </View>
          )}
        </Pressable>
      )}
    </View>
  );
}
