import { UpdateResolver } from '@urql/exchange-graphcache';

const deleteNewsPost: UpdateResolver = (result, args, cache) => {
  cache.invalidate({
    __typename: 'NewsPost',
    id: args.id as string,
  });
};

export default deleteNewsPost;
