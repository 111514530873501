import { RouteProp, useLinkTo } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import * as Linking from 'expo-linking';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Keyboard,
  KeyboardAvoidingView,
  LayoutChangeEvent,
  Platform,
  SafeAreaView,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';
import KeyInfoYellow from '../../assets/jsicons/KeyCon/KeyInfoYellow';
import AnimalModal from '../../components/Animals/AnimalModal/AnimalModal';
import AuthForm from '../../components/Auth/AuthForm/AuthForm';
import useStyles from './LoginScreen.style';
import { getAnimalPatternURL } from '/assets/KeyAnimalPattern';
import BackButtonHeader from '/components/BackButtonHeader';
import LoadingSpinnerOverlay from '/components/LoadingSpinnerOverlay';
import ProgressiveImageBackground from '/components/ProgressiveImageBackground';
import { useAuthContext, useOnboardContext } from '/context';
import useBinaryTimingAnimation from '../../hooks/useBinaryTimingAnimation';
import Animated, {
  Easing,
  interpolate,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { AnimatedScrollView } from '/components/common/ScrollView/ScrollView';

type LoginScreenProps = {
  navigation: StackNavigationProp<any>;
  route: RouteProp<any>;
};

const LoginScreen = (props: LoginScreenProps) => {
  const referralCode = props.route.params?.ref;

  const { setData } = useOnboardContext();

  const { styles } = useStyles();

  const linkTo = useLinkTo();

  const { isAuthenticating, fetching, setReturnTo } = useAuthContext();

  const [headerTranslate, setHeaderTranslate] = useState(0);

  const aboutIconOpacity = useSharedValue(0);
  const titleOpacity = useSharedValue(1);
  const logoOpacity = useSharedValue(1);
  const enterAnimation = useSharedValue(0);
  const enterOpacity = useSharedValue(0);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const [showBottom, setShowBottom] = useState(true);

  const showAboutIcon = useCallback(() => {
    aboutIconOpacity.value = withTiming(1, { duration: 150 });
  }, [aboutIconOpacity]);

  const hideAboutIcon = useCallback(() => {
    aboutIconOpacity.value = withTiming(1, {
      duration: 150,
    });
  }, [aboutIconOpacity]);

  const showTitle = useCallback(() => {
    titleOpacity.value = withTiming(1, {
      duration: 150,
    });
  }, [titleOpacity]);

  const hideTitle = useCallback(() => {
    titleOpacity.value = withTiming(0, {
      duration: 90,
    });
  }, [titleOpacity]);

  const showLogo = useCallback(() => {
    logoOpacity.value = withTiming(1, {
      duration: 150,
    });
  }, [logoOpacity]);

  const hideLogo = useCallback(() => {
    logoOpacity.value = withTiming(0, {
      duration: 90,
    });
  }, [logoOpacity]);

  useEffect(() => {
    if (referralCode) {
      setData({
        referral_code: referralCode,
      });
    }
  }, [referralCode, setData]);

  useEffect(() => {
    if (props.route.params?.returnto) {
      setReturnTo(props.route.params?.returnto);
    }
  }, [props.route.params?.returnto, setReturnTo]);

  useEffect(() => {
    if (headerTranslate > 0) {
      enterOpacity.value = withTiming(1, {
        duration: 1000,
      });
      enterAnimation.value = withTiming(
        1,
        {
          duration: 1000,
          easing: Easing.out(Easing.poly(4)),
        },
        () => {
          runOnJS(showAboutIcon)();
        },
      );
    }
  }, [enterAnimation, enterOpacity, headerTranslate, showAboutIcon]);

  useEffect(() => {
    const keyboardWillShow = Keyboard.addListener('keyboardWillShow', () => {
      setShowBottom(false);
      hideTitle();
      hideAboutIcon();
      hideLogo();
    });
    const keyboardWillHide = Keyboard.addListener('keyboardWillHide', () => {
      setShowBottom(true);
      showLogo();
      showTitle();
      showAboutIcon();
    });
    return () => {
      keyboardWillHide.remove();
      keyboardWillShow.remove();
    };
  }, [hideAboutIcon, hideLogo, hideTitle, showAboutIcon, showLogo, showTitle]);

  /** If animal modal is shown, hide everything else */
  const mainContentOpacity = useBinaryTimingAnimation({
    value: !isModalVisible,
  });
  const mainContentAnimatedStyle = useAnimatedStyle(() => ({
    opacity: mainContentOpacity.value,
  }));

  const formContainerAnimatedStyle = useAnimatedStyle(() => {
    const opacity = interpolate(enterOpacity.value, [0, 0.7, 1], [0, 0, 1]);

    return {
      opacity,
    };
  });

  const animatedHeaderStyle = useAnimatedStyle(() => {
    const translateY = interpolate(
      enterAnimation.value,
      [0, 0.7, 1],
      [headerTranslate, headerTranslate, 0],
    );

    return {
      transform: [
        {
          translateY,
        },
      ],
    };
  }, [headerTranslate]);

  const titleContainerAnimatedStyle = useAnimatedStyle(() => ({
    opacity: titleOpacity.value,
  }));

  const animatedLogoStyle = useAnimatedStyle(() => ({
    opacity: logoOpacity.value,
  }));

  const aboutIconAnimatedStyle = useAnimatedStyle(() => ({
    opacity: aboutIconOpacity.value,
  }));

  return (
    <ProgressiveImageBackground
      testID={'LoginScreen'}
      source={{ uri: getAnimalPatternURL('africanwilddog') }}
      thumbnailSource={require('../../assets/images/loginscreen2.jpg')}
      overlayStyle={{
        backgroundColor: '#111',
        opacity: 0.6,
      }}
      blurThumbnail={windowWidth < 720 ? false : true}
      style={styles('container')}
    >
      <AnimalModal
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
      />
      <AnimatedScrollView
        style={[
          {
            flex: 1,
            width: '100%',
            maxHeight: windowHeight,
          },
          mainContentAnimatedStyle,
        ]}
        contentContainerStyle={{
          minHeight: '100%',
        }}
        scrollToOverflowEnabled={false}
        overScrollMode="never"
      >
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
          }}
        >
          <Animated.View
            style={[
              styles('logoContainer'),
              {
                paddingTop: windowHeight < 740 ? 20 : 70,
              },
              animatedHeaderStyle,
            ]}
          >
            {Platform.OS === 'web' ? (
              <View style={[styles('backButtonContainer')]}>
                <BackButtonHeader
                  size={40}
                  onPress={() => {
                    if (props.navigation.canGoBack()) {
                      props.navigation.goBack();
                    } else linkTo('/');
                  }}
                />
              </View>
            ) : null}
            <Animated.Image
              style={[
                styles('logo'),
                {
                  height: windowHeight < 740 ? 150 : 200,
                  width: windowHeight < 740 ? 150 : 200,
                },
                animatedLogoStyle,
              ]}
              source={require('/assets/images/keyFullWhite.png')}
            />
            <View style={styles('titleContainer')}>
              <Animated.View style={[{ flex: 0 }, titleContainerAnimatedStyle]}>
                <Text style={styles('selectTitle')}>JOIN THE COMMUNITY</Text>
                <Text style={styles('selectTitle')}>EMPOWERING HOPE</Text>
              </Animated.View>
            </View>
          </Animated.View>
          {Platform.OS !== 'ios' ? (
            <Animated.View
              style={[
                styles('subContainer'),
                { zIndex: 25 },
                formContainerAnimatedStyle,
              ]}
              onLayout={(e: LayoutChangeEvent) => {
                setHeaderTranslate(e.nativeEvent.layout.height / 2);
              }}
            >
              <AuthForm />
            </Animated.View>
          ) : (
            <KeyboardAvoidingView
              behavior={Platform.OS === 'ios' ? 'position' : 'position'}
              keyboardVerticalOffset={
                Platform.OS === 'ios' ? -headerTranslate / 2 : 0
              }
              style={{
                width: '100%',
                zIndex: 1,
              }}
            >
              <Animated.View
                style={[styles('subContainer'), formContainerAnimatedStyle]}
                onLayout={(e: LayoutChangeEvent) => {
                  setHeaderTranslate(e.nativeEvent.layout.height / 2);
                }}
              >
                <AuthForm />
              </Animated.View>
            </KeyboardAvoidingView>
          )}
        </View>
        {showBottom && (
          <View
            style={{
              position: 'absolute',
              bottom: 0,
              flexDirection: 'row',
              width: '100%',
              zIndex: 20,
            }}
          >
            <SafeAreaView style={styles('aboutIconContainer')}>
              <Animated.View style={[{ flex: 1 }, aboutIconAnimatedStyle]}>
                <TouchableOpacity
                  testID="animalModalButton"
                  style={[
                    styles('aboutIconTouch'),
                    {
                      marginBottom: Platform.OS === 'android' ? 20 : 0,
                    },
                  ]}
                  onPress={() => {
                    setIsModalVisible(true);
                  }}
                >
                  <KeyInfoYellow />
                </TouchableOpacity>
              </Animated.View>
            </SafeAreaView>
            <View
              style={{
                position: 'absolute',
                bottom: 10,
                paddingBottom: windowHeight < 740 ? 0 : 20,
                width: '100%',
                zIndex: 50,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text
                style={{
                  color: 'white',
                  textAlign: 'center',
                  width: '50%',
                }}
              >
                By creating an account or logging in you agree to the{' '}
                <TouchableOpacity
                  style={{
                    transform: [{ translateY: 2 }],
                  }}
                  onPress={() => {
                    Linking.openURL(
                      'https://www.keyconservation.org/termsandconditions',
                    );
                  }}
                >
                  <Text
                    style={{ textDecorationLine: 'underline', color: 'white' }}
                  >
                    Terms of Use
                  </Text>
                </TouchableOpacity>{' '}
                and{' '}
                <TouchableOpacity
                  style={{
                    transform: [{ translateY: 2 }],
                  }}
                  onPress={() => {
                    Linking.openURL(
                      'https://www.keyconservation.org/privacypolicy',
                    );
                  }}
                >
                  <Text
                    style={{
                      textDecorationLine: 'underline',
                      color: 'white',
                    }}
                  >
                    Privacy Policy.
                  </Text>
                </TouchableOpacity>
              </Text>
            </View>
          </View>
        )}
      </AnimatedScrollView>
      {isAuthenticating || fetching ? <LoadingSpinnerOverlay /> : null}
    </ProgressiveImageBackground>
  );
};

export default LoginScreen;
