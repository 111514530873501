import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';
import { UserConnectionStatus } from '/generated/graphql';

const addConnection: UpdateResolver = (result, args, cache) => {
  const fragment = gql`
    fragment AddConnectionUser on User {
      id
      connections_count
      connection {
        status
      }
    }
  `;

  const initiatorData = cache.readFragment(fragment, {
    id: (result.addConnection as any)?.initiator?.id,
  }) as any;
  const recipientData = cache.readFragment(fragment, {
    id: (result.addConnection as any)?.recipient?.id,
  }) as any;

  cache.writeFragment(
    gql`
      fragment AddConnectionConnection on Connection {
        id
        status
      }
    `,
    result.addConnection,
  );

  cache.link(
    {
      __typename: 'User',
      id: args.userId as string,
    },
    'connection',
    result.addConnection as any,
  );

  // Only update the count in the cache if connection was confirmed
  if (initiatorData?.status === UserConnectionStatus.Confirmed) {
    cache.writeFragment(fragment, {
      id: (result.addConnection as any)?.initiator?.id,
      connections_count: initiatorData.connections_count + 1,
    });
    cache.writeFragment(fragment, {
      id: (result.addConnection as any)?.recipient?.id,
      connections_count: recipientData?.connections_count + 1,
    });
  }
};

export default addConnection;
