import {
  Image,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import React from 'react';
import { User, UserActivity, UserActivityType } from '/generated/graphql';
import Avatar from '/components/Avatar';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import dayjs from 'dayjs';
import { shorten } from '/util';
import { KEY_GREEN } from '/constants';
import getCDNImageUri from '/util/getCDNImageUri';

interface IUser extends Pick<User, 'id' | 'name' | 'profile_image'> {}

interface IData extends Pick<UserActivity, 'type' | 'data' | 'created_at'> {
  avatar?: IUser | undefined | null;
}

interface Props {
  data: IData | undefined;
  user: IUser;
}

export default function RecentActivityListItem(props: Props) {
  const { navigate, push } = useNavigation<StackNavigationProp<any>>();

  const data = JSON.parse(props.data?.data ?? `{}`);

  function goToProfile(id?: string) {
    if (!id) return;

    push('Profile', { id });
  }

  switch (props.data?.type) {
    case UserActivityType.Like:
    case UserActivityType.CampaignLike:
      return (
        <Item
          {...props}
          thumbnailUri={
            data.post
              ? data.post?.thumbnail
              : getCDNImageUri({
                  uri: data.momentInNature?.video,
                  isThumbnail: true,
                })
          }
          onPressThumbnail={() => {
            if (data.post) {
              navigate('Campaign', { postId: data.post?.id });
            } else if (data.momentInNature) {
              navigate('MomentInNature', { id: data.momentInNature?.id });
            }
          }}
        >
          Liked a{` `}
          {data.post ? (
            <>
              post on campaign{` `}
              <Text
                onPress={() => {
                  navigate('Campaign', { postId: data.post?.id });
                }}
                style={styles.pressableText}
              >
                "{shorten(data.post?.campaignName ?? '', 64)}"
              </Text>
            </>
          ) : (
            <>
              <Text
                onPress={() => {
                  navigate('MomentInNature', { id: data.momentInNature?.id });
                }}
                style={styles.pressableText}
              >
                moment in nature
              </Text>
            </>
          )}
        </Item>
      );
    case UserActivityType.Donation:
      return (
        <Item
          {...props}
          thumbnailUri={data.campaign?.thumbnail}
          onPressThumbnail={() => {
            navigate('Campaign', { campaignId: data.campaign?.id });
          }}
        >
          Donated to campaign{` `}
          <Text
            onPress={() => {
              navigate('Campaign', { campaignId: data.campaign?.id });
            }}
            style={styles.pressableText}
          >
            "{shorten(data.campaign?.name ?? '', 64)}"
          </Text>
        </Item>
      );
    case UserActivityType.JoinedTeam:
      return (
        <Item {...props}>
          Joined{` `}
          <Text
            onPress={() => goToProfile(props.data?.avatar?.id)}
            style={styles.pressableText}
          >
            {props.data?.avatar?.name}
          </Text>
          {` `}as a{` `}
          <Text style={styles.pressableText}>{data.title || 'member'}</Text>
        </Item>
      );
    case UserActivityType.Comment:
      return createNewCommentActivity(props.data, props.user, navigate);
    case UserActivityType.NewConnection:
      return (
        <Item {...props}>
          Connected with{' '}
          <Text
            onPress={() => goToProfile(props.data?.avatar?.id)}
            style={styles.pressableText}
          >
            {props.data?.avatar?.name}
          </Text>
        </Item>
      );
    case UserActivityType.FollowedOrganization:
      return (
        <Item {...props}>
          Followed{' '}
          <Text
            onPress={() => goToProfile(props.data?.avatar?.id)}
            style={styles.pressableText}
          >
            {props.data?.avatar?.name}
          </Text>
        </Item>
      );
    case UserActivityType.FollowedTopic:
      return (
        <Item {...props}>
          Followed the topic{' '}
          <Text
            onPress={() => {
              navigate('ResearchTopicSummary', { topic: data.topic });
            }}
            style={styles.pressableText}
          >
            {data.topic}
          </Text>
        </Item>
      );
    case UserActivityType.CampaginUpdate:
      return (
        <Item
          {...props}
          style={{
            backgroundColor: '#DDF8EE',
          }}
        >
          <Text
            style={[
              styles.pressableText,
              {
                fontFamily: 'Lato-BoldItalic',
                color: KEY_GREEN,
              },
            ]}
          >
            NEW UPDATE!
          </Text>{' '}
          <Text
            onPress={() => {
              goToProfile(props.data?.avatar?.id);
            }}
            style={styles.pressableText}
          >
            {props.data?.avatar?.name}
          </Text>{' '}
          has a new update on their{' '}
          <Text
            style={styles.pressableText}
            onPress={() => {
              navigate('Campaign', { postId: data.post?.id });
            }}
          >
            {data.post?.campaignName}
          </Text>{' '}
          campaign
        </Item>
      );
    default:
      return null;
  }
}

interface ItemProps {
  data: IData | undefined;
  user: IUser;
  thumbnailUri?: string;
  onPressThumbnail?: () => void;
  style?: StyleProp<ViewStyle>;
}

function Item(props: React.PropsWithChildren<ItemProps>) {
  return (
    <View style={[styles.itemContainer, props.style]}>
      <Avatar
        source={{
          uri:
            props.data?.avatar?.profile_image ?? props.user.profile_image ?? '',
        }}
        rounded
        size={48}
        containerStyle={{
          marginRight: 8,
        }}
      />
      <View style={{ flex: 1 }}>
        <Text style={styles.bodyText}>{props.children}</Text>
        <Text style={styles.timestamp}>
          {dayjs(Number(props.data?.created_at)).fromNow()}
        </Text>
      </View>
      {props.thumbnailUri ? (
        <Pressable onPress={props.onPressThumbnail}>
          <Image
            style={styles.thumbnail}
            source={{
              uri: getCDNImageUri({
                uri: props.thumbnailUri,
                isThumbnail: true,
                dimensions: {
                  width: 64,
                  height: 64,
                },
              }),
            }}
          />
        </Pressable>
      ) : null}
    </View>
  );
}

function createNewCommentActivity(
  notification: IData,
  user: IUser,
  navigate: StackNavigationProp<any>['navigate'],
) {
  const data = JSON.parse(notification?.data || '{}');
  const avatar = notification.avatar || user;

  const commentType = data?.post
    ? 'post'
    : data?.momentInNature
    ? 'momentInNature'
    : undefined;

  const thumbnailUri =
    commentType === 'post'
      ? data.post?.thumbnail
      : commentType === 'momentInNature'
      ? getCDNImageUri({
          uri: data.momentInNature?.video,
          isThumbnail: true,
        })
      : undefined;

  const onPressThumbnail = () => {
    if (commentType === 'post') {
      navigate('Campaign', { postId: data.post?.id });
    } else if (commentType === 'momentInNature') {
      navigate('MomentInNature', { id: data.momentInNature?.id });
    }
  };

  return (
    <Item
      data={notification}
      user={user}
      thumbnailUri={thumbnailUri}
      onPressThumbnail={onPressThumbnail}
    >
      Commented on{' '}
      {notification.avatar?.name ? (
        <>
          <Text
            onPress={() => {
              navigate('Profile', { id: avatar?.id });
            }}
            style={styles.pressableText}
          >
            {avatar?.name}
          </Text>
          's
        </>
      ) : (
        'a'
      )}
      {` `}post "{shorten(data.commentBody ?? '', 64)}"
    </Item>
  );
}

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 4,
    marginTop: 4,
    paddingHorizontal: 10,
  },
  bodyText: {
    fontFamily: 'Lato',
    fontSize: 17,
  },
  pressableText: {
    fontFamily: 'Lato-Bold',
  },
  timestamp: {
    fontFamily: 'Lato',
    fontSize: 17,
    color: 'gray',
  },
  thumbnail: {
    width: 64,
    height: 64,
    backgroundColor: 'gray',
  },
});
