import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';

const updateUserProfile: UpdateResolver = (result, args, cache) => {
  const fragment = gql`
    fragment UpdateUserProfile on User {
      id
      location
    }
  `;

  cache.writeFragment(fragment, {
    __typename: 'User',
    ...(result.updateUserProfile as any),
  });
};

export default updateUserProfile;
