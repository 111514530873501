import { StyleSheet } from 'react-native';
import { KEY_GRAY } from '/constants';

export default StyleSheet.create({
  donationInfoContainer: {
    marginTop: 8,
    backgroundColor: 'white',
    borderRadius: 6,
    padding: 6,
  },
  paragraph: {
    fontFamily: 'Lato',
    fontSize: 17,
    color: KEY_GRAY,
  },
  horizontalContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  lineItemText: {
    marginVertical: 4,
    fontFamily: 'Lato-Bold',
    fontSize: 16,
    color: KEY_GRAY,
  },
  breakdownTitle: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 15,
    marginTop: 8,
  },
  donorName: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 17,
    textTransform: 'uppercase',
  },
  locationText: {
    fontFamily: 'Lato',
    color: 'gray',
  },
  breakdownExpandToggle: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 2,
  },
  breakdownExpandToggleText: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    fontSize: 15,
    marginLeft: 2,
  },
  anonymousText: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
    color: 'gray',
  },
  breakdownSection: {
    marginTop: 6,
  },
});
