import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Animated, Easing, Text, View } from 'react-native';

const LoadingContext = createContext({});

export const useLoadingContext = () => useContext(LoadingContext);

// In seconds
const MAX_SHOW_LOADING_TIMEOUT = 30;

export default (props) => {
  const [showLoading, setShowLoading] = useState(false);
  const [loadingTimeout, setLoadingTimeout] = useState(
    MAX_SHOW_LOADING_TIMEOUT,
  );
  const [loadingInfo, setLoadingInfo] = useState('');

  const timeoutRef = useRef(null);

  useEffect(() => {
    // console.log(showLoading, loadingInfo);

    if (!showLoading && loadingInfo !== '') {
      setLoadingInfo('');
    }
  }, [showLoading, loadingInfo]);

  useEffect(() => {
    return () => {
      setShowLoading(false);
      setLoadingInfo('');
    };
  }, []);

  useEffect(() => {
    // Only begin the time out if showLoading set to true after
    // previously being false
    if (showLoading && timeoutRef.current === null && loadingTimeout > 0) {
      timeoutRef.current = setTimeout(() => {
        setShowLoading(false);
      }, 1000 * MAX_SHOW_LOADING_TIMEOUT);
    }
    // Clear any timeout that may be stored
    // and set to null if showLoading set to false.
    else if (showLoading === false) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
      setLoadingTimeout(MAX_SHOW_LOADING_TIMEOUT);
    }
  }, [loadingTimeout, showLoading]);

  return (
    <LoadingContext.Provider
      value={{
        showLoading,
        setShowLoading,
        loadingInfo,
        setLoadingInfo,
        setLoadingTimeout,
      }}
    >
      {props.children}
      {showLoading && (
        <View
          pointerEvents={showLoading ? 'auto' : 'none'}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            backgroundColor: 'rgba(255,255,255,0.45)',
            zIndex: 999,
            elevation: 999,
            position: 'absolute',
          }}
        >
          <AndroidSpinner />
          {loadingInfo !== '' && (
            <Text
              style={{
                fontSize: 16,
                fontWeight: 'bold',
                marginTop: 25,
              }}
            >
              {loadingInfo}
            </Text>
          )}
        </View>
      )}
    </LoadingContext.Provider>
  );
};

class AndroidSpinner extends React.Component {
  constructor(props) {
    super(props);

    this.spinValue = new Animated.Value(0);
  }

  componentDidMount() {
    this.spin();
  }

  spin() {
    this.spinValue.setValue(0); //set spinValue to 0 (zero)
    Animated.timing(this.spinValue, {
      toValue: 1,
      duration: 4000,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start(() => this.spin());
  }

  render() {
    const spin = this.spinValue.interpolate({
      inputRange: [0, 1],
      outputRange: ['0deg', '360deg'],
    });
    return (
      <Animated.Image
        style={{
          width: 100,
          height: 100,
          backgroundColor: 'transparent',
          transform: [{ rotate: spin }],
        }}
        source={require('../assets/images/keyFullBlack.png')}
      />
    );
  }
}
