import { UpdateResolver } from '@urql/exchange-graphcache';

const removeTeamMember: UpdateResolver = (result, args, cache) => {
  cache.invalidate({
    __typename: 'TeamMember',
    teamId: (result.removeTeamMember as any).teamId,
    userId: (result.removeTeamMember as any).userId,
  });
};

export default removeTeamMember;
