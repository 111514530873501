import { ListItem } from '@rneui/themed';
import dayjs from 'dayjs';
import React from 'react';
import { Image, Pressable, Text, TextStyle, View } from 'react-native';
import CampaignPostControls from '../CampaignPost/elements/CampaignPostControls';
import ChevronRight from '/assets/jsicons/miscIcons/ChevronRight';
import { Campaign, CampaignPost, User } from '/generated/graphql';
import { determineIfVideo } from '/util';
import getCDNImageUri from '/util/getCDNImageUri';

interface ICampaignBase extends Pick<Campaign, 'id' | 'name' | 'created_at'> {
  user?: Pick<User, 'profile_image' | 'name'>;
}

type ICampaignWithInteractions = ICampaignBase &
  Pick<Campaign, 'is_bookmarked'>;
interface ICampaignPostInteractions
  extends Pick<CampaignPost['interactions'], 'id' | 'is_liked' | 'likes'> {
  comments: {
    total: number;
  };
}

interface ICampaignPostBase
  extends Pick<CampaignPost, 'id' | 'media' | 'thumbnail'> {}

interface ICampaignPostWithoutInteractions extends ICampaignPostBase {
  campaign?: ICampaignBase | undefined;
}

interface ICampaignPostWithInteractions extends ICampaignPostBase {
  interactions: ICampaignPostInteractions;
  campaign?: ICampaignWithInteractions | undefined;
}

interface IPropsBase {
  onPress?: () => void;
  /** Size of media preview */
  thumbnailSize?: number;
  /** Toggle chevron arrow shown on right edge of component  */
  showChevronArrow?: boolean;
  /** Show a thin border on bottom of preview */
  bottomDivider?: boolean;
  /** Hide the org's profile image and name */
  hideAuthor?: boolean;
  /** Text OR JSX element to display in place of post date. If this is not provided, the default subtext will be displayed.  */
  subtitle?: string | JSX.Element;
  subtitleTextStyle?: TextStyle;
  titleStyle?: TextStyle;
  authorTextStyle?: TextStyle;
  chevronColor?: string;
  /** A react element to render on the bottom of the CampaignPreview */
  widget?: JSX.Element;
}

type CampaignPreviewProps =
  | (IPropsBase & {
      /** Show bookmark & comment buttons */
      showSocialControls: true;
      campaignPost: ICampaignPostWithInteractions | undefined;
      campaign: ICampaignWithInteractions | undefined;
    })
  | (IPropsBase & {
      /** Show bookmark & comment buttons */
      showSocialControls?: false | undefined;
      campaignPost: ICampaignPostWithoutInteractions | undefined;
      campaign: ICampaignBase | undefined;
    });

export default function CampaignPreview({
  campaignPost,
  onPress,
  thumbnailSize = 92,
  showSocialControls,
  showChevronArrow,
  bottomDivider,
  hideAuthor,
  subtitle,
  subtitleTextStyle,
  widget,
  titleStyle,
  authorTextStyle,
  ...props
}: CampaignPreviewProps) {
  const thumbnail = determineIfVideo(campaignPost?.media?.[0] ?? '')
    ? campaignPost?.thumbnail
    : campaignPost?.media?.[0] ?? campaignPost?.thumbnail;

  const campaign = props.campaign || campaignPost?.campaign;

  return (
    <Pressable
      style={({ pressed }) => ({
        width: '100%',
        opacity: onPress !== undefined && pressed ? 0.68 : 1,
      })}
      onPress={onPress}
    >
      <ListItem
        containerStyle={{
          padding: 0,
          backgroundColor: 'transparent',
        }}
        bottomDivider={bottomDivider}
      >
        <ListItem.Content>
          {/* MAIN PREVIEW CONTENT */}
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <Image
              style={{
                width: thumbnailSize,
                height: thumbnailSize,
                backgroundColor: 'gray',
              }}
              source={{
                uri: getCDNImageUri({
                  uri: thumbnail,
                  isThumbnail: true,
                  dimensions: {
                    width: thumbnailSize + 16,
                    height: thumbnailSize + 16,
                  },
                }),
              }}
            />
            <ListItem.Content
              style={{
                marginHorizontal: 5,
              }}
            >
              <ListItem.Title
                style={[
                  {
                    fontFamily: 'Lato-Bold',
                    fontSize: 16,
                  },
                  titleStyle,
                ]}
                numberOfLines={2}
              >
                {campaign?.name?.toUpperCase() || 'UNTITLED CAMPAIGN'}
              </ListItem.Title>
              <View
                style={{
                  display: hideAuthor || !campaign?.user ? 'none' : 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginVertical: 2,
                }}
              >
                <Image
                  resizeMode="cover"
                  style={{
                    width: 25,
                    height: 25,
                    borderRadius: 25,
                    backgroundColor: 'gray',
                  }}
                  source={{
                    uri: getCDNImageUri({
                      uri: campaign?.user?.profile_image,
                      dimensions: {
                        width: 32,
                        height: 32,
                      },
                    }),
                  }}
                />
                <Text
                  style={[
                    {
                      marginLeft: 5,
                      padding: 0,
                      fontSize: 16,
                      fontFamily: 'Lato',
                    },
                    authorTextStyle,
                  ]}
                  numberOfLines={1}
                >
                  {campaign?.user?.name}
                </Text>
              </View>
              {typeof subtitle === 'string' ? (
                <ListItem.Subtitle
                  style={[
                    {
                      fontFamily: 'Lato',
                    },
                    subtitleTextStyle,
                  ]}
                >
                  {subtitle}
                </ListItem.Subtitle>
              ) : (
                <>
                  <ListItem.Subtitle
                    style={[
                      {
                        fontFamily: 'Lato',
                      },
                      subtitleTextStyle,
                    ]}
                    numberOfLines={1}
                  >
                    Posted:{' '}
                    {dayjs(Number(campaign?.created_at)).format(
                      'MMMM Do, YYYY',
                    )}
                  </ListItem.Subtitle>
                  <ListItem.Subtitle
                    style={[
                      {
                        fontFamily: 'Lato-Italic',
                      },
                      subtitleTextStyle,
                    ]}
                    numberOfLines={1}
                  >
                    Last Update:{' '}
                    {dayjs(Number(campaign?.created_at)).format(
                      'MMMM Do, YYYY',
                    )}
                  </ListItem.Subtitle>
                </>
              )}
            </ListItem.Content>
            <View
              style={{
                display: showChevronArrow ? 'flex' : 'none',
                height: '100%',
                marginHorizontal: 4,
                paddingVertical: 8,
                alignItems: 'flex-end',
                justifyContent: 'flex-start',
              }}
            >
              <ChevronRight fill={props.chevronColor} />
            </View>
          </View>

          {/* SOCIAL CONTROLS */}
          {showSocialControls && (
            <View
              style={{
                width: '100%',
                alignItems: 'flex-end',
              }}
            >
              <CampaignPostControls
                campaign={campaign as ICampaignWithInteractions}
                data={campaignPost || null}
                hideCommentsButton={false}
                hideShareButton
              />
            </View>
          )}

          {/* VARIABLE WIDGET */}
          <View
            style={{
              width: '100%',
            }}
          >
            {widget ? widget : null}
          </View>
        </ListItem.Content>
      </ListItem>
    </Pressable>
  );
}
