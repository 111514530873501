import { UpdateResolver } from '@urql/exchange-graphcache';

const inviteGroupMember: UpdateResolver = (result, args, cache) => {
  const userId = (args as any).input?.user?.id;
  const groupId = (args as any).input?.group?.id;

  console.log('rsult', result);

  if (!userId || !groupId) return;

  cache.link(
    {
      __typename: 'User',
      id: userId as string,
    },
    'group_membership',
    {
      groupId,
    },
    result.inviteGroupMember as any,
  );
};

export default inviteGroupMember;
