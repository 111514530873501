import { View, Text, StyleSheet } from 'react-native';
import React from 'react';
import Button from '../../Button';

interface Props {
  message?: string;
  onRetry?: () => void;
}

const DEFAULT_MESSAGE = 'Something went wrong.';

export default function GenericError(props: Props) {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{props.message ?? DEFAULT_MESSAGE}</Text>
      {props.onRetry ? (
        <Button
          label="Retry"
          containerStyle={{
            marginTop: 8,
          }}
          onPress={props.onRetry}
        />
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 24,
    alignSelf: 'center',
    alignItems: 'center',
  },
  text: {
    fontFamily: 'Lato-Bold',
    color: 'crimson',
    fontSize: 16,
  },
});
