import { UpdateResolver } from '@urql/exchange-graphcache';

const deleteResearchTopicGroup: UpdateResolver = (result, args, cache) => {
  cache.invalidate({
    __typename: 'ResearchTopicGroup',
    id: args.id as string,
  });
};

export default deleteResearchTopicGroup;
