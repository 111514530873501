import * as Updates from 'expo-updates';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AppState } from 'react-native';
import Alert from '/Alert';
import { AppStateStatus } from 'react-native';

export const UpdatesContext = React.createContext({
  hasUpdate: false,
} as {
  hasUpdate: boolean;
  /** Should only be called if there is an update available */
  reload: () => void;
});

export default function UpdatesProvider(props: React.PropsWithChildren<{}>) {
  const [hasUpdate, setHasUpdate] = useState(false);
  const appState = useRef(AppState.currentState);

  async function reload() {
    try {
      await Updates.reloadAsync();
    } catch (error) {
      console.log('error reloading app', error);
    }
  }

  const fetchingUpdate = useRef(false);
  const onFetchUpdateAsync = useCallback(async function () {
    if (fetchingUpdate.current) {
      return;
    }

    fetchingUpdate.current = true;

    try {
      const update = await Updates.checkForUpdateAsync();

      if (update.isAvailable) {
        await Updates.fetchUpdateAsync();
        setHasUpdate(true);

        Alert.alert(
          'New version available',
          'A reload of the app is required to finish updating. Do you want to reload now?',
          [
            {
              text: 'Not now',
              style: 'cancel',
            },
            {
              text: 'Reload',
              style: 'default',
              onPress: () => {
                reload();
              },
            },
          ],
        );
      } else {
        setHasUpdate(false);
      }
    } catch (error) {
      // You can also add an alert() to see the error message in case of an error when fetching updates.
      //   alert(`Error fetching latest Expo update: ${error}`);
      setHasUpdate(false);
    } finally {
      fetchingUpdate.current = false;
    }
  }, []);

  const handleAppStateChange = useCallback(
    function (nextAppState: AppStateStatus) {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === 'active'
      ) {
        onFetchUpdateAsync();
      }
      appState.current = nextAppState;
    },
    [onFetchUpdateAsync],
  );

  useEffect(() => {
    if (__DEV__) return;

    onFetchUpdateAsync();

    const appStateChangeHandler = AppState.addEventListener(
      'change',
      handleAppStateChange,
    );

    return () => {
      appStateChangeHandler.remove();
    };
  }, [handleAppStateChange, onFetchUpdateAsync]);

  return (
    <UpdatesContext.Provider value={{ hasUpdate, reload }}>
      {props.children}
    </UpdatesContext.Provider>
  );
}
