import { KeyboardAwareFlatList } from '@mtourj/react-native-keyboard-aware-scroll-view';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useEffect, useState } from 'react';
import {
  Keyboard,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import Button from '../Button';
import GenericListFooter from '../GenericListFooter';
import commonStyles from './common/styles';
import {
  SortDirection,
  SystemSurveyPlacement,
  useSearchSkillsQuery,
} from '/generated/graphql';

interface Props {
  query: string;
}

export default function SkillSearch(props: Props) {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const [nextToken, setNextToken] = useState<string>();

  const [{ data, fetching, error, stale }] = useSearchSkillsQuery({
    variables: {
      query: props.query || '',
      nextToken,
      limit: 20,
      sortDirection: SortDirection.Asc,
    },
    // pause: !props.query?.trim(),
  });

  useEffect(() => {
    // If we change our search query, nextToken is no longer valid in this context
    // and so we should set it to undefined
    setNextToken(undefined);
  }, [props.query]);

  function onSkillPressed(skill: string) {
    navigate('SkillSummary', { name: skill });
  }

  function onPressSuggestNewSkill() {
    navigate('TakeSurvey', {
      placement: SystemSurveyPlacement.NewSkillSuggestion,
    });
  }

  return (
    <KeyboardAwareFlatList
      enableResetScrollToCoords={false}
      onEndReached={() => {
        if (data?.searchSkills.nextToken) {
          setNextToken(data.searchSkills.nextToken);
        }
      }}
      onEndReachedThreshold={0.2}
      onScrollBeginDrag={() => {
        // Dismiss keyboard whenever user begins scrolling
        Keyboard.dismiss();
      }}
      ListEmptyComponent={
        fetching ? null : props.query && error ? (
          <Text style={commonStyles.errorText}>
            There was a problem searching skills
          </Text>
        ) : props.query && !data?.searchSkills.items.length ? (
          <Text style={commonStyles.emptyText}>No results</Text>
        ) : (
          <Text style={commonStyles.emptyText}>Search by skill</Text>
        )
      }
      ListHeaderComponent={
        fetching || !props.query?.trim() ? null : (
          <View
            style={{
              padding: 24,
              alignSelf: 'center',
              maxWidth: 320,
              paddingBottom: 0,
            }}
          >
            <Text
              style={{
                fontFamily: 'Lato-Bold',
                fontSize: 16,
                color: 'gray',
                textAlign: 'center',
              }}
            >
              Can't find what you're looking for and would like us to add it?
            </Text>
            <Button
              label="Suggest New Skill"
              containerStyle={{
                marginVertical: 6,
                alignSelf: 'center',
              }}
              onPress={onPressSuggestNewSkill}
            />
          </View>
        )
      }
      ItemSeparatorComponent={() => <View style={commonStyles.itemSeparator} />}
      ListFooterComponent={
        <GenericListFooter
          hasMore={!!data?.searchSkills.nextToken}
          isFirstPage={!nextToken}
          loading={fetching || stale}
          onFetchMore={() => {
            if (data?.searchSkills.nextToken) {
              setNextToken(data.searchSkills.nextToken);
            }
          }}
        />
      }
      renderItem={({ item }) => {
        return (
          <TouchableOpacity
            key={item.name}
            onPress={() => onSkillPressed(item.name)}
            style={styles.itemContainer}
          >
            <Text style={styles.itemText}>{item.name}</Text>
          </TouchableOpacity>
        );
      }}
      style={{ flex: 1 }}
      data={data?.searchSkills.items}
    />
  );
}

const styles = StyleSheet.create({
  itemContainer: {
    padding: 12,
    height: 64,
    justifyContent: 'center',
  },
  itemText: {
    fontFamily: 'Lato-Bold',
    fontSize: 17,
  },
});
