import { UpdateResolver } from '@urql/exchange-graphcache';

const createGroupDiscussionPost: UpdateResolver = (result, args, cache) => {
  const groupId = (args as any).groupId;

  if (!groupId) return;

  cache.invalidate('Query', 'listGroupDiscussionCategories', {
    groupId,
  });
};

export default createGroupDiscussionPost;
