import {
  Descriptor,
  NavigationProp,
  ParamListBase,
  RouteProp,
  TabNavigationState,
  useTheme,
} from '@react-navigation/native';
import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  maxSize,
} from 'rn-responsive-styles';

import SideMenu from '@mtourj/react-native-side-menu';
import { BottomTabBarHeightContext } from '@react-navigation/bottom-tabs';
import { SafeAreaProviderCompat } from '@react-navigation/elements';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
  ResponsiveTabNavigationOptions,
  ResponsiveTabNavigatorProps,
} from '../navigators/createResponsiveTabNavigator';
import Header from './Header/Header';
import ResponsiveTabBar, { getTabBarHeight } from './ResponsiveTabBar';
import { MaybeScreen, MaybeScreenContainer } from './ScreenFallback';
import AccountSettingsDrawer from '../../../components/AccountSettingsDrawer/AccountSettingsDrawer';
import Sidebar from '/components/Sidebar';

function SceneContent({
  isFocused,
  children,
}: React.PropsWithChildren<{ isFocused: boolean }>) {
  const { styles } = useStyles();
  const { colors } = useTheme();

  return (
    <View
      accessibilityElementsHidden={!isFocused}
      importantForAccessibility={isFocused ? 'auto' : 'no-hide-descendants'}
      style={[styles('content'), { backgroundColor: colors.background }]}
    >
      {children}
    </View>
  );
}

type ResponsiveTabViewProps = ResponsiveTabNavigatorProps & {
  state: TabNavigationState<ParamListBase>;
  navigation: NavigationProp<ParamListBase>;
  descriptors: Record<
    string,
    Descriptor<
      ResponsiveTabNavigationOptions,
      NavigationProp<ParamListBase>,
      RouteProp<any>
    >
  >;
  lazy: boolean;
};

const ResponsiveTabView = (props: ResponsiveTabViewProps) => {
  const { styles, deviceSize } = useStyles();

  const { state, descriptors, lazy, detachInactiveScreens = true } = props;
  const { routes } = state;

  const focusedRouteKey = state.routes[state.index].key;
  const focusedRouteOptions = descriptors[focusedRouteKey].options;
  const focusedRouteState = state.routes[state.index].state;

  const [loaded, setLoaded] = useState([state.index]);

  const safeAreaInsets = useSafeAreaInsets();

  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const [isSidebarHidden, setSidebarHidden] = useState(false);

  const dimensions = SafeAreaProviderCompat.initialMetrics.frame;
  const tabBarHeight = useMemo(
    () =>
      getTabBarHeight({
        state,
        // @ts-ignore
        descriptors,
        dimensions,
        layout: { width: dimensions.width, height: 0 },
        insets: {
          ...SafeAreaProviderCompat.initialMetrics.insets,
        },
      }),
    [descriptors, dimensions, state],
  );

  useEffect(() => {
    // If deviceSize is LARGE_DEVICE or EXTRA_LARGE_DEVICE, then sidebar is not hidden
    if (
      deviceSize === DEVICE_SIZES.LARGE_DEVICE ||
      deviceSize === DEVICE_SIZES.EXTRA_LARGE_DEVICE
    ) {
      setSidebarHidden(false);
    } else setSidebarHidden(true);
  }, [deviceSize]);

  useEffect(() => {
    setLoaded((prevState) => [...prevState, state.index]);
  }, [state.index]);

  const onToggleSideDrawer = () => {
    setShowSideDrawer((prevState) => !prevState);
  };

  const onCloseSideDrawer = () => {
    setShowSideDrawer(false);
  };

  return (
    <SafeAreaProviderCompat>
      <View
        style={[
          StyleSheet.absoluteFill,
          {
            maxWidth: '100%',
          },
        ]}
      >
        <View style={[styles('container'), { backgroundColor: 'white' }]}>
          <View
            style={[
              styles('tabBarContainer'),
              {
                marginBottom: safeAreaInsets.bottom,
                paddingLeft: safeAreaInsets.left,
                paddingRight: safeAreaInsets.right,
              },
            ]}
          >
            <ResponsiveTabBar
              {...props.tabBarOptions}
              descriptors={props.descriptors}
              state={props.state}
              insets={safeAreaInsets}
              navigation={props.navigation}
            />
          </View>
          <MaybeScreenContainer
            enabled={detachInactiveScreens}
            hasTwoStates
            style={[
              styles('pages'),
              {
                maxWidth:
                  (!focusedRouteState?.index &&
                    focusedRouteOptions.contentMaxWidth) ||
                  // Give more room to admin screens
                  (focusedRouteOptions.isAdminScreen
                    ? CONTENT_MAX_WIDTH + 400
                    : CONTENT_MAX_WIDTH),
              },
            ]}
          >
            {routes.map((route, index) => {
              const descriptor = descriptors[route.key];
              if (!descriptor) return;
              const { unmountOnBlur, headerShown } = descriptor.options;
              const isFocused = state.index === index;

              if (unmountOnBlur && !isFocused) {
                return null;
              }

              if (lazy && !loaded.includes(index) && !isFocused) {
                // Don't render a screen if we've never navigated to it
                return null;
              }

              return (
                <MaybeScreen
                  key={route.key}
                  style={[
                    StyleSheet.absoluteFill,
                    { zIndex: isFocused ? 0 : -1 },
                  ]}
                  visible={isFocused}
                  enabled={detachInactiveScreens}
                >
                  <BottomTabBarHeightContext.Provider value={tabBarHeight}>
                    {headerShown === false ? null : (
                      <Header
                        onOpenSideDrawer={onToggleSideDrawer}
                        descriptor={descriptor}
                        navigation={props.navigation}
                      />
                    )}
                    <SceneContent isFocused={isFocused}>
                      {descriptor.render()}
                    </SceneContent>
                  </BottomTabBarHeightContext.Provider>
                </MaybeScreen>
              );
            })}
          </MaybeScreenContainer>
          <View style={styles('sidebar')}>
            {/* This side menu is only used when the sidebar is visible */}
            {/* @ts-ignore */}
            <SideMenu
              openMenuOffsetPercentage={0.88}
              style={{
                flex: 1,
              }}
              overlayStyle={{
                backgroundColor: 'gray',
                opacity: 0.1,
              }}
              disableGestures={isSidebarHidden}
              onChange={(isOpen: boolean) => setShowSideDrawer(isOpen)}
              menu={<AccountSettingsDrawer onCloseDrawer={onCloseSideDrawer} />}
              menuPosition="right"
              isOpen={showSideDrawer && !isSidebarHidden}
            >
              <Sidebar onOpenSideDrawer={onToggleSideDrawer} />
            </SideMenu>
          </View>
        </View>
      </View>
    </SafeAreaProviderCompat>
  );
};

ResponsiveTabView.defaultProps = {
  lazy: true,
};

const TAB_BAR_MAX_WIDTH = 280;
const CONTENT_MAX_WIDTH = 640;
const SIDEBAR_MAX_WIDTH = 400;

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flexDirection: 'row',
      flex: 1,
      overflow: 'hidden',
      justifyContent: 'center',
    },
    tabBarContainer: {
      width: '20%',
      maxHeight: '100%',
      backgroundColor: 'white',
      maxWidth: TAB_BAR_MAX_WIDTH,
    },
    sidebar: {
      maxHeight: '100%',
      width: SIDEBAR_MAX_WIDTH,
      maxWidth: '26%',
      overflow: 'hidden',
    },
    pages: {
      flex: 1,
      maxWidth: CONTENT_MAX_WIDTH,
      overflow: 'hidden',
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderColor: '#ddd',
    },
    content: {
      flex: 1,
    },
  },
  {
    [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
      tabBarContainer: {
        maxWidth: '9%',
      },
      sidebar: {
        maxWidth: '32%',
      },
    },
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      tabBarContainer: {
        maxWidth: '13%',
      },
    },
    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      pages: {
        borderRightWidth: 0,
      },
      sidebar: {
        display: 'none',
      },
    },
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      pages: {
        borderLeftWidth: 0,
      },
      tabBarContainer: {
        flex: 1,
        maxHeight: 48,
        width: '100%',
        maxWidth: '100%',
      },
      container: {
        flexDirection: 'column-reverse',
      },
    },
  },
);

export default ResponsiveTabView;
