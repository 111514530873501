import React from 'react';
import SvgXml from '/components/common/SvgXml';

export default function Language(props) {
  const xml = `<svg xmlns="http://www.w3.org/2000/svg" width="50.923" height="25.461" viewBox="0 0 50.923 25.461">
    <path id="language" d="M12.755,104.577h-.04s-.342,1.663-.621,2.626l-.875,2.968h3.016l-.851-2.968C13.1,106.24,12.755,104.577,12.755,104.577ZM49.013,96H1.91A1.905,1.905,0,0,0,0,97.91v21.642a1.905,1.905,0,0,0,1.91,1.91h47.1a1.905,1.905,0,0,0,1.91-1.91V97.91A1.905,1.905,0,0,0,49.013,96ZM18.555,116.369h-1.8a.955.955,0,0,1-.915-.684l-.74-2.522H10.336l-.724,2.514a.962.962,0,0,1-.915.692H6.906A.961.961,0,0,1,6,115.1l4.543-13.367a.951.951,0,0,1,.907-.644h2.562a.97.97,0,0,1,.907.644L19.462,115.1A.961.961,0,0,1,18.555,116.369Zm29.185,1.91H25.461v-19.1H47.74ZM29.6,106.5h8.816a14.886,14.886,0,0,1-2.061,3.063,21.638,21.638,0,0,1-1.456-1.91.949.949,0,0,0-1.281-.286l-1.09.652a.958.958,0,0,0-.3,1.353,25.047,25.047,0,0,0,1.87,2.435,19.9,19.9,0,0,1-2.284,1.687.945.945,0,0,0-.31,1.273l.629,1.106a.951.951,0,0,0,1.337.334,24.527,24.527,0,0,0,2.864-2.132,22.549,22.549,0,0,0,2.753,2.116.961.961,0,0,0,1.353-.326l.637-1.106a.959.959,0,0,0-.3-1.273,18.7,18.7,0,0,1-2.164-1.663c.119-.135.231-.263.342-.4a16.566,16.566,0,0,0,2.928-4.933h1.09a.958.958,0,0,0,.955-.955v-1.273a.958.958,0,0,0-.955-.955H37.874v-1.273a.958.958,0,0,0-.955-.955H35.646a.958.958,0,0,0-.955.955v1.273H29.6a.958.958,0,0,0-.955.955v1.273A.959.959,0,0,0,29.6,106.5Z" transform="translate(0 -96)" fill="#111"/>
    </svg>
    `;

  return <SvgXml xml={xml} width={30} height={30} fill="#000" {...props} />;
}
