import { AntDesign } from '@expo/vector-icons';
import { KeyboardAwareScrollView } from '@mtourj/react-native-keyboard-aware-scroll-view';
import React, { useEffect, useState } from 'react';
import {
  Modal,
  Pressable,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import Button from '/components/Button';
import DescribeSkills from '/components/SkilledImpact/DescribeSkills';
import SkillSelect from '/components/SkillSelect';
import { Skill, UserSkillPortfolio } from '/generated/graphql';

interface ISkillPortfolio
  extends Pick<
    UserSkillPortfolio,
    | 'links'
    | 'skillName'
    | 'media_carousel'
    | 'relevant_experience'
    | 'thumbnail'
  > {
  skill: Pick<Skill, 'name' | 'thumbnail'>;
}

interface Props {
  visible: boolean;
  data?: ISkillPortfolio[];
  onChange?: (data: ISkillPortfolio[]) => void;
  onClose?: () => void;
}

export default function EditSkillsModal(props: Props) {
  const [step, setStep] = useState<0 | 1>(0);

  useEffect(
    function resetOnClose() {
      if (!props.visible) {
        setStep(0);
      }
    },
    [props.visible],
  );

  /** Called when first step is complete */
  function onSkillsSelected(skills: string[]) {
    // Map over the new skill selection, including existing data for skills
    // that were already selected and not removed.
    const newSkills = skills.map((skillName) => {
      const existing = props.data?.find(
        (skill) => skill.skillName === skillName,
      );

      return {
        skillName,
        links: [],
        media_carousel: [],
        relevant_experience: '',
        ...existing,
      } as ISkillPortfolio;
    });

    props.onChange?.(newSkills);
  }

  return (
    <Modal animationType="slide" visible={props.visible}>
      <SafeAreaView style={styles.container}>
        <View style={styles.headerFooter}>
          <Pressable
            style={styles.closeButton}
            onPress={() => props.onClose?.()}
          >
            <AntDesign name="close" size={24} />
          </Pressable>
        </View>
        {step === 0 ? (
          <>
            <KeyboardAwareScrollView
              // Assign the step number as the key to make sure the next screen starts at scroll position 0
              key={'0'}
              enableResetScrollToCoords={false}
              style={{ flex: 1 }}
            >
              {/* Skill selection */}
              <View style={{ marginBottom: 16 }}>
                <Text
                  style={{
                    fontFamily: 'LeagueSpartan-Bold',
                    fontSize: 24,
                    marginBottom: 10,
                    marginTop: 15,
                  }}
                >
                  SKILLED IMPACT
                </Text>
                <Text
                  style={{
                    fontFamily: 'Lato-Bold',
                    fontSize: 16,
                    padding: 2,
                    lineHeight: 22,
                  }}
                >
                  The skilled impact feature connects conservation organizations
                  to skilled professionals who want to use their expertise to
                  help tackle projects in the field where their skill is needed.
                  {'\n\n'}
                  NOTE: Please only select skills that you currently have
                  experience with so we can ensure conservationists can receive
                  timely and impactful support.
                </Text>
                <Text
                  style={{
                    fontFamily: 'Lato',
                    fontSize: 14,
                    color: 'gray',
                    paddingVertical: 8,
                  }}
                >
                  You can add more details about your experience with each skill
                  in the next step.
                </Text>
              </View>
              <SkillSelect
                skills={props.data?.map((skill) => skill.skillName)}
                onSkillsChanged={(s) => onSkillsSelected(s)}
              />
            </KeyboardAwareScrollView>
            <View style={styles.headerFooter}>
              <Button label="Next" onPress={() => setStep(1)} />
            </View>
          </>
        ) : (
          <>
            <KeyboardAwareScrollView
              key={'1'}
              enableResetScrollToCoords={false}
              style={{ flex: 1 }}
            >
              {/* Skill portfolio input */}
              <DescribeSkills data={props.data} onChange={props.onChange} />
            </KeyboardAwareScrollView>
            <View style={styles.headerFooter}>
              <Button label="Done" onPress={props.onClose} />
              <Button label="Back" onPress={() => setStep(0)} />
            </View>
          </>
        )}
      </SafeAreaView>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 10,
    alignSelf: 'center',
    maxWidth: 800,
  },
  headerFooter: {
    padding: 10,
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 16,
  },
  closeButton: {
    padding: 10,
  },
});
