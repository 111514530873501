import {
  ActionSheetOptions,
  useActionSheet,
} from '@mtourj/react-native-action-sheet';
import ContextMenu, {
  ContextMenuAction,
} from '@mtourj/react-native-context-menu-view';
import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import { Pressable, StyleProp, StyleSheet, ViewStyle } from 'react-native';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles';

type Props = {
  style:
    | StyleProp<ViewStyle>
    | (({ pressed }: { pressed: boolean }) => ViewStyle | StyleProp<ViewStyle>)
    | undefined;
  disabled: boolean | undefined;
  options: ActionSheetOptions & {
    onPress: (index: number) => void;
  };
};

export interface ActionSheetPressableRef {
  /** Shows the action sheet */
  show: () => void;
}

const ActionSheetPressableCore = forwardRef<
  ActionSheetPressableRef,
  React.PropsWithChildren<Props>
>(({ options, ...props }: React.PropsWithChildren<Props>, ref) => {
  const { showActionSheetWithOptions } = useActionSheet();

  useImperativeHandle(ref, () => ({
    show: () => {
      showActionSheetWithOptions(options, options.onPress as any);
    },
  }));

  const contextMenuOptions: ContextMenuAction[] = options.options.map(
    (option, index) => ({
      title: option,
      subtitletitle: '',
      destructive: index === options.destructiveButtonIndex,
    }),
  );

  const { deviceSize } = useResponsiveStyle();

  const isLargeDevice = minSize(DEVICE_SIZES.MEDIUM_DEVICE).includes(
    deviceSize,
  );

  const Wrapper = isLargeDevice ? (_props: any) => _props.children : Pressable;

  const getWrapperStyle = useCallback(
    ({ pressed }: { pressed: boolean }) =>
      StyleSheet.flatten([
        typeof props.style === 'function'
          ? props.style({ pressed })
          : props.style,
        {
          pointerEvents:
            !isLargeDevice || props.disabled
              ? ('box-only' as 'box-only')
              : ('auto' as 'auto'),
        },
      ]),
    [props, isLargeDevice],
  );

  return (
    <Wrapper
      onPress={() => {
        showActionSheetWithOptions(options, options.onPress as any);
      }}
      style={getWrapperStyle}
      disabled={props.disabled}
    >
      <ContextMenu
        disabled={!isLargeDevice}
        actions={contextMenuOptions}
        onPress={(e) => {
          options.onPress(e.nativeEvent.index);
        }}
        style={isLargeDevice ? getWrapperStyle({ pressed: false }) : undefined}
        dropdownMenuMode
      >
        {props.children}
      </ContextMenu>
    </Wrapper>
  );
});

export default ActionSheetPressableCore;

const useResponsiveStyle = CreateResponsiveStyle({}, {});
