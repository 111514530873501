/**
 * For authentication and account management
 *
 * Displays user's profile image if signed in and provides account management menu
 * Otherwise displays a log in button
 */
import { Pressable, StyleSheet, Text, View } from 'react-native';

import { useAuthContext } from '/context';

import Avatar from './Avatar';
import { Feather } from '@expo/vector-icons';
import Button from './Button';
import {
  RouteProp,
  useLinkTo,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { CONTENT_PARAGRAPH_FONT_SIZE as CONTENT_PARAGRAPH_FONT_SIZE } from '/constants';
import getCDNImageUri from '/util/getCDNImageUri';
import { StackNavigationProp } from '@react-navigation/stack';

type ManageAccountWidgetProps = {
  onManageAccount: () => void;
};

export default function ManageAccountWidget(props: ManageAccountWidgetProps) {
  const { userData, isAuthenticating, fetching }: any = useAuthContext();

  const { navigate } = useNavigation<StackNavigationProp<any>>();
  const route = useRoute<RouteProp<any>>();
  const linkTo = useLinkTo();

  const onLogIn = () => {
    let params = '';

    if (route.path) {
      const returnTo = encodeURIComponent(route.path);
      params = `?returnto=${returnTo}`;
    }

    linkTo(`/login${params}`);
  };

  function goToProfile() {
    if (!userData?.id) return;

    navigate('ProfileStack', { screen: 'MyProfile' });
  }

  return (
    <View
      style={[
        styles.container,
        { opacity: isAuthenticating || fetching ? 0.5 : 1 },
      ]}
    >
      <Avatar
        containerStyle={{
          backgroundColor: 'gray',
        }}
        source={{
          uri: getCDNImageUri({
            uri: userData?.profile_image,
            dimensions: {
              width: 80,
              height: 80,
            },
          }),
        }}
        onPress={userData?.id && goToProfile}
        rounded
        size={50}
      />
      {isAuthenticating ? (
        <Text style={styles.text}>Authenticating...</Text>
      ) : fetching ? (
        <Text style={styles.text}>Fetching...</Text>
      ) : !userData?.id ? (
        <>
          <Button
            label="SIGN IN / SIGN UP"
            onPress={onLogIn}
            containerStyle={{
              marginHorizontal: 24,
            }}
            style={{
              paddingHorizontal: 16,
              paddingVertical: 0,
            }}
            labelStyle={{
              padding: 10,
              paddingVertical: 8,
            }}
          />
        </>
      ) : (
        <View style={styles.authenticatedContainer}>
          <Text onPress={goToProfile} numberOfLines={2} style={styles.text}>
            {userData.name}
          </Text>
          <Pressable onPress={props.onManageAccount}>
            <Feather size={28} name="menu" />
          </Pressable>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    textTransform: 'uppercase',
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: CONTENT_PARAGRAPH_FONT_SIZE,
    padding: 16,
  },
  authenticatedContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
