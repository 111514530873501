import { Ionicons } from '@expo/vector-icons';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Keyboard, Linking, Text, TouchableOpacity, View } from 'react-native';
import ConfirmForm from '../ConfirmForm/ConfirmForm';
import LoginForm from '../LoginForm/LoginForm';
import styles from './AuthForm.style';
import Alert from '/Alert';
import CheckmarkSwitch from '/components/CheckmarkSwitch';
import { SECTION_CONTAINER } from '/constants';
import { useAuthContext } from '/context';
import useBinaryTimingAnimation from '../../../hooks/useBinaryTimingAnimation';
import { isStrongPassword, isValidEmail } from '/util';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';
// import { useLinkProviderForCognitoUserMutation } from '/generated/graphql';

enum AuthFormMode {
  default = 'default',
  login = 'login',
  signup = 'signup',
  confirm_email = 'confirm_email',
}

export default function AuthForm() {
  const { navigate } = useNavigation<any>();
  const route = useRoute<RouteProp<any>>();

  const initEmail = route.params?.init_email?.trim();
  const armLinkUsername = route.params?.arm_link;

  const [email, _setEmail_] = useState(initEmail || '');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const { signUp, logIn, federatedLogIn } = useAuthContext();

  const [mode, setMode] = useState(AuthFormMode.default);

  const [acceptsTerms, setAcceptsTerms] = useState(false);

  const forgotPasswordOpacity = useBinaryTimingAnimation({
    value: mode === AuthFormMode.login,
  });
  const forgotPasswordAnimatedStyle = useAnimatedStyle(() => ({
    opacity: forgotPasswordOpacity.value,
  }));

  // const [, linkProviderForUser] = useLinkProviderForCognitoUserMutation();

  useEffect(() => {
    if (mode === AuthFormMode.default) {
      setPassword('');
      setConfirmPassword('');
    }
  }, [mode]);

  const setEmail = (newEmail: string) => {
    if (mode !== AuthFormMode.default) {
      setMode(AuthFormMode.default);
    }
    _setEmail_(newEmail);
  };

  const onSubmit = useCallback(async () => {
    try {
      if (!isValidEmail(email)) {
        Alert.alert(
          'Invalid email',
          'Please make sure you have typed your email correctly',
        );
        return;
      }

      if (mode !== AuthFormMode.default && !password) {
        Alert.alert('Missing password');
        return;
      }

      Keyboard.dismiss();

      switch (mode) {
        case AuthFormMode.default: {
          await logIn(email, '-');

          break;
        }
        case AuthFormMode.login: {
          await logIn(email, password);

          // await linkProviderForUser({
          //   username: armLinkUsername,
          // });

          break;
        }
        case AuthFormMode.signup: {
          // Fails regex?
          if (!isStrongPassword(password)) {
            Alert.alert(
              'Password too weak',
              'Password must contain between 8 and 24 characters, no spaces and at least 3 of the following: 1 uppercase letter, 1 lower case letter, 1 number and 1 special character',
            );
            return;
          }

          // Is too long?
          if (password.length > 24) {
            Alert.alert(
              'That password is too long. Passwords cannot be longer than 24 characters.',
            );
            return;
          }

          // Do the passwords match?
          if (password !== confirmPassword) {
            Alert.alert(
              'Passwords do not match',
              'Please make sure your passwords match',
            );
            return;
          }

          if (!acceptsTerms) {
            Alert.alert(
              'Terms and Conditions',
              'Please accept the terms and conditions to continue',
            );
            return;
          }

          await signUp(email, password);

          // If sign up was successful, we want to switch to the 'confirm your email' view.
          setMode(AuthFormMode.confirm_email);

          break;
        }
      }
    } catch (err: any) {
      console.log('err name', err.name);
      switch (err.name) {
        // If user has not yet confirmed their account, navigate to confirm tab
        case 'UserNotConfirmedError': {
          Alert.alert(
            'Notice',
            'You must confirm your email address before you can access your account. Check your email for a confirmation code.',
          );
          setMode(AuthFormMode.confirm_email);
          break;
        }
        case 'UserNotFoundError': {
          setMode(AuthFormMode.signup);
          break;
        }
        case 'AttemptLimitExceededError': {
          Alert.alert('Attempt limit exceeded. Please try again later.');
          break;
        }
        case 'UserAlreadyExistsError': {
          Alert.alert(
            'An account with that email address already exists. Please log into that account or use a different email.',
          );
          break;
        }
        case 'IncorrectLoginError': {
          if (mode !== AuthFormMode.login) {
            setMode(AuthFormMode.login);
          } else {
            Alert.alert('Incorrect username and password combination');
          }
          break;
        }
        default: {
          Alert.alert(
            `There was a problem ${
              mode === AuthFormMode.signup ? 'signing up' : 'logging in'
            }. Please try again later.`,
          );
        }
      }
    }
  }, [acceptsTerms, confirmPassword, email, logIn, mode, password, signUp]);

  const hasInitialized = useRef(false);
  useEffect(() => {
    if (hasInitialized.current || !initEmail) return;
    hasInitialized.current = true;
    onSubmit();
  }, [initEmail, onSubmit]);

  async function onConfirmSuccess() {
    try {
      if (!password) throw new Error();

      await logIn(email, password);
    } catch (err) {
      // If login fails, we want to switch back to the login tab to allow the user to try entering their credentials again.
      Alert.alert(
        'Success!',
        'Your email has been confirmed. Please enter your credentials again to log in.',
      );
      setMode(AuthFormMode.login);
    }
  }

  const instructionText = useMemo(() => {
    switch (mode) {
      case AuthFormMode.default: {
        return 'Enter your email to continue';
      }
      case AuthFormMode.login: {
        return `Enter your password to ${
          armLinkUsername ? 'finish linking' : 'sign in'
        }`;
      }
      case AuthFormMode.signup: {
        return 'Create a password to sign up';
      }
      case AuthFormMode.confirm_email: {
        return null;
      }
    }
  }, [armLinkUsername, mode]);

  return (
    <View
      style={{
        width: '100%',
        borderRadius: 8,
      }}
    >
      {mode === AuthFormMode.confirm_email ? (
        <ConfirmForm
          onConfirmSuccess={onConfirmSuccess}
          onCancel={() => setMode(AuthFormMode.login)}
          email={email}
        />
      ) : (
        <>
          <View style={styles.formContainer}>
            <View style={styles.formContent}>
              {instructionText && (
                <View style={styles.instructionTextContainer}>
                  {mode !== AuthFormMode.default ? (
                    <Ionicons
                      name="arrow-back"
                      size={24}
                      color="white"
                      style={{
                        marginRight: 10,
                        paddingBottom: 8,
                      }}
                      onPress={() => {
                        setMode(AuthFormMode.default);
                      }}
                    />
                  ) : null}
                  <Text style={styles.instructionText}>{instructionText}</Text>
                </View>
              )}
              <LoginForm
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                mode={mode}
                onSubmit={onSubmit}
                onFederatedSignIn={federatedLogIn}
              />

              {mode === AuthFormMode.signup ? (
                <View
                  style={{
                    flexDirection: 'row',
                    ...SECTION_CONTAINER,
                    marginHorizontal: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.95)',
                  }}
                >
                  <CheckmarkSwitch
                    value={acceptsTerms}
                    onValueChange={setAcceptsTerms}
                    style={{
                      marginRight: 6,
                    }}
                  />
                  <Text style={styles.acceptsTermsText}>
                    I have read and agree to the{' '}
                    <Text
                      onPress={() => {
                        Linking.openURL(
                          'https://www.keyconservation.org/termsandconditions',
                          // @ts-ignore
                          '_blank',
                        );
                      }}
                      style={styles.acceptsTermsTextBold}
                    >
                      Terms of Use
                    </Text>{' '}
                    and{' '}
                    <Text
                      onPress={() => {
                        Linking.openURL(
                          'https://www.keyconservation.org/privacypolicy',
                          // @ts-ignore
                          '_blank',
                        );
                      }}
                      style={styles.acceptsTermsTextBold}
                    >
                      Privacy Policy
                    </Text>
                  </Text>
                </View>
              ) : null}

              <Animated.View
                style={[styles.footnoteContainer, forgotPasswordAnimatedStyle]}
              >
                <TouchableOpacity
                  disabled={mode !== AuthFormMode.login}
                  testID="resetPasswordButton"
                  onPress={() => {
                    mode !== AuthFormMode.signup &&
                      navigate('ResetPassword', {
                        email: email,
                      });
                  }}
                >
                  <Text style={styles.footnote}>Forgot your password?</Text>
                </TouchableOpacity>
              </Animated.View>
            </View>
          </View>
        </>
      )}
    </View>
  );
}
