import SvgXml from '/components/common/SvgXml';

const xml = `<svg id="Group_8153" data-name="Group 8153" xmlns="http://www.w3.org/2000/svg" width="32.916" height="29.494" viewBox="0 0 32.916 29.494">
<path id="Path_21680" data-name="Path 21680" d="M32.23,41.014h-5L25.6,37.455a.69.69,0,0,0-1.289.1l-.994,3.586-1.3-7.475a.694.694,0,0,0-.686-.569.685.685,0,0,0-.665.6l-1,7.427L18.412,27.237a.686.686,0,0,0-.686-.624H17.72a.693.693,0,0,0-.679.645L15.875,45.437l-1.09-14.805a.688.688,0,0,0-1.371-.014L12.186,43.476l-1.042-8.311a.684.684,0,0,0-1.351-.048l-1.3,6.6L7.5,38.614a.685.685,0,0,0-.864-.446.651.651,0,0,0-.37.288L4.656,41.014H.686a.686.686,0,1,0,0,1.371H5.033a.7.7,0,0,0,.583-.322l1.029-1.632L8.01,44.655a.683.683,0,0,0,1.323-.082l.987-5.02L11.6,49.819a.688.688,0,0,0,.679.6h.007a.689.689,0,0,0,.672-.617L14,38.86l1.221,16.609a.693.693,0,0,0,.686.638.678.678,0,0,0,.679-.645l1.241-19.3L18.858,47.3a.689.689,0,0,0,.672.624.708.708,0,0,0,.693-.6l1.221-9.031L22.5,44.374a.694.694,0,0,0,.645.569.674.674,0,0,0,.693-.5l1.31-4.711,1.029,2.249a.678.678,0,0,0,.624.4H32.23a.682.682,0,1,0,0-1.365Z" transform="translate(0 -26.613)"/>
</svg>
`;

export default function Activity(props) {
  return <SvgXml xml={xml} fill="#333" width={36} height={36} {...props} />;
}
