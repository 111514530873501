import React, { useState } from 'react';
import SpeciesPicker from '/components/SpeciesPicker/SpeciesPicker';
import { ISpeciesPickerSpecies } from '/components/SpeciesPicker/SpeciesPickerModal';
import Button from '/components/Button';
import { KEY_GREEN } from '/constants';
import { View } from 'react-native';

interface Props {
  initialState: ISpeciesPickerSpecies[];
  onRequestClose: (filter: ISpeciesPickerSpecies[]) => void;
}

export default function SpeciesFilterModal(
  props: React.PropsWithChildren<Props>,
) {
  const [state, setState] = useState<ISpeciesPickerSpecies[]>(
    props.initialState,
  );

  return (
    <View
      style={{
        paddingBottom: 2,
      }}
    >
      <SpeciesPicker data={state} setData={setState} />
      <Button
        label="Apply"
        containerStyle={{
          marginTop: 8,
          alignSelf: 'flex-end',
        }}
        style={{
          backgroundColor: KEY_GREEN,
        }}
        onPress={() => {
          props.onRequestClose(state);
        }}
      />
    </View>
  );
}
