import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import React, { forwardRef, useMemo } from 'react';
import {
  PressableStateCallbackType,
  StyleProp,
  TextInput,
  ViewStyle,
} from 'react-native';
import ActionSheetPressableCore, {
  ActionSheetPressableRef,
} from './ActionSheetPressableCore';
import Alert from '/Alert';
import { KEY_GREEN, TEXT_INPUT_LARGE } from '/constants';
import { useAuthContext, useLoadingContext, useTeamContext } from '/context';
import {
  Campaign,
  CampaignPost,
  TeamMemberRole,
  TranslatableText,
  User,
  useAdminHideCampaignMutation,
  useAdminUnhideCampaignMutation,
  useArchiveCampaignMutation,
  useCloseCampaignMutation,
  useDeleteCampaignUpdateMutation,
} from '/generated/graphql';

// Usage:

// To show
/*
...
  this.ActionSheet.current?.show();
...
*/

interface IUser extends Pick<User, 'id'> {}

interface ICampaign extends Pick<Campaign, 'id' | 'closed' | 'hidden_reason'> {
  user: IUser | undefined | null;
}

interface ICampaignPost
  extends Pick<CampaignPost, 'id' | 'is_update' | 'created_at'> {
  user?: IUser | null;
  campaign?: ICampaign | null;
  description?: Omit<TranslatableText, 'id'>;
}

type CampaignActionSheetProps = {
  post: ICampaignPost | undefined | null;
  campaign: ICampaign | undefined | null;
  /**
   * Should we navigate to previous screen after a destructive action has been taken?
   */

  goBack?: boolean;
  style?:
    | StyleProp<ViewStyle>
    | ((state: PressableStateCallbackType) => StyleProp<ViewStyle>);
  disabled?: boolean;
};

export default forwardRef<
  ActionSheetPressableRef,
  React.PropsWithChildren<CampaignActionSheetProps>
>((props: React.PropsWithChildren<CampaignActionSheetProps>, ref) => {
  const { teams } = useTeamContext();

  const { userData, isAdmin } = useAuthContext();

  const { navigate } = useNavigation<any>();

  const { setShowLoading, setLoadingInfo }: any = useLoadingContext();

  const [, deleteCampaignUpdate] = useDeleteCampaignUpdateMutation();
  const [, _archiveCampaign] = useArchiveCampaignMutation();
  const [, _closeCampaign] = useCloseCampaignMutation();
  const [, _hideCampaign] = useAdminHideCampaignMutation();
  const [, _unhideCampaign] = useAdminUnhideCampaignMutation();

  const isMine = useMemo(() => {
    const user = props.campaign?.user || props.post?.user;

    if (!user) {
      return false;
    }

    return (
      userData?.id === user?.id ||
      teams.some((team) => {
        // isMine should also be true if we are an admin on the team of the org that owns
        // this campaign
        return (
          team.user.id === user?.id &&
          team.membership?.team_role === TeamMemberRole.Admin
        );
      })
    );
  }, [userData, teams, props.campaign, props.post]);

  const report = () => {
    const id = props.post?.id;

    if (typeof id === 'undefined') {
      console.warn(
        'CampaignActionSheet: `post` property missing or invalid - action canceled',
      );
      return;
    }

    // Take the user to a report screen
    navigate('CreateReport', {
      type: 'campaign',
      id: (props.campaign ?? props.post?.campaign)?.id,
      data: {
        id: props.campaign?.id,
        user: props.post?.user,
        description: props.post?.description?.text ?? '',
      },
    });
  };

  const deletePost = async () => {
    const id = props.post?.id;
    if (!id) {
      console.warn(
        'CampaignActionSheet: `post` property missing or invalid - action canceled',
      );
      return;
    }

    try {
      const { error } = await deleteCampaignUpdate({ id });
      if (error) throw error;

      Alert.notify({
        message: 'Post deleted!',
        color: KEY_GREEN,
      });
    } catch (err) {
      console.log(err);

      Alert.alert(
        'Failed to Delete',
        'There was a problem deleting this post. Please report this issue if it persists by pressing anywhere with 3 fingers.',
      );
    }
  };

  const archiveCampaign = async () => {
    try {
      if (!props.campaign?.id) {
        throw new Error(
          'Cannot archive campaign because a campaign ID could not be found ',
        );
      }

      const { error } = await _archiveCampaign({
        archiveCampaignId: props.campaign.id,
      });

      if (error) throw error;

      Alert.notify({
        message: 'Campaign archived!',
        color: KEY_GREEN,
      });
    } catch (error) {
      console.log(error);
      Alert.alert(
        'Oh no',
        'We ran into a problem while trying to archive this campaign. Please try again later.',
      );
    }
  };

  const closeCampaign = async () => {
    if (!props.campaign?.id) {
      console.warn(
        'CampaignActionSheet: `campaign.id` property is missing, cannot manage campaign',
      );
      return;
    }
    /** If this is our own post, then go to CloseCampaign screen where user can follow a checklist */
    if (isMine) {
      navigate('CloseCampaign', {
        campaignId: props.campaign.id,
      });
    } else {
      // Otherwise, we must be an admin who has not ownership of this campaign, so just skip directly
      // to the part where we close the campaign
      Alert.alert(
        'Close Campaign',
        'Are you sure you want to close this campaign?',
        [
          {
            text: 'Confirm',
            style: 'destructive',
            onPress: _close,
          },
          { style: 'cancel' },
        ],
      );
    }

    async function _close() {
      try {
        setShowLoading(true);
        setLoadingInfo('Closing campaign...');
        if (!props.campaign?.id) {
          throw new Error(
            'Cannot close campaign because a campaign ID could not be found ',
          );
        }
        const { error } = await _closeCampaign({ id: props.campaign.id });
        if (error) throw error;
        Alert.notify({
          message: 'Campaign closed successfully',
          color: KEY_GREEN,
          textColor: 'white',
        });
      } catch (error) {
        console.log('Failed to close campaign:', error);
        Alert.alert(
          'Oh no',
          'We ran into a problem while trying to close this campaign. Please try again later.',
        );
      } finally {
        setShowLoading(false);
        setLoadingInfo('');
      }
    }
  };

  function hideCampaign() {
    if (!props.campaign?.id) {
      console.warn(
        'CampaignActionSheet: `campaign.id` property is missing, cannot hide campaign',
      );
      return;
    }

    async function _hide(campaignId: string, reason: string) {
      if (!reason?.trim()) {
        Alert.alert(
          'You must provide a reason to hide this campaign.',
          undefined,
          [
            {
              text: 'Got it',
              onPress: promptHideReason,
            },
          ],
        );
        return;
      }

      try {
        const { error } = await _hideCampaign({
          campaignId,
          reason,
        });

        if (error) throw error;

        Alert.notify({
          message: 'Campaign hidden!',
          color: KEY_GREEN,
        });
      } catch (error) {
        console.log(error);
        Alert.alert(
          'Oh no',
          'There was a problem hiding this campaign. Please try again later.',
        );
      }
    }

    function promptHideReason() {
      Alert.alert(
        'Hide Campaign (Admin)',
        'To hide this campaign, you must provide a reason. This will be displayed to the user.',
        [
          {
            style: 'cancel',
          },
          {
            style: 'destructive',
            text: 'Hide',
            onPress: (e) => _hide(props.campaign!.id, e.values.reason),
          },
        ],
        [
          {
            name: 'reason',
            initialValue: '',
            renderComponent(value, setValue) {
              return (
                <TextInput
                  value={value}
                  onChangeText={setValue}
                  autoFocus
                  maxLength={250}
                  multiline
                  style={TEXT_INPUT_LARGE}
                />
              );
            },
            label: 'REASON',
          },
        ],
      );
    }

    promptHideReason();
  }

  function unhideCampaign() {
    if (!props.campaign?.id) {
      console.warn(
        'CampaignActionSheet: `campaign.id` property is missing, cannot unhide campaign',
      );
      return;
    }

    async function _unhide() {
      try {
        const { error } = await _unhideCampaign({
          campaignId: props.campaign!.id,
        });

        if (error) throw error;

        Alert.notify({
          message: 'Campaign unhidden!',
          color: KEY_GREEN,
        });
      } catch (error) {
        console.log(error);
        Alert.alert(
          'Oh no',
          'There was a problem unhiding this campaign. Please try again later.',
        );
      }
    }

    Alert.alert(
      'Unhide Campaign (Admin)',
      'Are you sure you want to unhide this campaign?',
      [
        {
          style: 'cancel',
        },
        {
          style: 'destructive',
          text: 'Unhide',
          onPress: _unhide,
        },
      ],
    );
  }

  const editPost = () => {
    if (!props.post) {
      console.warn(
        'CampaignActionSheet: `post` property is missing, cannot edit CampaignPost',
      );
      return;
    }
    if (!props.campaign && !props.post?.campaign) {
      console.warn(
        'CampaignActionSheet: `campaign` property is missing and campaign was not found on `post` property, cannot edit CampaignPost',
      );
      return;
    }

    navigate('EditCampaign', {
      postId: props.post.id,
      // campaign: props.campaign || props.post?.campaign,
    });
  };

  const manageCampaign = () => {
    if (!props.campaign?.id) {
      console.warn(
        'CampaignActionSheet: `campaign.id` property is missing, cannot manage campaign',
      );
      return;
    }

    navigate('ManageCampaign', {
      campaignId: props.campaign.id,
    });
  };

  const postUpdate = () => {
    if (!props.campaign?.id) return;

    navigate('CreateCampaignUpdate', {
      campaignId: props.campaign.id,
    });
  };

  // Options for actions to take on a campaign vary
  // depending on the user, so we use this function
  // to initialize the options we want
  const generateActionSheetOptions = () => {
    const options: string[] = [];

    // Define labels here
    const labels = {
      delete: 'Delete Update',
      archive: 'Archive Campaign',
      close: 'Close Campaign',
      hide: 'Hide Campaign (Admin)',
      unhide: 'Unhide Campaign (Admin)',
      edit: 'Edit',
      manage: 'Manage Campaign',
      postUpdate: 'Post an Update',
      report: 'Report',
      cancel: 'Cancel',
    };

    const isLessThan15MinutesOld =
      Number(props.post?.created_at ?? '0') < Date.now() + 36000 * 15; // 15 Minutes

    // Construct options[]
    if ((isMine || isAdmin) && props.post?.is_update && isLessThan15MinutesOld)
      options.push(labels.delete);
    if (isMine && props.campaign?.closed) options.push(labels.archive);
    if (
      (isMine || isAdmin) &&
      !(props.campaign ?? props.post?.campaign)?.closed
    )
      options.push(labels.close);
    if (isAdmin && !props.campaign?.hidden_reason) options.push(labels.hide);
    else if (isAdmin && props.campaign?.hidden_reason)
      options.push(labels.unhide);
    if (!!userData?.id && !isMine) options.push(labels.report);
    if (isMine) {
      options.push(labels.edit);
      options.push(labels.manage);
      options.push(labels.postUpdate);
    }
    options.push(labels.cancel);

    const cancelButtonIndex = options.length - 1; // Cancel option is always the last one.
    // Destructive option is at the top in all cases except if user is not signed in.
    const destructiveButtonIndex = !userData?.id ? undefined : 0;

    return {
      title: isAdmin ? 'Actions (Admin)' : 'Actions',
      options,
      cancelButtonIndex,
      destructiveButtonIndex,
      /*
       * Icons appear on Android & Web only
       */
      icons: options.map((option) => {
        switch (option) {
          case labels.delete: {
            return <Ionicons name="trash-bin-outline" />;
          }
          case labels.edit: {
            return <Ionicons name="pencil-outline" />;
          }
          case labels.postUpdate: {
            return <Ionicons name="create-outline" />;
          }
          case labels.report: {
            return <Ionicons name="alert-circle-outline" />;
          }
          default:
            return undefined;
        }
      }),
      onPress: (index: number | undefined) => {
        if (typeof index !== 'number') {
          console.warn(
            'CampaignActionSheet onPress called but an index was not provided',
          );
          return;
        }

        const buttonLabel = options[index];

        switch (buttonLabel) {
          case labels.delete: {
            Alert.alert(
              'Delete Post',
              "Are you sure you'd like to delete this post?",
              [
                {
                  text: 'Cancel',
                  style: 'cancel',
                },
                {
                  text: 'Delete',
                  style: 'destructive',
                  onPress: deletePost,
                },
              ],
            );
            break;
          }
          case labels.archive: {
            Alert.alert(
              'Archive Campaign',
              'Are you sure you want to archive this campaign? Users will still be able to access it but will not be able to interact with it',
              [
                {
                  style: 'cancel',
                },
                {
                  text: 'Archive',
                  style: 'destructive',
                  onPress: archiveCampaign,
                },
              ],
            );
            break;
          }
          case labels.close: {
            closeCampaign();
            break;
          }
          case labels.hide: {
            hideCampaign();
            break;
          }
          case labels.unhide: {
            unhideCampaign();
            break;
          }
          case labels.edit: {
            editPost();
            break;
          }
          case labels.manage: {
            manageCampaign();
            break;
          }
          case labels.postUpdate: {
            postUpdate();
            break;
          }
          case labels.report: {
            report();
            break;
          }
          default: {
            break;
          }
        }
      },
    };
  };

  const actionSheetOptions = generateActionSheetOptions();

  return (
    <ActionSheetPressableCore
      ref={ref}
      disabled={props.disabled}
      options={actionSheetOptions}
      style={props.style}
    >
      {props.children}
    </ActionSheetPressableCore>
  );
});
