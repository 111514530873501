import { UpdateResolver } from '@urql/exchange-graphcache';

const deleteSpeciesUserContentFact: UpdateResolver = (result, args, cache) => {
  cache.invalidate({
    __typename: 'SpeciesUserContentFact',
    id: args.id as string,
  });
};

export default deleteSpeciesUserContentFact;
