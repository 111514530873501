import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    width: '100%',
    padding: 8,
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: 6,
  },
  title: {
    fontFamily: 'Lato-Bold',
    fontSize: 17,
    marginBottom: 2,
  },
  bodyPreview: {
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    color: 'gray',
  },
});
