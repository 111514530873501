import {
  createStackNavigator,
  StackNavigationOptions,
} from '@react-navigation/stack';
import { getStackNavigatorOptions } from './common';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import React from 'react';
import LoadingSpinnerOverlay from '/components/LoadingSpinnerOverlay';

const createScreen = (
  Component: React.LazyExoticComponent<React.ComponentType<any>>,
) => {
  return (props: any) => (
    <React.Suspense fallback={<LoadingSpinnerOverlay />}>
      <Component {...props} />
    </React.Suspense>
  );
};

const Surveys = createScreen(
  React.lazy(() => import('/screens/admin/Surveys')),
);
const CreateSurveyScreen = createScreen(
  React.lazy(() => import('/screens/admin/Surveys/CreateSystemSurveyScreen')),
);
const EditSurveyScreen = createScreen(
  React.lazy(() => import('/screens/admin/Surveys/EditSystemSurveyScreen')),
);
const ViewSurveyScreen = createScreen(
  React.lazy(() => import('/screens/admin/Surveys/ViewSurveyScreen')),
);
const ViewSurveySubmissionScreen = createScreen(
  React.lazy(() => import('/screens/admin/Surveys/ViewSurveySubmissionScreen')),
);

const Stack = createStackNavigator();

export default function SruveyStack() {
  const safeAreaInsets = useSafeAreaInsets();
  return (
    <Stack.Navigator
      initialRouteName="Surveys"
      screenOptions={getStackNavigatorOptions(safeAreaInsets)}
    >
      <Stack.Screen
        component={Surveys}
        name="Surveys"
        options={SurveysScreenOptions}
      />
      <Stack.Screen
        component={ViewSurveyScreen}
        name="ViewSurvey"
        options={ViewSurveyScreenOptions}
      />
      <Stack.Screen
        component={CreateSurveyScreen}
        name="CreateSurvey"
        options={CreateSurveyScreenOptions}
      />
      <Stack.Screen
        component={EditSurveyScreen}
        name="EditSurvey"
        options={EditSurveyScreenOptions}
      />
      <Stack.Screen
        component={ViewSurveySubmissionScreen}
        name="ViewSurveySubmission"
        options={ViewSurveySubmissionScreenOptions}
      />
    </Stack.Navigator>
  );
}

const SurveysScreenOptions = (): StackNavigationOptions => ({
  title: 'Surveys',
  headerTitle: 'SURVEYS',
});

const ViewSurveyScreenOptions = (): StackNavigationOptions => ({
  title: 'View Survey',
  headerTitle: 'VIEW SURVEY',
});

const CreateSurveyScreenOptions = (): StackNavigationOptions => ({
  title: 'Create Survey',
  headerTitle: 'CREATE SURVEY',
});

const EditSurveyScreenOptions = (): StackNavigationOptions => ({
  title: 'Edit Survey',
  headerTitle: 'EDIT SURVEY',
});

const ViewSurveySubmissionScreenOptions = (): StackNavigationOptions => ({
  title: 'Survey Submission',
  headerTitle: 'SURVEY SUBMISSION',
});
