import {
  StyleSheet,
  Text,
  TouchableOpacity,
  Linking,
  ViewStyle,
} from 'react-native';
import React from 'react';
import { TitledLink } from '/generated/graphql';
import { formatURL } from '/util';
import { Entypo, FontAwesome } from '@expo/vector-icons';
import { KEY_GRAY } from '/constants';

interface ILinksViewerProps {
  links: TitledLink[] | undefined;
  buttonStyle?: ViewStyle;
}

export default function LinksViewer(props: ILinksViewerProps) {
  return (
    <>
      {props.links?.map((link, index) => (
        <TouchableOpacity
          key={index}
          onPress={() => {
            // @ts-ignore - untyped
            Linking.openURL(formatURL(link!.url), '__blank');
          }}
          style={[styles.linkButton, props.buttonStyle]}
        >
          <Entypo name="link" color={KEY_GRAY} size={32} />
          <Text numberOfLines={2} style={styles.linkButtonText}>
            {link!.title}
            {`  `}
            <FontAwesome name="external-link" color={KEY_GRAY} size={14} />
          </Text>
        </TouchableOpacity>
      ))}
    </>
  );
}

const styles = StyleSheet.create({
  linkButton: {
    backgroundColor: '#eee',
    borderRadius: 6,
    padding: 8,
    marginVertical: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  linkButtonText: {
    flex: 1,
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    marginLeft: 4,
    fontSize: 15,
    textAlignVertical: 'center',
  },
});
