import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';
import removeItemFromPaginatedField from '/util/cache/removeItemFromPaginatedField';

const InteractionsFragment = gql`
  fragment RemoveCommentInteractionsFragment on PostInteractions {
    id
    comments(limit: $limit, nextToken: $nextToken) {
      # total
      items {
        id
      }
    }
  }
`;

const removeComment: UpdateResolver = (result, args, cache) => {
  const interactionsId = (result?.removeComment as any)?.id;

  removeItemFromPaginatedField({
    cache,
    entity: {
      __typename: 'PostInteractions',
      id: interactionsId as string,
    },
    paginatedField: {
      name: 'comments',
      fragment: InteractionsFragment,
    },
    removeItem: {
      id: args.commentId as string,
    },
  });
};

export default removeComment;
