import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';

const followResearchTopic: UpdateResolver = (result, args, cache) => {
  const fragment = gql`
    fragment FollowResearchTopic on ResearchTopic {
      topic
      is_following
      follower_count
    }
  `;

  const data = cache.readFragment(fragment, { topic: args.topic }) as any;

  cache.writeFragment(fragment, {
    topic: args.topic,
    is_following: true,
    follower_count: (data?.follower_count ?? 0) + 1,
  });
};

export default followResearchTopic;
