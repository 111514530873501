import { Ionicons } from '@expo/vector-icons';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import Avatar from '../Avatar';
import HorizontalContainer from '../common/Generic/HorizontalContainer';
import MapMarker from '/assets/jsicons/headerIcons/map-marker';
import ChevronRight from '/assets/jsicons/miscIcons/ChevronRight';
import { KEY_GRAY } from '/constants';
import { Group, Location, PaginatedGroupMembers } from '/generated/graphql';

interface IGroup extends Pick<Group, 'name' | 'group_logo'> {
  location?: Pick<Location, 'name'> | null;
  members: Pick<PaginatedGroupMembers, 'total'>;
}

interface IGroupCardProps {
  item: IGroup;
  onPress: () => void;
}

export default function GroupCard({ item, onPress }: IGroupCardProps) {
  return (
    <Pressable onPress={() => onPress()} style={styles.itemContainer}>
      <Avatar
        source={{
          uri: item.group_logo ?? '',
        }}
        rounded
        size={56}
        containerStyle={{
          marginRight: 8,
        }}
      />
      <View style={{ flex: 1 }}>
        <Text style={styles.groupNameText} numberOfLines={2}>
          {item.name}
        </Text>
        {item.location?.name && (
          <HorizontalContainer>
            <MapMarker width={17} height={17} fill="black" />
            <Text numberOfLines={1} style={styles.groupDescriptionText}>
              {` `}
              {item.location?.name}
            </Text>
          </HorizontalContainer>
        )}
        <HorizontalContainer>
          <Ionicons name="people" size={17} color="black" />
          <Text style={styles.groupDescriptionText}>
            {` `}
            {item.members.total} member{item.members.total === 1 ? '' : 's'}
          </Text>
        </HorizontalContainer>
      </View>

      <ChevronRight />
    </Pressable>
  );
}

const styles = StyleSheet.create({
  itemContainer: {
    padding: 12,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  groupNameText: {
    fontFamily: 'Lato-Bold',
    fontSize: 17,
    textTransform: 'uppercase',
  },
  groupDescriptionText: {
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    color: KEY_GRAY,
  },
});
