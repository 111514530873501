import React, { useMemo } from 'react';
import {
  Pressable,
  StyleProp,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import { VolunteerRequestDifficulty } from '/generated/graphql';
import styles from './VolunteerDifficultyButton.style';

interface IDifficultyButtonProps {
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  difficulty: VolunteerRequestDifficulty;
  selectedDifficulty?: VolunteerRequestDifficulty | undefined;
  onSelect?: (difficulty: VolunteerRequestDifficulty) => void;
}

export default function VolunteerDifficultyButton(
  props: IDifficultyButtonProps,
) {
  const { difficulty, selectedDifficulty, onSelect } = props;

  const isSelected = difficulty === selectedDifficulty;

  const difficultyText = useMemo(() => {
    switch (difficulty) {
      case VolunteerRequestDifficulty.Difficult:
        return 'hard';
      default:
        return difficulty;
    }
  }, [difficulty]);

  return (
    <View style={[{ flexGrow: 1, flexBasis: 1 }, props.style]}>
      <Pressable
        pointerEvents={props.disabled ? 'none' : 'auto'}
        style={[
          styles.difficultyButton,
          isSelected && styles.difficultyButtonSelected,
        ]}
        onPress={() => onSelect?.(difficulty)}
      >
        <Text
          numberOfLines={1}
          style={[styles.difficultyButtonLabel, props.labelStyle]}
        >
          {difficultyText}
        </Text>
      </Pressable>
    </View>
  );
}
