import { Resolver, Variables } from '@urql/exchange-graphcache';
import { stringifyVariables } from 'urql';

interface PaginatedResult {
  nextToken?: string;
  prevToken?: string;
  total?: number;
  items: string[];
}

const MERGE_IGNORE_KEYS = ['nextToken', 'prevToken'];

const compareArgs = (
  fieldArgs: Variables,
  connectionArgs: Variables | null,
  strict = false,
): boolean => {
  let ignoreKeys = strict ? [] : MERGE_IGNORE_KEYS;

  const fieldArgsKeys = Object.keys(fieldArgs).filter(
    (key) => !ignoreKeys.includes(key),
  );
  const connectionArgsKeys = Object.keys(connectionArgs ?? {}).filter(
    (key) => !ignoreKeys.includes(key),
  );

  if (fieldArgsKeys.length !== connectionArgsKeys.length) return false;

  return fieldArgsKeys.every((key) => {
    const argA = fieldArgs[key];
    const argB = (connectionArgs ?? {})[key];

    return (
      typeof argA === typeof argB &&
      stringifyVariables(argA) === stringifyVariables(argB)
    );
  });
};

const cursorPagination = (fieldTypename = ''): Resolver => {
  return (_, fieldArgs, cache, info) => {
    const { parentKey: entityKey, fieldName } = info;
    const allFields = cache.inspectFields(entityKey);
    const fieldInfos = allFields.filter(
      (_info) => _info.fieldName === fieldName,
    );

    const isCached = !!cache.resolve(entityKey, fieldName, fieldArgs);
    info.partial =
      !isCached && MERGE_IGNORE_KEYS.some((key) => !!fieldArgs[key]);

    if (fieldInfos.length === 0) {
      return undefined;
    }

    const { nextToken, prevToken, total, items } =
      fieldInfos.reduce<PaginatedResult>(
        (acc, fi) => {
          const { fieldKey, arguments: args } = fi;

          if (!compareArgs(fieldArgs, args, !!info.variables.skipMerge)) {
            return acc;
          }

          const key = cache.resolve(entityKey, fieldKey) as string;
          const data = cache.resolve(key, 'items') as string[];

          return {
            nextToken: cache.resolve(key, 'nextToken') as string,
            prevToken: cache.resolve(key, 'prevToken') as string,
            total: cache.resolve(key, 'total') as number,
            items: [...acc.items, ...(data ?? [])],
          };
        },
        {
          nextToken: undefined,
          prevToken: undefined,
          total: 0,
          items: [],
        },
      );

    // Remove duplicates if any
    const uniqueItems = Array.from(new Set(items));

    return {
      __typename: fieldTypename,
      nextToken,
      prevToken,
      total,
      items: uniqueItems,
    };
  };
};

export default cursorPagination;
