import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  headingText: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 22,
    color: '#fafafa',
    marginBottom: 12,
    textAlign: 'center',
  },
  paragraph: {
    fontFamily: 'Lato-Bold',
    color: '#fafafa',
    fontSize: 17,
    textAlign: 'center',
    lineHeight: 23,
  },
});
