import React, { useRef, useState } from 'react';
import { TextInputProps, TextInput } from 'react-native';
import AtMentionSuggestions from '../AtMentionSuggestions/AtMentionSuggestions';
import { User, UserMention } from '/generated/graphql';
import _ from 'lodash';

export interface IUserMention extends Pick<UserMention, 'start' | 'end'> {
  user: Pick<User, 'id' | 'name'>;
}

export type UserMentionSuggestionContextProps =
  | {
      type: 'unspecified';
    }
  | {
      type: 'discussionBoard';
      discussionBoardId: string;
    };

type Props = TextInputProps &
  UserMentionSuggestionContextProps & {
    mentions?: IUserMention[];
    disabled?: boolean;
    innerRef?: React.Ref<TextInput>;
    onChangeMentions?: (mentions: IUserMention[]) => void;
    disableMentions?: boolean;
  };

export default function UserMentionTextInput({
  mentions: _mentions,
  onChangeMentions,
  onChangeText: _onChangeText,
  innerRef,
  disableMentions,
  ...rest
}: Props) {
  const [localValue, setLocalValue] = useState<string | undefined>(rest.value);
  const value = rest.value ?? localValue;

  const [localMentions, setLocalMentions] = useState<IUserMention[]>([]);
  const mentions = _mentions ?? localMentions;

  const [atMentionStart, setAtMentionStart] = React.useState<number | null>(
    null,
  );
  const atMentionQuery =
    atMentionStart === null ? '' : value?.slice(atMentionStart);

  const inputRef = useRef<TextInput>();

  function onChangeText(text: string) {
    setLocalValue(text);
    _onChangeText?.(text);

    if (disableMentions) {
      clearMentionsIfAny();
      return;
    }

    const newMentions = getUpdatedMentions(text, mentions);
    updateMentionsIfChanged(newMentions);

    updateAtMentionStart(text);
  }

  function clearMentionsIfAny() {
    if (localMentions.length) {
      setLocalMentions([]);
      onChangeMentions?.([]);
    }

    setAtMentionStart(null);
  }

  function getUpdatedMentions(text: string, __mentions: IUserMention[]) {
    return __mentions.reduce((acc, mention) => {
      if (isMentionUnchanged(text, mention)) {
        acc.push(mention);
      } else {
        updateMentionInText(text, mention, acc);
      }

      return acc;
    }, [] as IUserMention[]);
  }

  function isMentionUnchanged(text: string, mention: IUserMention) {
    if (mention.end <= text.length) {
      const substr = text.slice(mention.start - 1, mention.end);
      return substr === '@' + mention.user.name;
    }

    return false;
  }

  function updateMentionInText(
    text: string,
    mention: IUserMention,
    __mentions: IUserMention[],
  ) {
    let mentionStart = text.indexOf('@' + mention.user.name) + 1;
    while (mentionStart > 0) {
      const mentionEnd = mentionStart + mention.user.name.length;
      const overlappingMention = findOverlappingMention(
        mentionStart,
        mentionEnd,
        __mentions,
      );

      if (!overlappingMention) {
        __mentions.push({
          ...mention,
          start: mentionStart,
          end: mentionEnd,
        });
        break;
      }

      mentionStart = text.indexOf('@' + mention.user.name, mentionEnd) + 1;
    }
  }

  function findOverlappingMention(
    mentionStart: number,
    mentionEnd: number,
    __mentions: IUserMention[],
  ) {
    return __mentions.find(
      (m) =>
        (m.start <= mentionStart && m.end > mentionStart) ||
        (m.start < mentionEnd && m.end >= mentionEnd),
    );
  }

  function updateMentionsIfChanged(newMentions: IUserMention[]) {
    if (!_.isEqual(newMentions, mentions)) {
      setLocalMentions(newMentions);
      onChangeMentions?.(newMentions);
    }
  }

  function updateAtMentionStart(text: string) {
    if (text.endsWith('@')) {
      setAtMentionStart(text.length);
    } else if (
      atMentionStart !== null &&
      (text.endsWith('.') || text.endsWith('\n'))
    ) {
      setAtMentionStart(null);
    }
  }

  const onMentionSelect = (user: { id: string; name: string }) => {
    if (atMentionStart === null) return;

    const newMentions = [
      ...mentions,
      {
        start: atMentionStart,
        end: atMentionStart + user.name.length,
        user,
      },
    ];

    setLocalMentions(newMentions);
    onChangeMentions?.(newMentions);

    const newText = value?.slice(0, atMentionStart) + user.name + ' ';
    onChangeText?.(newText);
    setAtMentionStart(null);

    inputRef.current?.focus?.();
  };

  return (
    <>
      {atMentionQuery ? (
        <AtMentionSuggestions
          query={atMentionQuery}
          onMentionSelect={onMentionSelect}
          discussionBoardId={
            rest.type === 'discussionBoard' ? rest.discussionBoardId : null
          }
        />
      ) : null}
      <TextInput
        {...rest}
        onBlur={(e) => {
          setTimeout(() => {
            setAtMentionStart(null);
          }, 250);
          rest.onBlur?.(e);
        }}
        readOnly={rest.disabled}
        onChangeText={onChangeText}
        onKeyPress={({ nativeEvent }) => {
          if (
            nativeEvent.key.toLowerCase() === 'escape' ||
            nativeEvent.key.toLowerCase() === 'enter'
          ) {
            setAtMentionStart(null);
          }
        }}
        onSelectionChange={(event) => {
          const hasSelection =
            event.nativeEvent.selection.start !==
            event.nativeEvent.selection.end;
          const caretPosition = event.nativeEvent.selection.start;

          if (caretPosition > 0) {
            if (hasSelection) return;

            const prevChar = value?.charAt(caretPosition - 1);

            if (prevChar === '@') {
              setAtMentionStart(caretPosition);
              return;
            }
          } else {
            setAtMentionStart(null);
          }
        }}
        ref={(r: any) => {
          if (!r) return;

          inputRef.current = r;

          if (typeof innerRef === 'function') {
            innerRef(r);
          } else if (innerRef) {
            (innerRef as any).current = r;
          }
        }}
      />
    </>
  );
}
