import { View, Text } from 'react-native';
import React from 'react';
import Accordion from '/components/Accordion/Accordion';
import { IProfileCompletionWidgetUser } from '../ProfileCompletionWidget';
import { getSupporterProfileCompletionProgress } from '/util';
import Button from '/components/Button';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import { KEY_GREEN } from '/constants';
import commonStyles from '../common.style';
import ChecklistItem from '../common/CheckListItem';

interface Props {
  profile: IProfileCompletionWidgetUser;
  hideGoToProfileButton: boolean;
}

export default function SupporterProfileCompletionChecklist(props: Props) {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const progress = props.profile
    ? getSupporterProfileCompletionProgress(props.profile)
    : 0;

  function goToProfile() {
    navigate('ProfileStack');
  }

  return progress < 100 ? (
    <Accordion
      title={`Complete Profile (${progress}%)`}
      subtitle={
        <View>
          <Text
            style={{
              paddingTop: 4,
            }}
          >
            Fill out your profile and let yourself be known to the community.
          </Text>
          {props.hideGoToProfileButton ? null : (
            <Button
              containerStyle={{
                marginTop: 8,
                alignSelf: 'flex-end',
              }}
              label="Go to profile"
              onPress={goToProfile}
            />
          )}
        </View>
      }
      subtitleStyle={{
        fontFamily: 'Lato',
      }}
      /** Progress Circle */
      icon={
        <AnimatedCircularProgress
          size={120}
          width={15}
          rotation={0}
          fill={progress}
          tintColor={KEY_GREEN}
          backgroundColor="#dfdfdf"
          style={{ margin: 2 }}
        >
          {() => <Text style={commonStyles.progressText}>{progress}%</Text>}
        </AnimatedCircularProgress>
      }
    >
      <ChecklistItem
        text="Add a profile picture"
        completed={!!props.profile.profile_image}
      />
      <ChecklistItem
        text="Add a cover photo"
        completed={!!props.profile.cover_image}
      />
      <ChecklistItem
        text="Add a bio"
        completed={!!props.profile.bio_translatable?.text?.trim()}
      />
      <ChecklistItem
        text="Add a skill"
        completed={!!props.profile.skills?.length}
      />
    </Accordion>
  ) : null;
}
