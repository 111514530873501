import { DefaultTheme, Theme } from '@react-navigation/native';
import { OFFWHITE } from '/constants';

import { createNavigationContainerRef } from '@react-navigation/native';

export const NAVIGATION_THEME: Theme = {
  dark: DefaultTheme.dark,
  colors: {
    ...DefaultTheme.colors,
    background: OFFWHITE,
  },
};

export const navigationRef = createNavigationContainerRef();
