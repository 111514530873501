// @ts-nocheck

import { AnimatedFlashList, FlashListProps } from '@shopify/flash-list';
import { Mixins } from '@skele/components';
import React, { forwardRef } from 'react';
import { Animated, findNodeHandle, Platform } from 'react-native';

interface ViewportTrackingAnimatedFlashListProps<ItemT>
  extends FlashListProps<ItemT> {
  innerRef?: React.Ref<AnimatedFlashList<ItemT>>;
}

class ViewportTrackingAnimatedFlashList<ItemT> extends Mixins.WithEvents(
  {
    name: 'viewport',
    inChildContext: true,
    notifiesWithLastEventOnAdd: true,
  },

  React.Component<ViewportTrackingAnimatedFlashListProps<ItemT>>,
) {
  _viewportWidth = 0;

  _viewportHeight = 0;

  _viewportOffsetX = 0;

  _viewportOffsetY = 0;

  _nodeHandle;

  _onLayout = (event) => {
    this.props.onLayout?.(event);

    this._viewportWidth = event.nativeEvent.layout.width;
    this._viewportHeight = event.nativeEvent.layout.height;
    this._onViewportChange();
  };

  _onContentSizeChange = () => {
    this._onViewportChange();
  };

  _onViewportChange = (shouldMeasureLayout = true) => {
    // console.log(
    //   'will notify flatlist listeners?',
    //   this._nodeHandle,
    //   this._viewportWidth,
    //   this._viewportHeight
    // );

    this._nodeHandle &&
      this._viewportWidth > 0 &&
      this._viewportHeight > 0 &&
      this.notifyViewportListeners({
        parentHandle: this._nodeHandle,
        viewportOffsetX: this._viewportOffsetX,
        viewportOffsetY: this._viewportOffsetY,
        viewportWidth: this._viewportWidth,
        viewportHeight: this._viewportHeight,
        shouldMeasureLayout,
      });
  };

  _onScroll = (event) => {
    this._viewportOffsetX = event.nativeEvent.contentOffset.x;
    this._viewportOffsetY = event.nativeEvent.contentOffset.y;
    this._onViewportChange(false);
  };

  render() {
    const { innerRef, ...props } = this.props;

    const ListComponent =
      Platform.OS === 'android' ? Animated.FlatList : AnimatedFlashList;

    return (
      <ListComponent
        {...props}
        onContentSizeChange={this._onContentSizeChange}
        onLayout={(event) => {
          this._onLayout(event);
        }}
        ref={(r) => {
          // We want to share this ref
          this._nodeHandle = findNodeHandle(r);
          innerRef?.(r);
        }}
        //@ts-ignore
        onScroll={Animated.forkEvent(this.props.onScroll, this._onScroll)}
      >
        {this.props.children}
      </ListComponent>
    );
  }
}

function ViewportTrackingAnimatedFlashListWithRef<ItemT>(
  props: ViewportTrackingAnimatedFlashListProps<ItemT>,
  ref: React.Ref<AnimatedFlashList<ItemT>>,
) {
  return <ViewportTrackingAnimatedFlashList<ItemT> {...props} innerRef={ref} />;
}

export default forwardRef(ViewportTrackingAnimatedFlashListWithRef) as <ItemT>(
  props: ViewportTrackingAnimatedFlashListProps<ItemT> & {
    ref?: React.Ref<AnimatedFlashList<ItemT>>;
  },
) => React.ReactElement;
