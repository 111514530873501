import { Ionicons } from '@expo/vector-icons';
import { StyleSheet, Text, View } from 'react-native';

interface IChecklistItemProps {
  text: string;
  completed: boolean;
}

export default function ChecklistItem(props: IChecklistItemProps) {
  return (
    <View style={styles.checklistItem}>
      <Text
        style={[
          styles.checklistItemText,
          props.completed
            ? {
                textDecorationLine: 'line-through',
                opacity: 0.6,
              }
            : null,
        ]}
      >
        {props.text}
      </Text>
      {props.completed ? (
        <Ionicons
          name="checkmark-circle"
          style={styles.icon}
          size={24}
          color="black"
        />
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  checklistItem: {
    width: '100%',
    padding: 12,
    borderTopColor: '#ddd',
    borderTopWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  checklistItemText: {
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    flex: 1,
  },
  icon: {
    marginRight: 12,
  },
});
