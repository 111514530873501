/**
 * Profile header component
 * Handles rendering user's social media links and allowing
 * user to edit (remove/add) links.
 */
import { Feather, MaterialCommunityIcons } from '@expo/vector-icons';
import { openURL } from 'expo-linking';
import React from 'react';
import { Platform, Pressable, StyleSheet, TextInput, View } from 'react-native';
import Alert from '/Alert';
import PlusSign from '/assets/jsicons/headerIcons/plusSign';
import EditPencil from '/assets/jsicons/OnBoarding/EditPencil';
import Facebook from '/assets/jsicons/socialmedia/Facebook';
import Instagram from '/assets/jsicons/socialmedia/Instagram';
import LinkedIn from '/assets/jsicons/socialmedia/LinkedIn';
import Twitter from '/assets/jsicons/socialmedia/Twitter';
import { KEY_GREEN, TEXT_INPUT } from '/constants';
import { AlertPressEvent } from '/context/AlertProvider';
import { User } from '/generated/graphql';
import { DeepPartial } from '/types';
import { formatURL, isValidURLRegex, isValidUsernameRegex } from '/util';

interface ISocialLinksProps {
  isEditing: boolean;
  /** Color of icons */
  tint?: string;
  data: DeepPartial<User> | undefined | null;
  setData: (data: DeepPartial<User>) => void;
}

export default function SocialLinks(props: ISocialLinksProps) {
  return (
    <View pointerEvents="box-none" style={styles.container}>
      <SocialLink
        icon={
          <MaterialCommunityIcons
            name="web"
            size={30}
            color={props.tint ?? 'white'}
          />
        }
        onChange={(link) => {
          props.setData({
            website: link,
          });
        }}
        isEditing={props.isEditing}
        link={props.data?.website}
        type="url"
        name="website"
      />
      <SocialLink
        icon={<Twitter width={30} height={30} fill={props.tint ?? 'white'} />}
        onChange={(link) => {
          props.setData({
            twitter: link,
          });
        }}
        isEditing={props.isEditing}
        link={props.data?.twitter}
        type="username"
        name="Twitter"
      />
      <SocialLink
        icon={<Facebook width={30} height={30} fill={props.tint ?? 'white'} />}
        onChange={(link) => {
          props.setData({
            facebook: link,
          });
        }}
        isEditing={props.isEditing}
        link={props.data?.facebook}
        type="url"
        domain="facebook.com"
        name="Facebook"
      />
      <SocialLink
        icon={<Instagram width={30} height={30} fill={props.tint ?? 'white'} />}
        onChange={(link) => {
          props.setData({
            instagram: link,
          });
        }}
        isEditing={props.isEditing}
        link={props.data?.instagram}
        type="username"
        name="Instagram"
      />
      <SocialLink
        icon={<LinkedIn width={30} height={30} fill={props.tint ?? 'white'} />}
        onChange={(link) => {
          props.setData({
            linkedin: link,
          });
        }}
        isEditing={props.isEditing}
        link={props.data?.linkedin}
        type="url"
        domain="linkedin.com"
        name="LinkedIn"
      />
      <SocialLink
        icon={
          <Feather name="youtube" size={30} color={props.tint ?? 'white'} />
        }
        onChange={(link) => {
          props.setData({
            youtube: link,
          });
        }}
        isEditing={props.isEditing}
        domain="youtube.com"
        link={props.data?.youtube}
        type="url"
        name="YouTube"
      />
    </View>
  );
}

interface ISocialLinkProps {
  name: string;
  type: 'url' | 'username';
  // If set, SocialLink will reject user input if it is not a valid link to the specified domain
  domain?: string;
  link: string | null | undefined;
  onChange: (link: string) => void;
  icon: JSX.Element;
  isEditing: boolean;
}

const SocialLink = (props: ISocialLinkProps) => {
  const onChange = (e: AlertPressEvent) => {
    const link = e.values.input;

    // Make sure URL is valid
    if (
      props.type === 'url' &&
      link && // Empty strings allowed
      !isValidURLRegex(link, props.domain ? props.domain : undefined)
    ) {
      e.preventDefault();

      Alert.alert(
        'Invalid URL',
        `Please make sure you have entered your URL correctly${
          props.domain ? ` and that it is a valid link to ${props.domain}` : ''
        }`,
      );

      return;
    }

    if (props.type === 'username' && link?.trim().length) {
      props.onChange(`https://www.${props.name}.com/${link}`.toLowerCase());
    } else props.onChange(formatURL(link));
  };

  const onPress = () => {
    if (props.isEditing) {
      Alert.alert(
        `${props.link ? 'Change ' : 'Add '}${props.name}${
          props.type === 'url' ? ' link' : ' username'
        }`,
        '',
        [
          {
            text: 'Done',
            style: 'default',
            onPress: onChange,
          },
        ],
        [
          {
            name: 'input',
            initialValue:
              props.type === 'url'
                ? props.link
                : props.link?.slice(props.link?.lastIndexOf('/') + 1),
            placeholder: `Enter ${props.name} ${
              props.type === 'url' ? 'URL' : 'username'
            } here...`,
            renderComponent: (value, setValue) => {
              return (
                <TextInput
                  style={[TEXT_INPUT, { flex: undefined }]}
                  maxLength={props.type === 'username' ? 32 : undefined}
                  clearButtonMode="always"
                  value={value || undefined}
                  placeholder={`Enter ${props.name} ${
                    props.type === 'url' ? 'URL' : 'username'
                  } here...`}
                  placeholderTextColor={'gray'}
                  keyboardType={props.type === 'url' ? 'url' : undefined}
                  enterKeyHint="done"
                  textContentType={props.type === 'url' ? 'URL' : 'username'}
                  dataDetectorTypes={props.type === 'url' ? 'link' : undefined}
                  onChangeText={(text) => {
                    const isValid =
                      !text ||
                      (props.type === 'username'
                        ? isValidUsernameRegex(text)
                        : !!text);

                    if (!isValid) return;

                    setValue(text);
                  }}
                />
              );
            },
          },
        ],
      );
    } else if (props.link) {
      if (Platform.OS === 'web') {
        window.open(formatURL(props.link), '_blank');
      } else {
        openURL(formatURL(props.link));
      }
    }
  };

  return (
    <Pressable
      style={[
        {
          display: props.link?.trim() || props.isEditing ? 'flex' : 'none',
        },
        styles.socialLinkContainer,
      ]}
      onPress={onPress}
    >
      <View
        pointerEvents="none"
        style={{
          opacity: props.isEditing && !props.link?.trim() ? 0.55 : 1,
        }}
      >
        {props.icon}
      </View>
      {props.isEditing && (
        <View
          pointerEvents="none"
          style={[
            styles.socialLinkGreenPlus,
            {
              backgroundColor: !props.link ? KEY_GREEN : '#555',
            },
          ]}
        >
          {!props.link ? (
            <PlusSign
              pointerEvents="none"
              width={12}
              height={12}
              fill="black"
            />
          ) : (
            <EditPencil
              pointerEvents="none"
              width={11}
              height={11}
              color="white"
            />
          )}
        </View>
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: 8,
    zIndex: 1,
  },
  socialLinkContainer: {
    width: 34,
    height: 36,
    padding: 2,
    paddingVertical: 4,
    marginRight: 6,
  },
  socialLinkGreenPlus: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: KEY_GREEN,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
