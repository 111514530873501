import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { SECTION_CONTAINER } from '/constants';

interface Props {
  style?: StyleProp<ViewStyle>;
}

export default function SectionContainer(
  props: React.PropsWithChildren<Props>,
) {
  return <View style={[SECTION_CONTAINER, props.style]}>{props.children}</View>;
}
