import { FontAwesome, FontAwesome5, Ionicons } from '@expo/vector-icons';
import React from 'react';
import { CreativeConnectProjectCreativeType } from '/generated/graphql';
import { StyleProp, ViewStyle } from 'react-native';

type Props = {
  creativeType: CreativeConnectProjectCreativeType | null;
  size?: number;
  color?: string;
  style?: StyleProp<ViewStyle>;
};

export default function CreativeTypeIcon(props: Props) {
  const sharedProps = {
    size: props.size ?? 18,
    color: props.color ?? 'black',
    style: props.style,
  };

  switch (props.creativeType) {
    case CreativeConnectProjectCreativeType.Art:
      return <FontAwesome5 name="palette" {...sharedProps} />;
    case CreativeConnectProjectCreativeType.Film:
      return <FontAwesome5 name="film" {...sharedProps} />;
    case CreativeConnectProjectCreativeType.Game:
      return (
        <Ionicons
          name="game-controller"
          {...sharedProps}
          size={sharedProps.size + 1}
        />
      );
    case CreativeConnectProjectCreativeType.Photography:
      return <FontAwesome name="camera" {...sharedProps} />;
    case CreativeConnectProjectCreativeType.Podcast:
      return <FontAwesome name="podcast" {...sharedProps} />;
    case null:
      return null;
    default:
      props.creativeType satisfies never;
      return null;
  }
}
