import { Dimensions } from 'react-native';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles';

const WINDOW_HEIGHT = Dimensions.get('window').height;
const WINDOW_WIDTH = Dimensions.get('window').width;

export default CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    subContainer: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: 8,
      width: '100%',
      maxWidth: 500,
      alignSelf: 'center',
      marginTop: 8,
      marginBottom: 100,
    },
    button: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    backButtonContainer: {
      position: 'absolute',
      maxWidth: 1200,
      top: 40,
      paddingLeft: 10,
      width: '100%',
      zIndex: 10,
    },
    logo: {
      marginBottom: 0,
    },
    logoContainer: {
      paddingHorizontal: 16,
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    logoContainerOnFocus: {
      paddingHorizontal: 16,
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 20,
      width: '100%',
    },
    titleContainer: {
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: WINDOW_HEIGHT < 740 ? 0 : '5%',
    },
    selectTitle: {
      textAlign: 'center',
      fontSize: WINDOW_WIDTH < 350 ? 24 : 30,
      fontWeight: '900',
      fontFamily: 'Lato-Bold',
      color: 'white',
    },
    selectTitleHidden: {
      textAlign: 'center',
      fontSize: 30,
      fontWeight: '900',
      fontFamily: 'Lato-Bold',
      color: 'white',
    },
    highlight: {
      textAlign: 'center',
      flexWrap: 'wrap',
      marginTop: 3,
      fontSize: 24,
      fontFamily: 'Lato-Bold',
      color: 'black',
      backgroundColor: '#d7ff43',
    },
    buttons: {
      marginTop: 10,
      alignItems: 'center',
      width: '90%',
    },
    buttonContainer: {
      width: '90%',
      height: 50,
      marginBottom: 18,
      borderRadius: 5,
      fontFamily: 'Lato',
      backgroundColor: '#F4F5F7',
      shadowColor: 'rgba(0, 0, 0, 0.25)',
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowRadius: 4,
      shadowOpacity: 10,
      justifyContent: 'center',
    },
    buttonText: {
      fontFamily: 'Lato-Bold',
      letterSpacing: 0,
      textAlign: 'center',
      color: 'black',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 20,
    },
    aboutIconContainer: {
      position: 'absolute',
      left: 16,
      bottom: 16,
      zIndex: 99,
    },
    aboutIconTouch: {
      padding: 10,
    },
    Hidden: {
      display: 'none',
    },
    formView: {
      position: 'absolute',
      zIndex: 1,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
  {
    [minSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      logo: {
        width: WINDOW_HEIGHT < 740 ? 240 : 280,
        height: WINDOW_HEIGHT < 740 ? 240 : 280,
      },
      logoContainer: {
        paddingTop: WINDOW_HEIGHT < 740 ? 20 : 120,
      },
      aboutIconContainer: {
        left: 25,
        bottom: 25,
      },
    },
  },
);
