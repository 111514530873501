import { useEffect } from 'react';
import { EasingFunction } from 'react-native';
import {
  Easing,
  EasingFunctionFactory,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

type UseBinaryTimingAnimationProps = {
  value: boolean;
  duration?: number;
  easing?: EasingFunction | EasingFunctionFactory | undefined;
  /** Manually specify the animated value that should be set when `value` is false. Default is `0`. */
  disabledValue?: number;
  /** Manually specify the animated value that should be set when `value` is true. Default is `1`. */
  enabledValue?: number;
  initialValue?: number;
};

const DISABLED_VALUE = 0;
const ENABLED_VALUE = 1;

/**
 * Animates a value between 0 and 1 when the input boolean value changes
 * @param value Boolean input value
 * @returns {Animated.Value} An animated value between 0 and 1
 */
export default function useBinaryTimingAnimation({
  value,
  ...options
}: UseBinaryTimingAnimationProps) {
  const animatedValue = useSharedValue(
    options?.initialValue ?? value
      ? options.enabledValue ?? ENABLED_VALUE
      : options.disabledValue ?? DISABLED_VALUE,
  );

  useEffect(() => {
    const enabledValue = options?.enabledValue ?? ENABLED_VALUE;
    const disabledValue = options?.disabledValue ?? DISABLED_VALUE;

    animatedValue.value = withTiming(value ? enabledValue : disabledValue, {
      easing: options.easing ?? Easing.out(Easing.poly(4)),
      duration: options.duration ?? 140,
    });
  }, [value, options, animatedValue]);

  return animatedValue;
}
