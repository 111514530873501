import {
  Modal,
  Pressable,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import ScrollView from '/components/common/ScrollView/ScrollView';
import React from 'react';
import { DeepPartial } from '/types';
import { UserSkillPortfolio } from '/generated/graphql';
import SkillPortfolioView from './SkillPortfolioView';
import { AntDesign } from '@expo/vector-icons';
import { TITLE_FONT_SIZE } from '/constants';

interface Props {
  data: DeepPartial<UserSkillPortfolio> | undefined | null;
  visible: boolean;
  onRequestClose: () => void;
}

export default function UserSkillPortfolioModal(props: Props) {
  return (
    <Modal
      visible={props.visible}
      animationType="slide"
      onRequestClose={props.onRequestClose}
    >
      <SafeAreaView style={{ flex: 1 }}>
        <View style={styles.contentContainer}>
          <View style={styles.header}>
            <Text style={styles.headerText}>SKILL PORTFOLIO</Text>
            <Pressable
              style={styles.closeButton}
              onPress={props.onRequestClose}
            >
              <AntDesign name="close" size={28} />
            </Pressable>
          </View>
          <ScrollView style={{ flex: 1 }}>
            <SkillPortfolioView data={props.data as UserSkillPortfolio} />
          </ScrollView>
        </View>
      </SafeAreaView>
    </Modal>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    maxWidth: 800,
    width: '100%',
    alignSelf: 'center',
    padding: 10,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 8,
    paddingBottom: 12,
  },
  headerText: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: TITLE_FONT_SIZE,
  },
  closeButton: {
    padding: 8,
  },
});
