import React, { useCallback, useRef } from 'react';
import {
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import { CampaignBuilderComponentProps } from '../CampaignBuilder';
import RequestDonation from '../Donations/RequestDonation';

import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import DescribeSkills from '../SkillImpact/DescribeSkills';
import SelectSkillsContent from '../SkillImpact/SelectSkillsContent';
import RequestVolunteers from '../Volunteering/RequestVolunteers';
import CampaignBuilderFooter from './CampaignBuilderFooter';
import CampaignUrgencySelector from './CampaignUrgencySelector';
import Alert from '/Alert';
import Button from '/components/Button';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import {
  useFormValidationContext,
  ValidatedAny,
  withFormValidation,
} from '/components/ValidatedForm';
import { KEY_GRAY, KEY_GREEN } from '/constants';
import styles from '/constants/CampaignBuilder';
import { useAuthContext, useTeamContext } from '/context';
import { CampaignUrgency, UserRole } from '/generated/graphql';
import useAccountDonationStatus from '/hooks/useAccountDonationStatus';

const SUPPORT_TYPES = {
  funding: RequestDonation,
  volunteering: RequestVolunteers,
  skills: [SelectSkillsContent, DescribeSkills],
};

export type SupportType = 'funding' | 'volunteering' | 'skills';

function SupportPicker(props: CampaignBuilderComponentProps) {
  const scrollViewRef = useRef<ScrollView>();

  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const { userData } = useAuthContext();
  const { activeTeam, loading: teamContextLoading } = useTeamContext();

  const donationStatus = useAccountDonationStatus();
  const loadingDonationStatus =
    userData?.role === UserRole.Conservationist
      ? donationStatus.fetching
      : teamContextLoading;
  const acceptingDonations =
    userData?.role === UserRole.Conservationist
      ? !!donationStatus?.acceptingDonations
      : !!activeTeam?.user.accepting_donations;

  const { validateForm, fields } = useFormValidationContext(scrollViewRef);

  const setSupportType = useCallback(
    function (supportType: SupportType | undefined) {
      if (supportType === props.data?.supportNeeded?.[0]) return;
      if (!supportType && !props.data?.supportNeeded?.length) return;

      props.setData({
        supportNeeded: supportType ? [supportType] : [],
      });
    },
    [props],
  );

  const toggleSupportOption = useCallback(
    (supportType: SupportType) => {
      if (props.data?.supportNeeded?.includes(supportType)) {
        setSupportType(undefined);
      } else {
        if (props.data?.supportNeeded?.length && !!supportType) {
          Alert.alert(
            'Notice',
            'You can only choose one form of support per campaign. Unselect your current selection first to select a new one.' +
              '\nGood to know: selecting a new type of support will remove your current progress on the campaign',
          );
          return;
        }

        setSupportType(supportType);
      }
    },
    [props.data?.supportNeeded, setSupportType],
  );

  let supportNeededInvalidReason = !props.data?.supportNeeded?.length
    ? 'You must select one support option'
    : loadingDonationStatus
    ? 'Please wait...'
    : !acceptingDonations
    ? 'Your organization must set up at least one valid deposit method to receive donations'
    : undefined;

  return (
    <>
      <ScrollView
        ref={(r) => {
          if (r) scrollViewRef.current = r;
        }}
        style={{
          paddingTop: 120,
        }}
        contentContainerStyle={[
          styles.scrollView,
          { paddingBottom: Platform.OS === 'web' ? 0 : 120 },
        ]}
      >
        <ValidatedAny
          name="supportNeeded"
          value={{
            supportNeeded: props.data?.supportNeeded,
            acceptingDonations,
            loadingDonationStatus,
          }}
          validate={(value) => {
            if (
              value?.supportNeeded?.includes('funding') &&
              (!value.acceptingDonations || value.loadingDonationStatus)
            ) {
              return false;
            }

            return !!value?.supportNeeded?.length;
          }}
        >
          <View
            style={[
              styles.sectionContainer,
              fields.supportNeeded?.valid === false
                ? {
                    borderColor: 'crimson',
                    borderWidth: 1,
                  }
                : {
                    borderWidth: 1,
                    borderColor: 'transparent',
                  },
            ]}
          >
            <Text style={styles.subheading}>Select one</Text>
            {fields.supportNeeded?.valid === false ? (
              <HorizontalContainer>
                <Text
                  style={{
                    width: '100%',
                    // textAlign: 'right',
                    fontFamily: 'Lato-Bold',
                    fontSize: 15,
                    color: 'crimson',
                  }}
                >
                  {supportNeededInvalidReason}
                </Text>
                {!acceptingDonations &&
                !loadingDonationStatus &&
                userData?.role === UserRole.Conservationist ? (
                  <Button
                    label="Go to Settings"
                    onPress={() => {
                      navigate('DonationSettings');
                    }}
                    containerStyle={{
                      alignSelf: 'flex-end',
                    }}
                  />
                ) : null}
              </HorizontalContainer>
            ) : null}
            <View
              style={[
                styles.horizontalContainer,
                {
                  justifyContent: 'center',
                },
              ]}
            >
              <TouchableOpacity
                style={[
                  supportOption,
                  props.data?.supportNeeded?.includes('funding')
                    ? supportOptionSelected
                    : {},
                ]}
                onPress={() => {
                  toggleSupportOption('funding');
                }}
              >
                <Text style={supportOptionLabel}>FUNDING</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  supportOption,
                  props.data?.supportNeeded?.includes('volunteering')
                    ? supportOptionSelected
                    : {},
                ]}
                onPress={() => toggleSupportOption('volunteering')}
              >
                <Text style={supportOptionLabel}>IN-PERSON</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  supportOption,
                  props.data?.supportNeeded?.includes('skills')
                    ? supportOptionSelected
                    : {},
                ]}
                onPress={() => {
                  toggleSupportOption('skills');
                }}
              >
                <Text style={supportOptionLabel}>SKILLS</Text>
              </TouchableOpacity>
            </View>
          </View>
        </ValidatedAny>

        <CampaignUrgencySelector
          value={props.data?.campaign?.urgency ?? CampaignUrgency.None}
          onChange={(urgency) => {
            props.setData({
              campaign: {
                urgency,
              },
            });
          }}
          containerStyle={styles.sectionContainer}
        />
      </ScrollView>
      <CampaignBuilderFooter
        {...props}
        onNext={() =>
          props.next(
            props.data?.supportNeeded?.map((key) => SUPPORT_TYPES[key]),
            1,
          )
        }
        validateForm={validateForm}
      />
    </>
  );
}

const supportOption = {
  backgroundColor: '#eee',
  borderRadius: 4,
  padding: 9,
  paddingHorizontal: 12,
  margin: 2,
};

const supportOptionSelected = {
  backgroundColor: KEY_GREEN,
};

const supportOptionLabel = {
  fontFamily: 'Lato-Bold',
  color: KEY_GRAY,
};

export default withFormValidation(SupportPicker);
