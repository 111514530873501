import React, { useState } from 'react';
import { FlatList, Pressable, StyleSheet, Text, View } from 'react-native';
import { CampaignBuilderComponentProps } from '../CampaignBuilder';
import CampaignBuilderFooter from './CampaignBuilderFooter';
import CampaignConnectInviteCard from '/components/CampaignConnect/CampaignConnectInviteCard/CampaignConnectInviteCard';
import CheckmarkSwitch from '/components/CheckmarkSwitch';
import GenericListFooter from '/components/GenericListFooter';
import GenericError from '/components/common/Generic/GenericError';
import { KEY_GRAY, TITLE_FONT_SIZE } from '/constants';
import sharedStyles from '/constants/CampaignBuilder';
import { useAuthContext, useTeamContext } from '/context';
import { useListCampaignConnectInvitesForUserQuery } from '/generated/graphql';

export default function CampaignConnect(props: CampaignBuilderComponentProps) {
  const { activeTeam } = useTeamContext();
  const { userData } = useAuthContext();

  const [nextToken, setNextToken] = useState<string>();

  const [selectedInviteIds, setSelectedInviteIds] = useState<string[]>(
    props.data?.campaign_connect_invite_ids ?? [],
  );

  const [{ data, fetching, stale, error }, listMyCampaignConnectInvites] =
    useListCampaignConnectInvitesForUserQuery({
      variables: {
        nextToken,
        userId: activeTeam?.user.id || userData?.id,
        limit: 15,
      },
    });

  const paginate = () => {
    if (data?.listCampaignConnectInvitesForUser.nextToken)
      setNextToken(data.listCampaignConnectInvitesForUser.nextToken);
  };

  return (
    <>
      <FlatList
        style={{ flex: 1 }}
        contentContainerStyle={[
          sharedStyles.scrollView,
          {
            backgroundColor: 'white',
            padding: 10,
            marginTop: 8,
          },
        ]}
        ListHeaderComponent={
          <>
            <Text style={styles.headerText}>CAMPAIGN CONNECT</Text>
            <Text style={styles.paragraph}>
              You have Campaign Connect invites awaiting a response. If you'd
              like to submit this Campaign as a response to any of them, select
              them below.
            </Text>
          </>
        }
        ListEmptyComponent={
          !data && !fetching ? (
            error ? (
              <GenericError
                message="There was a problem loading your Campaign Connect invites."
                onRetry={listMyCampaignConnectInvites}
              />
            ) : (
              <Text style={styles.emptyText}>
                No Campaign Connect invites awaiting a response.
              </Text>
            )
          ) : null
        }
        ListFooterComponent={
          <GenericListFooter
            hasMore={!!data?.listCampaignConnectInvitesForUser.nextToken}
            isFirstPage={!nextToken}
            onFetchMore={paginate}
            loading={fetching || stale}
          />
        }
        onEndReached={paginate}
        data={data?.listCampaignConnectInvitesForUser.items}
        renderItem={({ item }) => {
          return (
            <Pressable
              onPress={() => {
                setSelectedInviteIds((_ids) =>
                  _ids.includes(item.id)
                    ? _ids.filter((id) => id !== item.id)
                    : [..._ids, item.id],
                );
              }}
              style={{
                flex: 1,
                marginVertical: 4,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <CheckmarkSwitch
                value={!!selectedInviteIds.find((id) => id === item.id)}
                onValueChange={(value) => {
                  setSelectedInviteIds((_ids) =>
                    value
                      ? [..._ids, item.id]
                      : _ids.filter((id) => id !== item.id),
                  );
                }}
              />
              <View style={{ flex: 1, paddingLeft: 10 }}>
                <CampaignConnectInviteCard viewingAsInvitedUser invite={item} />
              </View>
            </Pressable>
          );
        }}
      />
      <CampaignBuilderFooter
        {...props}
        onNext={() => {
          props.setData({
            // Make sure selected invites are still available
            campaign_connect_invite_ids: selectedInviteIds.filter(
              (id) =>
                !!data?.listCampaignConnectInvitesForUser.items.find(
                  (i) => i.id === id,
                ),
            ),
          });
          props.next();
        }}
        validateForm={() => true}
      />
    </>
  );
}

const styles = StyleSheet.create({
  headerText: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: TITLE_FONT_SIZE,
  },
  paragraph: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    fontSize: 15,
  },
  paragraphItalic: {
    fontFamily: 'Lato-BoldItalic',
  },
  containerStyle: {
    padding: 10,
  },
  emptyText: {
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    color: KEY_GRAY,
    textAlign: 'center',
    alignSelf: 'center',
    padding: 24,
  },
});
