import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';

const removeConnection: UpdateResolver = (result, args, cache) => {
  const fragment = gql`
    fragment RemoveConnection on User {
      id
      connections_count
    }
  `;

  const initiatorData = cache.readFragment(fragment, {
    id: (result.removeConnection as any)?.initiator?.id,
  }) as any;
  const recipientData = cache.readFragment(fragment, {
    id: (result.removeConnection as any)?.recipient?.id,
  }) as any;

  // Invalidate the connection itself
  cache.invalidate({
    __typename: 'Connection',
    id: args.connectionId as string,
  });

  // Subtract 1 from connections_count of both parties
  if (typeof initiatorData?.connections_count === 'number')
    cache.writeFragment(fragment, {
      id: initiatorData?.id,
      connections_count: Math.max(0, initiatorData?.connections_count - 1),
    });

  if (typeof recipientData?.connections_count === 'number')
    cache.writeFragment(fragment, {
      id: recipientData?.id,
      connections_count: Math.max(0, recipientData?.connections_count - 1),
    });
};

export default removeConnection;
