import React, { useEffect, useRef } from 'react';
import { StyleProp } from 'react-native';
import { Platform, View, ViewStyle } from 'react-native';

type Props = React.PropsWithChildren<{
  containerStyle?: StyleProp<ViewStyle>;
  theme: 'dark' | 'light';
}>;

export default function WebScrollbarTheme({
  children,
  theme,
  containerStyle,
}: Props) {
  const viewRef = useRef<any>();

  const previousTheme = useRef<typeof theme>();
  useEffect(() => {
    if (Platform.OS !== 'web') return;
    if (previousTheme.current === theme) return;
    if (!viewRef.current || !viewRef.current?.classList) return;

    previousTheme.current = theme;

    if (theme === 'dark') {
      viewRef.current.classList.add('dark-theme');
    } else {
      viewRef.current.classList.remove('dark-theme');
    }
  }, [theme]);
  return (
    <View
      style={containerStyle}
      ref={(r: any) => {
        viewRef.current = r;
      }}
    >
      {children}
    </View>
  );
}
