import { Platform, StyleSheet } from 'react-native';
import { OFFWHITE } from '../../constants';

export default StyleSheet.create({
  container: {
    flex: 1,
    zIndex: 50,
  },
  titleBackgroundContainer: {
    backgroundColor: 'white',
    padding: 48,
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: 240,
    marginBottom: 8,
  },
  titleBackground: {
    display: Platform.OS === 'web' ? 'none' : 'flex',
    width: '100%',
    height: '100%',
  },
  stepTitle: {
    color: '#000000',
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 32,
    textAlign: 'center',
    margin: 24,
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  scrollView: {
    // marginTop: 120,
    backgroundColor: OFFWHITE,
    paddingBottom: 0,
  },
  horizontalContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 8,
  },
  titleWithIconContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginVertical: 8,
  },
  iconContainer: {
    paddingHorizontal: 8,
  },
  sectionContainer: {
    backgroundColor: '#fff',
    padding: 10,
    paddingHorizontal: 16,
    marginTop: 8,
  },
  header: {
    zIndex: 99,
    backgroundColor: '#fff',
    marginTop: 25,
    height: 64,
    marginHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerLeft: {},
  headerCenter: {
    flex: 1,
    alignItems: 'center',
  },
  headerRight: {
    alignItems: 'flex-end',
  },
  title: {
    fontFamily: 'Lato-Bold',
    fontSize: 20,
  },
  subheading: {
    fontFamily: 'LeagueSpartan-Bold',
    lineHeight: 24,
    fontSize: 18,
    textTransform: 'uppercase',
    marginVertical: 6,
  },
  description: {
    fontFamily: 'Lato',
    fontSize: 16,
    marginVertical: 2,
  },
  bottomSectionContainer: {
    backgroundColor: 'white',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    paddingTop: 8,
    marginTop: 8,
    marginRight: Platform.OS === 'web' ? 8 : 0,
  },
});
