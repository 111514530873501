import React, { useMemo } from 'react';
import ConservationistProfileCompletionChecklist from './conservationist/ConservationistProfileCompletionChecklist';
import SupporterProfileCompletionChecklist from './supporter/SupporterProfileCompletionChecklist';
import { useAuthContext, useTeamContext } from '/context';
import {
  TeamMemberRole,
  User,
  UserRole,
  UserSkillPortfolio,
} from '/generated/graphql';

export interface IProfileCompletionWidgetUser
  extends Pick<
    User,
    'id' | 'role' | 'bio_translatable' | 'cover_image' | 'profile_image'
  > {
  skills?: Pick<UserSkillPortfolio, 'skillName' | 'userId'>[] | undefined;
}

interface Props {
  /** If not set, will use currently authenticated user ID */
  targetProfile?: IProfileCompletionWidgetUser | null;
  /** Default is `false` */
  hideGoToProfileButton?: boolean;
}

export default function ProfileCompletionWidget(props: Props) {
  const { userData } = useAuthContext();
  const { teams } = useTeamContext();

  const isNotTarget = useMemo(() => {
    return props.targetProfile?.id && props.targetProfile.id !== userData?.id;
  }, [props.targetProfile, userData?.id]);

  const isAdminForTarget = useMemo(() => {
    return teams.some((team) => {
      return (
        team?.user.id === props.targetProfile?.id &&
        team.membership?.team_role === TeamMemberRole.Admin
      );
    });
  }, [teams, props.targetProfile]);

  return !(isNotTarget && !isAdminForTarget) ? (
    props.targetProfile?.role === UserRole.Conservationist ? (
      <ConservationistProfileCompletionChecklist
        hideGoToProfileButton={!!props.hideGoToProfileButton}
        profile={props.targetProfile}
      />
    ) : props.targetProfile?.role === UserRole.Supporter ? (
      <SupporterProfileCompletionChecklist
        hideGoToProfileButton={!!props.hideGoToProfileButton}
        profile={props.targetProfile}
      />
    ) : null
  ) : null;
}
