import { FontAwesome } from '@expo/vector-icons';
import { KeyboardAwareScrollView } from '@mtourj/react-native-keyboard-aware-scroll-view';
import { useScrollToTop } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useEffect, useRef, useState } from 'react';
import { Platform, RefreshControl, StyleSheet, Text, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import MyImpactApplications from './sections/MyImpactApplications';
import MyImpactCampaigns from './sections/MyImpactCampaigns';
import Alert from '/Alert';
import Lightening from '/assets/jsicons/bottomnavigation/Lightening';
import Sync from '/assets/jsicons/bottomnavigation/Sync';
import ChevronRight from '/assets/jsicons/miscIcons/ChevronRight';
import MyDonations from '/components/ImpactTab/MyDonations/MyDonations';
import SearchableSection from '/components/ImpactTab/SearchableSection';
import withAuthRequired from '../../components/withAuthRequired';
import { KEY_GRAY } from '/constants';
import { useAuthContext, useTeamContext } from '/context';
import {
  TeamMemberRole,
  useGetMyImpactApplicationsQuery,
  useGetMyImpactCampaignsQuery,
  UserRole,
} from '/generated/graphql';
import { ImpactStackParamList } from '/navigation/navigators/nested/ImpactStack';
import UpdateAvailableWidget from '/components/UpdateAvailableWidget';
import MyCreativeConnect from '/components/ImpactTab/MyCreativeConnect/MyCreativeConnect';

interface Props {
  navigation: StackNavigationProp<ImpactStackParamList, 'SupSkilledImpact'>;
}

function SupporterMyImpact(props: Props) {
  const { teams } = useTeamContext();

  const { userData } = useAuthContext();

  const scrollViewRef = useRef<KeyboardAwareScrollView>();

  useScrollToTop(scrollViewRef as any);

  const [myCampaignsQuery, setMyCampaignsQuery] = useState('');
  const [myApplicationsQuery, setMyApplicationsQuery] = useState('');

  const [
    {
      data: myCampaigns,
      fetching: fetchingMyCampaigns,
      error: myCampaignsError,
    },
    getMyCampaigns,
  ] = useGetMyImpactCampaignsQuery({
    variables: {
      limit: 4,
      query: myCampaignsQuery,
    },
    requestPolicy: 'cache-and-network',
  });

  const [
    { data: applications, fetching: loading, error: skillApplicationsError },
    getMyImpactApplications,
  ] = useGetMyImpactApplicationsQuery({
    variables: {
      queryString: myApplicationsQuery,
      limitPerCategory: 4,
    },
    requestPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (skillApplicationsError) {
      Alert.alert(
        'Oh no',
        'There was a problem fetching My Impact. Please try again later.',
        [
          {
            text: 'Retry',
            onPress: () => {
              if (skillApplicationsError) getMyImpactApplications();
              if (myCampaignsError) getMyCampaigns();
            },
          },
          {
            text: 'Dismiss',
            style: 'cancel',
          },
        ],
      );
    }
  }, [
    skillApplicationsError,
    myCampaignsError,
    getMyImpactApplications,
    getMyCampaigns,
  ]);

  useEffect(() => {
    const navigationState = props.navigation.getState();

    if (
      navigationState.index === 0 &&
      userData?.role &&
      userData.role === UserRole.Conservationist
    ) {
      props.navigation.replace('OrgSkilledImpact', {});
    }
  }, [userData?.role, props.navigation]);

  const [isRefreshing, setIsRefreshing] = useState(false);
  useEffect(() => {
    if (!loading && !fetchingMyCampaigns) setIsRefreshing(false);
  }, [loading, fetchingMyCampaigns]);

  function onRefresh() {
    setIsRefreshing(true);
    getMyImpactApplications({ requestPolicy: 'network-only' });
    getMyCampaigns({ requestPolicy: 'network-only' });
  }

  function onManageTeamOrganizations() {
    props.navigation.navigate('OrgSkilledImpact', {});
  }

  return (
    <View style={{ flex: 1 }}>
      <UpdateAvailableWidget />
      <KeyboardAwareScrollView
        innerRef={(r: any) => {
          if (r) scrollViewRef.current = r;
        }}
        refreshControl={
          <RefreshControl
            tintColor={KEY_GRAY}
            refreshing={isRefreshing}
            onRefresh={onRefresh}
          />
        }
        contentContainerStyle={{
          paddingBottom: 48,
        }}
      >
        {/* If we have the role Admin on at least one team, then show the
      "Manage Team Organizations" button */}
        {teams?.length &&
        teams.some(
          (team) =>
            team?.membership?.team_role === TeamMemberRole.Admin ||
            team?.membership?.team_role === TeamMemberRole.Creator,
        ) ? (
          <TouchableOpacity
            onPress={onManageTeamOrganizations}
            style={styles.manageOrganizationsButtonContainer}
          >
            <FontAwesome name="gears" color={'gray'} size={20} />
            <Text style={styles.manageOrganizationsButtonText}>
              Manage Team Organizations
            </Text>
            <ChevronRight />
          </TouchableOpacity>
        ) : null}
        <SearchableSection
          onQueryChange={setMyCampaignsQuery}
          renderIcon={() => <Lightening width={25} height={25} />}
          title="My Campaigns"
        >
          <MyImpactCampaigns
            loading={fetchingMyCampaigns}
            expandAll={myCampaignsQuery?.length > 0}
            data={myCampaigns?.me}
          />
        </SearchableSection>
        <SearchableSection
          onQueryChange={setMyApplicationsQuery}
          renderIcon={() => <Sync width={25} height={25} />}
          title="My Skilled Impact Applications"
        >
          <MyImpactApplications
            loading={loading}
            expandAll={myApplicationsQuery?.length > 0}
            data={applications?.getMyImpactApplications}
          />
        </SearchableSection>
        {/* TODO: Uncomment when ready to show subscriptions here: */}
        {/* <SearchableSection
        onQueryChange={setMonthlySubscriptionsQuery}
        renderIcon={() => <Ionicons name="help-buoy" size={25} />}
        title="Monthly Subsriptions"
      /> */}
        <MyDonations />

        {userData?.id ? <MyCreativeConnect userId={userData.id} /> : null}
      </KeyboardAwareScrollView>
    </View>
  );
}

export default withAuthRequired(SupporterMyImpact);

const styles = StyleSheet.create({
  manageOrganizationsButtonContainer: {
    ...(Platform.OS === 'web'
      ? {
          cursor: 'pointer',
        }
      : null),
    padding: 16,
    backgroundColor: 'white',
    flexDirection: 'row',
    alignItems: 'center',
  },
  manageOrganizationsButtonText: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
    marginLeft: 8,
    flex: 1,
  },
});
