import React, { useRef } from 'react';
import CampaignConnectInviteCampaignSubmittedCTAModal, {
  ICampaignConnectInvite,
} from './CampaignConnectInviteCampaignSubmittedCTAModal';
import Button from '/components/Button';
import { useModalContext } from '/context';
import { User } from '/generated/graphql';

type Props = {
  invite: ICampaignConnectInvite;
  invitedUser: Pick<User, 'id' | 'name' | 'profile_image'> | null | undefined;
  invitingUser: Pick<User, 'id' | 'name' | 'profile_image'> | null | undefined;
};

export default function CampaignConnectInviteCampaignSubmittedCTA(
  props: Props,
) {
  const { spawnModal, closeModal } = useModalContext();

  const modalIdRef = useRef<string | null>(null);
  function onReview() {
    modalIdRef.current = spawnModal({
      title: 'Review Campaign',
      style: {
        width: '100%',
        height: '100%',
        maxWidth: 480,
        maxHeight: 640,
      },
      component: (
        <CampaignConnectInviteCampaignSubmittedCTAModal
          invite={props.invite}
          invitedUser={props.invitedUser}
          invitingUser={props.invitingUser}
          onRequestClose={() => {
            modalIdRef.current && closeModal(modalIdRef.current);
          }}
        />
      ),
    });
  }

  return <Button label="Review" onPress={onReview} />;
}
