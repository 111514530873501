import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { Image, Text, ViewStyle } from 'react-native';
import Accordion from '../Accordion/Accordion';
import GenericError from '../common/Generic/GenericError';
import KeyBulletinPostPreview from '../KeyBulletinPostPreview/KeyBulletinPostPreview';
import ListLoading from '../ListLoading';
import TruncatedList from '../TruncatedList/TruncatedList';
import { useListKeyBulletinPostsQuery } from '/generated/graphql';
import { useAuthContext } from '/context';
import Button from '../Button';
import styles from './KeyBulletinWidget.style';

interface Props {
  initiallyOpen?: boolean;
  contentContainerStyle?: ViewStyle;
}

export default function KeyBulletinWidget(props: Props) {
  const navigation = useNavigation<StackNavigationProp<any>>();

  const { userData } = useAuthContext();

  const [{ data, fetching, error }, listPosts] = useListKeyBulletinPostsQuery({
    variables: {
      limit: 3,
    },
  });

  return data?.listKeyBulletinPosts.total === 0 &&
    userData?.admin === false ? null : (
    <Accordion
      expanded={props.initiallyOpen}
      title="Key Updates"
      contentContainerStyle={props.contentContainerStyle}
      icon={
        <Image
          style={{
            width: 24,
            height: 24,
          }}
          source={require('/assets/images/key-bulletin-icon.jpg')}
        />
      }
    >
      {userData?.admin === true ? (
        <>
          <Text style={styles.adminLabel}>ADMIN</Text>
          <Button
            label="Create Post"
            containerStyle={{
              marginBottom: 4,
            }}
            onPress={() => {
              navigation.navigate('CreateKeyBulletinPost');
            }}
          />
        </>
      ) : null}
      {fetching ? (
        <ListLoading />
      ) : error ? (
        <GenericError onRetry={listPosts} message="" />
      ) : data?.listKeyBulletinPosts.total === 0 ? (
        <Text style={styles.emptyText}>No posts yet.</Text>
      ) : (
        <TruncatedList
          items={data?.listKeyBulletinPosts.items ?? []}
          total={data?.listKeyBulletinPosts.total ?? 0}
          renderItem={(item) => {
            return (
              <KeyBulletinPostPreview
                onPress={() => {
                  navigation.navigate('ViewKeyBulletinPost', {
                    id: item.id,
                  });
                }}
                data={item}
              />
            );
          }}
          onViewMore={() => {
            navigation.navigate('ListKeyBulletinPosts');
          }}
        />
      )}
    </Accordion>
  );
}
