import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useEffect } from 'react';
import { View } from 'react-native';
import NewsPostFlatList from '/components/NewsPostFlatList/NewsPostFlatList';
import SearchBar from '/components/SearchBar/SearchBar';
import { KEY_LIGHT_GRAY, SECTION_CONTAINER, TEXT_INPUT } from '/constants';
import useDebouncedState from '/hooks/useDebouncedState';
import { StyleSheet } from 'react-native';

type Props = {
  navigation: StackNavigationProp<any>;
  route: RouteProp<any>;
};

export default function ListNewsPosts(props: Props) {
  // filter params
  const {
    q, // query
    userId, // user id
    d, // draft
    b, // bookmark
  } = props.route.params ?? {};

  const [fetching, setFetching] = React.useState(false);

  const [, queryDebounced, setQuery] = useDebouncedState<string>(q || '');

  useEffect(() => {
    props.navigation.setParams({
      q: queryDebounced,
    });
  }, [props.navigation, queryDebounced]);

  return (
    <View style={{ flex: 1, paddingBottom: 20 }}>
      <View
        style={{
          backgroundColor: 'white',
          paddingBottom: 8,
          borderBottomWidth: 1,
          borderBottomColor: KEY_LIGHT_GRAY,
        }}
      >
        <SearchBar
          loading={fetching}
          containerStyle={TEXT_INPUT}
          onChangeText={setQuery}
          defaultValue={queryDebounced}
        />
      </View>

      <NewsPostFlatList
        filter={{
          query: queryDebounced,
          draft: d === '1' ? true : d === '0' ? false : undefined,
          is_bookmarked: b === '1' ? true : b === '0' ? false : undefined,
          userId,
        }}
        onFetchingChanged={setFetching}
        itemStyle={styles.itemStyle}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  itemStyle: { ...SECTION_CONTAINER, marginTop: 8, padding: 6 },
});
