import React from 'react';
import { View } from 'react-native';
import { CampaignBuilderComponentProps } from '../CampaignBuilder';
import Button from '/components/Button';
import { KEY_GREEN } from '/constants';
import styles from '/constants/CampaignBuilder';

interface Props
  extends Pick<
    CampaignBuilderComponentProps,
    'isSaving' | 'hasUnsavedChanges' | 'onSaveCampaignDraft' | 'next'
  > {
  validateForm: () => boolean;
  onNext: () => void;
  exit: () => void;
  disableNext?: boolean;
  nextButtonLabel?: string;
}

export default function CampaignBuilderFooter(props: Props) {
  return (
    <View
      style={[
        styles.bottomSectionContainer,
        {
          justifyContent: 'space-between',
        },
      ]}
    >
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        <Button
          label="Exit"
          onPress={props.exit}
          containerStyle={{
            marginRight: 8,
          }}
        />
        <View
          pointerEvents={
            props.isSaving || !props.hasUnsavedChanges ? 'none' : 'auto'
          }
          style={{
            opacity: props.hasUnsavedChanges || props.isSaving ? 1 : 0,
          }}
        >
          <Button
            style={{
              opacity: props.isSaving ? 0.6 : 1,
              width: 118,
            }}
            onPress={props.onSaveCampaignDraft}
            label={props.isSaving ? 'Saving...' : 'Save Draft'}
          />
        </View>
      </View>
      <Button
        style={{
          backgroundColor: KEY_GREEN,
        }}
        disabled={props.disableNext}
        onPress={() => {
          if (props.validateForm()) {
            props.onNext?.();
          }
        }}
        label={props.nextButtonLabel ?? 'Next'}
      />
    </View>
  );
}
