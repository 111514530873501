import { UpdateResolver } from '@urql/exchange-graphcache';
import { GetUserBigIssuesDocument } from '/generated/graphql';

const deleteBigIssue: UpdateResolver = (result, args, cache) => {
  const userId = (result.deleteBigIssue as any)?.userId;

  if (!userId) return;

  const query = GetUserBigIssuesDocument;

  const draftValues = [true, false, undefined];

  draftValues.forEach((draft) => {
    const variables: any = { userId };

    if (draft !== undefined) variables.draft = draft;

    cache.updateQuery({ query, variables }, (data) => {
      const issues = [...((data?.getBigIssues || []) as any[])];

      return {
        getBigIssues: issues.filter((issue) => issue.id !== args.id),
      } as any;
    });
  });
};

export default deleteBigIssue;
