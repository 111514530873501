import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Platform, Text, TextInput, View } from 'react-native';

import { KeyboardAwareScrollView } from '@mtourj/react-native-keyboard-aware-scroll-view';

import { FontAwesome5 } from '@expo/vector-icons';

import { CampaignBuilderComponentProps } from '../../CampaignBuilder';

import RemovableBadge from '/components/RemovableBadge';

import {
  KEY_GRAY,
  KEY_GREEN,
  REQUIRED_INPUT_INVALID,
  TEXT_INPUT,
  TEXT_INPUT_LARGE,
} from '/constants';
import styles from '/constants/CampaignBuilder';

import SpeciesPickerModal from '../../../SpeciesPicker/SpeciesPickerModal';
import AddAuthors from '../AddAuthors/AddAuthors';
import CampaignBuilderFooter from '../CampaignBuilderFooter';
import Alert from '/Alert';
import Lightening from '/assets/jsicons/bottomnavigation/Lightening';
import MapMarker from '/assets/jsicons/headerIcons/map-marker';
import Button from '/components/Button';
import LocationInput from '/components/LocationInput/LocationInput';
import UploadMedia from '/components/UploadMedia/UploadMedia';
import {
  useFormValidationContext,
  ValidatedAny,
  ValidatedTextInput,
  withFormValidation,
} from '/components/ValidatedForm';
import AddNewMedia from '/compositions/AddNewMedia';
import { useHabitatPickerContext } from '/context';
import { IucnThreatStatus, User } from '/generated/graphql';
import { DeepPartial } from '/types';
import UserMentionTextInput from '/components/UserMentionTextInput/UserMentionTextInput';

const ArticleBuilder = (props: CampaignBuilderComponentProps) => {
  const habitatPicker: any = useHabitatPickerContext();
  const [showSpeciesPicker, setShowSpeciesPicker] = useState(false);

  const [isUploading, setIsUploading] = useState(false);

  const scrollViewRef = useRef<KeyboardAwareScrollView>();

  const { validateForm, fields } = useFormValidationContext(scrollViewRef);

  useEffect(() => {
    if (habitatPicker.select) {
      habitatPicker.setSelect('');
    }
  }, [habitatPicker]);

  const onAddAuthor = useCallback(
    function (author: DeepPartial<User>) {
      props.setData({
        post: {
          authors: [
            ...(Array.from(props.data?.post.authors ?? []) as any[]),
            author,
          ],
        },
      });
    },
    [props],
  );

  const onRemoveAuthor = useCallback(
    function (authorId: string) {
      props.setData({
        post: {
          authors: Array.from(props.data?.post.authors ?? []).filter(
            (author) => author.id !== authorId,
          ),
        },
      });
    },
    [props],
  );

  const descriptionInput: MutableRefObject<TextInput | null> = useRef(null);

  return (
    <>
      <KeyboardAwareScrollView
        innerRef={(r: any) => {
          if (r) scrollViewRef.current = r;
        }}
        style={{
          paddingTop: 120,
        }}
        keyboardShouldPersistTaps="handled"
        enableResetScrollToCoords={false}
        contentContainerStyle={[
          styles.scrollView,
          { paddingBottom: Platform.OS === 'web' ? 0 : 120 },
        ]}
      >
        <SpeciesPickerModal
          visible={showSpeciesPicker}
          onRequestClose={(species) => {
            setShowSpeciesPicker(false);

            if (!species) return;

            const speciesArray = props.data?.campaign.species ?? [];

            // If this species is already in the list, don't add it again
            if (
              speciesArray.find(
                (s) =>
                  s?.species.acceptedNameUsageID ===
                    species.acceptedNameUsageID ||
                  s?.species.taxonID === species.acceptedNameUsageID,
              )
            ) {
              Alert.alert(
                'Species already added',
                'This species has already been added to the list',
              );
              return;
            }

            props.setData({
              campaign: {
                species: [
                  ...speciesArray,
                  {
                    species: {
                      taxonID: species.taxonID,
                      preferredVernacularName: species.vernacularName,
                      canonicalName: species.canonicalName,
                      ...(species.threatStatus
                        ? {
                            iucnThreatStatus: {
                              taxonID: species.taxonID,
                              threatStatus:
                                species.threatStatus as IucnThreatStatus,
                            },
                          }
                        : {}),
                    },
                    vernacularName: species.vernacularName,
                  },
                ],
              },
            });
          }}
        />

        <View style={styles.sectionContainer}>
          <View style={styles.horizontalContainer}>
            <View style={styles.iconContainer}>
              <Lightening fill={KEY_GREEN} />
            </View>
            <ValidatedTextInput
              name="name"
              containerStyle={{ flex: 1 }}
              style={[TEXT_INPUT]}
              placeholderTextColor="gray"
              placeholder="Campaign Title"
              maxLength={128}
              enterKeyHint="next"
              value={props.data?.campaign.name}
              onChangeText={(text) => {
                props.setData({ campaign: { name: text } });
              }}
              onSubmitEditing={() => {
                descriptionInput.current?.focus();
              }}
            />
          </View>
        </View>

        <View style={styles.sectionContainer}>
          <Text style={styles.subheading}>ADD AUTHORS</Text>
          <Text style={styles.description}>
            Mention the team members who are involved in writing this campaign
            post
          </Text>

          <AddAuthors
            authors={props.data?.post.authors}
            onAddAuthor={onAddAuthor}
            onRemoveAuthor={onRemoveAuthor}
          />
        </View>
        <ValidatedAny
          name="media"
          value={{ media: props.data?.post.media, isUploading }}
          validate={(value) => {
            return (
              !!value?.media?.some((item) => item.length) && !value.isUploading
            );
          }}
        >
          <View
            style={[
              styles.sectionContainer,
              fields.media?.valid
                ? {
                    borderWidth: 1,
                    borderColor: 'transparent',
                  }
                : {
                    borderWidth: 1,
                    borderColor: 'crimson',
                  },
            ]}
          >
            <Text style={styles.subheading}>Add a photo or video</Text>
            <Text style={styles.description}>
              This will be the image or video for your campaign
            </Text>
            <View
              style={{
                paddingVertical: 18,
              }}
            >
              <UploadMedia
                style={{
                  alignSelf: 'center',
                  backgroundColor: '#eee',
                  height: 280,
                  width: '100%',
                  borderRadius: 0,
                }}
                multiple
                selectionLimit={10}
                onChangeMedia={(media) => {
                  props.setData({
                    post: {
                      media: media.map((item) => item.uri),
                      thumbnail: media[0]?.thumbnailUri,
                    },
                  });
                }}
                onUploadStart={() => {
                  setIsUploading(true);
                }}
                onUploadEnd={() => {
                  setIsUploading(false);
                }}
                media={
                  props.data?.post.media?.map((uri) => ({
                    uri,
                  })) || []
                }
                placeholderImage={<AddNewMedia />}
              />
            </View>
          </View>
        </ValidatedAny>
        <View style={styles.sectionContainer}>
          <Text style={styles.subheading}>
            Tell us what is happening and what support you are hoping to receive
          </Text>
          <Text style={styles.description}>
            You will go into more detail about the exact support you need in the
            next steps.
          </Text>
          <ValidatedAny
            name="description"
            value={props.data?.post.description?.text}
          >
            <UserMentionTextInput
              type="unspecified"
              mentions={props.data?.post.mentions ?? []}
              onChangeMentions={(mentions) => {
                props.setData({
                  post: {
                    mentions: mentions.map((m) => ({
                      start: m.start,
                      end: m.end,
                      user: {
                        id: m.user.id,
                        name: m.user.name,
                      },
                    })),
                  },
                });
              }}
              innerRef={(r) => (descriptionInput.current = r)}
              style={[
                TEXT_INPUT_LARGE,
                { marginVertical: 8, fontFamily: 'Lato' },
                fields.description?.valid === false
                  ? REQUIRED_INPUT_INVALID
                  : {},
              ]}
              placeholder="Tell us what is happening..."
              value={props.data?.post.description?.text}
              onChangeText={(text) => {
                props.setData({ post: { description: { text } } });
              }}
              maxLength={2000}
              multiline
            />
          </ValidatedAny>
        </View>
        <View style={[styles.sectionContainer, { zIndex: 2 }]}>
          <Text style={styles.subheading}>Add location</Text>
          <Text style={styles.description}>
            Add the general location of this post. (optional){`\n`}
            For In-Person volunteer posts, you will be able to add a more
            precise location for where volunteers should meet up further in the
            campaign builder.
          </Text>
          <View style={[styles.horizontalContainer, { zIndex: 2 }]}>
            <MapMarker fill={KEY_GRAY} width={24} height={24} />
            <View
              style={{
                flex: 1,
                paddingLeft: 8,
              }}
            >
              <LocationInput
                style={TEXT_INPUT}
                value={props.data?.post.location}
                onChangeText={(text, isValid, prediction) => {
                  props.setData({
                    post: {
                      location: text,
                      latitude: prediction?.latitude?.toString() ?? '',
                      longitude: prediction?.longitude?.toString() ?? '',
                    },
                  });
                }}
              />
            </View>
          </View>
        </View>
        <View style={styles.sectionContainer}>
          <View style={styles.titleWithIconContainer}>
            <View
              style={{
                paddingRight: 8,
              }}
            >
              <FontAwesome5 name="paw" size={28} color={KEY_GRAY} />
            </View>
            <Text style={styles.subheading}>Species in this post</Text>
          </View>
          <Text style={styles.description}>
            Is there a species in this post? Let people know what they are.
          </Text>
          <Button
            label="Add species"
            containerStyle={{
              marginVertical: 8,
            }}
            onPress={() => {
              setShowSpeciesPicker(true);
            }}
          />
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {props.data?.campaign.species &&
              props.data?.campaign.species?.map((selection) => {
                if (!selection) return;

                const species = selection.species;
                const label =
                  selection.vernacularName ||
                  species.preferredVernacularName ||
                  species.canonicalName;

                return (
                  <RemovableBadge
                    key={selection.species.taxonID}
                    label={label}
                    onRemove={() => {
                      props.setData({
                        campaign: {
                          species: (props.data?.campaign.species ?? []).filter(
                            (s) => s?.species?.taxonID !== species.taxonID,
                          ),
                        },
                      });
                    }}
                  />
                );
              })}
          </View>
        </View>
      </KeyboardAwareScrollView>
      <CampaignBuilderFooter
        {...props}
        onNext={() => props.next()}
        validateForm={validateForm}
      />
    </>
  );
};

export default withFormValidation(ArticleBuilder);
