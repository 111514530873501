import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';

const joinTeam: UpdateResolver = (result, args, cache) => {
  if (!result.joinTeam) return;

  const fragment = gql`
    fragment JoinTeam on User {
      id
      team {
        id
      }
    }
  `;

  const data = cache.readFragment(fragment, {
    id: args.organizationId,
  }) as any;

  /** If the organization is not in the cahce, no need to update anything */
  if (!data) return;

  /** If this user does not have a Team attached to it already, attach it now */
  if (!data.team?.id) {
    cache.writeFragment(fragment, {
      __typename: 'User',
      id: args.organizationId,
      team: {
        __typename: 'Team',
        id: (result.joinTeam as any).teamId as string,
      },
    });
  }

  cache.link(
    {
      __typename: 'Team',
      id: (result.joinTeam as any).teamId as string,
    },
    'membership',
    result.joinTeam as any,
  );
};

export default joinTeam;
