import { post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useCallback, useEffect, useState } from 'react';

type StripeBalance = {
  total: number;
  available: number;
};

interface IUseStripeBalanceReturns {
  balance: StripeBalance;
  currency: string;
  fetching: boolean;
  error: boolean;
  refresh: () => Promise<void>;
}

export default function useStripeBalance(): IUseStripeBalanceReturns {
  const [totalBalance, setTotalBalance] = useState(0);
  const [availableBalance, setAvailableBalance] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);
  const [currency, setCurrency] = useState('USD');

  const getBalance = useCallback(async function (): Promise<void> {
    const session = await fetchAuthSession();
    const idToken = session.tokens?.idToken?.toString();

    try {
      setFetching(true);
      setError(false);

      const response = await post({
        apiName: 'stripeconnect',
        path: '/stripe/balance',
        options: {
          body: {
            authorization: idToken,
          } as any,
        },
      }).response;

      const { balance } = (await response.body.json()) as {
        balance: {
          available: any[];
          pending: any[];
        };
      };

      const total =
        (balance.available?.[0].amount ?? 0) +
        (balance.pending?.[0].amount ?? 0);
      const available = balance.available?.[0].amount ?? 0;

      setTotalBalance(total);
      setAvailableBalance(available);
      setCurrency(balance.available?.[0].currency?.toUpperCase?.() ?? 'USD');
    } catch (err) {
      setError(true);

      console.log('getStripeBalance error', err);
    } finally {
      setFetching(false);
    }
  }, []);

  useEffect(() => {
    getBalance();
  }, [getBalance]);

  return {
    balance: {
      total: totalBalance,
      available: availableBalance,
    },
    currency,
    fetching,
    error,
    refresh: getBalance,
  };
}
