import React, { useState, useEffect } from 'react';
import { StyleSheet, Animated } from 'react-native';

// To use tooltip, simply:
/*
  <Tooltip show={toShowOrNotToShow}>
    ...  
    <ChildrenGoHere... />
    ...
  </Tooltip>
*/
// Optional props: opacity, noShadow, color, backgroundColor,
// width, maxWidth, height, position ('top', 'bottom', 'left', 'right'),
// align ('left', 'right'), allowPointerEvents, style

// Feel free to modify this file to accept more props and allow for more
// customization -- As long as this component remains generalized

const Tooltip = (props) => {
  const height = props.height || 64;
  const width = props.width || '100%';
  const opacity = props.opacity || 0.9;

  const layout = {};

  let edge;
  let value = height;

  switch (props.position) {
    case 'bottom': {
      edge = props.position;
      break;
    }
    case 'left' || 'right': {
      edge = props.position;
      value = typeof width === 'number' ? width : width;
      break;
    }
    default:
      edge = 'top';
  }

  layout[edge] = typeof value === 'number' ? -value - 8 : value;

  const [shouldDisplay, setShouldDisplay] = useState(false);

  const [animation] = useState(new Animated.Value(0));

  const showAnim = Animated.timing(animation, {
    useNativeDriver: true,
    toValue: opacity,
    duration: 160,
  });

  const hideAnim = Animated.timing(animation, {
    useNativeDriver: true,
    toValue: 0,
    duration: 160,
  });

  const animate = () => {
    if (props.show) {
      setShouldDisplay(true);
      showAnim.start();
    } else {
      hideAnim.start(() => {
        setShouldDisplay(false);
      });
    }
  };

  useEffect(animate, [props.show]);

  const styles = StyleSheet.create({
    container: {
      maxWidth: props.maxWidth || undefined,
      width: props.width || '100%',
      backgroundColor: props.backgroundColor || 'white',
      zIndex: 100,
      height,
      left: props.align === 'left' ? 0 : undefined,
      right: props.align === 'right' ? 0 : undefined,
      shadowColor: props.noShadow ? '#0000' : 'gray',
      shadowRadius: 10,
      elevation: 5,
      shadowOpacity: 1,
      borderRadius: 8,
      padding: 10,
      position: 'absolute',
      ...layout,
    },
  });

  return (
    <Animated.View
      style={[
        styles.container,
        { opacity: animation, display: shouldDisplay ? 'flex' : 'none' },
        props.style,
        {
          pointerEvents: props.allowPointerEvents ? 'box-none' : 'none',
        },
      ]}
    >
      {props.children}
    </Animated.View>
  );
};

export default Tooltip;
