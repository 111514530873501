import { Entypo } from '@expo/vector-icons';
import React, { useRef, useState } from 'react';
import {
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Dropdown } from 'react-native-element-dropdown';
import CreativeConnectCreativeTypeFilterModal from '../CreativeConnectCreativeTypeFilterModal/CreativeConnectCreativeTypeFilterModal';
import SpeciesFilterButton from '../../FilterButtons/SpeciesFilterButton/SpeciesFilterButton';
import { ISpeciesPickerSpecies } from '/components/SpeciesPicker/SpeciesPickerModal';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import ScrollView from '/components/common/ScrollView/ScrollView';
import {
  BUTTON_SHADOW,
  KEY_GRAY,
  KEY_LIGHT_GRAY,
  PRIMARY_BUTTON_BACKGROUND,
} from '/constants';
import { useAuthContext, useModalContext, useTeamContext } from '/context';
import {
  CreativeConnectProjectCreativeType,
  CreativeConnectProjectFilter,
  TeamMemberRole,
  TeamMembershipStatus,
  User,
  UserRole,
} from '/generated/graphql';
import getCreativeTypeLabel from '/util/creative-connect/getCreativeTypeLabel';
import UserFilterButton from '/components/FilterButtons/UserFilterButton/UserFilterButton';
import { ViewStyle } from 'react-native';
import { isUnderPrivileged } from '/util';

interface Props {
  filter: CreativeConnectProjectFilter;
  setFilter: (filter: CreativeConnectProjectFilter) => void;
  containerStyle?: StyleProp<ViewStyle>;
  /** If specified, only show the specified filters */
  showFilters?: {
    /** If set to `self-and-authorized-teams-only`, `createdBy` filter will have to be set to either
     * authenticated user or one of their teams (on which they have the Creator role or higher).
     * If set to `true`, `createdBy` filter can be set to "Any".
     */
    createdBy: boolean | 'self-and-authorized-teams-only';
    status: boolean;
    acceptingApplications: boolean;
    creativeType: boolean;
    species: boolean;
  };
}

export default function CreativeConnectFilter(props: Props) {
  const { spawnModal, closeModal: _closeModal } = useModalContext();
  const { userData } = useAuthContext();
  const { teams } = useTeamContext();

  const [species, setSpecies] = useState<ISpeciesPickerSpecies[]>(
    (props.filter.species as any) ?? [],
  );
  const [creativeTypes, setCreativeTypes] = useState<
    CreativeConnectProjectCreativeType[]
  >(props.filter.creative_types ?? []);
  const [createdByFilter, setCreatedByFilter] = useState<
    Pick<User, 'id' | 'name' | 'profile_image'> | undefined
  >(
    [userData, ...teams.map((t) => t.user)].find(
      (u) => u?.id === props.filter.created_byId,
    ) ||
      (props.filter.created_byId
        ? {
            id: props.filter.created_byId,
            name: '...',
          }
        : undefined) ||
      (props.showFilters?.createdBy === 'self-and-authorized-teams-only'
        ? userData || undefined
        : undefined),
  );

  const modalId = useRef<string | null>(null);
  const closeModal = () => {
    modalId.current && _closeModal(modalId.current);
    modalId.current = null;
  };

  function onCreativeTypeFilterPressed() {
    modalId.current = spawnModal({
      title: 'Filter by Creative Type',
      component: (
        <CreativeConnectCreativeTypeFilterModal
          initialCreativeTypes={creativeTypes}
          onRequestClose={(types) => {
            setCreativeTypes(types);
            closeModal();

            props.setFilter({
              ...props.filter,
              creative_types: types,
            });
          }}
        />
      ),
    });
  }

  const creativeTypeFilterLabel = (() => {
    if (!creativeTypes.length) return 'Any';

    if (creativeTypes.length === 1) {
      return getCreativeTypeLabel(creativeTypes[0]);
    }

    return `${creativeTypes.length} types`;
  })();

  return (
    <View style={[styles.container, props.containerStyle]}>
      <Text style={styles.titleText}>FILTER</Text>
      <ScrollView
        horizontal
        contentContainerStyle={{
          paddingBottom: 4,
          paddingLeft: 3,
        }}
        showsHorizontalScrollIndicator={Platform.OS === 'web'}
      >
        {/* CREATED BY */}
        {props.showFilters && props.showFilters.createdBy ? (
          <View style={styles.fieldContainer}>
            <Text style={styles.fieldLabel}>CREATED BY</Text>
            <UserFilterButton
              value={createdByFilter}
              style={{
                backgroundColor: PRIMARY_BUTTON_BACKGROUND,
              }}
              required={
                props.showFilters.createdBy === 'self-and-authorized-teams-only'
              }
              onChange={(newCreatedByFilter) => {
                setCreatedByFilter(newCreatedByFilter);
                props.setFilter({
                  ...props.filter,
                  created_byId: newCreatedByFilter?.id,
                });
              }}
              users={
                userData?.role === UserRole.Conservationist
                  ? [userData]
                  : userData
                  ? [
                      userData,
                      ...teams
                        .filter(
                          (t) =>
                            t.membership?.membership_status ===
                              TeamMembershipStatus.Confirmed &&
                            !isUnderPrivileged(
                              TeamMemberRole.Creator,
                              t.membership.team_role,
                            ),
                        )
                        .map((t) => t.user),
                    ]
                  : []
              }
            />
          </View>
        ) : null}

        {/* STATUS */}
        {!props.showFilters || props.showFilters.status ? (
          <View style={styles.fieldContainer}>
            <Text style={styles.fieldLabel}>STATUS</Text>
            <ProjectStatusDropdown
              value={props.filter.is_premiered}
              onChange={(isPremiered) => {
                props.setFilter({
                  ...props.filter,
                  is_premiered: isPremiered,
                });
              }}
            />
          </View>
        ) : null}

        {/* ACCEPTING APPLICATIONS */}
        {!props.showFilters || props.showFilters.acceptingApplications ? (
          <View style={styles.fieldContainer}>
            <Text style={styles.fieldLabel}>COLLABORATION</Text>
            <AcceptingApplicationsDropdown
              value={props.filter.accepting_applications}
              onChange={(acceptingApplications) => {
                props.setFilter({
                  ...props.filter,
                  accepting_applications: acceptingApplications,
                });
              }}
            />
          </View>
        ) : null}

        {/* CREATIVE TYPES */}
        {!props.showFilters || props.showFilters.creativeType ? (
          <View style={styles.fieldContainer}>
            <Text style={styles.fieldLabel}>CREATIVE TYPE</Text>
            <Pressable
              onPress={onCreativeTypeFilterPressed}
              style={styles.filterButton}
            >
              <HorizontalContainer
                style={{
                  justifyContent: 'space-between',
                }}
              >
                <Text numberOfLines={1} style={styles.filterButtonText}>
                  {creativeTypeFilterLabel || 'Any'}
                </Text>
                <Entypo
                  name="chevron-small-down"
                  size={20}
                  style={{
                    width: 20,
                    height: 20,
                  }}
                  color="gray"
                />
              </HorizontalContainer>
            </Pressable>
          </View>
        ) : null}

        {/* SPECIES */}
        {!props.showFilters || props.showFilters.species ? (
          <View style={styles.fieldContainer}>
            <Text style={styles.fieldLabel}>SPECIES</Text>

            <SpeciesFilterButton
              species={species}
              onChange={(speciesFilter) => {
                setSpecies(speciesFilter);
                props.setFilter({
                  ...props.filter,
                  species: speciesFilter.map((s) => ({
                    taxonID: s.acceptedNameUsageID,
                    vernacularName: s.vernacularName,
                  })) as CreativeConnectProjectFilter['species'],
                });
              }}
            />
          </View>
        ) : null}
      </ScrollView>
    </View>
  );
}

interface IProjectStatusDropdownProps {
  value: CreativeConnectProjectFilter['is_premiered'];
  onChange: (value: CreativeConnectProjectFilter['is_premiered']) => void;
}

function ProjectStatusDropdown(props: IProjectStatusDropdownProps) {
  return (
    <Dropdown
      style={styles.filterButton}
      selectedTextStyle={styles.filterButtonText}
      data={[
        {
          label: 'All',
          value: undefined,
        },
        {
          label: 'Released',
          value: true,
        },
        {
          label: 'In Development',
          value: false,
        },
      ]}
      labelField="label"
      valueField="value"
      onChange={(item) => {
        props.onChange(item.value);
      }}
      placeholder="Select..."
      placeholderStyle={{
        color: KEY_GRAY,
        fontFamily: 'Lato-Bold',
      }}
      value={props.value as any}
    />
  );
}

interface IAcceptingApplicationsDropdownProps {
  value: CreativeConnectProjectFilter['accepting_applications'];
  onChange: (
    value: CreativeConnectProjectFilter['accepting_applications'],
  ) => void;
}

function AcceptingApplicationsDropdown(
  props: IAcceptingApplicationsDropdownProps,
) {
  return (
    <Dropdown
      style={styles.filterButton}
      selectedTextStyle={styles.filterButtonText}
      data={[
        {
          label: 'All',
          value: undefined,
        },
        {
          label: 'Open For Collaboration',
          value: true,
        },
        {
          label: 'Closed For Collaboration',
          value: false,
        },
      ]}
      labelField="label"
      valueField="value"
      onChange={(item) => {
        props.onChange(item.value);
      }}
      placeholder="Select..."
      placeholderStyle={{
        color: KEY_GRAY,
        fontFamily: 'Lato-Bold',
      }}
      value={props.value as any}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 8,
  },
  titleText: {
    color: 'white',
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 16,
  },
  fieldLabel: {
    color: 'white',
    fontFamily: 'Lato-Bold',
    fontSize: 14,
    marginTop: 8,
    marginBottom: 4,
  },
  fieldContainer: {
    marginRight: 6,
  },
  filterButton: {
    borderWidth: 1,
    borderColor: KEY_LIGHT_GRAY,
    borderRadius: 6,
    marginBottom: 6,
    padding: 8,
    backgroundColor: PRIMARY_BUTTON_BACKGROUND,
    alignSelf: 'flex-start',
    minWidth: 140,
    ...BUTTON_SHADOW,
    ...(Platform.OS === 'web'
      ? {
          // @ts-ignore
          cursor: 'pointer',
        }
      : {}),
  },
  filterButtonText: {
    fontFamily: 'Lato-Bold',
    color: 'black',
    fontSize: 16,
  },
});
