/**
 * Allows class components to use the `useFileUpload` hook's functionality
 */
import { forwardRef } from 'react';
import useFileUpload, { useFileUploadReturns } from '/hooks/useFileUpload';

export type { useFileUploadReturns as FileUploadProps };

export default function withFileUpload<OwnProps = any>(
  WrappedComponent: React.ComponentType<OwnProps & useFileUploadReturns>,
) {
  return forwardRef((props: OwnProps, ref) => {
    const fileUpload = useFileUpload();

    return <WrappedComponent {...props} {...fileUpload} ref={ref} />;
  });
}
