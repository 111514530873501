import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';

const addBookmark: UpdateResolver = (result, args, cache) => {
  const fragment = gql`
    fragment AddBookmark on Campaign {
      id
      is_bookmarked
    }
  `;

  cache.writeFragment(fragment, {
    id: args.id,
    is_bookmarked: true,
  });
};

export default addBookmark;
