/* eslint-disable no-bitwise */
/**
 * getCDNImageUri
 *
 * This utlity allows for easy creation of dynamic URI's to access images via the CDN and fetch any size of image
 */
import awsconfig from '/aws-exports';
import env from '/env';

type ImageDimensions = {
  width: number;
  height: number;
};

const domainAndProtocolRegex =
  /^(https?:\/\/)?(([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}/i;

// Get CloudFront endpoint
const ENDPOINT = env.CDN_ENDPOINT;

const ALLOWED_IMAGE_TYPES = new Set([
  'png',
  'jpg',
  'jpeg',
  'bmp',
  'tiff',
  'gif',
]);

const ALLOWED_THUMBNAIL_VIDEO_TYPES = new Set([
  'mp4',
  'mov',
  'avi',
  'wmv',
  'flv',
  'qt',
]);

type GetCDNImageUriOptions = {
  uri: string | undefined | null;
  dimensions?: ImageDimensions;
  isThumbnail?: boolean;
};

export default function getCDNImageUri({
  uri,
  dimensions,
  isThumbnail,
}: GetCDNImageUriOptions) {
  // If `uri` is falsy, return undefined so we can use this function directly
  // in our `source` prop where we want to feed the resulting URI
  if (typeof uri !== 'string' || !uri?.trim()) return undefined;

  const _result = uri.match(domainAndProtocolRegex);
  const fileType = uri.substring(uri.lastIndexOf('.') + 1);

  const isAllowedImage = ALLOWED_IMAGE_TYPES.has(fileType);

  const isAllowedVideo =
    isThumbnail && ALLOWED_THUMBNAIL_VIDEO_TYPES.has(fileType);

  // If the URI does not point to the current userassetsstorage bucket,
  // or it is an unsupported format, then do not do anything with this uri
  if (
    !_result?.[0].includes(awsconfig.aws_user_files_s3_bucket) ||
    (!isAllowedImage && !isAllowedVideo)
  )
    return uri;

  // Replace domain of URI with CloudFront CDN domain
  let newUri = uri.replace(domainAndProtocolRegex, ENDPOINT);

  if (!dimensions && !isThumbnail) {
    // If no dimensions or thumbnail option provided, just return uri with domain replaced
    return newUri;
  }

  /** If the URL is already formatted with (thumb_?)(dimensions?), then
   * we need to account for that */
  const pathParts = newUri.split('/');
  const fileNameIndex = pathParts.length - 1;
  const thumbAndDimensionsIndex = fileNameIndex - 1;

  const thumbAndDimensionsPart = pathParts[thumbAndDimensionsIndex];
  const thumbAndDimensionsMatch = thumbAndDimensionsPart.match(
    /^(thumb_)?(\d+x\d+)?$/,
  );

  if (thumbAndDimensionsMatch) {
    if (thumbAndDimensionsMatch[1]) {
      // If the URI already has a thumb_ prefix, set isThumbnail
      // true to add it back in
      isThumbnail = true;
    }

    if (thumbAndDimensionsMatch[2]) {
      // If the URI already has dimensions, set them to the dimensions
      // we will use
      const [width, height] = thumbAndDimensionsMatch[2].split('x');
      dimensions = {
        width: parseInt(width, 10),
        height: parseInt(height, 10),
      };
    }
  }

  let result =
    pathParts
      .slice(
        0,
        thumbAndDimensionsMatch ? thumbAndDimensionsIndex : fileNameIndex,
      )
      .join('/') + '/';

  if (isThumbnail) {
    result += 'thumb_';
  }

  if (dimensions?.width && dimensions.height) {
    /** This prevents us from creating too many different sized copies of one image */
    // Clamp to nearest highest multiple of 32, without rounding up if we are already
    // at a multiple of 32, and never going below 32
    const targetWidth = Math.max(32, ((dimensions.width - 1) | 31) + 1);
    const targetHeight = Math.max(32, ((dimensions.height - 1) | 31) + 1);

    const targetDimensions = `${targetWidth}x${targetHeight}`;

    result += targetDimensions;
  }

  result += '/' + pathParts[fileNameIndex];

  // Return new uri with dimensions included
  return result;
}
