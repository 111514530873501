import {
  AntDesign,
  EvilIcons,
  Feather,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
} from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { setStringAsync } from 'expo-clipboard';
import { requestMediaLibraryPermissionsAsync } from 'expo-image-picker';
import { saveToLibraryAsync } from 'expo-media-library';
import qrcode from 'qrcode';
import React, { useRef, useState } from 'react';
import {
  Platform,
  Pressable,
  Share,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import Popover, { PopoverPlacement } from 'react-native-popover-view';
import QRCodeComponent from 'react-native-qrcode-svg';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';
import { captureRef } from 'react-native-view-shot';
import Button from '../Button';
import UserSearch from '../Search/UserSearch';
import HorizontalContainer from '../common/Generic/HorizontalContainer';
import ScrollView from '../common/ScrollView/ScrollView';
import Alert from '/Alert';
import Facebook from '/assets/jsicons/socialmedia/Facebook';
import LinkedIn from '/assets/jsicons/socialmedia/LinkedIn';
import Twitter from '/assets/jsicons/socialmedia/Twitter';
import { KEY_GRAY, KEY_GREEN, KEY_LIGHT_GRAY, TEXT_INPUT } from '/constants';
import { useModalContext } from '/context';
import useBinaryTimingAnimation from '/hooks/useBinaryTimingAnimation';
import { formatURL } from '/util';
import shareToFacebook from '/util/shareToFacebook';
import shareToLinkedIn from '/util/shareToLinkedIn';
import shareToTwitter from '/util/shareToTwitter';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

type Props = {
  /** If available, this will be the pre-filled title of the post */
  shareTitle: string;
  /** If available, this will be the pre-filled message of the post */
  shareMessage: string;
  /** The URL to be shared */
  shareURL: string;
  style?: ViewStyle;
  disabled?: boolean;
  widget?: JSX.Element;
  isVisible?: boolean;
  initialIsVisible?: boolean;
  onRequestClose?: () => void;
};

const ICON_SIZE = 28;

type ShareOption =
  | 'twitter'
  | 'facebook'
  | 'linkedin'
  | 'copy-link'
  | 'direct-message';

export default function SharePopover(props: React.PropsWithChildren<Props>) {
  const [_isVisible, _setIsVisible] = React.useState(
    props.initialIsVisible ?? props.isVisible ?? false,
  );

  const safeAreaInsets = useSafeAreaInsets();
  const { spawnModal, closeModal } = useModalContext();

  const isVisible = props.isVisible ?? _isVisible;

  const qrCodeViewRef = useRef<View>();
  const linkInputRef = useRef<TextInput>();

  const [qrCodeDownloadSuccess, setQRCodeDownloadSuccess] = useState(false);
  const qrCodeDownloadSuccessAnimation = useBinaryTimingAnimation({
    value: qrCodeDownloadSuccess,
    duration: 200,
  });

  const setIsVisible = (visible: boolean) => {
    _setIsVisible(visible);

    if (visible === false && props.onRequestClose) {
      props.onRequestClose();
    }
  };

  const shareWithUserModalIdRef = useRef<string>();
  function shareViaDirectMessage() {
    shareWithUserModalIdRef.current = spawnModal({
      title: 'Share with...',
      component: (
        <UserSearchModal
          shareURL={props.shareURL}
          onClose={() => {
            closeModal(shareWithUserModalIdRef.current!);
          }}
        />
      ),
    });
  }

  function onShare(option: ShareOption, closePopover = true) {
    switch (option) {
      case 'twitter':
        shareToTwitter(props.shareMessage, props.shareURL);
        break;
      case 'facebook':
        shareToFacebook(props.shareURL);
        break;
      case 'linkedin':
        shareToLinkedIn(props.shareURL, props.shareTitle, props.shareMessage);
        break;
      case 'copy-link':
        setStringAsync(props.shareURL).then((value) => {
          if (value) {
            Alert.notify({
              message: 'Link copied to clipboard!',
              color: KEY_GREEN,
            });
          } else {
            Alert.notify({
              message: 'Failed to copy link to clipboard',
              color: 'crimson',
              textColor: 'white',
            });
          }
        });
        break;
      case 'direct-message': {
        shareViaDirectMessage();
        break;
      }
      default: {
        console.error('Unhandled share option:', option);
        option satisfies never;
      }
    }

    if (closePopover) setIsVisible(false);
  }

  async function onMoreShareOptions() {
    const { action } = await Share.share(
      {
        url: props.shareURL,
      },
      {
        dialogTitle: props.shareTitle || 'Share',
        subject: props.shareMessage,
      },
    );

    if (action === 'sharedAction') setIsVisible(false);
  }

  async function onDownloadQRCode() {
    const { granted } = await requestMediaLibraryPermissionsAsync(true);

    if (!granted) {
      Alert.notify({
        message: 'Permission to save to library denied',
        color: 'crimson',
        textColor: 'white',
      });
      return;
    }

    function getDataURLAsync(content: string) {
      return new Promise((resolve, reject) => {
        qrcode.toDataURL(content, (err: any, url: string) => {
          if (err) {
            reject(err);
          } else {
            resolve(url);
          }
        });
      });
    }

    try {
      if (Platform.OS === 'web') {
        const dataURL = (await getDataURLAsync(props.shareURL)) as string;
        const a = document.createElement('a');
        a.href = dataURL;
        a.download = 'qrcode.png';
        document.body.appendChild(a); // Required for this to work in FireFox
        a.click();
        document.body.removeChild(a);
      } else {
        const path = await captureRef(qrCodeViewRef, {
          quality: 1,
          format: 'png',
        });

        await saveToLibraryAsync(path);

        setQRCodeDownloadSuccess(true);
        setTimeout(() => {
          setQRCodeDownloadSuccess(false);
        }, 5000);
        Alert.notify({
          message: 'QR code saved!',
          color: KEY_GREEN,
        });
      }
    } catch (error) {
      console.error('Failed to save QR code to library:', error);
      Alert.notify({
        message: 'Failed to save QR code',
        color: 'crimson',
        textColor: 'white',
      });
    }
  }

  const QRCODE_SIZE = 144;
  const QRCODE_SAVE_BUTTON_SIZE = 32;

  const animatedQrCodeSuccessOpacityStyle = useAnimatedStyle(() => {
    return {
      opacity: qrCodeDownloadSuccessAnimation.value,
    };
  }, [qrCodeDownloadSuccessAnimation]);

  return (
    <Pressable
      onPress={() => {
        // do nothing
      }}
    >
      <Popover
        isVisible={isVisible}
        placement={PopoverPlacement.FLOATING}
        displayAreaInsets={safeAreaInsets}
        onRequestClose={() => setIsVisible(false)}
        from={
          <TouchableOpacity
            onPress={() => {
              setIsVisible(!isVisible);
            }}
            disabled={props.disabled}
            style={props.style}
          >
            {props.children}
          </TouchableOpacity>
        }
        popoverStyle={styles.container}
      >
        <HorizontalContainer
          style={{
            justifyContent: 'space-between',
            marginBottom: 4,
          }}
        >
          <Text style={styles.title}>SHARE</Text>

          <Pressable
            style={{
              paddingLeft: 6,
            }}
            pressRetentionOffset={{
              top: 8,
              left: 8,
              right: 8,
              bottom: 8,
            }}
            onPress={() => setIsVisible(false)}
          >
            <AntDesign name="close" size={18} />
          </Pressable>
        </HorizontalContainer>

        {/* Custom Widget */}
        {props.widget}

        {/* QR Code */}
        <View
          collapsable={false}
          ref={(r) => {
            if (r) qrCodeViewRef.current = r;
          }}
          style={{
            alignSelf: 'center',
            alignItems: 'center',
            width: QRCODE_SIZE,
            height: QRCODE_SIZE,
            marginHorizontal: QRCODE_SAVE_BUTTON_SIZE + 8,
          }}
        >
          <QRCodeComponent
            value={props.shareURL}
            size={QRCODE_SIZE - 8}
            logoBorderRadius={50}
            logoBackgroundColor="white"
            logo={require('/assets/images/keyFullBlack.png')}
          />
          <Button
            containerStyle={{
              position: 'absolute',
              right: -QRCODE_SAVE_BUTTON_SIZE,
              top: 0,
              width: QRCODE_SAVE_BUTTON_SIZE,
              shadowOpacity: 0,
            }}
            onPress={onDownloadQRCode}
            style={{
              padding: 0,
              height: QRCODE_SAVE_BUTTON_SIZE,
              backgroundColor: KEY_LIGHT_GRAY,
            }}
            label={
              <View>
                <Feather name="download" size={17} color="black" />
                <Animated.View
                  style={[
                    StyleSheet.absoluteFill,
                    {
                      backgroundColor: KEY_LIGHT_GRAY,
                    },
                    animatedQrCodeSuccessOpacityStyle,
                  ]}
                >
                  <FontAwesome
                    name="check-circle"
                    size={17}
                    color={KEY_GREEN}
                  />
                </Animated.View>
              </View>
            }
          />
        </View>

        <HorizontalContainer
          style={{
            marginBottom: 4,
            maxWidth: 320,
            width: '100%',
          }}
        >
          <TextInput
            editable={false}
            ref={(r) => {
              if (r) linkInputRef.current = r;
            }}
            onPressIn={() => {
              if (Platform.OS !== 'web') {
                onShare('copy-link', false);
              }
            }}
            value={formatURL(props.shareURL)}
            selectTextOnFocus
            style={[
              TEXT_INPUT,
              {
                flex: 1,
              },
            ]}
          />
          <Button
            onPress={() => {
              onShare('copy-link');
            }}
            label={<FontAwesome5 name="copy" size={17} color={KEY_GRAY} />}
            style={{
              backgroundColor: KEY_LIGHT_GRAY,
              height: 36,
              padding: 8,
            }}
            containerStyle={{
              shadowOpacity: 0,
              marginLeft: 4,
            }}
          />
        </HorizontalContainer>

        <ScrollView horizontal>
          <View style={styles.buttonWrapper}>
            <Pressable
              onPress={() => onShare('twitter')}
              style={[styles.button, styles.twitter]}
            >
              <Twitter width={ICON_SIZE} height={ICON_SIZE} fill={'white'} />
            </Pressable>
            <Text style={styles.buttonLabel}>Twitter</Text>
          </View>

          <View style={styles.buttonWrapper}>
            <Pressable
              onPress={() => onShare('facebook')}
              style={[styles.button, styles.facebook]}
            >
              <Facebook width={ICON_SIZE} height={ICON_SIZE} fill={'white'} />
            </Pressable>
            <Text style={styles.buttonLabel}>Facebook</Text>
          </View>

          <View style={styles.buttonWrapper}>
            <Pressable
              onPress={() => onShare('linkedin')}
              style={[styles.button, styles.linkedin]}
            >
              <LinkedIn width={ICON_SIZE} height={ICON_SIZE} fill={'white'} />
            </Pressable>
            <Text style={styles.buttonLabel}>LinkedIn</Text>
          </View>

          <View style={styles.buttonWrapper}>
            <Pressable
              onPress={() => onShare('direct-message')}
              style={[styles.button, styles.copyLink]}
            >
              <MaterialIcons name="message" size={ICON_SIZE} color={KEY_GRAY} />
            </Pressable>
            <Text style={styles.buttonLabel}>Direct{`\n`}Message</Text>
          </View>

          {Platform.OS !== 'web' ? (
            <View style={styles.buttonWrapper}>
              <Pressable
                onPress={onMoreShareOptions}
                style={[styles.button, styles.copyLink]}
              >
                <Feather
                  name="more-horizontal"
                  size={ICON_SIZE}
                  color={KEY_GRAY}
                />
              </Pressable>
              <Text style={styles.buttonLabel}>More{`\n`}Options...</Text>
            </View>
          ) : null}
        </ScrollView>
      </Popover>
    </Pressable>
  );
}

const UserSearchModal = ({
  onClose,
  shareURL,
}: {
  onClose: () => void;
  shareURL: string;
}) => {
  const [query, setQuery] = useState('');

  const navigation = useNavigation<StackNavigationProp<any>>();

  return (
    <>
      <HorizontalContainer>
        <TextInput
          autoCorrect={false}
          autoCapitalize="none"
          style={[TEXT_INPUT, { flex: 1 }]}
          placeholderTextColor={'gray'}
          placeholder={`Search all users...`}
          value={query}
          onChangeText={setQuery}
          enterKeyHint="search"
        />
        <Pressable
          style={{
            width: 42,
            height: 32,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <EvilIcons
            style={{
              marginHorizontal: 8,
            }}
            name="search"
            size={28}
            color="black"
          />
        </Pressable>
      </HorizontalContainer>
      <UserSearch
        query={query}
        style={
          Platform.OS === 'web' ? undefined : { flex: undefined, flexGrow: 0 }
        }
        emptyPlaceholderText="Begin typing to search for users"
        onSelectUser={(data) => {
          navigation.navigate('DirectMessageScreen', {
            userId: data.id,
            shareURL: shareURL,
          });
          onClose();
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 6,
    padding: 8,
    backgroundColor: 'white',
    elevation: 2,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
  },
  title: {
    fontSize: 16,
    fontFamily: 'LeagueSpartan-Bold',
    marginBottom: 4,
  },
  buttonWrapper: {
    alignItems: 'center',
    marginRight: 8,
  },
  button: {
    borderRadius: ICON_SIZE,
    padding: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonLabel: {
    fontSize: 12,
    fontFamily: 'Lato',
    marginTop: 4,
    textAlign: 'center',
  },
  twitter: {
    backgroundColor: '#00acee',
  },
  facebook: {
    backgroundColor: '#3b5998',
  },
  linkedin: {
    backgroundColor: '#0077b5',
  },
  copyLink: {
    backgroundColor: '#f5f5f5',
  },
});
