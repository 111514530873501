import React, { useMemo } from 'react';
import { Dropdown } from 'react-native-element-dropdown';
import styles from '../_common.style';
import { User } from '/generated/graphql';
import { KEY_GRAY } from '/constants';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import Avatar from '/components/Avatar';
import { Text, ViewStyle } from 'react-native';
import { StyleProp } from 'react-native';

interface IUser extends Pick<User, 'id' | 'name' | 'profile_image'> {}

type Props = {
  value: IUser | undefined;
  onChange: (value: IUser | undefined) => void;
  users: IUser[];
  required?: boolean;
  style?: StyleProp<ViewStyle>;
};

export default function UserFilterButton({
  value,
  onChange,
  users,
  required,
  style,
}: Props) {
  const options = useMemo(() => {
    const opts = users.map((user) => ({
      label: user.name,
      value: user,
    })) as { label: string; value: IUser | undefined }[];

    if (!required) {
      opts.unshift({
        label: 'Any',
        value: undefined,
      });
    }

    return opts;
  }, [users, required]);

  return (
    <Dropdown
      style={[styles.filterButton, style]}
      selectedTextStyle={styles.filterButtonText}
      data={options}
      labelField="label"
      valueField="value"
      onChange={(item) => {
        onChange(item.value);
      }}
      placeholder="Select..."
      placeholderStyle={{
        color: KEY_GRAY,
        fontFamily: 'Lato-Bold',
      }}
      renderItem={({ label, value: user }) => {
        return (
          <HorizontalContainer
            style={{
              minHeight: 28,
              padding: 2,
            }}
          >
            {user ? (
              <Avatar
                size={28}
                rounded
                containerStyle={{
                  marginRight: 6,
                }}
                source={{
                  uri: user?.profile_image,
                }}
              />
            ) : null}
            <Text numberOfLines={2} style={styles.filterButtonText}>
              {label}
            </Text>
          </HorizontalContainer>
        );
      }}
      value={{
        label: value?.id ? value?.name || '...' : 'Any',
        value: value,
      }}
    />
  );
}
