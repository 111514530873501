import { Text, View } from 'react-native';
import { User } from '/generated/graphql';
import Avatar from '/components/Avatar';
import { shorten } from '/util';
import { KEY_GRAY } from '/constants';
import { FontAwesome5 } from '@expo/vector-icons';

interface IContentCreatorBadgeProps {
  user: Pick<Partial<User>, 'name' | 'profile_image'> | undefined;
}

export function SpeciesUserContentCreatorBadge(
  props: IContentCreatorBadgeProps,
) {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 4,
      }}
    >
      <Avatar
        source={{
          uri: props.user?.profile_image ?? '',
        }}
        size={18}
        rounded
      />
      <Text
        style={{
          fontFamily: 'Lato-Bold',
          color: KEY_GRAY,
          paddingLeft: 4,
          fontSize: 14,
        }}
      >
        Created by {shorten(props.user?.name ?? '', 48)}
      </Text>
    </View>
  );
}

export function SpeciesUserContentPublicBadge() {
  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <FontAwesome5 name="globe-africa" size={18} color="black" />
        <Text
          style={{
            fontFamily: 'Lato-Bold',
            color: 'black',
            fontSize: 15,
            paddingLeft: 4,
          }}
        >
          PUBLIC
        </Text>
      </View>
      <Text
        style={{
          fontFamily: 'Lato-Bold',
          color: 'gray',
          fontSize: 15,
          paddingRight: 8,
        }}
      >
        Other organizations can use this content on their profile.{`\n`}Content
        cannot be edited after it has been made public.
      </Text>
    </>
  );
}
