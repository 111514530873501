import { EvilIcons } from '@expo/vector-icons';
import React, { useState } from 'react';
import {
  ActivityIndicator,
  Image,
  Modal,
  Platform,
  SafeAreaView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import SpeciesSearch, { SpeciesSearchSpecies } from '../Search/SpeciesSearch';
import { KEY_GRAY, TEXT_INPUT, TOTAL_SPECIES } from '/constants';
import { formatMoney } from '/util';

export type ISpeciesPickerSpecies = SpeciesSearchSpecies;

interface ISpeciesPickerModalProps {
  visible: boolean;
  hideSuggestButton?: boolean;
  /** Called when user makes a selection or otherwise tries to exit the picker */
  onRequestClose: (species: ISpeciesPickerSpecies | undefined) => void;
}

export default function SpeciesPickerModal(
  props: React.PropsWithChildren<ISpeciesPickerModalProps>,
) {
  const [filterInput, setFilterInput] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const [appliedFilter, setAppliedFilter] = useState<string>();

  return (
    <Modal
      animationType="slide"
      // transparent={true}
      visible={props.visible ?? false}
      onRequestClose={() => {
        props.onRequestClose?.(undefined);
      }}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <SafeAreaView
        style={{
          width: '100%',
          height: '100%',
          maxWidth: 1000,
          alignSelf: 'center',
          paddingTop: 16,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            height: 48,
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              height: 48,
              width: '15%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TouchableOpacity
              style={{
                padding: 12,
              }}
              onPress={() => props.onRequestClose(undefined)}
            >
              <EvilIcons name="close" size={24} color="black" />
            </TouchableOpacity>
          </View>
          <TextInput
            autoCorrect={false}
            autoCapitalize="none"
            style={[
              TEXT_INPUT,
              {
                flex: 1,
                marginRight: 8,
                width: '60%',
                color: KEY_GRAY,
              },
            ]}
            placeholderTextColor={'gray'}
            placeholder={`Search ${formatMoney(TOTAL_SPECIES, 0)} species...`}
            onChangeText={(text) => setFilterInput(text)}
            enterKeyHint="search"
            onSubmitEditing={() => setAppliedFilter(filterInput)}
          />
          <TouchableOpacity
            style={{
              height: 30,
              paddingHorizontal: '5%',
              borderRadius: 10,
              backgroundColor: 'lightgrey',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: 10,
              // display: modalInfo.search ? 'flex' : 'none',
            }}
            onPress={() => setAppliedFilter(filterInput)}
          >
            {loading ? (
              <ActivityIndicator size={24} color="black" />
            ) : (
              <EvilIcons name="search" size={24} color="black" />
            )}
          </TouchableOpacity>
        </View>

        <View
          style={{
            flex: 1,
          }}
        >
          <SpeciesSearch
            useScrollView={Platform.OS === 'web'}
            query={appliedFilter || ''}
            hideSuggestButton={props.hideSuggestButton}
            onSelectSpecies={props.onRequestClose}
            onPressSuggestSpecies={() => props.onRequestClose(undefined)}
            onLoadStart={() => setLoading(true)}
            onLoadEnd={() => {
              setLoading(false);
            }}
            style={{
              marginBottom: 100,
            }}
          />
        </View>

        <View
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            height: 100,
            zIndex: 1,
            backgroundColor: 'white',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            paddingHorizontal: '5%',
          }}
        >
          <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Image
              source={require('/assets/images/IUCN_Red_List.png')}
              style={{ height: 50, resizeMode: 'contain', width: 100 }}
            />
            <Text style={{}}>v 2021-1</Text>
          </View>
          <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Image
              source={require('/assets/images/gbif.png')}
              style={{ height: 50, resizeMode: 'contain', width: 100 }}
            />
            <Text style={{}}>DOI 10.15468/39omei</Text>
          </View>
        </View>
      </SafeAreaView>
    </Modal>
  );
}
