import { StyleSheet } from 'react-native';
import { KEY_GRAY, TITLE_FONT_SIZE, DESKTOP_MAX_WIDTH } from '/constants';

export default StyleSheet.create({
  container: {
    ...DESKTOP_MAX_WIDTH,
    flex: 1,
    backgroundColor: 'white',
  },
  headerContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 8,
  },
  headerTitle: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: TITLE_FONT_SIZE,
  },
  headerSubtitle: {
    color: KEY_GRAY,
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 20,
    marginBottom: 4,
    paddingHorizontal: 10,
  },
  headerCloseButton: {
    padding: 12,
  },
  fieldLabel: {
    fontFamily: 'Lato-Bold',
    fontSize: 14,
    marginTop: 8,
    marginBottom: 4,
  },
  fieldContainer: {
    marginRight: 6,
  },
});
