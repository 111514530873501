import { FontAwesome, FontAwesome5, Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import dayjs from 'dayjs';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useEffect, useState } from 'react';
import {
  FlatList,
  ImageBackground,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { useCommonStyles } from '../styles';
import Lightening from '/assets/jsicons/bottomnavigation/Lightening';
import Sync from '/assets/jsicons/bottomnavigation/Sync';
import Avatar from '/components/Avatar';
import { KEY_GRAY, KEY_GREEN, SECTION_CONTAINER } from '/constants';
import { Campaign, UserProfileFragmentFragment } from '/generated/graphql';
import { DeepPartial } from '/types';
import getCDNImageUri from '/util/getCDNImageUri';

interface Props {
  data:
    | Pick<
        DeepPartial<UserProfileFragmentFragment>,
        'impacted_campaigns_current' | 'impacted_campaigns_past'
      >
    | undefined
    | null;
}

export default function MyImpact(props: Props) {
  const { styles: commonStyles } = useCommonStyles();

  const [currentTab, setCurrentTab] = useState<0 | 1>(0);

  const totalCurrent =
    props.data?.impacted_campaigns_current?.items?.length ?? 0;
  const totalPast = props.data?.impacted_campaigns_past?.items?.length ?? 0;

  const total = totalCurrent + totalPast;

  useEffect(() => {
    if (!totalCurrent && totalPast) {
      setCurrentTab(1);
    }

    if (!totalPast && totalCurrent) {
      setCurrentTab(0);
    }
  }, [totalCurrent, totalPast]);

  return total ? (
    <View style={[SECTION_CONTAINER, { paddingHorizontal: 0 }]}>
      <View
        style={{
          paddingHorizontal: 12,
        }}
      >
        <View
          style={[
            commonStyles('sectionTitleContainer'),
            {
              justifyContent: 'flex-start',
            },
          ]}
        >
          <Lightening width={28} height={28} />
          <View style={{ flex: 1, paddingLeft: 4 }}>
            <Text style={commonStyles('sectionTitle')}>MY IMPACT</Text>
          </View>
        </View>
        <Text style={styles.subtitleText}>
          See where I've made a difference
        </Text>
      </View>

      <View style={styles.tabBar}>
        {totalCurrent ? (
          <Pressable
            style={[
              styles.tab,
              {
                borderBottomColor: currentTab === 0 ? KEY_GREEN : 'transparent',
              },
            ]}
            onPress={() => {
              setCurrentTab(0);
            }}
          >
            <Text numberOfLines={1} style={styles.tabLabel}>
              CURRENT
            </Text>
          </Pressable>
        ) : null}
        {totalPast ? (
          <Pressable
            style={[
              styles.tab,
              {
                borderBottomColor: currentTab === 1 ? KEY_GREEN : 'transparent',
              },
            ]}
            onPress={() => {
              setCurrentTab(1);
            }}
          >
            <Text numberOfLines={1} style={styles.tabLabel}>
              PAST
            </Text>
          </Pressable>
        ) : null}
      </View>

      <FlatList
        key={currentTab}
        style={styles.flatList}
        ListEmptyComponent={
          <Text style={styles.emptyText}>Nothing to see here</Text>
        }
        horizontal
        data={
          currentTab === 0
            ? props.data?.impacted_campaigns_current?.items
            : props.data?.impacted_campaigns_past?.items
        }
        renderItem={({ item }) => {
          return <CampaignCard data={item} />;
        }}
        ListFooterComponent={() => {
          // TODO: Add "View all" button here that navigates to paginated screen
          return <></>;
        }}
      />
    </View>
  ) : null;
}

const styles = StyleSheet.create({
  subtitleText: {
    fontSize: 15,
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    marginTop: 4,
  },
  tabBar: {
    flexDirection: 'row',
    width: '100%',
    paddingTop: 16,
  },
  tab: {
    flex: 1,
    borderBottomWidth: 2,
    borderBottomColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
  },
  tabLabel: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 17,
  },
  flatList: {
    paddingHorizontal: 6,
    paddingTop: 10,
  },
  emptyText: {
    fontFamily: 'Lato',
    fontSize: 16,
    color: 'gray',
    alignSelf: 'center',
    textAlign: 'center',
    padding: 24,
  },
});

interface CampaignCardProps {
  data: DeepPartial<Campaign>;
}

function CampaignCard(props: CampaignCardProps) {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  return (
    <Pressable
      onPress={() => {
        navigate('Campaign', { campaignId: props.data.id });
      }}
      style={cardStyles.container}
    >
      <ImageBackground
        style={cardStyles.image}
        source={{
          uri:
            getCDNImageUri({
              uri: props.data.original_post?.thumbnail,
              isThumbnail: true,
              dimensions: {
                width: 280,
                height: 260,
              },
            }) ?? '',
        }}
      >
        <LinearGradient
          colors={['rgba(8, 8, 8, 0.55)', 'transparent']}
          start={{ x: 0.5, y: 0 }}
          end={{ x: 0.5, y: 1 }}
          style={cardStyles.linearGradient}
        >
          <Text numberOfLines={2} style={cardStyles.campaignName}>
            {props.data.name}
          </Text>
        </LinearGradient>
        {props.data.species?.[0] ? (
          <LinearGradient
            colors={['#0009', 'transparent']}
            start={{ x: 0.5, y: 1 }}
            end={{ x: 0.5, y: 0 }}
            style={[cardStyles.linearGradient, cardStyles.speciesContainer]}
          >
            <FontAwesome name="paw" size={24} color="white" />
            <Text style={cardStyles.speciesName}>
              {props.data.species[0].vernacularName ||
                props.data.species[0].species?.preferredVernacularName ||
                props.data.species[0].species?.canonicalName}
              {` `}
              {props.data.species[0].vernacularName ||
              props.data.species[0].species?.preferredVernacularName ? (
                <Text style={cardStyles.speciesScientificName}>
                  ({props.data.species[0].species?.canonicalName})
                </Text>
              ) : null}
            </Text>
          </LinearGradient>
        ) : null}
      </ImageBackground>
      <Pressable
        onPress={(e) => {
          if (!props.data.user?.id) {
            e.preventDefault();
            return;
          }

          navigate('Profile', { id: props.data.user.id });
        }}
        style={cardStyles.orgNameContainer}
      >
        <Avatar
          rounded
          size={42}
          source={{ uri: props.data.user?.profile_image ?? '' }}
        />
        <Text numberOfLines={2} style={cardStyles.orgName}>
          {props.data.user?.name}
        </Text>
      </Pressable>
      <View style={cardStyles.footer}>
        {props.data?.support_needed_by_date ? (
          <>
            <FontAwesome5 name="clock" size={18} color="black" />
            <Text
              style={{
                marginLeft: 5,
                marginRight: 8,
                fontSize: 14,
                fontFamily: 'Lato',
              }}
            >
              {dayjs(props.data?.support_needed_by_date as any).format(
                'MMM DD',
              )}
            </Text>
          </>
        ) : null}

        {/* Support request indicators */}
        {/* Skilled Impact */}
        {props.data.skilled_impact_requests?.length ? (
          <Sync width={20} height={20} />
        ) : null}
        {/* Volunteering */}
        {props.data.volunteer_request?.id ? (
          <Ionicons
            name="earth-sharp"
            size={20}
            color={'black'}
            style={{
              marginLeft: 8,
              marginRight: 8,
            }}
          />
        ) : null}
        {/* Funding */}
        {props.data.donation_request?.id ? (
          <FontAwesome5
            name="credit-card"
            size={20}
            color={'black'}
            style={{
              marginLeft: 8,
              marginRight: 8,
            }}
          />
        ) : null}
      </View>
    </Pressable>
  );
}

const cardStyles = StyleSheet.create({
  container: {
    marginRight: 8,
    borderRadius: 6,
    overflow: 'hidden',
    width: 280,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: KEY_GRAY,
  },
  linearGradient: {
    padding: 8,
    paddingBottom: 18,
  },
  campaignName: {
    fontFamily: 'Lato-Bold',
    fontSize: 18,
    color: 'white',
  },
  speciesName: {
    flex: 1,
    paddingHorizontal: 4,
    fontFamily: 'Lato-Bold',
    fontSize: 17,
    color: 'white',
    textTransform: 'capitalize',
  },
  speciesScientificName: {
    fontFamily: 'Lato-BoldItalic',
    fontSize: 17,
    color: 'white',
  },
  image: { height: 260, justifyContent: 'space-between' },
  speciesContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  orgNameContainer: {
    padding: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
  orgName: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 19,
    textTransform: 'uppercase',
    marginLeft: 6,
  },
  footer: {
    paddingTop: 2,
    padding: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
});
