import React from 'react';
import { DateTimePickerProps } from './DateTimePickerCore';
import { ActivityIndicator } from 'react-native';
import { KEY_GRAY } from '/constants';

const DateTimePickerComponent = React.lazy(
  () => import('./DateTimePickerCore'),
);

export default function DateTimePicker(props: DateTimePickerProps) {
  return (
    <React.Suspense fallback={<ActivityIndicator color={KEY_GRAY} />}>
      <DateTimePickerComponent {...props} />
    </React.Suspense>
  );
}
