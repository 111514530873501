export default {
  readMessage: (variables) => ({
    __typename: 'Message',
    id: variables.messageId,
    read: true,
  }),
  followResearchTopic: (variables) => ({
    __typename: 'ResearchTopic',
    topic: variables.topic,
    is_following: true,
  }),
  unfollowResearchTopic: (variables) => ({
    __typename: 'ResearchTopic',
    topic: variables.topic,
    is_following: false,
  }),
  sortApplicant: (variables) => ({
    __typename: 'SkillRequestApplication',
    id: variables.id,
    sort_category: variables.category,
  }),
  addBookmark: (variables) => ({
    __typename: 'Campaign',
    id: variables.campaignId,
    is_bookmarked: true,
  }),
  removeBookmark: (variables) => ({
    __typename: 'Campaign',
    id: variables.campaignId,
    is_bookmarked: false,
  }),
  updateGroupDiscussionCategory: (variables) => ({
    __typename: 'GroupDiscussionCategory',
    id: variables.input.id,
    order: variables.input.order,
    name: variables.input.name,
  }),
  createCreativeConnectProjectBookmark: (variables) => ({
    __typename: 'CreativeConnectProject',
    id: variables.projectId,
    is_bookmarked: true,
  }),
  deleteCreativeConnectProjectBookmark: (variables) => ({
    __typename: 'CreativeConnectProject',
    id: variables.projectId,
    is_bookmarked: false,
  }),
  updateTeamMember: (variables) => {
    return {
      __typename: 'TeamMember',
      userId: variables.userId,
      teamId: variables.teamId,
      team_role: variables.role,
      title: variables.title,
    };
  },
  // TODO: Add optimistic updates:
  // addConnection: {},
  // removeConnection: {},
  followUser: (variables) => {
    return {
      __typename: 'Follow',
      followingId: variables.userId,
    };
  },
  unfollowUser: (variables) => {
    return {
      __typename: 'Follow',
      followingId: variables.followingId,
    };
  },
  addLike: (variables) => ({
    __typename: 'PostInteractions',
    is_liked: true,
    id: variables.interactionsId,
  }),
  removeLike: (variables) => ({
    __typename: 'PostInteractions',
    is_liked: false,
    id: variables.interactionsId,
  }),
  createJobPostBookmark: (variables) => {
    return {
      __typename: 'JobPost',
      id: variables.id,
      is_bookmarked: true,
    };
  },
  deleteJobPostBookmark: (variables) => {
    return {
      __typename: 'JobPost',
      id: variables.id,
      is_bookmarked: false,
    };
  },
  createNewsPostBookmark: (variables) => {
    return {
      __typename: 'NewsPost',
      id: variables.id,
      is_bookmarked: true,
    };
  },
  deleteNewsPostBookmark: (variables) => {
    return {
      __typename: 'NewsPost',
      id: variables.id,
      is_bookmarked: false,
    };
  },
  blockUser: (variables) => {
    return {
      __typename: 'User',
      id: variables.userId,
      is_blocked: true,
    };
  },
  unblockUser: (variables) => {
    return {
      __typename: 'User',
      id: variables.userId,
      is_blocked: false,
    };
  },
} as {
  [key: string]: (variables: any) => any;
};
