import React, { forwardRef } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  ScrollView as RNScrollView,
  ScrollViewProps,
  StyleSheet,
} from 'react-native';
import Animated from 'react-native-reanimated';

const BaseScrollView = forwardRef(
  (
    { children, animated, ...props }: ScrollViewProps & { animated?: boolean },
    ref: React.ForwardedRef<RNScrollView | Animated.ScrollView>,
  ) => {
    const baseProps: Partial<ScrollViewProps> = {
      keyboardShouldPersistTaps: 'handled',
    };

    const scrollViewProps = Object.assign(baseProps, props);

    scrollViewProps.contentContainerStyle = [
      {
        paddingBottom: 10,
      },
      StyleSheet.flatten(scrollViewProps.contentContainerStyle),
    ];

    if (animated) {
      return (
        <Animated.ScrollView
          ref={ref as React.RefObject<Animated.ScrollView>}
          {...scrollViewProps}
        >
          {children}
        </Animated.ScrollView>
      );
    }

    return (
      <RNScrollView
        ref={ref as React.RefObject<RNScrollView>}
        {...scrollViewProps}
      >
        {children}
      </RNScrollView>
    );
  },
);

type ScrollComponent = React.ForwardRefExoticComponent<
  React.PropsWithChildren<ScrollViewProps> & React.RefAttributes<RNScrollView>
> &
  typeof RNScrollView;

const ScrollView = forwardRef(
  (
    { children, ...props }: ScrollViewProps,
    ref: React.ForwardedRef<RNScrollView>,
  ) => {
    return (
      <BaseScrollView ref={ref} {...props}>
        {children}
      </BaseScrollView>
    );
  },
) as ScrollComponent;

type AnimatedScrollComponent = React.ForwardRefExoticComponent<
  React.PropsWithChildren<ScrollViewProps> & React.RefAttributes<RNScrollView>
> &
  typeof Animated.ScrollView;

const AnimatedScrollView = forwardRef(
  (
    { children, ...props }: ScrollViewProps,
    ref: React.ForwardedRef<Animated.ScrollView>,
  ) => {
    return (
      <BaseScrollView ref={ref} animated {...props}>
        {children}
      </BaseScrollView>
    );
  },
) as AnimatedScrollComponent;

interface ScrollViewType extends RNScrollView {}
interface AnimatedScrollViewType extends Animated.ScrollView {}
export type { ScrollViewType, AnimatedScrollViewType };

export default ScrollView;
export { ScrollView, AnimatedScrollView };
