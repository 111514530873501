// export default {
//   ACCOUNTING: 'Accounting',
//   ACTING: 'Acting',
//   ADVERTISING: 'Advertising',
//   AGRICULTURE: 'Agriculture',
//   ANIMATION: 'Animation',
//   ARCHITECTURE: 'Architecture',
//   ARTIFICIAL_INTELLIGENCE: 'Artificial Intelligence',
//   AUTO: 'Auto',
//   APP_DEVELOPMENT: 'App Development',
//   ART: 'Art',
//   AVIATION: 'Aviation',
//   BOATING: 'Boating',
//   BUSINESS_DEVELOPMENT: 'Business Development',
//   COMMUNICATION: 'Communication',
//   COMMUNITY_LIAISON: 'Community Liaison',
//   CONSTRUCTION: 'Construction',
//   CRAFT: 'Craft',
//   CULINARY: 'Culinary',
//   DATA_ANALYSIS: 'Data Analysis',
//   DATABASE_MANAGEMENT: 'Database Management',
//   DIVING: 'Diving',
//   DRONE: 'Drone',
//   ELECTRICITY: 'Electricity',
//   ENGINEERING: 'Engineering',
//   ENTREPRENEURSHIP: 'Entrepreneurship',
//   FINANCE: 'Finance',
//   FUNDRAISING: 'Fundraising',
//   GAMING: 'Gaming',
//   GRAPHIC_DESIGN: 'Graphic Design',
//   HOSPITALITY: 'Hospitality',
//   HUMAN_RESOURCES: 'Human Resources',
//   INFORMATION_TECHNOLOGY: 'Information Technology',
//   LANDSCAPE: 'Landscape',
//   LEGAL: 'Legal',
//   MANAGEMENT: 'Management',
//   MARKETING: 'Marketing',
//   MEDICAL: 'Medical',
//   MUSICAL: 'Musical',
//   PHOTOGRAPHY: 'Photography',
//   PLUMBING: 'Plumbing',
//   PUBLIC_RELATIONS: 'Public Relations',
//   RENEWABLE_ENERGY: 'Renewable Energy ',
//   RESEARCH: 'Research',
//   SOCIAL_MEDIA: 'Social Media',
//   STRATEGY_CONSULTING: 'Strategy Consulting',
//   TAXI: 'Taxi',
//   TRANSLATION: 'Translation',
//   VETERINARY_SERVICES: 'Veterinary Services',
//   VIDEOGRAPHY: 'Videography',
//   WEB_DESIGN: 'Web Design',
//   WEB_DEVELOPMENT: 'Web Development',
//   WRITING: 'Writing',
// }
export default {
  ACCOUNTING: 'Accounting',
  ACTING: 'Acting',
  ADVERTISING: 'Advertising',
  AGRICULTURE: 'Agriculture',
  ANIMATION: 'Animation',
  ARCHAEOLOGY: 'Archaeology',
  ARCHITECTURE: 'Architecture',
  ARTIFICIAL_INTELLIGENCE: 'Artificial Intelligence',
  AUTO_REPAIR: 'Auto Repair',
  APP_DEVELOPMENT: 'App Development',
  ART: 'Art',
  AVIATION: 'Aviation',
  BOAT_HANDLING: 'Boat Handling',
  // BOTANY: 'Botany',
  BUSINESS_DEVELOPMENT: 'Business Development',
  BUSINESS_MANAGEMENT: 'Business Management',
  CAMERA_TRAP: 'Camera Trap',
  COMMERCIAL_PAINTING: 'Commercial Painting',
  COMMUNICATION: 'Communication',
  COMMUNITY_LIAISON: 'Community Liaison',
  CONSTRUCTION: 'Construction',
  COPYWRITING: 'Copywriting',
  // CRAFT: 'Craft',
  CULINARY: 'Culinary',
  DATA_ANALYSIS: 'Data Analysis',
  DATABASE_MANAGEMENT: 'Database Management',
  // DIVING: 'Diving',
  DRONE: 'Drone',
  EDUCATION: 'Education',
  ELECTRICAL: 'Electrical',
  ENGINEERING: 'Engineering',
  ENTREPRENEURSHIP: 'Entrepreneurship',
  EVENT_PLANNING: 'Event Planning',
  FINANCE: 'Finance',
  FUNDRAISING: 'Fundraising',
  GAME_DEVELOPMENT: 'Game Development',
  GRANT_WRITING: 'Grant Writing',
  GRAPHIC_DESIGN: 'Graphic Design',
  HOSPITALITY: 'Hospitality',
  HUMAN_RESOURCES: 'Human Resources',
  INFORMATION_TECHNOLOGY: 'Information Technology',
  INTERIOR_DESIGN: 'Interior Design',
  LANDSCAPING: 'Landscaping',
  LEGAL: 'Legal',
  LIDAR: 'Lidar',
  // MANAGEMENT: 'Management',
  MARKETING: 'Marketing',
  MEDICAL: 'Medical',
  MUSIC: 'Music',
  PHOTOGRAPHY: 'Photography',
  PLUMBING: 'Plumbing',
  PUBLIC_RELATIONS: 'Public Relations',
  RENEWABLE_ENERGY: 'Renewable Energy',
  RESEARCH: 'Research',
  REAL_ESTATE: 'Real Estate',
  RESOURCE_MANAGEMENT: 'Resource Management',
  ROCK_CLIMBING: 'Rock Climbing',
  SCUBA_DIVING: 'Scuba Diving',
  SENSORS: 'Sensors',
  SKIING: 'Skiing',
  SNORKELING: 'Snorkeling',
  SNOWBOARDING: 'Snowboarding',
  SOCIAL_MEDIA: 'Social Media',
  SOUND_ENGINEERING: 'Sound Engineering',
  SURFING: 'Surfing',
  STRATEGY_CONSULTING: 'Strategy Consulting',
  // TAXI: 'Taxi' ,
  TRANSLATION: 'Translation',
  TRANSPORTATION: 'Transportation',
  TRUCK_DRIVING: 'Truck Driving',
  UX_DESIGN: 'UX Design',
  VETERINARY_SERVICES: 'Veterinary Services',
  VIDEOGRAPHY: 'Videography',
  VOICE_OVER: 'Voice Over',
  WASTE_MANAGEMENT: 'Waste Management',
  WEB_DESIGN: 'Web Design',
  WEB_DEVELOPMENT: 'Web Development',
  WELDING: 'Welding',
  WRITING: 'Writing',
};
