import React, { useState } from 'react';
import { Text } from 'react-native';
import { shorten } from '/util';

interface TruncatableTextProps {
  text: string;
  maxLength?: number;
  style?: any;
}

export default function TruncatableText({
  text,
  maxLength = 360,
  style,
}: TruncatableTextProps) {
  const [readMore, setReadMore] = useState(false);

  const toggleReadMore = () => {
    setReadMore(!readMore);
  };

  return (
    <Text style={style}>
      {readMore ? text : shorten(text, maxLength)}
      {text.length > maxLength && (
        <Text onPress={toggleReadMore} style={{ color: '#929292' }}>
          {readMore ? ' Read Less' : ' Read More'}
        </Text>
      )}
    </Text>
  );
}
