import { UpdateResolver } from '@urql/exchange-graphcache';
import { ListMyCreativeConnectInvitesDocument } from '/generated/graphql';

const joinCreativeConnectCollaborator: UpdateResolver = (
  result,
  args,
  cache,
) => {
  const projectId = (args.input as any)?.projectId as string;
  const userId = (args.input as any)?.userId as string;

  const argumentSets: any[] = [{}, { userId }];

  argumentSets.forEach((_args) => {
    if (Object.keys(_args).length > 0)
      cache.link(
        {
          __typename: 'CreativeConnectProject',
          id: projectId,
        },
        'collaborator',
        _args,
        result.joinCreativeConnectCollaborator as any,
      );
    else
      cache.link(
        {
          __typename: 'CreativeConnectProject',
          id: projectId,
        },
        'collaborator',
        result.joinCreativeConnectCollaborator as any,
      );
  });

  cache.updateQuery(
    {
      query: ListMyCreativeConnectInvitesDocument,
      variables: { userId },
    },
    (data: any) => ({
      __typename: 'Query',
      ...data,
      listMyCreativeConnectInvites: {
        __typename: 'PaginatedCreativeConnectProjects',
        ...(data?.listMyCreativeConnectInvites
          ? data.listMyCreativeConnectInvites
          : {}),
        items:
          data?.listMyCreativeConnectInvites?.items.filter(
            (item: any) => item.id !== projectId,
          ) ?? [],
      },
    }),
  );

  cache.invalidate('Query', 'listMyCreativeConnectProjects', {
    userId,
  });
};

export default joinCreativeConnectCollaborator;
