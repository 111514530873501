import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';
import { SystemSurveyPlacement } from '/generated/graphql';

const createSurveySubmission: UpdateResolver = (result, args, cache) => {
  if ((args.input as any)?.surveyId) {
    cache.link(
      {
        __typename: 'Survey',
        id: (args.input as any).surveyId,
      },
      'submission',
      (result as any)?.createSurveySubmission,
    );
  }

  // If we just did our "second onboarding" or "onboarding survey", we should update our OrganizationApplication
  // in cache to reflect our newly granted access
  if (
    (args.input as any)?.placement ===
    SystemSurveyPlacement.OrganizationOnboardingApproval
  ) {
    // Get our OrganizationApplication's ID
    const query = gql`
      query MeQuery {
        me {
          id
          application {
            id
          }
        }
      }
    `;

    const data = cache.readQuery({ query }) as any;

    // Update application, setting completed_survey to `true`
    const fragment = gql`
      fragment CreateSurveySubmission on OrganizationApplication {
        id
        completed_survey
      }
    `;

    cache.writeFragment(fragment, {
      id: data.me.application.id,
      completed_survey: true,
    });
  }
};

export default createSurveySubmission;
