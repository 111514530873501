import { StyleSheet } from 'react-native';
import { KEY_GRAY } from '/constants';

export default StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: 'center',
    maxWidth: 640,
    width: '100%',
  },
  loadingContainer: {
    alignItems: 'center',
    alignSelf: 'center',
    padding: 12,
  },
  loadingText: {
    color: KEY_GRAY,
    fontSize: 15,
    fontFamily: 'Lato-Bold',
  },
});
