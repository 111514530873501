import { UpdateResolver } from '@urql/exchange-graphcache';

const inviteTeamMember: UpdateResolver = (result, args, cache) => {
  cache.link(
    {
      __typename: 'User',
      id: args.userId as string,
    },
    'team_membership',
    result.inviteTeamMember as any,
  );
};

export default inviteTeamMember;
