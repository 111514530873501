import { UpdateResolver } from '@urql/exchange-graphcache';
import { GetGroupDocument } from '/generated/graphql';

const deleteGroupMember: UpdateResolver = (result, args, cache) => {
  cache.invalidate({
    __typename: 'GroupMember',
    groupId: args.groupId,
    userId: args.userId,
  });

  cache.updateQuery(
    {
      query: GetGroupDocument,
      variables: {
        id: args.groupId,
      },
    },
    (data: any) => {
      if (data?.getGroup?.membership) {
        data.getGroup.membership = null;
      }
      return data;
    },
  );
};

export default deleteGroupMember;
