import { AntDesign } from '@expo/vector-icons';
import ContextMenu from '@mtourj/react-native-context-menu-view';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLinkTo } from '@react-navigation/native';
import {
  createStackNavigator,
  StackNavigationOptions,
  StackNavigationProp,
} from '@react-navigation/stack';
import React, { useEffect, useRef, useState } from 'react';
import { Pressable, useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createCommonCluster, getStackNavigatorOptions } from './common';
import Alert from '/Alert';
import PlusLightening from '/assets/jsicons/headerIcons/plusLightening';
import Logo from '/assets/jsicons/other/Logo';
import Button from '/components/Button';
import CreativeConnectCreateOrEditModal from '/components/CreativeConnect/CreativeConnectCreateOrEditModal/CreativeConnectCreateOrEditModal';
import {
  CREATIVE_CONNECT_BACKGROUND_GRAY,
  KEY_GRAY,
  KEY_YELLOW,
} from '/constants';
import { useModalContext } from '/context';
import { AuthContext, useAuthContext } from '/context/AuthProvider';
import { useTeamContext } from '/context/TeamProvider';
import {
  OrganizationApplicationStatus,
  TeamMemberRole,
  UserRole,
} from '/generated/graphql';
import FeedScreen from '/screens/FeedScreen';
import { isOrganizationProfileComplete } from '/util';

const ASYNC_STORAGE_LAST_DISMISSED_SUPPORT_KEY_PROMPT_KEY =
  'SUPPORT_KEY_BANNER_LAST_DISMISSED';

const KEY_DONATE_URL = `https://keyconservation.org/donate`;

const Stack = createStackNavigator();

export default function FeedStack() {
  const [lastDismissedDonateBanner, setLastDismissedDonateBanner] =
    useState<number>();

  const safeAreaInsets = useSafeAreaInsets();

  useEffect(() => {
    AsyncStorage.getItem(
      ASYNC_STORAGE_LAST_DISMISSED_SUPPORT_KEY_PROMPT_KEY,
    ).then((lastDismissed) => {
      if (lastDismissed && !isNaN(Number(lastDismissed))) {
        setLastDismissedDonateBanner(Number(lastDismissed));
      }
    });
  }, []);

  return (
    <Stack.Navigator
      initialRouteName="Home"
      screenOptions={{
        ...getStackNavigatorOptions(safeAreaInsets),
        title: 'Home',
      }}
    >
      <Stack.Screen
        component={FeedScreen}
        name="Home"
        options={FeedScreenOptions(lastDismissedDonateBanner)}
      />
      {createCommonCluster(Stack)}
    </Stack.Navigator>
  );
}

const FeedScreenOptions =
  (lastDismissedDonateBanner: number | undefined) =>
  ({ navigation }: any): StackNavigationOptions => {
    const SearchButton = () => {
      const linkTo = useLinkTo();

      return (
        <Pressable
          onPress={() => {
            linkTo('/discover/list');
          }}
          style={({ pressed }) => ({
            width: 50,
            height: 50,
            opacity: pressed ? 0.5 : 1,
            marginHorizontal: 8,
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          <AntDesign name="search1" size={28} color={KEY_GRAY} />
        </Pressable>
      );
    };

    const maxLastDismissed = Date.now() - 1000 * 3600 * 24 * 7; // 7 days in the past

    const shouldShowDonateButton =
      lastDismissedDonateBanner && lastDismissedDonateBanner > maxLastDismissed;

    return {
      headerTitleAlign: 'center',
      headerTitle: () => (
        <>
          <Logo color={KEY_GRAY} width={48} height={48} />
        </>
      ),
      headerLeft: () => <SearchButton />,
      headerRight: () => (
        <AuthContext.Consumer>
          {({ userData }) => {
            if (userData?.id && !userData.is_deactivated) {
              return <CreateCampaignButton navigation={navigation} />;
            } else if (shouldShowDonateButton) return <DonateButton />;
            else return null;
          }}
        </AuthContext.Consumer>
      ),
    };
  };

type CreateCampaignButtonProps = {
  navigation: StackNavigationProp<any>;
};

function CreateCampaignButton({ navigation }: CreateCampaignButtonProps) {
  const { spawnModal, closeModal } = useModalContext();
  const { userData } = useAuthContext();
  const { teams } = useTeamContext();

  const creativeConnectModalRef = useRef<string>();
  function onCreateCreativeConnect() {
    creativeConnectModalRef.current = spawnModal({
      title: 'CREATIVE CONNECT',
      style: {
        maxWidth: 480,
        backgroundColor: CREATIVE_CONNECT_BACKGROUND_GRAY,
      },
      disableEntireHeader: true,
      component: (
        <CreativeConnectCreateOrEditModal
          onRequestClose={() => {
            if (creativeConnectModalRef.current)
              closeModal(creativeConnectModalRef.current);
          }}
        />
      ),
    });
  }

  const isConservationist = userData?.role === UserRole.Conservationist;
  const organizationApproved =
    userData?.application?.status === OrganizationApplicationStatus.Approved;
  const organizationSurveyCompleted =
    userData?.application?.completed_survey === true;

  const isAuthorizedTeamMember = teams.some((team: any) => {
    const role = team.membership?.team_role;

    /** If the organization behind this Team has an incomplete profile,
     * then nobody can create campaigns for it */
    if (!isOrganizationProfileComplete(team.user)) return false;

    return role === TeamMemberRole.Admin || role === TeamMemberRole.Creator;
  });

  let disabled = false;
  let disabledReason = '';

  if (userData?.is_deactivated) {
    disabled = true;
    disabledReason =
      'You cannot create campaigns because your account is deactivated.';
  } else if (isConservationist && !organizationApproved) {
    disabled = true;
    disabledReason =
      'You cannot create campaigns because your organizaton has not yet been approved.';
  } else if (isConservationist && !organizationSurveyCompleted) {
    disabled = true;
    disabledReason =
      'You cannot create campaigns because you have not yet finished setting up your account.';
  } else if (isConservationist && !isOrganizationProfileComplete(userData)) {
    disabled = true;
    disabledReason =
      'You cannot create campaigns because you must complete your profile first.';
  }

  const Wrapper = disabled
    ? (_props: any) => (
        <Pressable
          onPress={() => {
            Alert.alert('Access Restricted', disabledReason);
          }}
        >
          {_props.children}
        </Pressable>
      )
    : React.Fragment;

  type LightningBoltMenuOption =
    | 'Campaign'
    | 'Campaign Update'
    | 'Moment In Nature'
    | 'Job Post'
    | 'News'
    | 'Group'
    | 'Creative Connect';
  type LightningBoltMenuAction = {
    title: LightningBoltMenuOption;
    subtitletitle: string;
    systemIcon: string;
  };

  /** Actions only available to orgs and their team members */
  const conservationistActions: LightningBoltMenuAction[] = [
    {
      title: 'Campaign',
      subtitletitle: 'Create a new campaign',
      systemIcon: 'plus.circle',
    },
    {
      title: 'Campaign Update',
      subtitletitle: 'Create update post for existing campaign',
      systemIcon: 'note.text.badge.plus',
    },
    {
      title: 'Moment In Nature',
      subtitletitle: 'Share a short video',
      systemIcon: 'video.badge.plus',
    },
    {
      title: 'Job Post',
      subtitletitle: 'Create a job post',
      systemIcon: 'person.crop.rectangle.badge.plus',
    },
    {
      title: 'News',
      subtitletitle: 'Share a news article',
      systemIcon: 'newspaper',
    },
  ];

  /** Actions accessible to both roles */
  const universalActions: LightningBoltMenuAction[] = [
    {
      title: 'Group',
      subtitletitle: 'Create a new group',
      systemIcon: 'person.2.circle',
    },
    {
      title: 'Creative Connect',
      subtitletitle: 'Create a new project',
      systemIcon: 'shared.with.you.circle',
    },
  ];

  const actions: LightningBoltMenuAction[] =
    isConservationist || isAuthorizedTeamMember
      ? [...conservationistActions, ...universalActions]
      : universalActions;

  return (
    <Wrapper>
      <ContextMenu
        style={{
          marginHorizontal: 8,
          width: 50,
          height: 50,
          justifyContent: 'center',
          alignItems: 'center',
          opacity: disabled ? 0.5 : 1,
        }}
        disabled={disabled}
        dropdownMenuMode
        actions={actions}
        onPress={(e) => {
          if (disabled) {
            Alert.alert('Access Restricted', disabledReason);
            return;
          }

          const title = actions[e.nativeEvent.index]
            .title as LightningBoltMenuOption;

          switch (title) {
            case 'Campaign': {
              navigation.navigate('CreateCampaign');
              break;
            }
            case 'Campaign Update': {
              navigation.navigate('CreateCampaignUpdate');
              break;
            }
            case 'Moment In Nature': {
              navigation.navigate('CreateMomentInNature');
              break;
            }
            case 'Job Post': {
              navigation.navigate('CreateJobPost');
              break;
            }
            case 'News': {
              navigation.navigate('CreateNewsPost');
              break;
            }
            case 'Group': {
              navigation.navigate('CreateGroup');
              break;
            }
            case 'Creative Connect': {
              onCreateCreativeConnect();
              break;
            }
          }
        }}
      >
        <PlusLightening fill={KEY_GRAY} />
      </ContextMenu>
    </Wrapper>
  );
}

function DonateButton() {
  const { width: windowWidth } = useWindowDimensions();

  let fontSize = 16;
  let padding = 16;

  if (windowWidth < 400) {
    fontSize = 14;
    padding = 8;
  }

  return (
    <Button
      label="SUPPORT KEY"
      style={{
        backgroundColor: KEY_YELLOW,
        padding,
        paddingVertical: 8,
      }}
      labelStyle={{
        fontSize,
      }}
      containerStyle={{
        marginHorizontal: 8,
      }}
      onPress={() => {
        // @ts-ignore - target argument is untyped
        Linking.openURL(KEY_DONATE_URL, '__blank');
      }}
    />
  );
}
