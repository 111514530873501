import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  accordion: {
    // paddingVertical: 2,
  },
  accordionTitle: {
    fontSize: 17,
    fontFamily: 'Lato-Bold',
  },
  accordionSubtitle: {
    fontFamily: 'Lato-Italic',
    fontSize: 17,
    marginLeft: 8,
  },
  accordionContentContainer: {
    padding: 4,
    paddingVertical: 12,
  },
  accordionInnerContentContainer: {
    padding: 0,
    overflow: 'hidden',
  },
  activityIndicator: {
    width: 24,
    height: 24,
  },
});
