import React, { useCallback } from 'react';
import Alert from '/Alert';

type AsyncJobTask = {
  job: () => Promise<any>;
};

type AsyncJobInfo = {
  /** The number of tasks in the job. */
  totalTasks: number;
};

type AsyncJob = {
  /** The tasks to run in the job. */
  tasks: AsyncJobTask[];
  /** The loading text to display for the job. */
  loadingText?: (info: AsyncJobInfo) => string;
  successText?: (info: AsyncJobInfo) => string;
  failedText?: (info: AsyncJobInfo) => string;
};

type AsyncJobContext = {
  runJob: (job: AsyncJob) => Promise<any>;
};

const ctx = React.createContext({} as AsyncJobContext);

export const useAsyncJobContext = () => React.useContext(ctx);

/**
 * The AsyncJobProvider provides a way to run tasks independently of the component tree.
 * In-progress jobs are displayed as a toast (using AlertProvider) with customizable loading text.
 */
export default function AsyncJobProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const runJob = useCallback(async (job: AsyncJob) => {
    const chainedPromises = job.tasks.reduce(async (promise, task) => {
      await promise;
      return task.job();
    }, Promise.resolve());

    const jobInfo = { totalTasks: job.tasks.length };
    const jobMessage = job.loadingText
      ? job.loadingText(jobInfo)
      : `Running ${jobInfo.totalTasks} tasks...`;

    Alert.notify({
      message: jobMessage,
      awaitPromise: {
        promise: chainedPromises,
        successMessage: job.successText
          ? job.successText(jobInfo)
          : 'Job completed successfully.',
        errorMessage: job.failedText ? job.failedText(jobInfo) : 'Job failed.',
      },
    });
  }, []);

  return <ctx.Provider value={{ runJob }}>{children}</ctx.Provider>;
}
