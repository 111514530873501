import React from 'react';
import { Text, TextInput, useWindowDimensions, View } from 'react-native';
import commonStyles from '../common.style';
import styles from './ResetPasswordForm.style';

interface IResetPasswordFormProps {
  email: string;
  setEmail: (email: string) => void;
}

export default function ResetPasswordForm(props: IResetPasswordFormProps) {
  const { width } = useWindowDimensions();

  return (
    <>
      <View>
        <View
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            borderRadius: width / 2,
            width: width * 0.9,
            height: width * 0.9,
            maxWidth: 480,
            maxHeight: 480,
            justifyContent: 'center',
            alignItems: 'center',
            paddingVertical: 24,
            paddingHorizontal: 48,
          }}
        >
          <Text style={commonStyles.headingText}>RESET YOUR PASSWORD</Text>
          <Text style={commonStyles.paragraph}>
            Enter your email below and we will send you a code to reset your
            password. If you cannot remember your email, please contact us via
            our website and we will help locate your account.
          </Text>
        </View>
      </View>
      <View style={styles.inputContainer}>
        <View style={styles.inputField}>
          <TextInput
            placeholder="EMAIL@EXAMPLE.COM"
            placeholderTextColor="rgba(0,0,0, 0.4)"
            enterKeyHint="next"
            onSubmitEditing={() => {}}
            style={styles.input}
            keyboardType="email-address"
            autoCapitalize="none"
            autoCorrect={false}
            value={props?.email}
            onChangeText={(text) => props?.setEmail(text.trim())}
          />
        </View>
      </View>
    </>
  );
}
