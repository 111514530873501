import React from 'react';
import { StyleSheet, Text, View, ViewStyle } from 'react-native';
import { formatMoney } from '../../util';
import { KEY_GREEN } from '/constants';

function formatMoneyText(
  amount: number,
  currencySymbol = '$',
  decimalCount = 0,
) {
  return currencySymbol + (formatMoney(amount, decimalCount) || 0);
}

function formatPercentageText(percentage: number | string) {
  return percentage + '%';
}

export enum ProgressBarType {
  Money,
  Percentage,
}

type ProgressBarProps = {
  progress: number;

  goal: number;
  currencySymbol?: string;
  style?: ViewStyle;
  /** Height of progress bar. Default is 17 */
  height?: number;
  progressType?: ProgressBarType;
  decimalCount?: number;
};

const ProgressBar = (props: ProgressBarProps) => {
  const progressPercent = (props.progress || 0) / props.goal;

  const formatText =
    props.progressType === ProgressBarType.Percentage
      ? formatPercentageText
      : (amount: number) =>
          formatMoneyText(amount, props.currencySymbol, props.decimalCount);

  return (
    <View
      style={[
        {
          // ...styles.request,
          width: '100%',
          height: props.height ?? 19,
          marginVertical: 2,
          borderRadius: 6,
          overflow: 'hidden',
          backgroundColor: `rgba(3, 255, 157, 0.3)`,
        },
        props.style,
      ]}
    >
      <View
        style={{
          height: '100%',
          justifyContent: 'center',
          position: 'absolute',
          left: 8,
          zIndex: 5,
        }}
      >
        <Text style={styles.progressBarText}>{formatText(props.progress)}</Text>
      </View>
      <View
        style={{
          flex: 1,
          // textAlign: 'left',
          overflow: 'visible',
          backgroundColor: KEY_GREEN,
          width: `${Math.min(progressPercent, 1) * 100}%`,
        }}
      >
        {/* <View
          style={{
            height: '100%',
            justifyContent: 'center',
            position: 'absolute',
            right: 8,
          }}
        >
          <Text
            style={
              progressPercent >= 0.2
                ? styles.progressBarText
                : { display: 'none' }
            }
          >
            {formatText(props.progress)}
          </Text>
        </View> */}
      </View>
      <View
        style={{
          height: '100%',
          justifyContent: 'center',
          position: 'absolute',
          right: 8,
        }}
      >
        <Text
          style={{
            zIndex: 5,
            opacity: 0.5,
            display: progressPercent >= 0.8 ? 'none' : 'flex',
          }}
        >
          {formatText(props.goal)}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  request: {
    width: '100%',
    height: 17,
    marginVertical: 2,
    borderRadius: 4,
  },
  requestFill: {
    flex: 1,
    textAlign: 'left',
    overflow: 'visible',
  },
  progressBarRight: {
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    right: 8,
  },
  progressBarHide: {
    display: 'none',
  },
  progressBarLeft: {
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    left: 8,
    zIndex: 5,
  },
  progressBarText: {
    fontFamily: 'Lato-Bold',
  },
  donationGoal: {
    zIndex: 5,
    opacity: 0.5,
  },
});

export default ProgressBar;
