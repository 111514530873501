import React from 'react';
import { View, Text } from 'react-native';
import styles from './MomentInNatureCarousel.style';
import MomentInNatureCarouselBase from '../MomentInNatureCarouselBase/MomentInNatureCarouselBase';
import MomentInNatureCarouselSkeleton from './MomentInNatureCarouselSkeleton';
import MomentInNatureIcon from '/assets/jsicons/MomentInNatureIcon';
import { SECTION_CONTAINER } from '/constants';
import {
  MomentInNatureFeedType,
  useGetMomentInNatureFeedQuery,
} from '/generated/graphql';

interface Props {
  feedType: MomentInNatureFeedType;
}

export default function MomentInNatureCarousel(props: Props) {
  const [{ data, fetching, error }] = useGetMomentInNatureFeedQuery({
    variables: {
      type: props.feedType,
    },
  });

  return (fetching || data?.getMomentInNatureFeed.items.length) && !error ? (
    <View style={SECTION_CONTAINER}>
      <View style={styles.titleContainer}>
        <MomentInNatureIcon
          width={24}
          height={24}
          color="black"
          style={{
            marginRight: 6,
            paddingBottom: 2,
          }}
        />
        <Text style={styles.titleText}>MOMENT IN NATURE</Text>
      </View>
      <Text style={styles.subtitleText}>
        Take a moment to be transported into nature
      </Text>

      {fetching && !data?.getMomentInNatureFeed.items.length ? (
        <MomentInNatureCarouselSkeleton />
      ) : (
        <MomentInNatureCarouselBase
          items={data?.getMomentInNatureFeed.items || []}
          hasMore={!!data?.getMomentInNatureFeed.nextToken}
          loading={fetching}
          onLoadMore={() => {
            // Implement load more functionality here
          }}
        />
      )}
    </View>
  ) : null;
}
