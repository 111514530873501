import { Platform } from 'react-native';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  maxSize,
} from 'rn-responsive-styles';
import { SECTION_CONTAINER } from '/constants';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      justifyContent: 'center',
      padding: 12,
    },
    contentContainer: {
      ...SECTION_CONTAINER,
      flex: 1,
      width: '100%',
      height: '100%',
      minWidth: 310,
      minHeight: 420,
      maxWidth: 1200,
      maxHeight: 720,
      alignSelf: 'center',
    },
    headerContainer: {
      flexDirection: 'row',
      padding: 16,
      paddingTop: Platform.OS === 'web' ? 24 : 0,
    },
    closeButtonContainer: {
      zIndex: 3,
      padding: 8,
      alignSelf: 'flex-start',
    },
    header: {
      width: '100%',
      flexDirection: 'row',
      padding: 10,
      alignItems: 'center',
    },
    keyIcon: {
      padding: 12,
    },
    headerTitleContainer: {
      flex: 1,
      paddingVertical: 12,
    },
    title: {
      fontFamily: 'LeagueSpartan-Bold',
      color: 'black',
      fontSize: 33,
    },
    text: {
      fontFamily: 'LeagueSpartan',
      color: 'black',
      fontSize: 27,
    },
    listContainer: {
      flex: 1,
    },
    animalCaptionContainer: {
      flex: 1,
      paddingHorizontal: 12,
    },
    animalName: {
      fontFamily: 'LeagueSpartan-Bold',
      fontSize: 24,
      textTransform: 'uppercase',
    },
    animalPhotoCredit: {
      fontFamily: 'LeagueSpartan-Bold',
      fontSize: 18,
    },
  },
  {
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      headerContainer: {
        flexDirection: 'column',
        paddingHorizontal: 0,
      },
      header: {
        paddingHorizontal: 4,
      },
      title: {
        fontSize: 27,
      },
      text: {
        fontSize: 23,
      },
      keyIcon: {
        padding: 8,
      },
    },
  },
);

export default useStyles;
