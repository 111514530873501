import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useCommonStyles } from '../styles';
import Button from '/components/Button';
import ResearchTopicButton from '/components/ResearchTopicButton';
import { SECTION_CONTAINER } from '/constants';
import { User } from '/generated/graphql';

interface IUserSlice {
  followed_topics: {
    items: Pick<
      User['followed_topics']['items'][0],
      'topic' | 'is_following'
    >[];
  };
}

interface Props {
  data: IUserSlice | undefined | null;
}

export default function FollowedTopics(props: Props) {
  const { styles: commonStyles } = useCommonStyles();

  const [viewLimit, setViewLimit] = useState(30);

  return props.data?.followed_topics.items.length ? (
    <View style={SECTION_CONTAINER}>
      <View style={commonStyles('sectionTitleContainer')}>
        <Text style={commonStyles('sectionTitle')}>TOPICS I'M FOLLOWING</Text>
      </View>

      <View style={styles.topicsContainer}>
        {props.data?.followed_topics.items.slice(0, viewLimit).map((topic) => (
          <ResearchTopicButton key={topic.topic} topic={topic} />
        ))}
      </View>

      {props.data?.followed_topics.items.length > viewLimit && (
        <Button
          containerStyle={styles.viewMoreButton}
          label="View More..."
          onPress={() => {
            setViewLimit((prevState) => prevState + 30);
          }}
        />
      )}
    </View>
  ) : null;
}

const styles = StyleSheet.create({
  topicsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingVertical: 6,
  },
  viewMoreButton: {
    alignSelf: 'center',
    marginTop: 8,
  },
});
