import { useContext, useEffect } from 'react';
import { MetaTagContext } from '/context/MetaTagProvider';

type Props = {
  title?: string;
  thumbnail?: string;
  description?: string;
};

export default function MetaTags(props: Props) {
  const { setPageTitle, setPageThumbnail, setPageDescription } =
    useContext(MetaTagContext);

  useEffect(() => {
    if (props.title) {
      setPageTitle(props.title);
    } else {
      setPageTitle('');
    }

    return () => {
      setPageTitle('');
    };
  }, [props.title, setPageTitle]);

  useEffect(() => {
    if (props.thumbnail) {
      setPageThumbnail(props.thumbnail);
    } else {
      setPageThumbnail('');
    }

    return () => {
      setPageThumbnail('');
    };
  }, [props.thumbnail, setPageThumbnail]);

  useEffect(() => {
    if (props.description) {
      setPageDescription(props.description);
    } else {
      setPageDescription('');
    }
  }, [props.description, setPageDescription]);

  return null;
}
