import React from 'react';
import { AppInfoResponse, useAppInfoQuery } from '/generated/graphql';

const Context = React.createContext({} as AppInfoResponse | undefined);

export const useAppInfo = () => React.useContext(Context);

export default function AppInfoProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const [{ data }] = useAppInfoQuery();

  return <Context.Provider value={data?.appInfo}>{children}</Context.Provider>;
}
