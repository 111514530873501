import {
  AntDesign,
  EvilIcons,
  FontAwesome,
  FontAwesome5,
} from '@expo/vector-icons';
import React, { useMemo, useRef, useState } from 'react';
import {
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextInput,
  View,
  ViewStyle,
} from 'react-native';
import Avatar from '../Avatar';
import Button from '../Button';
import HorizontalContainer from '../common/Generic/HorizontalContainer';
import ScrollView from '../common/ScrollView/ScrollView';
import ResearchTopicFilterButton from '../FilterButtons/ResearchTopicFilterButton/ResearchTopicFilterButton';
import SpeciesFilterButton from '../FilterButtons/SpeciesFilterButton/SpeciesFilterButton';
import UserRoleFilterButton from '../FilterButtons/UserRoleFilterButton/UserRoleFilterButton';
import UserSearch from '../Search/UserSearch';
import { ISpeciesPickerSpecies } from '../SpeciesPicker/SpeciesPickerModal';
import {
  KEY_GRAY,
  KEY_GREEN,
  PRIMARY_BUTTON_BACKGROUND,
  TEXT_INPUT,
} from '/constants';
import { useModalContext } from '/context';
import { User, UserRole } from '/generated/graphql';
import useDebouncedState from '/hooks/useDebouncedState';

const MAX_USERS_PER_PAGE = 5;

export interface IUserPickerUser
  extends Pick<User, 'id' | 'name' | 'profile_image'> {}

type Props = {
  style?: StyleProp<ViewStyle>;
  darkTheme?: boolean;
  buttonStyle?: StyleProp<ViewStyle>;
  /** Override the default button label */
  buttonLabel?: React.ReactNode;
  role?: UserRole;
  users: IUserPickerUser[];
  addButtonText?: string;
  showFilters?: boolean;
  searchModalTitle?: string;
  /** IDs of users to exclude from search results */
  excludeUserIds?: string[];
  onChange: (users: IUserPickerUser[]) => void;
};

export default function UserPicker({ onChange, ...props }: Props) {
  const { spawnModal, closeModal } = useModalContext();

  const [page, setPage] = useState(0);

  const addUserModalIdRef = useRef<string | undefined>();
  function onAddUser() {
    addUserModalIdRef.current = spawnModal({
      title: props.searchModalTitle ?? 'Select User',
      component: (
        <UserSearchModal
          role={props.role}
          excludeUserIds={Array.from(
            new Set([
              ...props.users.map((user) => user.id),
              ...(props.excludeUserIds ?? []),
            ]),
          )}
          showFilters={props.showFilters}
          onClose={(data) => {
            if (data) {
              onChange([...props.users, data]);
            }

            closeModal(addUserModalIdRef.current ?? '');
          }}
        />
      ),
    });
  }

  const renderedUsers = useMemo(() => {
    return props.users
      ?.slice(page * MAX_USERS_PER_PAGE, (page + 1) * MAX_USERS_PER_PAGE)
      .map((organization) => {
        return (
          <HorizontalContainer
            style={{
              marginTop: 6,
            }}
            key={organization.id}
          >
            <Avatar
              rounded
              size={48}
              containerStyle={{
                marginRight: 8,
              }}
              source={{ uri: organization.profile_image }}
            />

            <Text
              style={[
                styles.userName,
                props.darkTheme ? { color: 'white' } : undefined,
              ]}
              numberOfLines={2}
            >
              {organization.name}
            </Text>

            <Button
              onPress={() => {
                onChange(
                  props.users.filter((user) => user.id !== organization.id),
                );
              }}
              label={<FontAwesome name="trash-o" size={24} color="black" />}
            />
          </HorizontalContainer>
        );
      });
  }, [props.users, props.darkTheme, page, onChange]);

  const userTypeText =
    props.role === UserRole.Supporter
      ? 'Supporter'
      : props.role === UserRole.Conservationist
      ? 'Organization'
      : 'User';

  const numPages = Math.ceil((props.users?.length ?? 0) / MAX_USERS_PER_PAGE);

  return (
    <View style={props.style}>
      <Pressable
        style={[styles.addButton, props.buttonStyle]}
        onPress={onAddUser}
      >
        {props.buttonLabel ?? (
          <HorizontalContainer>
            <View
              style={{
                marginRight: 6,
              }}
            >
              <FontAwesome name="user" size={24} color={'black'} />
              <FontAwesome
                style={{
                  position: 'absolute',
                  right: -4,
                  bottom: -4,
                }}
                name="plus"
                size={16}
                color={KEY_GREEN}
              />
            </View>
            <Text style={styles.addButtonText}>
              {props.addButtonText ?? `Add ${userTypeText}`}
            </Text>
          </HorizontalContainer>
        )}
      </Pressable>

      {(props?.users?.length ?? 0) > MAX_USERS_PER_PAGE && (
        <View style={styles.paginationContainer}>
          <Pressable
            onPress={() => setPage(page - 1)}
            disabled={page === 0}
            style={[
              styles.paginationButton,
              {
                opacity: page === 0 ? 0.5 : 1,
              },
            ]}
          >
            <AntDesign
              name="caretleft"
              size={22}
              color={props.darkTheme ? 'white' : 'black'}
            />
          </Pressable>

          <Text
            style={[
              styles.paginationText,
              props.darkTheme ? { color: 'lightgray' } : undefined,
            ]}
          >
            {page + 1} of {numPages}
          </Text>
          <Pressable
            testID="campaign-picker-next-button"
            onPress={() => setPage(page + 1)}
            disabled={page === numPages - 1}
            style={[
              styles.paginationButton,
              {
                opacity: page === numPages - 1 ? 0.5 : 1,
              },
            ]}
          >
            <AntDesign
              name="caretright"
              size={22}
              color={props.darkTheme ? 'white' : 'black'}
            />
          </Pressable>
        </View>
      )}

      {renderedUsers}
    </View>
  );
}

const UserSearchModal = ({
  onClose,
  ...props
}: {
  onClose: (data: Pick<User, 'id' | 'name' | 'profile_image'>) => void;
  role?: UserRole;
  excludeUserIds: string[];
  showFilters?: boolean;
}) => {
  const [role, setRole] = useState<UserRole | undefined>(props.role);
  const [species, setSpecies] = useState<ISpeciesPickerSpecies[]>([]);
  const [topics, setTopics] = useState<string[]>([]);

  const [query, queryDebounced, setQuery] = useDebouncedState('');

  const userTypeText =
    role === UserRole.Supporter
      ? 'supporters'
      : UserRole.Conservationist
      ? 'organizations'
      : 'users';

  return (
    <>
      {props.showFilters ? (
        <HorizontalContainer
          style={{
            marginBottom: 2,
          }}
        >
          <FontAwesome5
            name="filter"
            size={16}
            color="gray"
            style={{
              marginRight: 2,
            }}
          />
          <Text
            style={{
              fontFamily: 'LeagueSpartan-Bold',
              fontSize: 16,
              color: 'gray',
            }}
          >
            FILTER
          </Text>
        </HorizontalContainer>
      ) : null}
      <HorizontalContainer>
        <TextInput
          autoCorrect={false}
          autoCapitalize="none"
          style={[TEXT_INPUT, { flex: 1 }]}
          placeholderTextColor={'gray'}
          placeholder={`Search all ${userTypeText}...`}
          onChangeText={setQuery}
          enterKeyHint="search"
        />
        <Pressable
          style={{
            width: 42,
            height: 32,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <EvilIcons
            style={{
              marginHorizontal: 8,
            }}
            name="search"
            size={28}
            color="black"
          />
        </Pressable>
      </HorizontalContainer>
      {props.showFilters ? (
        <ScrollView
          horizontal
          contentContainerStyle={{
            paddingBottom: 4,
          }}
          showsHorizontalScrollIndicator={Platform.OS === 'web'}
          style={{
            overflow: 'visible',
            flexGrow: 0,
            flexShrink: 0,
            backgroundColor: 'white',
          }}
        >
          {props.role ? null : (
            <View style={styles.fieldContainer}>
              <Text style={styles.fieldLabel}>USER TYPE</Text>
              <UserRoleFilterButton value={role} onChange={setRole} />
            </View>
          )}
          <View style={styles.fieldContainer}>
            <Text style={styles.fieldLabel}>SPECIES</Text>
            <SpeciesFilterButton species={species} onChange={setSpecies} />
          </View>
          <View style={styles.fieldContainer}>
            <Text style={styles.fieldLabel}>TOPICS</Text>
            <ResearchTopicFilterButton topics={topics} onChange={setTopics} />
          </View>
        </ScrollView>
      ) : null}
      <UserSearch
        query={queryDebounced}
        key={JSON.stringify({
          query,
          role,
          topics,
          species: species.map((s) => s.acceptedNameUsageID),
        })}
        style={
          Platform.OS === 'web' ? undefined : { flex: undefined, flexGrow: 0 }
        }
        filter={{
          role,
          topics,
          speciesTaxonIds: species.map((s) => s.acceptedNameUsageID),
        }}
        excludeUsers={props.excludeUserIds}
        emptyPlaceholderText={`Begin typing to search for ${userTypeText}`}
        onSelectUser={(data) => {
          onClose(data);
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  addButton: {
    backgroundColor: PRIMARY_BUTTON_BACKGROUND,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    paddingVertical: 12,
    marginVertical: 4,
  },
  addButtonText: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    fontSize: 16,
  },
  paginationContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: 240,
    alignSelf: 'center',
    marginVertical: 8,
  },
  paginationText: {
    flex: 1,
    textAlign: 'center',
    fontFamily: 'Lato-Bold',
    color: 'gray',
    fontSize: 15,
  },
  paginationButton: {
    padding: 10,
  },
  userName: {
    flex: 1,
    fontFamily: 'Lato-Bold',
    fontSize: 15,
  },
  fieldLabel: {
    fontFamily: 'Lato-Bold',
    fontSize: 14,
    marginTop: 2,
    marginBottom: 4,
  },
  fieldContainer: {
    marginRight: 6,
  },
});
