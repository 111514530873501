import {
  View,
  Text,
  ViewStyle,
  NativeSyntheticEvent,
  ImageLoadEventData,
  Platform,
  Pressable,
  Image as RNImage,
} from 'react-native';
import React, { useState } from 'react';
import LoadingOverlay from '/components/LoadingOverlay';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import { ResizeMode, VideoReadyForDisplayEvent } from 'expo-av';
import { determineIfVideo } from '/util';
import VideoPlayer from '/components/VideoPlayer';
import { useIsFocused } from '@react-navigation/native';
import getCDNImageUri from '/util/getCDNImageUri';
import styles from './MediaItem.style';

type Props = {
  uri: string;
  resizeMode?: ResizeMode;
  thumbnailUri?: string;
  useOriginalSource: boolean;
  targetCDNMediaWidth: number;
  targetCDNMediaHeight: number;
  shouldPlayVideo: boolean;
  hideErrorRetryButton?: boolean;
  onNaturalMediaDimensions?: (width: number, height: number) => void;
  onPress?: () => void;
  style: ViewStyle;
};

export default function MediaItem(props: Props) {
  const isFocused = useIsFocused();

  const [loading, setLoading] = useState(!determineIfVideo(props.uri));

  /** Error message displayed to the user */
  const [errorMessage, setErrorMessage] = useState('');

  function onRetry() {
    setErrorMessage('');
  }

  return (
    <Pressable style={props.style} onPress={props.onPress}>
      {/* LOADING OVERLAY */}
      {loading ? <LoadingOverlay loading={loading} /> : null}

      {!props.shouldPlayVideo && determineIfVideo(props.uri) ? (
        <View style={styles.videoIconContainer}>
          <Ionicons name="videocam" size={28} color="white" />
        </View>
      ) : null}

      {/* CONTENT */}
      {errorMessage || !props.uri ? (
        // ERROR
        <View style={styles.errorContainer}>
          <MaterialIcons
            name="error-outline"
            color="#ddd"
            size={40}
            style={{
              marginBottom: 8,
            }}
          />
          <Text style={styles.errorText}>
            {props.uri ? errorMessage : 'No media'}
          </Text>
          {props.hideErrorRetryButton ? null : (
            <Pressable style={styles.retryButton} onPress={onRetry}>
              <Ionicons name="reload" size={18} color="white" />
              <Text style={styles.retryButtonText}>Retry</Text>
            </Pressable>
          )}
        </View>
      ) : determineIfVideo(props.uri) && props.shouldPlayVideo ? (
        // VIDEO
        <VideoPlayer
          isLooping
          suppress={!isFocused}
          onLoadStart={() => {
            setErrorMessage('');
            setLoading(true);
          }}
          onLoad={() => {
            setErrorMessage('');
            setLoading(false);
          }}
          onReadyForDisplay={(event) => {
            setLoading(false);

            const { width, height } =
              getVideoDimensionsFromReadyForDisplayEvent(event);

            if (!width || !height) return;

            props.onNaturalMediaDimensions?.(width, height);
          }}
          onThumbnailLoad={(event) => {
            const { width, height } =
              getImageDimensionsFromImageLoadEvent(event);

            if (!width || !height) return;

            props.onNaturalMediaDimensions?.(width, height);
          }}
          onError={(error) => {
            console.log('MediaViewer failed to load a video:', error);
            setErrorMessage('There was a problem loading this video');
            setLoading(false);
          }}
          // Viewport tracking kinda sucks in the browser, so don't rely on it
          mode={Platform.OS === 'web' ? 'default' : 'autoplay-viewport'}
          resizeMode={props.resizeMode ?? ResizeMode.CONTAIN}
          thumbnailResizeMode={props.resizeMode ?? ResizeMode.COVER}
          sourceUri={props.uri}
          thumbnailSource={{
            uri:
              props.useOriginalSource && props.thumbnailUri
                ? props.thumbnailUri
                : getCDNImageUri({
                    uri: props.uri,
                    isThumbnail: true,
                    dimensions: {
                      width: props.targetCDNMediaWidth,
                      height: props.targetCDNMediaHeight,
                    },
                  }),
          }}
          style={{ flex: 1 }}
        />
      ) : (
        // PHOTO
        <RNImage
          source={{
            uri: determineIfVideo(props.uri)
              ? getCDNImageUri({
                  uri: props.thumbnailUri ?? props.uri,
                  dimensions: {
                    width: props.targetCDNMediaWidth,
                    height: props.targetCDNMediaHeight,
                  },
                  isThumbnail: true,
                })
              : props.useOriginalSource
              ? props.uri
              : getCDNImageUri({
                  uri: props.uri,
                  dimensions: {
                    width: props.targetCDNMediaWidth,
                    height: props.targetCDNMediaHeight,
                  },
                }),
          }}
          resizeMode={props.resizeMode ?? ResizeMode.COVER}
          style={{
            flex: 1,
          }}
          onLoadEnd={() => {
            if (loading) setLoading(false);
          }}
          onLoad={(event) => {
            /** On web, dimensions are get using a different strategy
             * in a useEffect above */
            if (Platform.OS === 'web') return;

            setErrorMessage('');

            const { width, height } =
              getImageDimensionsFromImageLoadEvent(event);

            if (!width || !height) return;

            props.onNaturalMediaDimensions?.(width, height);
          }}
          onError={(error) => {
            if (loading) setLoading(false);
            console.log('image load error', error);
            setErrorMessage('There was a problem fetching this image');
          }}
        />
      )}
    </Pressable>
  );
}

function getImageDimensionsFromImageLoadEvent(
  event: NativeSyntheticEvent<ImageLoadEventData>,
) {
  if (Platform.OS === 'web') {
    /** @ts-ignore - Works a little differently in the browser */
    const img = event.nativeEvent.path?.[0] ?? event.nativeEvent.target;
    if (!img) return {};

    return {
      width: img.width as number,
      height: img.height as number,
    };
  } else {
    const { width, height } = event.nativeEvent.source;
    return {
      width,
      height,
    };
  }
}

function getVideoDimensionsFromReadyForDisplayEvent(
  event: VideoReadyForDisplayEvent,
) {
  if (Platform.OS === 'web') {
    /** @ts-ignore - Works a little differently in the browser */
    const { videoWidth, videoHeight } = event.target;

    if (!videoWidth || !videoHeight) return {};

    return {
      width: videoWidth as number,
      height: videoHeight as number,
    };
  } else {
    const { width, height } = event.naturalSize;
    return {
      width,
      height,
    };
  }
}
