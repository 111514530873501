import { useStripeContext } from '/context/StripeProvider';

export type DepositMethodStatus =
  | 'active'
  | 'action_required'
  | 'action_eventually_required'
  | 'in_review'
  | 'rejected'
  | 'inactive';

type UseStripeStatusReturns = {
  status: DepositMethodStatus | null;
  acceptingCharges: boolean;
  fetching: boolean;
  error: boolean;
  refresh: () => Promise<void>;
};

export default function useStripeStatus(): UseStripeStatusReturns {
  const { status } = useStripeContext();

  return {
    status: status.status,
    acceptingCharges: status.acceptingCharges,
    fetching: status.fetching,
    error: status.error,
    refresh: status.getStripeStatus,
  };
}
