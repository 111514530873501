import {
  Descriptor,
  NavigationProp,
  RouteProp,
} from '@react-navigation/native';
import React from 'react';
import { Dimensions, Platform, Pressable, Text, View } from 'react-native';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  maxSize,
} from 'rn-responsive-styles';
import { ResponsiveTabNavigationOptions } from '../../navigators/createResponsiveTabNavigator';
import Avatar from '/components/Avatar';
import { TITLE_FONT_SIZE } from '/constants';
import { useAuthContext } from '/context';

export const getDefaultHeaderHeight = (statusBarHeight: number) => {
  const layout = Dimensions.get('window');
  const isLandscape = layout.width > layout.height;

  let headerHeight;

  if (Platform.OS === 'ios') {
    // @ts-ignore
    if (isLandscape && !Platform.isPad) {
      headerHeight = 32;
    } else {
      headerHeight = 44;
    }
  } else if (Platform.OS === 'android') {
    headerHeight = 56;
  } else {
    headerHeight = 64;
  }

  return headerHeight + statusBarHeight;
};

type HeaderProps = {
  descriptor: Descriptor<
    ResponsiveTabNavigationOptions,
    NavigationProp<any>,
    RouteProp<any>
  >;
  onOpenSideDrawer: () => void;
  navigation: NavigationProp<any>;
};

export default function Header({ descriptor, onOpenSideDrawer }: HeaderProps) {
  // const { colors } = useTheme();

  const { userData }: any = useAuthContext();

  const { styles } = useStyles();

  // const headerHeight = getDefaultHeaderHeight(insets.top);
  const headerHeight = 80;

  const { tabBarLabel } = descriptor.options;

  /** Invoked when avatar is clicked */
  function onManageAccount() {
    onOpenSideDrawer?.();
  }

  return (
    <View
      pointerEvents="box-none"
      style={[
        styles('header'),
        {
          // paddingTop: insets ? insets.top : 0,
          height: headerHeight,
          zIndex: 10,
          // borderBottomColor: colors.border,
        },
      ]}
    >
      <Text style={styles('text')}>{tabBarLabel}</Text>
      <Pressable onPress={onManageAccount}>
        <Avatar
          containerStyle={styles('accountButton')}
          source={{ uri: userData?.profile_image }}
          size={48}
          rounded
        />
      </Pressable>
    </View>
  );
}

const useStyles = CreateResponsiveStyle(
  {
    text: {
      marginLeft: 24,
      fontFamily: 'LeagueSpartan-Bold',
      fontSize: TITLE_FONT_SIZE,
      textTransform: 'uppercase',
    },
    header: {
      elevation: 8,
      backgroundColor: '#FFF',
      // paddingLeft: 16,
      alignItems: 'center',
      justifyContent: 'space-between',
      // borderBottomWidth: StyleSheet.hairlineWidth,
      flexDirection: 'row',
    },
    accountButton: {
      marginHorizontal: 16,
      marginRight: 24,
      display: 'none',
    },
  },
  {
    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      accountButton: {
        display: 'flex',
      },
    },
  },
);
