import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';

const sortApplicant: UpdateResolver = (result, args, cache) => {
  const fragment = gql`
    fragment SortApplicant on SkillRequestApplication {
      id
      sort_category
    }
  `;

  cache.writeFragment(fragment, {
    id: args.id,
    sort_category: args.category,
  });
};

export default sortApplicant;
