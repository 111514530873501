import React, {
  ComponentProps,
  JSXElementConstructor,
  useCallback,
  useState,
} from 'react';
import { View, Text } from 'react-native';

import { Link, useLinkTo, useFocusEffect } from '@react-navigation/native';

import { useAuthContext } from '../context';

/**
 * A higher order component (HOC) to wrap around admin-only screens
 *
 * We may want to modify this in the future to prompt authentication
 * while persisting original URL, navigating to intended destination
 * after user has logged in for a continuous experience.
 */
export default function withAdminAccess<
  ComponentType extends JSXElementConstructor<any>,
>(Component: ComponentType) {
  return (props: ComponentProps<ComponentType>) => {
    // Are we ready to render protected content?
    const [ready, setReady] = useState(false);

    // In case, for any reason, we don't redirect automatically
    // we use this flag to render a navigation button
    const [shouldRedirect, setShouldRedirect] = useState(false);

    const linkTo = useLinkTo();

    const { isAuthenticating, fetching, isAdmin } = useAuthContext();

    const init = useCallback(
      function () {
        // If admin flag is set to true, set app to ready
        if (isAdmin) setReady(true);
        // Otherwise, redirect
        else {
          linkTo('/');
          setShouldRedirect(true);
        }
      },
      [isAdmin, linkTo],
    );

    useFocusEffect(
      useCallback(() => {
        setShouldRedirect(false);

        // Check for authorization

        // If app loads into an admin screen, we will wait for authentication to complete
        // before determining readiness to render protected content
        if (!isAuthenticating && !fetching) init();
      }, [isAuthenticating, fetching, init]),
    );

    if (shouldRedirect)
      return (
        <View>
          <Text>Unathorized. Redirecting...</Text>
          <Link to="/">
            <Text>Click here if you are not automatically redirected</Text>
          </Link>
        </View>
      );
    if (ready === true) return <Component {...props} />;
    else
      return (
        <View>
          <Text>Please wait...</Text>
        </View>
      );
  };
}
