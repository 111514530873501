import scrapPatterns from './json/ScrapAnimalPatterns.json';
import patterns from './json/AnimalPatterns.json';

/**
 * Gets random animal scrapbook pattern from S3 bucket
 * @returns {{ uri: string, thumbnailUri: string }} Pattern URI and thumbnail URI
 */
export function getRandomScrapPattern() {
  const randomIndex = getRandomIndex(scrapPatterns.length);

  return scrapPatterns[randomIndex];
}

/**
 * Gets random animal pattern from S3 bucket
 *
 */
const PatternKeys = Object.keys(patterns) as (keyof typeof patterns)[];
export function getRandomPattern() {
  const key = PatternKeys[getRandomIndex(PatternKeys.length)];

  return patterns[key];
}

function getRandomIndex(length: number) {
  return Math.floor(Math.random() * (length - 1));
}

/** Gets specific animal pattern from S3 bucket */
export function getAnimalPatternURL(name: keyof typeof patterns) {
  return patterns[name];
}
