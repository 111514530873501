import _ from 'lodash';

const API_URL = 'https://maps.googleapis.com/maps/api/geocode/json';

export function GeoPlace(res) {
  Object.defineProperty(this, 'googleResponse', {
    value: res,
    writable: true,
    enumerable: false,
    configurable: true,
  });

  Object.defineProperty(this, 'city', {
    enumerable: true,
    get: function () {
      switch (this.country.short_name) {
        case 'CA':
        case 'US':
          return this.locality || this.sublocality;
      }
      return undefined;
    },
  });

  Object.defineProperty(this, 'province_state', {
    enumerable: true,
    get: function () {
      switch (this.country.short_name) {
        case 'CA':
        case 'US':
          return this.administrative_area_level_1;
      }
      return undefined;
    },
  });

  this.googleResponse = res;
  this._parseResult(res);
}

GeoPlace.prototype._normalizeAddressComponents = function (res) {
  var components = {};
  res.address_components.forEach(function (item) {
    components[item.types[0]] = {
      long_name: item.long_name,
      short_name: item.short_name,
    };
  });
  return components;
};

GeoPlace.prototype._parseResult = function (res) {
  var norm = this._normalizeAddressComponents(res);
  _.extend(this, norm);

  this.formatted_address = res.formatted_address;

  var geo = res.geometry;
  this.location = geo.location;
  this.location_type = geo.location_type;

  if (geo.bounds) this.location_bounds = geo.bounds;

  return norm;
};

GeoPlace.parseAddressResults = function (results) {
  var places = [];
  results.forEach(function (res) {
    places.push(new GeoPlace(res));
  });
  return places;
};

export function GeoCoder(apiKey) {
  this.queryData = {
    key: apiKey,
    sensor: false,
  };

  this.lastResults = null;
}

GeoCoder.prototype.find = function (place, cb) {
  var coder = this;
  fetch(`${API_URL}?address=${place}&key=${coder.queryData.key}`)
    .then((res) => res.json())
    .then((res) => {
      switch (res.status) {
        case 'OK':
        case 'ZERO_RESULTS':
          coder.lastResults = res;
          cb && cb(null, GeoPlace.parseAddressResults(res.results), res);
          break;
        default:
          cb && cb(res);
      }
    });
};

GeoCoder.prototype.reverseFind = function (lat, lng, cb) {
  var coder = this;
  fetch(`${API_URL}?latlng=${lat},${lng}&key=${coder.queryData.key}`)
    .then((res) => res.json())
    .then((res) => {
      switch (res.status) {
        case 'OK':
        case 'ZERO_RESULTS':
          coder.lastResults = res;
          cb && cb(null, GeoPlace.parseAddressResults(res.results), res);
          break;
        default:
          cb && cb(res);
      }
    });
};

export default function (options) {
  if (!options || !_.has(options, 'key')) {
    throw new Error(
      'Property `key` required. Please register your app with google api and the api key here.',
    );
  }

  return new GeoCoder(options.key);
}
