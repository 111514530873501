import { Linking, Platform } from 'react-native';
import env from '/env';

const shareToFacebook = async (url: string) => {
  const encodedUrl = encodeURIComponent(url);
  const facebookAppId = env.FACEBOOK_APP_ID;
  const facebookShareUrl = `https://www.facebook.com/dialog/share?app_id=${facebookAppId}&href=${encodedUrl}&display=popup`;

  try {
    if (Platform.OS === 'web') {
      window.open(
        facebookShareUrl,
        'Share to Facebook',
        'width=600,height=400,menubar=no,toolbar=no,scrollbars=yes',
      );
    } else {
      await Linking.openURL(facebookShareUrl);
    }
  } catch (error) {
    console.error('An error occurred while sharing', error);
  }
};

export default shareToFacebook;
