import {
  createStackNavigator,
  StackNavigationOptions,
} from '@react-navigation/stack';
import { getStackNavigatorOptions } from './common';
import Configuration from '/screens/admin/Configuration';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import React from 'react';
import LoadingSpinnerOverlay from '/components/LoadingSpinnerOverlay';

const Stack = createStackNavigator();

const createScreen = (Component: React.LazyExoticComponent<any>) => {
  return (props: any) => (
    <React.Suspense fallback={<LoadingSpinnerOverlay />}>
      <Component {...props} />
    </React.Suspense>
  );
};

const ConfigureSkillsScreen = createScreen(
  React.lazy(
    () => import('/screens/admin/Configuration/ConfigureSkillsScreen'),
  ),
);
const ConfigureResearchTopicsScreen = createScreen(
  React.lazy(
    () => import('/screens/admin/Configuration/ConfigureResearchTopicsScreen'),
  ),
);

export default function ConfigurationStack() {
  const safeAreaInsets = useSafeAreaInsets();

  return (
    <Stack.Navigator
      initialRouteName="Configuration"
      screenOptions={getStackNavigatorOptions(safeAreaInsets)}
    >
      <Stack.Screen
        component={Configuration}
        name="Configuration"
        options={ConfigurationScreenOptions}
      />
      <Stack.Screen
        component={ConfigureSkillsScreen}
        name="ConfigureSkills"
        options={ConfigureSkillsScreenOptions}
      />

      <Stack.Screen
        component={ConfigureResearchTopicsScreen}
        name="ConfigureResearchTopics"
        options={ConfigureResearchTopicsScreenOptions}
      />
    </Stack.Navigator>
  );
}

const ConfigurationScreenOptions = (): StackNavigationOptions => ({
  title: 'Configuration',
  headerTitle: 'CONFIGURATION',
});

const ConfigureSkillsScreenOptions = (): StackNavigationOptions => ({
  title: 'Configure Skills',
  headerTitle: 'CONFIGURE SKILLS',
});

const ConfigureResearchTopicsScreenOptions = (): StackNavigationOptions => ({
  title: 'Configure Topics',
  headerTitle: 'CONFIGURE TOPICS',
});
