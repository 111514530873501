import { UpdateResolver } from '@urql/exchange-graphcache';

const deleteDiscussionBoardMessage: UpdateResolver = (result, args, cache) => {
  const messageId = args?.messageId as any;

  cache.invalidate({
    __typename: 'Message',
    id: messageId,
  });
};

export default deleteDiscussionBoardMessage;
