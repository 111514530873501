import { View, Text, ViewStyle, Pressable } from 'react-native';
import React from 'react';
import useUpdates from '/hooks/useUpdates';
import { StyleSheet } from 'react-native';
import { KEY_GRAY } from '/constants';
import { MaterialIcons } from '@expo/vector-icons';

type Props = {
  containerStyle?: ViewStyle;
};

export default function UpdateAvailableWidget(props: Props) {
  const { hasUpdate, reload } = useUpdates();

  return hasUpdate ? (
    <View style={[styles.container, props.containerStyle]}>
      <MaterialIcons
        style={{
          marginRight: 8,
        }}
        name="system-update"
        size={24}
        color="white"
      />
      <Text style={styles.text}>
        A new update is available. Please restart the app to apply it.
      </Text>
      <Pressable
        onPress={reload}
        style={({ pressed }) => [
          styles.reloadButton,
          {
            opacity: pressed ? 0.5 : 1,
          },
        ]}
      >
        <Text style={styles.buttonText}>RELOAD</Text>
      </Pressable>
    </View>
  ) : null;
}

const styles = StyleSheet.create({
  container: {
    padding: 8,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: KEY_GRAY,
  },
  text: {
    flex: 1,
    fontFamily: 'Lato-Bold',
    color: 'white',
    fontSize: 16,
  },
  reloadButton: {
    marginLeft: 10,
    backgroundColor: 'white',
    padding: 6,
    borderRadius: 8,
  },
  buttonText: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 16,
    color: KEY_GRAY,
    marginTop: 2,
  },
});
