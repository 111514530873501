import useStripeStatus from './useStripeStatus';
import { useAuthContext } from '/context';

type AccountDonationStatus =
  | 'not_set_up'
  | 'active'
  | 'active_action_required'
  | 'inactive'
  | 'inactive_pending';

export interface UseAccountDonationStatusReturns {
  error: boolean;
  fetching: boolean;
  acceptingDonations: boolean;
  status: AccountDonationStatus;
  refresh: () => void;
}

/**
 * This hook determines whether the signed in organization can accept donations.
 * Status can be one of the following:
 *  - 'not_set_up' - The organization has not set up any deposit methods
 *  - 'active' - The organization has at least one valid deposit method and is accepting donations
 *  - 'inactive' - The organization has at least one deposit method but is not accepting donations
 */
export default function useAccountDonationStatus(): UseAccountDonationStatusReturns {
  const {
    userData,
    fetching: fetchingUser,
    fetchUserError,
    refresh: refreshUser,
  } = useAuthContext();

  const stripeStatus = useStripeStatus();

  let status: AccountDonationStatus = 'not_set_up';

  (() => {
    if (!userData?.stripe_cid || !userData?.onboarded) return;

    if (stripeStatus.acceptingCharges) {
      if (stripeStatus.status === 'action_eventually_required') {
        status = 'active_action_required';
      } else status = 'active';
    } else if (stripeStatus.acceptingCharges === false) {
      if (stripeStatus.status === 'in_review') {
        status = 'inactive_pending';
      } else {
        status = 'inactive';
      }
    }
  })();

  const refresh = () => {
    if (stripeStatus.error) {
      stripeStatus.refresh();
    }
    if (fetchUserError) {
      refreshUser();
    }
  };

  return {
    error: !!stripeStatus.error || !!fetchUserError,
    acceptingDonations:
      status === 'active' || status === 'active_action_required',
    fetching: stripeStatus.fetching || fetchingUser,
    status,
    refresh,
  };
}
