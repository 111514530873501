import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import MyImpactWidget from '/components/ImpactTab/MyImpactWidget/MyImpactWidget';
import TruncatedList from '/components/TruncatedList/TruncatedList';
import Accordion from '/components/Accordion/Accordion';
import CampaignPreview from '/components/CampaignPreview/CampaignPreview';
import ACTION_ALERT from '/components/ImpactTab/elements/ActionAlerts';
import { KEY_GRAY } from '/constants';
import { GetMyImpactCampaignsQuery } from '/generated/graphql';

type MyImpactCampaignsData = GetMyImpactCampaignsQuery['me'];

interface IMyImpactCampaignsProps {
  data: MyImpactCampaignsData | undefined;
  expandAll: boolean;
  loading: boolean;
}

export default function MyImpactCampaigns({
  data,
  expandAll,
  loading,
}: IMyImpactCampaignsProps) {
  const { navigate } = useNavigation<any>();

  const [activeCampaignsActionAlert, setActiveCampaignsActionAlert] = useState<
    keyof typeof ACTION_ALERT | undefined
  >();

  // Helper function that helps us decide
  // which accordions to expand when the parent component
  // requests all accordions expanded
  // Only accordions holding lists with length > 0
  // should expand
  function shouldExpand(list: any[] | undefined) {
    return expandAll && (list?.length ?? 0) > 0;
  }

  useEffect(() => {
    /**
     * Action alert logic
     */
    if (
      data?.impacted_campaigns_current.items.some((campaign) => {
        return !!campaign.skilled_impact_requests?.some(
          (req) => req.my_application?.has_unread_messages_from_organization,
        );
      })
    ) {
      setActiveCampaignsActionAlert('NewMessage');
    } else {
      setActiveCampaignsActionAlert(undefined);
    }
  }, [data]);

  function goToCampaign(campaignId: string) {
    navigate('Campaign', {
      campaignId,
    });
  }

  function onViewAllMyCampaigns(category: 'current' | 'past' | 'archived') {
    navigate('MyImpactCampaigns', {
      category,
    });
  }

  return (
    <>
      {/* CURRENTLY EMPOWERING */}
      <Accordion
        loading={loading}
        expanded={shouldExpand(data?.impacted_campaigns_current.items)}
        title="Currently Empowering"
        headerStyle={styles.accordionHeaderStyle}
        contentContainerStyle={styles.accordionContentContainerStyle}
        icon={<Ionicons name="rocket" size={28} />}
        badgeValue={data?.impacted_campaigns_current?.total ?? 0}
        subtitle={
          activeCampaignsActionAlert
            ? ACTION_ALERT[activeCampaignsActionAlert]?.text
            : ''
        }
        subtitleStyle={{
          color:
            activeCampaignsActionAlert &&
            ACTION_ALERT[activeCampaignsActionAlert]?.color,
        }}
      >
        {data?.impacted_campaigns_current?.items?.length ? (
          <TruncatedList
            style={{ width: '100%' }}
            maxLength={2}
            onViewMore={() => {
              onViewAllMyCampaigns('current');
            }}
            total={data.impacted_campaigns_current.total}
            items={data?.impacted_campaigns_current?.items}
            renderItem={(campaign, index) => {
              return (
                <CampaignPreviewContainer>
                  <CampaignPreview
                    onPress={() => goToCampaign(campaign.id)}
                    showChevronArrow
                    key={index}
                    campaign={campaign}
                    campaignPost={campaign.original_post}
                    widget={<MyImpactWidget data={campaign} />}
                  />
                </CampaignPreviewContainer>
              );
            }}
          />
        ) : (
          <View style={styles.emptyContainer}>
            <Text style={styles.emptyText}>
              You are not currently empowering any campaigns
            </Text>
          </View>
        )}
      </Accordion>

      {/* PREVIOUSLY EMPOWERED */}
      <Accordion
        loading={loading}
        expanded={shouldExpand(data?.impacted_campaigns_past.items)}
        title="Empowered"
        headerStyle={styles.accordionHeaderStyle}
        contentContainerStyle={styles.accordionContentContainerStyle}
        icon={<Ionicons name="rocket-outline" size={28} />}
        badgeValue={data?.impacted_campaigns_past.total ?? 0}
      >
        {data?.impacted_campaigns_past?.items?.length ? (
          <TruncatedList
            style={{ width: '100%' }}
            onViewMore={() => {
              onViewAllMyCampaigns('past');
            }}
            total={data.impacted_campaigns_past.total}
            items={data?.impacted_campaigns_past.items}
            renderItem={(campaign, index) => (
              <CampaignPreviewContainer>
                <CampaignPreview
                  onPress={() => goToCampaign(campaign.id)}
                  showChevronArrow
                  showSocialControls
                  key={index}
                  campaign={campaign}
                  campaignPost={campaign.original_post}
                  widget={<MyImpactWidget data={campaign} />}
                />
              </CampaignPreviewContainer>
            )}
          />
        ) : (
          <View style={styles.emptyContainer}>
            <Text style={styles.emptyText}>
              You have not yet empowered any campaigns
            </Text>
          </View>
        )}
      </Accordion>

      {/* ARCHIVED */}
      <Accordion
        loading={loading}
        expanded={shouldExpand(data?.impacted_campaigns_archived.items)}
        title="Archived"
        headerStyle={styles.accordionHeaderStyle}
        contentContainerStyle={styles.accordionContentContainerStyle}
        icon={
          <Ionicons
            style={{
              transform: [{ rotate: '-45deg' }],
            }}
            name="rocket-outline"
            size={28}
          />
        }
        badgeValue={data?.impacted_campaigns_archived.total ?? 0}
      >
        {data?.impacted_campaigns_archived.items?.length ? (
          <TruncatedList
            style={{ width: '100%' }}
            onViewMore={() => {
              onViewAllMyCampaigns('archived');
            }}
            total={data.impacted_campaigns_archived.total}
            items={data?.impacted_campaigns_archived.items}
            renderItem={(campaign, index) => (
              <CampaignPreviewContainer>
                <CampaignPreview
                  onPress={() => goToCampaign(campaign.id)}
                  showChevronArrow
                  showSocialControls
                  key={index}
                  campaign={campaign}
                  campaignPost={campaign.original_post}
                  widget={<MyImpactWidget data={campaign} />}
                />
              </CampaignPreviewContainer>
            )}
          />
        ) : (
          <View style={styles.emptyContainer}>
            <Text style={styles.emptyText}>
              You have no archived contributions
            </Text>
          </View>
        )}
      </Accordion>
    </>
  );
}

const CampaignPreviewContainer = (props: React.PropsWithChildren<{}>) => (
  <View style={styles.previewContainer}>{props.children}</View>
);

const styles = StyleSheet.create({
  emptyContainer: {
    padding: 12,
    marginVertical: 6,
    width: '100%',
  },
  emptyText: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
  },
  accordionContentContainerStyle: {
    backgroundColor: 'transparent',
    paddingVertical: 4,
  },
  accordionHeaderStyle: {
    backgroundColor: 'white',
    paddingHorizontal: 16,
  },
  previewContainer: {
    width: '100%',
    borderRadius: 6,
    overflow: 'hidden',
    backgroundColor: 'white',
    marginVertical: 4,
    paddingRight: 6,
  },
});
