import { ColorValue } from 'react-native';
// @ts-ignore
import TinyEmitter from 'tiny-emitter/instance';
import {
  AlertButton,
  AlertField,
  SmallAlertAwaitPromise,
} from './context/AlertProvider';

type AlertEvent =
  | {
      type?: 'default';
      title: string;
      message?: string;
      buttons?: (AlertButton | undefined)[];
      fields?: AlertField[];
    }
  | {
      type: 'notification';
      message: string;
      color?: ColorValue;
      textColor?: ColorValue;
      duration?: number;
      awaitPromise?: SmallAlertAwaitPromise;
    };

export default class Alert {
  private static isReady = false;

  private static eventQueue: AlertEvent[] = [];

  static __setReady() {
    if (!this.isReady) {
      this.isReady = true;

      if (this.eventQueue.length) {
        this.eventQueue.forEach((deferredEvent) => {
          switch (deferredEvent.type) {
            case 'notification': {
              this.notify(deferredEvent);
              break;
            }
            case 'default':
            default: {
              const { title, message, buttons } = deferredEvent;
              this.alert(title, message, buttons);
              break;
            }
          }
        });
      }

      this.eventQueue = [];
    }
  }

  /**
   * Displays a customizable alert message over the app
   * @param title Title displayed at top of alert
   * @param message Smaller text displayed below title
   * @param buttons Specify button style, text and onPress
   */
  static alert<T>(
    title: string,
    message?: string,
    buttons?: (AlertButton | undefined)[],
    fields?: AlertField<T>[],
  ) {
    if (!this.isReady) {
      this.eventQueue.push({
        type: 'default',
        title,
        message,
        buttons,
        fields,
      });
      /** 'alert' event is handled in context/AlertProvider */
    } else TinyEmitter.emit('alert', title, message, buttons, fields);
  }

  /**
   * Displays a small alert at the top of the screen with customizable text, color and duration
   * @param options
   */
  static notify(options: {
    message: string;
    /** Background color of notification */
    color?: ColorValue;
    /** Color of notification message text */
    textColor?: ColorValue;
    /** Duration in seconds before alert automatically disappears. Setting to zero will prevent
     * the notification from disappearing until user dismisses it manually. */
    duration?: number;
    /** If provided, the alert will not close until `duration`ms after the promise resolves.
     * If promise rejects, the alert won't close until user manually dismisses it. */
    awaitPromise?: SmallAlertAwaitPromise;
  }) {
    if (!this.isReady) {
      this.eventQueue.push({
        type: 'notification',
        ...options,
      });
      /** 'alert-notify' event is handled in context/AlertProvider */
    } else {
      TinyEmitter.emit(
        'alert-notify',
        options.message,
        options.color,
        options.textColor,
        options.duration,
        options.awaitPromise,
      );
    }
  }
}
