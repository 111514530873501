import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  header: {
    padding: 8,
    top: 40,
    left: 0,
    right: 0,
    alignItems: 'center',
    position: 'absolute',
    zIndex: 99,
  },
  headerContent: {
    width: '100%',
    maxWidth: 1000,
    flexDirection: 'row',
    alignItems: 'center',
  },
  arrowView: {
    padding: 8,
    left: 0,
    maxWidth: 64,
    maxHeight: 64,
  },
  progressBar: {
    flex: 1,
    paddingHorizontal: 16,
  },
});
