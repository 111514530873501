import { AntDesign, FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useRef } from 'react';
import { Pressable, View } from 'react-native';
import styles from './common.style';
import ListEditCreativeConnectProjectsModal from './ListEditCreativeConnectProjectsModal';
import CreativeConnectDark from '/assets/jsicons/CreativeConnectDark';
import X from '/assets/jsicons/miscIcons/X';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import SectionText from '/components/common/Section/SectionText';
import SectionTitle from '/components/common/Section/SectionTitle';
import {
  CREATIVE_CONNECT_BACKGROUND,
  CREATIVE_CONNECT_BACKGROUND_GRAY,
} from '/constants';
import { useModalContext } from '/context';

type Props = {
  onRequestClose: () => void;
};

export default function CreativeConnectCreateOrEditModal(props: Props) {
  const { spawnModal, closeModal } = useModalContext();
  const navigation = useNavigation<StackNavigationProp<any>>();

  const listModalIdRef = useRef<string>();
  function onEditExisting() {
    listModalIdRef.current = spawnModal({
      disableEntireHeader: true,
      title: 'SELECT PROJECT',
      style: {
        backgroundColor: CREATIVE_CONNECT_BACKGROUND_GRAY,
        maxWidth: 480,
      },
      component: (
        <ListEditCreativeConnectProjectsModal
          onRequestClose={() => {
            listModalIdRef.current && closeModal(listModalIdRef.current);
          }}
        />
      ),
    });

    props.onRequestClose();
  }

  return (
    <View>
      <HorizontalContainer
        style={{
          justifyContent: 'space-between',
        }}
      >
        <HorizontalContainer>
          <CreativeConnectDark
            style={{
              marginRight: 8,
            }}
          />
          <SectionTitle
            style={{
              color: 'white',
              fontSize: 22,
              marginTop: 4,
            }}
          >
            CREATIVE CONNECT
          </SectionTitle>
        </HorizontalContainer>

        <Pressable
          style={{
            padding: 8,
          }}
          onPress={props.onRequestClose}
        >
          <X fill="white" />
        </Pressable>
      </HorizontalContainer>
      <Pressable
        onPress={() => {
          navigation.navigate('CreateCreativeConnectProject');
          props.onRequestClose();
        }}
        style={({ pressed }) => [
          styles.creativeConnectOptionButton,
          {
            backgroundColor: pressed ? 'gray' : CREATIVE_CONNECT_BACKGROUND,
          },
        ]}
      >
        <View
          style={{
            flexDirection: 'row',
          }}
        >
          <AntDesign
            name="pluscircleo"
            size={32}
            color="white"
            style={{
              marginRight: 12,
            }}
          />
          <View style={{ flex: 1 }}>
            <SectionTitle style={styles.creativeConnectOptionButtonTitle}>
              NEW CREATION
            </SectionTitle>
            <SectionText style={styles.creativeConnectOptionButtonSubtitle}>
              Share your work with the world and drive your audience to action
            </SectionText>
          </View>
        </View>
      </Pressable>

      <Pressable
        onPress={onEditExisting}
        style={({ pressed }) => [
          styles.creativeConnectOptionButton,
          {
            backgroundColor: pressed ? 'gray' : CREATIVE_CONNECT_BACKGROUND,
          },
        ]}
      >
        <View
          style={{
            flexDirection: 'row',
          }}
        >
          <FontAwesome
            name="edit"
            size={32}
            color="white"
            style={{
              marginRight: 12,
            }}
          />
          <View style={{ flex: 1 }}>
            <SectionTitle style={styles.creativeConnectOptionButtonTitle}>
              UPDATE EXISTING CREATION
            </SectionTitle>
            <SectionText style={styles.creativeConnectOptionButtonSubtitle}>
              Add/remove episodes, update media & details, change settings
            </SectionText>
          </View>
        </View>
      </Pressable>
    </View>
  );
}
