import { FontAwesome5 } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import dayjs from 'dayjs';
import React from 'react';
import { Pressable, Text, View } from 'react-native';
import Alert from '/Alert';
import Avatar from '/components/Avatar';
import Button from '/components/Button';
import CampaignConnectLinkablePostPreview from '/components/CampaignConnect/CampaignConnectLinkablePostPreview/CampaignConnectLinkablePostPreview';
import commonModalStyles from '/components/CampaignConnect/commonModalStyles.style';
import CampaignPreviewTile from '/components/CampaignPreviewTile/CampaignPreviewTile';
import ListLoading from '/components/ListLoading';
import GenericError from '/components/common/Generic/GenericError';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import ScrollView from '/components/common/ScrollView/ScrollView';
import SectionText from '/components/common/Section/SectionText';
import { ALERT_RED, KEY_GRAY, KEY_GREEN } from '/constants';
import {
  CampaignConnectInvite,
  CreativeConnectProject,
  User,
  useAcceptCampaignConnectSubmissionMutation,
  useGetCampaignPreviewQuery,
  useRejectCampaignConnectSubmissionMutation,
} from '/generated/graphql';
import { shorten } from '/util';
import TruncatableText from '/components/TruncatableText';

export interface ICampaignConnectInvite
  extends Pick<
    CampaignConnectInvite,
    | 'id'
    | 'message'
    | 'expiresAt'
    | 'created_at'
    | 'submittedCampaignId'
    | 'creativeConnectProjectId'
  > {
  creativeConnectProject?:
    | (Pick<
        CreativeConnectProject,
        'id' | 'creative_type' | 'media' | 'is_premiered' | 'is_series'
      > & {
        title: Pick<CreativeConnectProject['title'], 'text'>;
      })
    | null
    | undefined;
}

type Props = {
  invite: ICampaignConnectInvite;
  invitedUser: Pick<User, 'id' | 'name' | 'profile_image'> | null | undefined;
  invitingUser: Pick<User, 'id' | 'name' | 'profile_image'> | null | undefined;
  hideLinkedPost?: boolean;
  onRequestClose: (shouldRemoveInvite: boolean) => void;
};

const RESPONSE_BUTTON_WIDTH = 180;

export default function CampaignConnectInviteCampaignSubmittedCTAModal(
  props: Props,
) {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const [{ data, fetching, error }, getCampaign] = useGetCampaignPreviewQuery({
    variables: {
      campaignId: props.invite.submittedCampaignId as string,
    },
    pause: !props.invite.submittedCampaignId,
  });

  const [{ fetching: accepting }, acceptCampaignSubmission] =
    useAcceptCampaignConnectSubmissionMutation();
  const [{ fetching: rejecting }, rejectCampaignSubmission] =
    useRejectCampaignConnectSubmissionMutation();

  function onAcceptSubmission() {
    const _accept = async () => {
      try {
        const { error: err } = await acceptCampaignSubmission({
          inviteId: props.invite.id,
        });
        if (err) {
          throw err;
        }

        Alert.notify({
          message: 'Submission accepted',
          color: KEY_GREEN,
        });
        props.onRequestClose(true);
      } catch (err) {
        console.error('Error accepting campaign submission:', err);
        Alert.alert(
          'Oh no',
          'There was a problem accepting the submission. Please try again.',
        );
        return;
      }
    };

    Alert.alert(
      'Accept Submission',
      'Are you sure you want to accept this submission?',
      [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        {
          text: 'Accept',
          style: 'default',
          onPress: _accept,
        },
      ],
    );
  }

  function onRejectSubmission() {
    const _reject = async () => {
      try {
        const { error: err } = await rejectCampaignSubmission({
          inviteId: props.invite.id,
        });
        if (err) {
          throw err;
        }

        Alert.notify({
          message: 'Submission rejected',
          color: KEY_GREEN,
        });
        props.onRequestClose(true);
      } catch (err) {
        console.error('Error rejecting campaign submission:', err);
        Alert.alert(
          'Oh no',
          'There was a problem rejecting the submission. Please try again.',
        );
      }
    };

    Alert.alert(
      'Reject Submission',
      'Are you sure you want to reject this submission?',
      [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        {
          text: 'Reject',
          style: 'destructive',
          onPress: _reject,
        },
      ],
    );
  }

  const busy = accepting || rejecting;

  return (
    <ScrollView
      style={{
        pointerEvents: busy ? 'none' : 'auto',
        opacity: busy ? 0.6 : 1,
      }}
    >
      <SectionText>
        <Pressable
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
          onPress={() => {
            navigate('Profile', {
              id: props.invitedUser?.id,
            });
          }}
        >
          <Avatar
            rounded
            source={{
              uri: props.invitedUser?.profile_image ?? '',
            }}
            containerStyle={{
              marginRight: 4,
            }}
            size={16}
          />
          <SectionText
            style={{
              fontFamily: 'Lato-Bold',
            }}
          >
            {shorten(props.invitedUser?.name ?? 'an organization', 28)}
          </SectionText>
        </Pressable>{' '}
        responded to your invite to create a campaign. Please review it to
        feature it on your post.
      </SectionText>

      <Text style={commonModalStyles.fieldTitle}>SUBMITTED CAMPAIGN</Text>
      {fetching ? (
        <ListLoading />
      ) : error || !data?.getCampaign ? (
        <GenericError onRetry={error ? getCampaign : undefined} />
      ) : (
        <>
          <CampaignPreviewTile
            campaign={data.getCampaign}
            campaignPost={data.getCampaign.original_post}
            style={{
              width: '100%',
              marginTop: 2,
            }}
            onPress={() => {
              navigate('Campaign', {
                campaignId: data.getCampaign.id,
              });
            }}
          />
        </>
      )}

      <HorizontalContainer
        style={{
          marginTop: 8,
          alignItems: 'flex-start',
        }}
      >
        <Button
          label="View Campaign"
          style={{
            width: RESPONSE_BUTTON_WIDTH,
          }}
          containerStyle={{
            marginRight: 8,
          }}
          onPress={() => {
            if (!data?.getCampaign) return;
            navigate('Campaign', {
              campaignId: data.getCampaign.id,
            });
          }}
        />
        <SectionText>
          Go to the Campaign page to view the submission in detail.
        </SectionText>
      </HorizontalContainer>

      <HorizontalContainer
        style={{
          marginTop: 8,
          alignItems: 'flex-start',
        }}
      >
        <Button
          containerStyle={{
            marginRight: 8,
          }}
          style={{
            width: RESPONSE_BUTTON_WIDTH,
            backgroundColor: KEY_GREEN,
          }}
          label="Accept"
          loading={accepting}
          onPress={onAcceptSubmission}
        />
        <SectionText>
          If this looks good, accept the submission to feature it on your post.
        </SectionText>
      </HorizontalContainer>

      <HorizontalContainer
        style={{
          marginTop: 8,
          alignItems: 'flex-start',
        }}
      >
        <Button
          containerStyle={{
            marginRight: 8,
          }}
          style={{
            width: RESPONSE_BUTTON_WIDTH,
            backgroundColor: ALERT_RED,
          }}
          labelStyle={{
            color: 'white',
          }}
          label="Reject"
          loading={rejecting}
          onPress={onRejectSubmission}
        />
        <SectionText>
          Reject this submission and delete the invite.{' '}
          <SectionText
            style={{
              fontFamily: 'Lato-Bold',
            }}
          >
            {shorten(props.invitedUser?.name ?? 'the organization', 28)}
          </SectionText>{' '}
          won't be notified.
        </SectionText>
      </HorizontalContainer>

      {props.invite.expiresAt ? (
        <HorizontalContainer>
          <FontAwesome5
            name="clock"
            size={17}
            color={
              dayjs(props.invite.expiresAt).isBefore(dayjs().add(1, 'day'))
                ? 'crimson'
                : KEY_GRAY
            }
          />
          {/* if expiresAt is less than day from now, text should be red */}
          <SectionText
            style={{
              color: dayjs(props.invite.expiresAt).isBefore(
                dayjs().add(1, 'day'),
              )
                ? 'crimson'
                : KEY_GRAY,
            }}
          >
            {` `} Expires{' '}
            {dayjs(props.invite.expiresAt).format('MMMM DD, YYYY h:mm A')}
          </SectionText>
        </HorizontalContainer>
      ) : null}

      {props.hideLinkedPost ? null : (
        <>
          <Text style={commonModalStyles.fieldTitle}>YOUR POST</Text>
          <SectionText
            style={{
              marginBottom: 4,
            }}
          >
            This is the post where the campaign would be featured
          </SectionText>

          <CampaignConnectLinkablePostPreview invite={props.invite} />
        </>
      )}

      <HorizontalContainer
        style={{
          marginTop: 16,
        }}
      >
        <FontAwesome5 name="clock" size={17} color={'gray'} />
        <SectionText
          style={{
            color: 'gray',
            fontFamily: 'Lato-Bold',
            marginLeft: 4,
          }}
        >
          You sent this invite{' '}
          {dayjs(Number(props.invite.created_at)).fromNow()}.
        </SectionText>
      </HorizontalContainer>

      {props.invite.message?.trim() ? (
        <View style={commonModalStyles.invitedByContainer}>
          <HorizontalContainer>
            <Avatar
              rounded
              size={24}
              containerStyle={{
                marginRight: 4,
              }}
              source={{
                uri: props.invitingUser?.profile_image,
              }}
            />
            <Text style={commonModalStyles.invitedUserName} numberOfLines={2}>
              {props.invitingUser?.name || 'Unknown User'}
            </Text>
          </HorizontalContainer>
          <SectionText
            style={{
              fontFamily: 'Lato-Italic',
            }}
          >
            <Text
              style={{
                fontFamily: 'Lato-Bold',
              }}
            >
              Message:
            </Text>{' '}
            "
            <TruncatableText text={props.invite.message} />"
          </SectionText>
        </View>
      ) : null}
    </ScrollView>
  );
}
