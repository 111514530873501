import {
  getLastKnownPositionAsync,
  requestForegroundPermissionsAsync,
} from 'expo-location';
import React, { useEffect, useState } from 'react';

interface LastKnownPosition {
  latitude: number | undefined;
  longitude: number | undefined;
}

interface WithLastKnownPositionProps {
  lastKnownPosition: LastKnownPosition | null;
}

export function withLastKnownPositionHOC<
  OwnProps extends WithLastKnownPositionProps,
>(Component: React.ComponentType<OwnProps>) {
  return (props: Omit<OwnProps, keyof WithLastKnownPositionProps>) => {
    const [lastKnownPosition, setLastKnownPosition] =
      useState<LastKnownPosition | null>(null);

    useEffect(() => {
      async function fetchLastKnownPosition() {
        try {
          const position = await getLastKnownPositionAsync();
          if (position)
            setLastKnownPosition({
              latitude: position?.coords.latitude,
              longitude: position?.coords.longitude,
            });
        } catch (error) {
          console.error(error);
        }
      }

      requestForegroundPermissionsAsync().then(({ status }) => {
        if (status !== 'granted') {
          return;
        }

        fetchLastKnownPosition();
      });
    }, []);

    return (
      <Component
        {...(props as OwnProps)}
        lastKnownPosition={lastKnownPosition}
      />
    );
  };
}
