import React, { useState } from 'react';
import Button from '/components/Button';
import RadioOption from '/components/RadioOption';
import ScrollView from '/components/common/ScrollView/ScrollView';
import { KEY_GREEN } from '/constants';
import { CreativeConnectProjectCreativeType } from '/generated/graphql';

const CREATIVE_TYPES = Object.values(CreativeConnectProjectCreativeType);

type Props = {
  initialCreativeTypes: CreativeConnectProjectCreativeType[];
  onRequestClose: (creativeTypes: CreativeConnectProjectCreativeType[]) => void;
};

export default function CreativeConnectCreativeTypeFilterModal(props: Props) {
  const [creativeTypes, setCreativeTypes] = useState<
    CreativeConnectProjectCreativeType[]
  >(props.initialCreativeTypes ?? []);

  return (
    <>
      <ScrollView>
        {(
          ['Any', ...CREATIVE_TYPES] as
            | ['Any', ...CreativeConnectProjectCreativeType[]]
        ).map((creative_type) => {
          let title;

          switch (creative_type) {
            case 'Any':
              title = 'Any';
              break;
            case CreativeConnectProjectCreativeType.Art:
              title = 'Art';
              break;
            case CreativeConnectProjectCreativeType.Film:
              title = 'Film';
              break;
            case CreativeConnectProjectCreativeType.Game:
              title = 'Game';
              break;
            case CreativeConnectProjectCreativeType.Photography:
              title = 'Photography';
              break;
            case CreativeConnectProjectCreativeType.Podcast:
              title = 'Podcast';
              break;
            default:
              creative_type satisfies never;
          }

          if (!title) return;

          const isSelected: boolean =
            creative_type === 'Any'
              ? creativeTypes.length === 0
              : !!creativeTypes?.includes(creative_type);

          return (
            <RadioOption
              key={creative_type}
              title={title}
              isSelected={isSelected}
              onPress={() => {
                let newCreativeType: CreativeConnectProjectCreativeType[] = [];

                if (creativeTypes && isSelected) {
                  if (creative_type !== 'Any')
                    newCreativeType = creativeTypes.filter(
                      (ct) => ct !== creative_type,
                    );
                } else {
                  if (creative_type === 'Any') newCreativeType = [];
                  else
                    newCreativeType = [...(creativeTypes ?? []), creative_type];
                }

                setCreativeTypes(newCreativeType);
              }}
            />
          );
        })}
      </ScrollView>
      <Button
        label="Apply"
        onPress={() => {
          props.onRequestClose(creativeTypes);
        }}
        style={{
          backgroundColor: KEY_GREEN,
        }}
        containerStyle={{
          alignSelf: 'flex-end',
        }}
      />
    </>
  );
}
