import { UpdateResolver } from '@urql/exchange-graphcache';
import { GetUserBigIssuesDocument } from '/generated/graphql';

const createBigIssue: UpdateResolver = (result, args, cache) => {
  const { userId } = args;

  cache.invalidate('User', 'big_issues', {
    id: userId,
  });

  const query = GetUserBigIssuesDocument;

  const draftValues = [true, false, undefined];

  draftValues.forEach((draft) => {
    const variables: any = { userId };

    if (draft !== undefined) variables.draft = draft;

    cache.updateQuery({ query, variables }, (data) => {
      const issues = [...((data?.getBigIssues || []) as any[])];

      issues.push(result.createBigIssue);

      return {
        getBigIssues: issues,
      } as any;
    });
  });
};

export default createBigIssue;
