import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useAuthContext } from '/context';
import { KEY_GREEN } from '/constants';
import Button from '/components/Button';
import { useNavigation } from '@react-navigation/native';

export default function OrgSurveyCheckCTA() {
  const { userData } = useAuthContext();
  const { navigate } = useNavigation<any>();

  const surveyLink = () => {
    navigate('OrgDataSurveySetup');
  };

  return userData?.application?.completed_survey === false ? (
    <View style={styles.container}>
      <Button
        label="FINISH SET-UP"
        containerStyle={{
          alignSelf: 'flex-end',
          marginTop: 8,
        }}
        style={{
          backgroundColor: KEY_GREEN,
        }}
        labelStyle={{
          fontSize: 15,
        }}
        onPress={surveyLink}
      />
    </View>
  ) : null;
}

const styles = StyleSheet.create({
  container: { flex: 1 },
});
