import { signOut } from 'aws-amplify/auth';
import * as Linking from 'expo-linking';
import * as Updates from 'expo-updates';
import React from 'react';
import {
  Dimensions,
  ImageBackground,
  Platform,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';

import { KEY_GREEN } from '../constants';

const Fallback = ({ resetError }: { resetError: () => void }) => {
  const { width: window_width, height: window_height } = useWindowDimensions();

  const circleSize = Math.min(window_width, window_height);

  return (
    <View
      style={{
        width: '100%',
        height: '100%',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ImageBackground
        source={require('/assets/images/greatgreen_medium.jpg')}
        style={{
          flex: 1,
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        resizeMode={'cover'}
      >
        <View
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            borderRadius: circleSize / 2,
            width: circleSize * 0.95,
            height: circleSize * 0.95,
            maxWidth: 480,
            maxHeight: 480,
            justifyContent: 'center',
            alignItems: 'center',
            margin: 12,
            paddingHorizontal: 28,
          }}
        >
          <Text
            style={{
              fontFamily: 'LeagueSpartan-Bold',
              marginBottom: '1%',
              textAlign: 'center',
              color: '#fafafa',
              fontSize: 38,
              fontWeight: '500',
            }}
          >
            OH NO!
          </Text>
          <Text
            style={{
              fontFamily: 'Lato-Bold',
              textAlign: 'center',
              color: '#fafafa',
              fontSize: window_height > 700 ? 17 : 15,
              fontWeight: '500',
            }}
          >
            Something unexpected has occured.{` `}
            {Platform.OS === 'web'
              ? 'Please refresh the page.'
              : 'Please close and restart the app'}
          </Text>
          <Text
            style={{
              fontFamily: 'Lato-Bold',
              textAlign: 'center',
              color: '#fafafa',
              fontSize: window_height > 700 ? 17 : 15,
              fontWeight: '500',
            }}
          >
            If you continue to have issues or would like to tell us what
            happened before this error please send an email to our development
            team directly at development@keyconservation.org. Please include as
            much detail as possible.{`\n\n`}Thank you,{`\n`}Team Key
          </Text>
        </View>
        {Platform.OS === 'ios' && (
          <TouchableOpacity
            style={{ marginBottom: '5%' }}
            onPress={async () => {
              resetError();
              await Updates.reloadAsync();
            }}
          >
            <View
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: KEY_GREEN,
                width: Dimensions.get('window').width * 0.9,
                height: Dimensions.get('window').height * 0.06,
                borderRadius: 10,
              }}
            >
              <Text
                style={{
                  fontFamily: 'Lato-Bold',
                  textAlign: 'center',
                  color: 'black',
                  fontWeight: '500',
                  fontSize: 20,
                  opacity: 0.75,
                }}
              >
                RESTART APP
              </Text>
            </View>
          </TouchableOpacity>
        )}
        {Platform.OS !== 'web' ? (
          <>
            <TouchableOpacity
              style={{ marginBottom: 12 }}
              onPress={() =>
                Linking.openURL('mailto:development@keyconservation.com')
              }
            >
              <View
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#fafafa',
                  width: Dimensions.get('window').width * 0.9,
                  height: Dimensions.get('window').height * 0.06,
                  maxWidth: 480,
                  maxHeight: 64,
                  borderRadius: 10,
                }}
              >
                <Text
                  style={{
                    fontFamily: 'Lato-Bold',
                    textAlign: 'center',
                    color: 'black',
                    fontWeight: '500',
                    fontSize: 20,
                    opacity: 0.75,
                  }}
                >
                  EMAIL TEAM KEY
                </Text>
              </View>
            </TouchableOpacity>

            <TouchableOpacity
              style={{ marginBottom: '5%' }}
              onPress={async () => {
                await signOut();
                await Updates.reloadAsync();
              }}
            >
              <View
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#fafafa',
                  width: Dimensions.get('window').width * 0.9,
                  height: Dimensions.get('window').height * 0.06,
                  maxWidth: 480,
                  maxHeight: 64,
                  borderRadius: 10,
                }}
              >
                <Text
                  style={{
                    fontFamily: 'Lato-Bold',
                    textAlign: 'center',
                    color: 'black',
                    fontWeight: '500',
                    fontSize: 20,
                    opacity: 0.75,
                  }}
                >
                  SIGN OUT
                </Text>
              </View>
            </TouchableOpacity>
          </>
        ) : null}
      </ImageBackground>
    </View>
  );
};

export default Fallback;
