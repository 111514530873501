import React from 'react';
import CreativeConnectProjectCard from '/components/CreativeConnect/CreativeConnectProjectCard/CreativeConnectProjectCard';
import ListLoading from '/components/ListLoading';
import GenericError from '/components/common/Generic/GenericError';
import {
  CampaignConnectInvite,
  useGetCreativeConnectProjectPreviewQuery,
} from '/generated/graphql';
import getCreativeConnectLinkablePostType from '/util/creative-connect/getCreativeConnectLinkablePostType';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import CreativeConnect from '/assets/jsicons/CreativeConnect';
import { KEY_GRAY } from '/constants';
import { Text } from 'react-native';

interface ICampaignConnectInvite
  extends Pick<CampaignConnectInvite, 'creativeConnectProjectId'> {}

type LinkablePostPreviewProps = {
  invite: ICampaignConnectInvite;
};

export default function CampaignConnectLinkablePostPreview(
  props: LinkablePostPreviewProps,
) {
  const [ccProjectQuery, getCreativeConnectProject] =
    useGetCreativeConnectProjectPreviewQuery({
      variables: {
        getCreativeConnectProjectId: props.invite.creativeConnectProjectId!,
      },
      pause: !props.invite.creativeConnectProjectId,
    });

  const linkablePostTypeKey = getCreativeConnectLinkablePostType(props.invite);
  if (!linkablePostTypeKey) return null;

  switch (linkablePostTypeKey) {
    case 'creativeConnectProjectId': {
      return ccProjectQuery.fetching ? (
        <ListLoading />
      ) : ccProjectQuery.error ? (
        <GenericError
          onRetry={getCreativeConnectProject}
          message="Error fetching Creative Connect project preview"
        />
      ) : !ccProjectQuery.data?.getCreativeConnectProject ? null : (
        <>
          <HorizontalContainer style={{ marginBottom: 4 }}>
            <CreativeConnect width={18} height={18} />
            <Text
              style={{
                fontFamily: 'LeagueSpartan-Bold',
                fontSize: 17,
                marginLeft: 4,
                marginTop: 2,
                color: KEY_GRAY,
              }}
            >
              CREATIVE CONNECT PROJECT
            </Text>
          </HorizontalContainer>
          <CreativeConnectProjectCard
            theme="light"
            data={ccProjectQuery.data?.getCreativeConnectProject}
            displayCreativeTypeAndStatus
          />
        </>
      );
    }
    default: {
      console.error(
        `[CampaignConnectInviteCTAModal] Unhandeled linkable post type: ${linkablePostTypeKey}`,
      );
      return null;
    }
  }
}
