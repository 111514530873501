import { StyleSheet, Dimensions } from 'react-native';
import { responsiveFontSize } from 'react-native-responsive-dimensions';

export default StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  obBody: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: Dimensions.get('window').width,
    width: Math.min(Dimensions.get('window').width * 0.95, 540),
    height: Math.min(Dimensions.get('window').width * 0.95, 540),
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    padding: 24,
  },
  obBodyWrapper: {
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '80%',
    width: '100%',
    // backgroundColor: 'red',
  },
  image: {
    height: Dimensions.get('screen').height * 0.5,
  },
  titleTexts: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  obText: {
    color: '#ffffff',
    fontFamily: 'Lato-Bold',
    textAlign: 'center',
    fontSize: 24,
    lineHeight: 25,
  },
  obTitle: {
    marginTop: '20%',
    textAlign: 'center',
    color: '#ffffff',
    fontFamily: 'LeagueSpartan-Bold',
    textTransform: 'uppercase',
    fontSize: responsiveFontSize(5.5),
    lineHeight: 45,
  },
  buttons: {
    width: '100%',
    // flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 40,
  },
  buttonTouch: {
    width: '95%',
    maxWidth: 350,
    height: 50,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    color: '#000000',
    fontFamily: 'LeagueSpartan-Bold',
    textTransform: 'uppercase',
    fontSize: 28,
  },
  white: { backgroundColor: '#F5F5F5' },
  green: { backgroundColor: '#00FE9D' },
});
