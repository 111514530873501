import { Resolver, Variables } from '@urql/exchange-graphcache';
import { stringifyVariables } from 'urql';

const openSearchPagination = (fieldTypename = ''): Resolver => {
  const compareArgs = (
    fieldArgs: Variables,
    connectionArgs: Variables | null,
    strict = false,
  ): boolean => {
    // Ignore the keys we use to paginate
    let ignoreKeys = ['from', 'size'];

    /** If we want to skip pagination, then we can disable merging by not ignoring any different arguments */
    if (strict) {
      // console.log('compareArgs skip merge');
      ignoreKeys = [];
    }

    for (const key in connectionArgs) {
      if (ignoreKeys.includes(key)) {
        continue;
      } else if (!(key in fieldArgs)) {
        return false;
      }

      const argA = fieldArgs[key];
      const argB = connectionArgs[key];

      if (
        typeof argA !== typeof argB || typeof argA !== 'object'
          ? argA !== argB
          : stringifyVariables(argA) !== stringifyVariables(argB)
      ) {
        return false;
      }
    }

    for (const key in fieldArgs) {
      if (ignoreKeys.includes(key)) {
        continue;
      }
      if (!(key in (connectionArgs ?? {}))) return false;
    }

    return true;
  };

  return (result, fieldArgs, cache, info) => {
    const { parentKey: entityKey, fieldName } = info;
    const allFields = cache.inspectFields(entityKey);
    const fieldInfos = allFields.filter(
      (_info) => _info.fieldName === fieldName,
    );
    const size = fieldInfos.length;

    if (size === 0) {
      return;
    }

    let total: number = 0;
    let species: string[] = [];

    fieldInfos.forEach((fi) => {
      const { fieldKey, arguments: args } = fi;

      // Compare args
      if (
        fieldArgs === null ||
        !compareArgs(fieldArgs, args, !!info.variables.skipMerge)
      ) {
        // Don't merge if non-pagination related arguments are different
        return;
      }

      const key = cache.resolve(entityKey, fieldKey) as string;
      const data = cache.resolve(key, 'species') as string[];

      total = cache.resolve(key, 'total') as number;

      species.push(...(data ?? []));
    });

    // In the case fieldTypename is not provided, we can try to infer it from `result`
    // const typename = fieldTypename || (result[fieldName] as any)?.__typename;
    const typename = fieldTypename;

    // Remove duplicates if any
    const set = new Set(species);
    species = Array.from(set);

    return {
      __typename: typename,
      total,
      species,
    };
  };
};

export default openSearchPagination;
