import React from 'react';
import {
  Animated,
  Easing,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

interface ILoadingSpinnerOverlayProps {
  style?: StyleProp<ViewStyle>;
}

export default class LoadingSpinnerOverlay extends React.Component<ILoadingSpinnerOverlayProps> {
  spinValue: Animated.Value;

  constructor(props: ILoadingSpinnerOverlayProps) {
    super(props);

    this.spinValue = new Animated.Value(0);
  }

  componentDidMount() {
    this.spin();
  }

  spin() {
    this.spinValue.setValue(0); //set spinValue to 0 (zero)
    Animated.timing(this.spinValue, {
      toValue: 1,
      duration: 4000,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start(() => this.spin());
  }

  render() {
    const spin = this.spinValue.interpolate({
      inputRange: [0, 1],
      outputRange: ['0deg', '360deg'],
    });
    return (
      <View
        style={[
          StyleSheet.absoluteFill,
          {
            zIndex: 99,
            backgroundColor: 'rgba(40, 40, 40, 0.7)',
            alignItems: 'center',
            justifyContent: 'center',
          },
          this.props.style,
        ]}
      >
        <Animated.Image
          testID="LoadingSpinnerOverlay"
          style={{
            width: 100,
            height: 100,
            transform: [{ rotate: spin }],
          }}
          source={require('../assets/images/keyFullBlack.png')}
        />
      </View>
    );
  }
}
