import React from 'react';
import { StyleProp, Text, TextStyle } from 'react-native';
import { CARD_TITLE_FONT_SIZE } from '/constants';

interface Props {
  style?: StyleProp<TextStyle>;
  numberOfLines?: number;
}

export default function SectionTitle(props: React.PropsWithChildren<Props>) {
  return (
    <Text style={[style, props.style]} numberOfLines={props.numberOfLines}>
      {props.children}
    </Text>
  );
}

const style: TextStyle = {
  flexShrink: 1,
  fontFamily: 'LeagueSpartan-Bold',
  fontSize: CARD_TITLE_FONT_SIZE,
  marginBottom: 6,
  letterSpacing: 0.3,
};
