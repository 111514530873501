import { FontAwesome } from '@expo/vector-icons';
import { useMemo, useState } from 'react';
import { ActivityIndicator, LayoutChangeEvent, Text, View } from 'react-native';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  maxSize,
  minSize,
} from 'rn-responsive-styles';
import Alert from '/Alert';
import Button from '/components/Button';
import UploadMedia from '/components/UploadMedia/UploadMedia';
import { KEY_GRAY, KEY_GREEN, OFFWHITE } from '/constants';
import { useAuthContext, useTeamContext } from '/context';
import { TeamMemberRole, User, UserRole } from '/generated/graphql';

type Data = Pick<User, 'cover_image' | 'cover_image_thumbnail' | 'role' | 'id'>;

interface IProfileToolbarProps {
  data: Data | undefined;
  isEditing: boolean;
  isSaving: boolean;
  onLayout: (event: LayoutChangeEvent) => void;
  setData: (data: Partial<Data>) => void;
  setBusy: (busy: boolean) => void;
  onEditProfile: () => void;
  onSaveChanges: () => void;
  onDiscardChanges: () => void;
}

export default function ProfileToolbar(props: IProfileToolbarProps) {
  const { userData } = useAuthContext();
  const { teams } = useTeamContext();

  const [uploadingCoverImage, setUploadingCoverImage] = useState(false);

  const { styles, deviceSize } = useStyles();

  const shouldRender = useMemo(() => {
    const isAdminForTarget = teams.some((team) => {
      return (
        team.user.id === props.data?.id &&
        team.membership?.team_role === TeamMemberRole.Admin
      );
    });

    return props.data?.id === userData?.id || isAdminForTarget;
  }, [props.data?.id, userData?.id, teams]);

  return shouldRender ? (
    <View
      onLayout={props.onLayout}
      pointerEvents={props.isSaving ? 'none' : 'box-none'}
      style={styles('container')}
    >
      <View style={styles('buttonSectionLeft')}>
        {props.isEditing && (
          <UploadMedia
            mediaType="All"
            media={[
              {
                uri: props.data?.cover_image ?? '',
                thumbnailUri: props.data?.cover_image_thumbnail ?? '',
              },
            ]}
            onChangeMedia={(items) => {
              const media = items[0];

              props.setData({
                cover_image: media?.uri,
                cover_image_thumbnail: media?.thumbnailUri,
              });
            }}
            targetMediaDimensions={{
              width: 216,
              height: 384,
            }}
            onUploadStart={() => {
              setUploadingCoverImage(true);
              props.setBusy(true);
            }}
            onUploadEnd={() => {
              setUploadingCoverImage(false);
              props.setBusy(false);
            }}
            removable={props.data?.role === UserRole.Supporter}
            onError={(errorMessage) => {
              Alert.alert('Error', errorMessage);
            }}
            renderComponent={(pressed) => (
              <Button
                pointerEvents="none"
                style={{
                  opacity: pressed ? 0.68 : 1,
                }}
                label={
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <View
                      style={{
                        width: 24,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {uploadingCoverImage ? (
                        <ActivityIndicator
                          size={10}
                          color={KEY_GRAY}
                          style={{
                            marginRight: 4,
                          }}
                        />
                      ) : (
                        <FontAwesome
                          size={17}
                          style={{
                            marginRight: 6,
                          }}
                          name="image"
                        />
                      )}
                    </View>
                    <Text style={styles('buttonLabel')}>
                      {uploadingCoverImage
                        ? 'Uploading'
                        : props.data?.cover_image
                        ? 'Change'
                        : 'Add'}{' '}
                      cover
                    </Text>
                  </View>
                }
              />
            )}
          />
        )}
      </View>
      <View style={styles('buttonSectionRight')}>
        {props.isEditing ? (
          <>
            <Button
              disabled={props.isSaving}
              onPress={props.onSaveChanges}
              label={
                deviceSize === DEVICE_SIZES.EXTRA_SMALL_DEVICE
                  ? 'Save'
                  : 'Save Changes'
              }
              style={{
                backgroundColor: KEY_GREEN,
              }}
              labelStyle={styles('buttonLabel')}
              containerStyle={styles('button')}
            />
            <Button
              disabled={props.isSaving}
              onPress={props.onDiscardChanges}
              label="Cancel"
              labelStyle={styles('buttonLabel')}
              containerStyle={styles('button')}
            />
          </>
        ) : (
          <Button
            labelStyle={styles('buttonLabel')}
            onPress={props.onEditProfile}
            label="Edit Profile"
          />
        )}
        {props.isSaving && (
          <View style={styles('savingContainer')}>
            <ActivityIndicator size={12} />
            <Text style={styles('savingText')}>Saving...</Text>
          </View>
        )}
      </View>
    </View>
  ) : (
    <View onLayout={props.onLayout} />
  );
}

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 8,
      backgroundColor: OFFWHITE,
    },
    button: {
      marginHorizontal: 4,
    },
    buttonLabel: {
      fontFamily: 'Lato-Bold',
      fontSize: 16,
    },
    buttonSectionLeft: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      pointerEvents: 'box-none',
    },
    buttonSectionRight: {
      flex: 1,
      flexDirection: 'row-reverse',
      alignItems: 'center',
      pointerEvents: 'box-none',
    },
    savingContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    savingText: {
      fontFamily: 'LeagueSpartan-Bold',
      fontSize: 18,
      color: KEY_GRAY,
      marginHorizontal: 8,
    },
  },
  {
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      buttonLabel: {
        fontSize: 15,
      },
    },
    [minSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      buttonLabel: {
        fontSize: 18,
      },
    },
  },
);
