import { StyleSheet } from 'react-native';
import { KEY_GRAY } from '/constants';

export default StyleSheet.create({
  loadingText: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
    color: KEY_GRAY,
    textAlign: 'center',
    marginVertical: 16,
  },
  errorText: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
    color: 'crimson',
    textAlign: 'center',
  },
  campaignPreviewContainer: {
    overflow: 'hidden',
    borderRadius: 6,
    margin: 8,
    marginTop: 0,
    backgroundColor: 'white',
  },
  campaignContainer: {
    paddingHorizontal: 8,
  },
  campaignGridTileImageContainer: {
    borderRadius: 6,
    overflow: 'hidden',
  },
  campaignGridTileTitle: {
    marginTop: 2,
    fontFamily: 'Lato-Bold',
    fontSize: 18,
    textTransform: 'uppercase',
  },
  campaignTileUserName: {
    fontFamily: 'Lato-Bold',
    fontSize: 14,
    color: KEY_GRAY,
  },
  urgencyBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    height: 32,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  urgencyBarText: {
    fontFamily: 'Lato-Bold',
    fontSize: 14,
    letterSpacing: 2,
    color: 'black',
  },
  campaignTileSupportRequestLabel: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
  },
});
