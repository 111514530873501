import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  placeholderContainer: {
    padding: 24,
    marginTop: 48,
    alignSelf: 'center',
    alignItems: 'center',
  },
  loadingText: {
    fontFamily: 'Lato-Bold',
    color: 'gray',
    fontSize: 15,
    marginTop: 8,
  },
  errorText: {
    fontFamily: 'Lato-Bold',
    color: 'crimson',
    fontSize: 15,
    marginBottom: 8,
  },
});
