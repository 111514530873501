import React, { useEffect, useState } from 'react';
import { Platform, Text, View } from 'react-native';
import SkillSelect from '../../SkillSelect';
import { CampaignBuilderComponentProps } from '../CampaignBuilder';
import Alert from '/Alert';
import sharedStyles from '/constants/CampaignBuilder';
import styles from '/constants/CampaignBuilder/SkillImpact/SkillExpand';

import { KeyboardAwareScrollView } from '@mtourj/react-native-keyboard-aware-scroll-view';
import Sync from '/assets/jsicons/bottomnavigation/Sync';

import CampaignBuilderFooter from '../components/CampaignBuilderFooter';
import { SkillRequest } from '/generated/graphql';

const SelectSkillsContent = (props: CampaignBuilderComponentProps) => {
  const [skillImpactRequests, setSkillImpactRequests] = useState(
    new Map<string, Partial<SkillRequest>>(),
  );

  const onChangeSkillImpactRequest = (skills: string[]) => {
    const newMap = new Map(skillImpactRequests);

    if (skills.length > Array.from(newMap.keys()).length) {
      skills.map((skill) => {
        if (!newMap.has(skill)) {
          const existingRequest = props.data?.skilled_impact_requests?.find(
            (req) => {
              return req.skill === skill;
            },
          );

          const skillImpactRequestJSON = {
            id: existingRequest?.id,
            due_date:
              existingRequest?.due_date ??
              new Date(Date.now() + 1000 * 60 * 60 * 2),
            campaign_id: props.data?.campaign.id,
            skill,
            preferred_languages: existingRequest?.preferred_languages ?? [],
            required_languages: existingRequest?.required_languages ?? [],
            expertise_required: existingRequest?.expertise_required ?? false,
            expertise: existingRequest?.expertise ?? [],
            goals: existingRequest?.goals ?? [],
            any_language_apply: existingRequest?.any_language_apply ?? false,
            our_contribution: existingRequest?.our_contribution ?? '',
          };
          newMap.set(skill, skillImpactRequestJSON);
        }
      });
    } else {
      Array.from(newMap.keys()).map((skill) => {
        if (!skills.includes(skill)) {
          newMap.delete(skill);
        }
      });
    }

    setSkillImpactRequests(newMap);

    // Update parent state
    // ** This allows only one skill request per campaign **

    const requests = Array.from(newMap.values());

    props.setData({
      skilled_impact_requests: requests.length ? [requests[0]] : [],
    });
  };

  // This side effect is responsible for making sure skillImpactRequests
  // is updated if we receive a new skilled_impact_requests in props.data
  useEffect(() => {
    if (props.data?.skilled_impact_requests?.length) {
      setSkillImpactRequests(
        new Map(
          props.data.skilled_impact_requests?.map((req) => [req.skill, req]),
        ),
      );
    }
  }, [props.data?.skilled_impact_requests]);

  return (
    <>
      <KeyboardAwareScrollView
        style={{
          paddingTop: 120,
        }}
        contentContainerStyle={[
          sharedStyles.scrollView,
          { paddingBottom: Platform.OS === 'web' ? 0 : 120, paddingTop: 8 },
        ]}
      >
        <View
          style={[
            styles.itemFooterRow,
            { backgroundColor: 'white', flexDirection: 'column' },
          ]}
        >
          <View style={sharedStyles.titleWithIconContainer}>
            <View
              style={{
                paddingRight: 8,
              }}
            >
              <Sync />
            </View>
            <Text style={[sharedStyles.subheading, { fontSize: 21 }]}>
              Skilled Impact
            </Text>
          </View>
          <Text style={[sharedStyles.subheading, { fontSize: 17 }]}>
            Select the skill you need help with. At this time only 1 skill can
            be selected per campaign.
          </Text>
        </View>
        <View
          style={[
            styles.itemContentRows,
            { backgroundColor: 'white', justifyContent: 'center' },
          ]}
        >
          <SkillSelect
            skills={Array.from(skillImpactRequests.keys())}
            onlyOne={true}
            onSkillsChanged={(skills) => {
              onChangeSkillImpactRequest(skills);
            }}
          />
        </View>
      </KeyboardAwareScrollView>
      <CampaignBuilderFooter
        {...props}
        onNext={() => props.next()}
        validateForm={() => {
          if (skillImpactRequests.size > 0) {
            return true;
          } else {
            Alert.alert(
              'No skills selected',
              'Please select the skill(s) you need from the list to proceed.',
            );
            return false;
          }
        }}
      />
    </>
  );
};

export default SelectSkillsContent;
