import { AntDesign, FontAwesome } from '@expo/vector-icons';
import React, { useEffect, useRef, useState } from 'react';
import {
  ActivityIndicator,
  FlatList,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import Avatar from '/components/Avatar';
import Button from '/components/Button';
import TextInputWithIcon from '/components/TextInputWithIcon';
import { KEY_GRAY, TEXT_INPUT } from '/constants';
import { useAuthContext, useModalContext, useTeamContext } from '/context';
import { useGetTeamQuery, User, UserRole } from '/generated/graphql';

interface IAuthor extends Pick<User, 'id' | 'name' | 'profile_image'> {}

interface Props {
  onRemoveAuthor: (id: string) => void;
  onAddAuthor: (author: IAuthor) => void;
  authors: IAuthor[] | undefined | null;
}

export default function AddAuthors({
  onAddAuthor: _onAddAuthor,
  ...props
}: Props) {
  const { spawnModal, closeModal: _closeModal } = useModalContext();
  const closeModal = () => {
    modalId.current && _closeModal(modalId.current);
    modalId.current = null;
  };

  const { userData } = useAuthContext();

  const modalId = useRef<string | null>(null);

  useEffect(() => {
    if (!userData) return;

    if (
      userData?.role !== UserRole.Conservationist &&
      !props.authors?.some((author) => author.id === userData?.id)
    ) {
      _onAddAuthor({
        id: userData.id,
        name: userData.name,
        profile_image: userData.profile_image,
      });
    }
  }, [props.authors, userData, _onAddAuthor]);

  function onRemoveAuthor(id: string) {
    props.onRemoveAuthor?.(id);

    closeModal();
  }

  function onAddAuthor(author: IAuthor) {
    _onAddAuthor?.(author);

    closeModal();
  }

  function onAddAuthors() {
    modalId.current = spawnModal({
      title: 'Add Authors',
      style: {
        minWidth: 300,
        minHeight: 400,
      },
      component: (
        <AddAuthorsModalComponent
          authors={props.authors ?? []}
          onAddAuthor={onAddAuthor}
        />
      ),
    });
  }

  return (
    <View>
      {props.authors?.length ? (
        props.authors.map((author) => {
          return (
            <View key={author.id} style={styles.authorCard}>
              <Avatar
                source={{ uri: author.profile_image ?? '' }}
                rounded
                size={48}
              />
              <Text style={styles.authorCardName}>{author.name}</Text>
              <Button
                disabled={author.id === userData?.id}
                containerStyle={{
                  marginLeft: 6,
                }}
                label={<FontAwesome name="trash-o" size={24} color="black" />}
                onPress={() => onRemoveAuthor(author.id)}
              />
            </View>
          );
        })
      ) : (
        <Text style={styles.noAuthorsText}>No team members mentioned</Text>
      )}

      <Button
        label="Add authors"
        containerStyle={{
          marginTop: 4,
        }}
        onPress={onAddAuthors}
      />
    </View>
  );
}

interface AddAuthorsModalProps {
  authors: IAuthor[];
  onAddAuthor: (author: IAuthor) => void;
}

function AddAuthorsModalComponent(props: AddAuthorsModalProps) {
  const { activeTeam } = useTeamContext();

  const [query, setQuery] = useState('');

  const [{ data, fetching, error }] = useGetTeamQuery({
    variables: { teamId: activeTeam?.id as string },
    pause: !activeTeam?.id,
  });

  return (
    <View style={modalStyles.container}>
      <TextInputWithIcon
        icon={
          <View
            style={{
              paddingHorizontal: 4,
              height: '100%',
              flexDirection: 'row',
              alignSelf: 'center',
            }}
          >
            <AntDesign
              style={{
                alignSelf: 'center',
              }}
              name="search1"
              size={24}
              color={KEY_GRAY}
            />
          </View>
        }
        style={TEXT_INPUT}
        placeholder="Search members..."
        placeholderTextColor="gray"
        value={query}
        onChangeText={setQuery}
      />
      {fetching ? (
        <ActivityIndicator
          style={modalStyles.activityIndicator}
          color={KEY_GRAY}
        />
      ) : error ? (
        <Text style={modalStyles.errorText}>
          There was a problem fetching members of your team
        </Text>
      ) : (
        <FlatList
          data={
            data?.getTeam.members.items.filter(
              (author) => !query?.trim || author.user.name.includes(query),
            ) ?? []
          }
          ListEmptyComponent={
            <Text style={modalStyles.emptyText}>
              {query && data?.getTeam.members.total
                ? 'No results'
                : "You don't have any team members, so you cannot add any authors"}
            </Text>
          }
          style={{ flexGrow: 0 }}
          renderItem={({ item }) => {
            const authorAlreadyAdded = props.authors.some(
              (author) => author.id === item.user.id,
            );

            return (
              <AuthorMemberCard
                onAddAuthor={props.onAddAuthor}
                user={item.user}
                alreadyAdded={authorAlreadyAdded}
              />
            );
          }}
        />
      )}
    </View>
  );
}

const modalStyles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
  },
  memberCard: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    paddingVertical: 12,
  },
  memberCardRightContainer: {
    paddingLeft: 8,
    flex: 1,
  },
  memberName: {
    fontFamily: 'Lato-Bold',
    fontSize: 18,
    paddingVertical: 4,
  },
  activityIndicator: {
    padding: 24,
    alignSelf: 'center',
  },
  errorText: {
    fontFamily: 'Lato-Bold',
    padding: 24,
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: 18,
    color: 'crimson',
  },
  emptyText: {
    fontFamily: 'Lato-Bold',
    padding: 24,
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: 18,
    color: 'gray',
  },
});

interface AuthorMemberCardProps {
  alreadyAdded: boolean;
  user: IAuthor;
  onAddAuthor: (member: IAuthor) => void;
}

function AuthorMemberCard(props: AuthorMemberCardProps) {
  return (
    <View style={modalStyles.memberCard}>
      <Avatar
        source={{ uri: props.user.profile_image ?? '' }}
        rounded
        size={56}
      />
      <View style={modalStyles.memberCardRightContainer}>
        <Text style={modalStyles.memberName}>{props.user.name}</Text>
        <Button
          disabled={props.alreadyAdded}
          label={props.alreadyAdded ? 'Added' : 'Add Author'}
          onPress={() => props.onAddAuthor(props.user)}
          containerStyle={{
            alignSelf: 'flex-end',
            width: '100%',
            maxWidth: 300,
          }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  authorCard: {
    padding: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
  authorCardName: {
    flex: 1,
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    padding: 6,
  },
  noAuthorsText: {
    fontFamily: 'Lato',
    color: 'gray',
    textAlign: 'center',
    padding: 16,
    alignSelf: 'center',
    fontSize: 16,
  },
});
