import { useNavigation } from '@react-navigation/native';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import * as Device from 'expo-device';
import { Subscription } from 'expo-media-library';
import * as Notifications from 'expo-notifications';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Platform } from 'react-native';
import { useAuthContext } from '/context/AuthProvider';
import {
  Notification,
  useRegisterExpoPushTokenMutation,
  useTotalUnreadNotificationsSubscription,
  useUnregisterExpoPushTokenMutation,
} from '/generated/graphql';
import { navigationRef } from '/navigation';
import { DeepPartial } from '/types';
import { snakeToPascal } from '/util';
import { handleNotificationPress } from '/util/notifications';

const LOGGING_ENABLED = false;

type NotificationContextType = {
  unread_notifications: number;
};

export const NotificationContext = React.createContext<NotificationContextType>(
  {} as any,
);

export const useNotificationContext = () =>
  React.useContext(NotificationContext);

export default function NotificationProvider(
  props: React.PropsWithChildren<{}>,
) {
  const [expoPushToken, setExpoPushToken] = useState('');

  const notificationListener = useRef<Subscription>();
  const responseListener = useRef<Subscription>();
  const { userAttributes, userData } = useAuthContext();
  const pushTokenRegistered = useRef(false);

  const [unreadNotificationsOffset, setUnreadNotificationsOffset] = useState(0);

  const [totalUnreadNotificationsSubscription] =
    useTotalUnreadNotificationsSubscription({
      /** Only use this on the web because native devices
       * can use the expo push notification handler instead */
      pause: Platform.OS !== 'web',
    });

  const [{ fetching: registering }, registerExpoPushToken] =
    useRegisterExpoPushTokenMutation();
  const [, unregisterExpoPushToken] = useUnregisterExpoPushTokenMutation();

  const navigation = useNavigation();

  useEffect(() => {
    /** Web only */
    if (Platform.OS !== 'web') return;

    const newOffset = Math.max(
      0,
      (totalUnreadNotificationsSubscription.data?.totalUnreadNotifications ||
        0) - (userData?.unread_notifications || 0),
    );

    setUnreadNotificationsOffset(newOffset);
  }, [
    totalUnreadNotificationsSubscription.data?.totalUnreadNotifications,
    userData?.unread_notifications,
  ]);

  useEffect(() => {
    /** No push notifications on web */
    if (Platform.OS === 'web') return;

    if (
      !expoPushToken ||
      !userAttributes?.sub ||
      pushTokenRegistered.current ||
      registering
    )
      return;

    const registerPushToken = async () => {
      logMessage('Registering push token');

      const IDToken = (await fetchAuthSession()).tokens?.idToken?.toString();

      if (!IDToken) return;

      registerExpoPushToken(
        { token: expoPushToken },
        { fetchOptions: { headers: { Authorization: IDToken } } },
      ).then(({ error }) => {
        if (error) {
          console.warn('Error registering expo push token', error);

          return;
        }
        logMessage('Successfully registered expo push token');

        pushTokenRegistered.current = true;
      });
    };

    registerPushToken();
  }, [userAttributes, expoPushToken, registering, registerExpoPushToken]);

  useEffect(() => {
    /** If userData.unread_notifications changes, reset unreadNotificationsOffset */
    setUnreadNotificationsOffset(0);
  }, [userData?.unread_notifications]);

  function logMessage(message: string, level: 'log' | 'warn' = 'log') {
    if (LOGGING_ENABLED || level === 'warn')
      console[level](`[NotificationProvider] ${message}`);
  }

  const handleAuthEvents = useCallback(
    ({ payload: { event, data } }: any) => {
      switch (event) {
        // case 'signIn':
        //   setSignedOut(false);
        //   break;
        case 'signOut':
          // On signout, unregister current expo push token from user
          logMessage('Unregistering push token');
          unregisterExpoPushToken(
            { token: expoPushToken },
            {
              fetchOptions: {
                headers: {
                  // Include authorization in case it was removed from URQL client already, which can happen
                  authorization: data?.signInUserSession?.idToken?.jwtToken,
                },
              },
            },
          ).then(({ error }) => {
            if (error) {
              logMessage(
                'Error unregistering expo push token\t' + error,
                'warn',
              );
            }
            logMessage('Successfully unregistered expo push token');
          });
          pushTokenRegistered.current = false; // Set this to false regardless of outcome of server request
          break;
      }
    },
    [expoPushToken, unregisterExpoPushToken],
  );

  const getShouldShowAlert = useCallback(() => {
    const route = navigationRef.current?.getCurrentRoute();

    switch (route?.name) {
      case 'DirectMessageScreen':
        return false;
      default:
        return true;
    }
  }, []);

  useEffect(() => {
    if (Platform.OS === 'web') return;

    /** Handle push notifications while foregrounded */
    Notifications.setNotificationHandler({
      handleNotification: async () => {
        /** Increment number unreadNotificationsOffset to reflect in tab bar instantly */
        setUnreadNotificationsOffset((prevState) => ++prevState);

        /** Still show the notification */
        return {
          shouldShowAlert: getShouldShowAlert(),
          shouldPlaySound: false,
          shouldSetBadge: true,
        };
      },
    });
  }, [getShouldShowAlert]);

  useEffect(() => {
    if (Platform.OS === 'web') return;

    const removeAuthEventListener = Hub.listen('auth', handleAuthEvents);

    registerForPushNotificationsAsync().then((token) => {
      if (token) setExpoPushToken(token);
    });

    // This listener is fired whenever a notification is received while the app is foregrounded
    // notificationListener.current =
    //   Notifications.addNotificationReceivedListener((notification) => {
    //     // TODO: Handle foreground notifications
    //   });

    // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
    responseListener.current =
      Notifications.addNotificationResponseReceivedListener((res) => {
        const notification = res?.notification?.request?.content
          ?.data as DeepPartial<Notification>;
        if (notification?.type) {
          handleNotificationPress(
            Object.assign(notification, {
              type: snakeToPascal(notification.type ?? ''),
            }),
            userAttributes?.sub as string,
            navigation.navigate,
          );
        }
      });

    return () => {
      if (notificationListener.current) {
        Notifications.removeNotificationSubscription(
          notificationListener.current,
        );
        notificationListener.current = undefined;
      }

      if (responseListener.current) {
        responseListener.current.remove();
        responseListener.current = undefined;
      }
      removeAuthEventListener();
    };
  }, [handleAuthEvents, navigation.navigate, userAttributes?.sub]);

  return (
    <NotificationContext.Provider
      value={{
        unread_notifications:
          (userData?.unread_notifications || 0) + unreadNotificationsOffset,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
}

async function registerForPushNotificationsAsync() {
  let token;
  if (Device.isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      // alert('Failed to get push token for push notification!');
      return;
    }
    token = (
      await Notifications.getExpoPushTokenAsync({
        projectId: '3c13b60c-a23b-4462-9bd2-ec964f01befc',
      })
    ).data;
    // console.log(token);
  } else {
    // alert("Must use physical device for Push Notifications");
  }

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

  return token;
}
