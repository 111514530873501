import { StyleSheet } from 'react-native';
import { KEY_LIGHT_GRAY } from '/constants';

export default StyleSheet.create({
  invitedByContainer: {
    borderRadius: 6,
    backgroundColor: KEY_LIGHT_GRAY,
    padding: 8,
    marginVertical: 8,
  },
  fieldTitle: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 18,
    textTransform: 'uppercase',
    marginTop: 12,
    marginBottom: 2,
  },
  invitedUserName: {
    flex: 1,
    fontFamily: 'Lato-Bold',
    fontSize: 16,
  },
});
