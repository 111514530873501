import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import CampaignFlatList from '../../CampaignFlatList/CampaignFlatList';
import { useCommonStyles } from '../styles';
import Avatar from '/components/Avatar';
import Button from '/components/Button';
import GridList from '/components/GridList';
import { KEY_GRAY, KEY_LIGHT_GRAY, SECTION_CONTAINER } from '/constants';
import { UserProfileFragmentFragment } from '/generated/graphql';

interface IUser
  extends Pick<UserProfileFragmentFragment, 'supporters_current'> {}

interface ICurrentCampaignsProps {
  isEditing: boolean;
  userId: string | undefined;
  data: IUser | undefined | null;
}

export default function CurrentCampaigns(props: ICurrentCampaignsProps) {
  const { push } = useNavigation<StackNavigationProp<any>>();

  const { styles: commonStyles } = useCommonStyles();

  const onViewAllCampaigns = () => {
    push('ListCampaigns', {
      page: 'profile',
      filter: {
        userId: props.userId,
        closed: false,
      },
    });
  };

  function goToProfile(id: string) {
    if (!id) return;

    push('Profile', { id });
  }

  return (
    <View style={styles.container}>
      <View
        style={[
          SECTION_CONTAINER,
          { flex: undefined, opacity: props.isEditing ? 0.6 : 1 },
        ]}
      >
        <Text style={commonStyles('sectionTitle')}>OUR CURRENT CAMPAIGNS</Text>
        <Text style={commonStyles('sectionText')}>
          {props.isEditing
            ? 'Your current campaigns will appear here...'
            : 'Check out our campaigns below to learn how you can get involved'}
        </Text>
        {props.isEditing ? null : (
          <Button
            containerStyle={{ marginTop: 12 }}
            style={styles.viewAllButton}
            labelStyle={styles.viewAllButtonLabel}
            label="VIEW ALL"
            onPress={onViewAllCampaigns}
          />
        )}

        {props.data?.supporters_current?.items.length ? (
          <View
            style={[
              SECTION_CONTAINER,
              {
                marginHorizontal: 0,
                backgroundColor: KEY_LIGHT_GRAY,
              },
            ]}
          >
            <View style={commonStyles('horizontalContainer')}>
              <Ionicons
                name="rocket"
                size={28}
                style={{
                  marginRight: 6,
                }}
              />
              <Text style={commonStyles('sectionTitle')}>
                CURRENTLY EMPOWERING
              </Text>
            </View>
            <Text style={commonStyles('sectionText')}>
              These are the supporters that are empowering currently active
              campaigns
            </Text>

            <GridList
              data={props.data?.supporters_current?.items}
              maxTileWidth={128}
              renderItem={({ item, tileWidth }) => (
                <Pressable
                  onPress={() => {
                    goToProfile(item.id);
                  }}
                  style={commonStyles('supporterTile')}
                >
                  <Avatar
                    source={{ uri: item.profile_image ?? '' }}
                    rounded
                    size={tileWidth - 12}
                  />
                  <Text style={commonStyles('supporterName')}>
                    {item?.name}
                  </Text>
                </Pressable>
              )}
            />

            {props.data.supporters_current?.nextToken ? (
              <Text style={commonStyles('moreSupportersText')}>
                +
                {(props.data.supporters_current.total ?? 0) -
                  props.data.supporters_current.items.length}{' '}
                MORE
              </Text>
            ) : null}
          </View>
        ) : null}
      </View>
      <View
        style={{
          display: props.isEditing ? 'none' : 'flex',
        }}
      >
        <CampaignFlatList
          page={'profile'}
          listKey={`${props.userId}-open`}
          disableViewportTracker
          emptyPlaceholderText="This organization does not currently have any open campaigns"
          filter={{
            closed: false,
            userId: props.userId ?? '',
          }}
          scrollable={false}
          limit={3}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 8,
  },
  headerContainer: {
    marginVertical: 6,
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  viewAllButton: {
    backgroundColor: KEY_GRAY,
  },
  viewAllButtonLabel: {
    color: 'white',
    paddingVertical: 8,
  },
});
