import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useCommonStyles } from '../styles';
import Button from '/components/Button';
import ResearchTopicButton from '/components/ResearchTopicButton';
import ResearchTopicPicker from '/components/ResearchTopicPicker';
import { SECTION_CONTAINER } from '/constants';
import { ResearchTopic } from '/generated/graphql';

export interface IResearchTopic extends Pick<ResearchTopic, 'topic'> {}

type Data = {
  topics?: IResearchTopic[] | undefined;
};

interface Props {
  data: Data | undefined | null;
  setData: (data: Data) => void;
  isEditing: boolean;
}

export default function UserResearchTopics(props: Props) {
  const { styles: commonStyles } = useCommonStyles();

  const [viewLimit, setViewLimit] = useState(30);

  function onRemoveTopic(topicName: string) {
    const newTopics = [...(props.data?.topics ?? [])];

    props.setData({
      topics: newTopics.filter((topic) => topic.topic !== topicName),
    });
  }

  function onSelectTopic(topicName: string) {
    const newTopics = [...(props.data?.topics ?? []), { topic: topicName }];

    props.setData({
      topics: newTopics,
    });
  }

  return !props.data?.topics?.length && !props.isEditing ? null : (
    <View style={SECTION_CONTAINER}>
      <View style={commonStyles('sectionTitleContainer')}>
        <Text style={commonStyles('sectionTitle')}>OUR RESEARCH TOPICS</Text>
      </View>
      <Text style={commonStyles('sectionText')}>
        These are the main topics we focus on
      </Text>

      <View
        style={{
          paddingVertical: 4,
        }}
      >
        {props.isEditing ? (
          <ResearchTopicPicker
            onRemoveTopic={onRemoveTopic}
            onSelectTopic={onSelectTopic}
            containerStyle={{
              paddingVertical: 4,
            }}
            selectedTopics={
              props.data?.topics?.map((topic) => topic.topic) as string[]
            }
          />
        ) : (
          <>
            <View style={styles.buttonsContainer}>
              {props.data?.topics?.slice(0, viewLimit).map((topic) => (
                <ResearchTopicButton
                  key={topic.topic}
                  topic={topic as ResearchTopic}
                />
              ))}
            </View>
            {(props.data?.topics?.length ?? 0) > viewLimit && (
              <Button
                containerStyle={{
                  alignSelf: 'center',
                  marginTop: 8,
                }}
                label="View More..."
                onPress={() => {
                  setViewLimit((prevState) => prevState + 30);
                }}
              />
            )}
          </>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  buttonsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingVertical: 4,
  },
});
