import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  StyleProp,
  ViewStyle,
  Pressable,
  ColorValue,
  TextStyle,
} from 'react-native';
import CheckmarkSwitch from '/components/CheckmarkSwitch';
import { KEY_YELLOW } from '/constants';

const DEFAULT_OPTIONS = [
  'Strongly Agree',
  'Agree',
  'Disagree',
  'Strongly Disagree',
];

interface IMultipleChoiceCommonProps<ValueT> {
  /** Override default Agree/Disagree options */
  options?: ValueT[];
  disabledOptionsIndices?: number[];
  showSeparator?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  checkmarkFillColor?: ColorValue;
  checkmarkSize?: number;
  checkmarkStyle?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  subtitleStyle?: StyleProp<TextStyle>;
  choiceContainerStyle?: StyleProp<ViewStyle>;
  renderTitle?: (value: ValueT) => string;
  renderSubtitle?: (value: ValueT) => string;
}

interface IMultipleChoiceString<ValueT>
  extends IMultipleChoiceCommonProps<ValueT> {
  multiselect?: boolean;
  /** Either pass a single value or an array of values to allow for multiple selections */
  value: ValueT | undefined;
  onChange: (value: ValueT) => void;
}

interface IMultipleChoiceStringArray<ValueT>
  extends IMultipleChoiceCommonProps<ValueT> {
  multiselect?: boolean;
  /** Either pass a single value or an array of values to allow for multiple selections */
  value: ValueT[] | undefined;
  onChange: (value: ValueT[]) => void;
}

export type MultipleChoiceProps<ValueT> =
  | IMultipleChoiceStringArray<ValueT>
  | IMultipleChoiceString<ValueT>;

export default function MultipleChoice<ValueT>(
  props: MultipleChoiceProps<ValueT>,
) {
  function onSelectValue(value: ValueT & ValueT[], selected: boolean) {
    const itemIndex = props.options?.indexOf(value as ValueT);

    if (props.disabledOptionsIndices?.includes(itemIndex as number)) {
      return;
    }

    if (!props.multiselect) {
      if (props.value !== value) props.onChange(value);
    } else if (typeof props.value !== 'undefined') {
      const newValue = [...(props.value as ValueT[])];

      if (selected) {
        newValue.push(value);
      } else {
        const targetIndex = newValue.indexOf(value);
        newValue.splice(targetIndex, 1);
      }

      props.onChange(newValue as ValueT[] & ValueT);
    }
  }

  return (
    <View style={[styles.listContainer, props.containerStyle]}>
      {(props.options ?? DEFAULT_OPTIONS).map((value, index) => {
        const isSelected = Array.isArray(props.value)
          ? props.value.includes(value as ValueT)
          : value === props.value;

        return (
          <React.Fragment key={index}>
            {props.showSeparator && index !== 0 ? (
              <View style={styles.separator} />
            ) : null}
            <Pressable
              disabled={props.disabledOptionsIndices?.includes(index)}
              onPress={() =>
                onSelectValue(value as ValueT & ValueT[], !isSelected)
              }
              key={`${value}`}
              style={[
                styles.switchContainer,
                {
                  opacity: props.disabledOptionsIndices?.includes(index)
                    ? 0.5
                    : 1,
                },
              ]}
            >
              <CheckmarkSwitch
                // trackColor={{ true: '#0EE6A6', false: undefined }}
                fillColor={props.checkmarkFillColor ?? KEY_YELLOW}
                style={[styles.obSwitchButton, props.checkmarkStyle]}
                value={isSelected}
                size={props.checkmarkSize}
                disabled={isSelected && !props.multiselect}
                onValueChange={(selected) => {
                  onSelectValue(value as ValueT & ValueT[], selected);
                }}
              />
              <View
                style={[styles.choiceContainer, props.choiceContainerStyle]}
              >
                <Text style={[styles.switchText, props.labelStyle]}>
                  {props.renderTitle?.(value as ValueT) || (value as string)}
                </Text>
                {props.renderSubtitle?.(value as ValueT) ? (
                  <Text style={[styles.switchSubtext, props.subtitleStyle]}>
                    {props.renderSubtitle?.(value as ValueT)}
                  </Text>
                ) : null}
              </View>
            </Pressable>
          </React.Fragment>
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  listContainer: {
    flexDirection: 'column',
    paddingVertical: 20,
    paddingLeft: 10,
  },
  obSwitchButton: {
    marginBottom: 24,
    marginRight: 10,
  },
  choiceContainer: {
    flex: 1,
    marginBottom: 10,
  },
  switchContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  switchText: {
    fontFamily: 'Lato-Bold',
    alignSelf: 'flex-start',
    textAlign: 'left',
    fontSize: 16,
    width: '100%',
  },
  switchSubtext: {
    fontFamily: 'Lato',
    alignSelf: 'flex-start',
    textAlign: 'left',
    fontSize: 16,
    width: '100%',
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: '#eaeaea',
    marginBottom: 8,
  },
});
