import React, { useState } from 'react';
import { Text, TextInput, useWindowDimensions, View } from 'react-native';
import commonStyles from '../common.style';
import styles from './ConfirmResetForm.style';
import PasswordTooltip from '../PasswordTooltip/PasswordTooltip';
import { KEY_GREEN } from '/constants';

interface IConfirmResetFormProps {
  email: string;
  verificationCode: string;
  newPassword: string;
  confirmPassword: string;
  setVerificationCode: (code: string) => void;
  setNewPassword: (password: string) => void;
  setConfirmPassword: (password: string) => void;
}

export default function ConfirmResetForm(props: IConfirmResetFormProps) {
  const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);

  const { width } = useWindowDimensions();

  return (
    <>
      <View
        style={{
          backgroundColor: 'rgba(0,0,0,0.5)',
          borderRadius: width / 2,
          width: width * 0.9,
          height: width * 0.9,
          maxWidth: 480,
          maxHeight: 480,
          justifyContent: 'center',
          alignItems: 'center',
          padding: 32,
        }}
      >
        <Text style={commonStyles.headingText}>Confirm Reset</Text>
        <Text style={commonStyles.paragraph}>
          Check your email for the verification code{'\n'}(Sent to{' '}
          <Text
            style={{
              color: KEY_GREEN,
            }}
          >
            {props.email}
          </Text>
          )
        </Text>
      </View>
      <View style={styles.inputContainer}>
        <View style={styles.confirmInputField}>
          <TextInput
            placeholder="VERIFICATION CODE"
            placeholderTextColor="rgba(0,0,0, 0.4)"
            enterKeyHint="next"
            onSubmitEditing={() => {}}
            style={[styles.confirmInput, { marginBottom: 8 }]}
            autoComplete="off"
            dataDetectorTypes="none"
            textContentType="oneTimeCode"
            keyboardType="default"
            autoCapitalize="none"
            autoCorrect={false}
            value={props?.verificationCode}
            onChangeText={(text) => props?.setVerificationCode(text.trim())}
          />
          <PasswordTooltip
            password={props.newPassword}
            show={showPasswordTooltip}
            onTapToHide={() => setShowPasswordTooltip(false)}
          />
          <TextInput
            placeholder="NEW PASSWORD"
            placeholderTextColor="rgba(0,0,0, 0.4)"
            enterKeyHint="next"
            secureTextEntry={true}
            onSubmitEditing={() => {}}
            style={styles.confirmInput}
            textContentType="newPassword"
            autoCapitalize="none"
            autoCorrect={false}
            autoComplete="password"
            value={props?.newPassword}
            onChangeText={(text) => props?.setNewPassword(text.trim())}
            onFocus={() => setShowPasswordTooltip(true)}
            onBlur={() => setShowPasswordTooltip(false)}
            onEndEditing={() => setShowPasswordTooltip(false)}
          />
          <TextInput
            placeholder="CONFIRM PASSWORD"
            placeholderTextColor="rgba(0,0,0, 0.4)"
            enterKeyHint="next"
            secureTextEntry={true}
            onSubmitEditing={() => {}}
            style={styles.confirmInput}
            textContentType="password"
            autoCapitalize="none"
            autoCorrect={false}
            autoComplete="password"
            value={props.confirmPassword}
            onChangeText={props.setConfirmPassword}
          />
        </View>
      </View>
    </>
  );
}
