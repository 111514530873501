import { HeaderBackButton } from '@react-navigation/elements';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { BlurView } from 'expo-blur';
import React from 'react';
import { Platform, Pressable, StyleSheet, View } from 'react-native';
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedProps,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Ellipse from '/assets/jsicons/Ellipse';
import MomentInNatureActionSheetPressable from '/components/ActionSheet/MomentInNatureActionSheetPressable';
import { MomentInNature } from '/generated/graphql';
import useBinaryTimingAnimation from '/hooks/useBinaryTimingAnimation';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import { FontAwesome5 } from '@expo/vector-icons';
import { useModalContext } from '/context';
import { Text } from 'react-native';

type Props = {
  data:
    | Pick<MomentInNature, 'id' | 'userId' | 'caption' | 'is_good_news'>
    | undefined
    | null;
  isOpaque: boolean;
};

const AnimatedBlurView = Animated.createAnimatedComponent(BlurView);

export default function ViewMomentInNatureHeader({
  isOpaque,
  ...props
}: Props) {
  const { spawnModal } = useModalContext();
  const navigation = useNavigation<StackNavigationProp<any>>();
  const linkTo = useLinkTo();

  const safeAreaInsets = useSafeAreaInsets();

  const animation = useBinaryTimingAnimation({
    value: isOpaque,
  });

  function onPressGoodNews() {
    spawnModal({
      title: 'GOOD NEWS POST',
      style: {
        maxWidth: 480,
      },
      component: (
        <View>
          <Text style={styles.goodNewsModalText}>
            Posts with a key <FontAwesome5 name="key" size={16} color="black" />{' '}
            icon are marked by the organization as good news. Good News posts
            highlight the positive things that are happening in conservation.
          </Text>
        </View>
      ),
    });
  }

  const animatedOpacityStyle = useAnimatedStyle(() => ({
    opacity: animation.value,
  }));

  const animatedBlurViewProps = useAnimatedProps(() => {
    const intensity = interpolate(
      animation.value,
      [0, 1],
      [0, 100],
      Extrapolation.CLAMP,
    );

    return {
      intensity,
    };
  }, []);

  return (
    <Animated.View
      style={[
        styles.headerContainer,
        {
          paddingTop: safeAreaInsets.top,
          paddingRight: safeAreaInsets.right + 8,
          paddingLeft: safeAreaInsets.left + 8,
        },
      ]}
    >
      <AnimatedBlurView
        animatedProps={animatedBlurViewProps}
        style={[StyleSheet.absoluteFill, { zIndex: -1 }]}
        tint="systemThinMaterialDark"
      />

      <HeaderBackButton
        style={[
          {
            padding: 12,
          },
          styles.iconShadow,
        ]}
        onPress={() => {
          if (navigation.canGoBack()) navigation.goBack();
          else linkTo('/');
        }}
        tintColor="white"
      />

      <Animated.Text style={[styles.headerTitle, animatedOpacityStyle]}>
        MOMENT IN NATURE
      </Animated.Text>

      <HorizontalContainer>
        {props.data?.is_good_news ? (
          <Pressable onPress={onPressGoodNews} style={styles.goodNewsIcon}>
            <FontAwesome5
              name="key"
              size={19}
              color="white"
              style={styles.iconShadow}
            />
          </Pressable>
        ) : null}
        <MomentInNatureActionSheetPressable
          moment_in_nature={props.data}
          goBackAfterDelete
          disabled={!props.data}
          style={({ pressed }) => ({
            opacity: props.data ? (pressed ? 0.5 : 1) : 0,
          })}
        >
          <Ellipse
            fill="white"
            style={StyleSheet.flatten([
              {
                margin: 12,
              },
              styles.iconShadow,
            ])}
            width={22}
            height={22}
          />
        </MomentInNatureActionSheetPressable>
      </HorizontalContainer>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    zIndex: 99,
    elevation: 1,
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    minHeight: 64,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerTitle: {
    fontSize: 20,
    color: 'white',
    fontFamily: 'LeagueSpartan-Bold',
    flex: 1,
    textAlign: 'center',
  },
  goodNewsModalText: {
    fontFamily: 'Lato',
    fontSize: 16,
    color: 'black',
  },
  goodNewsIcon: {
    padding: 12,
  },
  iconShadow: {
    elevation: 5,
    shadowOpacity: Platform.OS === 'web' ? 0 : 0.7,
    shadowRadius: 4,
    shadowOffset: {
      width: 0,
      height: 0,
    },
  },
});
