import React from 'react';
import { TouchableOpacity, Text } from 'react-native';
import Alert from '/Alert';
import styles from '../constants/Buttons/Back';
import LongArrowLeft from '../assets/jsicons/miscIcons/LongArrowLeft';
import { KEY_GRAY } from '../constants';

import { useNavigation } from '@react-navigation/native';

// Optional props:

// confirm - A confirmation message that makes sure the user wants to go back,
// if not present, BackButton will go back immediately
// content - Text or a React component to replace the default arrow icon

const BackButton = ({
  confirm = undefined,
  content = undefined,
  tintColor = undefined,
}) => {
  const { goBack } = useNavigation();

  // console.log(props);
  const handlePress = () => {
    if (confirm) {
      Alert.alert('Confirm', confirm, [
        { text: 'Okay', onPress: goBack, style: 'default' },
        { text: 'Cancel', style: 'cancel' },
      ]);
    } else goBack();
  };
  return (
    <TouchableOpacity
      onPress={handlePress}
      style={{
        width: 48,
        height: 48,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 99,
      }}
    >
      <Text
        style={{
          fontFamily: 'Lato',
          color: tintColor || KEY_GRAY,
          fontSize: 16,
        }}
      >
        {content || (
          <LongArrowLeft fill={tintColor || KEY_GRAY} style={styles.outline} />
        )}
      </Text>
    </TouchableOpacity>
  );
};

export default BackButton;
