import { StyleSheet } from 'react-native';
import { CARD_TITLE_FONT_SIZE } from '/constants';

export default StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
  },
  iconContainer: {
    width: 25,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 4,
  },
  headerText: {
    fontSize: CARD_TITLE_FONT_SIZE,
    fontFamily: 'Lato-Bold',
  },
  balanceButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 8,
    borderRadius: 6,
  },
  depositMethodButtonImage: {
    width: 120,
    height: 64,
  },
  depositMethodBalanceText: {
    fontFamily: 'Lato',
    fontSize: 26,
    letterSpacing: 0.2,
  },
  depositMethodBalanceLabel: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 14,
  },
});
