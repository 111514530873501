import React from 'react';
import { ColorValue, ViewStyle } from 'react-native';
import { ActivityIndicator } from 'react-native';
import { KEY_GRAY } from '/constants';

interface Props {
  style?: ViewStyle;
  color?: ColorValue;
}

export default function ListLoading(props: Props) {
  return (
    <ActivityIndicator
      color={props.color ?? KEY_GRAY}
      size={'large'}
      style={[
        {
          alignSelf: 'center',
          padding: 24,
        },
        props.style,
      ]}
    />
  );
}
