import { AntDesign, FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import {
  Modal,
  Pressable,
  ScrollView,
  StyleSheet,
  Switch,
  Text,
  TextInput,
  View,
} from 'react-native';
import Carousel from '../../Carousel/Carousel';
import SpeciesPickerModal from '../../SpeciesPicker/SpeciesPickerModal';
import BrowseUserContentFactsModal from './EditSpeciesUserContentModal/BrowseUserContentFactsModal';
import BrowseUserContentMediaModal from './EditSpeciesUserContentModal/BrowseUserContentMediaModal';
import EditUserContentMediaModal from './EditSpeciesUserContentModal/EditUserContentMediaModal';
import commonStyles from './EditSpeciesUserContentModal/styles';
import {
  IOurSpeciesSpeciesUserContent,
  IOurSpeciesSpeciesUserContentMedia,
} from './OurSpecies';
import { SpeciesUserContentCreatorBadge } from './shared/SpeciesUserContent';
import Alert from '/Alert';
import Button from '/components/Button';
import IUCNStatusImage from '../../IUCNStatusImage/IUCNStatusImage';
import LoadingOverlay from '/components/LoadingOverlay';
import {
  ValidatedAny,
  useFormValidationContext,
  withFormValidation,
} from '/components/ValidatedForm';
import { KEY_GRAY, KEY_GREEN, TEXT_INPUT_LARGE } from '/constants';
import { useAuthContext, useTeamContext } from '/context';
import {
  SpeciesSearchResult,
  SpeciesUserContentCategory,
  SpeciesUserContentFact,
  SpeciesUserContentMedia,
  usePutSpeciesUserContentMutation,
} from '/generated/graphql';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';

interface Props {
  visible: boolean;
  userId: string | undefined;
  category: SpeciesUserContentCategory;
  data: IOurSpeciesSpeciesUserContent | undefined;
  alreadyIncludedSpeciesIds: number[] | undefined;
  onRequestClose: (data?: IOurSpeciesSpeciesUserContent) => void;
}

const DEFAULT_STATE: Partial<IOurSpeciesSpeciesUserContent> = {
  media: [],
  facts: [],
  new_species: undefined,
};

function EditSpeciesUserContentModal(props: Props) {
  const { activeTeam } = useTeamContext();
  const { userData } = useAuthContext();

  const activeUser = activeTeam?.user.id ? activeTeam.user : userData;

  const [state, setState] = useState(
    props.data || ({} as Partial<IOurSpeciesSpeciesUserContent>),
  );

  const speciesTaxonID =
    state.new_species?.acceptedNameUsageID || state.newSpeciesTaxonID;

  const [showSpeciesPicker, setShowSpeciesPicker] = useState(false);

  const scrollViewRef = useRef<ScrollView>();

  const { validateForm, fields } = useFormValidationContext(scrollViewRef);

  // ID of media currently being edited, if any
  const [editingMediaIndex, setEditingMediaIndex] = useState<number>();

  //   Modals
  const [browsingFacts, setBrowsingFacts] = useState(false);
  const [browsingMedia, setBrowsingMedia] = useState(false);

  const [editingMedia, setEditingMedia] = useState(false);

  const [publishFactsPublicly, setPublishFactsPublicly] = useState(false);

  const [promptConfirmExit, setPromptConfirmExit] = useState(false);

  /** Used to show user a prompt before they change species letting them know
   * that their inputs will be cleared if they switch species */
  const [queueSetSpecies, setQueueSetSpecies] = useState<
    Partial<SpeciesSearchResult> | undefined
  >(undefined);

  const [{ fetching: savingUserContent }, putSpeciesUserContent] =
    usePutSpeciesUserContentMutation();

  useEffect(() => {
    setState((props.data || DEFAULT_STATE) as IOurSpeciesSpeciesUserContent);

    if (props.data?.facts?.every((f) => f.public)) {
      setPublishFactsPublicly(true);
    } else {
      setPublishFactsPublicly(false);
    }
  }, [props.data]);

  useEffect(() => {
    if (publishFactsPublicly) {
      setState((prevState) => ({
        ...prevState,
        facts: prevState.facts?.map((fact) => ({ ...fact, public: true })),
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        facts: prevState.facts?.map((fact) => ({ ...fact, public: false })),
      }));
    }
  }, [publishFactsPublicly]);

  useEffect(() => {
    if (props.visible) return;

    // If we've opened the modal, make sure prompts get closed and reset state
    setPromptConfirmExit(false);
    setQueueSetSpecies(undefined);
    setBrowsingFacts(false);
    setBrowsingMedia(false);

    setState(DEFAULT_STATE); // Clear state on every exit
  }, [props.visible]);

  useEffect(() => {
    if (props.visible && !props.data?.new_species?.taxonID) {
      setShowSpeciesPicker(true);
    }
  }, [props.visible, props.data]);

  function hasFormChanged() {
    // Exlcude `species` when making comparison (This works because if species changes, everything else must change)
    // Excluded species to stop asking user to save changes upon exiting the modal when all they did was add a species.
    const { new_species: __, ...prevState } = props.data ?? DEFAULT_STATE;
    const { new_species: ___, ...newState } = state;

    return !_.isEqual(prevState, newState);
  }

  function onCancel() {
    if (!hasFormChanged()) props.onRequestClose?.();
    else setPromptConfirmExit(true);
  }

  async function onSave() {
    if (!validateForm() || !state.new_species?.taxonID) return;

    if (!hasFormChanged()) {
      // If no changes were made to the form, skip making a request
      // and just exit
      props.onRequestClose?.();
      return;
    }

    try {
      /** Make sure there is a thumbnail */
      const thumbnailMediaId = state.thumbnail_media?.id || state.media?.[0].id;

      const { data, error } = await putSpeciesUserContent({
        input: {
          taxonID: state.new_species.taxonID,
          preferred_vernacular_name:
            state.preferred_vernacular_name?.vernacularName,
          mediaIds: state.media?.map((media) => media.id) as string[],
          thumbnailMediaId: thumbnailMediaId as string,
          facts: state.facts
            ?.filter((f) => !!f.body?.trim())
            .map((fact, i) => ({
              id: fact.id,
              body: fact.body!,
              public: publishFactsPublicly,
              taxonID: state.new_species?.taxonID!,
              order: i,
            })),
          category: props.category,
        },
        userId: props.userId,
      });

      if (error) throw error;

      props.onRequestClose?.(data?.putSpeciesUserContent);

      Alert.notify({
        color: KEY_GREEN,
        message: 'Profile updated',
      });
    } catch (error) {
      // TODO: Handle error
      console.log(
        'EditSpeciesUserContentModal onSave failed with error:',
        error,
      );
    }
  }

  function onOpenSpeciesPicker() {
    setShowSpeciesPicker(true);
  }

  function setThumbnailMedia(
    media: Pick<SpeciesUserContentMedia, 'id' | 'thumbnail'>,
  ) {
    setState({
      ...state,
      thumbnail_media: media,
    });
  }

  function onEditMedia(__: any, removedIndex?: number) {
    if (removedIndex !== undefined) {
      const newSpeciesMedia = Array.from(
        (state.media ?? []) as SpeciesUserContentMedia[],
      );
      const [removedMedia] = newSpeciesMedia.splice(removedIndex, 1);

      /** If the media we removed was being used as the thumbnail, unset
       * thumbnail */
      let thumbnail_media = state.thumbnail_media;
      if (state.thumbnail_media?.id === removedMedia.id) {
        thumbnail_media = undefined;
      }

      setState((prevState) => ({
        ...prevState,
        media: newSpeciesMedia,
        thumbnail_media,
      }));
    } else {
      setEditingMediaIndex(undefined);
      setEditingMedia(true);
    }
  }

  function onDeleteMedia(id: string) {
    const targetMediaIndex = state.media?.findIndex((media) => media.id === id);

    /** If deleted media is not being used in state, do nothing */
    if (targetMediaIndex === -1) return;

    onEditMedia(undefined, targetMediaIndex);
  }

  function onDeleteFact(id: string) {
    setState((prevState) => ({
      ...prevState,
      facts: prevState.facts?.filter((fact) => fact.id !== id),
    }));
  }

  function onUseFact(fact: Pick<SpeciesUserContentFact, 'id' | 'body'>) {
    // If fact already exists, do nothing
    if (state.facts?.some((f) => f.id === fact.id)) return;

    setState((prevState) => ({
      ...prevState,
      facts: [
        ...(prevState.facts ?? []),
        { ...fact, public: publishFactsPublicly },
      ],
    }));
  }

  function onMoveUpFact(index: number) {
    if (index === 0) return;

    const facts = [...(state.facts ?? [])];
    const fact = facts.splice(index, 1)[0];
    facts.splice(index - 1, 0, fact);

    setState((prevState) => ({
      ...prevState,
      facts,
    }));
  }

  function onMoveDownFact(index: number) {
    if (!state.facts?.length || index === state.facts?.length - 1) return;

    const facts = [...(state.facts ?? [])];
    const fact = facts.splice(index, 1)[0];
    facts.splice(index + 1, 0, fact);

    setState((prevState) => ({
      ...prevState,
      facts,
    }));
  }

  function onBrowseFacts() {
    setBrowsingFacts(true);
  }

  function onBrowseMedia() {
    setBrowsingMedia(true);
  }

  function onCloseFactsBrowser() {
    setBrowsingFacts(false);
  }

  function onCloseMediaBrowser(data?: IOurSpeciesSpeciesUserContentMedia) {
    setBrowsingMedia(false);

    if (data) {
      setState((prevState) => ({
        ...prevState,
        media: [...(prevState?.media ?? []), data],
      }));
    }
  }

  function onCloseMediaEditor(data?: IOurSpeciesSpeciesUserContentMedia) {
    setEditingMedia(false);

    if (data) {
      const newMedia = [...(state.media ?? [])];

      if (editingMediaIndex !== undefined)
        // Replace old media with new media
        newMedia.splice(editingMediaIndex, 1, data);
      else newMedia.push(data);

      setState((prevState) => ({
        ...prevState,
        media: newMedia,
      }));
    }

    setEditingMediaIndex(undefined);
  }

  function setSpecies(data: Partial<SpeciesSearchResult>) {
    const oldAcceptedTaxonID =
      state.new_species?.acceptedNameUsageID || state.new_species?.taxonID;

    const shouldClearState = data.acceptedNameUsageID !== oldAcceptedTaxonID;

    const { canonicalName, taxonID } = data;

    if (!canonicalName || !taxonID) {
      console.error('Species data is missing required fields');
      Alert.alert('Oh no', 'There was a problem selecting this species');
      return;
    }

    setState((prevState) => ({
      ...prevState,
      new_species: {
        canonicalName: canonicalName,
        taxonID: taxonID,
        acceptedNameUsageID: data.acceptedNameUsageID,
        iucnThreatStatus: data.threatStatus
          ? {
              threatStatus: data.threatStatus,
            }
          : undefined,
        preferredVernacularName: data.vernacularName,
      },
      preferred_vernacular_name: data.vernacularName
        ? {
            vernacularName: data.vernacularName,
          }
        : undefined,
      ...(shouldClearState
        ? {
            media: [],
            thumbnail_media: undefined,
            facts: undefined,
          }
        : {}),
    }));
  }

  function onCloseSpeciesPicker(data?: Partial<SpeciesSearchResult>) {
    setShowSpeciesPicker(false);

    // If new species is picked, set in state
    if (data) {
      // Clear "top_facts" and "media" if the species we switched to is not a synonym of the
      // species we switched from
      const oldAcceptedTaxonID =
        state.new_species?.acceptedNameUsageID || state.new_species?.taxonID;

      const shouldClearState =
        !!oldAcceptedTaxonID && data.acceptedNameUsageID !== oldAcceptedTaxonID;

      if (shouldClearState) {
        setQueueSetSpecies(data);
      } else {
        setSpecies(data);
      }
    }
  }

  const propsTaxonId =
    props.data?.new_species?.acceptedNameUsageID ||
    props.data?.newSpeciesTaxonID;

  const isDuplicateSpecies =
    speciesTaxonID &&
    speciesTaxonID !== propsTaxonId &&
    (props.alreadyIncludedSpeciesIds?.includes(speciesTaxonID) ||
      props.alreadyIncludedSpeciesIds?.includes(
        state.new_species?.taxonID || -1,
      ));

  return (
    <>
      <Modal
        visible={props.visible}
        onRequestClose={() => {
          // If we have a sub-modal open or we are saving, don't allow the swipe gesture to exit the modal
          if (
            editingMedia ||
            browsingFacts ||
            browsingMedia ||
            savingUserContent
          )
            return;

          onCancel();
        }}
        style={{
          width: '100%',
          height: '100%',
        }}
        presentationStyle="formSheet"
        animationType="slide"
      >
        <ScrollView
          ref={(r) => {
            if (r) scrollViewRef.current = r;
          }}
          style={{ flex: 1 }}
          contentContainerStyle={commonStyles.contentContainerStyle}
        >
          {/* HEADER */}
          <View
            style={[
              commonStyles.headerContainer,
              { justifyContent: 'space-between' },
            ]}
          >
            <Text style={commonStyles.headerText}>
              {props.data ? 'EDIT ' : 'ADD '}SPECIES
            </Text>
            <Pressable
              style={{
                padding: 12,
              }}
              onPress={onCancel}
            >
              <AntDesign name="close" size={24} />
            </Pressable>
          </View>

          {/* CHOOSE SPECIES */}
          <View style={styles.selectedSpeciesContainer}>
            <View style={styles.speciesNameContainer}>
              <Text style={styles.speciesName}>
                {(state.preferred_vernacular_name?.vernacularName ||
                  state.new_species?.preferredVernacularName ||
                  state.new_species?.canonicalName) ??
                  'No species selected'}
              </Text>
              {/* Only render canonical name here if `preferredVernacularName` is also present */}
              {state.new_species &&
              !!state.new_species.preferredVernacularName ? (
                <Text style={styles.speciesScientificName}>
                  {state.new_species.canonicalName}
                </Text>
              ) : null}
              <View style={styles.speciesPickerButtonContainer}>
                <Button
                  label={`${state.new_species ? 'Change' : 'Select'} species`}
                  onPress={onOpenSpeciesPicker}
                />
              </View>
            </View>

            <View style={styles.redlistCategoryContainer}>
              <IUCNStatusImage
                redlist_category={
                  state.new_species?.iucnThreatStatus?.threatStatus ?? ''
                }
              />
            </View>
          </View>

          {/* If user has not yet selected a species, block the form and prompt user
            to first select a species */}
          <View
            pointerEvents={
              state.new_species && !isDuplicateSpecies ? 'none' : 'auto'
            }
            style={{
              width: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              padding: 24,
              paddingTop: 200,
              alignItems: 'center',
              display:
                state.new_species && !isDuplicateSpecies ? 'none' : 'flex',
            }}
          >
            <FontAwesome
              name="paw"
              color={isDuplicateSpecies ? 'crimson' : KEY_GRAY}
              size={24}
            />
            <Text
              style={[
                styles.selectSpeciesPromptText,
                { color: isDuplicateSpecies ? 'crimson' : 'black' },
              ]}
            >
              {isDuplicateSpecies
                ? 'You already have this species on your profile. Please select a different species to continue.'
                : 'Select a species to continue'}
            </Text>
          </View>

          <View
            style={{
              display:
                state.new_species && !isDuplicateSpecies ? 'flex' : 'none',
            }}
          >
            {/* TOP FACTS */}
            <ValidatedAny
              containerStyle={{
                borderWidth: 1,
                borderColor:
                  fields.top_facts?.valid !== false ? 'transparent' : 'crimson',
              }}
              name="top_facts"
              value={state.facts}
            >
              <>
                <Text style={commonStyles.sectionTitle}>INTERESTING FACTS</Text>
                <View style={styles.sectionTextWithBrowseButton}>
                  <Text
                    style={[
                      commonStyles.sectionText,
                      { flex: 1, paddingRight: 8 },
                    ]}
                  >
                    Add up to 5 interesting facts about this species. You can
                    also use content you've previously created, or other content
                    made public by the community by clicking "Browse"
                  </Text>
                  <Button
                    label={
                      <View style={commonStyles.buttonLabelContainer}>
                        <AntDesign
                          name="appstore1"
                          size={18}
                          color={KEY_GRAY}
                        />
                        <Text style={commonStyles.buttonLabelText}>Browse</Text>
                      </View>
                    }
                    onPress={onBrowseFacts}
                  />
                </View>

                <Text
                  style={[
                    commonStyles.errorText,
                    {
                      textAlign: 'left',
                      paddingVertical: 2,
                    },
                  ]}
                >
                  {fields.top_facts?.valid === false &&
                    'Please add at least one fact'}
                </Text>

                {(
                  [] as {
                    id?: string | undefined | null;
                    body: string;
                  }[]
                )
                  .concat(
                    state.facts ?? [],
                    (state.facts?.length || 0) < 5
                      ? {
                          id: 'empty',
                          body: '',
                        }
                      : [],
                  )
                  .map((item, i) => (
                    <HorizontalContainer
                      key={item.id || i}
                      style={{
                        marginBottom: 4,
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <TextInput
                          key={i}
                          value={item.body}
                          multiline
                          onChangeText={(t) => {
                            const facts = [...(state.facts ?? [])];

                            if (i < facts.length) {
                              facts[i] = { ...facts[i], body: t };
                            } else {
                              facts.push({
                                id: undefined,
                                body: t,
                                public: publishFactsPublicly,
                              });
                            }

                            setState((prevState) => ({ ...prevState, facts }));
                          }}
                          onBlur={() => {
                            if (!state.facts?.length || i >= state.facts.length)
                              return;

                            if (!state.facts?.[i]?.body?.trim()) {
                              const facts = [...(state.facts ?? [])];
                              facts.splice(i, 1);
                              setState((prevState) => ({
                                ...prevState,
                                facts,
                              }));
                            }
                          }}
                          placeholder="+ Add a fact..."
                          placeholderTextColor={'gray'}
                          style={[
                            TEXT_INPUT_LARGE,
                            {
                              minHeight: 80,
                            },
                          ]}
                        />
                      </View>
                      <View
                        style={{
                          width: 40,
                          marginLeft: 4,
                        }}
                      >
                        {item.id === 'empty' ? null : (
                          <>
                            <Button
                              disabled={i === 0}
                              label={
                                <AntDesign
                                  name="caretup"
                                  size={18}
                                  color={KEY_GRAY}
                                />
                              }
                              onPress={() => onMoveUpFact(i)}
                              containerStyle={{
                                flex: 0.5,
                                marginBottom: 4,
                              }}
                            />
                            <Button
                              disabled={
                                !state.facts?.length ||
                                i === state.facts?.length - 1
                              }
                              label={
                                <AntDesign
                                  name="caretdown"
                                  size={18}
                                  color={KEY_GRAY}
                                />
                              }
                              onPress={() => onMoveDownFact(i)}
                              containerStyle={{
                                flex: 0.5,
                              }}
                            />
                          </>
                        )}
                      </View>
                    </HorizontalContainer>
                  ))}

                <View style={commonStyles.switchContainer}>
                  <Text style={commonStyles.switchLabel}>
                    Allow other organizations to use this content on their
                    profile?
                  </Text>
                  <Switch
                    trackColor={{
                      true: KEY_GREEN,
                    }}
                    value={publishFactsPublicly}
                    onValueChange={setPublishFactsPublicly}
                  />
                </View>
              </>
            </ValidatedAny>

            {/* MEDIA */}
            <View>
              <Text style={commonStyles.sectionTitle}>EXPLORE</Text>
              <View style={styles.sectionTextWithBrowseButton}>
                <Text
                  style={[
                    commonStyles.sectionText,
                    { flex: 1, paddingRight: 8 },
                  ]}
                >
                  Upload media showing this species. You can also use content
                  you've previously added, or content made public by the
                  community by clicking "Browse"
                </Text>
                <Button
                  label={
                    <View style={commonStyles.buttonLabelContainer}>
                      <AntDesign name="appstore1" size={18} color={KEY_GRAY} />
                      <Text style={commonStyles.buttonLabelText}>Browse</Text>
                    </View>
                  }
                  onPress={onBrowseMedia}
                />
              </View>
              <ValidatedAny
                containerStyle={{
                  overflow: 'hidden',
                  borderWidth: 1,
                  borderColor:
                    fields.media?.valid !== false ? 'transparent' : 'crimson',
                }}
                name="media"
                value={state.media}
              >
                <Carousel
                  onChange={onEditMedia}
                  editMode="add-remove-only"
                  data={state.media?.map((media, index) => ({
                    media: media.uri ?? '',
                    thumbnail: media.thumbnail ?? '',
                    caption: media.caption ?? '',
                    // eslint-disable-next-line react/no-unstable-nested-components
                    footerComponent: () => {
                      /** If a thumbnail is set in state and that thumbnail is this media, set true.
                       * Otherwise, set true if index is 0*/
                      const isSetAsThumbnail = state.thumbnail_media?.id
                        ? state.thumbnail_media?.id === media.id
                        : index === 0;

                      return (
                        <>
                          {media.public ? (
                            <>
                              <SpeciesUserContentCreatorBadge
                                user={media.created_by}
                              />
                              {media?.created_by?.id === activeUser?.id ? (
                                <PublicContentBadge />
                              ) : null}
                            </>
                          ) : (
                            <Button
                              label="Edit"
                              onPress={() => {
                                setEditingMediaIndex(index);
                                setEditingMedia(true);
                              }}
                            />
                          )}
                          <Button
                            label={
                              isSetAsThumbnail
                                ? 'Using as thumbnail'
                                : 'Set As Thumbnail'
                            }
                            containerStyle={{
                              marginTop: 8,
                            }}
                            disabled={isSetAsThumbnail}
                            onPress={() => {
                              setThumbnailMedia(media);
                            }}
                          />
                        </>
                      );
                    },
                  }))}
                  addCardPromptText="Add photos and/or videos of this species"
                  isEditing
                />
              </ValidatedAny>
            </View>

            {/* BUTTONS */}
            <View style={commonStyles.buttonContainer}>
              <Button
                label="Finish"
                onPress={onSave}
                style={{
                  backgroundColor: KEY_GREEN,
                }}
              />
              <Button
                label="Cancel"
                containerStyle={{
                  marginRight: 8,
                }}
                onPress={onCancel}
              />
            </View>
          </View>
        </ScrollView>

        {/* CONFIRM EXIT MODAL */}
        <View
          style={[
            commonStyles.exitPromptContainer,
            { display: promptConfirmExit ? 'flex' : 'none' },
          ]}
        >
          <Text style={commonStyles.exitPromptTitle}>Discard Changes?</Text>
          <Text style={commonStyles.exitPromptText}>
            Looks like you've made some changes. If you cancel now, they will be
            gone forever. Would you like to save or discard your changes?
          </Text>
          <Button
            label="Save and Exit"
            onPress={() => {
              setPromptConfirmExit(false);
              onSave();
            }}
          />
          <Button
            containerStyle={{
              marginVertical: 4,
            }}
            label="Discard and Exit"
            onPress={() => props.onRequestClose?.()}
          />
          <Button label="Go back" onPress={() => setPromptConfirmExit(false)} />
        </View>

        {/* CONFIRM CHANGE SPECIES MODAL */}
        {queueSetSpecies ? (
          <View style={commonStyles.exitPromptContainer}>
            <Text style={commonStyles.exitPromptTitle}>Change species?</Text>
            <Text style={commonStyles.exitPromptText}>
              If you change species, this form will be reset
            </Text>
            <Button
              label="Change Species"
              onPress={() => {
                setSpecies(queueSetSpecies);
                setQueueSetSpecies(undefined);
              }}
            />
            <Button
              label="Cancel"
              containerStyle={{
                marginTop: 4,
              }}
              onPress={() => setQueueSetSpecies(undefined)}
            />
          </View>
        ) : null}

        {/* LOADING OVERLAY */}
        <LoadingOverlay loading={savingUserContent} />

        {/* MODALS */}
        <EditUserContentMediaModal
          visible={editingMedia}
          userId={props.userId}
          data={
            editingMediaIndex !== undefined
              ? state.media?.[editingMediaIndex]
              : undefined
          }
          speciesTaxonId={speciesTaxonID}
          onRequestClose={onCloseMediaEditor}
        />

        <BrowseUserContentFactsModal
          visible={browsingFacts}
          userId={props.userId}
          speciesTaxonId={speciesTaxonID}
          onRequestClose={onCloseFactsBrowser}
          alreadyInlcudedIds={state.facts?.map((fact) => fact.id) as string[]}
          onDeleteTopFacts={onDeleteFact}
          onUseFact={onUseFact}
        />

        <BrowseUserContentMediaModal
          visible={browsingMedia}
          userId={props.userId}
          speciesTaxonId={speciesTaxonID}
          alreadyIncludedIds={state.media?.map((media) => media.id) as string[]}
          onRequestClose={onCloseMediaBrowser}
          onDeleteMedia={onDeleteMedia}
        />

        <SpeciesPickerModal
          hideSuggestButton
          visible={showSpeciesPicker}
          onRequestClose={onCloseSpeciesPicker}
        />
      </Modal>
    </>
  );
}

export default withFormValidation(EditSpeciesUserContentModal, {
  disableValidateFieldOnChange: true,
});

function PublicContentBadge() {
  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <FontAwesome5 name="globe-africa" size={18} color="black" />
        <Text
          style={{
            fontFamily: 'Lato-Bold',
            color: 'black',
            fontSize: 15,
            paddingLeft: 4,
          }}
        >
          PUBLIC
        </Text>
      </View>
      <Text
        style={{
          fontFamily: 'Lato-Bold',
          color: 'gray',
          fontSize: 15,
          paddingRight: 8,
        }}
      >
        Other organizations can use this content on their profile.{`\n`}Content
        cannot be edited after it has been made public.
      </Text>
    </>
  );
}

const styles = StyleSheet.create({
  selectedSpeciesContainer: {
    flexDirection: 'row',
    paddingBottom: 12,
  },
  speciesNameContainer: {
    flex: 1,
  },
  speciesPickerButtonContainer: {
    flexDirection: 'row',
    marginTop: 8,
  },
  redlistCategoryContainer: {
    paddingHorizontal: 8,
  },
  speciesName: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 21,
    textTransform: 'capitalize',
  },
  speciesScientificName: {
    fontFamily: 'Lato-BoldItalic',
    fontSize: 19,
    color: KEY_GRAY,
  },
  addTopFactsPlaceholderText: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    padding: 8,
  },
  addTopFactsPlaceholderContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'dashed',
    borderWidth: 2,
    borderColor: KEY_GRAY,
    margin: 8,
    borderRadius: 4,
  },
  topFactsText: {
    fontFamily: 'Lato',
    fontSize: 18,
    marginBottom: 8,
  },
  selectSpeciesPromptText: {
    fontFamily: 'Lato-Bold',
    fontSize: 21,
    textAlign: 'center',
  },
  sectionTextWithBrowseButton: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingBottom: 4,
  },
});
