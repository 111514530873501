import React, { useCallback } from 'react';

import {
  createStackNavigator,
  StackNavigationProp,
} from '@react-navigation/stack';

import { useFocusEffect, useLinkTo } from '@react-navigation/native';
import { useAuthContext } from '/context';
import LoginScreen from '/screens/LoginScreen/LoginScreen';
import ResetPasswordScreen from '/screens/ResetPasswordScreen/ResetPasswordScreen';

const Stack = createStackNavigator();

// type LoginStackParamList = {}

interface Props {
  navigation: StackNavigationProp<any>;
}

export default function LoginStack(props: Props) {
  const { isAuthenticating, fetching, userAttributes } = useAuthContext();

  const linkTo = useLinkTo();

  useFocusEffect(
    useCallback(() => {
      if (isAuthenticating || fetching || !userAttributes?.sub) return;

      const allRoutes = props.navigation.getState().routeNames;

      // If the `onboard` route exists, that means user should be directed there
      if (allRoutes.includes('Onboard')) {
        linkTo('/onboard');
      } else {
        linkTo('/');
      }
    }, [
      isAuthenticating,
      fetching,
      userAttributes?.sub,
      props.navigation,
      linkTo,
    ]),
  );

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        options={{
          title: 'Sign In',
        }}
        component={LoginScreen}
        name="LoginScreen"
      />
      <Stack.Screen
        options={{
          title: 'Reset Password',
        }}
        component={ResetPasswordScreen}
        name="ResetPassword"
      />
    </Stack.Navigator>
  );
}
