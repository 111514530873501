import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  adminLabel: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 14,
    color: 'crimson',
    marginVertical: 4,
  },
  emptyText: {
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    color: 'gray',
    alignSelf: 'center',
    padding: 16,
  },
});
