import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  passwordRequirement: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 5,
    paddingVertical: 0.5,
  },
  label: {
    fontFamily: 'Lato-Bold',
    marginLeft: 0,
  },
  sublabel: {
    fontFamily: 'Lato',
    marginLeft: 5,
  },
  subRequirements: {
    marginLeft: 0,
  },
  tapToHide: {
    textAlign: 'right',
    marginTop: 8,
    fontFamily: 'Lato-Bold',
    fontSize: 14,
    color: 'gray',
  },
});
