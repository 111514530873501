import React from 'react';
import { Dropdown } from 'react-native-element-dropdown';
import styles from '../_common.style';
import { KEY_GRAY } from '/constants';
import { UserRole } from '/generated/graphql';

type Props = {
  value?: UserRole;
  onChange: (value: UserRole | undefined) => void;
};

export default function UserRoleFilterButton({ value, onChange }: Props) {
  return (
    <Dropdown
      style={styles.filterButton}
      selectedTextStyle={styles.filterButtonText}
      data={[
        {
          label: 'Any',
          value: undefined,
        },
        {
          label: 'Individual',
          value: UserRole.Supporter,
        },
        {
          label: 'Organization',
          value: UserRole.Conservationist,
        },
      ]}
      labelField="label"
      valueField="value"
      onChange={(item) => {
        onChange(item.value);
      }}
      placeholder="Select..."
      placeholderStyle={{
        color: KEY_GRAY,
        fontFamily: 'Lato-Bold',
      }}
      value={value}
    />
  );
}
