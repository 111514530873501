import { Entypo } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Currency, getDecimalPlacesForCurrency } from 'currency-decimal-places';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Pressable, Text, View, ViewStyle } from 'react-native';
import CampaignPreview from '../CampaignPreview/CampaignPreview';
import styles from './DonationCard.style';
import Lightening from '/assets/jsicons/bottomnavigation/Lightening';
import MapMarker from '/assets/jsicons/headerIcons/map-marker';
import { getRandomPattern } from '/assets/KeyAnimalPattern';
import Avatar from '/components/Avatar';
import { KEY_GRAY, KEY_YELLOW } from '/constants';
import {
  Campaign,
  Donation,
  DonationItem,
  DonationRequest,
  DonationRequestGoal,
  User,
} from '/generated/graphql';
import { getCurrencyFormatter } from '/util';

const GOALS_LIST_TRUNCATED_LENGTH = 3;

interface IDonor extends Pick<User, 'id' | 'name' | 'profile_image'> {
  locations: Pick<User['locations'][0], 'name'>[];
}

interface IDonationItem extends Pick<DonationItem, 'id' | 'localized_amount'> {
  donation_goal?: Pick<DonationRequestGoal, 'id' | 'title'> | null;
  fallback_title: string;
}

interface ICampaign
  extends Pick<Campaign, 'id' | 'name' | 'created_at' | 'updated_at'> {
  original_post: Pick<Campaign['original_post'], 'id' | 'thumbnail' | 'media'>;
  user: Pick<User, 'id' | 'name' | 'profile_image'>;
}

interface IDonationRequest extends Pick<DonationRequest, 'id'> {
  campaign: ICampaign;
}

interface IDonation
  extends Pick<
    Donation,
    'id' | 'amount' | 'created_at' | 'message' | 'amount_hidden' | 'anonymous'
  > {
  donor?: IDonor | null;
  line_items?: IDonationItem[] | null;
  donation_request?: IDonationRequest;
}

interface Props {
  donation: IDonation;
  showCampaignPreview?: boolean;
  hideBreakdown?: boolean;
  style?: ViewStyle;
  showAnonymousFlag?: boolean;
}

const parseCurrency = (amount: number, currency: string) => {
  const targetDecimalPlaces = getDecimalPlacesForCurrency(
    currency.toUpperCase() as Currency,
  );

  const fixedAmount = (amount / 10 ** targetDecimalPlaces).toFixed(
    targetDecimalPlaces,
  );

  return getCurrencyFormatter(currency).format(Number(fixedAmount));
};

export default function DonationCard(props: Props) {
  const { push } = useNavigation<StackNavigationProp<any>>();

  const [breakdownExpanded, setBreakdownExpanded] = React.useState(false);

  const donationCurrency = props?.donation.amount?.currency;
  const donationAmount = props?.donation.amount?.amount;
  const campaign = props.donation.donation_request?.campaign;

  const goToDonorProfile = () => {
    if (!props.donation.donor) return;

    push('Profile', {
      id: props.donation.donor.id,
    });
  };

  const goToCampaign = () => {
    if (!campaign?.id) return;

    push('Campaign', {
      campaignId: campaign.id,
    });
  };

  const avatarUri = useMemo(() => {
    return (
      (!props.donation.anonymous && props.donation.donor?.profile_image) ||
      getRandomPattern()
    );
  }, [props.donation.anonymous, props.donation.donor]);

  return (
    <View style={[styles.donationInfoContainer, props.style]}>
      <View style={styles.horizontalContainer}>
        <Avatar
          onPress={goToDonorProfile}
          containerStyle={{
            marginRight: 8,
          }}
          rounded
          source={{
            uri: avatarUri,
          }}
          size={48}
        />
        <View style={{ flex: 1 }}>
          <Text
            style={styles.donorName}
            onPress={props.donation.donor ? goToDonorProfile : undefined}
          >
            {props.donation.donor?.name ?? 'Anonymous'}
          </Text>
          {props.donation.donor?.locations?.[0]?.name ? (
            <View style={styles.horizontalContainer}>
              <MapMarker fill={KEY_YELLOW} width={16} height={16} />
              <Text style={styles.locationText}>
                {props.donation.donor.locations[0].name}
              </Text>
            </View>
          ) : null}
          <Text style={styles.paragraph}>
            Donated{` `}
            {!props.donation.amount_hidden &&
            !!donationAmount &&
            !!donationCurrency ? (
              <Text style={{ fontFamily: 'Lato-Bold' }}>
                {parseCurrency(donationAmount, donationCurrency)}
              </Text>
            ) : null}
          </Text>
          {props.donation.anonymous && props.showAnonymousFlag ? (
            <View style={styles.horizontalContainer}>
              <Entypo name="lock" size={18} color="gray" />
              <Text style={styles.anonymousText}>{` `}ANONYMOUS</Text>
            </View>
          ) : null}
        </View>
      </View>

      <View style={styles.breakdownSection}>
        {props.donation.line_items
          ?.slice(
            0,
            breakdownExpanded ? undefined : GOALS_LIST_TRUNCATED_LENGTH,
          )
          .map((lineItem) => {
            const title =
              lineItem.donation_goal?.title ?? lineItem.fallback_title;

            return title ? (
              <View key={lineItem.id} style={styles.horizontalContainer}>
                <Lightening width={17} height={17} />
                <Text style={styles.lineItemText}>{title}</Text>
              </View>
            ) : null;
          })}
        {!props.hideBreakdown &&
        (props.donation.line_items?.length ?? 0) >
          GOALS_LIST_TRUNCATED_LENGTH ? (
          <Pressable
            onPress={() => {
              setBreakdownExpanded(!breakdownExpanded);
            }}
            style={styles.breakdownExpandToggle}
          >
            <Text style={styles.breakdownExpandToggleText}>
              {breakdownExpanded ? 'Collapse' : 'Show All'}
            </Text>
            {breakdownExpanded ? (
              <Entypo name="chevron-up" size={17} color={KEY_GRAY} />
            ) : (
              <Entypo name="chevron-down" size={17} color={KEY_GRAY} />
            )}
          </Pressable>
        ) : null}
      </View>

      {props.showCampaignPreview ? (
        <View
          style={{
            marginTop: 8,
          }}
        >
          <CampaignPreview
            campaign={campaign}
            campaignPost={campaign?.original_post}
            onPress={goToCampaign}
          />
        </View>
      ) : null}

      <Text
        style={[
          styles.paragraph,
          {
            marginTop: 8,
          },
        ]}
      >
        {!!props.donation.created_at &&
          dayjs(Number(props.donation.created_at)).fromNow()}
      </Text>
    </View>
  );
}
