import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import Alert from '/Alert';

import styles from '/constants/CampaignBuilder/SkillImpact/SkillExpand';

import ValidatedTextInput from '/components/ValidatedForm/ValidatedTextInput';

interface IProjectGoalsFormContentProps {
  data: any;
  index: number;
  onChangeContent: (content: any) => void;
}

const ProjectGoalsFormContent = (props: IProjectGoalsFormContentProps) => {
  const [title, setTitle] = useState(props.data?.title ?? '');
  const [descr, setDescr] = useState(props.data?.description ?? '');

  useEffect(() => {
    props.onChangeContent?.({ title: title, description: descr });
  }, [title, descr]);

  // Keep local state updated
  useEffect(() => {
    if (props.data?.title && props.data?.title !== title) {
      setTitle(props.data?.title);
    }
    if (props.data?.description && props.data?.description !== descr) {
      setDescr(props.data?.description);
    }
  }, [props.data?.title, props.data?.description]);

  return (
    <View style={styles.itemContentRows}>
      <View style={styles.expertiseDescriptionContainer}>
        <ValidatedTextInput
          placeholderTextColor="gray"
          name={`goal_title_${props.index}`}
          maxLength={64}
          style={[styles.textInput, { fontFamily: 'Lato-Bold' }]}
          placeholder="Goal Title"
          onChangeText={setTitle}
          value={title}
        />
        <ValidatedTextInput
          placeholderTextColor="gray"
          name={`goal_description_${props.index}`}
          multiline={true}
          maxLength={640}
          numberOfLines={4}
          style={styles.goalDescriptionBox}
          placeholder="Description of goal"
          onChangeText={setDescr}
          value={descr}
        />
      </View>
    </View>
  );
};

export default ProjectGoalsFormContent;
