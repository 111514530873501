import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 6,
    overflow: 'hidden',
  },
  button: {
    padding: 16,
    paddingHorizontal: 24,
  },
  buttonLabel: {
    fontFamily: 'Lato-Bold',
    fontSize: 17,
  },
  input: {
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: '#ddd',
    padding: 16,
    minWidth: 64,
    textAlign: 'center',
    fontFamily: 'Lato-Bold',
    fontSize: 16,
  },
});
