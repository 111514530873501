/**
 * Render a pending TeamMember request initiated by an organization
 */

import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import Alert from '/Alert';
import Avatar from '/components/Avatar';
import Button from '/components/Button';
import { KEY_GREEN, KEY_YELLOW } from '/constants';
import {
  Group,
  GroupMember,
  GroupMembershipStatus,
  useDeleteGroupMemberMutation,
  useJoinGroupMutation,
} from '/generated/graphql';

interface IGroupMember
  extends Pick<GroupMember, 'status' | 'userId' | 'groupId'> {
  group: Pick<Group, 'id' | 'group_logo' | 'name'>;
}

interface Props {
  invite: IGroupMember;
  avatarSize?: number;
}

export default function PendingGroupInvite(props: Props) {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const [, deleteInvite] = useDeleteGroupMemberMutation();
  const [{ fetching: joining }, joinGroup] = useJoinGroupMutation();

  async function onJoinGroup() {
    try {
      const { error } = await joinGroup({
        input: {
          group: {
            id: props.invite.groupId,
          },
          user: {
            id: props.invite.userId,
          },
        },
      });

      if (error) throw error;

      Alert.notify({
        message: 'Successfully joined group',
        color: KEY_GREEN,
      });
    } catch (error) {
      console.log('Failed to join group', error);
      Alert.notify({
        message: 'Failed to join group',
        color: 'crimson',
        textColor: 'white',
      });
    }
  }

  function onDelete() {
    const remove = async () => {
      try {
        const { error } = await deleteInvite({
          groupId: props.invite.groupId,
          userId: props.invite.userId,
        });

        if (error) throw error;

        Alert.notify({ message: 'Successfully deleted', color: KEY_GREEN });
      } catch (error) {
        console.log('Failed to remove membership', error);
        Alert.notify({
          message: `Failed to delete invite`,
          color: 'crimson',
          textColor: 'white',
        });
      }
    };

    Alert.alert(
      `Delete invite`,
      `Are you sure you want to delete this invite?`,
      [
        {
          text: 'Delete',
          style: 'destructive',
          onPress: remove,
        },
        {
          style: 'cancel',
        },
      ],
    );
  }

  return (
    <View key={props.invite.groupId} style={styles.memberCard}>
      <Pressable
        onPress={() => navigate('ViewGroup', { id: props.invite.groupId })}
      >
        <Avatar
          source={{ uri: props.invite?.group.group_logo ?? '' }}
          size={props.avatarSize ?? 48}
          rounded
        />
      </Pressable>
      <View style={styles.cardTextSection}>
        <Text style={styles.cardTitle}>{props.invite.group.name}</Text>
      </View>
      {props.invite.status === GroupMembershipStatus.Accepted ? (
        // If status is confirmed, then it must be because we just confirmed it
        <View pointerEvents="none">
          <Button
            label="Joined"
            style={[styles.cardButtonStyle, { backgroundColor: KEY_YELLOW }]}
          />
        </View>
      ) : (
        <>
          <Button
            label="Join"
            loading={joining}
            style={[styles.cardButtonStyle, { backgroundColor: KEY_GREEN }]}
            onPress={() => onJoinGroup()}
          />

          <Button
            containerStyle={{
              marginLeft: 6,
            }}
            style={styles.cardButtonStyle}
            label={<FontAwesome name="trash-o" size={24} color="black" />}
            onPress={() => onDelete()}
          />
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  memberCard: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
  },
  cardTextSection: {
    flex: 1,
    padding: 8,
  },
  cardTitle: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
  },
  cardSubtitle: {
    fontFamily: 'Lato',
    color: 'gray',
    fontSize: 15,
  },
  cardButtonStyle: {
    height: 40,
  },
});
