import { FontAwesome } from '@expo/vector-icons';
import React, { ReactElement, useEffect, useState } from 'react';
import {
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import Button from './Button';
import PlusSign from '/assets/jsicons/headerIcons/plusSign';
import CheckMark from '/assets/jsicons/miscIcons/CheckMark';
import { KEY_GRAY, TEXT_INPUT } from '/constants';

interface IFormListProps<FormItemT> {
  data: FormItemT[];
  /** Optionally define default state for new entries that are added to FormList */
  defaultFormState?: FormItemT;
  /** If set, FormList will not display the Add button if the length of data reaches or exceeds maxLength */
  maxLength?: number;
  containerStyle?: StyleProp<ViewStyle>;
  nonRemovableIndices?: number[];
  /** Render an icon to the left of each form. Default is a green checkmark circle */
  renderIcon?: () => JSX.Element;
  renderForm: (data: RenderFormData<FormItemT>) => ReactElement;
  keyExtractor?: (item: FormItemT, index: number) => string;
  placeholder?: string;
  /** Placeholder text on the 'Remove' button  */
  removeButtonLabel?: string;
  onChange?: (data: FormItemT[]) => void;
}

type RenderFormData<ItemT> = {
  item: ItemT;
  index: number;
  onChange: (item: ItemT) => void;
};

export default function FormList<ItemT = any>(props: IFormListProps<ItemT>) {
  const [lastActiveForm, setLastActiveForm] = useState<number>();

  function onChange(item: ItemT, index: number) {
    const newItems = Array.from(props.data);
    newItems.splice(index, 1, item);
    props.onChange?.(newItems);
  }

  function onAddItem() {
    props.onChange?.([
      ...(props.data ?? []),
      props.defaultFormState ?? ({} as any),
    ]);
  }

  function onRemoveItem(index: number) {
    // Since we are using indices as keys for each form, we need to reset lastActiveForm
    // so that the useEffect that responds to lastActiveForm in each <Form /> component is
    // triggerred and resets its internal state
    setLastActiveForm(undefined);

    const newItems = Array.from(props.data);
    newItems.splice(index, 1);
    props.onChange?.(newItems);
  }

  return (
    <View
      style={[
        {
          // flex: 1,
        },
        props.containerStyle,
      ]}
    >
      {props.data?.map((item, index) => {
        return (
          <Form
            key={props.keyExtractor?.(item, index) ?? index}
            lastActiveForm={lastActiveForm}
            setLastActiveForm={setLastActiveForm}
            removeButtonPlaceholder={props.removeButtonLabel}
            index={index}
            item={item}
            onChange={onChange}
            renderIcon={props.renderIcon}
            renderForm={props.renderForm}
            onRemoveItem={onRemoveItem}
            removable={!props.nonRemovableIndices?.includes(index)}
          />
        );
      })}
      {props.maxLength === undefined || props.data?.length < props.maxLength ? (
        <TouchableOpacity onPress={onAddItem} style={styles.addButtonContainer}>
          <View style={styles.circle}>
            <PlusSign />
          </View>
          <Text style={styles.addButton}>
            {props.placeholder ?? 'Add item...'}
          </Text>
        </TouchableOpacity>
      ) : null}
    </View>
  );
}

interface IFormProps<FormItemT = any> {
  index: number;
  item: FormItemT;
  lastActiveForm: number | undefined;
  removeButtonPlaceholder?: string;
  removable: boolean;
  setLastActiveForm: (index: number) => void;
  onRemoveItem: (index: number) => void;
  renderIcon?: () => JSX.Element;
  renderForm: (data: RenderFormData<FormItemT>) => ReactElement;
  onChange?: (item: FormItemT, index: number) => void;
}

const Form = (props: IFormProps) => {
  const [isPromptingDeletion, setIsPromptingDeletion] = useState(false);

  useEffect(() => {
    // If we have focused another form and we are prompting deletion, hide the prompt
    setIsPromptingDeletion(false);
  }, [props.lastActiveForm]);

  return (
    <View
      onStartShouldSetResponderCapture={() => {
        // If this component gets touched, this form becomes our active form
        props.setLastActiveForm(props.index);

        return false;
      }}
      style={{
        flexDirection: 'row',
      }}
    >
      <View
        style={{
          paddingHorizontal: 10,
        }}
      >
        <View
          style={{
            justifyContent: 'flex-start',
            alignItems: 'center',
            flex: 1,
            paddingVertical: 6,
          }}
        >
          {props.renderIcon ? (
            props.renderIcon()
          ) : (
            <CheckMark fill="#0FFEA3" width={30} height={30} />
          )}
        </View>
      </View>
      <View style={{ flex: 1, paddingBottom: 8 }}>
        {props.renderForm?.({
          item: props.item,
          index: props.index,
          onChange: (item) => props.onChange?.(item, props.index),
        })}

        {isPromptingDeletion ? (
          <View
            style={{
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Text style={{ fontFamily: 'Lato-Bold' }}>Are you sure?</Text>
            <View style={{ flexDirection: 'row' }}>
              <Button
                label="Cancel"
                onPress={() => setIsPromptingDeletion(false)}
              />
              <Button
                onPress={() => props.onRemoveItem(props.index)}
                containerStyle={{
                  marginLeft: 8,
                }}
                label="Remove"
                labelStyle={{
                  color: 'white',
                }}
                style={{
                  backgroundColor: 'crimson',
                }}
              />
            </View>
          </View>
        ) : (
          <View style={{ flexDirection: 'row-reverse' }}>
            <Button
              disabled={!props.removable}
              label={
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <FontAwesome
                    size={17}
                    name="trash-o"
                    style={{
                      marginRight: 4,
                    }}
                    color={KEY_GRAY}
                  />
                  <Text
                    style={{
                      color: KEY_GRAY,
                      fontFamily: 'Lato-Bold',
                      fontSize: 17,
                    }}
                  >
                    {props.removeButtonPlaceholder || 'Remove'}
                  </Text>
                </View>
              }
              onPress={() => setIsPromptingDeletion(true)}
            />
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  addButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  addButton: {
    ...TEXT_INPUT,
    flex: 1,
    color: KEY_GRAY,
  },
  circle: {
    width: 30,
    height: 30,
    borderWidth: 2,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 8,
  },
});
