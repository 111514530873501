import { useLocales } from 'expo-localization';
import {
  TranslatableText,
  useGetTranslatableTextQuery,
} from '/generated/graphql';
import { useEffect } from 'react';

type UseTranslatableTextOptions = {
  data: Pick<TranslatableText, 'id' | 'language' | 'text'> | undefined | null;
  showTranslation: boolean;
  onTranslateError?: () => void;
};

type UseTranslatableTextReturns = {
  text: string;
  isTranslated: boolean;
  isTranslating: boolean;
};

export default function useTranslatableText({
  onTranslateError,
  ...options
}: UseTranslatableTextOptions): UseTranslatableTextReturns {
  const { languageCode } = useLocales()[0];

  const [{ data, fetching, error }] = useGetTranslatableTextQuery({
    variables: {
      id: options.data?.id as string,
      languageCode,
    },
    requestPolicy: 'cache-and-network',
    pause: !options.data?.id || !options.showTranslation,
  });

  useEffect(() => {
    if (error) {
      onTranslateError?.();
    }
  }, [error, onTranslateError]);

  return {
    text:
      ((options.showTranslation && data?.getTranslatableText?.text) ||
        options.data?.text) ??
      '',
    isTranslated:
      !!options.showTranslation && !!data?.getTranslatableText?.text,
    isTranslating: fetching,
  };
}
