// priority - lower number is higher priority

const ACTION_ALERTS = {
  ActionNeeded: {
    priority: 0,
    text: 'Action needed',
    color: '#D1096D',
  },
  NewMessage: {
    priority: 1,
    text: 'New message',
    color: '#007AE3',
  },
  // Probably should lose all of the following:
  SelectionMode: {
    priority: 2,
    text: 'Selection mode',
    color: '#007AE3',
  },
  WaitingForResponse: {
    priority: 3,
    text: 'Waiting for a response',
    color: '#FFBA00',
  },
  InProgress: {
    priority: 4,
    text: 'In Progress',
    color: '#289F75',
  },
} as const;

export default ACTION_ALERTS;
