import React from 'react';
import { Text, View } from 'react-native';
import { useCommonStyles } from '../styles';
import RecentActivityListItem from './elements/RecentActivityListItem';
import Activity from '/assets/jsicons/Activity';
import { SECTION_CONTAINER } from '/constants';
import { User, UserActivity } from '/generated/graphql';
import { DeepPartial } from '/types';

interface IData extends DeepPartial<Pick<User, 'recent_activity'>> {}

interface Props {
  data: IData | undefined | null;
}

export default function RecentActivity(props: Props) {
  const { styles: commonStyles } = useCommonStyles();

  return props.data?.recent_activity?.length ? (
    <View style={[SECTION_CONTAINER, { paddingHorizontal: 0 }]}>
      <View
        style={[
          commonStyles('sectionTitleContainer'),
          { justifyContent: 'flex-start', paddingLeft: 8 },
        ]}
      >
        <Activity width={28} height={28} />
        <Text style={[commonStyles('sectionTitle'), { paddingLeft: 8 }]}>
          RECENT ACTIVITY
        </Text>
      </View>

      {props.data?.recent_activity?.map((activity) => (
        <RecentActivityListItem
          key={activity.id}
          data={activity as UserActivity}
          user={props.data as User}
        />
      ))}
    </View>
  ) : null;
}
