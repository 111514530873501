import RemovableBadge from '/components/RemovableBadge';
import React, { useEffect, useState } from 'react';
import { Switch, Text, View } from 'react-native';
import Language from '/assets/jsicons/Language';
import Button from '/components/Button';
import ValidatedAny from '/components/ValidatedForm/ValidatedAny';
import { ValidatedFormFields } from '/components/ValidatedForm/ValidatedForm';
import { KEY_GREEN } from '/constants';
import styles from '/constants/CampaignBuilder/SkillImpact/SkillExpand';
import { useHabitatPickerContext } from '/context';
import { SkillRequest } from '/generated/graphql';

type Props = {
  fields: ValidatedFormFields;
  skill: Partial<SkillRequest>;
  onChange: (data: Partial<SkillRequest>) => void;
};

const Languages = ({ onChange, ...props }: Props) => {
  const requiredLangs = props.skill?.required_languages ?? [];
  const prefferedLangs = props.skill?.preferred_languages ?? [];

  const [lang, setLang] = useState('');

  const { setShowModal, setModalInfo, setDataType, select, setSelect }: any =
    useHabitatPickerContext();

  const [anyLanguageApply, setAnyLanguageApply] = useState(
    props.skill?.any_language_apply ?? false,
  );

  useEffect(() => {
    onChange({
      any_language_apply: anyLanguageApply,
    });
  }, [anyLanguageApply, onChange]);

  useEffect(() => {
    if (!select) return;

    if (lang === 'required') {
      setRequiredLangs([...requiredLangs, select]);
    } else if (lang === 'preferred') {
      setPreferredLangs([...prefferedLangs, select]);
    }

    setLang('');
    setSelect('');
  }, [select]);

  const setRequiredLangs = (langs: string[]) => {
    onChange({
      required_languages: langs,
    });
  };

  const setPreferredLangs = (langs: string[]) => {
    onChange({
      preferred_languages: langs,
    });
  };

  return (
    <ValidatedAny
      name="languages"
      value={props.skill?.required_languages}
      validate={(value) => {
        // Valid if "Any language can apply is selected",
        // or if at least one language is selected
        return anyLanguageApply || !!value?.length;
      }}
    >
      <View style={styles.itemContainers}>
        <View style={styles.itemContentRows}>
          <View style={styles.itemContentIconContainer}>
            <Language width={38} height={38} fill="#555555" />
          </View>
          <Text style={styles.itemContentTitle}>Languages Needed</Text>
        </View>
        <View style={styles.itemContentRows}>
          <Text style={styles.itemBodyText}>
            What language do you need this skilled professional to speak or
            understand to help with this skill request? Select one or multiple
            languages.
          </Text>
        </View>
        <View
          style={[
            styles.itemContentRows,
            {
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          ]}
        >
          <Text style={[styles.itemBodyText, { fontFamily: 'Lato-Bold' }]}>
            Any language can apply
          </Text>
          <Switch
            trackColor={{ true: KEY_GREEN }}
            // thumbColor={isEnabled ? '#f5dd4b' : '#f4f3f4'}
            // ios_backgroundColor="#3e3e3e"
            onValueChange={() => setAnyLanguageApply(!anyLanguageApply)}
            value={anyLanguageApply}
          />
        </View>
        <View
          style={{
            display: anyLanguageApply ? 'none' : 'flex',
          }}
        >
          <View
            style={[
              styles.itemContentRows,
              props.fields.languages?.valid == false
                ? {
                    borderColor: 'crimson',
                    borderWidth: 1,
                  }
                : null,
            ]}
          >
            <Text style={[styles.itemBodyText, { fontFamily: 'Lato-Bold' }]}>
              Our supporter must speak the following language(s)
            </Text>
            <Button
              label="Add languages"
              containerStyle={{
                width: '100%',
                marginVertical: 10,
              }}
              onPress={() => {
                setLang('required');
                setDataType('lang');
                setShowModal(true);
                setModalInfo({ search: true });
              }}
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {requiredLangs?.map((lang, i) => (
              <RemovableBadge
                key={i}
                label={lang}
                onRemove={() => {
                  setRequiredLangs(requiredLangs.filter((l) => l !== lang));
                }}
              />
            ))}
          </View>
          <View style={styles.itemContentRows}>
            <Text style={[styles.itemBodyText, { fontFamily: 'Lato-Bold' }]}>
              Would like our supporter to speak the following language(s) but
              they are not required
            </Text>
            <Button
              label="Add languages"
              containerStyle={{
                width: '100%',
                marginVertical: 10,
              }}
              onPress={() => {
                setLang('preferred');
                setDataType('lang');
                setShowModal(true);
                setModalInfo({ search: true });
              }}
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {prefferedLangs?.map((lang, i) => (
              <RemovableBadge
                key={i}
                label={lang}
                onRemove={() => {
                  setPreferredLangs(prefferedLangs.filter((l) => l !== lang));
                }}
              />
            ))}
          </View>
        </View>
      </View>
    </ValidatedAny>
  );
};

export default Languages;
