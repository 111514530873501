import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  AlertButton as RNAlertButton,
  Animated,
  ColorValue,
  Easing,
  StyleSheet,
  Text,
  TextInput,
  useWindowDimensions,
  View,
  Keyboard,
  Platform,
} from 'react-native';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
// @ts-ignore -- Types unavailable for this library
import TinyEmitter from 'tiny-emitter/instance';
import { v4 } from 'uuid';
import Alert from '/Alert';
import Button from '/components/Button';
import NetworkErrorAlert from '/components/NetworkErrorAlert';
import SmallAlert from '/components/SmallAlert';
import { KEY_GRAY, TEXT_INPUT, TEXT_INPUT_LARGE } from '/constants';

export type AlertPressEvent<T = any> = {
  // If called, alert will not close after being pressed
  preventDefault: () => void;
  /** If Alert contains fields, this will contain their values when button was pressed */
  values: { [fieldName: string]: T };
};

export type AlertButton = Pick<RNAlertButton, 'style' | 'text'> & {
  onPress?: (e: AlertPressEvent) => void;
};

export type AlertField<T = any> = {
  name: string;
  /** Default is `text` */
  type?: 'text' | 'text-large'; // We can extend this to allow for other forms of input in the future
  /** Default is `80` */
  maxLength?: number;
  initialValue?: T;
  /** By default, this is empty */
  placeholder?: T;
  /** Optionally define a custom input component */
  renderComponent?: (
    value: T,
    setValue: (value: T) => void,
    field: AlertField<T>,
  ) => JSX.Element | (() => JSX.Element);
  /** Optionally apply transformations to text whenever onChangeText is called ont the field */
  transformText?: (text: string) => string;
  /** Optionally show a label above the input field */
  label?: string;
};

type AlertState = {
  title: string;
  message?: string;
  buttons?: AlertButton[];
  fields?: AlertField[];
};

export type SmallAlertAwaitPromise = {
  promise: Promise<any>;
  successMessage: string;
  successColor?: ColorValue;
  successTextColor?: ColorValue;
  errorMessage: string;
  errorColor?: ColorValue;
  errorTextColor?: ColorValue;
};

type SmallAlertState = {
  message: string;
  color?: ColorValue;
  textColor?: ColorValue;
  /** If provided, the alert will close after `duration`ms. If `awaitPromise` is also
   * provided, the duration will not count down until `awaitPromise` resolves. */
  duration?: number;
  /** If provided, the alert will not close until `duration`ms after the promise resolves */
  awaitPromise?: SmallAlertAwaitPromise;
};

type AlertProviderState = {
  alerts: { [id: string]: AlertState };
  smallAlerts: { [id: string]: SmallAlertState };
};

export default function AlertProvider(props: React.PropsWithChildren<{}>) {
  const [state, setState] = useState<AlertProviderState>({
    alerts: {},
    smallAlerts: {},
  });

  const { width: window_width } = useWindowDimensions();

  const { top: safeAreaInsetTop } = useSafeAreaInsets();

  const [keyboardOffset, setKeyboardOffset] = useState(0);

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      (e) => {
        // Adjust the bottom position based on keyboard height
        setKeyboardOffset(e.endCoordinates.height);
      },
    );
    const keyboardHideListener = Keyboard.addListener(
      Platform.select({
        default: 'keyboardWillHide',
        android: 'keyboardDidHide',
      }),
      () => {
        // Reset the bottom position
        setKeyboardOffset(0);
      },
    );

    return () => {
      keyboardDidShowListener.remove();
      keyboardHideListener.remove();
    };
  }, []);

  const addAlert = useCallback(
    (
      title: string,
      message?: string,
      buttons?: AlertButton[],
      fields?: AlertField[],
    ) => {
      setState((prevState) => {
        return {
          ...prevState,
          alerts: {
            ...prevState.alerts,
            [v4()]: {
              title: title,
              message: message,
              buttons: buttons,
              fields: fields,
            },
          },
        };
      });
    },
    [setState],
  );

  const addSmallAlert = useCallback(
    (
      message: string,
      color?: ColorValue,
      textColor?: ColorValue,
      closeAfterDuration?: number,
      awaitPromise?: SmallAlertAwaitPromise,
    ) => {
      setState((prevState) => ({
        ...prevState,
        smallAlerts: {
          ...prevState.smallAlerts,
          [v4()]: {
            message,
            color,
            textColor,
            closeAfterDuration,
            awaitPromise,
          },
        },
      }));
    },
    [setState],
  );

  useEffect(() => {
    TinyEmitter.on(
      'alert',
      (
        title: string,
        message?: string,
        buttons?: (AlertButton | undefined)[],
        fields?: AlertField[],
      ) => {
        addAlert(
          title,
          message,
          buttons?.filter((b) => b !== undefined) as AlertButton[] | undefined,
          fields,
        );
      },
    );

    TinyEmitter.on(
      'alert-notify',
      (
        message: string,
        color?: ColorValue,
        textColor?: ColorValue,
        duration?: number,
        awaitPromise?: SmallAlertAwaitPromise,
      ) => {
        addSmallAlert(message, color, textColor, duration, awaitPromise);
      },
    );

    // Initialize Alert
    Alert.__setReady();

    return () => {
      TinyEmitter.off('alert');
      TinyEmitter.off('alert-notify');
    };
  }, [addAlert, addSmallAlert]);

  const onCloseAlert = useCallback(
    (id: string) => {
      // Remove alert from state after it has been closed
      setState((prevState) => {
        const newState = { ...prevState };

        delete newState.alerts[id];

        return newState;
      });
    },
    [setState],
  );

  const onCloseSmallAlert = useCallback(
    (id: string) => {
      // Remove alert from state after it has been closed
      setState((prevState) => {
        const newState = Object.assign({}, prevState);

        delete newState.smallAlerts[id];

        return newState;
      });
    },
    [setState],
  );

  return (
    <>
      {props.children}
      {Object.entries(state.alerts).map(([key, value]) => {
        return (
          <AlertComponent
            key={key}
            keyboardOffset={keyboardOffset}
            state={value}
            onClose={() => onCloseAlert(key)}
          />
        );
      })}
      <View
        pointerEvents="box-none"
        style={[StyleSheet.absoluteFill, { overflow: 'hidden' }]}
      >
        <View
          pointerEvents="box-none"
          style={{
            position: 'absolute',
            width: '100%',
            minWidth: 280,
            maxWidth: 480,
            left: window_width >= 450 ? undefined : 0,
            right: window_width >= 450 ? 40 : 0,
            alignItems: window_width >= 450 ? 'flex-end' : 'center',
            bottom: window_width >= 450 ? 100 : undefined,
            top: window_width < 450 ? safeAreaInsetTop : undefined,
            zIndex: 9999,
            elevation: 9999,
          }}
        >
          {Object.entries(state.smallAlerts).map(([key, value]) => {
            return (
              <SmallAlertContainer
                key={key}
                alert={value}
                animationDirection={
                  window_width >= 450 ? 'slide-up' : 'slide-down'
                }
                visible={!!state.smallAlerts[key]}
                onClose={() => onCloseSmallAlert(key)}
              />
            );
          })}
          <NetworkErrorAlert />
        </View>
      </View>
    </>
  );
}

type SmallAlertContainerProps = {
  alert: SmallAlertState;
  animationDirection: 'slide-up' | 'slide-down';
  visible: boolean;
  onClose: () => void;
};

function SmallAlertContainer(props: SmallAlertContainerProps) {
  const [height, setHeight] = useState(0);

  return (
    <SmallAlert
      style={{
        marginTop: -Math.max(0, height - 56),
      }}
      animationDirection={props.animationDirection}
      {...props.alert}
      visible={props.visible}
      onClose={props.onClose}
      onLayout={({ nativeEvent }) => {
        setHeight(nativeEvent.layout.height);
      }}
    />
  );
}

interface IAlertComponentProps {
  onClose: () => void;
  state: AlertState;
  keyboardOffset: number;
}

/* Alert component */
function AlertComponent(props: IAlertComponentProps) {
  const { styles } = useStyles();

  const [fieldValues, setFieldValues] = useState<{
    [fieldName: string]: any;
  }>({});

  const opacityAnimation = useRef(new Animated.Value(0.001));

  const animateOpacity = (targetOpacity: number, callback?: () => void) =>
    Animated.timing(opacityAnimation.current, {
      toValue: targetOpacity,
      duration: 250,
      easing: Easing.out(Easing.poly(4)),
      useNativeDriver: true,
    }).start(callback);

  useEffect(() => {
    animateOpacity(1);

    // Map fields to an object we can store in state
    const stateFieldMap: typeof fieldValues = {};

    props.state.fields?.forEach((field) => {
      // Make sure no duplicate names are present in props.state.fields. If there are, issue a warning.
      // If field has been previously added to fieldNameMap, we have a duplicate
      if (stateFieldMap[field.name]) {
        console.warn(
          `[AlertProvider] An Alert was created with two or more fields that have the same name (${field.name}). This will cause unexpected behavior.`,
        );
      } else {
        stateFieldMap[field.name] = field.initialValue ?? undefined;
      }
    });

    setFieldValues(stateFieldMap);
  }, [props.state.fields]);

  const closeAlert = () => {
    animateOpacity(0, props.onClose);
  };

  const interpolatedScale = opacityAnimation.current.interpolate({
    inputRange: [0, 1],
    outputRange: [1.2, 1],
  });

  return (
    <Animated.View
      onStartShouldSetResponder={() => {
        Keyboard.dismiss();
        return false;
      }}
      style={[
        styles('backdrop'),
        {
          opacity: opacityAnimation.current,
          marginBottom: props.keyboardOffset,
          justifyContent: props.keyboardOffset > 0 ? 'flex-end' : 'center',
        },
      ]}
    >
      <Animated.View
        style={[
          styles('alertDialog'),
          {
            transform: [{ scale: interpolatedScale }],
          },
        ]}
      >
        <View style={styles('titleContainer')}>
          <Text style={styles('title')}>{props.state.title ?? 'Alert'}</Text>
        </View>
        {!!props.state.message?.trim() && (
          <Text style={styles('message')}>{props.state.message}</Text>
        )}
        {props.state.fields?.map((field, index) => {
          // Helper function
          const setFieldValue = (value: any) => {
            setFieldValues((prevState) => ({
              ...prevState,
              [field.name]: value,
            }));
          };

          // If a custom component was provided, render that.
          if (field.renderComponent) {
            if (typeof field.renderComponent !== 'function') {
              console.warn(
                '[AlertProvider] An Alert was created with in invalid `component` option. Make sure `component` is a function returning a valid JSX element or React component',
              );
              return;
            }

            const Component = field.renderComponent(
              fieldValues[field.name] ?? '',
              setFieldValue,
              field,
            );

            if (React.isValidElement(Component)) {
              return <View key={index}>{Component}</View>;
            } else if (
              typeof Component === 'function' &&
              React.isValidElement(<Component />)
            ) {
              return (
                <View key={index}>
                  <Component />
                </View>
              );
            } else {
              // If we cannot verify that the component is valid, issue a warning
              console.warn(
                '[AlertProvider] `component` option is invalid. Please make sure you are returning a valid JSX element or React component.',
              );
            }
          }

          // Otherwise, render a component based on field.type
          switch (field.type) {
            /** TEXT INPUT */
            case 'text':
            case 'text-large':
            default: {
              const style =
                field.type === 'text-large' ? TEXT_INPUT_LARGE : TEXT_INPUT;

              return (
                <View key={index}>
                  {typeof field.label === 'string' && field.label.trim() ? (
                    <Text style={styles('defaultFieldLabel')}>
                      {field.label}
                    </Text>
                  ) : null}
                  <TextInput
                    multiline={field.type === 'text-large'}
                    style={[style, { flex: undefined }]}
                    maxLength={field.maxLength ?? 80}
                    clearButtonMode="always"
                    value={fieldValues[field.name] ?? ''}
                    placeholder={field.placeholder}
                    placeholderTextColor={'gray'}
                    enterKeyHint="done"
                    onChangeText={(text) => {
                      if (typeof field.transformText === 'function') {
                        text = field.transformText(text);
                      }

                      setFieldValue(text);
                    }}
                  />
                </View>
              );
            }
          }
        })}
        <View
          style={{
            paddingTop: 8,
            flexDirection:
              props.state.buttons && props.state.buttons?.length > 2
                ? 'column'
                : 'row',
          }}
        >
          {(
            props.state.buttons ?? [
              {
                style: 'default',
                text: 'Dismiss',
              },
            ]
          ).map((button, index) => {
            let label = button.text;

            if (!label?.trim()) {
              if (button.style === 'cancel') label = 'Cancel';
              else label = 'Dismiss';
            }

            function onButtonPress() {
              // Allow caller to prevent Alert from closing
              let _preventDefault = false;

              button.onPress?.({
                preventDefault: () => {
                  _preventDefault = true;
                },
                values: fieldValues,
              });

              if (!_preventDefault) closeAlert();
            }

            return (
              <Button
                key={index}
                label={label}
                onPress={onButtonPress}
                containerStyle={[
                  styles('button'),
                  (props.state.buttons?.length ?? 1) <= 2 && {
                    flex: 1,
                  },
                ]}
                labelStyle={{
                  fontFamily: button.style === 'default' ? 'Lato-Bold' : 'Lato',
                  color: button.style === 'destructive' ? 'crimson' : KEY_GRAY,
                }}
              />
            );
          })}
        </View>
      </Animated.View>
    </Animated.View>
  );
}

const useStyles = CreateResponsiveStyle(
  {
    backdrop: {
      ...StyleSheet.absoluteFillObject,
      flex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 99999,
      elevation: 99999,
      padding: 8,
    },
    alertDialog: {
      width: 270,
      overflow: 'hidden',
      backgroundColor: 'white',
      borderRadius: 6,
      padding: 12,
    },
    titleContainer: {
      paddingBottom: 8,
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      flex: 1,
      fontFamily: 'Lato-Bold',
      textAlign: 'center',
      fontSize: 21,
      paddingHorizontal: 8,
    },
    message: {
      width: '100%',
      textAlign: 'center',
      fontFamily: 'Lato',
      fontSize: 16,
      padding: 4,
      paddingVertical: 8,
      maxHeight: 360,
    },
    button: {
      shadowOpacity: 0,
      margin: 2,
    },
    defaultFieldLabel: {
      fontFamily: 'LeagueSpartan-Bold',
      fontSize: 15,
      textTransform: 'uppercase',
      marginTop: 4,
      marginBottom: 2,
    },
  },
  {
    [minSize(DEVICE_SIZES.LARGE_DEVICE)]: {
      alertDialog: {
        width: 350,
      },
    },
  },
);
