import {
  Entypo,
  FontAwesome,
  FontAwesome5,
  Ionicons,
} from '@expo/vector-icons';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { useHeaderHeight } from '@react-navigation/elements';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Viewport } from '@skele/components';
import dayjs from 'dayjs';
import { ResizeMode } from 'expo-av';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useState } from 'react';
import {
  Image,
  Pressable,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import Hyperlink from 'react-native-hyperlink';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Avatar from '../Avatar';
import BigIssuesCarousel from '../BigIssues/BigIssuesCarousel';
import CommentsButton from '../CampaignPost/elements/CommentsButton';
import CampaignPreview from '../CampaignPreview/CampaignPreview';
import IUCNStatusImage from '../IUCNStatusImage/IUCNStatusImage';
import LikeButtonComponent from '../LikeButtonComponent';
import LoadingOverlay from '../LoadingOverlay';
import ShareButton from '../Sharing/ShareButton';
import VideoPlayer from '../VideoPlayer';
import HorizontalContainer from '../common/Generic/HorizontalContainer';
import SectionContainer from '../common/Section/SectionContainer';
import TranslateButton from '../common/TranslatableText/TranslateButton';
import styles from './MomentInNatureComponent.style';
import MapMarker from '/assets/jsicons/headerIcons/map-marker';
import ChevronRight from '/assets/jsicons/miscIcons/ChevronRight';
import {
  HYPERLINK_STYLE,
  KEY_GRAY,
  KEY_LIGHT_GRAY,
  SECTION_CONTAINER,
} from '/constants';
import { useVideoPlayerContext } from '/context';
import {
  Campaign,
  CampaignPost,
  Location,
  MomentInNature as MomentInNatureType,
  NewSpecies,
  OrganizationBigIssue,
  PostInteractions,
  SpeciesIucnThreatStatus,
  User,
  useAddLikeMutation,
  useRemoveLikeMutation,
} from '/generated/graphql';
import { TranslationProp } from '/types/translation';
import { createUniversalURL, shorten } from '/util';
import getCDNImageUri from '/util/getCDNImageUri';

interface IMomentInNatureSpecies
  extends Pick<
    NewSpecies,
    'taxonID' | 'thumbnail' | 'preferredVernacularName' | 'canonicalName'
  > {
  iucnThreatStatus?:
    | Pick<SpeciesIucnThreatStatus, 'taxonID' | 'threatStatus'>
    | undefined
    | null;
}

interface IMomentInNatureCampaign
  extends Pick<Campaign, 'id' | 'name' | 'created_at'> {
  original_post?: Pick<CampaignPost, 'id' | 'thumbnail' | 'media'>;
  user: Pick<User, 'id' | 'name' | 'profile_image'>;
}

interface IMomentInNatureOrganizationBigIssue
  extends Pick<OrganizationBigIssue, 'id' | 'title'> {}

interface IMomentInNatureUser
  extends Pick<User, 'id' | 'name' | 'profile_image'> {}

interface IMomentInNatureInteractions
  extends Pick<PostInteractions, 'id' | 'likes' | 'is_liked'> {
  comments: {
    total: number;
  };
}

export interface IMomentInNature
  extends Pick<
    MomentInNatureType,
    | 'id'
    | 'userId'
    | 'video'
    | 'is_good_news'
    | 'caption'
    | 'good_news_description_translatable'
    | 'created_at'
  > {
  interactions: IMomentInNatureInteractions | undefined | null;
  campaigns: { items: IMomentInNatureCampaign[] } | undefined | null;
  big_issues:
    | { items: IMomentInNatureOrganizationBigIssue[] }
    | undefined
    | null;
  user: IMomentInNatureUser | undefined | null;
  location?: Pick<Location, 'name'> | undefined | null;
  new_species: {
    species: IMomentInNatureSpecies | undefined | null;
    vernacularName?: string | null;
  }[];
}

interface Props {
  data: IMomentInNature | undefined | null;
  isMuted?: boolean;
  safeAreaInsetTop?: number;
  onScrollToContent: ((yOffset: number) => void) | null;
  translation?: TranslationProp;
}

const MIN_MEDIA_HEIGHT = 480;
const MAX_MEDIA_HEIGHT = 1080;

export default function MomentInNatureComponent(props: Props) {
  const { push } = useNavigation<StackNavigationProp<any>>();

  const safeAreaInsets = useSafeAreaInsets();

  const { state, _setMute } = useVideoPlayerContext();

  const [loading, setLoading] = useState(true);

  const windowDimensions = useWindowDimensions();
  const tabBarHeight = useBottomTabBarHeight();
  const headerHeight = useHeaderHeight();

  const mediaHeight = Math.min(
    windowDimensions.height - tabBarHeight - headerHeight,
    MAX_MEDIA_HEIGHT,
  );

  const [, addLike] = useAddLikeMutation();
  const [, removeLike] = useRemoveLikeMutation();

  function toggleMute() {
    _setMute(!state.mute);
  }

  function goToProfile() {
    if (!props.data?.user?.id) return;

    push('Profile', { id: props.data?.user.id });
  }

  function goToSpecies(
    taxonID: number,
    vernacularName: string | undefined | null,
  ) {
    push('SpeciesSummary', { taxonID, name: vernacularName });
  }

  function goToCampaign(id: string) {
    push('Campaign', { campaignId: id });
  }

  function onLikeButtonPressed() {
    if (!props.data?.interactions?.id) return;

    if (props.data.interactions.is_liked) {
      removeLike({ interactionsId: props.data.interactions.id });
    } else {
      addLike({ interactionsId: props.data.interactions.id });
    }
  }

  function scrollToContent() {
    props.onScrollToContent?.(mediaHeight);
  }

  return (
    <Viewport.Tracker>
      <View style={{ flex: 1 }}>
        {/* VIDEO */}
        <View
          style={{
            minHeight: MIN_MEDIA_HEIGHT,
          }}
        >
          <LoadingOverlay loading={loading} />

          <LinearGradient
            colors={['rgba(10, 10, 10, 0.8)', 'transparent']}
            style={[
              styles.linearGradient,
              styles.videoHeader,
              {
                height: 64 + safeAreaInsets.top,
              },
            ]}
          />

          <View
            style={[
              StyleSheet.absoluteFill,
              {
                minHeight: MIN_MEDIA_HEIGHT,
              },
            ]}
          >
            <Image
              style={{
                width: '100%',
                height: '100%',
              }}
              resizeMode={'cover'}
              source={{
                uri: getCDNImageUri({
                  uri: props.data?.video ?? '',
                  isThumbnail: true,
                }),
              }}
            />
          </View>

          <VideoPlayer
            sourceUri={props.data?.video}
            isLooping
            hideControls
            shouldPlay
            mode="autoplay-viewport"
            thumbnailSource={{
              uri: getCDNImageUri({
                uri: props.data?.video ?? '',
                isThumbnail: true,
              }),
            }}
            onLoadStart={() => setLoading(true)}
            onLoad={() => setLoading(false)}
            onError={() => setLoading(false)}
            isMuted={props.isMuted ?? state.mute}
            resizeMode={ResizeMode.COVER}
            style={[
              styles.video,
              {
                minHeight: MIN_MEDIA_HEIGHT,
                height: mediaHeight,
                justifyContent: 'center',
              },
            ]}
          />

          <LinearGradient
            colors={['transparent', 'rgba(10, 10, 10, 0.8)']}
            style={[styles.linearGradient, styles.videoFooter]}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Pressable
                style={({ pressed }) => [
                  styles.muteButton,
                  {
                    opacity: pressed ? 0.5 : 1,
                  },
                ]}
                onPress={toggleMute}
              >
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  {state.mute ? (
                    <Ionicons
                      name="volume-mute"
                      color="rgb(220, 220, 220)"
                      size={24}
                    />
                  ) : (
                    <Ionicons
                      name="volume-high"
                      color="rgb(255, 255, 255)"
                      size={24}
                    />
                  )}
                </View>
              </Pressable>

              <HorizontalContainer>
                <LikeButtonComponent
                  size={40}
                  returnToOnLogIn={`/moment-in-nature/${props.data?.id}`}
                  likeCount={props.data?.interactions?.likes ?? 0}
                  isLiked={props.data?.interactions?.is_liked ?? false}
                  onPress={onLikeButtonPressed}
                />
                <CommentsButton
                  size={40}
                  commentCount={props.data?.interactions?.comments.total ?? 0}
                  interactionsId={props.data?.interactions?.id ?? ''}
                />
                <ShareButton
                  url={createUniversalURL('moment-in-nature/' + props.data?.id)}
                  shareMessage={`Check out this Moment in Nature post by ${shorten(
                    props.data?.user?.name || '',
                    64,
                  )} on Key Conservation!`}
                />
                <Entypo
                  onPress={() => {
                    scrollToContent();
                  }}
                  name="chevron-down"
                  size={28}
                  color="white"
                  style={{
                    alignSelf: 'center',
                    padding: 16,
                  }}
                />
              </HorizontalContainer>
            </View>
          </LinearGradient>
        </View>

        {/* AUTHOR & CAPTION */}
        <SectionContainer>
          <Pressable
            onPress={goToProfile}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 10,
            }}
          >
            <Avatar
              source={{
                uri: props.data?.user?.profile_image ?? '',
              }}
              rounded
              size={42}
            />
            <View style={{ marginLeft: 8, flex: 1 }}>
              <Text style={styles.authorNameText}>
                {props.data?.user?.name}
              </Text>
              {props.data?.location?.name ? (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <MapMarker fill={KEY_GRAY} width={16} height={16} />
                  <Text style={styles.locationText}>
                    {` `}
                    {props.data?.location?.name}
                  </Text>
                </View>
              ) : null}
            </View>
          </Pressable>

          {props.data?.caption.text.trim() ? (
            <>
              {props.translation ? (
                <TranslateButton
                  isTranslated={props.translation.isTranslated}
                  isTranslating={props.translation.isTranslating}
                  onPress={() => {
                    props.translation?.onToggleTranslate();
                  }}
                  fromLanguage={props.data?.caption.language}
                />
              ) : null}
              <Hyperlink linkDefault linkStyle={HYPERLINK_STYLE}>
                <Text style={styles.sectionText}>
                  {props.data?.caption.text}
                </Text>
              </Hyperlink>
            </>
          ) : null}
          <Text style={styles.timestampText}>
            Posted
            {` `}
            {dayjs(
              !props.data?.created_at
                ? Date.now()
                : isNaN(Number(props.data?.created_at))
                ? props.data?.created_at
                : Number(props.data?.created_at),
            ).fromNow()}
          </Text>
        </SectionContainer>

        {/* SPECIES */}
        {props.data?.new_species?.length ? (
          <View style={SECTION_CONTAINER}>
            <Text style={styles.sectionTitle}>SPECIES IN THIS POST</Text>
            <Text style={styles.sectionText}>
              Click each species to learn more
            </Text>

            {props.data?.new_species.map((species, index) => {
              if (!species) return null;

              return (
                <Pressable
                  key={species.species?.taxonID}
                  onPress={() => {
                    if (species.species?.taxonID)
                      goToSpecies(
                        species.species.taxonID,
                        species.vernacularName,
                      );
                  }}
                  style={({ pressed }) => [
                    styles.speciesListItem,
                    {
                      borderTopWidth: index !== 0 ? 1 : 0,
                      borderTopColor: KEY_LIGHT_GRAY,
                      opacity: pressed ? 0.5 : 1,
                    },
                  ]}
                >
                  {species.species?.thumbnail ? (
                    <Avatar
                      source={{ uri: species.species.thumbnail }}
                      rounded
                      size={48}
                    />
                  ) : (
                    <View style={styles.speciesThumbnailPlaceholder}>
                      <FontAwesome name="paw" size={24} color={KEY_GRAY} />
                    </View>
                  )}

                  <IUCNStatusImage
                    style={{
                      marginLeft: 6,
                    }}
                    width={48}
                    height={48}
                    redlist_category={
                      species.species?.iucnThreatStatus?.threatStatus
                    }
                  />

                  <View style={{ flex: 1, marginLeft: 6 }}>
                    <Text style={styles.speciesVernacularName}>
                      {species.vernacularName ||
                        species.species?.preferredVernacularName}
                    </Text>
                    <Text style={styles.speciesCanonicalName}>
                      {species.species?.canonicalName}
                    </Text>
                  </View>

                  <ChevronRight />
                </Pressable>
              );
            })}
          </View>
        ) : null}

        {/* CAMPAIGNS */}
        {props.data?.campaigns?.items.length ? (
          <>
            <View style={SECTION_CONTAINER}>
              <Text style={styles.sectionTitle}>HELP PROTECT THIS MOMENT</Text>
              <Text style={styles.sectionText}>
                Here are campaigns you can get involved in to help this
                organization protect moments like this.
              </Text>
            </View>

            {props.data?.campaigns.items.map((campaign) => (
              <Pressable
                key={campaign.id}
                style={[
                  SECTION_CONTAINER,
                  { padding: 0, borderRadius: 6, overflow: 'hidden' },
                ]}
              >
                <CampaignPreview
                  onPress={() => goToCampaign(campaign.id)}
                  campaign={campaign}
                  showChevronArrow
                  campaignPost={campaign.original_post}
                />
              </Pressable>
            ))}
          </>
        ) : null}

        {/* BIG ISSUES */}
        {props.data?.big_issues?.items.length ? (
          <View style={SECTION_CONTAINER}>
            <Text style={styles.sectionTitle}>THE BIG ISSUES</Text>
            <Text style={styles.sectionText}>
              These are the main problems that we face trying to protect this
              moment.
            </Text>

            <BigIssuesCarousel data={props.data?.big_issues.items} />
          </View>
        ) : null}

        {/* GOOD NEWS */}
        {props.data?.is_good_news &&
        props.data.good_news_description_translatable?.text ? (
          <View style={SECTION_CONTAINER}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 4,
              }}
            >
              <FontAwesome5
                name="key"
                size={18}
                style={{
                  marginRight: 6,
                }}
                color="black"
              />
              <Text style={styles.sectionTitle}>GOOD NEWS</Text>
            </View>
            <Text style={styles.sectionText}>
              The organization has marked this moment as Good News:{`\n`}
            </Text>
            {props.translation ? (
              <TranslateButton
                isTranslated={props.translation.isTranslated}
                isTranslating={props.translation.isTranslating}
                onPress={() => {
                  props.translation?.onToggleTranslate();
                }}
                fromLanguage={
                  props.data?.good_news_description_translatable.language
                }
              />
            ) : null}
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Avatar
                rounded
                source={{ uri: props.data.user?.profile_image || '' }}
                size={20}
                containerStyle={{
                  marginRight: 4,
                  marginVertical: 2,
                }}
              />
              <Hyperlink linkDefault linkStyle={HYPERLINK_STYLE}>
                <Text style={styles.goodNewsDescriptionText}>
                  "{props.data?.good_news_description_translatable.text}"
                </Text>
              </Hyperlink>
            </View>
          </View>
        ) : null}
      </View>
    </Viewport.Tracker>
  );
}
