import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useEffect, useRef, useState } from 'react';
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  StyleSheet,
  View,
} from 'react-native';
import Alert from '/Alert';
import ScrollView from '/components/common/ScrollView/ScrollView';
import LoadingOverlay from '/components/LoadingOverlay';
import OnboardHeader from '/components/Onboarding/OnboardHeader';
import TakeSurveyComponent from '/components/Surveys/TakeSurveyComponent';
import { DESKTOP_MAX_WIDTH } from '/constants';
import {
  SurveyResponseInput,
  useCreateSurveySubmissionMutation,
  useGetSystemSurveyQuery,
} from '/generated/graphql';

interface Props {
  navigation: StackNavigationProp<any>;
  route: RouteProp<any>;
}

function TakeSurveyScreen(props: Props) {
  /** Route params */
  const surveyPlacement = props.route.params?.placement;

  const scrollViewRef = useRef<any>();

  const [progress, setProgress] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [onScroll, setOnScroll] =
    useState<(e: NativeSyntheticEvent<NativeScrollEvent>) => void>();

  const [{ data, fetching, error }, refetch] = useGetSystemSurveyQuery({
    variables: { placement: surveyPlacement, submissionsLimit: 0 },
    pause: !surveyPlacement,
  });

  const [{ fetching: submitting, error: submitError }, submitSurvey] =
    useCreateSurveySubmissionMutation();

  useEffect(() => {
    if (error) {
      Alert.alert('Oh no', 'Failed to fetch survey', [
        {
          text: 'Try Again',
          style: 'default',
          onPress: () => {
            refetch();
          },
        },
        {
          text: 'Go Back',
          style: 'cancel',
          onPress: () => {
            props.navigation.goBack();
          },
        },
      ]);
    }
  }, [error, refetch, props.navigation]);

  useEffect(() => {
    if (submitError) {
      Alert.alert(
        'Oh no',
        'We ran into a problem while submitting your form. Please try again later.',
      );
    }
  }, [submitError]);

  function exit() {
    if (props.navigation.canGoBack()) {
      props.navigation.goBack();
    } else {
      props.navigation.navigate('main');
    }
  }

  function onGoBack() {
    if (currentPageIndex !== 0) {
      setCurrentPageIndex(currentPageIndex - 1);
      scrollViewRef.current?.scrollTo(0);
    } else {
      exit();
    }
  }

  async function onSubmitSurvey(responses: SurveyResponseInput[]) {
    if (!data?.getSystemSurvey.survey?.id) return;

    try {
      const params = {
        responses,
        ...(surveyPlacement
          ? { placement: surveyPlacement }
          : { surveyId: data.getSystemSurvey.survey.id }),
      };

      const { error: err } = await submitSurvey({
        input: params,
      });
      if (err) throw err;

      exit();

      Alert.alert('Submitted successfully');
    } catch (err) {
      Alert.alert(
        'Oh no',
        'We ran into a problem while submitting your form. Please try again later.',
      );
      console.log('Error submitting survey', err);
    }
  }

  return (
    <View
      style={{
        flex: 1,
        paddingTop: 110,
        backgroundColor: 'white',
      }}
    >
      <OnboardHeader onGoBack={onGoBack} progress={progress} />
      <ScrollView
        key={currentPageIndex}
        ref={(r) => {
          if (r) scrollViewRef.current = r;
        }}
        onScroll={onScroll}
        scrollEventThrottle={50}
        style={{ flex: 1 }}
        contentContainerStyle={styles.contentContainer}
      >
        {data?.getSystemSurvey.survey.questions.length ? (
          <TakeSurveyComponent
            style={DESKTOP_MAX_WIDTH}
            questions={data?.getSystemSurvey.survey?.questions}
            scrollViewRef={scrollViewRef}
            currentPageIndex={currentPageIndex}
            onCurrentPageIndexChange={setCurrentPageIndex}
            onSubmit={onSubmitSurvey}
            onScrollChanged={setOnScroll}
            onGoBack={exit}
            onChangeProgress={setProgress}
            questionsPerPage={20}
          />
        ) : null}
      </ScrollView>

      {/* LOADING OVERLAY */}
      <LoadingOverlay
        loading={submitting || fetching}
        label={submitting ? 'Submitting...' : ''}
      />
    </View>
  );
}

export default TakeSurveyScreen;

const styles = StyleSheet.create({
  contentContainer: {
    minHeight: '100%',
    padding: 8,
    paddingBottom: 42,
  },
});
