import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';
import { GetNotificationsForUserDocument } from '/generated/graphql';

const markAllNotificationsRead: UpdateResolver = (result, args, cache) => {
  cache.updateQuery(
    {
      query: GetNotificationsForUserDocument,
      variables: {
        userId: args.userId,
      },
    },
    (data) => {
      return {
        __typename: 'Query',
        ...data,
        getNotificationsForUser: {
          __typename: 'GetNotificationsForUserResponse',
          ...(data as any)?.getNotificationsForUser,
          has_unreads: false,
        },
      };
    },
  );

  const query = gql`
    query MeQuery {
      me {
        id
        unread_notifications
      }
    }
  `;

  const data = cache.readQuery({ query }) as any;

  if (!data?.me) return;

  cache.invalidate(
    {
      __typename: 'User',
      id: data.me.id,
    },
    'unread_notifications',
  );
};

export default markAllNotificationsRead;
