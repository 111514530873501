import FontAwesome from '@expo/vector-icons/FontAwesome';
import React from 'react';
import {
  ColorValue,
  Pressable,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from 'react-native';
import { KEY_GREEN } from '/constants';

interface Props {
  value: boolean;
  onValueChange?: (value: boolean) => void;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  /** Default is `24` */
  size?: number;
  fillColor?: ColorValue;
  checkmarkColor?: ColorValue;
}

const DEFAULT_SIZE = 24;

export default function CheckmarkSwitch(props: Props) {
  const size = props.size ?? DEFAULT_SIZE;

  function onPress() {
    props.onValueChange?.(!props.value);
  }

  return (
    <Pressable
      disabled={props.disabled}
      onPress={onPress}
      style={[
        styles.container,
        props.style,
        {
          opacity: props.disabled ? 0.7 : 1,
          width: size,
          height: size,
          borderRadius: size,
          backgroundColor: props.value
            ? props.fillColor ?? KEY_GREEN
            : 'transparent',
          borderColor: props.value ? props.fillColor ?? KEY_GREEN : 'black',
        },
      ]}
    >
      {props.value ? (
        <FontAwesome
          name="check"
          size={Math.floor(size * 0.8)}
          color={props.checkmarkColor ?? 'black'}
        />
      ) : null}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
