import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  confirmInputField: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    marginBottom: 4,
    marginTop: 12,
  },
  confirmInput: {
    width: '100%',
    height: 60,
    backgroundColor: 'rgba(255,255,255,0.5)',
    fontWeight: 'bold',
    color: '#fafafa',
    paddingHorizontal: 10,
    marginBottom: 4,
  },
  inputContainer: {
    width: '100%',
  },
});
