import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  SortDirection,
  SystemSurveyPlacement,
  useSearchSkillsQuery,
} from '/generated/graphql';
import { KEY_GRAY, TEXT_INPUT } from '/constants';
import SkillButton from './SkilledImpact/SkillButton';
import Alert from '/Alert';
import { debounce } from 'lodash';
import Button from './Button';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

interface Props {
  /** Temporary option to prevent users from specifying more than one skill request per campaign, until we add intentional support for it */
  onlyOne?: boolean;
  skills?: string[];
  pageSize?: number;
  onSkillsChanged: (skills: string[]) => void;
}

const DEFAULT_PAGE_SIZE = 80;

export default function SkillSelect(props: Props) {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const [query, _setQuery] = useState('');
  const [queryDebounced, _setQueryDebounced] = useState('');

  const [nextToken, setNextToken] = useState<string>();

  const [selectedSkills, setSelectedSkills] = useState<string[]>(
    props.skills ?? [],
  );

  const [{ data, fetching, stale, error }] = useSearchSkillsQuery({
    variables: {
      query: queryDebounced,
      limit: props.pageSize ?? DEFAULT_PAGE_SIZE,
      nextToken,
      sortDirection: SortDirection.Asc,
    },
  });

  useEffect(() => {
    setSelectedSkills(props.skills ?? []);
  }, [props.skills]);

  useEffect(() => {
    /** If we changed query, clear nextToken because it no longer matches our results */
    setNextToken(undefined);
  }, [query]);

  const setQueryDebouncedRef = useRef(debounce(_setQueryDebounced, 250));
  const setQueryDebounced = setQueryDebouncedRef.current;

  const setQuery = useCallback(
    (value: string) => {
      _setQuery(value);
      setQueryDebounced(value);
    },
    [setQueryDebounced],
  );

  function onLoadMore() {
    if (data?.searchSkills.nextToken) setNextToken(data.searchSkills.nextToken);
  }

  function toggleSkill(skill: string) {
    let newSelectedSkills = Array.from(selectedSkills);

    if (newSelectedSkills.includes(skill)) {
      newSelectedSkills = newSelectedSkills.filter(
        (_skill) => skill !== _skill,
      );
    } else {
      if (props.onlyOne && selectedSkills.length) {
        Alert.alert('Warning', 'You may only select one skill');
        return;
      }

      newSelectedSkills = [...newSelectedSkills, skill];
    }

    setSelectedSkills(newSelectedSkills);
    props.onSkillsChanged?.(newSelectedSkills);
  }

  function onSuggestSkill() {
    navigate('TakeSurvey', {
      placement: SystemSurveyPlacement.NewSkillSuggestion,
    });
  }

  return (
    <View style={styles.container}>
      <TextInput
        value={query}
        onChangeText={setQuery}
        style={TEXT_INPUT}
        placeholder="Filter skills..."
        placeholderTextColor={'gray'}
      />
      <View style={styles.list}>
        {fetching && !nextToken ? null : data?.searchSkills.items.length ===
          0 ? (
          <Text style={styles.emptyText}>No results</Text>
        ) : (
          data?.searchSkills.items.map((skill) => {
            const maxSelectionsReached =
              !!props.onlyOne && !!props.skills?.length;

            return (
              <SkillButton
                color={'#F5F5F5'}
                style={{
                  marginHorizontal: 3,
                  marginVertical: 5,
                  height: 35,
                  opacity:
                    maxSelectionsReached && !selectedSkills.includes(skill.name)
                      ? 0.5
                      : 1,
                }}
                labelStyle={{
                  fontFamily: 'Lato',
                }}
                disabled={
                  maxSelectionsReached && !selectedSkills.includes(skill.name)
                }
                key={skill.name}
                skill={skill.name}
                selected={selectedSkills.includes(skill.name)}
                onPress={() => toggleSkill(skill.name)}
              />
            );
          })
        )}
      </View>
      {fetching ? (
        <ActivityIndicator
          color={KEY_GRAY}
          size="small"
          style={{
            marginTop: 8,
            padding: 10,
          }}
        />
      ) : data?.searchSkills.nextToken ? (
        fetching || stale ? (
          <ActivityIndicator
            size="small"
            style={styles.activityIndicator}
            color={KEY_GRAY}
          />
        ) : (
          <TouchableOpacity style={styles.loadMoreButton} onPress={onLoadMore}>
            <Text style={styles.loadMoreButtonText}>Load more...</Text>
          </TouchableOpacity>
        )
      ) : null}

      <View style={styles.skillSuggestionBox}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <SkillButton skill="OTHER" color="white" disabled />
        </View>
        <Text style={styles.skillSuggestionBoxText}>
          Are we missing a skill you'd like to give? Let us know here and we
          will consider it for our next update!
        </Text>
        <Button
          containerStyle={{
            margin: 8,
            alignSelf: 'center',
          }}
          label="Suggest a new skill"
          onPress={onSuggestSkill}
        />
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    padding: 8,
    width: '100%',
  },
  list: {
    marginVertical: 8,
    alignSelf: 'center',
    maxWidth: 800,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  loadMoreButtonText: {
    fontFamily: 'Lato-Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  loadMoreButton: {
    padding: 10,
    marginTop: 8,
  },
  emptyText: {
    padding: 12,
    fontFamily: 'Lato',
    fontSize: 16,
    color: 'gray',
  },
  skillSuggestionBox: {
    borderRadius: 6,
    backgroundColor: '#F5F5F5',
    padding: 15,
    marginVertical: 8,
  },
  skillSuggestionBoxText: {
    fontFamily: 'Lato',
    fontSize: 16,
    textAlign: 'center',
  },
  activityIndicator: {
    padding: 8,
    alignSelf: 'center',
  },
});
