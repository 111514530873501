import React from 'react';
import { ActivityIndicator, StyleSheet, View, ViewStyle } from 'react-native';
import Button from './Button';
import { KEY_GRAY } from '/constants';

interface Props {
  /** Dictates height of component when not disalpying anything. Default is `16` */
  emptyHeight?: number;
  style?: ViewStyle;
  hasMore?: boolean;
  loading?: boolean;
  activityIndicatorColor?: string;
  /** If set, hides the dot that indicates no more pages remain */
  isFirstPage?: boolean;
  onFetchMore?: () => void;
}

export default function GenericListFooter(props: Props) {
  const shouldShowFooter = props.loading || props.hasMore || !props.isFirstPage;

  return shouldShowFooter ? (
    <View style={[styles.container, props.style]}>
      {props.loading ? (
        <ActivityIndicator
          color={props.activityIndicatorColor ?? KEY_GRAY}
          size={24}
        />
      ) : props.hasMore ? (
        <Button label="Load More" onPress={props.onFetchMore} />
      ) : !props.isFirstPage ? (
        <View
          style={{
            width: 12,
            height: 12,
            backgroundColor: '#ccc',
            borderRadius: 20,
            alignSelf: 'center',
            marginVertical: 10,
          }}
        />
      ) : null}
    </View>
  ) : (
    <View
      style={{
        height: props.emptyHeight ?? 16,
      }}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 24,
    alignSelf: 'center',
    alignItems: 'center',
  },
});
