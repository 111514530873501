import { forwardRef } from 'react';
import {
  StyleProp,
  StyleSheet,
  TextInput,
  TextInputProps,
  View,
  ViewStyle,
} from 'react-native';

import { TEXT_INPUT } from '/constants';

type IconPosition = 'left' | 'right';

export interface ITextInputWithIconProps extends TextInputProps {
  containerStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
  icon?: (() => JSX.Element) | JSX.Element;
  /** Default is `right` */
  iconPosition?: IconPosition;
}

const TextInputWithIcon = forwardRef<TextInput, ITextInputWithIconProps>(
  ({ icon, ...props }: ITextInputWithIconProps, ref) => {
    const renderIcon = (renderPosition: IconPosition) => {
      const position = props.iconPosition ?? 'right'; // Default is `right`

      /** If this is not our desired position, return null */
      if (renderPosition !== position) return null;

      const style =
        position === 'left'
          ? styles.iconContainerLeft
          : styles.iconContainerRight;

      return (
        <View style={style}>{typeof icon === 'function' ? icon() : icon}</View>
      );
    };

    return (
      <View
        pointerEvents={props.disabled ? 'none' : 'auto'}
        style={StyleSheet.flatten([styles.textContainer, props.containerStyle])}
      >
        {renderIcon('left')}
        <TextInput {...props} ref={ref} />
        {renderIcon('right')}
      </View>
    );
  },
);

export default TextInputWithIcon;

const styles = StyleSheet.create({
  textContainer: {
    ...TEXT_INPUT,
    minHeight: 36,
    height: 'auto',
    fontFamily: 'Lato',
    fontSize: 16,
    paddingHorizontal: 6,
    paddingVertical: 2,
    justifyContent: 'center',
  },
  iconContainerRight: {
    zIndex: 2,
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
  },
  iconContainerLeft: {
    zIndex: 2,
    position: 'absolute',
    flexDirection: 'row',
    alignItems: 'center',
    left: 0,
    top: 0,
    bottom: 0,
  },
});
