import { StyleSheet } from 'react-native';
import { DEFAULT_TAB, KEY_GRAY } from '/constants';

export default StyleSheet.create({
  container: {
    height: 48,
    width: '100%',
    backgroundColor: 'white',
  },
  buttonText: {
    fontFamily: 'Lato-Bold',
    letterSpacing: 0.2,
    fontSize: 17,
    paddingHorizontal: 10,
    textTransform: 'uppercase',
  },
  button: {
    ...DEFAULT_TAB,
    borderBottomWidth: 0,
    backgroundColor: 'white',
  },
  tabBarWebScroller: {
    ...StyleSheet.absoluteFillObject,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tabBarWebScrollerButton: {
    padding: 10,
    paddingHorizontal: 16,
  },
  tabBarWebScrollerButtonCircle: {
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: 'gray',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabBarWebScrollerButtonIcon: {
    marginBottom: 2,
  },
});
