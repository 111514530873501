import React from 'react';
import { Pressable, Text, View } from 'react-native';
import styles from '../CampaignPost.style';
import Ellipse from '/assets/jsicons/Ellipse';
import MapMarker from '/assets/jsicons/headerIcons/map-marker';
import CampaignActionSheetPressable from '/components/ActionSheet/CampaignActionSheetPressable';
import Avatar from '/components/Avatar';
import {
  Campaign,
  CampaignPost,
  TranslatableText,
  User,
} from '/generated/graphql';
import { shorten } from '/util';

const LOCATION_TEXT_COLOR = '#505050';

interface IUser extends Pick<User, 'id' | 'name' | 'profile_image'> {}

interface ICampaign extends Pick<Campaign, 'id' | 'name' | 'closed'> {
  user: IUser | null;
}

interface ICampaignPost
  extends Pick<CampaignPost, 'id' | 'location' | 'is_update' | 'created_at'> {
  description?: Omit<TranslatableText, 'id'>;
  campaign?: ICampaign | null;
  user?: IUser | null;
}

interface ICampaignPostHeaderProps {
  data: ICampaignPost | undefined | null;
  campaign: ICampaign | undefined | null;
  user: IUser | undefined | null;
  disableHeader: boolean;
  disableActionSheetButton: boolean | undefined;
  onPress: () => void;
}

export default function CampaignPostHeader(props: ICampaignPostHeaderProps) {
  const campaign = props.campaign
    ? Object.assign(props.campaign, { user: props.user })
    : undefined;
  const data = props.data
    ? Object.assign(props.data, { user: props.user })
    : undefined;

  return (
    <>
      <View
        style={[
          styles.topRow,
          {
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          },
        ]}
      >
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            paddingHorizontal: 8,
          }}
        >
          <Text style={styles.postTitle}>
            {shorten(props.campaign?.name ?? '', 128)}&nbsp;
          </Text>

          <ActionSheetButton
            campaign={campaign}
            data={data}
            disabled={props.disableActionSheetButton}
          />
        </View>
      </View>
      <Pressable
        style={{
          paddingHorizontal: 8,
          flexDirection: 'row',
          alignItems: 'center',
        }}
        disabled={props.disableHeader}
        onPress={props.onPress}
      >
        <Avatar
          avatarStyle={{
            borderRadius: 50,
          }}
          source={{ uri: props.user?.profile_image || undefined }}
          size={50}
        />
        <View
          style={{
            flex: 1,
            marginLeft: 6,
          }}
        >
          <Text style={styles.orgTitleView}>
            {props.user?.name?.toUpperCase()}
          </Text>
          {props.data?.location ? (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <View
                style={{
                  marginRight: 1,
                }}
              >
                <MapMarker width={12} height={12} fill={LOCATION_TEXT_COLOR} />
              </View>
              <Text
                style={[styles.locationText, { color: LOCATION_TEXT_COLOR }]}
                numberOfLines={2}
              >
                {props.data?.location}
              </Text>
            </View>
          ) : null}
        </View>
      </Pressable>
    </>
  );
}

function ActionSheetButton(props: {
  data: ICampaignPost | undefined | null;
  campaign: ICampaign | undefined;
  disabled: boolean | undefined;
}) {
  return (
    <CampaignActionSheetPressable
      post={props.data}
      disabled={props.disabled}
      campaign={props.campaign || props.data?.campaign}
      style={({ pressed }) => ({
        opacity: pressed ? 0.5 : 1,
      })}
    >
      <View
        style={{
          width: 40,
          height: 32,
          justifyContent: 'center',
          paddingRight: 5,
          alignItems: 'flex-end',
        }}
      >
        <Ellipse fill="#000" height={22} width={22} />
      </View>
    </CampaignActionSheetPressable>
  );
}
