import { Badge } from '@rneui/themed';
import React, { useMemo } from 'react';
import { Platform, Text, useWindowDimensions, View } from 'react-native';

import {
  createStackNavigator,
  StackNavigationOptions,
} from '@react-navigation/stack';

import { TabBarOptions } from './nested/common';
import FeedStack from './nested/FeedStack';
import NotificationStack from './nested/NotificationStack';
import ProfileStack from './nested/ProfileStack';

import Bell from '/assets/jsicons/bottomnavigation/Bell';
import Globe from '/assets/jsicons/bottomnavigation/Globe';
import Lightening from '/assets/jsicons/bottomnavigation/Lightening';

import {
  AntDesign,
  FontAwesome5,
  Fontisto,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from '@expo/vector-icons';
import { createResponsiveTabNavigator } from '../ResponsiveTabs';
import { ResponsiveTabNavigationOptions } from '../ResponsiveTabs/navigators/createResponsiveTabNavigator';
import DataSurveyStack from './nested/DataSurveyStack';
import ImpactStack from './nested/ImpactStack';
import MapStack from './nested/MapStack';
import CampaignBuilder from '/components/CampaignBuilder/CampaignBuilder';
import { KEY_GRAY, KEY_GREEN } from '/constants';
import { NotificationContext } from '/context/NotificationProvider';
import { truncateNumber } from '/util';
import FindTeamMemberScreen from '/screens/Teams/FindTeamMemberScreen';
import LoadingSpinnerOverlay from '/components/LoadingSpinnerOverlay';
import ConfigurationStack from './nested/ConfigurationStack';
import SurveyStack from './nested/SurveyStack';
import ModerationStack from './nested/ModerationStack';

const createScreen = (
  Component: React.LazyExoticComponent<React.ComponentType<any>>,
) => {
  return (props: any) => (
    <React.Suspense fallback={<LoadingSpinnerOverlay />}>
      <Component {...props} />
    </React.Suspense>
  );
};

const Organizations = createScreen(
  React.lazy(() => import('/screens/admin/Organizations')),
);
const Supporters = createScreen(
  React.lazy(() => import('/screens/admin/Supporters')),
);
const FindTeamScreen = createScreen(
  React.lazy(() => import('/screens/Teams/FindTeamScreen')),
);
const Referrals = createScreen(
  React.lazy(() => import('/screens/admin/Referrals')),
);

const Stack = createStackNavigator();
const Tabs = createResponsiveTabNavigator();

export default function TabNavigator() {
  const { width: window_width } = useWindowDimensions();

  const iconSize = useMemo(() => {
    return window_width <= 540 ? 25 : 32;
  }, [window_width]);

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName="main"
    >
      {/* MAIN TAB NAVIGATION */}
      <Stack.Screen name="main">
        {() => (
          <Tabs.Navigator
            screenOptions={TabBarOptions}
            tabBarOptions={{
              iconHorizontal: true,
              activeTintColor: KEY_GREEN,
              activeBackgroundColor: '#EAEAEA',
              inactiveTintColor: 'black',
              // Icon size based on window width
              iconSize,
              labelSize: 20,
            }}
            backBehavior="history"
          >
            <Tabs.Screen
              component={FeedStack}
              name="FeedStack"
              options={FeedStackOptions}
            />
            <Tabs.Screen
              component={ImpactStack}
              name="ImpactStack"
              options={ImpactStackOptions}
            />
            <Tabs.Screen
              component={MapStack}
              name="MapStack"
              options={MapStackOptions}
            />
            <Tabs.Screen
              component={NotificationStack}
              name="NotificationStack"
              options={NotificationStackOptions}
            />
            <Tabs.Screen
              component={ProfileStack}
              name="ProfileStack"
              options={ProfileStackOptions}
            />

            {/* ADMIN SCREENS */}
            {Platform.OS === 'web' && (
              <>
                <Tabs.Screen
                  options={{
                    tabBarLabel: 'Moderation',
                    // eslint-disable-next-line react/no-unstable-nested-components
                    tabBarIcon: ({ color, size }) => (
                      <MaterialIcons
                        name="report-problem"
                        size={size}
                        color={color}
                      />
                    ),
                    isAdminScreen: true,
                  }}
                  name="ModerationStack"
                  component={ModerationStack}
                />
                <Tabs.Screen
                  options={{
                    tabBarLabel: 'Surveys',
                    // eslint-disable-next-line react/no-unstable-nested-components
                    tabBarIcon: ({ color, size }) => (
                      <Ionicons
                        name="document-text"
                        size={size}
                        color={color}
                      />
                    ),
                    isAdminScreen: true,
                  }}
                  name="SurveyStack"
                  component={SurveyStack}
                />
                <Tabs.Screen
                  options={{
                    tabBarLabel: 'Organizations',
                    // eslint-disable-next-line react/no-unstable-nested-components
                    tabBarIcon: ({ color, size }) => (
                      <Fontisto name="treehouse" size={size} color={color} />
                    ),
                    isAdminScreen: true,
                  }}
                  name="Organizations"
                  component={Organizations}
                />
                <Tabs.Screen
                  options={{
                    tabBarLabel: 'Supporters',
                    // eslint-disable-next-line react/no-unstable-nested-components
                    tabBarIcon: ({ color, size }) => (
                      <Ionicons name="people" size={size} color={color} />
                    ),
                    isAdminScreen: true,
                  }}
                  name="Supporters"
                  component={Supporters}
                />
                <Tabs.Screen
                  options={{
                    tabBarLabel: 'Referrals',
                    // eslint-disable-next-line react/no-unstable-nested-components
                    tabBarIcon: ({ color, size }) => (
                      <MaterialCommunityIcons
                        name="account-network"
                        size={size}
                        color={color}
                      />
                    ),
                    isAdminScreen: true,
                  }}
                  name="Referrals"
                  component={Referrals}
                />
                <Tabs.Screen
                  options={{
                    tabBarLabel: 'Configuration',
                    // eslint-disable-next-line react/no-unstable-nested-components
                    tabBarIcon: ({ color, size }) => (
                      <Ionicons name="settings" size={size} color={color} />
                    ),
                    isAdminScreen: true,
                  }}
                  name="ConfigurationStack"
                  component={ConfigurationStack}
                />
              </>
            )}
          </Tabs.Navigator>
        )}
      </Stack.Screen>

      {/* MODAL SCREENS */}
      <Stack.Screen
        component={CampaignBuilder}
        name="CreateCampaign"
        options={CampaignBuilderOptions}
      />
      <Stack.Screen
        component={DataSurveyStack}
        name="OrgDataSurveySetup"
        options={DataSurveyStackOptions}
      />
      <Stack.Screen
        component={FindTeamMemberScreen}
        name="FindTeamMember"
        options={FindTeamMemberScreenOptions}
      />
      <Stack.Screen
        component={FindTeamScreen}
        name="FindTeam"
        options={FindTeamScreenOptions}
      />
    </Stack.Navigator>
  );
}

/**
 * Configs
 */

const FeedStackOptions = (): ResponsiveTabNavigationOptions => ({
  tabBarLabel: 'FEED',
  tabBarIcon: ({ size, color }) => (
    <Lightening
      fill={Platform.OS === 'web' ? color : KEY_GRAY}
      width={size}
      height={size}
    />
  ),
});

const ImpactStackOptions = (): ResponsiveTabNavigationOptions => ({
  tabBarLabel: 'IMPACT',
  tabBarIcon: ({ size, color }) => (
    <FontAwesome5
      name="rocket"
      size={size}
      color={Platform.OS === 'web' ? color : KEY_GRAY}
    />
  ),
  authRequired: true,
});

const MapStackOptions = (): ResponsiveTabNavigationOptions => ({
  tabBarLabel: 'DISCOVER',
  tabBarIcon: ({ size, color }) => (
    <Globe
      width={size}
      fill={Platform.OS === 'web' ? color : KEY_GRAY}
      height={size}
    />
  ),
  contentMaxWidth: 1200,
});

const NotificationStackOptions = (): ResponsiveTabNavigationOptions => ({
  tabBarLabel: 'NOTIFICATIONS',
  tabBarIcon: ({ size, color }) => (
    <NotificationContext.Consumer>
      {({ unread_notifications }) => (
        <View>
          <Bell
            fill={Platform.OS === 'web' ? color : KEY_GRAY}
            width={size}
            height={size}
          />
          {unread_notifications ? (
            <Badge
              value={
                <Text
                  style={{
                    fontFamily: 'Lato',
                    color: 'black',
                    padding: 2,
                  }}
                >
                  {truncateNumber(unread_notifications, 99)}
                </Text>
              }
              badgeStyle={{
                backgroundColor: '#D7FE49',
                overflow: 'hidden',
                position: 'relative',
              }}
              containerStyle={{
                position: 'absolute',
                top: -4,
                right: -4,
              }}
            />
          ) : null}
        </View>
      )}
    </NotificationContext.Consumer>
  ),
  authRequired: true,
});

const ProfileStackOptions = (): ResponsiveTabNavigationOptions => ({
  tabBarLabel: 'PROFILE',
  tabBarIcon: ({ size, color }) => (
    <AntDesign
      name="smileo"
      size={size}
      color={Platform.OS === 'web' ? color : undefined}
    />
  ),
  authRequired: true,
});

export const CampaignBuilderOptions = (): StackNavigationOptions => ({
  title: 'Create Campaign',
  headerShown: false,
  presentation: 'modal',
});

const DataSurveyStackOptions = (): StackNavigationOptions => ({
  headerShown: false,
  presentation: 'modal',
});

const FindTeamMemberScreenOptions = (): StackNavigationOptions => ({
  headerTitle: 'INVITE TEAM MEMBER',
  presentation: 'modal',
});

const FindTeamScreenOptions = (): StackNavigationOptions => ({
  headerTitle: 'JOIN A TEAM',
  headerShown: false,
  presentation: 'modal',
});
