import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  errorContainer: {
    padding: 10,
    alignItems: 'center',
    alignSelf: 'center',
  },
  errorText: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
    color: 'gray',
  },
});
