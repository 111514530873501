import { Entypo, Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import {
  Image,
  ImageStyle,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import Avatar from '../Avatar';
import HorizontalContainer from '../common/Generic/HorizontalContainer';
import Sync from '/assets/jsicons/bottomnavigation/Sync';
import { KEY_GRAY } from '/constants';
import {
  Campaign,
  CampaignPost,
  DonationRequest,
  User,
  VolunteerRequest,
} from '/generated/graphql';
import { getUrgencyLabelAndColors } from '/util';
import getCDNImageUri from '/util/getCDNImageUri';

interface ICampaign
  extends Pick<Campaign, 'id' | 'name' | 'created_at' | 'urgency'> {
  user?: Pick<User, 'profile_image' | 'name'>;
  skilled_impact_requests?: Pick<
    Campaign['skilled_impact_requests'][number],
    'id'
  >[];
  volunteer_request?: Pick<VolunteerRequest, 'id'> | undefined | null;
  donation_request?: Pick<DonationRequest, 'id'> | undefined | null;
}

interface ICampaignPost
  extends Pick<CampaignPost, 'id' | 'media' | 'thumbnail'> {}

type Props = {
  /** Default behavior navigates to Campaign screen */
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  imageStyle?: StyleProp<ImageStyle>;
  campaign: ICampaign;
  campaignPost: ICampaignPost;
  /** Widget to display below the component */
  widget?: (() => JSX.Element) | JSX.Element;
};

const DEFAULT_COMPONENT_WIDTH = 180;
const DEFAULT_IMAGE_HEIGHT = 144;

export default function CampaignPreviewTile({
  campaign,
  campaignPost,
  ...props
}: Props) {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const supportRequests: ('skills' | 'volunteering' | 'funding')[] = [];

  if (campaign.skilled_impact_requests?.length) supportRequests.push('skills');
  if (campaign.volunteer_request) supportRequests.push('volunteering');
  if (campaign.donation_request) supportRequests.push('funding');

  const showSupportRequestLabels = supportRequests.length === 1;

  const urgency = getUrgencyLabelAndColors(campaign?.urgency);

  const [imageDimensions, setImageDimensions] = React.useState<
    | {
        width: number;
        height: number;
      }
    | undefined
  >(undefined);

  return (
    <Pressable
      key={campaign.id}
      onPress={() => {
        if (typeof props.onPress === 'function') {
          props.onPress();
          return;
        }

        navigate('Campaign', {
          campaignId: campaign.id,
        });
      }}
      style={[{ width: DEFAULT_COMPONENT_WIDTH }, props.style]}
    >
      <View style={{ flex: 1 }}>
        <View style={styles.campaignGridTileImageContainer}>
          <View
            style={[
              styles.urgencyBar,
              {
                backgroundColor: urgency.color,
              },
            ]}
          >
            <Text
              style={[
                styles.urgencyBarText,
                urgency.labelColor ? { color: urgency.labelColor } : null,
              ]}
            >
              {urgency.label}
            </Text>
          </View>
          <Image
            onLayout={(event) => {
              const { width, height } = event.nativeEvent.layout;
              setImageDimensions({
                width: width,
                height,
              });
            }}
            source={{
              uri: !imageDimensions
                ? undefined
                : getCDNImageUri({
                    uri: campaignPost.media[0],

                    isThumbnail: true,
                    dimensions: imageDimensions,
                  }),
            }}
            style={[
              {
                width: '100%',
                height: DEFAULT_IMAGE_HEIGHT,
                backgroundColor: 'gray',
              },
              props.imageStyle,
            ]}
          />
        </View>
        <Text numberOfLines={2} style={styles.campaignGridTileTitle}>
          {campaign.name}
        </Text>

        <HorizontalContainer>
          <Avatar
            source={{
              uri: campaign.user?.profile_image,
            }}
            rounded
            size={17}
            containerStyle={{
              marginRight: 4,
            }}
          />
          <Text numberOfLines={1} style={styles.campaignTileUserName}>
            {campaign.user?.name}
          </Text>
        </HorizontalContainer>

        <HorizontalContainer>
          {supportRequests.includes('funding') ? (
            <>
              <View
                style={{
                  marginRight: 4,
                }}
              >
                <Entypo name="credit" size={17} color={KEY_GRAY} />
              </View>
              {showSupportRequestLabels ? (
                <Text style={styles.campaignTileSupportRequestLabel}>
                  FUNDING
                </Text>
              ) : null}
            </>
          ) : null}
          {supportRequests.includes('volunteering') ? (
            <>
              <View
                style={{
                  marginRight: 4,
                }}
              >
                <Ionicons name="earth" size={17} color={KEY_GRAY} />
              </View>
              {showSupportRequestLabels ? (
                <Text style={styles.campaignTileSupportRequestLabel}>
                  IN-PERSON
                </Text>
              ) : null}
            </>
          ) : null}
          {supportRequests.includes('skills') ? (
            <>
              <View
                style={{
                  marginRight: 4,
                }}
              >
                <Sync width={17} height={17} fill={KEY_GRAY} />
              </View>
              {showSupportRequestLabels ? (
                <Text style={styles.campaignTileSupportRequestLabel}>
                  SKILLS
                </Text>
              ) : null}
            </>
          ) : null}
        </HorizontalContainer>
      </View>

      {typeof props.widget === 'function'
        ? props.widget()
        : props.widget ?? null}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  campaignGridTileImageContainer: {
    borderRadius: 6,
    overflow: 'hidden',
  },
  campaignGridTileTitle: {
    marginTop: 2,
    fontFamily: 'Lato-Bold',
    fontSize: 18,
    textTransform: 'uppercase',
  },
  campaignTileUserName: {
    fontFamily: 'Lato-Bold',
    fontSize: 14,
    color: KEY_GRAY,
  },
  urgencyBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    height: 32,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  urgencyBarText: {
    fontFamily: 'Lato-Bold',
    fontSize: 14,
    letterSpacing: 2,
    color: 'black',
  },
  campaignTileSupportRequestLabel: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
  },
});
