/**
 * This utility allows us to easily interface with the ffmpeg lambda function from the client side
 */
import { post } from 'aws-amplify/api';

type GenerateVideoThumbnailOptions = {
  source: string;
  position?: number;
  /** If set, thumbnails will be stored where they can later be retreived by
   * the `getCDNImageUri` utility using only the path of the video */
  useOriginalFilename?: boolean;
};

export default {
  /** Generates a thumbnail for a video, returns S3 uri */
  generateVideoThumbnail: async ({
    source,
    position = 0,
    useOriginalFilename,
  }: GenerateVideoThumbnailOptions): Promise<string> => {
    const response = await post({
      apiName: 'ffmpegapi',
      path: `/functions/generatethumbnail`,
      options: {
        queryParams: {
          source,
          position: position.toString(),
          useOriginalFilename: `${useOriginalFilename ? true : false}`,
        },
      },
    }).response;

    const { Location } = (await response.body.json()) as {
      Location: string;
    };

    return Location;
  },
};
