import { FontAwesome5 } from '@expo/vector-icons';
import ImageCrop, { DragMode } from '@mtourj/react-native-image-crop';
import { ResizeMode, Video } from 'expo-av';
import * as ImageManipulator from 'expo-image-manipulator';
import * as MediaLibrary from 'expo-media-library';
import * as VideoThumbnails from 'expo-video-thumbnails';
import React, { useEffect, useRef, useState } from 'react';
import {
  ActivityIndicator,
  Modal,
  Platform,
  Pressable,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { Video as VideoCompression } from 'react-native-compressor';
import Animated from 'react-native-reanimated';
import {
  SafeAreaView,
  useSafeAreaInsets,
} from 'react-native-safe-area-context';
import ThumbnailPicker from './components/ThumbnailPicker';
import LoadingOverlay from '../LoadingOverlay';
import styles from './MediaEditor.style';
import { ACTIVITY_INDICATOR_DEFAULT, KEY_GREEN } from '/constants';

type CropData = {
  translateX: number;
  translateY: number;
  scale: number;
  currentZoomDistance: number;
};

type AssetType = Pick<
  MediaLibrary.Asset,
  'mediaType' | 'uri' | 'height' | 'width' | 'duration'
> & {
  id?: string;
};

export type MediaEditorMedia = {
  uri: string;
  thumbnailUri?: string;
  cropData?: CropData;
  asset: AssetType;
};

type Props = {
  visible: boolean;
  media: MediaEditorMedia[];
  onFinishEditing: (media: MediaEditorMedia[]) => void;
  /** ONLY WORKS ON IMAGES. If set, ImagePicker will prevent user from resizing crop box
   * and will return an image with the specified dimensions */
  targetMediaDimensions?: {
    width: number;
    height: number;
  };
  circleCrop?: boolean;
};

/** MediaEditor takes in a list of media, with optionally prefilled crop
 * data.
 * Images: User can crop images, and when done, they are compressed.
 * Videos: User can select a thumbnail, and when done, the video is compressed (except on web)
 * It provides a callback to the parent component with the updated media.
 */
export default function MediaEditor(props: Props) {
  const [_media, setMedia] = useState<MediaEditorMedia[]>(props.media || []);

  /** This helps us keep track of how many items were filtered out, and where
   * they were filtered out from, so we can keep track of the correct index
   * of the media we are currently editing
   */
  let indexOffsetMap: { [key: number]: number } = {
    0: 0,
  };
  /** We do not support doing anything with videos on web yet, so we exclude them */
  const media = _media.filter((m) => {
    const shouldExclude = m.asset.mediaType === 'video';

    // Get the largest index in the map
    const lastIndexInMap = Math.max(...Object.keys(indexOffsetMap).map(Number));

    // If we are excluding this media, we need to offset the index of all
    // media after it by 1
    if (shouldExclude) {
      indexOffsetMap[lastIndexInMap] = indexOffsetMap[lastIndexInMap] + 1;
    } else {
      indexOffsetMap[lastIndexInMap + 1] = 0;
    }

    return !shouldExclude;
  });

  const [currentlyEditingIndex, setCurrentlyEditingIndex] = useState<number>(0);
  const [isCompressing, setCompressing] = useState<boolean>(false);
  const [thumbnailPositionMillis, setThumbnailPositionMillis] = useState(0);
  const [shouldPlayVideo, setShouldPlayVideo] = useState(false);

  // This is the index of the media we are currently editing, taking into
  // account the index offset map
  const trueCurrentlyEditingIndex = (() => {
    let index = currentlyEditingIndex;
    for (let i = 0; i <= index; i++) {
      index += indexOffsetMap[i] || 0;
    }
    return index;
  })();

  const safeAreaInsets = useSafeAreaInsets();

  const videoRef = useRef<Video>();
  const cropperRef = useRef<any>();

  const currentMedia =
    currentlyEditingIndex >= media.length
      ? undefined
      : media[currentlyEditingIndex];

  useEffect(() => {
    if (currentlyEditingIndex >= media.length) {
      setCurrentlyEditingIndex(media?.length - 1 || 0);
    }
  }, [media.length, currentlyEditingIndex]);

  useEffect(() => {
    if (props.visible) {
      setCurrentlyEditingIndex(0);
    }
  }, [props.visible]);

  useEffect(() => {
    setMedia(props.media);
  }, [props.media]);

  useEffect(() => {
    setThumbnailPositionMillis(0);
  }, [currentlyEditingIndex]);

  const togglePlayVideo = () => {
    setShouldPlayVideo((prevState) => !prevState);
  };

  const applyEditsToCurrentMedia = async () => {
    if (!currentMedia) return;

    const isVideo = currentMedia.asset.mediaType === 'video';

    // Getting cropping information from our ImageCrop component if it
    // is currently mounted (This is null if we are looking at a video)
    const data = cropperRef.current?.getCropData();

    // If video, getAssetInfoAsync will provide us with the localUri
    // we can use to upload the video, otherwise, we use manipulateAsync
    // to crop the image using our current ImageCrop crop data (stored in
    // `data' above), which returns the uri of the cropped image
    let asset = isVideo
      ? currentMedia.asset
      : await ImageManipulator.manipulateAsync(
          currentMedia.asset.uri,
          [
            {
              crop: {
                // If this is not a video, `data` should not be undefined
                height: Math.floor(data?.size.height) as number,
                width: Math.floor(data?.size.width) as number,
                originX: Math.max(data?.offset.x, 0) as number,
                originY: Math.max(data?.offset.y, 0) as number,
              },
            },
          ],
          {
            compress: 0.85, // Slightly compress all images to reduce size
          },
        );

    if (!asset || (isVideo && !(asset as MediaLibrary.AssetInfo)?.uri)) {
      throw new Error(
        'MediaEditor failed to get asset info for selected video',
      );
    }

    // Compress video before upload

    let bitrate;

    const totalPixels = asset.height * asset.width;

    // Higher resolution, higher bitrate
    if (totalPixels < 320 * 240) bitrate = 420000; // 420kbps
    else if (totalPixels < 480 * 270) bitrate = 750000; // 750 kbps
    else if (totalPixels < 1024 * 576) bitrate = 1600000; // 1,600 kbps
    else if (totalPixels < 1280 * 720) bitrate = 2700000; // 2,700 kbps
    else if (totalPixels < 1920 * 1080) bitrate = 4500000; // 4,500 kbps
    else bitrate = 5500000; // 6,000 kbps

    if (isVideo) {
      try {
        setCompressing(true);
        const uri = await VideoCompression.compress(
          (asset as MediaLibrary.AssetInfo).uri! as string,
          {
            compressionMethod: 'manual',
            maxSize: 2560,
            bitrate,
          },
        );

        (asset as MediaLibrary.AssetInfo).uri = uri;
      } catch (err) {
        console.log('Failed to compress video', err);
        throw err;
      } finally {
        setCompressing(false);
      }
    }

    const { uri: thumbnailUri } = isVideo
      ? await VideoThumbnails.getThumbnailAsync(
          (asset as MediaLibrary.AssetInfo).uri!,
          {
            time: Math.floor(thumbnailPositionMillis),
          },
        )
      : asset;

    // Compress thumbnail
    const thumbnail = await ImageManipulator.manipulateAsync(thumbnailUri, [], {
      compress: 0.64,
    });

    const newMedia = _media.map((item, index) => {
      if (index === trueCurrentlyEditingIndex && asset) {
        return {
          uri: asset.uri,
          thumbnailUri: thumbnail.uri,
          cropData: data?.zoomData,
          asset: item.asset,
        };
      } else {
        return item;
      }
    });

    setMedia(newMedia);

    return newMedia;
  };

  const onSubmit = async (newMedia: MediaEditorMedia[] | undefined) => {
    if (!newMedia) return;

    try {
      props.onFinishEditing(newMedia);
    } catch (err) {
      console.log('MediaEditor: Error while saving & exiting', err);
    }
  };

  return (
    <Modal
      animationType="slide"
      visible={props.visible}
      hardwareAccelerated={true}
      style={{
        paddingRight: safeAreaInsets.right,
        paddingLeft: safeAreaInsets.left,
        paddingBottom: safeAreaInsets.bottom,
      }}
    >
      <LoadingOverlay loading={isCompressing} label="Compressing..." />
      <View
        style={[
          styles.headerContainer,
          {
            paddingTop: safeAreaInsets.top,
          },
        ]}
      >
        <TouchableOpacity
          onPress={() => {
            if (currentlyEditingIndex > 0) {
              setCurrentlyEditingIndex(currentlyEditingIndex - 1);
            } else props.onFinishEditing([]);
          }}
          style={styles.headerLeft}
        >
          <Text style={{ fontFamily: 'Lato', color: 'white', fontSize: 16 }}>
            {currentlyEditingIndex > 0 ? 'Back' : 'Cancel'}
          </Text>
        </TouchableOpacity>
        <View style={styles.headerCenter}>
          <Text style={{ fontFamily: 'Lato', fontSize: 18, color: 'white' }}>
            {currentMedia?.asset.mediaType === 'video'
              ? 'Choose Thumbnail'
              : 'Crop Image'}
            {media.length > 1 &&
              ` (${currentlyEditingIndex + 1}/${media.length})`}
          </Text>
        </View>
        <TouchableOpacity
          style={styles.headerRight}
          onPress={async () => {
            const newMedia = await applyEditsToCurrentMedia();

            if (currentlyEditingIndex < media.length - 1) {
              setCurrentlyEditingIndex(currentlyEditingIndex + 1);
            } else onSubmit(newMedia);
          }}
        >
          <Text style={{ fontFamily: 'Lato', color: KEY_GREEN, fontSize: 16 }}>
            {currentlyEditingIndex < media.length - 1 ? 'Next' : 'Done'}
          </Text>
        </TouchableOpacity>
      </View>
      <View
        style={{
          flex: 1,
          backgroundColor: '#303136',
          zIndex: 99,
          overflow: 'hidden',
        }}
      >
        <SafeAreaView style={{ flex: 1 }}>
          <View
            style={{
              flex: 1,
              backgroundColor: '#303136',
              zIndex: -99,
            }}
          >
            {/* <TouchableWithoutFeedback onPress={() => expandViewer.start()}> */}
            <Animated.View
              style={{
                flex: 1,
                // height: window_width,
                // transform: [{ translateY }],
                backgroundColor: '#000',
                // opacity,
              }}
            >
              {/* 
                  This conditional decides whether to show our ImageCrop
                  component or a Video player based on state.currentImage's
                  MediaType.
                */}
              {currentMedia?.uri ? (
                currentMedia.asset.mediaType === 'photo' ? (
                  <View
                    style={{
                      flex: 1,
                      padding: 24,
                      justifyContent: 'center',
                      alignItems: 'center',
                      overflow: 'hidden',
                    }}
                  >
                    <ImageCrop
                      ref={cropperRef}
                      maxScale={2.5}
                      source={{ uri: currentMedia.asset.uri }}
                      imageWidth={currentMedia.asset.width}
                      imageHeight={currentMedia.asset.height}
                      fixedRatio={
                        // If specific dimensions have been specified, then we
                        // don't want the user to change the desired aspect ratio
                        !!props.targetMediaDimensions?.width &&
                        !!props.targetMediaDimensions?.height
                      }
                      initialCropBoxHeight={
                        props.targetMediaDimensions?.height || 300
                      }
                      initialCropBoxWidth={
                        props.targetMediaDimensions?.width || 300
                      }
                      circular={props.circleCrop}
                      dragMode={
                        Platform.OS === 'web'
                          ? DragMode.SELECTION
                          : DragMode.IMAGE
                      }
                      // zoomData={currentMedia.cropData}
                    />
                  </View>
                ) : currentMedia.asset.mediaType === 'video' ? (
                  <Pressable
                    onPress={togglePlayVideo}
                    style={{
                      flex: 1,
                      alignItems: 'center',
                    }}
                  >
                    <View
                      style={{
                        top: 0,
                        bottom: 0,
                        position: 'absolute',
                        alignSelf: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <ActivityIndicator
                        size="large"
                        color={ACTIVITY_INDICATOR_DEFAULT}
                      />
                    </View>
                    <Video
                      ref={(r) => {
                        if (r) videoRef.current = r;
                      }}
                      source={{
                        uri: currentMedia.asset.uri,
                      }}
                      resizeMode={ResizeMode.CONTAIN}
                      style={{
                        flex: 1,
                        width: 300,
                        height: 300,
                      }}
                      positionMillis={thumbnailPositionMillis}
                      onPlaybackStatusUpdate={(status) => {
                        // When video reaches end, toggle 'shouldPlayVideo' to false.
                        if (
                          status.isLoaded &&
                          status.durationMillis === status.positionMillis &&
                          shouldPlayVideo
                        ) {
                          togglePlayVideo();
                        }
                      }}
                      shouldPlay={shouldPlayVideo}
                      onError={(error) => {
                        console.log('error', error);
                      }}
                    />
                    <View
                      style={{
                        top: 0,
                        bottom: 0,
                        position: 'absolute',
                        alignSelf: 'center',
                        justifyContent: 'center',
                        display: shouldPlayVideo ? 'none' : 'flex',
                      }}
                    >
                      <View
                        style={{
                          width: 64,
                          height: 64,
                          borderRadius: 32,
                          backgroundColor: '#333',
                          opacity: 0.75,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <FontAwesome5
                          style={{
                            marginLeft: 2,
                          }}
                          size={24}
                          name="play"
                          color="white"
                        />
                      </View>
                    </View>
                  </Pressable>
                ) : null
              ) : null}
            </Animated.View>
            {/* </TouchableWithoutFeedback> */}

            {currentMedia?.asset.mediaType === 'video' &&
            !!currentMedia.asset.id ? (
              <View
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingBottom: 24,
                }}
              >
                <Text
                  style={{
                    paddingHorizontal: 16,
                    paddingBottom: 24,
                    color: 'white',
                    fontFamily: 'Lato-Bold',
                  }}
                >
                  Drag the slider to pick a cover photo for this video
                </Text>
                <ThumbnailPicker
                  asset={currentMedia.asset}
                  onSetThumbnail={(position) => {
                    setThumbnailPositionMillis(position);
                    setShouldPlayVideo(false);
                  }}
                />
              </View>
            ) : null}
          </View>
        </SafeAreaView>
      </View>
    </Modal>
  );
}
