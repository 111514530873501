import { StyleSheet } from 'react-native';
import { KEY_GREEN } from '/constants';

export default StyleSheet.create({
  inputContainer: {},
  inputField: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    marginBottom: '2%',
  },
  input: {
    // borderWidth: 1,
    // borderColor: '#F5F5F5',
    width: '100%',
    flex: 1,
    height: 60,
    backgroundColor: 'rgba(255,255,255,0.5)',
    fontWeight: 'bold',
    // borderRadius: 7,
    color: '#fafafa',
    // marginLeft: 10,
    paddingHorizontal: 10,
  },
  buttonContainer: {
    backgroundColor: KEY_GREEN,
    paddingVertical: 20,
    marginTop: 8,
    flexDirection: 'row',
    // borderRadius: 7,
    justifyContent: 'center',
    shadowColor: 'gray',
    shadowRadius: 2,
    shadowOpacity: 0.3,
    shadowOffset: {
      width: 1,
      height: 3,
    },
  },
  button: {
    flex: 1,
    fontFamily: 'Lato-Bold',
    fontStyle: 'normal',
    textAlign: 'center',
    color: 'black',
    fontSize: 18,
  },
  googleButton: {
    backgroundColor: '#fff',
  },
  appleButton: {
    backgroundColor: '#000',
  },
  facebookButton: {
    backgroundColor: '#3b5998',
  },
});
