import { Platform } from 'react-native';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles';
import { CARD_TITLE_FONT_SIZE, KEY_GRAY, KEY_GREEN } from '/constants';

export default CreateResponsiveStyle(
  {
    contentCardContainer: {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      paddingTop: 24,
    },
    contentCard: {
      // flex: Platform.OS === 'web' ? undefined : 1,
      // ...SECTION_CONTAINER,
      // padding: 0,
      // marginTop: 0,
      // marginBottom: 10,
      // maxWidth: '100%',
      overflow: 'hidden',
      // minHeight: 64,
      // maxHeight: 480,
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
    },
    dragHandleContainer: {
      width: 40,
      height: 21,
      alignSelf: 'center',
    },
    dragHandle: {
      ...(Platform.OS === 'web'
        ? {
            cursor: 'grab' as any,
          }
        : {}),
      alignSelf: 'center',
      elevation: 2,
      shadowOpacity: 0.3,
      shadowColor: KEY_GREEN,
      width: 40,
      height: 5,
      backgroundColor: KEY_GRAY,
      marginVertical: 8,
      borderRadius: 5,
    },
    listViewButton: {
      padding: 8,
      paddingHorizontal: 16,
    },
    errorContainer: {
      width: '100%',
      maxWidth: '100%',
      backgroundColor: 'white',
      borderRadius: 6,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 12,
    },
    errorText: {
      color: 'crimson',
      fontFamily: 'Lato-Bold',
      fontSize: 16,
      padding: 12,
      maxWidth: 280,
    },
    contentCardHeader: {
      padding: 10,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: 'white',
    },
    contentCardTitle: {
      padding: 10,
      paddingHorizontal: 8,
      fontFamily: 'Lato-Bold',
      fontSize: CARD_TITLE_FONT_SIZE + 1,
    },
  },
  {
    [minSize(DEVICE_SIZES.LARGE_DEVICE)]: {
      // overlayContainer: {
      //   alignItems: 'flex-start',
      // },
      contentCardContainer: {
        maxWidth: 540,
        paddingHorizontal: 10,
        alignSelf: 'flex-start',
        // maxWidth: undefined,
        // alignSelf: 'flex-start',
        // alignItems: 'flex-start',
      },
      contentCard: {
        // marginHorizontal: 10,
        // maxWidth: 400,
      },
    },
  },
);
