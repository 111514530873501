import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';
import { SkillRequestApplicationStatus } from '/generated/graphql';

const selectApplicant: UpdateResolver = (result, args, cache) => {
  const fragment = gql`
    fragment SelectApplicant on SkillRequestApplication {
      id
      status
      selected_at
    }
  `;

  cache.writeFragment(fragment, {
    id: args.id,
    selected_at: new Date(),
    status: SkillRequestApplicationStatus.SelectedUnconfirmed,
  });
};

export default selectApplicant;
