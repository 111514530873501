import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';

const followUser: UpdateResolver = (result, args, cache) => {
  const fragment = gql`
    fragment FollowUser on User {
      id
      follower_count
      is_following
    }
  `;
  const data = cache.readFragment(fragment, { id: args.userId }) as any;

  const writeData: any = {
    id: args.userId,
    is_following: true,
  };

  if (data) {
    writeData.follower_count = data.follower_count + 1;
  }

  cache.writeFragment(fragment, writeData);
};

export default followUser;
