import { UpdateResolver } from '@urql/exchange-graphcache';

const joinGroup: UpdateResolver = (result, args, cache) => {
  const newMembership = result.joinGroup as any;

  if (!newMembership) {
    cache.invalidate({
      __typename: 'Group',
      id: (args.input as any).id,
    });
  }

  cache.link(
    {
      __typename: 'Group',
      id: newMembership.groupId,
    },
    'membership',
    { __typename: 'GroupMember', ...newMembership },
  );
};

export default joinGroup;
