import { EvilIcons } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import {
  ActivityIndicator,
  FlatList,
  Modal,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { KEY_GRAY, TEXT_INPUT } from '/constants';

export type LocationPickerResult = {
  location: string | undefined;
  latitude: string | undefined;
  longitude: string | undefined;
};

interface ILocationPickerModalProps {
  visible?: boolean;
  onRequestClose?: (data?: LocationPickerResult) => void;
}

export default function LocationPickerModal(props: ILocationPickerModalProps) {
  const [data, setData] = useState<any[]>();
  const [filter, setFilter] = useState('');
  const [modalInfo, setModalInfo] = useState({ search: false });
  const [loading, setLoading] = useState(false);
  const [resultCount, setResultCount] = useState(null);

  // Reset picker state when `visible` changes
  useEffect(() => {
    setData(undefined);
    setResultCount(null);
    setFilter('');
  }, [props.visible]);

  const getData = async (filter: string) => {
    let ENDPOINT = `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?text=${filter}&f=json`;

    setLoading(true);

    fetch(ENDPOINT)
      .then(async (res) => {
        const resJSON = await res.json();
        setData(resJSON.suggestions);
      })
      .catch((err) => {
        // Only treat this as an error if it is not an AbortError
        if (err.name !== 'AbortError') {
          // console.log('[LocationInput]', err);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getLocationDetails = async ({
    text,
    magicKey,
    isCollection,
  }: any): Promise<LocationPickerResult> => {
    let ENDPOINT = isCollection
      ? `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?singleLine=${text}&magicKey=${magicKey}&maxLocations=1&outFields=Match_addr%2CPlace_addr%2CType&f=pjson`
      : `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?singleLine=${text}&magicKey=${magicKey}&f=pjson`;

    const res = await fetch(ENDPOINT);
    const resJSON = await res.json();

    let latitude = resJSON?.candidates[0]?.location.y;
    let longitude = resJSON?.candidates[0]?.location.x;

    if (!latitude) latitude = '';
    if (!longitude) longitude = '';

    return {
      location: text,
      // Make sure we return strings strings
      latitude: latitude + '',
      longitude: longitude + '',
    };
  };

  async function onSelect(data: any) {
    try {
      setLoading(true);

      const location = await getLocationDetails(data);

      props.onRequestClose?.(location);
    } catch (error: any) {
      // Only treat this as an error if it is not an AbortError
      if (error?.name !== 'AbortError') {
        // Do something here?
      }
    } finally {
      setLoading(false);
    }
  }

  const onRequestClose = () => {
    props.onRequestClose?.();
  };

  return (
    <Modal
      animationType="slide"
      visible={props.visible}
      onRequestClose={onRequestClose}
    >
      <SafeAreaView
        style={{
          height: '100%',
          width: '100%',
          maxWidth: 800,
          paddingTop: 16,
          alignSelf: 'center',
        }}
      >
        {/* SEARCH BAR */}
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            paddingHorizontal: 8,
            height: 48,
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 50,
          }}
        >
          <View
            style={{
              height: 48,
              width: 48,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TouchableOpacity
              style={{
                padding: 8,
              }}
              onPress={onRequestClose}
            >
              <EvilIcons name="close" size={24} color="black" />
            </TouchableOpacity>
          </View>
          <TextInput
            selectionColor={KEY_GRAY}
            multiline={false}
            autoCorrect={false}
            style={[TEXT_INPUT, { flex: 1 }]}
            placeholder="Search here..."
            placeholderTextColor={'gray'}
            enterKeyHint="search"
            onChangeText={(text) => setFilter(text)}
            onSubmitEditing={() => getData(filter)}
          />
          <TouchableOpacity
            testID="location-picker-search-button"
            style={{
              height: 36,
              width: 72,
              padding: 4,
              borderRadius: 10,
              backgroundColor: 'lightgrey',
              alignItems: 'center',
              justifyContent: 'center',
              marginHorizontal: 8,
            }}
            onPress={() => getData(filter)}
          >
            {loading ? (
              <ActivityIndicator color="black" size={24} />
            ) : (
              <EvilIcons name="search" size={24} color="black" />
            )}
          </TouchableOpacity>
        </View>
        {/* RESULTS */}
        {data?.length ? (
          <FlatList
            keyExtractor={({ _, index }) => index}
            renderItem={({ item }) => {
              return <LocationPickerItem data={item} onSelect={onSelect} />;
            }}
            data={data}
            style={{ flex: 1 }}
            contentContainerStyle={{ paddingBottom: 75 }}
          />
        ) : (
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: 50,
            }}
          >
            {loading ? (
              <Text
                style={{
                  padding: 10,
                  textAlign: 'center',
                  fontFamily: 'Lato-Bold',
                  fontSize: 15,
                }}
              >
                Searching....
              </Text>
            ) : filter.length && data !== undefined ? (
              <Text
                style={{
                  padding: 10,
                  textAlign: 'center',
                  fontFamily: 'Lato-Bold',
                  fontSize: 15,
                }}
              >
                No results
              </Text>
            ) : (
              <Text
                style={{
                  padding: 10,
                  textAlign: 'center',
                  fontFamily: 'Lato-Bold',
                  fontSize: 15,
                }}
              >
                Search for a location above
              </Text>
            )}
          </View>
        )}
      </SafeAreaView>
    </Modal>
  );
}

interface ILocationPickerItemProps {
  data: any;
  onSelect: (data: any) => void;
}

const LocationPickerItem = (props: ILocationPickerItemProps) => {
  return (
    <TouchableOpacity
      onPress={() => {
        props.onSelect(props.data);
        // getData(data.text, data.magicKey, data.isCollection);
      }}
      style={{
        padding: 25,
        borderBottomColor: 'rgba(0,0,0,0.09)',
        borderBottomWidth: 1,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <View style={{ width: '90%' }}>
          <Text style={{ fontSize: 20, fontFamily: 'LeagueSpartan-Bold' }}>
            {props.data.text}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};
