import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import React from 'react';
import SkillBadge from './SkilledImpact/elements/SkillBadge';
import { UserSkillPortfolio } from '/generated/graphql';
import LinksViewer from './LinksViewer';
import Carousel from './Carousel/Carousel';
import { KEY_GRAY, KEY_YELLOW } from '/constants';
import { ResizeMode } from 'expo-av';

interface Props {
  data:
    | Pick<
        UserSkillPortfolio,
        'skillName' | 'relevant_experience' | 'links' | 'media_carousel'
      >
    | undefined
    | null;
  style?: StyleProp<ViewStyle>;
}

export default function SkillPortfolioView(props: Props) {
  return (
    <View style={props.style}>
      <SkillBadge
        color={KEY_YELLOW}
        skill={props.data?.skillName ?? ''}
        style={{
          alignSelf: 'flex-start',
        }}
      />
      {props.data?.relevant_experience?.trim?.().length ? (
        <>
          <Text style={styles.fieldTitle}>My Experience</Text>
          <Text style={styles.fieldText}>
            {props.data.relevant_experience.trim()}
          </Text>
        </>
      ) : null}

      {props.data?.links?.length ? (
        <>
          <Text style={styles.fieldTitle}>My Links</Text>
          <LinksViewer
            links={props.data.links?.map((link) => ({
              title: link,
              url: link,
            }))}
          />
        </>
      ) : null}

      {props.data?.media_carousel?.length ? (
        <>
          <Text style={styles.fieldTitle}>My Media</Text>
          <Carousel
            imageResizeMode={ResizeMode.CONTAIN}
            data={props.data.media_carousel}
          />
        </>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  fieldTitle: {
    fontFamily: 'Lato-Bold',
    fontSize: 17,
    paddingVertical: 2,
    color: KEY_GRAY,
  },
  fieldText: {
    fontFamily: 'Lato',
    fontSize: 15,
    marginBottom: 2,
  },
  fieldEmptyText: {
    fontFamily: 'Lato',
    fontSize: 15,
    marginBottom: 2,
    color: 'gray',
  },
});
