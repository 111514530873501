import { StyleSheet } from 'react-native';
import { CARD_TITLE_FONT_SIZE } from '/constants';

export default StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
  },
  iconContainer: {
    width: 25,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 4,
  },
  headerText: {
    fontSize: CARD_TITLE_FONT_SIZE,
    fontFamily: 'Lato-Bold',
  },
  noDonationsText: {
    fontFamily: 'Lato',
    fontSize: 16,
    color: 'gray',
    textAlign: 'center',
    marginVertical: 16,
  },
});
