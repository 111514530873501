import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';

const removeVolunteer: UpdateResolver = (result, args, cache) => {
  cache.invalidate(
    {
      __typename: 'VolunteerRequest',
      id: args.requestId as string,
    },
    'volunteers',
  );

  const fragment = gql`
    fragment RemoveVolunteer on VolunteerRequest {
      id
      volunteers(withdrawn: false, removed: false) {
        total
        items {
          requestId
          userId
        }
      }
    }
  `;

  const volunteerRequest = cache.readFragment(fragment, {
    id: args.requestId as string,
  }) as any;

  if (!volunteerRequest?.volunteers?.items) {
    return;
  }

  const volunteers = volunteerRequest.volunteers.items.filter(
    (volunteer: any) => volunteer.userId !== args.userId,
  );

  cache.writeFragment(fragment, {
    id: args.volunteerRequestId as string,
    volunteers: {
      ...volunteerRequest.volunteers,
      items: volunteers,
      total: (volunteers.volunteers?.total ?? 1) - 1,
    },
  });
};

export default removeVolunteer;
