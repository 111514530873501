import { UpdateResolver } from '@urql/exchange-graphcache';

const deleteJobPost: UpdateResolver = (result, args, cache) => {
  cache.invalidate({
    __typename: 'JobPost',
    id: args.id as string,
  });
};

export default deleteJobPost;
