import { FontAwesome } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useCallback, useEffect, useState } from 'react';
import {
  ColorValue,
  FlatList,
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import styles from './TabBar.style';
import useBinaryTimingAnimation from '../../hooks/useBinaryTimingAnimation';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';
import Color from 'color';

export type TabBarTabs = {
  key: string;
  title: string;
}[];

interface Props {
  containerStyle?: StyleProp<ViewStyle>;
  tabBarStyle?: StyleProp<ViewStyle>;
  tabBarButtonStyle?: StyleProp<ViewStyle>;
  tabBarButtonTextStyle?: StyleProp<TextStyle>;
  tabs: TabBarTabs;
  index: number;
  overflowGradientColor?: Color | ColorValue;
  onChange: (index: number) => void;
  /** Default is `bottom` */
  highlightPosition?: 'top' | 'bottom';
}

const SCROLL_THRESHOLD = 16;

const DEFAULT_COLOR = Color({ r: 255, g: 255, b: 255 });

export default function TabBar(props: Props) {
  const [isCloseToStart, setIsCloseToStart] = useState(true);
  const [isCloseToEnd, setIsCloseToEnd] = useState(true);

  const leftArrowOpacity = useBinaryTimingAnimation({ value: !isCloseToStart });
  const rightArrowOpacity = useBinaryTimingAnimation({ value: !isCloseToEnd });

  const leftArrowAnimtedStyle = useAnimatedStyle(() => ({
    opacity: leftArrowOpacity.value,
  }));
  const rightArrowAnimtedStyle = useAnimatedStyle(() => ({
    opacity: rightArrowOpacity.value,
  }));

  const flatlistRef = React.useRef<FlatList>(null);

  const scrollOffset = React.useRef(0);

  const [layoutWidth, setLayoutWidth] = useState(0);
  const [contentSize, setContentSize] = useState(0);

  const updateButtons = useCallback(
    function () {
      setIsCloseToStart(scrollOffset.current < SCROLL_THRESHOLD);
      setIsCloseToEnd(
        layoutWidth + scrollOffset.current >= contentSize - SCROLL_THRESHOLD,
      );
    },
    [contentSize, layoutWidth],
  );

  useEffect(() => {
    updateButtons();
  }, [updateButtons]);

  function handleScroll({
    nativeEvent,
  }: NativeSyntheticEvent<NativeScrollEvent>) {
    scrollOffset.current = nativeEvent.contentOffset.x;

    updateButtons();
  }

  function handleLayout(e: LayoutChangeEvent) {
    setLayoutWidth(e.nativeEvent.layout.width);
  }

  function handleContentSizeChange(width: number) {
    setContentSize(width);
  }

  function scrollRight() {
    flatlistRef.current?.scrollToOffset({
      offset: scrollOffset.current + 250,
    });
  }

  function scrollLeft() {
    flatlistRef.current?.scrollToOffset({
      offset: Math.max(scrollOffset.current - 250, 0),
    });
  }

  function handleTabPress(index: number) {
    props.onChange?.(index);
    flatlistRef.current?.scrollToIndex({ index, animated: true });
  }

  return (
    <View style={props.containerStyle}>
      <FlatList
        ref={flatlistRef}
        initialScrollIndex={0}
        onScroll={handleScroll}
        onLayout={handleLayout}
        onScrollToIndexFailed={() => {
          // Do nothing
        }}
        onContentSizeChange={handleContentSizeChange}
        style={[styles.container, props.tabBarStyle]}
        horizontal
        keyboardShouldPersistTaps="handled"
        showsHorizontalScrollIndicator={false}
        data={props.tabs}
        keyExtractor={(item) => item.key}
        renderItem={({ item, index }) => (
          <TouchableOpacity
            key={item.key}
            style={[
              styles.button,
              props.tabBarButtonStyle,
              props.highlightPosition === 'top'
                ? {
                    borderTopWidth: 3,
                    borderTopColor: `rgba(0, 255, 157, ${
                      props.index === index ? 1 : 0
                    })`,
                  }
                : {
                    borderBottomWidth: 3,
                    borderBottomColor: `rgba(0, 255, 157, ${
                      props.index === index ? 1 : 0
                    })`,
                  },
            ]}
            onPress={() => handleTabPress(index)}
          >
            <Text style={[styles.buttonText, props.tabBarButtonTextStyle]}>
              {item.title}
            </Text>
          </TouchableOpacity>
        )}
      />

      {/* WEB SCROLLER */}

      <View pointerEvents="box-none" style={styles.tabBarWebScroller}>
        <Animated.View
          pointerEvents={
            Platform.OS === 'web' && !isCloseToStart ? 'auto' : 'none'
          }
          style={[
            {
              height: '100%',
              minWidth: 40,
            },
            leftArrowAnimtedStyle,
          ]}
        >
          <LinearGradient
            colors={[
              new Color(props.overflowGradientColor ?? DEFAULT_COLOR)
                .alpha(1)
                .rgb()
                .string(),
              new Color(props.overflowGradientColor ?? DEFAULT_COLOR)
                .alpha(0)
                .rgb()
                .string(),
            ]}
            style={StyleSheet.absoluteFill}
            start={{
              x: 0,
              y: 0.5,
            }}
            end={{
              x: 1,
              y: 0.5,
            }}
          />
          {Platform.OS === 'web' ? (
            <Pressable
              style={styles.tabBarWebScrollerButton}
              onPress={scrollLeft}
            >
              <View style={styles.tabBarWebScrollerButtonCircle}>
                <FontAwesome
                  name="arrow-left"
                  size={16}
                  style={styles.tabBarWebScrollerButtonIcon}
                  color="white"
                />
              </View>
            </Pressable>
          ) : null}
        </Animated.View>
        <Animated.View
          pointerEvents={
            Platform.OS === 'web' && !isCloseToEnd ? 'auto' : 'none'
          }
          style={[
            {
              height: '100%',
              minWidth: 100,
              alignItems: 'flex-end',
            },
            rightArrowAnimtedStyle,
          ]}
        >
          <LinearGradient
            colors={[
              new Color(props.overflowGradientColor ?? DEFAULT_COLOR)
                .alpha(1)
                .rgb()
                .string(),
              new Color(props.overflowGradientColor ?? DEFAULT_COLOR)
                .alpha(0)
                .rgb()
                .string(),
            ]}
            style={StyleSheet.absoluteFill}
            end={{
              x: 0,
              y: 0.5,
            }}
            start={{
              x: 1,
              y: 0.5,
            }}
          />
          {Platform.OS === 'web' ? (
            <Pressable
              style={styles.tabBarWebScrollerButton}
              onPress={scrollRight}
            >
              <View style={styles.tabBarWebScrollerButtonCircle}>
                <FontAwesome
                  name="arrow-right"
                  size={16}
                  color="white"
                  style={styles.tabBarWebScrollerButtonIcon}
                />
              </View>
            </Pressable>
          ) : null}
        </Animated.View>
      </View>
    </View>
  );
}
