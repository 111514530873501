import React from 'react';
import { Text, TextStyle, View, ViewStyle } from 'react-native';
import SearchBar from '../SearchBar/SearchBar';
import {
  CARD_TITLE_FONT_SIZE,
  SECTION_CONTAINER,
  TEXT_INPUT,
} from '/constants';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles';

type Props = {
  title?: string;
  renderIcon?: () => JSX.Element | null;
  style?: ViewStyle;
  initialQuery?: string;
  loading?: boolean;
  onQueryChange?: (query: string) => void;
  contentContainerStyle?: ViewStyle;
  titleStyle?: TextStyle;
};

export default function SearchableSection({
  title = '',
  renderIcon = () => null,
  titleStyle = {},
  style = {},
  initialQuery = '',
  onQueryChange,
  children,
  loading,
  contentContainerStyle = {},
}: React.PropsWithChildren<Props>) {
  const { styles } = useStyles();

  return (
    <View
      style={[
        SECTION_CONTAINER,
        {
          backgroundColor: 'transparent',
          padding: 0,
          overflow: 'hidden',
        },
        style,
      ]}
    >
      <View style={styles('titleAndSearchBar')}>
        {!title?.trim() && !renderIcon?.() ? null : (
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              paddingBottom: 8,
            }}
          >
            {typeof renderIcon === 'function' && !!renderIcon() ? (
              <View
                style={{
                  width: 25,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: 4,
                }}
              >
                {renderIcon?.()}
              </View>
            ) : null}
            <Text
              style={[
                {
                  fontSize: CARD_TITLE_FONT_SIZE,
                  fontFamily: 'Lato-Bold',
                },
                titleStyle,
              ]}
            >
              {title}
            </Text>
          </View>
        )}
        <SearchBar
          loading={loading}
          containerStyle={styles('searchBarContainerStyle')}
          placeholder="Search"
          onChangeText={onQueryChange}
          defaultValue={initialQuery}
        />
      </View>
      <View style={contentContainerStyle}>{children && children}</View>
    </View>
  );
}

const useStyles = CreateResponsiveStyle(
  {
    titleAndSearchBar: {
      backgroundColor: 'white',
      padding: 16,
      paddingBottom: 0,
    },
    searchBarContainerStyle: {
      ...TEXT_INPUT,
      marginTop: 0,
      marginHorizontal: 0,
    },
  },
  {
    [minSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      titleAndSearchBar: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 0,
        marginTop: 0,
      },
      searchBarContainerStyle: {
        marginLeft: 8,
      },
    },
  },
);
