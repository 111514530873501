import { useContext } from 'react';
import SmallAlert from './SmallAlert';
import { UrqlContext } from '/context/UrqlProvider';

export default function NetworkErrorAlert() {
  const { hasNetworkError, clearNetworkError } = useContext(UrqlContext);

  return (
    <SmallAlert
      message="Network error. Check your connection or try again later."
      onClose={clearNetworkError}
      visible={hasNetworkError}
      color="#eb5f50"
      textColor="white"
    />
  );
}
