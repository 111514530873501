import { KeyboardAwareScrollView } from '@mtourj/react-native-keyboard-aware-scroll-view';
import React, { useEffect, useRef } from 'react';
import { Keyboard, Text, View } from 'react-native';
import { CampaignBuilderComponentProps } from '../CampaignBuilder';
import CampaignBuilderFooter from '../components/CampaignBuilderFooter';
import VolunteerRequestForm from './elements/VolunteerRequestForm';
import {
  useFormValidationContext,
  withFormValidation,
} from '/components/ValidatedForm';
import sharedStyles from '/constants/CampaignBuilder';
import { VolunteerRequestDifficulty } from '/generated/graphql';
import { CampaignBuilderFormState } from '/hooks/useCampaignBuilder';
import { Ionicons } from '@expo/vector-icons';

const DEFAULT_STATE: Partial<CampaignBuilderFormState['volunteer_request']> = {
  accessibility_details: '',
  bathrooms_details: '',
  kid_friendly_details: '',
  accessibility_friendly: false,
  activity_waiver_uri: '',
  additional_directions: '',
  bathrooms_nearby: false,
  date: new Date().toISOString(),
  latitude: 0,
  longitude: 0,
  description: '',
  difficulty: VolunteerRequestDifficulty.Moderate,
  difficulty_details: '',
  kid_friendly: false,
  know_before_you_go: '',
  location: '',
  volunteers_needed: 0,
  point_of_contact: {},
};

function RequestVolunteers(props: CampaignBuilderComponentProps) {
  const scrollView = useRef<KeyboardAwareScrollView | null>();

  const { validateForm } = useFormValidationContext(scrollView);

  const isReady = useRef(false);

  useEffect(
    /** If volunteer_request is not in state, initialize it */
    function initializeState() {
      if (Object.keys(props.data?.volunteer_request ?? {}).length === 0) {
        props.setData({
          volunteer_request: DEFAULT_STATE,
        });
      }

      if (!isReady.current) isReady.current = true;
    },
    [props],
  );

  return (
    <>
      <KeyboardAwareScrollView
        onStartShouldSetResponder={() => {
          Keyboard.dismiss();
          return false;
        }}
        onStartShouldSetResponderCapture={() => {
          return false;
        }}
        innerRef={(r: any) => (scrollView.current = r)}
        contentContainerStyle={sharedStyles.scrollView}
      >
        <View style={sharedStyles.sectionContainer}>
          <View style={sharedStyles.titleWithIconContainer}>
            <Ionicons
              name="earth-sharp"
              size={24}
              color="black"
              style={{
                marginRight: 8,
              }}
            />
            <Text style={sharedStyles.subheading}>IN-PERSON</Text>
          </View>
        </View>
        <VolunteerRequestForm
          data={props.data?.volunteer_request}
          onChange={(data) => {
            if (!isReady.current) return;

            props.setData({
              volunteer_request:
                data as CampaignBuilderFormState['volunteer_request'],
            });
          }}
        />
      </KeyboardAwareScrollView>
      <CampaignBuilderFooter
        {...props}
        onNext={() => {
          props.next();
        }}
        validateForm={validateForm}
      />
    </>
  );
}

export default withFormValidation(RequestVolunteers);
