import { View, Text } from 'react-native';
import React, { useMemo, useRef } from 'react';
import HorizontalContainer from '../../common/Generic/HorizontalContainer';
import Avatar from '../../Avatar';
import Button from '../../Button';
import { FontAwesome } from '@expo/vector-icons';
import { KEY_DARK_GREEN, KEY_GRAY, KEY_GREEN } from '/constants';
import {
  CampaignConnectInvite,
  User,
  useDeleteCampaignConnectInviteMutation,
} from '/generated/graphql';
import dayjs from 'dayjs';
import Alert from '/Alert';
import { useAuthContext, useModalContext } from '/context';
import CreateCampaignConnectInviteModal from '../CreateCampaignConnectInviteModal';
import ViewCampaignConnectInviteModal from '../ViewCampaignConnectInviteModal';
import CampaignConnectInviteCampaignSubmittedCTAModal from '/components/Notifications/CallsToAction/CampaignConnectInviteCampaignSubmittedCTA/CampaignConnectInviteCampaignSubmittedCTAModal';
import getCreativeConnectLinkablePostType from '/util/creative-connect/getCreativeConnectLinkablePostType';
import CampaignConnectInviteCTAModal from '/components/Notifications/CallsToAction/CampaignConnectInviteCTA/CampaignConnectInviteCTAModal';

export interface ICampaignConnectInvite
  extends Pick<
    CampaignConnectInvite,
    | 'expiresAt'
    | 'message'
    | 'creativeConnectProjectId'
    | 'submittedCampaignId'
    | 'invitedUserEmail'
  > {
  id?: string;
  created_at?: string;
  invitedUser?: Pick<User, 'id' | 'name' | 'profile_image'> | undefined | null;
  invitingUser?: Pick<User, 'id' | 'name' | 'profile_image'> | undefined | null;
}

type Props = {
  invite: ICampaignConnectInvite;
  viewingAsInvitedUser: boolean;
  darkTheme?: boolean;
  onUpdated?: (invite: ICampaignConnectInvite) => void;
  onRemoved?: () => void;
};

export default function CampaignConnectInviteCard(props: Props) {
  const { userData } = useAuthContext();
  const { spawnModal, closeModal } = useModalContext();

  const [{ fetching: deleting }, deleteCampaignConnectInvite] =
    useDeleteCampaignConnectInviteMutation();

  const editModalIdRef = useRef<string | null>(null);
  function onEdit() {
    // Only allow editing invites that have not been sent
    if (!userData?.id || props.invite.id) return;

    editModalIdRef.current = spawnModal({
      title: 'Edit Invite',
      style: {
        width: '100%',
        height: '100%',
        maxWidth: 480,
        maxHeight: 640,
      },
      component: (
        <CreateCampaignConnectInviteModal
          invitingUserId={userData.id}
          invitedUser={props.invite.invitedUser || undefined}
          ineligibleUserIds={[]}
          saveButtonAction="just-return"
          initialState={{
            seletedOrg: props.invite.invitedUser || null,
            message: props.invite.message,
            expiresAt: props.invite.expiresAt
              ? new Date(props.invite.expiresAt)
              : null,
          }}
          onRequestClose={(invite) => {
            if (invite) {
              props.onUpdated?.(invite);
            }

            if (editModalIdRef.current) closeModal(editModalIdRef.current);
          }}
        />
      ),
    });
  }

  function onRemove() {
    const remove = async () => {
      if (props.invite.id) {
        const { error } = await deleteCampaignConnectInvite({
          inviteId: props.invite.id,
        });

        if (error) {
          console.error(error);
          Alert.alert('Error', 'Failed to delete invite, please try again.');
          return;
        }

        Alert.notify({
          message: 'Invite deleted',
          color: KEY_GREEN,
        });
      }

      props.onRemoved?.();
    };

    Alert.alert(
      'Remove Invite',
      'Are you sure you want to remove this invite?',
      [
        {
          style: 'cancel',
        },
        {
          text: 'Remove',
          style: 'destructive',
          onPress: remove,
        },
      ],
    );
  }

  const modalIdRef = useRef<string | null>(null);
  function onViewInvite() {
    if (!props.invite) return;

    if (props.viewingAsInvitedUser) {
      const { id: inviteId, created_at: inviteCreatedAt } = props.invite;
      if (!inviteId || !inviteCreatedAt) return;

      modalIdRef.current = spawnModal({
        title: 'Campaign Connect',
        style: {
          width: '100%',
          maxWidth: 480,
        },
        component: (
          <CampaignConnectInviteCTAModal
            hideCTA
            invite={{
              ...props.invite,
              id: inviteId,
              created_at: inviteCreatedAt,
            }}
            invitingUser={props.invite.invitingUser}
            onRequestClose={() => {
              if (modalIdRef.current) closeModal(modalIdRef.current);
            }}
          />
        ),
      });
    } else if (!props.invite.submittedCampaignId) {
      modalIdRef.current = spawnModal({
        title: 'View Invite',
        style: {
          width: '100%',
          maxWidth: 480,
          maxHeight: 640,
        },
        component: (
          <ViewCampaignConnectInviteModal
            invite={props.invite}
            onRequestClose={(shouldRemove) => {
              if (modalIdRef.current) closeModal(modalIdRef.current);
              if (shouldRemove) {
                props.onRemoved?.();
              }
            }}
          />
        ),
      });
    } else {
      modalIdRef.current = spawnModal({
        title: 'Review Campaign',
        style: {
          width: '100%',
          height: '100%',
          maxWidth: 480,
          maxHeight: 640,
        },
        component: (
          <CampaignConnectInviteCampaignSubmittedCTAModal
            hideLinkedPost
            invite={props.invite as Required<typeof props.invite>}
            invitedUser={props.invite.invitedUser}
            invitingUser={props.invite.invitingUser}
            onRequestClose={(shouldRemove) => {
              if (modalIdRef.current) closeModal(modalIdRef.current);
              if (shouldRemove) {
                props.onRemoved?.();
              }
            }}
          />
        ),
      });
    }
  }

  const displayUser = props.viewingAsInvitedUser
    ? props.invite.invitingUser
    : props.invite.invitedUser;

  const invitedToFeatureOnPostTypeLabel = useMemo(() => {
    const linkablePostTypeKey = getCreativeConnectLinkablePostType(
      props.invite,
    );
    if (!linkablePostTypeKey) return null;

    switch (linkablePostTypeKey) {
      case 'creativeConnectProjectId':
        return 'Creative Connect project.';
      default:
        linkablePostTypeKey satisfies never;
        return 'post';
    }
  }, [props.invite]);

  return (
    <HorizontalContainer
      style={{
        marginTop: 4,
        opacity: deleting ? 0.5 : 1,
        pointerEvents: deleting ? 'none' : 'auto',
      }}
    >
      <Avatar
        rounded
        size={48}
        source={{ uri: displayUser?.profile_image }}
        containerStyle={{
          marginRight: 8,
        }}
      />
      <View style={{ flex: 1 }}>
        <Text
          numberOfLines={2}
          style={{
            flex: 1,
            fontFamily: 'LeagueSpartan-Bold',
            fontSize: 17,
            textTransform: 'uppercase',
            color: props.darkTheme ? 'white' : 'black',
          }}
        >
          {displayUser?.name ??
            (props.viewingAsInvitedUser
              ? 'Unknown User'
              : props.invite.invitedUserEmail)}
        </Text>
        <Text
          style={{
            flex: 1,
            fontFamily: 'LeagueSpartan-Bold',
            fontSize: 14,
            color: props.darkTheme ? 'lightgray' : 'gray',
            textTransform: 'uppercase',
          }}
        >
          {props.invite.expiresAt
            ? `Expires in ${dayjs(props.invite.expiresAt).fromNow(true)}`
            : 'Never expires'}
        </Text>
        <Text
          style={{
            flex: 1,
            fontFamily: 'Lato-Bold',
            fontSize: 14,
            color: props.invite.submittedCampaignId
              ? 'crimson'
              : props.invite.id
              ? props.darkTheme
                ? KEY_GREEN
                : KEY_DARK_GREEN
              : props.darkTheme
              ? 'lightgray'
              : KEY_GRAY,
          }}
        >
          {props.viewingAsInvitedUser
            ? `Invited you to feature a Campaign on their ${invitedToFeatureOnPostTypeLabel}`
            : props.invite.submittedCampaignId
            ? 'Review Required'
            : props.invite.id
            ? 'Invite sent'
            : 'Queued to send'}
        </Text>
      </View>
      {props.viewingAsInvitedUser ? (
        <Button label="View" onPress={onViewInvite} />
      ) : (
        <>
          {
            // Only show edit button for invites that have not been sent
            !props.invite.id ? (
              <Button
                label="Edit"
                containerStyle={{
                  marginRight: 6,
                }}
                onPress={() => {
                  onEdit();
                }}
              />
            ) : (
              <Button
                label={props.invite.submittedCampaignId ? 'Review' : 'View'}
                containerStyle={{
                  marginRight: 6,
                }}
                onPress={onViewInvite}
              />
            )
          }
          <Button
            loading={deleting}
            label={<FontAwesome size={20.5} name="trash-o" color={KEY_GRAY} />}
            onPress={onRemove}
          />
        </>
      )}
    </HorizontalContainer>
  );
}
