import { StyleSheet } from 'react-native';
import { KEY_GRAY } from '.';

export default StyleSheet.create({
  container: {
    width: 100,
    height: 100,
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: 'gray',
  },
  sectionButton: {
    alignSelf: 'center',
    marginBottom: 10,
  },
  touchableView: {
    backgroundColor: '#0EE6A6',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    height: 48,
    width: 243,
    marginBottom: 10,
  },
  touchableText: {
    fontFamily: 'Lato-Bold',
    color: 'white',
    fontSize: 10,
    textAlign: 'center',
    alignItems: 'center',
  },
  imageButton: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  editOverlay: {
    flexDirection: 'row',
    backgroundColor: 'black',
    opacity: 0.8,
    position: 'absolute',
    paddingHorizontal: 10,
    left: 0,
    right: 0,
    bottom: 0,
    height: 18,
  },
  imageContain: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: -1,
  },
  videoIconContainer: {
    position: 'absolute',
    zIndex: 1,
    top: 8,
    left: 8,
  },
  uploadIndicator: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    paddingVertical: 8,
    alignItems: 'center',
  },
  uploadIndicatorText: {
    marginLeft: 6,
    fontFamily: 'Lato-Bold',
  },
  uploadErrorText: {
    fontFamily: 'Lato-Bold',
    color: 'crimson',
    fontSize: 15,
  },
  retryUploadButton: {
    paddingHorizontal: 8,
    paddingVertical: 2,
  },
  retryUploadButtonText: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    fontSize: 15,
  },
  errorOverlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0,0,0,0.86)',
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorText: {
    fontFamily: 'Lato-Bold',
    color: 'crimson',
    fontSize: 15,
    textAlign: 'center',
  },
  mediaTileContainer: {
    width: 80,
    height: 80,
    borderWidth: 4,
    borderColor: 'transparent',
    borderRadius: 8,
    overflow: 'hidden',
  },
});
