import { FontAwesome } from '@expo/vector-icons';
import React, { useRef, useState } from 'react';
import { StyleSheet, Text } from 'react-native';
import Avatar from '../Avatar';
import Button from '../Button';
import DateTimePicker from '../DateTimePicker/DateTimePicker';
import RadioOption from '../RadioOption';
import UserPicker from '../UserPicker/UserPicker';
import {
  ValidatedAny,
  ValidatedTextInput,
  useFormValidationContext,
  withFormValidation,
} from '../ValidatedForm';
import ErrorText from '../common/Generic/ErrorText';
import HorizontalContainer from '../common/Generic/HorizontalContainer';
import ScrollView from '../common/ScrollView/ScrollView';
import SectionText from '../common/Section/SectionText';
import Alert from '/Alert';
import {
  KEY_DARK_GREEN,
  KEY_GRAY,
  KEY_GREEN,
  TEXT_INPUT,
  TEXT_INPUT_LARGE,
} from '/constants';
import {
  CampaignConnectInviteInput,
  CreateCampaignConnectInvitesInput,
  User,
  UserRole,
  useCreateCampaignConnectInvitesMutation,
} from '/generated/graphql';
import { isValidEmail } from '/util';

type CampaignConnectInviteLinkablePostType = keyof Pick<
  CreateCampaignConnectInvitesInput,
  'creativeConnectProjectId'
>;

export type CampaignConnectInviteModalLinkablePost = {
  type: CampaignConnectInviteLinkablePostType;
  id: string;
};

export type CreateCampaignConnectInviteModalInvite =
  CampaignConnectInviteInput & {
    id?: string;
    invitedUser?:
      | Pick<User, 'id' | 'name' | 'profile_image'>
      | undefined
      | null;
    submittedCampaignId?: string | null;
  };

type Props = {
  /** ID of the user who is inviting the organization. */
  invitingUserId: string;
  /** Email of user who is being invited. */
  invitedUserEmail?: string;
  /** User who is being invited. If set, user cannot change invited user. */
  invitedUser?: Pick<User, 'id' | 'name' | 'profile_image'>;
  /** IDs of users to exclude from results when searching for an organization to invite. */
  ineligibleUserIds: string[];
  initialState?: {
    seletedOrg: Pick<User, 'id' | 'name' | 'profile_image'> | null;
    message: string;
    expiresAt: Date | null;
  };
  /** The post that will feature the campaign when it is submitted & approved. Required if mode is 'save-immediately-and-return'. */
  linkedPost?: CampaignConnectInviteModalLinkablePost;
  /** Action to take when the save button is pressed.
   * - 'save-immediately-and-return': Publish the invite immediately and close the modal,
   *   returning the invite object.
   * - 'just-return': Don't publish the invite, just close the modal returning the invite object. */
  saveButtonAction: 'save-immediately-and-return' | 'just-return';
  /**
   * Called when the modal is closed.
   * @param invite The invite that was created, if any. If saveButtonAction is 'save-immediately-and-return',
   * the created invite ID will be included.
   */
  onRequestClose?: (invite?: CreateCampaignConnectInviteModalInvite) => void;
};

const MIN_EXPIRY_DATE_OFFSET = 1000 * 60 * 60 * 12;

function CreateCampaignConnectInviteModal(props: Props) {
  const scrollRef = useRef<any>();
  const { validateForm, onScroll, fields } =
    useFormValidationContext(scrollRef);

  const [inviteMode, setInviteMode] = useState<'userId' | 'email'>('userId');
  const [selectedOrg, setSelectedOrg] = useState<Pick<
    User,
    'id' | 'name' | 'profile_image'
  > | null>(props.initialState?.seletedOrg || null);
  const [invitedUserEmail, setInvitedUserEmail] = useState<string>(
    props.invitedUserEmail || '',
  );
  const [hasExpiration, setHasExpiration] = useState<boolean>(
    !!props.initialState?.expiresAt,
  );
  const [expiresAt, setExpiresAt] = useState<Date | null>(
    props.initialState?.expiresAt || null,
  );
  const [message, setMessage] = useState<string>(
    props.initialState?.message || '',
  );

  const [{ fetching: creating }, createCampaignConnectInvite] =
    useCreateCampaignConnectInvitesMutation();

  async function onSavePressed() {
    if (!validateForm()) return;

    if (props.saveButtonAction === 'just-return') {
      props.onRequestClose?.({
        invitedUserId: inviteMode === 'userId' ? selectedOrg?.id : undefined,
        invitedUserEmail: inviteMode === 'email' ? invitedUserEmail : undefined,
        invitedUser: selectedOrg || undefined,
        message: message,
        expiresAt: hasExpiration ? expiresAt : null,
      });
      return;
    }

    if (!props.linkedPost) {
      console.error(
        'linkedPost is required when saveButtonAction is save-immediately-and-return',
      );
      return;
    }

    const input: CreateCampaignConnectInvitesInput = {
      invitingUserId: props.invitingUserId,
      [props.linkedPost.type]: props.linkedPost.id,
      invites: [
        {
          invitedUserId: inviteMode === 'userId' ? selectedOrg?.id : undefined,
          invitedUserEmail:
            inviteMode === 'email' ? invitedUserEmail : undefined,
          expiresAt: hasExpiration ? expiresAt : null,
          message,
        },
      ],
    };

    const { data, error } = await createCampaignConnectInvite({
      input,
    });

    if (error) {
      console.error(error);
      Alert.alert('Oh no', 'Something went wrong. Please try again.');
      return;
    }

    Alert.notify({
      message: 'Invitation sent!',
      color: KEY_GREEN,
    });
    props.onRequestClose?.({
      ...input.invites[0],
      ...data?.createCampaignConnectInvites[0],
      invitedUser: selectedOrg || undefined,
    });
  }

  const invitedUser = props.invitedUser ?? selectedOrg;

  return (
    <>
      <ScrollView
        style={{
          // Margin -1, and padding 1 in contentContainerStyle allows TextInput outlines to
          // render without clipping.
          margin: -1,
          opacity: creating ? 0.6 : 1,
          pointerEvents: creating ? 'none' : 'auto',
        }}
        onScroll={onScroll}
        ref={(r) => {
          if (r) scrollRef.current = r;
        }}
        scrollEnabled={!creating}
        contentContainerStyle={{
          padding: 1,
        }}
      >
        <SectionText>
          Invite an organization to create a Campaign and have it be featured on
          your post.
        </SectionText>
        <Text style={styles.fieldLabel}>INVITED ORGANIZATION</Text>

        {inviteMode === 'email' ? (
          <>
            {fields.invitedUserEmail?.valid === false ? (
              <ErrorText>Please enter a valid email address.</ErrorText>
            ) : null}
            <SectionText>
              Enter the email address of the organization you want to invite.
            </SectionText>
            <ValidatedTextInput
              name="invitedUserEmail"
              value={invitedUserEmail}
              disableAutoValidation
              style={TEXT_INPUT}
              containerStyle={{
                marginTop: 2,
              }}
              placeholder="Email address"
              onChangeText={setInvitedUserEmail}
              validate={(v) => isValidEmail(v || '')}
            />

            <SectionText style={{ fontFamily: 'Lato-Bold', color: 'gray' }}>
              Is the organization you're looking for already on Key
              Conservation?
            </SectionText>
            <SectionText
              onPress={() => {
                setInviteMode('userId');
              }}
              style={{
                color: KEY_DARK_GREEN,
                fontFamily: 'Lato-Bold',
              }}
            >
              <FontAwesome
                name="search"
                size={16}
                style={{
                  marginRight: 4,
                }}
                color={KEY_DARK_GREEN}
              />
              Click here to search by name instead
            </SectionText>
          </>
        ) : (
          <ValidatedAny name="invitedOrg" value={invitedUser}>
            {fields.invitedOrg?.valid === false ? (
              <ErrorText>Please select an organization to invite.</ErrorText>
            ) : null}
            {invitedUser ? (
              <HorizontalContainer>
                <Avatar
                  rounded
                  source={{
                    uri: invitedUser.profile_image,
                  }}
                  size={48}
                />
                <Text style={styles.selectedOrgName} numberOfLines={2}>
                  {invitedUser.name}
                </Text>
                {/* If `props.invitedUser` is set, we don't allow changing the invited user. */}
                {invitedUser === props.invitedUser ? null : (
                  <Button
                    onPress={() => {
                      setSelectedOrg(null);
                    }}
                    label={
                      <FontAwesome
                        size={19}
                        name="trash-o"
                        style={{
                          marginRight: 4,
                        }}
                        color={KEY_GRAY}
                      />
                    }
                  />
                )}
              </HorizontalContainer>
            ) : (
              <UserPicker
                showFilters
                role={UserRole.Conservationist}
                users={selectedOrg ? [selectedOrg] : []}
                excludeUserIds={[
                  props.invitingUserId,
                  ...props.ineligibleUserIds,
                ]}
                onChange={(usrs) => {
                  setSelectedOrg(usrs[0]);
                }}
              />
            )}

            <SectionText
              style={{
                fontFamily: 'Lato-Bold',
                color: 'gray',
              }}
            >
              Can't find the organization you're looking for?
            </SectionText>
            <SectionText
              onPress={() => {
                setInviteMode('email');
              }}
              style={{
                color: KEY_DARK_GREEN,
                fontFamily: 'Lato-Bold',
              }}
            >
              <FontAwesome
                name="envelope"
                size={16}
                style={{
                  marginRight: 4,
                }}
                color={KEY_DARK_GREEN}
              />
              Click here to invite by email instead
            </SectionText>
          </ValidatedAny>
        )}

        <Text style={styles.fieldLabel}>MESSAGE (required)</Text>
        <SectionText>
          Be sure to write a clear message describing the campaign you want this
          organization to create.
        </SectionText>
        <ValidatedTextInput
          name="message"
          value={message}
          multiline
          containerStyle={{
            marginTop: 2,
          }}
          style={TEXT_INPUT_LARGE}
          placeholderTextColor={'gray'}
          maxLength={2000}
          placeholder="What kind of campaign do you want to create?"
          onChangeText={setMessage}
        />

        <Text style={styles.fieldLabel}>EXPIRY DATE (optional)</Text>
        <SectionText>
          Optionally set a date when the invitation will expire.
        </SectionText>

        <RadioOption
          title={'No Expiration'}
          subtitle="The invitation should never expire."
          isSelected={!hasExpiration}
          onPress={() => {
            setHasExpiration(false);
          }}
        />
        <RadioOption
          title={'Set Expiration'}
          subtitle="The invitation should expire on a specific date."
          isSelected={hasExpiration}
          onPress={() => {
            setHasExpiration(true);
            if (!expiresAt)
              setExpiresAt(new Date(Date.now() + MIN_EXPIRY_DATE_OFFSET * 2));
          }}
        />
        <ValidatedAny
          name="expiresAt"
          value={expiresAt}
          validate={(value) => {
            if (!hasExpiration) return true;
            return (
              !!value && value > new Date(Date.now() + MIN_EXPIRY_DATE_OFFSET)
            );
          }}
        >
          <DateTimePicker
            style={{
              marginTop: 8,
              pointerEvents: hasExpiration ? 'auto' : 'none',
              opacity: hasExpiration ? 1 : 0.5,
            }}
            minDate={new Date(Date.now() + MIN_EXPIRY_DATE_OFFSET)}
            mode="datetime"
            value={expiresAt ?? new Date(Date.now() + 1000 * 60 * 60 * 24 * 7)}
            onChange={(date) => {
              setExpiresAt(date);
            }}
          />
        </ValidatedAny>
        {fields.expiresAt?.valid === false ? (
          <ErrorText>
            Please select a date that is at least{' '}
            {(MIN_EXPIRY_DATE_OFFSET / (1000 * 60 * 60)).toFixed(0)} hours from
            now.
          </ErrorText>
        ) : null}
      </ScrollView>
      <Button
        label={
          props.saveButtonAction === 'save-immediately-and-return'
            ? 'Invite'
            : 'Save'
        }
        loading={creating}
        onPress={onSavePressed}
        containerStyle={{
          alignSelf: 'flex-end',
          marginTop: 10,
        }}
        style={{
          backgroundColor: KEY_GREEN,
        }}
      />
    </>
  );
}

export default withFormValidation(CreateCampaignConnectInviteModal);

const styles = StyleSheet.create({
  fieldLabel: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 16,
    color: 'black',
    marginTop: 12,
    marginBottom: 2,
  },
  selectedOrgName: {
    flex: 1,
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 19,
    marginHorizontal: 8,
    textTransform: 'uppercase',
  },
});
