import { Ionicons } from '@expo/vector-icons';
import {
  AVPlaybackStatus,
  ResizeMode,
  Video,
  VideoReadyForDisplayEvent,
} from 'expo-av';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ActivityIndicator,
  Animated,
  Easing,
  ImageLoadEventData,
  ImageSourcePropType,
  NativeSyntheticEvent,
  Pressable,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
  findNodeHandle,
  useWindowDimensions,
} from 'react-native';
import useCDNVideoSource from '../hooks/useCDNVideoSource';
import Hoverable from './Hoverable';
import ViewportAwareVideo from './ViewportAwareVideo';
import { KEY_GRAY } from '/constants';
import { useVideoPlayerContext } from '/context';
import useDebouncedState from '/hooks/useDebouncedState';
import { useIsFocused } from '@react-navigation/native';

type VideoPlayerMode = 'default' | 'autoplay-viewport';

interface IVideoPlayerProps {
  /** Default is `false` */
  isLooping?: boolean;
  /** Options:
   * `default`: Video not loaded until demanded, user has to play video
   * `autoplay-viewport`: Video automatically downloaded and played when
   *  entering the viewport.
   */
  mode?: VideoPlayerMode;
  /** Force unload video. Useful for edge cases. */
  suppress?: boolean;
  style?: StyleProp<ViewStyle>;
  /** Default is `ResizeMode.CONTAIN` */
  resizeMode?: ResizeMode;
  thumbnailResizeMode?: ResizeMode;
  sourceUri?: string | undefined;
  thumbnailSource?: ImageSourcePropType;
  hideControls?: boolean;
  isMuted?: boolean;
  shouldPlay?: boolean;
  preload?: boolean;
  /** default is `hls` */
  // videoQualityPreset?: 'hls' | '360p' | '480p' | '720p' | '1080p' | 'original';
  onLoadStart?: () => void;
  onLoad?: (status: AVPlaybackStatus) => void;
  onError?: (error: string) => void;
  onReadyForDisplay?: (event: VideoReadyForDisplayEvent) => void;
  onThumbnailLoad?: (event: NativeSyntheticEvent<ImageLoadEventData>) => void;
}

export default function VideoPlayer({
  mode = 'default',
  ...props
}: IVideoPlayerProps) {
  const { _setActiveVideoNodeHandle, _setMute, state } =
    useVideoPlayerContext();

  const { height: window_height } = useWindowDimensions();

  const videoSource = useCDNVideoSource({
    uri: props.sourceUri,
  });

  const isFocused = useIsFocused();

  const [pause, setPause] = useState(false);
  // Don't actually begin downloading the video until we need it
  const [shouldFetchVideo, setShouldFetchVideo] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const [, isBuffering, setIsBufferingDebounced, setIsBufferingImmediate] =
    useDebouncedState(false);
  const [_loading, setLoading] = useState(false);
  const loading = _loading || videoSource.fetching;

  const inViewport = useRef(false);

  const videoPositionMillis = useRef(0);

  const videoRef = useRef<Video>();
  const videoNodeHandle = useRef<number | null>(null);

  const muteButtonOpacity = useRef(new Animated.Value(0));
  const fadeMuteButtonTimeoutRef = useRef<NodeJS.Timeout>();

  const shouldPlay = useMemo(() => {
    return (
      shouldFetchVideo === true &&
      pause === false &&
      isFocused === true &&
      state.activeVideoNodeHandle === videoNodeHandle.current
    );
  }, [isFocused, pause, shouldFetchVideo, state.activeVideoNodeHandle]);

  const animateHideMuteButton = useMemo(
    () => () => {
      if (fadeMuteButtonTimeoutRef.current)
        clearTimeout(fadeMuteButtonTimeoutRef.current);

      Animated.timing(muteButtonOpacity.current, {
        toValue: 0,
        useNativeDriver: true,
        duration: 100,
        easing: Easing.out(Easing.poly(4)),
      }).start();
    },
    [],
  );

  const _animateFadeMuteButton = useMemo(
    () => () => {
      Animated.timing(muteButtonOpacity.current, {
        toValue: 0.6,
        useNativeDriver: true,
        duration: 350,
        easing: Easing.out(Easing.poly(4)),
      }).start();
    },
    [],
  );

  const animateShowMuteButton = useMemo(
    () =>
      (timeBeforeFading: number = 4000) => {
        Animated.timing(muteButtonOpacity.current, {
          toValue: 1,
          useNativeDriver: true,
          duration: 80,
          easing: Easing.out(Easing.poly(4)),
        }).start(() => {
          if (fadeMuteButtonTimeoutRef)
            clearTimeout(fadeMuteButtonTimeoutRef.current);

          // If timeBeforeFading was set to 0, then don't fade at all
          if (!timeBeforeFading) return;

          fadeMuteButtonTimeoutRef.current = setTimeout(() => {
            _animateFadeMuteButton();
          }, timeBeforeFading);
        });
      },
    [_animateFadeMuteButton],
  );

  const startVideo = useCallback(
    function () {
      if (props.suppress) return;

      setShouldFetchVideo(true);
      setPause(false);
      _setActiveVideoNodeHandle(videoNodeHandle.current);
    },
    [_setActiveVideoNodeHandle, props.suppress],
  );

  /** Stop playback and set current playback position to 0ms */
  function setVideoPosition(positionMs: number) {
    videoPositionMillis.current = positionMs;
    videoRef.current?.setPositionAsync(positionMs);
  }

  function pauseVideo() {
    setPause(true);
  }

  const resetVideo = useCallback(function () {
    pauseVideo();
    setVideoPosition(0);
  }, []);

  const onViewportEnter = useCallback(
    function () {
      inViewport.current = true;

      if (mode === 'autoplay-viewport' || props.shouldPlay) {
        startVideo();
      }
    },
    [mode, props.shouldPlay, startVideo],
  );

  function onViewportLeave() {
    inViewport.current = false;

    // If we've left the viewport and the currently active video is set as this one,
    // unset it
    if (state.activeVideoNodeHandle === videoNodeHandle.current) {
      _setActiveVideoNodeHandle(null);
    }

    resetVideo();
  }

  useEffect(
    function handleShouldPlayProp() {
      if (!isFocused) return;

      if (props.shouldPlay) startVideo();
      else if (props.shouldPlay === false) pauseVideo();
    },
    [isFocused, props.shouldPlay, startVideo],
  );

  useEffect(
    function handlePreloadProp() {
      if (!props.suppress && props.preload && videoSource.source) {
        setShouldFetchVideo(true);
      }
    },
    [props.preload, props.suppress, videoSource.source],
  );

  useEffect(
    function handleMuteProp() {
      if (props.isMuted) {
        _setMute(true);
      } else if (props.isMuted === false) {
        _setMute(false);
      }
    },
    [props.isMuted, _setMute],
  );

  useEffect(
    function handleSuppressionAndMode() {
      // If `suppress` was set, unload video
      if (props.suppress) {
        setShouldFetchVideo(false);
      } else {
        // If supression was removed, check if we need to be playing right now
        if (mode === 'autoplay-viewport' && inViewport.current) startVideo();
      }
    },
    [mode, startVideo, props.suppress],
  );

  useEffect(
    function handleShouldPlayChanged() {
      if (shouldPlay) {
        animateShowMuteButton();

        // When `shouldPlay` is changed, we need to manually begin playback using Video's ref
        videoRef.current?.playFromPositionAsync(videoPositionMillis.current);
      } else {
        animateHideMuteButton();
        videoRef.current?.pauseAsync();
      }
    },
    [animateHideMuteButton, animateShowMuteButton, shouldPlay],
  );

  useEffect(
    function handlePlaybackContextChanged() {
      /** If video is playing and another video has become the active video, stop playing */
      if (
        shouldPlay &&
        state.activeVideoNodeHandle &&
        state.activeVideoNodeHandle !== videoNodeHandle.current
      ) {
        resetVideo();
      }
    },
    [state, shouldPlay, resetVideo],
  );

  function onPlaybackStatusUpdate(status: AVPlaybackStatus) {
    if (!status.isLoaded) {
      !isBuffering && setIsBufferingDebounced(true);
      return;
    }

    status.isBuffering
      ? setIsBufferingDebounced(true)
      : setIsBufferingImmediate(false);

    videoPositionMillis.current = status.positionMillis;

    setIsPlaying(status.isPlaying);

    if (status.didJustFinish && !props.isLooping) {
      resetVideo();
    }
  }

  function onLoadStart() {
    setLoading(true);

    props.onLoadStart?.();
  }

  function onLoad(status: AVPlaybackStatus) {
    setLoading(false);

    props.onLoad?.(status);
  }

  function onError(error: string) {
    setLoading(false);

    props.onError?.(error);
  }

  const handlePress = useCallback(
    function () {
      if (pause) startVideo();
      else pauseVideo();
    },
    [pause, startVideo],
  );

  function onToggleMute() {
    animateShowMuteButton(); // Bring mute button to focus when pressed
    _setMute(!state.mute);
  }

  // const videoQualityPreset = props.videoQualityPreset || 'hls';

  return (
    <Pressable
      disabled={props.hideControls}
      onPress={handlePress}
      style={[styles.container, props.style]}
    >
      {/* VIDEO */}
      {!videoSource.source.uri ? null : (
        <ViewportAwareVideo
          isLooping={props.isLooping}
          innerRef={(r) => {
            if (r) {
              videoRef.current = r;
              videoNodeHandle.current = findNodeHandle(r);
            }
          }}
          onLoadStart={onLoadStart}
          onLoad={onLoad}
          onError={onError}
          preTriggerRatio={window_height > 600 ? -0.11 : -0.11}
          isMuted={props.isMuted || state.mute}
          onPlaybackStatusUpdate={onPlaybackStatusUpdate}
          shouldPlay={shouldPlay}
          onViewportEnter={onViewportEnter}
          onViewportLeave={onViewportLeave}
          onReadyForDisplay={props.onReadyForDisplay}
          source={shouldFetchVideo ? videoSource.source : undefined}
          usePoster
          posterStyle={{
            resizeMode: 'cover',
          }}
          posterSource={props.thumbnailSource}
          style={{
            width: '100%',
            height: '100%',
          }}
          videoStyle={{
            width: '100%',
            height: '100%',
          }}
          containerStyle={{
            flex: 1,
          }}
          resizeMode={props.resizeMode ?? ResizeMode.CONTAIN}
        />
      )}

      {/* LOADING INDICATOR / PLAY BUTTON */}
      <View
        style={[
          StyleSheet.absoluteFill,
          {
            display:
              (shouldPlay && !isPlaying && (isBuffering || loading)) ||
              (!props.hideControls && !isPlaying)
                ? 'flex'
                : 'none',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor:
              shouldPlay && (isBuffering || loading)
                ? 'transparent'
                : 'rgba(48, 48, 48, 0.4)',
            opacity: 0.9,
            zIndex: 2,
          },
        ]}
      >
        {(isBuffering || loading) && shouldPlay ? (
          <ActivityIndicator color="white" size="large" />
        ) : props.hideControls ? null : (
          <Ionicons name="play" size={64} color="white" />
        )}
      </View>

      {/* MUTE BUTTON */}
      <Animated.View
        style={[
          styles.muteButtonContainer,
          {
            display:
              shouldPlay && (!isBuffering || isPlaying) && !props.hideControls
                ? 'flex'
                : 'none',
            opacity: muteButtonOpacity.current,
          },
        ]}
      >
        <Hoverable
          onHoverIn={() => {
            animateShowMuteButton(0);
          }}
          onHoverOut={() => {
            _animateFadeMuteButton();
          }}
        >
          <Pressable onPress={onToggleMute} style={styles.muteButton}>
            {state.mute ? (
              <Ionicons name="volume-mute" color="white" size={20} />
            ) : (
              <Ionicons name="volume-high" color="white" size={20} />
            )}
          </Pressable>
        </Hoverable>
      </Animated.View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    backgroundColor: KEY_GRAY,
  },
  muteButtonContainer: {
    position: 'absolute',
    bottom: 12,
    right: 12,
    width: 32,
    height: 32,
    zIndex: 1,
  },
  muteButton: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: KEY_GRAY,
    borderRadius: 24,
  },
});
