import {
  createStackNavigator,
  StackNavigationOptions,
} from '@react-navigation/stack';
import { getStackNavigatorOptions } from './common';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import React from 'react';
import LoadingSpinnerOverlay from '/components/LoadingSpinnerOverlay';
import Moderation from '/screens/admin/Moderation';

const Stack = createStackNavigator();

const createScreen = (Component: React.LazyExoticComponent<any>) => {
  return (props: any) => (
    <React.Suspense fallback={<LoadingSpinnerOverlay />}>
      <Component {...props} />
    </React.Suspense>
  );
};

const UserReportsScreen = createScreen(
  React.lazy(() => import('/screens/admin/Moderation/UserReports')),
);
const HiddenCampaignsScreen = createScreen(
  React.lazy(() => import('/screens/admin/Moderation/HiddenCampaigns')),
);

export default function ConfigurationStack() {
  const safeAreaInsets = useSafeAreaInsets();

  return (
    <Stack.Navigator
      initialRouteName="Moderation"
      screenOptions={getStackNavigatorOptions(safeAreaInsets)}
    >
      <Stack.Screen
        component={Moderation}
        name="Moderation"
        options={ModerationScreenOptions}
      />
      <Stack.Screen
        component={UserReportsScreen}
        name="UserReports"
        options={UserReportsScreenOptions}
      />

      <Stack.Screen
        component={HiddenCampaignsScreen}
        name="HiddenCampaigns"
        options={HiddenCampaignsScreenOptions}
      />
    </Stack.Navigator>
  );
}

const ModerationScreenOptions = (): StackNavigationOptions => ({
  title: 'Moderation',
  headerTitle: 'MODERATION',
});

const UserReportsScreenOptions = (): StackNavigationOptions => ({
  title: 'User Reports',
  headerTitle: 'USER REPORTS',
});

const HiddenCampaignsScreenOptions = (): StackNavigationOptions => ({
  title: 'Hidden Campaigns',
  headerTitle: 'HIDDEN CAMPAIGNS',
});
