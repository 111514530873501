import React, { useMemo, useState } from 'react';
import {
  View,
  Pressable,
  Platform,
  Text,
  TextStyle,
  StyleProp,
  ViewStyle,
} from 'react-native';
import { Link, useTheme } from '@react-navigation/native';
import Color from 'color';

import TabBarIcon from './TabBarIcon';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  maxSize,
} from 'rn-responsive-styles';
import Hoverable from '/components/Hoverable';
import { TITLE_FONT_SIZE } from '/constants';
import { BottomTabBarButtonProps } from '@react-navigation/bottom-tabs';
import useBinaryTimingAnimation from '../../../hooks/useBinaryTimingAnimation';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';
import { NavigationRoute } from './ResponsiveTabBar';

type ResponsiveTabItemProps = {
  focused: boolean;
  route: NavigationRoute;
  label:
    | string
    | (({
        focused,
        color,
      }: {
        focused: boolean;
        color: string;
      }) => JSX.Element);
  icon:
    | JSX.Element
    | (({
        focused,
        color,
      }: {
        focused: boolean;
        color: string;
      }) => JSX.Element);
  button: (props: BottomTabBarButtonProps) => JSX.Element;
  ariaLabel: string;
  testID: string;
  onPress: () => void;
  onLongPress: () => void;
  horizontal: boolean;
  activeTintColor?: string;
  inactiveTintColor?: string;
  activeBackgroundColor?: string;
  inactiveBackgroundColor?: string;
  iconSize?: number;
  labelSize?: number;
  showLabel?: boolean;
  showIcon?: boolean;
  allowFontScaling?: boolean;
  labelStyle?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
};

function ResponsiveTabItem({
  focused,
  label,
  icon,
  button = ({
    children,
    style: buttonStyle,
    onPress: buttonOnPress,
    to,
    accessibilityRole,
    ...rest
  }: BottomTabBarButtonProps) => {
    if (Platform.OS === 'web' && to) {
      // React Native Web doesn't forward `onClick` if we use `TouchableWithoutFeedback`.
      // We need to use `onClick` to be able to prevent default browser handling of links.
      return (
        <Link
          {...rest}
          to={to}
          style={buttonStyle}
          onPress={(e: any) => {
            if (
              !(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) && // ignore clicks with modifier keys
              (e.button == null || e.button === 0) // ignore everything but left clicks
            ) {
              e.preventDefault();
              buttonOnPress?.(e);
            }
          }}
        >
          {children}
        </Link>
      );
    } else {
      return (
        <Pressable
          {...rest}
          accessibilityRole={accessibilityRole}
          onPress={buttonOnPress}
          style={buttonStyle}
        >
          {children}
        </Pressable>
      );
    }
  },
  ariaLabel,
  testID,
  onPress,
  onLongPress,
  horizontal,
  activeTintColor: customActiveTintColor,
  inactiveTintColor: customInactiveTintColor,
  activeBackgroundColor = '#ccc',
  inactiveBackgroundColor = 'transparent',
  iconSize = 32,
  labelSize,
  showLabel = true,
  showIcon = true,
  allowFontScaling,
  labelStyle,
  style,
}: ResponsiveTabItemProps) {
  const [isHovering, setHovering] = useState(false);

  const { colors } = useTheme();

  const { styles, deviceSize } = useStyles();

  const labelWidth = '100%';

  const activeTintColor = useMemo(
    () =>
      customActiveTintColor === undefined
        ? colors.primary
        : customActiveTintColor,
    [colors.primary, customActiveTintColor],
  );

  const inactiveTintColor = useMemo(
    () =>
      customInactiveTintColor === undefined
        ? Color(colors.text).mix(Color(colors.card), 0.5).hex()
        : customInactiveTintColor,
    [colors.card, colors.text, customInactiveTintColor],
  );

  const hoverOpacity = useBinaryTimingAnimation({
    value: isHovering,
    disabledValue: 1,
    enabledValue: 0.7,
  });
  const animatedContainerStyle = useAnimatedStyle(() => ({
    opacity: hoverOpacity.value,
  }));

  // Only apply backgrund color when the tab bar is docked to the bottom
  const backgroundColor =
    focused && deviceSize === DEVICE_SIZES.EXTRA_SMALL_DEVICE
      ? activeBackgroundColor
      : inactiveBackgroundColor;

  const itemProps: RenderItemPropsBase = {
    activeTintColor,
    inactiveTintColor,
    focused,
    horizontal,
    allowFontScaling,
    showIcon,
  };

  return (
    <Hoverable
      onHoverIn={() => {
        setHovering(true);
      }}
      onHoverOut={() => {
        setHovering(false);
      }}
    >
      <Animated.View
        style={[styles('tabButtonContainer'), animatedContainerStyle]}
      >
        {button({
          onPress,
          onLongPress,
          testID,
          'aria-label': ariaLabel,
          accessibilityRole: 'button',
          accessibilityState: {
            selected: focused,
          },
          style: [
            styles('tab'),
            { backgroundColor },
            horizontal ? styles('tabHorizontal') : styles('tabVertical'),
            style,
          ],
          children: (
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                height: 50,
                borderRadius: 10,
              }}
            >
              <ItemIcon {...itemProps} icon={icon} iconSize={iconSize} />
              <ItemLabel
                {...itemProps}
                label={label}
                labelSize={labelSize}
                labelWidth={labelWidth}
                labelStyle={labelStyle}
                showLabel={showLabel}
              />
            </View>
          ),
        })}
      </Animated.View>
    </Hoverable>
  );
}

type RenderItemPropsBase = {
  focused: boolean;
  activeTintColor: string;
  inactiveTintColor: string;
  horizontal: boolean;
  showIcon?: boolean;
  allowFontScaling?: boolean;
};

type ItemLabelProps = RenderItemPropsBase & {
  labelStyle?: StyleProp<TextStyle>;
  showLabel?: boolean;
  labelSize?: number;
  labelWidth?: number | string;
  label:
    | string
    | (({
        focused,
        color,
      }: {
        focused: boolean;
        color: string;
      }) => JSX.Element);
};

type ItemIconProps = RenderItemPropsBase & {
  icon:
    | JSX.Element
    | ((props: { focused: boolean; color: string }) => JSX.Element);
  iconSize?: number;
  iconStyle?: StyleProp<ViewStyle>;
};

function ItemLabel({
  focused,
  showLabel,
  activeTintColor,
  inactiveTintColor,
  label,
  horizontal,
  showIcon,
  labelSize,
  labelWidth,
  labelStyle,
  allowFontScaling,
}: ItemLabelProps) {
  const { styles } = useStyles();

  if (showLabel === false) {
    return null;
  }

  const color = focused ? activeTintColor : inactiveTintColor;

  if (typeof label === 'string') {
    return (
      <Text
        numberOfLines={1}
        style={[
          styles('label'),
          horizontal ? styles('labelHorizontal') : styles('labelVertical'),
          showIcon && horizontal
            ? styles('labelBeside')
            : styles('labelBeneath'),
          labelStyle,
          {
            color,
            fontSize: labelSize,
            width: labelWidth,
            // textAlign: 'center',
          },
        ]}
        allowFontScaling={allowFontScaling}
      >
        {label}
      </Text>
    );
  }

  if (typeof label === 'string') {
    return label;
  }

  return label({ focused, color });
}

function ItemIcon({
  focused,
  showIcon,
  icon,
  iconSize,
  iconStyle,
  activeTintColor,
  inactiveTintColor,
}: ItemIconProps) {
  if (showIcon === false || icon === undefined) {
    return null;
  }

  return (
    <View
      style={{
        width: iconSize,
        alignItems: 'center',
      }}
    >
      <TabBarIcon
        style={iconStyle}
        size={iconSize}
        focused={focused}
        activeTintColor={activeTintColor}
        inactiveTintColor={inactiveTintColor}
        renderIcon={icon}
      />
    </View>
  );
}

const useStyles = CreateResponsiveStyle(
  {
    tab: {
      flex: 1,
      backgroundColor: 'blue',
      alignItems: 'flex-start',
      width: '100%',
      borderRadius: 6,
      paddingHorizontal: 4,
    },
    tabButtonContainer: {
      flex: 1,
      height: '100%',
    },
    tabVertical: {
      justifyContent: 'flex-start',
      flexDirection: 'column',
    },
    tabHorizontal: {
      justifyContent: 'flex-start',
      flexDirection: 'row',
    },
    hoverStyle: {
      opacity: 0.7,
    },
    label: {
      paddingLeft: 12,
      backgroundColor: 'transparent',
      fontFamily: 'LeagueSpartan-Bold',
    },
    labelHorizontal: {
      textAlign: 'left',
    },
    labelVertical: {
      textAlign: 'center',
    },
    labelBeneath: {
      fontSize: 11,
      marginBottom: 1.5,
    },
    labelBeside: {
      fontSize: TITLE_FONT_SIZE,
      marginLeft: 5,
    },
  },
  {
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      tab: {
        flex: 1,
        alignItems: 'center',
        width: 'auto',
        height: '100%',
        borderRadius: 0,
      },
      tabHorizontal: {
        justifyContent: 'center',
      },
    },
  },
);

export default ResponsiveTabItem;
