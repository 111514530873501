import React from 'react';
import { Avatar as BaseAvatar, AvatarProps } from '@rneui/themed';
import getCDNImageUri from '/util/getCDNImageUri';

interface IAvatarProps extends Omit<AvatarProps, 'source'> {
  source: {
    uri: string | undefined | null;
  };
}

const avatarSizes = {
  small: 34,
  medium: 50,
  large: 75,
  xlarge: 150,
};

const PLACEHOLDER = require('/assets/images/blank-profile-picture.webp');

// This is an extension of the exisiting Avatar componenent that better
// fits our proprietary needs
const Avatar = (props: IAvatarProps) => {
  // Get number for given size
  const size =
    typeof props.size === 'number' && !isNaN(props.size)
      ? props.size
      : props.size && typeof props.size !== 'number'
      ? avatarSizes[props.size]
      : avatarSizes.small;

  return (
    <BaseAvatar
      {...props}
      source={
        props.source?.uri
          ? {
              uri: getCDNImageUri({
                uri: props.source.uri,
                dimensions: { width: size, height: size },
              }),
            }
          : PLACEHOLDER
      }
      imageProps={{ resizeMode: 'cover', ...props.imageProps }}
    />
  );
};

export default Avatar;
