import { View, Text, Pressable, ViewStyle } from 'react-native';
import React from 'react';
import CheckmarkSwitch from './CheckmarkSwitch';
import { StyleSheet } from 'react-native';
import { KEY_LIGHT_GRAY, SECTION_CONTAINER } from '/constants';

interface IRadioOptionProps {
  isSelected: boolean;
  onPress?: () => void;
  title: string | JSX.Element;
  disabled?: boolean;
  subtitle?: string;
  style?: ViewStyle;
}

export default function RadioOption(props: IRadioOptionProps) {
  return (
    <Pressable
      onPress={props.onPress}
      disabled={props.disabled}
      style={({ pressed }) => [
        styles.radioOption,
        props.style,
        {
          opacity: pressed || props.disabled ? 0.5 : 1,
        },
      ]}
    >
      <View style={styles.radioOptionTextContainer}>
        <Text style={styles.radioOptionTitle}>{props.title}</Text>
        {props.subtitle ? (
          <Text style={styles.radioOptionDescription}>{props.subtitle}</Text>
        ) : null}
      </View>

      <CheckmarkSwitch
        value={props.isSelected}
        onValueChange={() => {
          props.onPress?.();
        }}
      />
    </Pressable>
  );
}

const styles = StyleSheet.create({
  radioOption: {
    ...SECTION_CONTAINER,
    marginHorizontal: 0,
    marginTop: 8,
    backgroundColor: KEY_LIGHT_GRAY,
    flexDirection: 'row',
    alignItems: 'center',
  },
  radioOptionTitle: {
    fontFamily: 'Lato-Bold',
    fontSize: 17,
  },
  radioOptionDescription: {
    fontFamily: 'Lato',
    fontSize: 15,
  },
  radioOptionTextContainer: {
    flex: 1,
    marginRight: 8,
  },
});
