import { FontAwesome } from '@expo/vector-icons';
import React, { useEffect, useRef } from 'react';
import { TouchableOpacity, View } from 'react-native';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles';
import {
  useAddBookmarkMutation,
  useRemoveBookmarkMutation,
} from '/generated/graphql';

type BookmarkButtonProps = {
  campaignId: string | undefined | null;
  isBookmarked: boolean;
  /** Default is 44 */
  size?: number;
};

const BookmarkButton = (props: BookmarkButtonProps) => {
  const { styles } = useStyles();

  const mounted = useRef(false);

  const [, addBookmark] = useAddBookmarkMutation();
  const [, removeBookmark] = useRemoveBookmarkMutation();

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  });

  const onPress = async () => {
    if (!props.campaignId) {
      return null;
    }

    if (props.isBookmarked) {
      try {
        const { error } = await removeBookmark({
          campaignId: props.campaignId,
        });

        if (error) throw error;
      } catch (err) {
        console.log('failed to remove bookmark', err);
      }
    } else {
      try {
        const { error } = await addBookmark({ campaignId: props.campaignId });

        if (error) throw error;
      } catch (err) {
        console.log('failed to add bookmark', err);
      }
    }
  };

  return (
    <View>
      <TouchableOpacity
        style={[
          styles('bookmarkButton'),
          {
            width: props.size ?? styles('bookmarkButton').width,
            height: props.size ?? styles('bookmarkButton').height,
          },
        ]}
        onPress={onPress}
      >
        {props.isBookmarked ? (
          <FontAwesome name="bookmark" size={26} color="black" /> // solid
        ) : (
          <FontAwesome name="bookmark-o" size={26} color="black" />
        )}
      </TouchableOpacity>
    </View>
  );
};

const useStyles = CreateResponsiveStyle(
  {
    bookmarkButton: {
      borderRadius: 12,
      backgroundColor: '#F5F5F5',
      height: 44,
      width: 44,
      justifyContent: 'center',
      margin: 2,
      alignItems: 'center',
    },
  },
  {
    [minSize(DEVICE_SIZES.LARGE_DEVICE)]: {
      bookmarkButton: {
        height: 40,
        width: 40,
      },
    },
  },
);

export default BookmarkButton;
