import { StyleSheet } from 'react-native';
import {
  CARD_TITLE_FONT_SIZE,
  CONTENT_PARAGRAPH_FONT_SIZE,
  KEY_GRAY,
} from '/constants';

export default StyleSheet.create({
  video: {
    zIndex: 1,
    width: '100%',
    backgroundColor: 'black',
  },
  linearGradient: {
    zIndex: 3,
    position: 'absolute',
    left: 0,
    right: 0,
  },
  videoHeader: {
    top: 0,
    height: 80,
  },
  videoFooter: {
    bottom: 0,
    justifyContent: 'flex-end',
    paddingHorizontal: 10,
  },
  authorContainer: {
    flex: 1,
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  authorNameText: {
    color: 'black',
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 17,
    paddingTop: 4,
    textTransform: 'uppercase',
  },
  locationText: {
    flex: 1,
    fontFamily: 'Lato',
    fontSize: 16,
    color: KEY_GRAY,
  },
  sectionText: {
    flex: 1,
    fontSize: CONTENT_PARAGRAPH_FONT_SIZE,
    fontFamily: 'Lato',
  },
  speciesContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
  },
  speciesThumbnailPlaceholder: {
    width: 48,
    height: 48,
    borderRadius: 48,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: KEY_GRAY,
  },
  sectionTitle: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: CARD_TITLE_FONT_SIZE,
  },
  speciesListItem: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 6,
  },
  speciesVernacularName: {
    fontFamily: 'Lato-Bold',
    fontSize: 17,
  },
  speciesCanonicalName: {
    fontFamily: 'Lato',
  },
  timestampText: {
    fontFamily: 'Lato',
    color: 'gray',
    marginTop: 2,
  },
  muteButton: {
    opacity: 0.7,
    padding: 8,
    borderRadius: 6,
    backgroundColor: 'rgba(20, 20, 20, 0.2)',
  },
  actionSheetButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: 20,
    zIndex: 3,
  },
  goodNewsDescriptionText: {
    flex: 1,
    fontFamily: 'Lato',
    fontSize: CONTENT_PARAGRAPH_FONT_SIZE,
    color: KEY_GRAY,
  },
  playButtonOverlay: {
    zIndex: 2,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    opacity: 0.5,
  },
  likesContainer: {
    alignSelf: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
  },
  likeCounter: {
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    color: KEY_GRAY,
    marginRight: 8,
  },
});
