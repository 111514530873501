import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import dayjs from 'dayjs';
import React from 'react';
import {
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import MapMarker from '/assets/jsicons/headerIcons/map-marker';
import Avatar from '/components/Avatar';
import ShareButton from '/components/Sharing/ShareButton';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import { KEY_GRAY } from '/constants';
import { useAuthContext } from '/context';
import {
  JobPost,
  JobPostJobType,
  JobPostWorkplaceType,
  User,
  useCreateJobPostBookmarkMutation,
  useDeleteJobPostBookmarkMutation,
} from '/generated/graphql';
import { createUniversalURL } from '/util';

interface IJobPost
  extends Pick<
    JobPost,
    | 'id'
    | 'title'
    | 'is_bookmarked'
    | 'location'
    | 'job_type'
    | 'workplace_type'
    | 'created_at'
    | 'deadline'
  > {
  user: Pick<User, 'id' | 'name' | 'profile_image'>;
}

interface Props {
  data: IJobPost;
  style?: StyleProp<ViewStyle>;
  hideTimestamp?: boolean;
  onPress: () => void;
}

export default function JobPostCard({ data, ...props }: Props) {
  const { userData } = useAuthContext();

  const [, createBookmark] = useCreateJobPostBookmarkMutation();
  const [, deleteBookmark] = useDeleteJobPostBookmarkMutation();

  const jobTypeText = (() => {
    switch (data.job_type) {
      case JobPostJobType.FullTime:
        return 'Full Time';
      case JobPostJobType.PartTime:
        return 'Part Time';
      case JobPostJobType.Contract:
        return 'Contract';
      case JobPostJobType.Internship:
        return 'Internship';
      case JobPostJobType.Other:
      default:
        return 'Other';
    }
  })();

  const workplaceTypeText = (() => {
    switch (data.workplace_type) {
      case JobPostWorkplaceType.Remote:
        return 'Remote';
      case JobPostWorkplaceType.Hybrid:
        return 'Hybrid';
      case JobPostWorkplaceType.OnSite:
      default:
        return 'On Site';
    }
  })();

  return (
    <Pressable style={[styles.container, props.style]} onPress={props.onPress}>
      <View style={{ flex: 1 }}>
        <View>
          <HorizontalContainer
            style={{
              alignItems: 'flex-start',
            }}
          >
            <Avatar
              rounded
              size={48}
              source={{ uri: data.user.profile_image ?? '' }}
              containerStyle={{
                marginRight: 8,
              }}
            />
            <View style={{ flex: 1 }}>
              <Text numberOfLines={2} style={styles.jobTitle}>
                {data.title}
              </Text>
              <Text numberOfLines={2} style={styles.orgName}>
                {data.user.name}
              </Text>
              {data.workplace_type === JobPostWorkplaceType.Remote ? (
                <HorizontalContainer>
                  <FontAwesome5 name="globe" size={15} color="black" />
                  <Text style={styles.locationText}>REMOTE</Text>
                </HorizontalContainer>
              ) : (
                data.location?.name && (
                  <HorizontalContainer>
                    <MapMarker width={15} height={15} fill={KEY_GRAY} />
                    <Text style={styles.locationText}>
                      {data.location.name ?? ''}
                    </Text>
                  </HorizontalContainer>
                )
              )}
            </View>
          </HorizontalContainer>
        </View>
        <Text style={styles.summaryText}>
          {jobTypeText}
          <Text
            style={{
              color: 'gray',
            }}
          >
            {` `}●{` `}
          </Text>
          {workplaceTypeText}
          <Text
            style={{
              color: 'gray',
            }}
          >
            {` `}●{` `}
          </Text>
          Apply by{` `}
          {dayjs(data.deadline).format('DD MMM YYYY')}
        </Text>
      </View>
      <HorizontalContainer
        style={{
          justifyContent: 'space-between',
          paddingHorizontal: 4,
          alignItems: 'flex-end',
        }}
      >
        {!props.hideTimestamp ? (
          <Text style={styles.timestamp}>
            Posted: {dayjs(Number(data.created_at)).fromNow()}
          </Text>
        ) : null}
        <HorizontalContainer>
          <ShareButton
            iconSize={24}
            url={createUniversalURL(`jobs/${data.id}`)}
            shareMessage={`Check out this job on Key Conservation!`}
            style={{
              backgroundColor: 'transparent',
              width: 36,
              height: 36,
            }}
          />
          {/* Only show bookmark button if signed in */}
          {userData?.id ? (
            <View
              style={{
                width: 36,
                height: 36,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {data.is_bookmarked ? (
                <FontAwesome
                  name="bookmark"
                  size={24}
                  color="black"
                  onPress={() => {
                    deleteBookmark({ id: data.id });
                  }}
                /> // solid
              ) : (
                <FontAwesome
                  name="bookmark-o"
                  size={24}
                  color="black"
                  onPress={() => createBookmark({ id: data.id })}
                />
              )}
            </View>
          ) : null}
        </HorizontalContainer>
      </HorizontalContainer>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 8,
    paddingTop: 10,
    justifyContent: 'space-between',
  },
  jobTitle: {
    flex: 1,
    textTransform: 'uppercase',
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 18,
    marginBottom: 4,
  },
  orgName: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 14,
    textTransform: 'uppercase',
    marginBottom: 2,
  },
  locationText: {
    flex: 1,
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    color: KEY_GRAY,
    marginLeft: 4,
  },
  summaryText: {
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    marginVertical: 6,
  },
  timestamp: {
    fontFamily: 'Lato-Italic',
    color: 'gray',
  },
});
