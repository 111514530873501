import { StyleSheet } from 'react-native';
import { KEY_LIGHT_GRAY, KEY_YELLOW } from '/constants';

export default StyleSheet.create({
  section: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    backgroundColor: 'white',
    marginTop: 8,
  },
  pointOfContactModeButton: {
    flex: 1,
    borderRadius: 6,
    backgroundColor: KEY_LIGHT_GRAY,
    padding: 12,
    paddingVertical: 16,
    alignItems: 'center',
  },
  pointOfContactModeButtonText: {
    fontFamily: 'Lato-Bold',
    textAlign: 'center',
    fontSize: 15,
    marginTop: 6,
  },
  selectedCheckmark: {
    position: 'absolute',
    top: 16,
    right: 16,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: KEY_YELLOW,
    padding: 8,
    borderRadius: 24,
    zIndex: 1,
  },
  teamMemberMenuErrorText: {
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    color: 'crimson',
    textAlign: 'center',
    padding: 12,
    alignSelf: 'center',
  },
  activityIndicator: {
    padding: 12,
    alignSelf: 'center',
  },
  emptyText: {
    fontFamily: 'Lato-Bold',
    color: 'gray',
    fontSize: 15,
    alignSelf: 'center',
    textAlign: 'center',
    padding: 12,
  },
  pointOfContactPhotoUploadContainer: {
    backgroundColor: KEY_LIGHT_GRAY,
    borderRadius: 6,
    marginTop: 8,
    padding: 6,
  },
  pointOfContactName: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 17,
    marginTop: 8,
    marginVertical: 2,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  pointOfContactPosition: {
    fontFamily: 'Lato',
    fontSize: 16,
    textAlign: 'center',
  },
  teamMemberMenuFooter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 8,
  },
  invalidFieldText: {
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    marginTop: 8,
    marginBottom: 6,
    color: 'crimson',
  },
  switchFieldContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 4,
  },
  switchFieldIconContainer: {
    width: 24,
  },
  switchFieldLabel: {
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    marginLeft: 6,
  },
  difficultyContainer: {
    flexDirection: 'row',
    width: '100%',
  },
});
