import { FontAwesome5 } from '@expo/vector-icons';
import React from 'react';
import { Text, View } from 'react-native';
import DateTimePicker from '../../../DateTimePicker/DateTimePicker';
import { ValidatedAny } from '/components/ValidatedForm';
import { ValidatedFormFields } from '/components/ValidatedForm/ValidatedForm';
import { KEY_GRAY, REQUIRED_INPUT_INVALID } from '/constants';
import styles from '/constants/CampaignBuilder/SkillImpact/SkillExpand';
import { SkillRequest } from '/generated/graphql';

interface INextStepsProps {
  data: Partial<SkillRequest>;
  fields: ValidatedFormFields;
  onChange: (data: Partial<SkillRequest>) => void;
}

const NextSteps = (props: INextStepsProps) => {
  return (
    <View style={styles.itemContainers}>
      {/* <View style={styles.itemContentRows}>
        <Text style={styles.itemContentTitle}>Halfway Review Deadline</Text>
      </View>

      <Text style={[styles.text, { fontSize: 16 }]}>
        This is the date halfway through the project when you should check in
        with your selected applicant to see how everything is progressing,
        answer any questions, and make sure all is on schedule for the final
        deadline.
      </Text>

      <View
        style={{
          borderLeftWidth: 3,
          paddingLeft: 8,
        }}
      >
        <Text style={[TEXT_INPUT, { overflow: 'hidden', color: KEY_GRAY }]}>
          {dayjs(
            Date.now() +
              (new Date(
                props.data?.campaign.support_needed_by_date ?? 0
              ).getTime() -
                Date.now()) /
                2
          ).format('LL')}
        </Text>
      </View> */}

      <View style={[styles.itemContentRows, { paddingTop: 16 }]}>
        <FontAwesome5 name="flag-checkered" size={30} color={KEY_GRAY} />
        <Text
          style={[
            styles.itemContentTitle,
            { alignSelf: 'flex-start', marginLeft: 7, paddingTop: 2 },
          ]}
        >
          {/* Final */}
          Deadline
        </Text>
      </View>

      <Text style={[styles.text, { fontSize: 16 }]}>
        This is when you need the final project to be completed.
      </Text>

      <ValidatedAny
        name="dueDate"
        value={props.data?.due_date}
        validate={(value) => {
          // A valid dueDate is at least six hours from now
          return new Date(value).getTime() > Date.now() + 1000 * 3600 * 6;
        }}
      >
        <View
          style={[
            // styles.sectionContainer,
            { zIndex: 1 },
            props.fields.dueDate?.valid === false ? REQUIRED_INPUT_INVALID : {},
          ]}
        >
          {/* Validation error message */}
          <Text
            style={{
              display: props.fields.dueDate?.valid == false ? 'flex' : 'none',
              fontFamily: 'Lato-Bold',
              color: 'crimson',
              paddingBottom: 8,
            }}
          >
            Date must be set at least six hours in the future.
          </Text>

          <View
            style={{
              flexDirection: 'row',
              flex: 1,
              justifyContent: 'flex-end',
            }}
          >
            <DateTimePicker
              value={new Date(props.data.due_date)}
              onChange={(date) => {
                props.onChange({
                  due_date: date,
                });
              }}
            />
          </View>
        </View>
      </ValidatedAny>

      {/* <View
        style={{
          borderLeftWidth: 3,
          paddingLeft: 8,
        }}
      >
        <Text style={[TEXT_INPUT, { overflow: 'hidden', color: KEY_GRAY }]}>
          {dayjs(
            new Date(props.data?.campaign.support_needed_by_date ?? 0).getTime()
          ).format('LL')}
        </Text>
      </View> */}
    </View>
  );
};

export default NextSteps;
