import React from 'react';
import { Text, TextInput, View } from 'react-native';
import { useCommonStyles } from '../styles';
import Carousel from '../../Carousel/Carousel';
import { SECTION_CONTAINER, TEXT_INPUT_LARGE } from '/constants';
import { CarouselCard, TranslatableText, User } from '/generated/graphql';
import { shorten } from '/util';
import useTranslatableText from '/hooks/useTranslatableText';
import TranslateButton from '/components/common/TranslatableText/TranslateButton';

type AboutUsData = Pick<
  User,
  'name' | 'bio_translatable' | 'about_us_carousel'
>;

interface IAboutUsProps {
  data: AboutUsData | undefined | null;
  setData: (data: Partial<AboutUsData>) => void;
  /** Callback notifying parent of whether AboutUs component is busy and should not be updated */
  setBusy: (busy: boolean) => void;
  isEditing: boolean;
}

export default function AboutUs(props: IAboutUsProps) {
  const [showTranslation, setShowTranslation] = React.useState<boolean>(false);

  const {
    isTranslated,
    isTranslating,
    text: bioText,
  } = useTranslatableText({
    data: props.data?.bio_translatable,
    showTranslation,
    onTranslateError() {
      setShowTranslation(false);
    },
  });

  const { styles: commonStyles } = useCommonStyles();

  const onCarouselUploadStart = () => {
    props.setBusy(true);
  };

  const onCarouselUploadEnd = () => {
    props.setBusy(false);
  };

  const onCarouselChange = (data: CarouselCard[]) => {
    props.setData({
      about_us_carousel: data,
    });
  };

  return (
    <View style={SECTION_CONTAINER}>
      {/* MAIN CONTENT */}
      <View style={commonStyles('sectionTitleContainer')}>
        <Text style={commonStyles('sectionTitle')}>ABOUT US</Text>
      </View>

      {props.isEditing ? (
        <TextInput
          style={[
            TEXT_INPUT_LARGE,
            {
              marginTop: 4,
            },
          ]}
          maxLength={1000}
          multiline
          placeholder={`Write about ${shorten(props.data?.name ?? '', 32)}...`}
          value={props.data?.bio_translatable?.text ?? ''}
          onChangeText={(text) =>
            props.setData({
              bio_translatable: {
                ...props.data?.bio_translatable,
                text,
              } as TranslatableText,
            })
          }
        />
      ) : (
        <>
          <Text
            style={[
              commonStyles('sectionText'),
              !bioText && commonStyles('emptyPlaceholderSectionText'),
            ]}
          >
            {bioText || 'No information provided'}
          </Text>

          <TranslateButton
            isTranslated={isTranslated}
            isTranslating={isTranslating}
            onPress={() => setShowTranslation(!showTranslation)}
            fromLanguage={props.data?.bio_translatable?.language}
          />
        </>
      )}

      {/* CAROUSEL */}
      <Carousel
        data={props.data?.about_us_carousel as CarouselCard[]}
        carouselItemContainerStyle={{
          marginRight: 8,
        }}
        captionRequired={false}
        isEditing={props.isEditing}
        onChange={onCarouselChange}
        onUploadStart={onCarouselUploadStart}
        onUploadEnd={onCarouselUploadEnd}
      />
    </View>
  );
}
