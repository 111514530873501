import { HeaderBackButton } from '@react-navigation/elements';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import {
  ScrollView,
  StyleSheet,
  Switch,
  Text,
  TextInput,
  View,
} from 'react-native';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';
import commonStyles from './styles';
import Button from '/components/Button';
import LoadingOverlay from '/components/LoadingOverlay';
import UploadMedia from '/components/UploadMedia/UploadMedia';
import {
  ValidatedAny,
  useFormValidationContext,
  withFormValidation,
} from '/components/ValidatedForm';
import AddNewMedia from '/compositions/AddNewMedia';
import { KEY_GRAY, KEY_GREEN, TEXT_INPUT_LARGE } from '/constants';
import { useAuthContext, useTeamContext } from '/context';
import {
  SpeciesUserContentMedia,
  User,
  useAddSpeciesMediaMutation,
  useUpdateSpeciesMediaMutation,
} from '/generated/graphql';
import useBinaryTimingAnimation from '/hooks/useBinaryTimingAnimation';

interface ISpeciesUserContentMedia
  extends Pick<
    SpeciesUserContentMedia,
    'caption' | 'uri' | 'thumbnail' | 'public'
  > {
  id?: string;
  created_by?: Pick<User, 'id' | 'name' | 'profile_image'>;
}

interface IEditUserContentMediaModalProps {
  visible: boolean;
  userId: string | undefined;
  speciesTaxonId: number | undefined;
  data: ISpeciesUserContentMedia | undefined;
  onRequestClose: (
    data?: ISpeciesUserContentMedia & {
      id: string;
    },
  ) => void;
}

const DEFAULT_MEDIA_MODAL_STATE: ISpeciesUserContentMedia = {
  uri: '',
  thumbnail: '',
  caption: '',
  public: true,
};

function EditUserContentMediaModal(props: IEditUserContentMediaModalProps) {
  const { activeTeam } = useTeamContext();
  const { userData } = useAuthContext();

  const activeUser = activeTeam?.user.id ? activeTeam.user : userData;

  const [state, setState] = useState<ISpeciesUserContentMedia>(
    props.data ?? DEFAULT_MEDIA_MODAL_STATE,
  );

  const animatedOpacity = useBinaryTimingAnimation({ value: props.visible });
  const animatedContainerStyle = useAnimatedStyle(() => ({
    opacity: animatedOpacity.value,
  }));

  const scrollViewRef = useRef<ScrollView>();

  /** Used to confirm existing without saving changes */
  const [promptConfirmExit, setPromptConfirmExit] = useState(false);

  /** Used to confirm publishing publicly and irreversibly */
  const [promptMakePublic, setPromptMakePublic] = useState(false);

  const { validateForm, fields } = useFormValidationContext(scrollViewRef);

  const [{ fetching: addingMedia }, addSpeciesMedia] =
    useAddSpeciesMediaMutation();
  const [{ fetching: updatingMedia }, updateSpeciesMedia] =
    useUpdateSpeciesMediaMutation();

  useEffect(() => {
    setState(props.data ?? DEFAULT_MEDIA_MODAL_STATE);
  }, [props.data]);

  useEffect(() => {
    if (!props.visible) {
      setPromptConfirmExit(false);
      setPromptMakePublic(false);
      setState(DEFAULT_MEDIA_MODAL_STATE);
    }
  }, [props.visible]);

  function onCancel() {
    const prevState = props.data ?? DEFAULT_MEDIA_MODAL_STATE;

    if (_.isEqual(prevState, state)) {
      props.onRequestClose?.();
    } else {
      setPromptConfirmExit(true);
    }
  }

  async function onSave() {
    if (!validateForm()) return;

    if (!props.speciesTaxonId) {
      console.error(
        'EditUserContentMediaModal onSave failed because species ID could not be found',
      );
      return;
    }

    setPromptMakePublic(false);
    setPromptConfirmExit(false);

    try {
      let error: any;

      let mediaId: string | undefined;

      if (state.id) {
        // If this is an existing "media", call "update"
        const updateMedia = await updateSpeciesMedia({
          input: {
            taxonID: props.speciesTaxonId,
            id: state.id,
            uri: state.uri!,
            thumbnail: state.thumbnail!,
            caption: state.caption!,
            public: state.public!,
          },
        });

        error = updateMedia.error;
        mediaId = updateMedia.data?.updateSpeciesMedia.id;
      } else {
        // Otherwise, call "add"
        const addMedia = await addSpeciesMedia({
          input: {
            taxonID: props.speciesTaxonId,
            uri: state.uri!,
            thumbnail: state.thumbnail!,
            caption: state.caption!,
            public: state.public!,
          },
          userId: props.userId,
        });

        error = addMedia.error;
        mediaId = addMedia.data?.addSpeciesMedia.id;
      }

      if (error) throw error;

      if (!mediaId) throw new Error('Failed to save species media');

      // If all is successful, exit modal and make sure to include "id" to make sure the correct
      // entry is associated with the user profile, and include activeUser to show user's name and
      // avatar
      props.onRequestClose({
        ...state,
        id: mediaId,
        created_by: activeUser as User,
      });
    } catch (error) {
      console.log('Failed to save species media', error);
    }
  }

  return (
    <Animated.View
      pointerEvents={props.visible ? 'auto' : 'none'}
      style={[
        styles.container,
        {
          zIndex: props.visible ? 1 : -1,
        },
        animatedContainerStyle,
      ]}
    >
      {/* HEADER */}
      <View style={commonStyles.headerContainer}>
        <HeaderBackButton tintColor={KEY_GRAY} onPress={onCancel} />
        <Text style={commonStyles.headerText}>SPECIES: MEDIA</Text>
      </View>

      <ScrollView
        ref={(r) => {
          if (r) scrollViewRef.current = r;
        }}
        style={{ flex: 1 }}
        contentContainerStyle={commonStyles.contentContainerStyle}
      >
        {/* MEDIA UPLOAD */}
        <Text style={commonStyles.sectionTitle}>Add a photo or video</Text>
        <ValidatedAny
          containerStyle={{
            borderWidth: 1,
            borderColor:
              fields.media?.valid !== false ? 'transparent' : 'crimson',
          }}
          name="media"
          value={state.uri}
        >
          <View
            style={{
              alignSelf: 'center',
            }}
          >
            <UploadMedia
              disabled={!props.visible}
              persistAfterUserDeletion
              media={[
                {
                  uri: state.uri ?? '',
                  thumbnailUri: state.thumbnail,
                },
              ]}
              mediaType="All"
              style={{
                alignSelf: 'center',
                width: 280,
                height: 350,
                backgroundColor: '#ddd',
              }}
              onChangeMedia={(items) => {
                const media = items[0];

                setState((prevState) => ({
                  ...prevState,
                  uri: media?.uri,
                  thumbnail: media?.thumbnailUri || '',
                }));
              }}
              placeholderImage={<AddNewMedia />}
            />
          </View>
        </ValidatedAny>

        {/* CAPTION */}
        <Text style={commonStyles.sectionTitle}>Add a caption (optional)</Text>
        <TextInput
          style={[TEXT_INPUT_LARGE, { marginVertical: 8, maxHeight: 150 }]}
          multiline
          value={state.caption ?? ''}
          onChangeText={(text) =>
            setState((prevState) => ({ ...prevState, caption: text }))
          }
          maxLength={180}
          placeholder="Type here..."
        />

        {/* PUBLIC SWITCH */}
        <View style={commonStyles.switchContainer}>
          <Text style={commonStyles.switchLabel}>
            Allow other organizations to use this content on their profile?
          </Text>
          <Switch
            trackColor={{
              true: KEY_GREEN,
            }}
            value={state.public}
            onValueChange={(value) => {
              setState((prevState) => ({ ...prevState, public: value }));
            }}
          />
        </View>
        <Text
          style={{
            fontFamily: 'Lato-Bold',
            color: 'gray',
            paddingBottom: 16,
          }}
        >
          Enabling this will prevent you from making edits to this content after
          clicking "Publish"
        </Text>

        {/* BUTTONS */}
        <View style={commonStyles.buttonContainer}>
          <Button
            loading={addingMedia || updatingMedia}
            onPress={() => {
              if (state.public) setPromptMakePublic(true);
              else onSave();
            }}
            label={state.public ? 'Publish' : 'Save'}
            style={{
              backgroundColor: KEY_GREEN,
            }}
          />
          <Button
            disabled={addingMedia || updatingMedia}
            onPress={onCancel}
            label="Cancel"
            containerStyle={{
              marginRight: 8,
            }}
          />
        </View>

        {/* CONFIRM EXIT MODAL */}
        <View
          style={[
            commonStyles.exitPromptContainer,
            { display: promptConfirmExit ? 'flex' : 'none' },
          ]}
        >
          <Text style={commonStyles.exitPromptTitle}>Discard Changes?</Text>
          <Text style={commonStyles.exitPromptText}>
            Looks like you've made some changes. If you cancel now, they will be
            gone forever. Would you like to save or discard your changes?
          </Text>
          <Button
            label="Save and Exit"
            onPress={() => {
              if (state.public) {
                setPromptConfirmExit(false);
                setPromptMakePublic(true);
              } else onSave();
            }}
          />
          <Button
            containerStyle={{
              marginVertical: 4,
            }}
            label="Discard and Exit"
            onPress={() => props.onRequestClose?.()}
          />
          <Button label="Go back" onPress={() => setPromptConfirmExit(false)} />
        </View>

        {/* CONFIRM PUBLIC SAVE MODAL */}
        <View
          style={[
            commonStyles.exitPromptContainer,
            { display: promptMakePublic ? 'flex' : 'none' },
          ]}
        >
          <Text style={commonStyles.exitPromptTitle}>Publish publicly?</Text>
          <Text style={commonStyles.exitPromptText}>
            You chose to make this content available to other organizations to
            use. This cannot be undone, and you will not be able to edit this
            media anymore. You can still delete this content at any time.
          </Text>
          <Button
            label="Publish"
            onPress={() => {
              setPromptMakePublic(false);
              onSave();
            }}
          />
          <Button
            label="Cancel"
            containerStyle={{
              marginTop: 4,
            }}
            onPress={() => setPromptMakePublic(false)}
          />
        </View>
      </ScrollView>

      {/* LOADING OVERLAY */}
      <LoadingOverlay loading={addingMedia || updatingMedia} />
    </Animated.View>
  );
}

export default withFormValidation(EditUserContentMediaModal);

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'white',
  },
});
