import { UpdateResolver } from '@urql/exchange-graphcache';
import removeItemFromPaginatedField from '/util/cache/removeItemFromPaginatedField';
import { gql } from 'urql';

const deleteCreativeConnectProject: UpdateResolver = (result, args, cache) => {
  cache.invalidate('Query', 'listMyCreativeConnectProjects', {
    userId: args.userId,
  });

  removeItemFromPaginatedField({
    cache,
    entity: {
      __typename: 'Query',
    },
    paginatedField: {
      name: 'listMyCreativeConnectProjects',
      fragment: gql`
        fragment deleteCreativeConnectProjectListMyCCProjects on Query {
          listMyCreativeConnectProjects(userId: $userId, limit: $limit) {
            total
            nextToken
            items {
              id
              application_deadline
              created_at
              is_series
              creative_type
              is_premiered
              media
              created_by {
                id
                profile_image
                name
              }
              title {
                id
                text
                language
              }
              description {
                id
                language
                text
              }
              collaborators {
                total
                nextToken
                items {
                  projectId
                  userId
                  user {
                    id
                    profile_image
                  }
                }
              }
            }
          }
        }
      `,
    },
    removeItem: {
      id: args.id,
    },
  });
};

export default deleteCreativeConnectProject;
