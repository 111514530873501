import React, { useState } from 'react';
import { View } from 'react-native';
import Button from '../Button';
import RemovableBadge from '../RemovableBadge';
import SpeciesPickerModal, {
  ISpeciesPickerSpecies,
} from './SpeciesPickerModal';
import Alert from '/Alert';
import { IucnThreatStatus } from '/generated/graphql';
import { ViewStyle } from 'react-native';
import { StyleProp } from 'react-native';
import { ALERT_RED } from '/constants';

interface SpeciesPickerProps {
  data: ISpeciesPickerSpecies[];
  darkTheme?: boolean;
  setData: (data: ISpeciesPickerSpecies[]) => void;
  maxSelectionSize?: number;
  buttonStyle?: StyleProp<ViewStyle>;
  buttonContainerStyle?: StyleProp<ViewStyle>;
  buttonLabel?: string;
}

export default function SpeciesPicker(props: SpeciesPickerProps) {
  const [showModal, setShowModal] = useState(false);

  return (
    <View>
      <SpeciesPickerModal
        visible={showModal}
        onRequestClose={(newSpecies) => {
          setShowModal(false);

          if (
            !newSpecies ||
            (props.maxSelectionSize &&
              props.data.length >= props.maxSelectionSize)
          )
            return;

          // If this species is already in the list, don't add it again
          if (
            props.data.find(
              (s) =>
                newSpecies.acceptedNameUsageID === s.taxonID ||
                newSpecies.acceptedNameUsageID === s.acceptedNameUsageID,
            )
          ) {
            Alert.alert(
              'Species already added',
              'You have already added this species.',
            );
            return;
          }

          props.setData([
            ...props.data,
            {
              taxonID: newSpecies.taxonID,
              acceptedNameUsageID: newSpecies.acceptedNameUsageID,
              vernacularName: newSpecies.vernacularName,
              canonicalName: newSpecies.canonicalName,
              ...(newSpecies.threatStatus
                ? {
                    iucnThreatStatus: {
                      taxonID: newSpecies.taxonID,
                      threatStatus: newSpecies.threatStatus as IucnThreatStatus,
                    },
                  }
                : {}),
            },
          ]);
        }}
      />

      <Button
        label={props.buttonLabel || 'Add species'}
        disabled={
          !!props.maxSelectionSize &&
          props.data.length >= props.maxSelectionSize
        }
        style={props.buttonStyle}
        containerStyle={[
          {
            marginVertical: 8,
          },
          props.buttonContainerStyle,
        ]}
        onPress={() => {
          setShowModal(true);
        }}
      />
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {!!props.data.length &&
          props.data?.map((species, index) => {
            if (!species) return;

            const label = species.vernacularName || species.canonicalName;

            return (
              <RemovableBadge
                key={species.taxonID}
                style={index === 0 ? { marginLeft: 0 } : undefined}
                label={label}
                removeButtonColor={props.darkTheme ? ALERT_RED : 'black'}
                onRemove={() => {
                  props.setData(
                    (props.data ?? []).filter(
                      (s) => s?.taxonID !== species.taxonID,
                    ),
                  );
                }}
              />
            );
          })}
      </View>
    </View>
  );
}
