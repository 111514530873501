import { UpdateResolver } from '@urql/exchange-graphcache';

const archiveUserReport: UpdateResolver = (result, args, cache) => {
  cache.invalidate({
    __typename: 'UserReport',
    id: args.id as string,
  });
};

export default archiveUserReport;
