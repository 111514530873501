import { useEffect, useState } from 'react';
import { Platform } from 'react-native';

/** For web only */
export default function useIsMouseDown() {
  const [isMouseDown, setIsMouseDown] = useState(false);

  useEffect(() => {
    if (Platform.OS !== 'web') return;

    /**
     * Alert if clicked on outside of element
     */
    function handleMouseUp() {
      setIsMouseDown(false);
    }

    function handleMouseDown() {
      setIsMouseDown(true);
    }

    // Bind the event listener
    document.addEventListener?.('mouseup', handleMouseUp);
    document.addEventListener?.('mousedown', handleMouseDown);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener?.('mouseup', handleMouseUp);
      document.removeEventListener?.('mousedown', handleMouseDown);
    };
  }, []);

  return isMouseDown;
}
