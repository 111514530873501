import { UpdateResolver } from '@urql/exchange-graphcache';

const createGroupDiscussionCategory: UpdateResolver = (result, args, cache) => {
  const groupId = (args as any).input?.group?.id;

  if (!groupId) return;

  cache.invalidate({
    __typename: 'Group',
    id: groupId,
  });
};

export default createGroupDiscussionCategory;
