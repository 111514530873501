import React, { useRef } from 'react';
import { Text, View } from 'react-native';
import { useCommonStyles } from '../styles';
import EditOrganizationBigIssueModal, {
  IBigIssue,
} from './EditOrganizationBigIssueModal';
import BigIssuesCarousel from '/components/BigIssues/BigIssuesCarousel';
import LoadingOverlay from '/components/LoadingOverlay';
import { SECTION_CONTAINER } from '/constants';
import { useModalContext } from '/context';
import {
  CarouselCard,
  useDeleteOrganizationBigIssueMutation,
  useGetUserBigIssuesQuery,
} from '/generated/graphql';

interface Props {
  userId: string | undefined;
  setBusy: (busy: boolean) => void;
  isEditing: boolean;
}

export default function TheBigIssues(props: Props) {
  const { styles: commonStyles } = useCommonStyles();

  const { spawnModal, closeModal } = useModalContext();

  const [{ data, fetching, error }, refetch] = useGetUserBigIssuesQuery({
    variables: {
      userId: props.userId!,
      draft: false,
      includeBigIssueCampaigns: true,
    },
    pause: !props.userId,
  });

  const [{ fetching: deleting }, deleteBigIssue] =
    useDeleteOrganizationBigIssueMutation();

  const editModalIdRef = useRef<string>();
  function openEditorModal(_data?: IBigIssue) {
    editModalIdRef.current = spawnModal({
      title: 'EDIT BIG ISSUE',
      disableCloseButton: true,
      component: (
        <EditOrganizationBigIssueModal
          userId={props.userId as string}
          data={_data}
          onRequestClose={() => {
            closeModal(editModalIdRef.current as string);
          }}
          setBusy={props.setBusy}
          refetch={() => refetch({ requestPolicy: 'network-only' })}
        />
      ),
    });
  }

  function onEditPressed(id: string) {
    if (!props.userId) return;

    const issue = data?.getBigIssues.find((i) => i.id === id);

    if (!issue) return;

    if (!issue.campaigns) {
      console.error(
        '[TheBigIssues] onEditPressed: fetched BigIssue is missing `campaigns`, required by edit form',
      );
    }

    openEditorModal({
      ...issue,
      campaigns: issue.campaigns ?? {
        total: 0,
        items: [],
        nextToken: null,
      },
    });
  }

  function onCarouselChange(_: CarouselCard[], removedIndex?: number) {
    // If we removed a card, delete the big issue
    if (removedIndex !== undefined) {
      const targetIssue = data?.getBigIssues?.[removedIndex];
      if (targetIssue) {
        props.setBusy(true);
        deleteBigIssue({
          id: targetIssue.id,
        })
          .then(() => {
            // TODO: Implement
          })
          .catch(() => {
            // TODO: Implement
          })
          .finally(() => {
            // TODO: Implement
          });
      }
    } else {
      refetch({ requestPolicy: 'network-only' });
      openEditorModal();
    }
  }

  const nonDraftBigIssues = data?.getBigIssues?.filter(
    (item) => item !== undefined && item.draft === false,
  );

  return (
    <View
      style={[
        SECTION_CONTAINER,
        {
          display:
            !nonDraftBigIssues?.length && !props.isEditing ? 'none' : 'flex',
        },
      ]}
    >
      {/* LOADING OVERLAY */}
      <LoadingOverlay loading={deleting} />

      <View style={commonStyles('sectionTitleContainer')}>
        <Text style={commonStyles('sectionTitle')}>THE BIG ISSUES</Text>
      </View>
      <Text style={commonStyles('sectionText')}>
        These are the main problems that we face.
      </Text>
      <BigIssuesCarousel
        data={nonDraftBigIssues}
        error={error}
        fetching={fetching}
        refetch={refetch}
        isEditing={props.isEditing}
        onCarouselChange={onCarouselChange}
        onEditPressed={onEditPressed}
      />
    </View>
  );
}
