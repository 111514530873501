import { UpdateResolver } from '@urql/exchange-graphcache';

const deleteGroupDiscussionCategory: UpdateResolver = (result, args, cache) => {
  cache.invalidate({
    __typename: 'GroupDiscussionCategory',
    id: args.categoryId as string,
  });
};

export default deleteGroupDiscussionCategory;
