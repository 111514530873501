import React, { ForwardedRef, useContext } from 'react';
import { StyleProp, TextInput, TextInputProps } from 'react-native';
import { ValidatedFormContext } from './ValidatedForm';
import ValidatedAny, { IValidatedAnyProps } from './ValidatedAny';
import { REQUIRED_INPUT_INVALID } from '/constants';

interface IValidatedTextInputProps
  extends TextInputProps,
    IValidatedAnyProps<string> {
  value: string | undefined;
  /** Override default error styling */
  errorStyle?: StyleProp<TextInput>;
}

export default React.forwardRef(function ValidatedTextInput(
  props: IValidatedTextInputProps,
  ref: ForwardedRef<TextInput>,
) {
  const { state, validateField } = useContext(ValidatedFormContext);

  const errorStyle =
    state.fields[props.name]?.valid === false
      ? props.errorStyle ?? REQUIRED_INPUT_INVALID
      : {};

  return (
    <ValidatedAny {...props}>
      <TextInput
        {...props}
        style={[props.style, errorStyle]}
        onBlur={(e) => {
          validateField(props.name);

          props.onBlur?.(e);
        }}
        ref={ref}
      />
    </ValidatedAny>
  );
});
