import { UpdateResolver } from '@urql/exchange-graphcache';

const inviteCreativeConnectCollaborator: UpdateResolver = (
  result,
  args: any,
  cache,
) => {
  const projectId = args.input?.projectId;

  cache.invalidate(
    {
      __typename: 'CreativeConnectProject',
      id: projectId as string,
    },
    'collaborators',
    {
      confirmed: false,
      initiated_by_collaborator: true,
    },
  );

  cache.invalidate(
    {
      __typename: 'CreativeConnectProject',
      id: projectId as string,
    },
    'collaborators',
  );
};

export default inviteCreativeConnectCollaborator;
