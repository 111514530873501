import Feather from '@expo/vector-icons/Feather';
import React from 'react';
import { View, ViewStyle } from 'react-native';
import SharePopover from '../SharePopover/SharePopover';

type Props = {
  sharePopoverInitiallyVisible?: boolean;
  popoverWidget?: JSX.Element;
  icon?: JSX.Element;
  url: string;
  shareMessage: string;
  style?: ViewStyle;
  iconSize?: number;
  disabled?: boolean;
};

export default function ShareButton(props: Props) {
  return (
    <SharePopover
      disabled={props.disabled}
      initialIsVisible={props.sharePopoverInitiallyVisible}
      shareTitle={``}
      widget={props.popoverWidget}
      shareMessage={props.shareMessage}
      shareURL={props.url}
    >
      <View
        style={[
          {
            borderRadius: 12,
            backgroundColor: '#f5f5f5',
            margin: 2,
            justifyContent: 'center',
            alignItems: 'center',
            width: 40,
            height: 40,
          },
          props.style,
        ]}
      >
        {props.icon ? (
          props.icon
        ) : (
          <Feather name="share" size={props.iconSize ?? 28} color="black" />
        )}
      </View>
    </SharePopover>
  );
}
