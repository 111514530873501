export default {
  container: {
    backgroundColor: 'rgba(0,0,0,0)',
    paddingRight: 28,
    paddingLeft: 20,
    paddingVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  outline: {
    fontSize: 28,
    color: 'white',
  },
};
