import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { Text, View } from 'react-native';
import { useCommonStyles } from '../styles';
import JobPostCard from '/components/JobBoard/elements/JobPostCard';
import TruncatedList from '/components/TruncatedList/TruncatedList';
import { SECTION_CONTAINER } from '/constants';
import { useListJobPostsQuery } from '/generated/graphql';

interface Props {
  userId: string | undefined;
  isEditing: boolean;
}

export default function OurJobPosts(props: Props) {
  const navigation = useNavigation<StackNavigationProp<any>>();

  const { styles: commonStyles } = useCommonStyles();

  const [{ data }] = useListJobPostsQuery({
    variables: {
      limit: 2,
      filter: {
        userId: props.userId,
        closed: false,
      },
    },
  });

  return data?.listJobPosts.total ? (
    <View style={SECTION_CONTAINER}>
      <View style={commonStyles('sectionTitleContainer')}>
        <Text style={commonStyles('sectionTitle')}>JOB POSTS</Text>
      </View>

      <TruncatedList
        items={data?.listJobPosts.items ?? []}
        total={data.listJobPosts.total}
        maxLength={2}
        onViewMore={() => {
          navigation.navigate('ListJobPosts', {
            userId: props.userId,
          });
        }}
        renderItem={(item) => {
          return (
            <JobPostCard
              data={item}
              onPress={() => {
                navigation.navigate('ViewJobPost', {
                  id: item.id,
                });
              }}
            />
          );
        }}
      />
    </View>
  ) : null;
}
