import { StyleSheet } from 'react-native';
import {
  CARD_TITLE_FONT_SIZE,
  CONTENT_PARAGRAPH_FONT_SIZE,
  KEY_GRAY,
  TITLE_FONT_SIZE,
} from '/constants';

export default StyleSheet.create({
  exitPromptContainer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(20, 20, 20, 0.7)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  exitPromptTitle: {
    fontFamily: 'Lato-Bold',
    fontSize: TITLE_FONT_SIZE,
    color: 'white',
  },
  exitPromptText: {
    maxWidth: 320,
    textAlign: 'center',
    margin: 24,
    fontFamily: 'Lato-Bold',
    fontSize: CONTENT_PARAGRAPH_FONT_SIZE,
    color: 'white',
  },
  contentContainerStyle: {
    padding: 10,
    paddingBottom: 48,
    width: '100%',
    maxWidth: 800,
    alignSelf: 'center',
  },
  headerText: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: TITLE_FONT_SIZE,
  },
  sectionTitle: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: CARD_TITLE_FONT_SIZE,
    marginTop: 16,
    marginBottom: 6,
  },
  sectionText: {
    fontFamily: 'Lato',
    fontSize: CONTENT_PARAGRAPH_FONT_SIZE,
    paddingBottom: 4,
  },
  headerContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
    maxWidth: 800,
    alignSelf: 'center',
  },
  buttonContainer: {
    paddingVertical: 6,
    width: '100%',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  buttonLabelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonLabelText: {
    fontFamily: 'Lato-Bold',
    fontSize: 17,
    marginLeft: 4,
  },
  errorText: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
    color: 'crimson',
    textAlign: 'center',
  },
  switchContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 6,
  },
  switchLabel: {
    flex: 1,
    fontFamily: 'Lato',
    fontSize: 17,
    paddingRight: 16,
  },
  createdByContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 2,
  },
  createdByText: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    paddingLeft: 4,
  },
});
