import { useAuthContext } from '/context';
import { View, Text, Pressable, ViewStyle } from 'react-native';
import { ALERT_RED, KEY_GRAY, KEY_GREEN, SECTION_CONTAINER } from '/constants';
import styles from './ReferralWidget.style';
import { FontAwesome5, Ionicons } from '@expo/vector-icons';
import * as Clipboard from 'expo-clipboard';
import { createUniversalURL } from '/util';
import Alert from '/Alert';

type Props = {
  style?: ViewStyle;
};

export default function ReferralWidget(props: Props) {
  const { userData } = useAuthContext();

  const referralLink = createUniversalURL('login', {
    ref: userData?.referral_link?.code,
  });

  function onCopyLink() {
    Clipboard.setStringAsync(referralLink)
      .then(() => {
        Alert.notify({
          message: 'Copied referral link to clipboard!',
          color: KEY_GREEN,
        });
      })
      .catch((err) => {
        Alert.notify({
          message: 'Failed to copy referral link to clipboard',
          color: ALERT_RED,
        });
        console.log('Failed to copy referral link to clipboard', err);
      });
  }

  return userData?.referral_link ? (
    <View style={[SECTION_CONTAINER, props.style]}>
      <View style={styles.titleContainer}>
        <Ionicons name="person-add" size={24} color="black" />
        <Text style={styles.titleText}>{` `}REFERRALS</Text>
      </View>
      <Text style={styles.bodyText}>
        Invite friends to Key using your referral link and earn a referral for
        every new user that signs up!
      </Text>
      <Text
        style={[
          styles.bodyTextBold,
          {
            marginVertical: 8,
          },
        ]}
      >
        Referrals: {userData.referral_link?.uses ?? 0}
      </Text>
      <Text style={[styles.bodyTextBold, { color: 'black' }]}>
        Your referral link:
      </Text>
      <Pressable style={styles.linkPressable} onPress={onCopyLink}>
        <FontAwesome5 name="copy" size={22} color={KEY_GRAY} />
        <Text
          style={[
            styles.bodyTextBold,
            {
              flex: 1,
              marginLeft: 8,
            },
          ]}
        >
          {referralLink}
        </Text>
      </Pressable>
    </View>
  ) : null;
}
