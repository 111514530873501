import React from 'react';
import { Text, View } from 'react-native';
import ButtonToggle from '../../../ButtonToggle/ButtonToggle';
import FormList from '/components/FormList';
import ValidatedAny from '/components/ValidatedForm/ValidatedAny';
import { ValidatedFormFields } from '/components/ValidatedForm/ValidatedForm';
import ValidatedTextInput from '/components/ValidatedForm/ValidatedTextInput';
import { REQUIRED_INPUT_INVALID } from '/constants';
import styles from '/constants/CampaignBuilder/SkillImpact/SkillExpand';
import { SkillRequest, SkillRequestExpertiseInput } from '/generated/graphql';

interface IExpertiseFormProps {
  onChange: (changes: {
    expertise?: SkillRequestExpertiseInput[];
    expertise_required?: boolean;
  }) => void;
  skill: Pick<SkillRequest, 'expertise_required' | 'expertise'>;
  fields: ValidatedFormFields;
}

const ExpertiseForm = (props: IExpertiseFormProps) => {
  const onChangeExpertiseRequired = (value: boolean) => {
    if (props.skill?.expertise_required === value) return;

    props.onChange({
      expertise_required: value,
    });
  };

  function onChangeExpertise(expertise: SkillRequestExpertiseInput[]) {
    props.onChange({
      expertise,
    });
  }

  return (
    <ValidatedAny
      name="expertise"
      containerStyle={{ flex: 1 }}
      required={!!props.skill?.expertise_required}
      value={props.skill?.expertise}
      validate={(value) => {
        return !!value?.length;
      }}
    >
      <View
        style={[
          styles.itemContainers,
          { flex: 1 },
          props.fields.expertise?.valid === false
            ? REQUIRED_INPUT_INVALID
            : null,
        ]}
      >
        <View style={styles.itemContentRows}>
          <Text style={styles.itemContentTitle}>Expertise Needed</Text>
        </View>
        <View style={styles.itemContentRows}>
          <Text style={styles.itemBodyText}>
            Does this skill request need someone with a certain expertise to
            help with the task?
          </Text>
        </View>
        <View
          style={[
            styles.itemContentRows,
            {
              flexDirection: 'row',
              alignItems: 'center',
              maxWidth: 400,
            },
          ]}
        >
          <ButtonToggle
            label="YES"
            style={{
              marginLeft: 0,
              flex: 1,
            }}
            selected={!!props.skill?.expertise_required}
            onPress={() => onChangeExpertiseRequired(true)}
          />
          <ButtonToggle
            label="NO"
            style={{
              flex: 1,
            }}
            selected={!props.skill?.expertise_required}
            onPress={() => onChangeExpertiseRequired(false)}
          />
        </View>
        <View
          style={{
            flexDirection: 'row',
            display: props.skill?.expertise_required ? 'flex' : 'none',
            paddingVertical: 8,
          }}
        >
          <View style={[styles.leftLineAddGoal, { height: '100%' }]} />
          <FormList
            placeholder="Add expertise..."
            data={props.skill?.expertise}
            containerStyle={{ flex: 1 }}
            renderIcon={() => <></>}
            renderForm={({ item, onChange, index }) => {
              return (
                <View
                  style={{
                    flex: 1,
                    paddingBottom: 8,
                  }}
                >
                  <ValidatedTextInput
                    name={`expertise_name_${index}`}
                    numberOfLines={1}
                    maxLength={64}
                    style={[
                      styles.textInput,
                      {
                        fontFamily: 'Lato-Bold',
                      },
                    ]}
                    placeholder="Expertise Name"
                    placeholderTextColor="gray"
                    onChangeText={(text) => onChange({ ...item, name: text })}
                    value={item.name}
                  />
                  <ValidatedTextInput
                    name={`expertise_description_${index}`}
                    maxLength={320}
                    multiline
                    style={styles.goalDescriptionBox}
                    placeholder="Explain in detail the type of expertise you need"
                    placeholderTextColor="gray"
                    onChangeText={(text) =>
                      onChange({ ...item, description: text })
                    }
                    value={item.description}
                  />
                </View>
              );
            }}
            onChange={onChangeExpertise}
          />
        </View>
        <Text
          style={{
            display: props.fields.expertise?.valid === false ? 'flex' : 'none',
            fontFamily: 'Lato-Bold',
            color: 'crimson',
          }}
        >
          Please add at least one expertise description
        </Text>
      </View>
    </ValidatedAny>
  );
};

export default ExpertiseForm;
