import { UpdateResolver } from '@urql/exchange-graphcache';
import {
  GetNotificationsForUserDocument,
  NotificationType,
} from '/generated/graphql';
import { isValidJson } from '/util';

const acceptCampaignConnectSubmission: UpdateResolver = (
  result,
  args,
  cache,
) => {
  const inviteId = args.inviteId as string | undefined;

  if (!inviteId) return;

  cache.invalidate({
    __typename: 'CampaignConnectInvite',
    id: inviteId as string,
  });
  // Go through all notifications, remove those whose `data` field contains the string
  // `campaignConnectInviteId: ${inviteId}` and whose type is
  // `NotificationType.CampaignConnectInviteCampaignSubmitted`.
  const queries = cache.inspectFields('Query');
  const getNotificationsQueries = queries.filter(
    (q) => q.fieldName === 'getNotificationsForUser',
  );

  getNotificationsQueries.forEach((q) => {
    cache.updateQuery(
      {
        query: GetNotificationsForUserDocument,
        variables: q.arguments,
      },
      (data) => {
        const prev = data?.getNotificationsForUser as any;
        return {
          ...data,
          __typename: 'Query',
          getNotificationsForUser: {
            __typename: 'GetAllNotificationsResponse',
            ...prev,
            actionable: ((prev?.actionable as any[]) ?? []).reduce(
              (acc, cur) => {
                const section = {
                  ...cur,
                  notifications: cur.notifications.filter((n: any) => {
                    if (
                      n.type !==
                      NotificationType.CampaignConnectInviteCampaignSubmitted
                    )
                      return true;

                    const parsedData = isValidJson(n.data)
                      ? JSON.parse(n.data)
                      : {};
                    return parsedData?.campaignConnectInviteId !== inviteId;
                  }),
                };

                if (section.notifications.length === 0) return acc;
                return [...acc, section];
              },
              [],
            ),
          },
        };
      },
    );
  });
};

export default acceptCampaignConnectSubmission;
